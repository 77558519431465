import React from "react";
import { Modal } from "@material-ui/core";
import BotframeworkChatWidget from "./BotframeworkChatWidget";

const IntebotChatModal = (props) => {
    const { openTestChat, setOpenTestChat } = props;
    return (
        <div>
            <Modal
                open={openTestChat}
                onClose={(e, reason) => {
                    if (reason === "backdropClick") {
                        return;
                    }
                    setOpenTestChat(false);
                }}
            >
                <BotframeworkChatWidget setOpenTestChat={setOpenTestChat} />
            </Modal>
        </div>
    );
};

export default IntebotChatModal;
