import React, { memo } from "react";

//MUI components
import { TextField, IconButton, Tooltip } from "@material-ui/core";
//Icons
import EliminarIcono from "../../Icons/EliminarIcono";

//Styles Sheets
import "./Styles/editQnAQuestions.css";

const QuestionTextField = (props) => {
    const {
        questionIndex,
        filteredQuestions,
        questionObj,
        lastQuestion,
        pairErrors,
        pairErrorHelper,
        handlePairChange,
        id,
        newPair,
        removeQuestion,
        pairIsSuggestions,
    } = props;

    return (
        <div className="questions-textfields">
            <TextField
                disabled={pairIsSuggestions}
                ref={filteredQuestions.length === questionIndex + 10 ? lastQuestion : null}
                name={`questions`} //index
                value={questionObj.question ?? ""} //map value
                variant={pairIsSuggestions ? "standard" : "outlined"}
                size="small"
                error={pairErrors?.questions?.[questionIndex] ?? false}
                helperText={pairErrorHelper?.questions?.[questionIndex] ?? ""}
                multiline
                fullWidth
                onChange={(event) =>
                    handlePairChange(
                        event.target.name,
                        event.target.value,
                        id,
                        questionObj.id,
                        newPair
                    )
                } //Handle Change Question
            />
            {!pairIsSuggestions && (
                <Tooltip title="Eliminar frase">
                    <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => removeQuestion(id, questionObj.id, newPair)}
                    >
                        <EliminarIcono width="15px" height="15px" />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

const propAreEqual = (prevProps, newProps) => {
    let isQuestionEqual = prevProps.questionObj.question === newProps.questionObj.question;
    let isPairIdEqual = prevProps.id === newProps.id;
    let isQuestionIdEqual = prevProps.questionObj.id === newProps.questionObj.id;
    return isPairIdEqual && isQuestionEqual && isQuestionIdEqual;
};

export default memo(QuestionTextField, propAreEqual);
