import React from 'react'

//MUI components
import {
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core/';

import { appCulture } from '../IntebotCatalog';

const AppTableComponent = (props) => {
    const { name, culture, tempAppName, setTempAppId, id, tempAppId, setChangeAppHasChanged} = props

    const getAppCultureName = (culture) => {
        return appCulture.find((item) => item.code === culture).language;
    }

    const handleRadioChange = () => {
        tempAppName.current = name;
        setTempAppId(id);
        setChangeAppHasChanged(true);
    }

    return (
        <div className='intent-table-component' >
            <RadioGroup value={tempAppId} onChange={() => handleRadioChange()}>
                <FormControlLabel value={id} control={<Radio color="primary"/>} />
            </RadioGroup>
            {/* OPEN MODAL BUTTON FOR EDIT INTENT */}
            <h3 className='app-name-header'>{name}</h3>
            <h3 className='app-culture-header'>
                {
                    getAppCultureName(culture)
                }
            </h3>
        </div>
    )
}

export default AppTableComponent;
