import React from "react";
//Mui components
import { Grid, Breadcrumbs, Typography, Button, TextField } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";

//Icons
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";
import AgregarIcono from "../../Icons/AgregarIcono";

//Custom Portal Components
import FormModal from "../FormModalComponents/FormModal";
import ButtonConfirmDialog from "../common/ButtonConfirmDialog";
import Table from "../common/Tables/StyledTable";
import CustomAlert from "../common/Alerts/CustomAlert";
import BackToSection from "../common/Modals/BackToSectionModal";
import IntebotCatalog from "../IntebotCatalog";
import AppContext from "../../Context/AppContext";
import { intebotDataActions } from "../DataFunctions";

//styleSheets
import "./Styles/EditCatalogModal.css";

//Swal
import Swal from "sweetalert2";

export default class EditCatalogModal extends React.Component {
    errorMessages = IntebotCatalog.errorHelper;
    constructor(props) {
        super(props);
        this.state = {
            catalogId: undefined,
            tempCatalog: {},
            hasChanges: false,
            catalogKeysErrors: [],
            catalogRecordsErrors: [],
            countCatalogKeysErrors: 0,
            countCatalogRecordsErrors: 0,
            takenValues: [],
            errorHelper: [],
            errorHelperRecords: [],
            emptyTable: false,
            alert: {
                open: false,
                severity: "", //success, error, warning, info
                message: "",
            },
        };

        this.child = React.createRef();
    }

    componentDidMount() {
        const { catalog } = this.props;
        this.updateErrors(JSON.parse(JSON.stringify(catalog)));
    }

    componentDidUpdate(prevProps, prevState) {
        const { updateContextAttribute } = this.context;
        const { hasChanges } = this.state;

        if (prevState.hasChanges !== hasChanges) {
            updateContextAttribute("catalogsHasChanges", hasChanges);
        }
    }

    //Función para construir los objetos que manejan los errores del modal
    updateErrors = (propsCatalog) => {
        const { errorMessages } = this;
        const { catalogId } = this.props;
        const tempCatalog = JSON.parse(JSON.stringify(propsCatalog));
        let errorHelperCopy = [];
        let errorHelperRecordsCopy = [];
        //Al construir debemos armar un objeto para el manejo de errores de los registros del catálogo
        let catalogKeysErrors = {};
        Object.keys(propsCatalog).forEach((property) => {
            catalogKeysErrors[property] = propsCatalog[property] === "" ? true : false;
            errorHelperCopy[property] =
                propsCatalog[property] === "" ? errorMessages.emptyField : "";
        });
        //Errores de los registros del catálogo {key: "", values: []}
        let catalogRecordsErrorsCopy = [];
        propsCatalog.catalog.forEach((element) => {
            catalogRecordsErrorsCopy.push(element);
            errorHelperRecordsCopy.push(element);
        });
        for (let index = 0; index < propsCatalog.catalog.length; index++) {
            const element = propsCatalog.catalog[index];
            Object.keys(element).forEach((property) => {
                //catalog errors se setea con el empty field cuando deberia setearse con true o false
                if (element[property] === "" || element[property].length === 0) {
                    catalogRecordsErrorsCopy[index][property] = true;
                    //errorHelperRecordsCopy[index][property] = errorMessages.emptyField
                } else {
                    catalogRecordsErrorsCopy[index][property] = false;
                    //errorHelperRecordsCopy[index][property] = ''
                }
            });
        }
        //Número de campos con errores:
        const countCatalogKeysErrors = Object.values(catalogKeysErrors).reduce(
            (a, item) => a + item,
            0
        );
        const countCatalogRecordsErrors = Object.values(catalogRecordsErrorsCopy).reduce(
            (a, { key, values }) => a + key + values,
            0
        );
        //Guardamos en el estado
        this.setState({
            tempCatalog: tempCatalog,
            hasChanges: false,
            catalogId: catalogId,
            catalogKeysErrors: catalogKeysErrors,
            catalogRecordsErrors: catalogRecordsErrorsCopy,
            countCatalogKeysErrors: countCatalogKeysErrors,
            countCatalogRecordsErrors: countCatalogRecordsErrors,
            errorHelper: errorHelperCopy,
            errorHelperRecords: errorHelperRecordsCopy,
        });
    };

    catalogChangeHandler = (targetName, targetValue, targetType, targetRequired) => {
        //Hacemos una copia independiente del objeto para guardar cambios temporales:
        const { catalogList } = this.props;
        const { errorMessages } = this;
        const { tempCatalog, catalogKeysErrors, errorHelper } = this.state;
        const tempCatalogCopy = JSON.parse(JSON.stringify(tempCatalog));
        const catalogKeysErrorsCopy = JSON.parse(JSON.stringify(catalogKeysErrors));
        //console.log(targetName, targetValue, targetType, targetRequired)
        //Evaluamos el tipo de componente para actualizar correctamente el estado del objeto:
        switch (targetType) {
            case "text-field":
                //Simplemente agregamos la nueva cadena o arreglo en el atributo correspondiente:
                tempCatalogCopy[targetName] = targetValue.toUpperCase();
                errorHelper[targetName] = targetValue ? errorMessages.emptyField : "";
                break;
            default:
                break;
        }
        //Revisamos si hay errores en los campos
        if (tempCatalogCopy[targetName] === "" && targetRequired) {
            catalogKeysErrorsCopy[targetName] = true;
            errorHelper[targetName] = errorMessages.emptyField;
        } else {
            //También colocamos error si ya existe el nombre para evitar duplicidad
            if (catalogList.includes(targetValue.toUpperCase())) {
                catalogKeysErrorsCopy[targetName] = true;
                errorHelper[targetName] = errorMessages.catalogTaken;
            } else {
                catalogKeysErrorsCopy[targetName] = false;
            }
        }
        //Contamos la cantidad de errores
        const countCatalogKeysErrorsCopy = Object.values(catalogKeysErrorsCopy).reduce(
            (a, item) => a + item,
            0
        );
        this.setState({
            tempCatalog: tempCatalogCopy,
            hasChanges: true,
            catalogKeysErrors: catalogKeysErrorsCopy,
            countCatalogKeysErrors: countCatalogKeysErrorsCopy,
            errorHelper: errorHelper,
        });
    };

    catalogRecordsChangeHandler = (
        targetName,
        targetValue,
        targetType,
        targetRequired,
        position,
        targetIndex
    ) => {
        //Hacemos una copia independiente del objeto para guardar cambios temporales:
        const { takenValues, errorHelper } = this.state;
        const { errorMessages } = this;
        //console.log('Records',targetName, targetValue, targetType, targetRequired, position, targetIndex)
        const tempCatalogCopy = JSON.parse(JSON.stringify(this.state.tempCatalog));
        const catalogRecordsErrorsCopy = JSON.parse(
            JSON.stringify(this.state.catalogRecordsErrors)
        );
        //Evaluamos el tipo de componente para actualizar correctamente el estado del objeto:
        switch (targetType) {
            case "text-field":
                //Simplemente agregamos la nueva cadena o arreglo en el atributo correspondiente:
                tempCatalogCopy.catalog[position][targetName] = targetValue;
                if (targetValue === "" && targetRequired) {
                    catalogRecordsErrorsCopy[position][targetName] = true;
                    errorHelper[targetName] = errorMessages.emptyField;
                } else {
                    const keysList = this.state.tempCatalog.catalog.map((obj) => obj.key);
                    if (keysList.includes(targetValue)) {
                        catalogRecordsErrorsCopy[position][targetName] = true;
                        errorHelper[targetName] = errorMessages.catalogKeyTaken;
                    } else {
                        catalogRecordsErrorsCopy[position][targetName] = false;
                        errorHelper[targetName] = "";
                    }
                }
                break;
            case "chip-editor":
                let targetValueLowerCase =
                    targetValue.length > 0 ? targetValue.toLowerCase() : targetValue;
                const tempKeyWords = tempCatalogCopy.catalog[position][targetName].map(
                    (item) => item
                );
                if (typeof targetIndex == "undefined") {
                    //Tenemos que agregar Chip al arreglo:
                    tempKeyWords.push(targetValueLowerCase);
                    tempCatalogCopy.catalog[position][targetName] = tempKeyWords;
                } else {
                    //Significa que estamos recibiendo un índice y tenemos que quitar un Chip del arreglo:
                    let tempDeletedItem = tempKeyWords.splice(targetIndex, 1);
                    tempCatalogCopy.catalog[position][targetName] = tempKeyWords;
                    // buscamos el valor eliminado del arreglo de valores tomados y lo quitamos
                    let IDtakenValueDelete = takenValues.indexOf(tempDeletedItem[0]);
                    if (IDtakenValueDelete > -1) {
                        takenValues.splice(IDtakenValueDelete, 1);
                    }
                }
                if (
                    tempKeyWords.length === 0 &&
                    targetRequired
                    //|| takenValues.length > 0
                )
                    //|| valueExists
                    catalogRecordsErrorsCopy[position][targetName] = true;
                else catalogRecordsErrorsCopy[position][targetName] = false;
                break;
            default:
                break;
        }
        //buscamos todos los valores del catalogo, quitamos el chip actual y concatenamos todos los valores para buscar los valores que ya existen en otros chips
        let tempCatalogValuesArray = tempCatalogCopy.catalog.map((catalog) => catalog.values);
        let currentChip = tempCatalogValuesArray.splice(position, 1);
        let currentChipValues = [].concat.apply([], currentChip);
        const tempCatalogValues = [].concat.apply([], tempCatalogValuesArray);
        //arreglo que almacena todos los valores que ya existen en el catalogo
        let valueExists = currentChipValues.map((value) => {
            return { value: value, taken: tempCatalogValues.includes(value) };
        });
        // si queremos agregar en otro valor en otro chip, y ya existe, evitamos que se agregue 2 veces al takenvalues
        valueExists
            .filter((chip) => {
                if (chip.taken && !takenValues.includes(chip.value)) {
                    return true;
                } else {
                    return false;
                }
            })
            .map((chip) => takenValues.push(chip.value));
        //si el arreglo de valores tomados existe, entonces indicamos que hay un error
        if (takenValues.length > 0) {
            catalogRecordsErrorsCopy[position][targetName] = true;
        } else if (takenValues.length === 0 && !targetName === "keys") {
            catalogRecordsErrorsCopy[position][targetName] = false;
        }
        const countCatalogRecordsErrors = Object.values(catalogRecordsErrorsCopy).reduce(
            (a, { key, values }) => a + key + values,
            0
        );
        this.setState({
            tempCatalog: tempCatalogCopy,
            hasChanges: true,
            catalogRecordsErrors: catalogRecordsErrorsCopy,
            countCatalogRecordsErrors: countCatalogRecordsErrors,
            takenValues: takenValues,
            errorHelper: errorHelper,
        });
    };

    onSave = async () => {
        //Destructuramos todos los objetos a utilizar
        const { catalogsTemp, updateContextAttribute, getStorageData } = this.context;
        const { tempCatalog, catalogId } = this.state;
        const { token } = getStorageData(["token"]);
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");
        //Editar o agregar el catálogo actual en el objeto de catálogos
        let newTempCatalogs = [];
        if (typeof catalogId == "undefined") {
            //Se está agregando un nuevo catálogo
            newTempCatalogs = JSON.parse(JSON.stringify(catalogsTemp));
            let newCatalogId = newTempCatalogs.push(tempCatalog);
            this.setState({
                catalogId: newCatalogId,
            });
        } else {
            //Se está guardando un catálogo existente
            newTempCatalogs = catalogsTemp.map((catalog, index) => {
                if (index === catalogId) {
                    return tempCatalog;
                }
                return catalog;
            });
        }

        //Llamamos al servicio para guardar el catálogo en la base de datos
        let data = {
            catalogs: newTempCatalogs,
            token,
        };
        let res = await intebotDataActions(
            data,
            "updateConfigurationCatalogs&enviroment=Test",
            "ModelConfigurationOperations"
        );
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        //Manejamos la respuesta del servicio
        switch (responseCode) {
            case 0:
                //Guardamos en el estado y en el contexto
                this.setState({
                    hasChanges: false,
                    alert: {
                        open: true,
                        severity: "success",
                        message: "Nuevo catalogo guardado correctamente",
                    },
                });
                updateContextAttribute("catalogs", newTempCatalogs);
                updateContextAttribute("catalogsTemp", newTempCatalogs);
                updateContextAttribute("catalogsHasSavedChanges", true);

                if (this.props.tempSave) {
                    this.props.addNewCatalog(tempCatalog);
                    //close modal
                    this.child.current.handleClose();
                }

                break;
            case 2:
                //El usuario no tiene permisos para realizar esta acción
                this.setState({
                    alert: {
                        open: true,
                        severity: "info", //success, error, warning, info
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    },
                });
                break;
            case 3:
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;

            default:
                //Ocurrió un error al guardar el catálogo
                this.setState({
                    alert: {
                        open: true,
                        severity: "error",
                        message:
                            "Ocurrió un error al guardar el catálogo, por favor intenta de nuevo.",
                    },
                });
                break;
        }
    };

    onCancel = () => {
        //Volver a llamar construcción de errores y conteo
        const { takenValues } = this.state;
        const { catalog } = this.props;
        let takenValuesCopy = takenValues;
        takenValuesCopy = [];
        this.updateErrors(JSON.parse(JSON.stringify(catalog)));
        this.setState({
            takenValues: takenValuesCopy,
        });
    };

    onDelete = (id) => {
        const { errorMessages } = this;
        const {
            tempCatalog,
            takenValues,
            catalogRecordsErrors,
            errorHelperRecords,
            catalogKeysErrors,
        } = this.state;
        let emptyTable = false;
        const tempCatalogCopy = JSON.parse(JSON.stringify(tempCatalog));
        const takenValuesCopy = JSON.parse(JSON.stringify(takenValues));
        //guardamos y concatenamos los valores del Chip eliminado
        let deletedChip = tempCatalogCopy.catalog.splice(id, 1);
        let arrayDeletedValues = deletedChip.map((catalog) => catalog.values);
        let deletedChipValues = [].concat.apply([], arrayDeletedValues);
        //buscamos si el Chip eliminado tenía algún valor tomado y lo quitamos del arreglo
        let DeletedTakenID = deletedChipValues.map((value) => takenValuesCopy.indexOf(value));
        DeletedTakenID.map((ID) => (ID === -1 ? null : delete takenValuesCopy[ID]));
        //filtramos el arreglo para quitar los espacios vacíos de los valores que había en el chip eliminado
        let filteredTakenValues = takenValuesCopy.filter((taken) => taken);
        const catalogRecordsErrorsCopy = JSON.parse(JSON.stringify(catalogRecordsErrors));
        if (tempCatalogCopy.catalog.length === 0) {
            emptyTable = true;
            errorHelperRecords["recordsTable"] = errorMessages.emptyTable;
        }
        catalogRecordsErrorsCopy.splice(id, 1);
        const countCatalogRecordsErrors = Object.values(catalogRecordsErrorsCopy).reduce(
            (a, { key, values }) => a + key + values,
            0
        );
        this.setState({
            tempCatalog: tempCatalogCopy,
            hasChanges: true,
            catalogRecordsErrors: catalogRecordsErrorsCopy,
            countCatalogRecordsErrors: countCatalogRecordsErrors,
            takenValues: filteredTakenValues,
            emptyTable: emptyTable,
        });
    };

    addNewCatalogItem = () => {
        const { errorMessages } = this;
        const { errorHelper, tempCatalog, catalogRecordsErrors, errorHelperRecords } = this.state;
        const tempCatalogCopy = JSON.parse(JSON.stringify(tempCatalog));
        //let errorHelper = JSON.parse(JSON.stringify(this.state.errorHelper));
        tempCatalogCopy.catalog.push({ key: "", values: [] });
        const catalogRecordsErrorsCopy = JSON.parse(JSON.stringify(catalogRecordsErrors));
        catalogRecordsErrorsCopy.push({ key: true, values: true });
        errorHelper.push({ key: errorMessages.emptyField, values: errorMessages.emptyField });
        const countCatalogRecordsErrors = Object.values(catalogRecordsErrorsCopy).reduce(
            (a, { key, values }) => a + key + values,
            0
        );
        errorHelperRecords["recordsTable"] = "";
        this.setState({
            tempCatalog: tempCatalogCopy,
            hasChanges: true,
            catalogRecordsErrors: catalogRecordsErrorsCopy,
            countCatalogRecordsErrors: countCatalogRecordsErrors,
            errorHelper: errorHelper,
            emptyTable: false,
            errorHelperRecords: errorHelperRecords,
        });
    };

    handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: "",
            },
        });
    };

    catalogRecordsKeys = IntebotCatalog.CatalogItemsKeys;

    render() {
        const {
            catalogKeysErrors,
            catalogRecordsErrors,
            countCatalogKeysErrors,
            countCatalogRecordsErrors,
            hasChanges,
            tempCatalog,
            takenValues,
            errorHelper,
            errorHelperRecords,
            emptyTable,
        } = this.state;
        const {
            catalog,
            buttonName,
            buttonTooltip,
            buttonType,
            startIcon,
            newCatalog,
            alignButtonRight,
        } = this.props;
        const { readOnly } = this.context;

        return (
            <div>
                <CustomAlert
                    open={this.state.alert.open}
                    severity={this.state.alert.severity}
                    message={this.state.alert.message}
                    handleCloseAlert={() => this.handleCloseAlert()}
                />
                <FormModal
                    ref={this.child}
                    modalTitle={
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                            <BackToSection
                                titleButton="Catálogos"
                                askConfirm={hasChanges}
                                onClose={() => {
                                    this.child.current.handleClose();
                                    this.onCancel();
                                }}
                            />
                            <Typography color="textPrimary">
                                {typeof catalog != "undefined"
                                    ? catalog.name
                                        ? catalog.name
                                        : "Nuevo Catálogo"
                                    : ""}
                            </Typography>
                        </Breadcrumbs>
                    }
                    buttonName={buttonName}
                    buttonTooltip={buttonTooltip}
                    buttonType={buttonType}
                    startIcon={startIcon}
                    onCancel={() => this.onCancel()}
                    onSave={() => this.onSave()}
                    fullScreen
                    hasErrors={
                        countCatalogKeysErrors > 0 || countCatalogRecordsErrors > 0 || emptyTable
                            ? true
                            : false
                    }
                    hasChanges={hasChanges}
                    alignButtonRight={alignButtonRight}
                    disableCancel={newCatalog}
                    noActions={readOnly}
                >
                    <Grid container justifyContent="space-around">
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems="flex-start">
                                <Grid item sm={12} xs={12}>
                                    <Grid container spacing={1} alignItems="flex-start">
                                        <Grid item sm={3} xs={12}>
                                            <label>
                                                <span style={{ color: "red" }}>* </span>&nbsp;Nombre
                                                del catálogo
                                            </label>
                                        </Grid>
                                        <Grid item sm={9} xs={12}>
                                            <TextField
                                                name="name"
                                                disabled={!tempCatalog.erasable}
                                                value={tempCatalog ? tempCatalog.name : ""}
                                                placeholder="Nombre"
                                                variant="outlined"
                                                multiline
                                                size="small"
                                                error={catalogKeysErrors["name"]}
                                                required={true}
                                                onChange={(event) =>
                                                    this.catalogChangeHandler(
                                                        event.target.name,
                                                        event.target.value,
                                                        "text-field",
                                                        true
                                                    )
                                                }
                                                helperText={
                                                    catalogKeysErrors["name"]
                                                        ? errorHelper["name"]
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="flex-start">
                                <Grid item sm={12} xs={12}>
                                    <Grid container spacing={1} alignItems="flex-start">
                                        <Grid item sm={3} xs={12}>
                                            <label>
                                                <span style={{ color: "red" }}>* </span>
                                                &nbsp;Registros del catálogo
                                            </label>
                                        </Grid>
                                        <Grid item sm={9} xs={12}>
                                            <Table className="hideLastColumn">
                                                <thead>
                                                    <tr>
                                                        {this.catalogRecordsKeys.map(
                                                            (items, index) => (
                                                                <th key={index}>{items.alias}</th>
                                                            )
                                                        )}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {typeof tempCatalog.catalog == "undefined" ||
                                                    tempCatalog.catalog.length === 0 ? (
                                                        <tr>
                                                            {Array.from(Array(2).keys()).map(
                                                                (header, index) => (
                                                                    <td
                                                                        key={index}
                                                                        style={{ padding: "10px" }}
                                                                    ></td>
                                                                )
                                                            )}
                                                            <td width="1%"></td>
                                                        </tr>
                                                    ) : (
                                                        tempCatalog.catalog.map((record, index) => (
                                                            <tr key={index}>
                                                                <td width="20%">
                                                                    <TextField
                                                                        disabled={
                                                                            !tempCatalog.erasable
                                                                        }
                                                                        name="key"
                                                                        value={record["key"]}
                                                                        placeholder="Nombre"
                                                                        variant="outlined"
                                                                        multiline
                                                                        fullWidth
                                                                        size="medium"
                                                                        error={
                                                                            catalogRecordsErrors[
                                                                                index
                                                                            ]
                                                                                ? catalogRecordsErrors[
                                                                                      index
                                                                                  ]["key"]
                                                                                : false
                                                                        }
                                                                        required={true}
                                                                        onChange={(event) =>
                                                                            this.catalogRecordsChangeHandler(
                                                                                event.target.name,
                                                                                event.target.value,
                                                                                "text-field",
                                                                                true,
                                                                                index
                                                                            )
                                                                        }
                                                                        // helperText={catalogRecordsErrors[index]["key"] ? errorHelperRecords[index]['key'] : ''}
                                                                        style={{
                                                                            padding:
                                                                                catalogRecordsErrors[
                                                                                    index
                                                                                ]["key"]
                                                                                    ? "15px 0"
                                                                                    : null,
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td width="75%">
                                                                    <ChipInput
                                                                        //disabled={countCatalogRecordsErrors > 0 ? false : true}
                                                                        name="values"
                                                                        fullWidth
                                                                        value={record["values"]}
                                                                        placeholder="Añade opción"
                                                                        alwaysShowPlaceholder
                                                                        variant="outlined"
                                                                        size="small"
                                                                        error={
                                                                            catalogRecordsErrors[
                                                                                index
                                                                            ]
                                                                                ? catalogRecordsErrors[
                                                                                      index
                                                                                  ]["values"]
                                                                                : false
                                                                        }
                                                                        onAdd={(chip) =>
                                                                            this.catalogRecordsChangeHandler(
                                                                                "values",
                                                                                chip,
                                                                                "chip-editor",
                                                                                true,
                                                                                index
                                                                            )
                                                                        }
                                                                        onDelete={(
                                                                            chip,
                                                                            position
                                                                        ) =>
                                                                            this.catalogRecordsChangeHandler(
                                                                                "values",
                                                                                chip,
                                                                                "chip-editor",
                                                                                true,
                                                                                index,
                                                                                position
                                                                            )
                                                                        }
                                                                        newChipKeyCodes={[9]} //Tab
                                                                        fullWidthInput={true}
                                                                        id={"chip-input-catalog"}
                                                                    />
                                                                    {catalogRecordsErrors[index][
                                                                        "values"
                                                                    ] ? (
                                                                        <span
                                                                            style={{
                                                                                color: "#f44336",
                                                                                fontSize: "12px",
                                                                            }}
                                                                        >
                                                                            {/* {errorHelperRecords[index]['values']} */}
                                                                        </span>
                                                                    ) : null}
                                                                </td>

                                                                <td>
                                                                    <ButtonConfirmDialog
                                                                        disabled={
                                                                            !tempCatalog.erasable
                                                                        }
                                                                        title={
                                                                            "¿Deseas eliminar el registro con clave: " +
                                                                            record.key +
                                                                            "?"
                                                                        }
                                                                        confirmName="Sí"
                                                                        cancelName="No"
                                                                        tooltip={
                                                                            "Eliminar registro"
                                                                        }
                                                                        color="secondary"
                                                                        size="small"
                                                                        onConfirm={() =>
                                                                            this.onDelete(index)
                                                                        }
                                                                        icon={
                                                                            <TrashCan
                                                                                width="20px"
                                                                                height="20px"
                                                                            />
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        {/* {
                                                catalogRecordsErrors.map(error => 
                                                    error.key ?
                                                    <Grid item xs={12}>
                                                        <span style={{color:'#f44336'}} >
                                                            * El nombre de la Clave ya está tomado
                                                        </span>
                                                    </Grid> 
                                                    : null
                                                    )
                                            } */}
                                        {takenValues.length > 0 ? (
                                            <Grid item xs={4}>
                                                <span style={{ color: "#f44336" }}>
                                                    * Los valores:{" "}
                                                    {takenValues.map((item, index) => (
                                                        <strong key={index}> {item} </strong>
                                                    ))}{" "}
                                                    <br />
                                                    ya están tomados
                                                </span>
                                            </Grid>
                                        ) : null}
                                        {
                                            <span style={{ color: "#f44336" }}>
                                                {errorHelperRecords["recordsTable"]}
                                            </span>
                                        }
                                        {countCatalogRecordsErrors > 0 ? (
                                            <Button
                                                startIcon={
                                                    <AgregarIcono
                                                        width="15px"
                                                        height="15px"
                                                        disabled={true}
                                                    />
                                                }
                                                disabled
                                                size="small"
                                            >
                                                Crear nuevo registro
                                            </Button>
                                        ) : (
                                            <Button
                                                startIcon={
                                                    <AgregarIcono width="15px" height="15px" />
                                                }
                                                disabled={!tempCatalog.erasable}
                                                color="primary"
                                                size="small"
                                                onClick={this.addNewCatalogItem}
                                            >
                                                Crear nuevo registro
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormModal>
            </div>
        );
    }
}

EditCatalogModal.contextType = AppContext;
