import React,{ useState, useContext } from 'react';
import { Link } from 'react-router-dom';

//Mui components
import {
    TextField,
    InputAdornment,
    Button as MuiButton,
  } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

//Icons
import { ReactComponent as IconUser } from '../../Icons/usuario_icono.svg'

//External libraries
import { isEmail } from "validator"; //https://github.com/validatorjs/validator.js/
import Swal from 'sweetalert2' //https://sweetalert2.github.io

//Custom Portal Components
import AppContext from '../../Context/AppContext';
import IntebotCatalog from '../IntebotCatalog';
import { authActions } from '../DataFunctions';

const errorHelperKeys = IntebotCatalog.errorHelper


const Button = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        //border: 0,
        color: 'white',
        height: 36,
        padding: '0 30px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&$disabled': {
            color: 'white',
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: 'none',
    },
}))(MuiButton);

// const FormControlLabel = withStyles(theme => ({
//     label: {
//         fontSize: '1em',
//         color: '#b2b2b2',
//     }
// }))(MuiFormControlLabel);

const Restorepassword = () => {

    //React Hooks
    const context = useContext(AppContext);
    const [state, setState] = useState({
         //Restore
        restorePasswordForm:{
            email:'',
        },
        restoreErrors:{
            email: false,
        },
        errorHelperRestore:{
            email: errorHelperKeys.removeError
        },
    });

    //======== Restore Password =======
    const sendRestorePasswordForm = async (restorePasswordForm) => {
        const { updateContextAttribute } = context;
        const { errorHelperRestore } = state;
        let errorHelperRestoreCopy = JSON.parse(JSON.stringify(errorHelperRestore))
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Restaurando contraseña...')
        let res = await authActions(restorePasswordForm, 'ForgotPassword', 'PasswordReset')
        // console.table(restorePasswordForm)
        // console.log('res :>> ', res);
        if(!res){
            errorHelperRestoreCopy['restoreForm'] = errorHelperKeys.resetPasswordError;
            updateContextAttribute('loadingDialog', false)
            updateContextAttribute('LoadingMessage', '')
            //Reset restore password
            setState({
                ...state,
                errorHelperRestore: errorHelperRestoreCopy
            })
            return
        }
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //Si no hay un código de respuesta lo mandamos al default
        //console.log('responseCode', responseCode)
        switch (responseCode) {
            case 0:
                let alertResult = await Swal.fire({
                    icon: 'success',
                    title: 'Verifica tu correo.',
                    text: 'Checa tu bandeja de entrada del correo registrado, no olvides en revisar tu bandeja de spam.',
                })
                if(alertResult){
                    window.location.href="/login"
                }
                break;
            case 1:
                errorHelperRestoreCopy['restoreForm'] = errorHelperKeys.accountNotExists;
                break;
            default:
                errorHelperRestoreCopy['restoreForm'] = errorHelperKeys.resetPasswordError;
                break;
        }
        setState({
            ...state,
            errorHelperRestore: errorHelperRestoreCopy
        })
        
    }
    
    const searchErrorsRestorePassword = () => {
        const { restorePasswordForm ,restoreErrors, errorHelperRestore } = state;
        //Copias de los Objetos a manejar
        let restoreErrorsCopy = JSON.parse(JSON.stringify(restoreErrors));
        let errorHelperRestoreCopy = JSON.parse(JSON.stringify(errorHelperRestore));
        //Iteramos el form en busca de errores
        for (const resetPasswordKey in restorePasswordForm) {
            const formValue = restorePasswordForm[resetPasswordKey]; //Valor actual que esta siendo iterado
            switch (resetPasswordKey) {
                case 'email':
                    //Si no es un email valido ponemos el error
                    let validEmail = isEmail(formValue);
                    if(!validEmail){
                        restoreErrorsCopy[resetPasswordKey] = true;
                        errorHelperRestoreCopy[resetPasswordKey] = errorHelperKeys.notEmail;
                        break;
                    }
                    //Sin errores
                    restoreErrorsCopy[resetPasswordKey] = false;
                    errorHelperRestoreCopy[resetPasswordKey] = errorHelperKeys.removeError;
                    break;
                default:
                    break;
            }
        }

        //iteramos el objeto de errores
        let restorePasswordFormHasErrors = false;
        for (const key in restoreErrorsCopy) {
            const errorValue = restoreErrorsCopy[key];
            //Si encontramos 1 error, ponemos la bandera en true y evitamos que se mande el form
            if(errorValue){
                restorePasswordFormHasErrors = true;
                break; 
            }
        }

        //Si no hay errores, mandamos el form al servicio
        if(!restorePasswordFormHasErrors) {
            sendRestorePasswordForm(restorePasswordForm)
        }

        //Guardamos los cambios de los objetos
        setState({
            ...state,
            restoreErrors: restoreErrorsCopy,
            errorHelperRestore: errorHelperRestoreCopy
        })
    }

    const handleRestorePasswordForm = (targetName, targetValue) => {
        const { restorePasswordForm, restoreErrors, errorHelperRestore } = state;
        let restorePasswordFormCopy = JSON.parse(JSON.stringify(restorePasswordForm));
        let restoreErrorsCopy = JSON.parse(JSON.stringify(restoreErrors));
        let errorHelperRestoreCopy = JSON.parse(JSON.stringify(errorHelperRestore));
        switch (targetName) {
            case "email":
                if(targetValue.length > 50){
                    //NO guardamos el campo
                    break;
                }
                //Guardamos el campo
                restorePasswordFormCopy[targetName] = targetValue;
                //Quitamos errores
                restoreErrorsCopy[targetName] = false;
                errorHelperRestoreCopy[targetName] = errorHelperKeys.removeError
                break;
            default:
                break;
        }
        //Guardamos las validaciones
        setState({
            ...state,
            restorePasswordForm: restorePasswordFormCopy,
            restoreErrors: restoreErrorsCopy,
            errorHelperRestore: errorHelperRestoreCopy
        })
    }


    const { restorePasswordForm, restoreErrors, errorHelperRestore } = state;
    return (
        <div>
            <header>
                <h2> Recuperar tu cuenta </h2>
                <h4> Introduce tu correo electrónico para buscar tu cuenta </h4>
            </header>
            {/* Errores del Restore Account */}
            <div style={{display: 'flex',justifyContent:'center'}} >
                <span style={{fontSize: '0.75rem',color:'red'}} >{errorHelperRestore['restoreForm']}</span> 
            </div>
            <div className='AuthInputsContainer'>
                <TextField
                    name="email"
                    value={restorePasswordForm.email} //Form Restore
                    variant="outlined"
                    size="small"
                    type="email"
                    onChange={(e) => handleRestorePasswordForm(e.target.name, e.target.value)} //handle Restore Password
                    placeholder="Correo electrónico"
                    error={restoreErrors.email} //restore errors
                    helperText={errorHelperRestore.email} //restore helper
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <div style={{
                                    width: '12px'
                                }} >
                                    <IconUser />
                                </div>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="restoreHelpContainer">
                <Link to="/login">Inicio de sesión</Link> 
            </div>
            <div className="authButtonContainer" >
                <Button onClick={() => searchErrorsRestorePassword()} >Restablecer contraseña</Button>
            </div>
        </div>
    );
}

export default Restorepassword;
