import React from "react";
import LaunchIcon from "@material-ui/icons/Launch";
import AttachFileIcon from "@material-ui/icons/AttachFile";

export default function MultiMediaComponent(props) {
    const { multiMediaURLArray, template, index } = props;

    const { URL = "", type = "" } = multiMediaURLArray?.[index] || {};
    const { multimediaUrl = "", fileLink = "", fileName = "", fileType = "" } = template;
    switch (true) {
        case type.includes("image") || fileType.includes("image"):
            return (
                <div key={URL || fileLink} className="multimedia-container">
                    <a href={multimediaUrl || fileLink} target="_blank">
                        <img
                            className="multimedia-container-image"
                            src={URL || fileLink}
                            alt="multimedia"
                        />
                        {fileName}
                    </a>
                </div>
            );
        case type.includes("video") || fileType.includes("video"):
            return (
                <div key={URL || fileLink} className="multimedia-container">
                    <a href={multimediaUrl || fileLink} target="_blank">
                        <video src={URL || fileLink} alt="multimedia" width={300} controls />
                        {fileName}
                    </a>
                </div>
            );
        case type.includes("pdf") || fileType.includes("pdf"):
            return (
                <div key={URL || fileLink} className="multimedia-container">
                    <iframe src={URL || fileLink} title="pdf" width={300} height={300}></iframe>
                    <a href={multimediaUrl || fileLink} target="_blank">
                        {" "}
                        Abrir PDF
                        <LaunchIcon width="15" />
                    </a>
                </div>
            );

        default:
            return (
                <>
                    <a href={multimediaUrl || fileLink} target="_blank" className="file-link">
                        <div className="multimedia-container-file">
                            <div className="file-icon">
                                <AttachFileIcon width="90px" />
                            </div>
                            <p className="file-name">{fileName}</p>
                        </div>
                    </a>
                </>
            );
    }
}
