import React from 'react';

const SearchNoResults = (props) => {
    const { searchQuery } = props
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <h3>No se han encontrado resultados para tu búsqueda ({searchQuery}).</h3>
        </div>
    )
}

export default SearchNoResults;
