import * as React from "react";

function TablaIcono(props) {
  return (
    <svg id="table-icon" aria-hidden="true" viewBox="0 0 22 22" {...props}>
      <path fill="currentColor" d="M20 6v14H6v2h16V6z"></path>
      <path
        fill="currentColor"
        d="M18 2H2v16h16V2zM9 16H4v-5h5v5zm7 0h-5v-5h5v5zm0-7H4V4h12v5z"
      ></path>
    </svg>
  );
}

export default TablaIcono;
