import React, { useState, useContext, useEffect, useRef }from 'react';

//MUI componets
import {
    Grid,
    Tooltip,
    Button,
    CircularProgress,
    Radio
} from '@material-ui/core/';

//Custom Portal Components
import SearchBox from '../common/SearchBox';
import IntentsTableComponent from './IntentsTableComponent';
import AppContext from '../../Context/AppContext';
import ErrorInFetch from '../common/ErrorInFetch';
import IntentModal from './Modals/IntentModal';
import IntentCreateNewModal from './Modals/IntentCreateNewModal';
import { actionsPLNManagement } from '../DataFunctions';
import RefreshButton from '../common/RefreshButton';
import SearchNoResults from '../common/SearchNoResults';

//Icons
import AgregarIcono from '../../Icons/AgregarIcono';
import { ReactComponent as TrashCan } from '../../Icons/trashcanIcon.svg';
import EditarIcono from '../../Icons/EditarIcono'

//Styles
import './styles/EditIntentsTable.css'

//External libraries
import Swal from 'sweetalert2' //https://sweetalert2.github.io

const EditIntentsTable = (props) => {
    //Context
    const { updateContextAttribute, getStorageData } = useContext(AppContext);
    const storageData = getStorageData(["exclusiveServices", "appId"])

    //State
    const [searchHasNoResults, setSearchHasNoResults] = useState(false);
    const [openIntentModal, setOpenIntentModal] = useState(false);
    const [openIntentCreateNewModal, setOpenIntentCreateNewModal] = useState(false);
    const [selectedIntent, setSelectedIntent] = useState("");
    const [currentIntent, setCurrentIntent] = useState({});
    const [searchQuery, setSearchQuery] = useState("");

    const initialIntentList = useRef([]);

    //Ref
    const defaultNewIntent = useRef({
        intentName: "",
        examples: "0",
        features:[],
        exampleList:[]
    });

    //Props
    const { 
        loading,
        errorInFetch,
        refreshPLNData,
        intentsList, 
        entityList,
        featureList,
        setIntentsList,
        intentListNames,
        appName,
        stateAppId,
        PLNtakenNamesList
     } = props

     //DidMount
    useEffect(() => {
        initialIntentList.current = intentsList;
    }, []);

    useEffect(() => {
        getSelectedIntent(selectedIntent)
    }, [selectedIntent])

    const getSelectedIntent = (selectedIntent) => {
        let currentIntent = defaultNewIntent.current;
        if(selectedIntent){
            currentIntent = intentsList.find((intent) => intent.intentName === selectedIntent)
        }
        setCurrentIntent(currentIntent)
    }

    const clearIntentTableState = () => {
        setSearchQuery("");
        setCurrentIntent({});
        setSelectedIntent("");
        setOpenIntentCreateNewModal(false);
        setOpenIntentModal(false);
        setSearchHasNoResults(false);
    }


    const deleteSelectedIntent = async () => {
        //Preguntar si quiere eliminar el intent
        let alert = await Swal.fire({
            text: '¿Deseas eliminar esta intención?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
            confirmButtonColor: '#27315d',
            denyButtonColor: '#27315d',
        })
        //Cancelamos el eliminado del intent
        if (alert.isDenied || alert.isDismissed) {
            return
        }

        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Eliminando intención...')

        let intentId = intentsList.find((intent) => intent.intentName === selectedIntent).intentId;
        let data = {
            intentId: intentId
        }
        if(storageData.exclusiveServices.processNaturalLenguage && storageData.appId){
            data["appID"] = storageData.appId
        }
        let res = await actionsPLNManagement(data, "deleteIntent");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: `La intención fue eliminada correctamente.`,
                });
                clearIntentTableState()
                refreshPLNData()
                break;
        
            default:
                let alert = {
                    open: true,
                    severity: 'error', //success, error, warning, info
                    message: 'Ocurrió un error al guardar los datos'
                }
                updateContextAttribute('alert', alert)
                break;
        }
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
    }

    const createNewIntent = async (intentName) => {
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Creando intención...')
        let data = {
            "intentName" : intentName
        }
        if(storageData.exclusiveServices.processNaturalLenguage && storageData.appId){
            data["appID"] = storageData.appId
        }
        let res = await actionsPLNManagement(data, "createIntent");
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: `La intención fue agregada correctamente.`,
                });
                clearIntentTableState()
                refreshPLNData()
                setOpenIntentCreateNewModal(false)
                break;
        
            default:
                let alert = {
                    open: true,
                    severity: 'error', //success, error, warning, info
                    message: 'Ocurrió un error al guardar los datos'
                }
                updateContextAttribute('alert', alert)
                break;
        }
    }

    const searchIntent = (searchText) => {
        setSearchHasNoResults(false)
        setSearchQuery(searchText)
        if(!searchText){
            setIntentsList(initialIntentList.current)
            return
        }
        let filteredIntentList = intentsList.filter((intent) => intent.intentName.toLowerCase().includes(searchText.toLowerCase()))
        setIntentsList(filteredIntentList)
        if(filteredIntentList.length === 0){
            setSearchHasNoResults(true)
        }
    }

    
    return (
        <React.Fragment>
            <h1>Intenciones {appName ? `- ${appName}` : ""}</h1>
            {/* ======== Modales de Intentes ======= */}
                <IntentModal
                    openIntentModal={openIntentModal}
                    setOpenIntentModal={setOpenIntentModal}
                    currentIntent={currentIntent}
                    entityList={entityList}
                    featureList={featureList}
                    refreshPLNData={refreshPLNData}
                    intentListNames={intentListNames}
                    clearIntentTableState={clearIntentTableState}
                    setSelectedIntent={setSelectedIntent}
                    PLNtakenNamesList={PLNtakenNamesList}
                    stateAppId={stateAppId}
                />
            {/* ======== Modal crear nuevo Intents ======== */}
                <IntentCreateNewModal
                    openIntentCreateNewModal={openIntentCreateNewModal}
                    setOpenIntentCreateNewModal={setOpenIntentCreateNewModal}
                    createNewIntent={createNewIntent}
                    intentListNames={intentListNames}
                    PLNtakenNamesList={PLNtakenNamesList}
                />
            <Grid container>
                <header className='edit-intents-actions'>
                    <Tooltip title="Crear intención">
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setSelectedIntent("")
                                setOpenIntentCreateNewModal(true)
                            }} 
                            startIcon={<AgregarIcono width="15px" height="15px" />} 
                            size="small" 
                        > 
                            Crear 
                        </Button>
                    </Tooltip>
                    <Tooltip title="Editar intención">
                        <Button 
                            disabled={!Boolean(selectedIntent)}
                            onClick={() => {
                                setOpenIntentModal(true)
                            }} 
                            startIcon={<EditarIcono width="20px" height="20px" />} 
                            size="small" 
                        > 
                            Editar 
                        </Button>
                    </Tooltip>
                    <Tooltip title="Eliminar intención">
                        <Button disabled={!Boolean(selectedIntent)}  onClick={() => deleteSelectedIntent("Eliminar")} startIcon={<TrashCan width="15px" height="15px" />} size="small" > Eliminar </Button>
                    </Tooltip>
                    <RefreshButton
                        refreshFunction={() => {clearIntentTableState(); refreshPLNData();} }
                    />
                    <SearchBox
                        searchFunction={(searchQuery) => searchIntent(searchQuery)}
                        searchQuery={searchQuery}
                    />
                </header>
                <main className='intents-table'>
                    <header className='intents-table-header'>
                        <div className='radio-header'></div>
                        <h2 className='name-header'>Nombre</h2>
                        <h2 className='examples-header'>Ejemplo</h2>
                        <h2 className='features-header'>Valores</h2>
                    </header>
                    { intentsList.length === 0 && !loading && <div className='empty-intents-table'></div> }
                    { searchHasNoResults && <SearchNoResults searchQuery={searchQuery}/> }
                    {
                        loading ? 
                            <div className='center-loading-icon'>
                                <CircularProgress /> 
                            </div> 
                        : errorInFetch ? 
                            <ErrorInFetch
                                errorInFetch={errorInFetch}
                                refreshFunction={() => {clearIntentTableState(); refreshPLNData();} }
                            /> 
                        :
                            intentsList.map((intent, index) =>
                                <IntentsTableComponent
                                    key={index}
                                    {...intent}
                                    selectedIntent={selectedIntent}
                                    setSelectedIntent={setSelectedIntent}
                                />
                            )

                    }
                </main>
            </Grid>
        </React.Fragment>
    );
}

export default EditIntentsTable;
