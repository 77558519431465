import React,  { useState, useEffect } from 'react';

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
    IconButton,
    TextField,
    Grid,
    CircularProgress as MuiCircularProgress,
    Tooltip,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';

//Custom portal components
import CustomButton from "../../common/CustomButton";
import { errorHelper, entityTypes } from '../../IntebotCatalog';
import RegexEntityType from './RegexEntityType';

//Icons
import CloseIcon from '@material-ui/icons/Close';
import ListExample from '../../../Icons/List_Example_PLN.png';
import MLExample from '../../../Icons/ML_Example_PLN.png';
import RegexExample from '../../../Icons/regex.png'


//Styles
import '../styles/entitiesModal.css'

//External Libraries
import Cookies from 'js-cookie' //https://www.npmjs.com/package/js-cookie

//Footer styles
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        padding: theme.spacing(2),
        margin: '0',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
}))

//==== Dialog Styles =====
const Dialog = withStyles((theme) => ({
    root: {
        marginTop: '56px',
        [theme.breakpoints.up('md')]: {
            marginTop: '64px',
        },
    },
}))(MuiDialog)

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle)

const DialogContent = withStyles((theme) => ({
    root:{
        display:'flex',
        justifyContent:'center',
        padding: "23px"
    }

}))(MuiDialogContent)



const CreateNewEntityModal = (props) => {
    const classes = useStyles();

    const [tempEntity, setTempEntity] = useState({
        name: "",
        entityType: "Entity Extractor",
    })

    const [regexPattern, setRegexPattern] = useState("");

    const [createNewEntityErrors, setCreateNewEntityErrors] = useState({
        name: true,
    })
    const [createNewEntityErrorsHelper, setCreateNewEntityErrorsHelper] = useState({
        name: errorHelper.emptyField,
    })
    const [newEntityHasChanges, setNewEntityHasChanges] = useState(false)
    
    const { setCreateNewEntity, createNewEntity, PLNtakenNamesList, saveNewEntity } = props;
    const { name, entityType } = tempEntity;

    const handleEntityForm = (targetName, targetValue) => {
        const tempEntityCopy = JSON.parse(JSON.stringify(tempEntity))
        const createNewEntityErrorsCopy = JSON.parse(JSON.stringify(createNewEntityErrors))
        const createNewEntityErrorsHelperCopy = JSON.parse(JSON.stringify(createNewEntityErrorsHelper))
        switch (targetName) {
            case "name":
                //Evitamos que el cliente ponga espacios
                targetValue = targetValue.replace(/ /g,"")
                tempEntityCopy[targetName] = targetValue
                //remove current intent name from intentListNames
                let duplicateName = PLNtakenNamesList.filter(takenNameObj => takenNameObj.name.toLowerCase() === targetValue.toLowerCase())

                //Evitamos que se duplique el nombre de la entidad
                if (duplicateName.length > 0) {
                    createNewEntityErrorsCopy[targetName] = true
                    createNewEntityErrorsHelperCopy[targetName] = errorHelper.duplicateName
                    break;
                }

                //si el campo esta vacio
                if(!targetValue){
                    createNewEntityErrorsCopy[targetName] = true
                    createNewEntityErrorsHelperCopy[targetName] = errorHelper.emptyField
                    break;
                }
                
                //quitamos errores
                createNewEntityErrorsCopy[targetName] = false
                createNewEntityErrorsHelperCopy[targetName] = errorHelper.removeError
                break;
            case "entityType":
                tempEntityCopy[targetName] = targetValue
                if(targetValue === "Regex Entity Extractor"){
                    createNewEntityErrorsCopy["regexPattern"] = true
                    createNewEntityErrorsHelperCopy["regexPattern"] = errorHelper.emptyField
                    break;
                }
                //quitamos errores
                createNewEntityErrorsCopy["regexPattern"] = false
                createNewEntityErrorsHelperCopy["regexPattern"] = errorHelper.removeError
                //limpiamos el campo de regex
                setRegexPattern('')
                break;
            case "regexPattern":
                setRegexPattern(targetValue);
                if(!targetValue){
                    createNewEntityErrorsCopy["regexPattern"] = true
                    createNewEntityErrorsHelperCopy["regexPattern"] = errorHelper.emptyField
                    break;
                }
                //quitamos errores
                createNewEntityErrorsCopy[targetName] = false
                createNewEntityErrorsHelperCopy[targetName] = errorHelper.removeError
                break;
            default:
                break;
        }
        setNewEntityHasChanges(true)
        setTempEntity(tempEntityCopy)
        setCreateNewEntityErrors(createNewEntityErrorsCopy)
        setCreateNewEntityErrorsHelper(createNewEntityErrorsHelperCopy)
    }

    return (
        <React.Fragment>
            <Dialog
                open={createNewEntity}
                fullScreen
            >
                <DialogTitle disableTypography>
                    <h2>Crear entidad</h2>
                    <IconButton onClick={() => setCreateNewEntity(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid className='intent-modal-content' item xs={11} sm={8}>
                        <div>
                            <label className='top-label-field' >
                                Nombre de la entidad
                            </label>
                            <TextField
                                name="name"
                                value={name}
                                size='small'
                                type='small'
                                onChange={(event) => handleEntityForm(event.target.name, event.target.value)}
                                fullWidth
                                variant='outlined'
                                error={createNewEntityErrors['name']}
                                helperText={createNewEntityErrorsHelper['name']}
                            />
                        </div>
                        <div>
                            <label className='top-label-field' >
                                Tipo de entidad
                            </label>
                            <RadioGroup name='entityType' row value={entityType} onChange={(e) => handleEntityForm(e.target.name, e.target.value)}>
                                {
                                    entityTypes.map(type => 
                                        <FormControlLabel value={type.name} control={<Radio color="primary"/>} label={type.typeName} />
                                    )
                                }                                
                            </RadioGroup>
                            <span>{entityTypes?.find(type => type.name === entityType)?.typeDescription ?? ''}</span>
                            <div className='example-description-container' >
                                <hr />
                                <label className='top-label-field' >
                                    Ejemplo
                                </label>
                                {entityType === "Entity Extractor" && <img src={MLExample} alt="Ejemplo de Machine Learning" />}
                                {entityType === "Closed List Entity Extractor" && <img src={ListExample} alt="Ejemplo de Lista" />}
                                {entityType === "Regex Entity Extractor" && <img src={RegexExample} alt="Ejemplo de Regex" />}
                                <hr />
                            </div>
                            {
                                entityType === "Regex Entity Extractor" && 
                                    <RegexEntityType
                                        handleChange={handleEntityForm}
                                        entityErrors={createNewEntityErrors}
                                        entityErrorHelper={createNewEntityErrorsHelper}
                                        regexPattern={regexPattern}
                                    />
                            }
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.root}>
                        <CustomButton 
                            disabled={Object.values(createNewEntityErrors).some(error => error) || !newEntityHasChanges}
                            onClick={() => saveNewEntity(tempEntity.name, entityType, regexPattern)}
                            variant="contained" 
                        > 
                            Guardar entidad 
                        </CustomButton>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default CreateNewEntityModal;
