import React, { useContext, useState, useRef, useEffect } from 'react';

//MUI components
import { TextField, MenuItem, Grid, withStyles, CircularProgress, Button } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid';
import AppContext from '../Context/AppContext';

//Custom Portal Components
import PermissionUserInfoRow from '../components/ManagePermissionsComponents/PermissionUserInfoRow';
import MuiDialogActions from "@material-ui/core/DialogActions";
import ButtonConfirmDialog from "../components/common/ButtonConfirmDialog";
import { errorHelper } from '../components/IntebotCatalog';
import { authActions } from '../components/DataFunctions';
import RefreshButton from '../components/common/RefreshButton';
import AddNewPermissionModal from '../components/ManagePermissionsComponents/AddNewPermissionModal'; 

//Custom Portal Icons
import AgregarIcono from '../Icons/AgregarIcono';

//External Libraries
import { isEmail } from "validator"; //https://github.com/validatorjs/validator.js/

//Styles
import './StyledPages/ManagePermissions.css'
import Swal from 'sweetalert2';
import { Fragment } from 'react';

const columns = [
    { field: 'name', headerName: 'Nombre', width: 140, },
    { field: 'email', headerName: 'Correo', width: 250, },
    { field: 'role', headerName: 'Permiso',width: 220, },
    { field: 'status', headerName: 'Invitación',width: 90, },
    { field: 'delete', headerName: 'Eliminar',width: 80, },
];

const permissionsOptions = [
    { name: "Administrador", value: "admin" },
    { name: "Colaborador", value: "collaborator" },
    { name: "Lector", value: "reader" }
]

const DialogActions = withStyles({
    root: {
        margin: 0,
        padding: "5px",
        justifyContent: "center",
    },
})(MuiDialogActions);

const ManagePermissions = () => {
    
    const defaultNewUser = useRef({
        email: '',
        role: 'reader'
    })
    const rolesToUpdate = useRef([]);
    const rolesToDelete = useRef([]);
    const [roles, setRoles] = useState([])
    const [rolesRollBack, setRolesRolesRollBack] = useState([])
    const [loading, setLoading] = useState(false)
    const [errorInFetch, setErrorInFetch] = useState(false)
    const [permissionsTableHasErrors, setPermissionsTableHasErrors] = useState(false)
    const [tableErrorHelper, setTableErrorHelper] = useState('')
    const [newUserRole, setNewUserRole] = useState(defaultNewUser.current);
    const [rolesTableHasChanges, setRolesTableHasChanges] = useState(false);
    const [openNewPermissionModal, setOpenNewPermissionModal] = useState(false);
    const [newUserRoleError, setNewUserRoleError] = useState({
        email: false,
    })
    const [newUserRoleErrorHelper, setNewUserRoleErrorHelper] = useState({
        email: errorHelper.removeError,
    })

    const context = useContext(AppContext);
    const { getStorageData, updateContextAttribute } = context;
    let storageData = getStorageData(["currentSubscription", "email"])


    useEffect(() => {
        refreshRoles()
    }, [])

    const refreshRoles = () => {
        getRolesInfo()
        setRolesTableHasChanges(false)
        setPermissionsTableHasErrors(false)
        setTableErrorHelper(errorHelper.removeError)
        rolesToUpdate.current = []
        rolesToDelete.current = []
        setErrorInFetch(false)
    }

    const getRolesInfo = async () => {
        setLoading(true)
        let data = {
            "email": storageData.email,
            "subscriptionId" : storageData.currentSubscription.subscriptionId,
        }
        let res = await authActions(data, "GetInteBotRoles", "SubscriptionOperations")
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        let roles = []
        setLoading(false)
        switch (responseCode) {
            case 0:
                roles = res.data.response.data.roles.map((role) => {
                    return { ...role, name: `${role.name ?? ""} ${role.lastName ?? ""}`, status: role.isVerified ? "VERIFIED" : "PENDING_VERIFICATION" }
                })
                //Buscamos el index del usuario logueado para ponerlo de segundo en la lista
                let index = roles.findIndex((role) => role.email === storageData.email)
                if (index > 0) {
                    let emailRole = roles.splice(index, 1)
                    roles.splice(1, 0, emailRole[0])
                }

                break;
            case 2:
                Swal.fire({
                    icon: 'info', 
                    title: 'El únicamente el administrador de la subscripción puede realizar estos cambios.',
                })
                setErrorInFetch(true)
                break;
            default:
                setErrorInFetch(true)
                break;
        }
        setRoles(roles)
        setRolesRolesRollBack(roles)        
    }

    const handleNewUser = (targetName, targetValue) => {
        let newUserRoleCopy = JSON.parse(JSON.stringify(newUserRole))
        newUserRoleCopy[targetName] = targetValue
        setNewUserRole(newUserRoleCopy)
        setNewUserRoleError({
            email: false
        })
        setNewUserRoleErrorHelper({
            email: errorHelper.removeError
        })
    }

    const sendNewUserInvitation = async () => {
        let newUserRoleCopy = JSON.parse(JSON.stringify(newUserRole))
        //Antes de hacer la peticion validamos que sea un email válido
        let validEmail = isEmail(newUserRoleCopy.email)
        if(!validEmail){
            setNewUserRoleError({
                email: true
            })
            setNewUserRoleErrorHelper({
                email: errorHelper.notEmail
            })
            return
        }
        //Preparamos la info del servicio
        let data = {
            "subscriptionId" : storageData.currentSubscription.subscriptionId,
            "listRoles" : [newUserRoleCopy],
            "adminEmail": storageData.email
        }
        updateContextAttribute("loadingDialog", true)
        updateContextAttribute("LoadingMessage", "Mandando invitación...")
        let res = await authActions(data, "NewRole", "RoleManagement")
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //Si no hay un código de respuesta lo mandamos al default
        switch (responseCode) {
            case 6:
                //Si exitoso
                Swal.fire({
                    icon: 'success',
                    title: 'La invitación a sido mandada con éxito.',
                    text: 'Revisa la bandeja de entrada del correo seleccionado.',
                })
                updateContextAttribute("loadingDialog", false)
                updateContextAttribute("LoadingMessage", "")
                setNewUserRole(defaultNewUser.current)
                setOpenNewPermissionModal(false)
                break;
            case 8:
                //Si exitoso
                Swal.fire({
                    icon: 'info',
                    title: 'Este usuario ya pertenece a la suscripción',
                    text: 'Intenta con otro correo.',
                })
                updateContextAttribute("loadingDialog", false)
                updateContextAttribute("LoadingMessage", "")
                setNewUserRole(defaultNewUser.current)
                break;
            default:
                updateContextAttribute("loadingDialog", false)
                updateContextAttribute("LoadingMessage", "")
                updateContextAttribute("alert", {
                    open: true,
                    severity: 'error', //success, error, warning, info
                    message: 'Ocurrió un error al mandar la invitación, intenta de nuevo.'
                })
                break;
        }
        
    }

    const handlePermissionsSelector = (targetValue, id) => {
        let rolesCopy = JSON.parse(JSON.stringify(roles))
        let rolesToUpdateCopy = JSON.parse(JSON.stringify(rolesToUpdate.current))
        rolesCopy[id]['role'] = targetValue;
        setPermissionsTableHasErrors(false)
        setTableErrorHelper(errorHelper.removeError)
        let roleIndex = rolesToUpdateCopy.findIndex(rolUpdate => rolUpdate.id === id)
        let roleUpdateObt = {
            email: rolesCopy[id].email,
            role: targetValue
        }
        if(roleIndex > -1){
            rolesToUpdateCopy[roleIndex] = roleUpdateObt
        } else {
            rolesToUpdateCopy.push(roleUpdateObt)
        }
        rolesToUpdate.current = rolesToUpdateCopy
        setRoles(rolesCopy);
        setRolesTableHasChanges(true)
    }

    const sendRolesChanges = async () => {
        let rolesToUpdateCopy = JSON.parse(JSON.stringify(rolesToUpdate.current))
        let rolesToDeleteCopy = JSON.parse(JSON.stringify(rolesToDelete.current))
        updateContextAttribute("loadingDialog", true)
        updateContextAttribute("LoadingMessage", "Actualizando permisos...")
        let responseCodesArray = []
        if(rolesToUpdateCopy.length > 0){
            let data = {
                subscriptionId: storageData.currentSubscription.subscriptionId,
                listRoles: rolesToUpdateCopy,
                "adminEmail": storageData.email
            }
            let res = await authActions(data, "UpdateRole", "RoleManagement")
            let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //Si no hay un código de respuesta lo mandamos al default
            responseCodesArray.push(responseCode)
        }
        if(rolesToDeleteCopy.length > 0){
            let data = {
                subscriptionId: storageData.currentSubscription.subscriptionId,
                listRoles: rolesToDeleteCopy,
                "adminEmail": storageData.email
            }
            let res = await authActions(data, "DeleteRole", "RoleManagement")
            let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //Si no hay un código de respuesta lo mandamos al default
            responseCodesArray.push(responseCode)
        }
        switch (true) {
            case responseCodesArray.every(code => code === 0):
                //Si exitoso
                updateContextAttribute("alert", {
                    open: true,
                    severity: 'success', //success, error, warning, info
                    message: 'Los permisos se actualizaron correctamente'
                })
                updateContextAttribute("loadingDialog", false)
                updateContextAttribute("LoadingMessage", "")
                refreshRoles()
                break;
            default:
                updateContextAttribute("loadingDialog", false)
                updateContextAttribute("LoadingMessage", "")
                updateContextAttribute("alert", {
                    open: true,
                    severity: 'error', //success, error, warning, info
                    message: 'Hubo un error en actualizar alguno de los valores en los permisos'
                })
                break;
        }
    }

    const onCancel = () => {
        setRoles(rolesRollBack)
        setRolesTableHasChanges(false)
        setPermissionsTableHasErrors(false)
        setTableErrorHelper(errorHelper.removeError)
    }

    const handleDeleteUser = async (id) => {
        let alert = await Swal.fire({
            text: '¿Deseas eliminar este usuario?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
            confirmButtonColor: '#27315d',
            denyButtonColor: '#27315d',
        })
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return
        }
        let rolesCopy = JSON.parse(JSON.stringify(roles))
        let rolesToDeleteCopy = JSON.parse(JSON.stringify(rolesToDelete.current))
        let rolesToUpdateCopy = JSON.parse(JSON.stringify(rolesToUpdate.current))
        let deletedRole = rolesCopy.splice(id, 1)[0]
        let roleDeleteObj = {
            email: deletedRole.email,
            role: deletedRole.role
        }
        rolesToDeleteCopy.push(roleDeleteObj)
        let deletedRoleInUpdateIndex = rolesToUpdateCopy.findIndex(role => role.email === deletedRole.email)
        if(deletedRoleInUpdateIndex > -1 ){
            rolesToUpdateCopy.splice(deletedRoleInUpdateIndex, 1)
            rolesToUpdate.current = rolesToUpdateCopy
        }
        rolesToDelete.current = rolesToDeleteCopy
        setRoles(rolesCopy)
        setRolesTableHasChanges(true)
    }

    const askConfirm = async () => {
        let alert = await Swal.fire({
            text: '¿Deseas salir?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
            confirmButtonColor: '#27315d',
            denyButtonColor: '#27315d',
        })
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return
        }
        closeInviteNewUser()
    }

    const closeInviteNewUser = () => {
        setNewUserRole(defaultNewUser.current)
        setOpenNewPermissionModal(false)
    }

    return (
        <div className='manage-permissions-container'>
            {/* ===== Modales === */}
            <AddNewPermissionModal
                openNewPermissionModal={openNewPermissionModal}
                newUserRole={newUserRole}
                newUserRoleError={newUserRoleError}
                handleNewUser={handleNewUser}
                newUserRoleErrorHelper={newUserRoleErrorHelper}
                sendNewUserInvitation={sendNewUserInvitation}
                askConfirm={askConfirm}
                closeInviteNewUser={closeInviteNewUser}
                permissionsOptions={permissionsOptions}
            />
            <div className='manage-permissions-table-container' >
                {
                    loading ?
                        <div className='center-loading-icon'>
                            <CircularProgress />
                        </div>
                    : errorInFetch ? (
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <span>Hubo un error, intenta más tarde.</span>
                            <RefreshButton
                                refreshFunction={refreshRoles}
                            />
                        </div>
                    ) :
                    ( <div>
                        <div>
                            <div className='manage-users-page-header'>
                                <h3>Usuarios</h3>
                                <RefreshButton
                                    refreshFunction={refreshRoles}
                                />
                            </div>
                            <div className='manage-users-table-container'>
                                <header className='manage-users-table-header'>
                                    {
                                        columns.map((column, index) => 
                                            <span className={columns.length - 1 === index ? 'manage-users-header-label' : 'manage-users-header-label-border' } style={{width: column.width, padding: "15px" }} >{column.headerName}</span>
                                            )
                                    }
                                </header>
                                <main style={{border: permissionsTableHasErrors ? "1px #f44336 solid" : ""}} >
                                    {
                                        roles.map((row, index) => 
                                                <PermissionUserInfoRow
                                                    {...row}
                                                    columns={columns}
                                                    handlePermissionsSelector={handlePermissionsSelector}
                                                    handleDeleteUser={handleDeleteUser}
                                                    id={index}
                                                    permissionsOptions={permissionsOptions}
                                                    currentUserEmail={storageData.email}
                                                />
                                            )
                                    }
                                </main>
                                {
                                    permissionsTableHasErrors && <span
                                        style={{
                                            color: '#f44336',
                                            fontSize: '0.75rem',
                                        }}>
                                        {tableErrorHelper}
                                    </span>
                                }
                                <Grid container justifyContent="flex-end" >
                                    <Button disabled={loading || errorInFetch} onClick={() => setOpenNewPermissionModal(true)} startIcon={<AgregarIcono width="15px" height="15px" />} size="small" > Agregar usuario a la suscripción </Button>
                                </Grid>
                            </div>
                            {/* Botones guardar y cancelar */}
                            <DialogActions className="settings__action-btn--position">
                                <ButtonConfirmDialog
                                    buttonName="Cancelar"
                                    title="¿Estás seguro que deseas cancelar tus cambios?"
                                    confirmName="Sí"
                                    cancelName="No"
                                    variant="contained"
                                    onConfirm={() => onCancel()}
                                    disabled={!rolesTableHasChanges}
                                >
                                    Se revertirán todas las modificaciones realizadas.
                                </ButtonConfirmDialog>
                                <ButtonConfirmDialog
                                    buttonName="Publicar"
                                    title="¿Confirmas que deseas guardar tus cambios?"
                                    confirmName="Sí"
                                    cancelName="No"
                                    variant="contained"
                                    color="primary"
                                    onConfirm={() => {
                                        sendRolesChanges();
                                    }}
                                    disabled={!rolesTableHasChanges || permissionsTableHasErrors}
                                >
                                    <p style={{ marginTop: "3px" }}>
                                        Esta acción guardará de manera temporal las
                                        modificaciones realizadas.
                                        <br />
                                        Será necesario publicar los cambios para que se
                                        apliquen en InteBot.
                                    </p>
                                </ButtonConfirmDialog>
                            </DialogActions>
                        </div>
                    </div> )
                }
            </div>
        </div>
    );
}

export default ManagePermissions;
