import React, { Component, Fragment, useContext } from "react";

//Material UI components
import {
    Grid,
    withStyles,
    TextField,
    MenuItem,
    Switch,
    CircularProgress,
} from "@material-ui/core/";
import MuiDialogActions from "@material-ui/core/DialogActions";

//Portal catalogs
import AppContext from "../Context/AppContext";

//Custom Portal components
import ButtonConfirmDialog from "../components/common/ButtonConfirmDialog";
import CustomAlert from "../components/common/Alerts/CustomAlert";

//External libraries
import {
    enable as enableDarkMode,
    disable as disableDarkMode,
    isEnabled as isDarkReaderEnabled,
} from "darkreader"; //https://github.com/darkreader/darkreader

//Styles
import "./StyledPages/Settings.css";
const DialogActions = withStyles({
    root: {
        margin: 15,
        padding: "10px",
        justifyContent: "center",
    },
})(MuiDialogActions);

// const
const optionsPersonality = [
    {
        value: "01",
        text: "Profesional",
        name: "Personalidad_Profesional",
        displayName: "qna_chitchat_Professional.xlsx",
    },
    {
        value: "02",
        text: "Amigable",
        name: "Personalidad_Amigable",
        displayName: "qna_chitchat_Friendly.xlsx",
    },
    { value: false, text: "Ninguna", name: "None" },
];
const optionsVoiceTone = [
    { value: false, text: "Masculino" },
    { value: true, text: "Femenino" },
];

class Settings extends Component {
    state = {
        hasChanges: false,
        hasChangesPersonality: false,
        hasChangesVoice: false,
        alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
        },
        customChatbot: {
            personality: "",
            lastPersonality: "",
            femaleVoice: false,
        },
        isAudioAvailable: false,
        loading: false,
    };

    async componentDidMount() {
        const { getStorageData } = this.context;
        let storageData = getStorageData(["exclusiveServices"]);
        if (
            storageData.exclusiveServices.processNaturalLenguage ||
            storageData.exclusiveServices.sendCampaigns
        )
            return;
        this.setState({
            loading: true,
        });
        let { fetchKnowledgeBaseSources } = this.context;
        await fetchKnowledgeBaseSources();
        await this.assignData();

        this.setState({
            loading: false,
        });
    }

    async assignData() {
        let { kbFileSources, messages, getStorageData } = this.context;
        // console.log('assignData kbFileSources :>> ', kbFileSources);
        const { BOT_VOICE_MANAGEMENT } = messages;

        let storageData = getStorageData(["subscriptionModule"]);
        let { subscriptionModule } = storageData;

        let personality = false;
        let lastPersonality = false;
        if (kbFileSources.length > 0) {
            // filtramos las fuentes para dejar solo los documentos relacionados con la personalidad
            let filtered = kbFileSources.filter((value) => {
                return (
                    value.displayName &&
                    (value.displayName.includes("Personalidad_Amigable") ||
                        value.displayName.includes("Personalidad_Profesional"))
                );
            });

            if (filtered.length > 0) {
                // obtenemos el valor equivalente a la personalidad
                let found = optionsPersonality.filter(
                    (option) => option.name === filtered[0].displayName
                );
                personality = found[0].value;
                lastPersonality = personality;
            }
        }

        this.setState({
            ...this.state,
            customChatbot: {
                personality: personality,
                lastPersonality,
                femaleVoice: subscriptionModule.processAudio
                    ? BOT_VOICE_MANAGEMENT.femaleVoice
                    : false,
            },
            isAudioAvailable: subscriptionModule.processAudio,
            hasChanges: false,
        });
    }

    handleChanges = (target, value) => {
        const { customChatbot, hasChangesPersonality, hasChangesVoice } = this.state;
        let customChatbotCopy = JSON.parse(JSON.stringify(customChatbot));
        let hasChangesPersonalityCopy = JSON.parse(JSON.stringify(hasChangesPersonality));
        let hasChangesVoiceCopy = JSON.parse(JSON.stringify(hasChangesVoice));

        switch (target) {
            case "personality":
                customChatbotCopy[target] = value; // guardamos el campo
                hasChangesPersonalityCopy = true;
                break;

            case "femaleVoice":
                customChatbotCopy[target] = value; // guardamos el campo
                hasChangesVoiceCopy = true;
                break;

            default:
                break;
        }

        this.setState({
            ...this.state,
            customChatbot: customChatbotCopy,
            hasChangesPersonality: hasChangesPersonalityCopy,
            hasChangesVoice: hasChangesVoiceCopy,
            hasChanges: true,
        });
    };

    handleDarkMode = (targetValue) => {
        this.setState({
            isDarkModeEnabled: targetValue,
        });
        if (targetValue) {
            enableDarkMode({
                brightness: 120,
                contrast: 100,
                sepia: 10,
            });
            document.cookie = "darkMode=true";
            return;
        }
        disableDarkMode();
    };

    /**
     * @name onSave
     * @description función para enviar los cambios del tono de voz y personalidad
     */
    onSave = async () => {
        const { customChatbot, hasChangesPersonality, hasChangesVoice } = this.state;
        const { updateContextAttribute, updateCustomization, messages, kbFileSources } =
            this.context;

        let messagesCopy = JSON.parse(JSON.stringify(messages));
        // Agregamos el cambio del tono de voz a messages
        messagesCopy.BOT_VOICE_MANAGEMENT = { femaleVoice: customChatbot.femaleVoice };

        updateContextAttribute("messages", messagesCopy);

        // Función para realizar los cambios de la personalización
        let res = await updateCustomization(customChatbot, hasChangesPersonality, hasChangesVoice);
        if (res) {
            if (hasChangesPersonality) {
                let kbFileSourcesCopy = JSON.parse(JSON.stringify(kbFileSources));
                // Buscamos los archivos que corresponden a la personalidad y los borramos que las fuentes actuales.
                kbFileSources.forEach((elem, i) => {
                    if (elem.displayName && elem.displayName.includes("Personalidad_")) {
                        kbFileSourcesCopy.splice(i, 1);
                    }
                });

                let fileObj = {
                    displayName: "",
                    source: "",
                };

                // Revisamos si la personalidad corresponde a una listada y asignamos los valores al objeto
                if (customChatbot.personality === "01") {
                    fileObj.displayName = "Personalidad_Profesional";
                    fileObj.source =
                        "https://stgaccintebotmultimedia.blob.core.windows.net/multimedia/qna_chitchat_Professional.xlsx";
                } else {
                    fileObj.displayName = "Personalidad_Amigable";
                    fileObj.source =
                        "https://stgaccintebotmultimedia.blob.core.windows.net/multimedia/qna_chitchat_Friendly.xlsx";
                }

                if (fileObj.displayName) {
                    // Agregamos la personalidad a las fuentes actuales
                    kbFileSourcesCopy.push(fileObj);
                    updateContextAttribute("kbFileSources", kbFileSourcesCopy);
                }
            }
        }
        this.setState({
            ...this.state,
            hasChanges: false,
            hasChangesPersonality: false,
            hasChangesVoice: false,
        });
    };

    onCancel = () => {
        this.assignData();
    };

    handleCloseAlert = (event, reason) => {
        this.setState({
            ...this.state,
            alert: {
                open: false,
                message: "",
            },
        });
    };

    render() {
        const { handleDarkMode, isDarkModeEnabled, getStorageData, readOnly } = this.context;
        const { hasChanges, customChatbot, isAudioAvailable, loading } = this.state;

        let storageData = getStorageData(["exclusiveServices"]);

        return (
            <Fragment>
                {loading ? (
                    <Grid justifyContent="center" container>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Fragment>
                        <CustomAlert
                            open={this.state.alert.open}
                            severity={this.state.alert.severity}
                            message={this.state.alert.message}
                            handleCloseAlert={() => this.handleCloseAlert()}
                        />

                        {!storageData.exclusiveServices.processNaturalLenguage &&
                            !storageData.exclusiveServices.sendCampaigns && (
                                <Grid
                                    direction="column"
                                    container
                                    alignItems="center"
                                    className="settings__container"
                                    id="settings__container"
                                >
                                    <div>
                                        <h1>Personalización de Intebot</h1>
                                    </div>
                                    <Grid item xs={11} sm={10} style={{ fontSize: "16px" }}>
                                        <label className="top-label-field">Personalidad</label>
                                        <TextField
                                            select
                                            name={"personality"}
                                            value={customChatbot.personality}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            onChange={(event) =>
                                                this.handleChanges(
                                                    event.target.name,
                                                    event.target.value
                                                )
                                            }
                                            style={{ marginBottom: "20px" }}
                                        >
                                            {optionsPersonality.map((option, i) => (
                                                <MenuItem key={i} value={option.value}>
                                                    {optionsPersonality[i].text}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {isAudioAvailable && (
                                            <Fragment>
                                                <label className="top-label-field">
                                                    Tono de voz
                                                </label>
                                                <TextField
                                                    select
                                                    name={"femaleVoice"}
                                                    value={customChatbot.femaleVoice}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(event) =>
                                                        this.handleChanges(
                                                            event.target.name,
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    {optionsVoiceTone.map((option, i) => (
                                                        <MenuItem key={i} value={option.value}>
                                                            {optionsVoiceTone[i].text}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px",
                                fontSize: "0.875rem",
                                flexDirection: "column",
                                margin: "0 30px",
                            }}
                        >
                            <h1>Configuración del Portal Intebot</h1>
                            <Grid item xs={11} sm={10}>
                                <label>Fondo oscuro</label>
                                <Switch
                                    checked={isDarkModeEnabled}
                                    onChange={(e) => handleDarkMode(e.target.checked)}
                                    name="changeDarkMode"
                                    color="primary"
                                />
                            </Grid>
                        </div>

                        {/* Botones guardar y cancelar */}
                        {(!storageData.exclusiveServices.processNaturalLenguage &&
                            !storageData.exclusiveServices.sendCampaigns) ||
                            (readOnly && (
                                <DialogActions className="settings__action-btn--position">
                                    <ButtonConfirmDialog
                                        buttonName="Cancelar"
                                        title="¿Estás seguro que deseas cancelar tus cambios?"
                                        confirmName="Sí"
                                        cancelName="No"
                                        variant="contained"
                                        onConfirm={() => this.onCancel()}
                                        disabled={!hasChanges}
                                    >
                                        Se revertirán todas las modificaciones realizadas.
                                    </ButtonConfirmDialog>
                                    <ButtonConfirmDialog
                                        buttonName="Publicar"
                                        title="¿Confirmas que deseas guardar tus cambios?"
                                        confirmName="Sí"
                                        cancelName="No"
                                        variant="contained"
                                        color="primary"
                                        onConfirm={() => {
                                            this.onSave();
                                        }}
                                        disabled={!hasChanges}
                                    >
                                        <p style={{ marginTop: "3px" }}>
                                            Esta acción guardará de manera temporal las
                                            modificaciones realizadas.
                                            <br />
                                            Será necesario publicar los cambios para que se apliquen
                                            en InteBot.
                                        </p>
                                    </ButtonConfirmDialog>
                                </DialogActions>
                            ))}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

Settings.contextType = AppContext;

export default Settings;
