import React, { useState, useContext, useEffect } from "react";

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
    Grid,
    IconButton,
    CircularProgress,
    TextField,
    MenuItem,
    Switch,
    Tooltip,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";

//External libraries
import { DropzoneAreaBase } from "material-ui-dropzone"; // https://yuvaleros.github.io/material-ui-dropzone/
import { DropzoneArea } from "material-ui-dropzone"; // https://yuvaleros.github.io/material-ui-dropzone/
import Swal from "sweetalert2";

//Custom portal components
import CustomButton from "../common/CustomButton";
import { whatsAppCampaignActions, blobStorage } from "../DataFunctions";
import AppContext from "../../Context/AppContext";
import MultiMediaComponent from "./MultiMediaComponent";
import IntebotCatalog from "../IntebotCatalog";

//Icons
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as IconCSV } from "../../Icons/csv-icon.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

//styleSheets
import "./Styles/uploadCampaingsPhoneNumbers.css";

const birdAcceptedFiles = IntebotCatalog.birdAcceptedFiles;

//Footer styles
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "fixed",
        bottom: "0",
        flex: "0 0 auto",
        width: "100%",
        left: "0",
        zIndex: "99",
        backgroundColor: "white",
        padding: theme.spacing(2),
        margin: "0",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
}));

const Dialog = withStyles((theme) => ({
    root: {
        marginTop: "56px",
        [theme.breakpoints.up("md")]: {
            marginTop: "64px",
        },
    },
}))(MuiDialog);

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "50px",
    },
}))(MuiDialogContent);

// const errorHelperKeys = IntebotCatalog.errorHelper;

const acceptedFiles = [".csv"];
const selectSource = [
    { value: "file", alias: "Archivo de contactos" },
    { value: "table", alias: "Contactos existentes" },
];

const columns = [
    { field: "name", headerName: "Nombre", flex: 1 },
    { field: "phone", headerName: "Número de teléfono", flex: 1 },
];

const localizedTextsMap = {
    noRowsLabel: "No hay contactos para mostrar",
    footerRowSelected: (count) => `${count.toLocaleString()} contacto(s) seleccionado(s)`,
    columnHeaderSortIconLabel: "Ordenar columna",
};

const Uploadcampaingsphonenumbers = (props) => {
    const [state, setState] = useState({
        fileObjects: [],
        contacts: [],
        openLoading: false,
        Loading: false,
        dialogTitle: "",
        dialogMessage: "",
        LoadingSucceeded: false,
        LoadingFailed: false,
        hasChanges: false,
        contactSource: "file",
        selectedContacts: [],
        template: null,
        templateIndex: null,
        replaceFile: false,
        fileBase64: "",
        uploadedFile: null,
    });

    //Valores del storage
    const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
    let outgoingMessageDayLimit = remeberAuthCredentials
        ? parseInt(localStorage.getItem("outgoingMessageDayLimit"))
        : parseInt(sessionStorage.getItem("outgoingMessageDayLimit"));
    let outgoingMessageDayCounter = remeberAuthCredentials
        ? parseInt(localStorage.getItem("outgoingMessageDayCounter"))
        : parseInt(sessionStorage.getItem("outgoingMessageDayCounter"));
    let outgoingMessageLimit = remeberAuthCredentials
        ? parseInt(localStorage.getItem("outgoingMessageLimit"))
        : parseInt(sessionStorage.getItem("outgoingMessageLimit"));
    let outgoingMessageCounter = remeberAuthCredentials
        ? parseInt(localStorage.getItem("outgoingMessageCounter"))
        : parseInt(sessionStorage.getItem("outgoingMessageCounter"));

    //state, props
    const context = useContext(AppContext);
    const { updateContextAttribute } = context;

    const {
        openUploadPhoneNumbersForm,
        handleOpenSendCampaing,
        selectedTemplate,
        handleDialogAction,
        refreshTemplateList,
        intebotContacts,
        multiMediaURLArray,
        templateList,
    } = props;

    const {
        fileObjects,
        dialogTitle,
        openLoading,
        Loading,
        LoadingSucceeded,
        LoadingFailed,
        dialogMessage,
        contacts,
        hasChanges,
        contactSource,
        selectedContacts,
        template,
        templateIndex,
        replaceFile,
        fileBase64,
        uploadedFile,
    } = state;

    const classes = useStyles();

    useEffect(() => {
        updateContextAttribute("sendCampaingsHasChanges", hasChanges);
    }, [hasChanges]);

    useEffect(() => {
        resetForm();
    }, [contactSource]);

    useEffect(() => {
        let templateIndex = templateList.findIndex(
            (template) => template.templateName === selectedTemplate
        );
        setState({
            ...state,
            template: templateList[templateIndex],
            templateIndex: templateIndex,
        });
    }, [openUploadPhoneNumbersForm]);

    const convertCSVtoJSON = (text) => {
        const delimiter = ",";
        const tableHeader = text.slice(0, text.indexOf("\n")).split(delimiter); //Obtenemos el encabezado de la tabla contando desde el inicio del archivo hasta encontrar el primer \n y se paramos el arreglo por comas
        const tableRows = text.slice(text.indexOf("\n") + 1).split("\n"); //Obtenemos las filas, contando apartir de la segunda linea y separamos el arreglo por cada \n
        //buscamos todos los \r o \n que esten en el arreglo, los sustituimos por "" y los eliminamos
        let filteredTableHeader = tableHeader
            .map((header) => header.replace(/(\r\n|\n|\r)/gm, ""))
            .filter((header) => header);
        let filteredTableRows = tableRows
            .map((row) => row.replace(/(\r\n|\n|\r)/gm, ""))
            .filter((row) => row);
        //recorremos ambos arreglos sin ningun "\r" o "\n" y creamos la estructura necesaria para formar el JSON
        const fileInJSON = filteredTableRows.map((row) => {
            const rowArray = row.split(delimiter).filter((row) => row); //separamos cada fila y quitamos espacios en blanco
            const JSON = filteredTableHeader.reduce((obj, header, index) => {
                //creamos un nuevo objeto juntando los headers con su respectivo valor
                obj[header] = rowArray[index] ? rowArray[index].trim() : "";
                if (
                    (template?.multimediaUrl && !filteredTableHeader.includes("header")) ||
                    (filteredTableHeader.includes("header") &&
                        !rowArray[index] &&
                        template?.multimediaUrl)
                ) {
                    obj["header"] = template?.multimediaUrl;
                }
                return obj;
            }, {});
            return JSON;
        });
        return fileInJSON;
    };

    const extractPhoneNumbers = async (arrayOfFiles) => {
        const fileName = arrayOfFiles[0].file.name;
        const textFromCSV = await arrayOfFiles[0].file.text(); //Sacamos el Objeto File y lo convertimos a texto
        try {
            //Esperamos que el archivo sea converitod a JSON
            var CSVInJSON = convertCSVtoJSON(textFromCSV);
            if (CSVInJSON.length <= 0) throw new Error("Contacts is empty");
            setState({
                ...state,
                fileObjects: arrayOfFiles,
                contacts: CSVInJSON,
                hasChanges: true,
            });
            let alert = {
                open: true,
                severity: "success", //success, error, warning, info
                message: `El archivo "${fileName}" fue agregado correctamente`,
            };
            updateContextAttribute("alert", alert);
        } catch (error) {
            let alert = {
                open: true,
                severity: "error", //success, error, warning, info
                message: `El archivo "${fileName}" contiene errores.`,
            };
            updateContextAttribute("alert", alert);
        }
    };

    const closeLoadingDialog = (openLoading) => {
        setState({
            ...state,
            openLoading: openLoading,
            LoadingSucceeded: false,
            LoadingFailed: false,
        });
    };

    const resetForm = () => {
        //reiniciamos el form
        setState({
            ...state,
            fileObjects: [],
            contacts: [],
            openLoading: false,
            Loading: false,
            dialogTitle: "",
            dialogMessage: "",
            LoadingSucceeded: false,
            LoadingFailed: false,
            hasChanges: false,
            selectedContacts: [],
        });
    };

    const onCancel = () => {
        resetForm();
        //Cerramos el form
        handleOpenSendCampaing(false);
    };

    const sendCampaing = async () => {
        let { contacts } = state;

        if (uploadedFile) {
            let res = await blobStorage(uploadedFile, "uploadFile&uploadType=campaigns");
            let resCode = res.data.code;
            switch (resCode) {
                case "00":
                    let imageURL = res.data.imageURL;
                    for (let index = 0; index < contacts.length; index++) {
                        const contactsJSON = contacts[index];
                        contactsJSON["header"] = imageURL;
                    }

                    //Actualizamos la URL del template
                    let updateURLBody = {
                        templateName: selectedTemplate,
                        url: imageURL,
                    };
                    let resUpdate = await whatsAppCampaignActions(
                        updateURLBody,
                        "updateUrlTemplate",
                        false
                    );

                    //if multimedia is blobStorage then delete the previous image
                    if (template.isBlobStorage) {
                        let fileName = template.multimediaUrl.replace(/\?.+/, "").split("/").pop();
                        let data = {
                            name: fileName,
                        };
                        let res = await blobStorage(data, "deleteFile&uploadType=campaigns");
                        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;
                        switch (responseCode) {
                            case 0:
                                //Continuar con la eliminación del template
                                break;
                            default:
                                //Waring al usuario que no se pudo eliminar el archivo multimedia
                                let alert = {
                                    open: true,
                                    severity: "error", //success, error, warning, info
                                    message: `El archivo "${fileName}" no se pudo eliminar del contenedor de multimedia.`,
                                };
                                updateContextAttribute("alert", alert);
                                break;
                        }
                    }

                    break;

                default:
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "No se pudo subir el archivo, intentalo de nuevo.",
                    });
                    return;
            }
        }

        const { handleOpenSendCampaing } = props;
        handleDialogAction(true, "Conectando con el servicio de mensajeria...", "loadingModal");
        let email = remeberAuthCredentials
            ? localStorage.getItem("email")
            : sessionStorage.getItem("email");
        //si el numero de contactos subidos es mayor a los mensajes disponibles, entonces recortamos el arreglo
        // if(contacts.length > outgoingMessageDayCounter){
        //     contacts = contacts.slice(0,outgoingMessageDayCounter)
        // }
        //preguntamos si se ha excedido de la cuota
        if (outgoingMessageCounter > 0 && outgoingMessageDayCounter > 0) {
            //mandamos el template a los numeros extraidos
            try {
                let sendCampaingData = {
                    templateName: selectedTemplate,
                    contacts: contacts,
                    email: email,
                };
                let res = await whatsAppCampaignActions(
                    sendCampaingData,
                    "sendWhatsappMessage",
                    true
                ); //Cambiar Funcion
                if (parseInt(res.data.response.code) === 0) {
                    resetForm();
                    handleOpenSendCampaing(false);
                    refreshTemplateList();
                    handleDialogAction(
                        true,
                        "Sus contactos fueron mandados al servicio de mensajería con éxito. Le mandaremos por correo el estatus de los mensajes enviados.",
                        "loadingSucceeded"
                    );
                } else {
                    handleDialogAction(
                        true,
                        "Hubo un error al mandar los mensajes, intente más tarde.",
                        "LoadingFailed"
                    );
                }
            } catch (error) {
                handleDialogAction(
                    true,
                    "Hubo un error al mandar los mensajes, intente más tarde.",
                    "LoadingFailed"
                );
            }
        } else {
            handleDialogAction(true, "Has excedido tu cuota de mensajes...", "LoadingFailed");
        }
    };

    const handleSourceSelector = (targetValue) => {
        setState({
            ...state,
            contactSource: targetValue,
        });
    };

    const handleSelectContacts = (selectedContacts) => {
        let intebotContactsCopy = JSON.parse(JSON.stringify(intebotContacts));
        let contacts = [];
        selectedContacts.forEach((selectedId) => {
            let selectedContactInfo = intebotContactsCopy.find(
                (contact) => contact.id === selectedId
            );
            delete selectedContactInfo["id"];
            contacts.push(selectedContactInfo);
        });
        setState({
            ...state,
            selectedContacts: selectedContacts,
            contacts: contacts,
        });
    };

    function readFile(file) {
        if (!file) return;
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Define what happens once the file is loaded
            reader.onload = function (event) {
                resolve(event.target.result); // Resolve with the file contents
            };

            // Define what happens if there's an error reading the file
            reader.onerror = function (event) {
                reject(event.target.error); // Reject with the error
            };

            // Start reading the file
            reader.readAsDataURL(file);
        });
    }

    const handleReplacefile = async (targetName, targetValue) => {
        const { fileBase64, uploadedFile, replaceFile } = state;
        let fileBase64Copy = JSON.parse(JSON.stringify(fileBase64));
        let uploadedFileCopy = JSON.parse(JSON.stringify(uploadedFile));
        let replaceFileCopy = JSON.parse(JSON.stringify(replaceFile));

        switch (targetName) {
            case "fileUpload":
                //save image in base64
                if (!targetValue) break;
                fileBase64Copy = await readFile(targetValue);
                let fileName = targetValue.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/ /g, "_");
                let fileType = targetValue.type;
                uploadedFileCopy = {
                    name: fileName,
                    body: fileBase64Copy.split(",")[1],
                    type: fileType,
                    length: targetValue.size,
                };
                break;

            case "replaceURL":
                if (targetValue) {
                    let askConfirm = await Swal.fire({
                        title: "¿Estás seguro que deseas remplazar el archivo?",
                        text: "Una vez la plantilla sea mandada se remplazará el archivo multimedia.",
                        showDenyButton: true,
                        confirmButtonText: "Si",
                        denyButtonText: `No`,
                        confirmButtonColor: "#27315d",
                        denyButtonColor: "#27315d",
                    });
                    //Cancelamos el guardado de cambios
                    if (askConfirm.isDenied || askConfirm.isDismissed) {
                        return;
                    }
                }
                //if !replaceFile clear fileBase64
                replaceFileCopy = targetValue;
                if (!targetValue) {
                    fileBase64Copy = "";
                    uploadedFileCopy = null;
                }

                break;

            default:
                break;
        }
        setState({
            ...state,
            replaceFile: replaceFileCopy,
            fileBase64: fileBase64Copy,
            uploadedFile: uploadedFileCopy,
        });
    };
    return (
        <div>
            <Dialog open={openLoading}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {Loading && <CircularProgress />}
                        {LoadingSucceeded && <CheckCircleIcon className="check-circle-icon" />}
                        {LoadingFailed && <ErrorIcon className="error-circle-icon" />}
                        <p>{dialogMessage}</p>
                        {Loading ? null : (
                            <CustomButton
                                onClick={() => closeLoadingDialog(false)}
                                variant="contained"
                            >
                                Ok
                            </CustomButton>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={openUploadPhoneNumbersForm} fullScreen>
                <DialogTitle disableTypography>
                    <h2>Envio de campañas</h2>
                    <IconButton
                        onClick={() => {
                            if (fileObjects.length > 0 || selectedContacts.length > 0)
                                handleDialogAction(
                                    true,
                                    "Los cambios realizados se perderán.",
                                    "askConfirmation",
                                    onCancel
                                );
                            else handleOpenSendCampaing(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ marginBottom: "70px" }}>
                    <Grid item xs={10}>
                        <div className="form-header">
                            <div>
                                <h2>{selectedTemplate}</h2>
                            </div>
                            <div>
                                <p>
                                    Mensajes disponibles de hoy {outgoingMessageDayCounter} de{" "}
                                    {outgoingMessageDayLimit}
                                </p>
                                <p>
                                    Mensajes disponibles totales {outgoingMessageCounter} de{" "}
                                    {outgoingMessageLimit}
                                </p>
                            </div>
                        </div>
                        <label className="top-label-field">Cargar otro archivo</label>
                        <Tooltip
                            title={`
                                Sustituye el archivo multimedia actual por uno nuevo.
                            `}
                        >
                            <HelpOutlineIcon color="primary" fontSize="small" />
                        </Tooltip>
                        <Switch
                            checked={replaceFile}
                            onChange={(event) => {
                                handleReplacefile("replaceURL", event.target.checked);
                            }}
                            color="primary"
                            name="checkedB"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <p>Archivo multimedia por enviar:</p>

                        {!replaceFile && (
                            <div className="multimedia-template-container">
                                <div className="multimedia-container">
                                    <MultiMediaComponent
                                        multiMediaURLArray={multiMediaURLArray}
                                        template={template}
                                        index={templateIndex}
                                    />
                                </div>
                            </div>
                        )}
                        {replaceFile && (
                            <DropzoneArea
                                acceptedFiles={birdAcceptedFiles}
                                dropzoneClass="dropZoneContainerTemplate"
                                filesLimit={1}
                                maxFileSize={20000000} //20 MB
                                showFileNames
                                dropzoneText={"Arrastra y suelta un archivo aquí o haz click"}
                                onChange={(file) => {
                                    handleReplacefile("fileUpload", file[0]);
                                }}
                                dropzoneParagraphClass="dropzoneParagraph"
                                previewGridClasses={{
                                    container: "previewGridContainer",
                                    item: "previewGridItem",
                                    image: "previewGridImage",
                                }}
                                //Mensajes del Drop Zone
                                getFileAddedMessage={(fileName) =>
                                    `El archivo ${fileName} fue agregado con éxito`
                                }
                                getFileLimitExceedMessage={(filesLimit) =>
                                    ` Número máximo de archivos alcanzados. Solamente se permiten ${filesLimit} archivos `
                                }
                                getFileRemovedMessage={(fileName) =>
                                    ` El archivo ${fileName} ha sido eliminado. `
                                }
                                getDropRejectMessage={(rejectedFile) =>
                                    ` Este archivo ${rejectedFile.name} no es compatible o Excede el máximo permitido de 4MB `
                                }
                                alertSnackbarProps={{
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                }}
                            />
                        )}
                        <div>
                            <p>Enviar a:</p>
                            <div className="source-selector-container">
                                <TextField
                                    select
                                    name={"chooseContactSource"}
                                    value={contactSource}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => {
                                        resetForm();
                                        handleSourceSelector(event.target.value);
                                    }}
                                >
                                    {selectSource.map((option, i) => (
                                        <MenuItem key={i} value={option.value}>
                                            {option.alias}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            {contactSource === "file" && (
                                <div className="center-drop-zone">
                                    <DropzoneAreaBase
                                        //files in state
                                        fileObjects={fileObjects}
                                        maxFileSize={4000000} //4mb
                                        acceptedFiles={acceptedFiles}
                                        showFileNames
                                        onAdd={(arrayOfFiles) => {
                                            resetForm();
                                            extractPhoneNumbers(arrayOfFiles);
                                        }}
                                        //Styles
                                        dropzoneClass="drop-csv-container"
                                        //dropzoneClass={ multiMediaErrors[index].file ? "dropZoneContainerWithError" : "dropZoneContainer"  }
                                        previewGridClasses={{
                                            container: "previewGridContainer",
                                            item: "preview-csv-grid",
                                            image: "previewGridImage",
                                        }}
                                        getPreviewIcon={() => {
                                            return (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        width: "50px",
                                                    }}
                                                >
                                                    <IconCSV className="icon-primary-color" />
                                                </div>
                                            );
                                        }}
                                        dropzoneText={fileObjects[0] ? "" : "Archivo"}
                                        dropzoneParagraphClass="dropzoneParagraph"
                                        showAlerts={["error", "info"]}
                                        alertSnackbarProps={{
                                            anchorOrigin: { vertical: "top", horizontal: "right" },
                                        }}
                                        getDropRejectMessage={(rejectedFile) =>
                                            ` Este archivo ${rejectedFile.name} no es compatible o Excede el máximo permitido de 4MB.`
                                        }
                                    />
                                </div>
                            )}
                            {contactSource === "table" && (
                                <div className="contacts-table-container">
                                    <div className="contacts-table">
                                        <DataGrid
                                            disableColumnMenu
                                            checkboxSelection
                                            rows={intebotContacts}
                                            columns={columns}
                                            onSelectionModelChange={(selectedContacts) => {
                                                handleSelectContacts(selectedContacts);
                                            }}
                                            selectionModel={selectedContacts}
                                            localeText={localizedTextsMap}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.root}>
                        <Grid container alignContent="flex-end">
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <CustomButton
                                            disabled={
                                                contacts.length <= 0 || (!fileBase64 && replaceFile)
                                            }
                                            onClick={async () => {
                                                let askConfirm = await Swal.fire({
                                                    title: "¿Estás seguro que deseas enviar la campaña?",
                                                    text: "Una vez enviada no se podrá cancelar hasta que se complete el envío.",
                                                    showDenyButton: true,
                                                    confirmButtonText: "Si",
                                                    denyButtonText: `No`,
                                                    confirmButtonColor: "#27315d",
                                                    denyButtonColor: "#27315d",
                                                });
                                                //Cancelamos el guardado de cambios
                                                if (askConfirm.isDenied || askConfirm.isDismissed) {
                                                    return;
                                                }
                                                handleDialogAction(
                                                    true,
                                                    "Conectando con el servicio de mensajeria...",
                                                    "loadingModal"
                                                );
                                                await refreshTemplateList();
                                                let randomTimer =
                                                    Math.floor(Math.random() * (10 - 1 + 1) + 1) *
                                                    1000;
                                                //console.log('randomTimer :>> ', randomTimer);
                                                setTimeout(() => {
                                                    sendCampaing(); //timeout
                                                }, randomTimer);
                                            }}
                                            variant="contained"
                                        >
                                            Enviar campaña
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Uploadcampaingsphonenumbers;
