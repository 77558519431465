import React, { memo, Fragment, useState, useEffect, useRef, useCallback } from "react";

//MUI components
import { Grid, TextField, IconButton, Button, Tooltip, InputAdornment } from "@material-ui/core";

//Icons
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";
import AgregarIcono from "../../Icons/AgregarIcono";
import CheckIcon from "@material-ui/icons/Check";
import ImageIcon from "@material-ui/icons/Image";
import EliminarIcono from "../../Icons/EliminarIcono";

import MultiMediaComponent from "../CampaingPageComponents/MultiMediaComponent";

//Custom portal components
import QuestionTextField from "./QuestionTextField";

//Styles Sheets
import "./Styles/editQnAQuestions.css";

import IntebotCatalog from "../IntebotCatalog";

import { DropzoneArea } from "material-ui-dropzone"; // https://yuvaleros.github.io/material-ui-dropzone/

const acceptedFiles = IntebotCatalog.acceptedFiles;

const FaqPair = (props) => {
    const {
        id,
        answer,
        questions,
        newPair,
        handlePairChange,
        addNewQuestion,
        removePair,
        removeQuestion,
        pairErrors,
        pairErrorHelper,
        currentPage,
        source,
        firstIdsFromSources,
        pairIsSuggestions,
        suggestion,
        handleSuggetionChange,
        removeSuggestion,
        acceptSuggestion,
        type,
        handleImageUploadField,
        dropZoneOpenObj,
        handleAddNewFiles,
        messagesTemp,
        closeImageUploadField,
        handleDeleteFile,
    } = props;

    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [currentQuestionsPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);

    const loadMoreQuestions = (endSlice) => {
        let startSlice = endSlice - itemsPerPage;
        let newfilteredQuestions = [...filteredQuestions, ...questions.slice(startSlice, endSlice)];
        setFilteredQuestions(newfilteredQuestions);
    };

    const observer = useRef();
    const lastQuestion = useCallback((questionTextField) => {
        if (observer.current) {
            observer.current.disconnect();
        }
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
        });
        if (questionTextField) {
            observer.current.observe(questionTextField);
        }
    }, []);

    const reRenderQuestions = () => {
        let endSlice = currentQuestionsPage * itemsPerPage;
        let renderQuestions = questions.slice(0, endSlice);
        setFilteredQuestions(renderQuestions);
    };

    const loadFirstQuestions = () => {
        let firstPageQuestions = questions.slice(0, itemsPerPage);
        setFilteredQuestions(firstPageQuestions);
    };

    useEffect(() => {
        loadFirstQuestions();
        setCurrentPage(1);
    }, [currentPage]);

    useEffect(() => {
        reRenderQuestions();
    }, [questions]);

    useEffect(() => {
        let endSlice = currentQuestionsPage * itemsPerPage;
        if (currentQuestionsPage > 1) {
            loadMoreQuestions(endSlice);
        }
    }, [currentQuestionsPage]);

    let currentMultiMedia = messagesTemp.MULTIMEDIA_MESSAGES.find(
        (multiMediaObj) => multiMediaObj.idPair === id
    );
    let currentMultiMediaIndex = messagesTemp.MULTIMEDIA_MESSAGES.findIndex(
        (multiMediaObj) => multiMediaObj.idPair === id
    );

    return (
        <div className="faq-pair">
            {!firstIdsFromSources
                ? null
                : firstIdsFromSources.includes(id) && (
                      <div className="source-file-header">
                          <label className="top-label-field">Archivo: {source}</label>
                      </div>
                  )}
            <div className="edit-pairs-container">
                <div className="qna-pair">
                    <div className="edit-question">
                        {/* UNICAMENTE CON FINES DE DEBUG */}
                        {/* <span> Archivo {source}</span> */}
                        {/* <span> ID {id} </span> */}
                        <span
                            style={{
                                color: "#f44336",
                                margin: "0 14px",
                                fontSize: "0.75rem",
                                display: pairErrors?.questionsTable ? "block" : "none",
                            }}
                        >
                            {pairErrors?.questionsTable ? pairErrorHelper["questionsTable"] : ""}
                        </span>
                        {/* QUESTIONS => */}
                        {filteredQuestions.map((questionObj, questionIndex) => (
                            <QuestionTextField
                                key={questionIndex}
                                questionIndex={questionIndex}
                                filteredQuestions={filteredQuestions}
                                questionObj={questionObj}
                                lastQuestion={lastQuestion}
                                pairErrors={pairErrors}
                                pairErrorHelper={pairErrorHelper}
                                handlePairChange={handlePairChange}
                                id={id}
                                newPair={newPair}
                                removeQuestion={removeQuestion}
                                pairIsSuggestions={pairIsSuggestions}
                            />
                        ))}
                        {/* SUGGESTIONS => */}
                        {pairIsSuggestions &&
                            suggestion.map((suggestionObj, suggestionIndex) => (
                                <TextField
                                    key={suggestionIndex}
                                    suggestion={suggestionObj.question}
                                    suggestionId={suggestionObj.id}
                                    pairErrors={pairErrors}
                                    pairErrorHelper={pairErrorHelper}
                                    handleSuggetionChange={handleSuggetionChange}
                                    removeSuggestion={removeSuggestion}
                                    id={id}
                                />
                            ))}
                        {!pairIsSuggestions && (
                            <Grid container justifyContent="flex-end">
                                <Button
                                    disabled={
                                        pairErrors?.questions.some(
                                            (questionError) => questionError
                                        ) ?? false
                                    }
                                    onClick={() => addNewQuestion(id)}
                                    startIcon={<AgregarIcono width="15px" height="15px" />}
                                    size="small"
                                >
                                    {" "}
                                    Agregar frase alternativa{" "}
                                </Button>
                            </Grid>
                        )}
                    </div>
                    <div className="edit-response">
                        <div className="response-textfield">
                            {/* ANSWER => */}
                            <TextField
                                disabled={type === "suggestionQnA"}
                                name={`answer`} //index
                                value={answer} //map value
                                variant={type === "suggestionQnA" ? "standard" : "outlined"}
                                size="small"
                                error={pairErrors?.answer ?? false}
                                helperText={pairErrorHelper?.answer ?? ""}
                                multiline
                                fullWidth
                                onChange={(event) =>
                                    handlePairChange(
                                        event.target.name,
                                        event.target.value,
                                        id,
                                        null,
                                        newPair
                                    )
                                }
                                InputProps={{
                                    endAdornment: (
                                        <Tooltip title="Subir imagen">
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={
                                                        dropZoneOpenObj[id] || currentMultiMedia
                                                    }
                                                    onClick={() => handleImageUploadField(id)}
                                                >
                                                    <ImageIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        </Tooltip>
                                    ),
                                }}
                            />
                            {dropZoneOpenObj[id] && (
                                <div>
                                    <div className="uploadFile-container">
                                        <DropzoneArea
                                            name="uploadFile"
                                            acceptedFiles={acceptedFiles}
                                            dropzoneClass={"dropZoneContainerNoWidth"}
                                            filesLimit={1}
                                            maxFileSize={20000000} //20 MB
                                            showFileNames
                                            dropzoneText={
                                                "Arrastra y suelta un archivo aquí o haz click"
                                            }
                                            onChange={(file) => {
                                                handleAddNewFiles(file[0], id);
                                            }}
                                            dropzoneParagraphClass="dropzoneParagraph"
                                            previewGridClasses={{
                                                container: "previewGridContainer",
                                                item: "previewGridItem",
                                                image: "previewGridImage",
                                            }}
                                            //Mensajes del Drop Zone
                                            getFileAddedMessage={(fileName) =>
                                                `El archivo ${fileName} fue agregado con éxito`
                                            }
                                            getFileLimitExceedMessage={(filesLimit) =>
                                                ` Número máximo de archivos alcanzados. Solamente se permiten ${filesLimit} archivos `
                                            }
                                            getFileRemovedMessage={(fileName) =>
                                                ` El archivo ${fileName} ha sido eliminado. `
                                            }
                                            getDropRejectMessage={(rejectedFile) =>
                                                ` Este archivo ${rejectedFile.name} no es compatible o Excede el máximo permitido de 4MB `
                                            }
                                            alertSnackbarProps={{
                                                anchorOrigin: {
                                                    vertical: "top",
                                                    horizontal: "right",
                                                },
                                            }}
                                        />
                                        <Tooltip title="Eliminar Archivo Multimedia">
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => closeImageUploadField(id)}
                                            >
                                                <TrashCan width="20px" height="20px" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}

                            {messagesTemp.MULTIMEDIA_MESSAGES.map(
                                (multiMediaObj) => multiMediaObj.idPair
                            ).includes(id) && (
                                <div className="uploadFile-container">
                                    <MultiMediaComponent
                                        template={currentMultiMedia}
                                        index={currentMultiMediaIndex}
                                    />
                                    <Tooltip title="Eliminar Archivo Multimedia">
                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            onClick={() => handleDeleteFile(id, currentMultiMedia)}
                                        >
                                            <TrashCan width="20px" height="20px" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {pairIsSuggestions && (
                    <Tooltip title="Aceptar sugerencia">
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={() => acceptSuggestion(id)}
                        >
                            <CheckIcon width="15px" height="15px" />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Eliminar FAQ">
                    <IconButton color="secondary" size="small" onClick={() => removePair(id)}>
                        <TrashCan width="20px" height="20px" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

const propAreEqual = (prevProps, newProps) => {
    let isAnswerEqual = prevProps.answer === newProps.answer;
    let isPairIdEqual = prevProps.id === newProps.id;
    let isQuestionsEqual =
        prevProps.questions.length === newProps.questions.length &&
        prevProps.questions.every(
            (questionObj, index) => questionObj.question === newProps.questions[index].question
        );
    let isSuggestionEqual = true;
    if (prevProps.pairIsSuggestions) {
        isSuggestionEqual =
            prevProps.suggestion.length === newProps.suggestion.length &&
            prevProps.suggestion.every(
                (suggestionObj, index) =>
                    suggestionObj.question === newProps.suggestion[index].question
            );
    }

    let dropZoneOpenObjEqual =
        prevProps.dropZoneOpenObj[prevProps.id] === newProps.dropZoneOpenObj[newProps.id];

    let multiMediaMessagesEqual =
        prevProps.messagesTemp.MULTIMEDIA_MESSAGES === newProps.messagesTemp.MULTIMEDIA_MESSAGES;

    return (
        isAnswerEqual &&
        isQuestionsEqual &&
        isPairIdEqual &&
        isSuggestionEqual &&
        dropZoneOpenObjEqual &&
        multiMediaMessagesEqual
    );
};

export default memo(FaqPair, propAreEqual);
