import React, { useEffect,useState, useRef } from 'react';

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
    IconButton,
    TextField,
    Grid,
    Tooltip,
} from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';

//Icons
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

//Custom portal components
import CustomButton from "../../common/CustomButton";
import { errorHelper } from '../../IntebotCatalog';


//External libraries
import Swal from 'sweetalert2' //https://sweetalert2.github.io

//Footer styles
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        padding: theme.spacing(2),
        margin: '0',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
}))

//==== Dialog Styles =====
const Dialog = withStyles((theme) => ({
    root: {
        marginTop: '56px',
        [theme.breakpoints.up('md')]: {
            marginTop: '64px',
        },
    },
}))(MuiDialog)

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle)

const DialogContent = withStyles((theme) => ({
    root:{
        display:'flex',
        justifyContent:'center',
        padding: "23px",
        width: "400px"
    }

}))(MuiDialogContent)

export default function IntentCreateNewModal(props) {
    const [intentName, setIntentName] = useState('')

    const defaultErrors = useRef({
        intentName: true,
    })
    const defaultErrorHelper = useRef({
        intentName: errorHelper.emptyField,
    })

    const [createIntentErrors, setCreateIntentErrors] = useState(defaultErrors.current)
    const [createIntentErrorHelper, setCreateIntentErrorHelper] = useState(defaultErrorHelper.current)
    const [hasChanges, setHasChanges] = useState(false)

    const { openIntentCreateNewModal, setOpenIntentCreateNewModal, createNewIntent, PLNtakenNamesList } = props;

    const classes = useStyles();

    useEffect(() => {
        if(!openIntentCreateNewModal){
            clearForm()
        }
    }, [openIntentCreateNewModal])
    

    const handleIntentName = (targetName, targetValue) => {
        let createIntentErrorsCopy = JSON.parse(JSON.stringify(createIntentErrors))
        let createIntentErrorHelperCopy = JSON.parse(JSON.stringify(createIntentErrorHelper))

        //Buscamos Errores
        switch (targetName) {
            case "intentName":
                //Evitamos que el cliente ponga espacios
                targetValue = targetValue.replace(/ /g,"")
                setIntentName(targetValue)
                
                let duplicateName = PLNtakenNamesList.filter(takenNameObj => takenNameObj.name.toLowerCase() === targetValue.toLowerCase())
                //check name duplicates
                if(duplicateName.length > 0){
                    createIntentErrorsCopy.intentName = true
                    createIntentErrorHelperCopy.intentName = errorHelper.duplicateName
                    break;
                }

                //check empty field
                if(!targetValue){
                    createIntentErrorsCopy[targetName] = true
                    createIntentErrorHelperCopy[targetName] = errorHelper.emptyField
                    break;
                }

                //remove errors
                createIntentErrorsCopy[targetName] = false
                createIntentErrorHelperCopy[targetName] = errorHelper.removeError
                break;
            default:
                break;
        }

        setCreateIntentErrors(createIntentErrorsCopy)
        setCreateIntentErrorHelper(createIntentErrorHelperCopy)
        setHasChanges(true)
    }

    const clearForm = () => {
        setIntentName('')
        setCreateIntentErrors(defaultErrors.current)
        setCreateIntentErrorHelper(defaultErrorHelper.current)
    }

    const handleClose = async (newIntentCreated) => {
        if(!newIntentCreated && hasChanges){
            let alert = await Swal.fire({
                text: '¿Estás seguro que deseas salir?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: `No`,
                confirmButtonColor: '#27315d',
                denyButtonColor: '#27315d',
            })
            //Cancelamos el eliminado del Par
            if (alert.isDenied || alert.isDismissed) {
                return
            }
        }
        setOpenIntentCreateNewModal(false)
    }


    return (
        <Dialog
            open={openIntentCreateNewModal}
        >
            <DialogTitle disableTypography>
                <h2>Crear intención</h2>
                <IconButton onClick={() => handleClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid className='intent-modal-content' item xs={12}>
                <div className='center-tooltip'>
                    <label className='top-label-field' >
                        Nombre del template
                    </label>
                    <Tooltip
                        title={`
                            El nombre de la intención debe ser sin espacios.
                        `}
                        >
                        <HelpOutlineIcon color="primary" fontSize="small" />
                    </Tooltip>
                </div>
                    <TextField
                        name="intentName"
                        value={intentName}
                        size='small'
                        type='small'
                        onChange={(event) => handleIntentName(event.target.name, event.target.value)}
                        fullWidth
                        variant='outlined'
                        error={createIntentErrors['intentName']}
                        helperText={createIntentErrorHelper['intentName']}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <div className={classes.root}>
                    <CustomButton 
                        disabled={createIntentErrors['intentName']}
                        onClick={() => createNewIntent(intentName)}
                        variant="contained" 
                    > 
                        Crear Intención
                    </CustomButton>
                </div>
            </DialogActions>
        </Dialog>
    )
}
