import React from 'react';

//MUI components
import {
    TextField,
} from "@material-ui/core";

const RegexEntityType = (props) => {
    const { handleChange, entityErrors, entityErrorHelper, regexPattern } = props
    return (
        <div>
            <label className='top-label-field' >
                Contenido de la expresión regular
            </label>
            <TextField
                name="regexPattern"
                value={regexPattern}
                size='small'
                type='small'
                onChange={(event) => handleChange(event.target.name, event.target.value)}
                fullWidth
                variant='outlined'
                error={entityErrors["regexPattern"]}
                helperText={entityErrorHelper["regexPattern"]}
            />
        </div>
    );
}

export default RegexEntityType;
