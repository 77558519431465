import * as React from "react";

function SvgEliminarIcono(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.53 30.53"
      {...props}
    >
      <g data-name="Capa 2">
        <path
          d="M21.58 15.26l8-8a3.29 3.29 0 000-4.66L27.91 1a3.29 3.29 0 00-4.66 0l-8 8-8-8a3.29 3.29 0 00-4.63 0L1 2.62a3.29 3.29 0 000 4.66l8 8-8 8a3.29 3.29 0 000 4.66l1.65 1.65a3.29 3.29 0 004.66 0l8-8 8 8a3.29 3.29 0 004.66 0l1.65-1.65a3.29 3.29 0 000-4.66z"
          fill="#27315d"
          opacity={0.81}
          data-name="Capa 1"
        />
      </g>
    </svg>
  );
}

export default SvgEliminarIcono;
