import * as React from "react";

import './Styles/iconStyles.css'

function SvgAgregarIcono(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38.94 38.94"
      
      {...props}
    >
      <g data-name="Capa 2">
        <g data-name="Capa 1">
          <circle className="agregar-icono-circle" cx={19.47} cy={19.47} r={19.47} />
          <path
            d="M31 18H21V8.61a1.5 1.5 0 00-1.5-1.5 1.5 1.5 0 00-1.5 1.5V18H8a1.5 1.5 0 00-1.5 1.5A1.5 1.5 0 008 21h10v10.66a1.5 1.5 0 001.5 1.5 1.5 1.5 0 001.5-1.5V21h10a1.5 1.5 0 001.5-1.5A1.5 1.5 0 0031 18z"
            className="agregar-icono-cross"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgAgregarIcono;
