import React from 'react';
//MUI components
import { Dialog, DialogContent, CircularProgress, Button as MuiButton } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    placeholder: {
        height: 40,
    },
    text: {
        textAlign: 'center',
    }
}));
const Button = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        //border: 0,
        color: 'white',
        height: 36,
        padding: '0 30px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&$disabled': {
            color: 'white',
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: 'none',
    },
}))(MuiButton);

export default function LoadingDialog(props) {
    const classes = useStyles();
    const { open, LoadingMessage } = props;

    return (
        <Dialog open={open}>
            <DialogContent>
                <div className={classes.root}>
                    <div className={classes.placeholder}>
                        <CircularProgress />
                    </div>
                </div>
                <div className={classes.text}> 
                    <p>{LoadingMessage}</p>
                    <p>Espere un momento por favor.</p>
                </div>
            </DialogContent>
        </Dialog>
    );
}
