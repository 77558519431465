import React from "react";

//MUi components
import { Grid, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

//Custom Portal Components
import IntebotCatalog from "../../IntebotCatalog";
import SimpleTable from "../../common/Tables/SimpleTable";
import AddResponseCase from "./Modals/AddResponseCase";

// styles
import "./Styles/ResponseBranchesTable.css";

//Catalogs Keys
const responseBranchesKeys = IntebotCatalog.responseBranchesKeys;
const responseCodeKeys = responseBranchesKeys.responseCode;
const replyActionKeys = responseBranchesKeys.replyAction;
const responseAttributesKeys = responseBranchesKeys.responseAttributes;

export default function ResponseBranchesTable(props) {
    const {
        responseBranches,
        classes,
        allProcessesNames,
        StyledMentionInput,
        autoCompleteList,
        updateResponseAttributes,
        addResponseBranch,
        updateResponseBranch,
        removeResponseBranch,
        currentProcess,
    } = props;

    let responseCode = responseBranches.map((item) => item.responseCode);

    const [state, setState] = React.useState({
        //responseName: "",
        indexBranches: 0,
    });

    React.useEffect(() => {
        assingData();
    }, []);

    const assingData = () => {
        if (!currentProcess.newProcess && responseCode.length > 0) {
            setState({
                ...state,
                //responseName: responseCode[0].responseName,
                indexBranches: responseBranches.length,
            });
        }
    };

    /**
     * @name removeResponse
     * @description Eliminar objeto de respuesta de la tabla
     */
    const removeResponse = (indexTable) => {
        removeResponseBranch(indexTable);
    };

    const { indexBranches } = state;

    return (
        <section className="response-table-container">
            {/* ======= RESPONSE CODE FIELDS ====== */}
            <label className="top-label-field">
                Escenarios de respuesta
                <Tooltip title="Tabla de códigos de respuestas">
                    <HelpOutlineIcon color="primary" fontSize="small" />
                </Tooltip>
            </label>

            <Grid container className="response-code-container">
                <Grid item className={classes.tableSize}>
                    <SimpleTable
                        headers={["Descripción de escenario", "Código de escenario"]}
                        values={responseBranches}
                        type="response-branches-table"
                        index={indexBranches}
                        classes={classes}
                        responseBranches={responseBranches}
                        allProcessesNames={allProcessesNames}
                        StyledMentionInput={StyledMentionInput}
                        autoCompleteList={autoCompleteList}
                        responseCodeKeys={responseCodeKeys}
                        replyActionKeys={replyActionKeys}
                        responseAttributesKeys={responseAttributesKeys}
                        updateResponseAttributes={updateResponseAttributes}
                        isNewCase={true}
                        processName={currentProcess.processName}
                        functionDelete={(indexTable) => {
                            removeResponse(indexTable);
                        }}
                        functionEdit={updateResponseBranch}
                    />
                </Grid>

                <Grid container justifyContent="flex-end" className={classes.consistentInputSize}>
                    {/* modal add service reponse*/}
                    <AddResponseCase
                        index={indexBranches}
                        classes={classes}
                        responseBranches={responseBranches}
                        allProcessesNames={allProcessesNames}
                        StyledMentionInput={StyledMentionInput}
                        autoCompleteList={autoCompleteList}
                        responseCodeKeys={responseCodeKeys}
                        replyActionKeys={replyActionKeys}
                        responseAttributesKeys={responseAttributesKeys}
                        isNewCase={true}
                        processName={currentProcess.processName}
                        addResponseBranch={addResponseBranch}
                    />
                </Grid>
            </Grid>
        </section>
    );
}
