//Todos los canales disponibles
exports.CHANNELS = [
    { name: 'WhatsApp', available: true},
    { name:'Web', available: true},
    { name:'Messanger', available: false},
    { name:'Teams', available:false},
];

//Solamente Exportamos los canales disponibles, pendiente cambiarlo a la estructura del local storage
exports.AVAILABLE_CHANNELS= this.CHANNELS.filter((channel) => {
    //primer paso filtramos el los canales para encontrar a los que NO estan disponibles y Quitarlos
    if(channel.available){
        return true;
    }
    return false;
    //segundo paso guardamos solo los que si estan disponibles
}).map((channel) =>{
    return channel
})

//QnA Ocp-Apim-Subscription-Key:
exports.QNA_SUSCRIPTION_KEY = '43390c0a73e04d23bb1b40b35b659b0f'
//QnA nombre de la base de conocimientos del cliente
exports.KB_NAME = 'intebot-cfe-noreste'

// subscriptionIds validas para elementos especiales
exports.SUBSCRIPTIONS = [
    "Platinum-619eb3d86c",
    "Platinum-63347e3f59" // test
];