import React, { useState, useEffect, useContext, useRef } from 'react';

//MUI componets
import {
    Grid,
    Tooltip,
    Button,
    CircularProgress,
    Radio
} from '@material-ui/core/';

//Icons
import AgregarIcono from '../../Icons/AgregarIcono';
import { ReactComponent as TrashCan } from '../../Icons/trashcanIcon.svg';
import EditarIcono from '../../Icons/EditarIcono'

//Custom Portal Components
import SearchBox from '../common/SearchBox';
import RefreshButton from '../common/RefreshButton';
import SearchNoResults from '../common/SearchNoResults';
import { actionsPLNManagement } from '../DataFunctions';
import ErrorInFetch from '../common/ErrorInFetch';
import AppTableComponent from './AppTableComponent';
import CreateNewAppModal from './Modals/CreateNewAppModal';
import AppContext from '../../Context/AppContext';

import Swal from 'sweetalert2' //https://sweetalert2.github.io

import './styles/ConversationApps.css';

const ConversationApps = (props) => {

    const [openCreateNewAppModal, setOpenCreateNewAppModal] = useState(false);
    const [currentApp, setCurrentApp] = useState({})
    const [searchQuery, setSearchQuery] = useState("");
    const [searchHasNoResults, setSearchHasNoResults] = useState(false);
    const [loading, setLoading] = useState(false);
    const [appList, setAppList] = useState([]);
    const [errorInFetch, setErrorInFetch] = useState(false);

    const intitalAppList = useRef([]);

    const { updateContextAttribute } = useContext(AppContext);

    const { setTempAppId, tempAppId, tempAppName, setChangeAppHasChanged, changeAppHasChanged } = props
    

    const refreshFunction = async () => {
        getAppsList();
    }

    const searchApp = (searchQuery) => {
        setSearchHasNoResults(false)
        setSearchQuery(searchQuery)
        if(!searchQuery){
            setAppList(intitalAppList.current)
            return
        }
        let filteredAppList = appList.filter((app) => app.name.toLowerCase().includes(searchQuery.toLowerCase()))
        setAppList(filteredAppList)
        if(filteredAppList.length === 0){
            setSearchHasNoResults(true)
        }

    }

    useEffect(() => {
        refreshFunction();
    }, [])

    const getAppsList = async () => {
        setLoading(true);
        const res = await actionsPLNManagement(null, 'getAppsList');
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        setLoading(false);
        switch (responseCode) {
            case 0:
                let appList = res.data.data;
                intitalAppList.current = appList;
                setAppList(appList);
                break;
        
            default:
                setErrorInFetch(true)
                break;
        }

    }

    const deleteApp = async (appId) => {
        //Preguntar si quiere eliminar la app
        let alert = await Swal.fire({
            text: '¿Deseas eliminar esta aplicación?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
            confirmButtonColor: '#27315d',
            denyButtonColor: '#27315d',
        })
        //Cancelamos el eliminado del intent
        if (alert.isDenied || alert.isDismissed) {
            return
        }
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Creando aplicación...')
        let data = {
            "appID" : appId
        }
        const res = await actionsPLNManagement(data, 'deleteApp');
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute('loadingDialog', false)
        switch (responseCode) {
            case 0:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: `La aplicación fue eliminada correctamente.`,
                });
                refreshFunction();
                //Remove AppName and AppId from local storage
                localStorage.removeItem('appName')
                localStorage.removeItem('appId')
                setTempAppId(null)
                tempAppName.current = null
                break;
            default:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: `Hubo un error al eliminar la aplicación.`,
                });
                setErrorInFetch(true)
                break;
        }
    }


    const createNewApp = async (newAppBody) => {
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Creando aplicación...')
        let data = {
            "nameApp" : newAppBody.nameApp,
            "cultureApp" : newAppBody.cultureApp
        }
        const res = await actionsPLNManagement(data, 'createApp');
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute('loadingDialog', false)
        switch (responseCode) {
            case 0:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: `La aplicación fue agregada correctamente.`,
                });
                setOpenCreateNewAppModal(false);
                refreshFunction();
                //Delete appid from localstorage
                localStorage.removeItem('appId');
                break;
            default:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: `Hubo un error al agregar la aplicación.`,
                });
                setErrorInFetch(true)
                break;
        }
    }
    

    return (
        <Grid container>
            <h1>Aplicaciones</h1>
            <CreateNewAppModal
                openCreateNewAppModal={openCreateNewAppModal}
                setOpenCreateNewAppModal={setOpenCreateNewAppModal}
                createNewApp={createNewApp}
            />
            <header className='edit-intents-actions'>
                    <Tooltip title="Crear entidad">
                        <Button 
                            onClick={() => {
                                setOpenCreateNewAppModal(true);
                            }} 
                            startIcon={<AgregarIcono width="15px" height="15px" />} 
                            size="small" 
                        > 
                            Crear 
                        </Button>
                    </Tooltip>
                    <Tooltip title="Eliminar entidad">
                        <Button disabled={!tempAppId} onClick={() => deleteApp(tempAppId)} startIcon={<TrashCan width="15px" height="15px" />} size="small" > Eliminar </Button>
                    </Tooltip>
                    <RefreshButton
                        refreshFunction={refreshFunction}
                    />
                    <SearchBox
                        searchFunction={(searchQuery) => searchApp(searchQuery)}
                        searchQuery={searchQuery}
                    />
                </header>
            <main className='apps-table'>
                <header className='apps-table-header'>
                    <div className='radio-header'></div>
                    <h2 className='app-name-header'>Nombre</h2>
                    <h2 className='app-culture-header'>Cultura</h2>
                </header>
                { appList.length === 0 && !loading && <div className='empty-intents-table'></div> }
                { searchHasNoResults && <SearchNoResults searchQuery={searchQuery}/> }
                <ErrorInFetch
                    errorInFetch={errorInFetch}
                    refreshFunction={refreshFunction}
                />
                {
                    loading ? 
                        <div className='center-loading-icon'>
                            <CircularProgress /> 
                        </div> 
                    : appList.map((app, index) => 
                        <AppTableComponent
                            key={index}
                            {...app}
                            tempAppId={tempAppId}
                            setTempAppId={setTempAppId}
                            tempAppName={tempAppName}
                            setChangeAppHasChanged={setChangeAppHasChanged}
                            changeAppHasChanged={changeAppHasChanged}
                        />
                    )
                }

            </main>
        </Grid>
    );
}

export default ConversationApps;
