//React Libraries
import React from "react";

//Material-UI components
import {
    Grid as MuiGrid,
    TextField,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

//Custom Portal Icons
import AgregarIcono from "../Icons/AgregarIcono";
import { ReactComponent as TrashCan } from "../Icons/trashcanIcon.svg";

//Custom Portal Components
import IntebotCatalog from "../components/IntebotCatalog";
import InputChooser from "../components/InputChooser";
import FooterControls from "../components/FooterComponents/FooterControls";
import CustomAlert from "../components/common/Alerts/CustomAlert";
import MandatoryField from "../components/common/Fields/MandatoryField";
import AppContext from "../Context/AppContext";
import CustomButton from "../components/common/CustomButton";

//External Libraries
import { MentionsInput, Mention } from "react-mentions"; // https://github.com/signavio/react-mentions
import { DropzoneAreaBase } from "material-ui-dropzone"; // https://yuvaleros.github.io/material-ui-dropzone/
import Swal from "sweetalert2"; //https://sweetalert2.github.io
import { intebotDataActions } from "../components/DataFunctions";

//Custom CSS
import "../components/StyleComponents/dropZone.css";

// config
import { SUBSCRIPTIONS } from "../Config/settings";

//JSX styles
const Grid = withStyles((theme) => ({
    root: {
        "& div label": {
            display: "block",
            [theme.breakpoints.up("md")]: {
                textAlign: "right",
            },
        },
    },
}))(MuiGrid);

// const messageTitle = {
//     display: 'flex',
//     justifyContent: 'space-evenly',
//     width: '50%',
//     margin: 'auto'
// }

const StyledMentionInput = {
    control: {
        backgroundColor: "#fafafa",
        fontSize: 16,
        fontWeight: "normal",
    },

    input: {
        margin: 0,
    },
    "&multiLine": {
        control: {
            fontFamily: "Roboto",
            border: "1px solid silver",
            borderRadius: "4px",
            width: "224px",
        },

        highlighter: {
            padding: 9,
            overflow: "hidden",
            lineHeight: "initial",
        },

        input: {
            padding: 10.5,
            minHeight: "40px",
            outline: 0,
            border: 0,
            overflow: "hidden",
            color: "#27315D",
        },
    },

    suggestions: {
        list: {
            backgroundColor: "white",
            border: "1px solid #b2b2b2",
            fontSize: 16,
            maxHeight: "300px",
            minWidth: "400px",
            overflowY: "auto",
            overflowX: "hidden",
            wordBreak: "break-word",
        },

        item: {
            padding: "10px",
            "&focused": {
                backgroundColor: "#ebebeb",
            },
        },
    },
};

export default class MessagesForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: {},
            errors: {},
            countErrors: 0,
            errorHelper: {},
            multiMediaErrors: [],
            multiMediaErrorHelper: [],
            existingMultiMediaErrors: [],
            existingMultiMediaErrorHelper: [],
            newMultiMediaFields: [], //Crear un Nuevo Mensaje Multimedia
            fileObjects: [], //Arreglo de archivos controlados que estan almacenados en el componente de Drop Zone
            existingFileObjects: [], // Archivos convertidos de URL a objetos con la info necesaria para ser leída por el <drop zone> = {data: base64 encode, file: JS file object}
            fileDataToSend: [], //Arreglo de archivos con las cuatro propiedades necesarias para subirlos al blob #name #body #type #length,
            fileDataToDelete: [], //Arreglo de archivos con la propiedad #name, para ser eliminados del Blob storage
            queuedFile: {}, //En caso de que ya exista un archivo en el drop zone, ponemos en espera el nuevo archivo, si el cliente acepta el remplazo, lo sustituimos con el anterior archivo
            indexOfFile: 0, //posición del archvio a ser remplazado/eliminado
            removeFile: false, //Confirmación del usuario de eliminar un archivo sin súbir al blob en el <dialog>
            replaceFile: false, //Confirmación del usuario de remplazar un archivo sin súbir al blob en el <dialog>
            replaceExistingFile: false, //Confirmación del usuario de eliminar y remplazar un archivo traido desde una URL
            Loading: false,
            openDialog: false,
            alert: {
                open: false,
                severity: "", //success, error, warning, info
                message: "",
            },
            dialog: {
                title: "",
                message: "",
            },
        };
    }

    //Portal Catalogs
    messagesKeys = IntebotCatalog.MessageCommandsKeys;
    acceptedFiles = IntebotCatalog.acceptedFiles;
    errorHelperKeys = IntebotCatalog.errorHelper;
    //Obtener un arreglo de URL's => convertir cada URL en fileObject => Guardarlos en el state
    createFileFromUrl = async () => {
        const { fetchFilesFromURL, getFileAsBase64 } = this;
        const { messagesTemp, updateContextAttribute } = this.context;
        const { existingMultiMediaErrors, existingMultiMediaErrorHelper } = this.state;
        this.setState({
            Loading: true,
        });
        let ArrayOfFiles = [];
        //1- Pedimos todos los mensajes multimedia
        let multiMediaMessages = messagesTemp.MULTIMEDIA_MESSAGES
            ? messagesTemp.MULTIMEDIA_MESSAGES
            : [];
        //2- Iterar el arreglo de URL's
        for (let index = 0; index < multiMediaMessages.length; index++) {
            const fileLink = multiMediaMessages[index].fileLink;
            //3- convertir las URL en Files
            let file = await fetchFilesFromURL(fileLink);
            if (!file) {
                //agregamos los errores por cada campo
                existingMultiMediaErrors[index] = {
                    file: true,
                    message: false,
                    trigger: multiMediaMessages[index].trigger ? false : true,
                };
                existingMultiMediaErrorHelper[index] = {
                    file: " * El archivo no se pudo cargar, por favor remplace el archivo o elimine el campo",
                    message: false,
                    trigger: multiMediaMessages[index].trigger
                        ? ""
                        : "* El campo no puede estar vacío",
                };
                continue;
            }
            //4- convertir los Files en Base64
            let fileInBase64 = await getFileAsBase64(file);
            //5- Creación del FileObject
            let fileObject = {
                data: fileInBase64,
                file: file,
            };
            ArrayOfFiles.push([fileObject]);
            //agregamos los errores por cada campo
            existingMultiMediaErrors[index] = {
                file: fileObject ? false : true,
                message: false,
                trigger: multiMediaMessages[index].trigger ? false : true,
            };
            existingMultiMediaErrorHelper[index] = {
                file: fileObject ? "" : "* No se puede guardar un mensaje multimedia sin archivo ",
                message: false,
                trigger: multiMediaMessages[index].trigger ? "" : "* El campo no puede estar vacío",
            };
        }
        //6- Guardamos Todos los archivos en el state con el formato requerido para ser leído por el <Dropzone>
        updateContextAttribute("existingFileObjects", ArrayOfFiles);
        this.setState({
            existingFileObjects: ArrayOfFiles,
            Loading: false,
            existingMultiMediaErrors: existingMultiMediaErrors,
            existingMultiMediaErrorHelper: existingMultiMediaErrorHelper,
        });
    };

    //Crear un new File apartir de una URL
    fetchFilesFromURL = async (imageUrl) => {
        try {
            //1- Hacemos fetch al archivo con la URL
            let res = await fetch(imageUrl);
            //*falta notificar al usuario si un archivo no está disponible
            //La convertimos en blob
            let imageBlob = await res.blob();
            //2- Obtenemos la información que nos interesa del archivo
            let filename = imageUrl.replace(/\?.+/, "").split("/").pop();
            let metadata = {
                type: imageBlob.type,
            };
            //3- Creamos el archivo y lo regresamos
            let file = new File([imageBlob], filename, metadata);
            return file;
        } catch (error) {
            console.log(error);
        }
    };

    //leer un File => converitlo a base64 => Lo regresamos en un Promise
    getFileAsBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            //1- Leemos el archivo como Base64
            try {
                reader.readAsDataURL(file);
            } catch (error) {
                console.log(`error`, error);
            }
            //2- Regresamos el resultado a la Promesa
            reader.onload = () => {
                resolve(reader.result);
            };
            //...o regresamos el error
            reader.onerror = reject;
        });
    };

    //funcion para abrir convertir los archivos en URL, en file objects de javascript
    //  **************** WARNING !! (CALLBACK HELL) DO NOT USE IT !! **********************
    // getUploadedFiles = async (imageUrl) => {
    //     //hacemos fetch a la url, almacenamos la información en un blob
    //     fetch(imageUrl).then(response => response.blob()).then(imageBlob => {
    //         // Sacamos el nombre del archivo de la URL
    //         let filename = imageUrl.replace(/\?.+/, '').split('/').pop();
    //         // agregamos el tipo de archivo para crear el new File
    //         let metadata = {
    //             type: imageBlob.type
    //         };
    //         //creamos un archivo de javascript con la info de la URL
    //         let file = new File([imageBlob], filename, metadata)
    //         // =========================================================
    //         // mandamos el archivo a la funcion para converitlo en base64 => creamos el objeto de archivo
    //         this.getFileAsBase64(file, (base64data) => {
    //             const { existingFileObjects } = this.state;
    //             let fileObject = {
    //                 data: base64data,
    //                 file: file
    //             }
    //             let ArrayOfFiles = [...existingFileObjects]
    //             ArrayOfFiles.push([fileObject])
    //             console.log(`fileObject`, fileObject)
    //             this.setState({
    //                 existingFileObjects: ArrayOfFiles
    //             })
    //         });
    //     });
    // };

    componentDidMount() {
        const { messagesTemp, newMultiMediaMessages, fileObjects } = this.context;
        const { messagesKeys, errorHelperKeys } = this;
        const { errors, errorHelper } = this.state;
        let errorsCopy = Object.assign({}, errors);
        let errorHelperCopy = Object.assign({}, errorHelper);
        //Revisamos si existen errores en el contexto temporal
        let multiMediaErrors = [];
        let multiMediaErrorHelper = [];
        newMultiMediaMessages.forEach((multimedia, index) => {
            multiMediaErrors[index] = {
                file: fileObjects[index] ? false : true,
                message: false,
                trigger: multimedia.trigger ? false : true,
            };
            multiMediaErrorHelper[index] = {
                file: fileObjects[index] ? "" : "* El archivo multimedia no puede estar vacío",
                trigger: multimedia.trigger ? "" : "* El campo no puede estar vacío",
            };
        });
        //const messagesCopy = JSON.parse(JSON.stringify(messagesTemp))
        //0- Mandamos a llamar el Fetch de imagenes por URL'S
        this.createFileFromUrl();
        messagesKeys.forEach((element) => {
            //preguntamos si está vacío
            if (!messagesTemp[element.name] && element.required) {
                errorsCopy[element.name] = true;
                errorHelperCopy[element.name] = errorHelperKeys.emptyField;
            }
            //errores especificos
            //algunos campos son arrays, hay que acceder al nombre desde el array
            switch (element.name) {
                case "MENU_MESSAGE":
                    if (messagesTemp[element.name].length > 60) {
                        errorHelperCopy[element.name] = errorHelperKeys.maxLength60;
                        break;
                    }
                    break;
                case "MENU_SECTION":
                    if (messagesTemp[element.name].length > 24) {
                        errorHelperCopy[element.name] = errorHelperKeys.maxLength24;
                        break;
                    }
                    break;
                default:
                    break;
            }
        });
        const countErrors = Object.values(errorsCopy).reduce((a, item) => a + item, 0);
        this.setState({
            errors: errorsCopy,
            countErrors: countErrors,
            errorHelper: errorHelperCopy,
            newMultiMediaFields: newMultiMediaMessages,
            fileObjects: fileObjects,
            multiMediaErrors: multiMediaErrors,
            multiMediaErrorHelper: multiMediaErrorHelper,
        });
    }

    // //verificamos si hay nuevos files en el context y volvemos a renderizar
    componentDidUpdate(prevProps) {
        const { clearMultiMediaFields, updateContextAttribute } = this.context;
        const { contextMessages } = this.props;
        const { resetMultiMediaState } = this;
        let prevMultiMedia = prevProps.contextMessages.MULTIMEDIA_MESSAGES
            ? prevProps.contextMessages.MULTIMEDIA_MESSAGES
            : [];
        let currentMultiMedia = contextMessages.MULTIMEDIA_MESSAGES
            ? contextMessages.MULTIMEDIA_MESSAGES
            : [];
        if (clearMultiMediaFields) {
            resetMultiMediaState();
            updateContextAttribute("clearMultiMediaFields", false);
        }
        if (prevMultiMedia.length !== currentMultiMedia.length) {
            this.createFileFromUrl();
        }
    }

    resetMultiMediaState = () => {
        this.setState({
            multiMediaErrors: [],
            multiMediaErrorHelper: [],
            newMultiMediaFields: [],
            fileObjects: [],
            fileDataToSend: [],
            fileDataToDelete: [],
            queuedFile: {},
            indexOfFile: 0,
            removeFile: false,
            replaceFile: false,
            replaceExistingFile: false,
        });
    };

    //Realizamos las modificaciones sobre el contexto temporal de Mensajes
    changeHandler = (targetName, targetValue, targetType, targetRequired) => {
        const { messagesTemp, updateContextAttribute } = this.context;
        const { errorHelperKeys } = this;
        const { errors, errorHelper } = this.state;
        //console.log(targetName, targetValue, targetType, targetRequired)

        //Hacemos una copia temporal para modificarla y actualizar el contexto
        const messagesCopy = Object.assign({}, messagesTemp);
        const errorsCopy = Object.assign({}, errors);
        const errorHelperCopy = Object.assign({}, errorHelper);
        //Evaluamos el tipo de componente para actualizar correctamente el estado del objeto:
        switch (targetType) {
            case "text-field":
                //Simplemente agregamos la nueva cadena o arreglo en el atributo correspondiente:
                messagesCopy[targetName] = targetValue;
                break;
            case "checkbox":
                messagesCopy[targetName] = targetValue;
                break;
            case "warning-text-field":
                //Quitamos espacios y agregamos Upper case antes de actualizar valor
                messagesCopy[targetName] = targetValue.replace(/\s/g, "").toUpperCase();
                break;
            case "double-text-field":
                messagesCopy[targetName] = targetValue;
                break;
            case "mention-text-field":
                messagesCopy[targetName] = targetValue;
                break;
            default:
                break;
        }

        switch (targetName) {
            case "MENU_MESSAGE":
                if (targetValue.length > 60) {
                    errorHelperCopy[targetName] = errorHelperKeys.maxLength60;
                    break;
                }
                if (targetValue === "" && targetRequired) {
                    errorsCopy[targetName] = true;
                    errorHelperCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }
                errorsCopy[targetName] = false;
                errorHelperCopy[targetName] = errorHelperKeys.removeError;
                break;
            case "MENU_SECTION":
                if (targetValue.length > 24) {
                    errorHelperCopy[targetName] = errorHelperKeys.maxLength24;
                    break;
                }
                if (targetValue === "" && targetRequired) {
                    errorsCopy[targetName] = true;
                    errorHelperCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }
                errorsCopy[targetName] = false;
                errorHelperCopy[targetName] = errorHelperKeys.removeError;
                break;
            default:
                //Revisamos si hay errores en los campos
                if (targetValue === "" && targetRequired) {
                    errorsCopy[targetName] = true;
                } else {
                    errorsCopy[targetName] = false;
                }
                break;
        }

        //Contamos la cantidad de errores actualmente
        const countErrors = Object.values(errorsCopy).reduce((a, item) => a + item, 0);
        //Guardamos el nuevo contexto y los errores en el estado
        updateContextAttribute("messagesTemp", messagesCopy);
        updateContextAttribute("messagesHasChanges", true);
        this.setState({
            messages: messagesCopy,
            errors: errorsCopy,
            countErrors: countErrors,
            errorHelper: errorHelperCopy,
        });
    };

    addNewMultiMediaMessages = () => {
        const { newMultiMediaFields, multiMediaErrors, multiMediaErrorHelper } = this.state;
        const { updateContextAttribute } = this.context;
        let newMultiMediaFieldsCopy = JSON.parse(JSON.stringify(newMultiMediaFields));
        let multiMediaErrorsCopy = JSON.parse(JSON.stringify(multiMediaErrors));
        let multiMediaErrorHelperCopy = JSON.parse(JSON.stringify(multiMediaErrorHelper));
        //agregamos un set de campos vacíos
        newMultiMediaFieldsCopy.push({
            fileType: "",
            fileLink: "",
            fileName: "",
            message: "",
            trigger: "",
        });
        //agregamos los errores en los campos obligatorios
        multiMediaErrorsCopy.push({
            file: true,
            message: false,
            trigger: true,
        });
        multiMediaErrorHelperCopy.push({
            file: "* El archivo multimedia no puede estar vacío",
            trigger: "* El campo no puede estar vacío",
        });
        updateContextAttribute("messagesHasChanges", true);
        //console.log(`newMultiMediaFieldsCopy`, newMultiMediaFieldsCopy)
        this.setState({
            newMultiMediaFields: newMultiMediaFieldsCopy,
            multiMediaErrors: multiMediaErrorsCopy,
            multiMediaErrorHelper: multiMediaErrorHelperCopy,
        });
    };

    replaceExistingFile = (ArrayOfFiles, index, replace) => {
        const { updateContextAttribute } = this.context;
        const {
            existingFileObjects,
            fileDataToSend,
            existingMultiMediaErrors,
            existingMultiMediaErrorHelper,
        } = this.state;
        let fileDataList = [...fileDataToSend];
        let existingFilesList = [...existingFileObjects];
        let fileToUpload = ArrayOfFiles[0];
        if (replace) {
            existingFilesList.splice(index, 1, ArrayOfFiles);
            updateContextAttribute("messagesHasChanges", true);
            //quitamos el error
            existingMultiMediaErrors[index].file = false;
            existingMultiMediaErrorHelper[index].file = "";
            this.setState({
                replaceExistingFile: false,
            });
            //preguntamos por el archivo anterior
        } else if (existingFilesList[index]) {
            this.setState({
                openDialog: true,
                dialog: {
                    title: "¿Quieres remplazar el archivo existente?",
                    message: "Este mensaje multimedia ya contiene un archivo, deseas remplazarlo?",
                },
                //Ponemos al archivo en espera
                queuedFile: ArrayOfFiles,
                indexOfFile: index,
                replaceExistingFile: true,
            });
            fileToUpload = null;
        }
        let existingFile = true; //control para sabe si estamos extrayendo data de un archivo existente/nuevo
        fileDataList = fileToUpload
            ? this.extractFileData(fileToUpload, index, replace, existingFile)
            : fileDataList;
        this.setState({
            existingFileObjects: existingFilesList,
            fileDataToSend: fileDataList,
        });
    };

    handleAddNewFiles = async (ArrayOfFiles, index, replace) => {
        const { messagesTemp, updateContextAttribute } = this.context;
        const { fileObjects, fileDataToSend, multiMediaErrors, multiMediaErrorHelper } = this.state;
        //console.log(`multiMediaErrors`, multiMediaErrors)
        //Copia del arreglo de Archivos a ser mandados al blob
        let fileDataList = [...fileDataToSend];
        //Copia de la lista de todos los Archivos cargados en el DropZone
        let fileList = [...fileObjects];
        //Info extraida por el navegador del Archivo a subir
        let fileToUpload = ArrayOfFiles[0];
        //Buscamos si ya existe el archivo
        let existingFileNames = [
            ...messagesTemp.MULTIMEDIA_MESSAGES.map((multiMedia) => multiMedia.fileName),
            ...fileDataToSend.map((fileData) => fileData.name),
        ];
        let duplicateFile = existingFileNames.includes(fileToUpload.file.name);
        if (duplicateFile) {
            //Preguntamos al usuario si quiere remplazar el archivo
            let result = await Swal.fire({
                text: "El archivo seleccionado ya existe en el almacenamiento, ¿Deseas sobrescribirlo?",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            //Cancelamos el archivo subido dentro del Drop Zone
            if (result.isDenied || result.isDismissed) {
                return (fileToUpload = null);
            }
        }
        //hacemos el remplazo en caso de que la funcion sea llamada desde el <dialog>
        if (replace) {
            fileList.splice(index, 1, ArrayOfFiles);
            updateContextAttribute("messagesHasChanges", true);
            this.setState({
                replaceFile: false,
            });
        } else if (fileList[index]) {
            //Si no, preguntamos si existe un archivo en la posición del drop zone
            this.setState({
                openDialog: true,
                dialog: {
                    title: "¿Quieres remplazar el archivo existente?",
                    message: "Este mensaje multimedia ya contiene un archivo, deseas remplazarlo?",
                },
                //Ponemos al archivo en espera
                queuedFile: ArrayOfFiles,
                indexOfFile: index,
                replaceFile: true,
            });
            //Cancelamos el archivo subido dentro del Drop Zone
            fileToUpload = null;
        } else {
            //Si no hay remplazos ni archivos existentes, guardamos el arreglo de archivos en el state
            let addedFileIndex = fileList.push(ArrayOfFiles) - 1;
            //quitamos el error
            multiMediaErrors[addedFileIndex].file = false;
            multiMediaErrorHelper[addedFileIndex].file = "";
        }
        //Si el archivo no fue cancelado, entonces extraemos la info el blob storage para almacenar el archivo
        let existingFile = false; //Control para saber si extraemos la data de un archivo existente o uno nuevo
        fileDataList = fileToUpload
            ? this.extractFileData(fileToUpload, index, replace, existingFile)
            : fileDataList;
        this.setState({
            fileObjects: fileList,
            fileDataToSend: fileDataList,
        });
    };

    //El servicio de Blob necesitacuatro propiedades para almacenar el archivo: #name #body #type #length,
    extractFileData = (fileToUpload, index, replace, existingFile) => {
        //inicialización de variables
        const { messagesTemp, updateContextAttribute } = this.context;
        const { fileDataToSend, newMultiMediaFields } = this.state;
        let messagesTempCopy = JSON.parse(JSON.stringify(messagesTemp));
        let newMultiMediaFieldsCopy = [...newMultiMediaFields];
        let fileDataList = [...fileDataToSend];
        //Extraemos las 4 propiedades del objeto File
        let fileName = fileToUpload.file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let onlyBase64Code = fileToUpload.data.split(",")[1];
        let fileType = fileToUpload.file.type;
        let fileSize = fileToUpload.file.size;
        //generamos una clave única para evitar duplicidad de nombres de archivos
        //let fileUniqid = uniqid()
        //Armamos el JSON que recibirá el WS
        const MULTIMEDIA_MESSAGES_FILE_DATA = {
            //"name": `${fileUniqid}-${fileName}`, //Hacemos el nombre del archivo único Ej. kw5g403p-intebot.jpg
            name: fileName,
            body: onlyBase64Code,
            type: fileType,
            length: fileSize,
            index: existingFile ? index : null, //Si es un archivo con un link existente, guardamos la posición del link a sustituir
        };
        //extraemos el tipo de archivo
        let chooseUplodedType = fileType.startsWith("image/")
            ? "image"
            : fileType.startsWith("video/")
            ? "video"
            : "file";
        //Preguntamos si es un mensaje multimedia existente y remplazamos en el respectivo lugar
        if (existingFile) {
            messagesTempCopy.MULTIMEDIA_MESSAGES[index].fileType = chooseUplodedType;
            messagesTempCopy.MULTIMEDIA_MESSAGES[index].fileName = fileName;
            updateContextAttribute("messagesTemp", messagesTempCopy);
        } else {
            //Si es un archivo nuevo, lo agregamos en los campos nuevos
            newMultiMediaFieldsCopy[index].fileType = chooseUplodedType;
            newMultiMediaFieldsCopy[index].fileName = fileName;
        }
        //la guardamos en su respectivo lugar
        if (replace) {
            //si es un archivo existente, en la posición a ser remplazada
            fileDataList.splice(index, 1, MULTIMEDIA_MESSAGES_FILE_DATA);
        } else {
            //en el caso de uno nuevo, al final del arreglo
            fileDataList.push(MULTIMEDIA_MESSAGES_FILE_DATA);
        }
        return fileDataList;
    };

    //eliminar el archivo dentro del <dropZone>
    //*Solo se le permitiria al cliente remplazar o eliminar por completo el campo de mulimedia
    //*Solo referencia*
    // handleDeleteFile = (deleteFileObj, index, deleteFrom) => {
    //     const { fileObjects, fileDataToSend, multiMediaErrors, multiMediaErrorHelper, existingFileObjects, existingMultiMediaErrorHelper, existingMultiMediaErrors} = this.state;
    //     console.log(`index`, index)
    //     console.log(`deleteFileObj`, deleteFileObj)
    //     console.log(`fileObjects`, fileObjects[index][0].data)
    //     //tenemos que eliminarlo de los archivos cargados en el <dropzone> y de la info que irá al blob
    //     if(deleteFrom === "existingFiles") {
    //         delete existingFileObjects[index]
    //         existingMultiMediaErrors[index].file = true
    //         existingMultiMediaErrorHelper[index].file ="* No se puede guardar un mensaje multimedia sin archivo"
    //     }
    //     if(deleteFrom === "newFiles") {
    //         let IDFileDelete = fileObjects[index].indexOf(deleteFileObj)
    //         console.log(`IDFileDelete`, IDFileDelete)
    //         delete fileObjects[index] //NO DEJAR ESPACIOS VACIOS <- hace que se recorran todos los archivos multimedia, y da problemas al agregar nuevos
    //         fileDataToSend.splice(IDFileDelete, 1)
    //         //agregamos el Error
    //         multiMediaErrors[index].file = true
    //         multiMediaErrorHelper[index].file = "* No se puede guardar un mensaje multimedia sin archivo"
    //     }
    //     this.setState({
    //         fileObjects: fileObjects,
    //         fileDataToSend: fileDataToSend,
    //         multiMediaErrors: multiMediaErrors,
    //         multiMediaErrorHelper: multiMediaErrorHelper
    //     })
    // }

    //Actualización únicamente de los <input> de mensaje y trigger
    updateNewMultiMediaMessages = (field, index, fieldName) => {
        const { updateContextAttribute } = this.context;
        const { newMultiMediaFields, multiMediaErrors, multiMediaErrorHelper } = this.state;
        let newMultiMediaFieldsCopy = JSON.parse(JSON.stringify(newMultiMediaFields));
        let multiMediaErrorsCopy = JSON.parse(JSON.stringify(multiMediaErrors));
        let multiMediaErrorHelperCopy = JSON.parse(JSON.stringify(multiMediaErrorHelper));
        let targetName = field.target.name ? field.target.name : fieldName;
        let targetValue = field.target.value;
        switch (targetName) {
            case "trigger":
                multiMediaErrorsCopy[index].trigger = targetValue ? false : true;
                multiMediaErrorHelperCopy[index].trigger = targetValue
                    ? ""
                    : "* El campo no puede estar vacío";
                newMultiMediaFieldsCopy[index][targetName] = targetValue;
                break;
            default:
                newMultiMediaFieldsCopy[index][targetName] = targetValue;
                break;
        }
        updateContextAttribute("messagesHasChanges", true);
        this.setState({
            newMultiMediaFields: newMultiMediaFieldsCopy,
            multiMediaErrors: multiMediaErrorsCopy,
            multiMediaErrorHelper: multiMediaErrorHelperCopy,
        });
    };
    //editar mensajes Existentes, únicamente los <inputs> de mensaje y trigger
    editMultiMediaMessages = (field, index, fieldName) => {
        const { messagesTemp, updateContextAttribute } = this.context;
        const { existingMultiMediaErrors, existingMultiMediaErrorHelper } = this.state;
        let messagesTempCopy = JSON.parse(JSON.stringify(messagesTemp));
        let customMessagesCopy = messagesTempCopy.MULTIMEDIA_MESSAGES;
        let targetName = field.target.name;
        let targetValue = field.target.value;
        //console.log(targetName, targetValue, index, fieldName)
        if (fieldName) {
            customMessagesCopy[index][fieldName] = targetValue;
        }
        switch (targetName) {
            case "trigger":
                existingMultiMediaErrors[index].trigger = targetValue ? false : true;
                existingMultiMediaErrorHelper[index].trigger = targetValue
                    ? ""
                    : "* El campo no puede estar vacío";
                customMessagesCopy[index][targetName] = targetValue;
                break;
            default:
                customMessagesCopy[index][targetName] = targetValue;
                break;
        }
        messagesTempCopy.MULTIMEDIA_MESSAGES = customMessagesCopy;
        updateContextAttribute("messagesTemp", messagesTempCopy);
        updateContextAttribute("messagesHasChanges", true);
        this.setState({
            messages: messagesTempCopy,
        });
    };

    //eliminar campos mulitimedia y archivos guardados en el state
    removeMultiMediaMessageWithFile = (IDtoRemove, confirmDelete, deleteFrom) => {
        //Tenemos 3 estados que eliminar
        // 1- Los campos de mensaje y trigger, 2- los archivos cargados del <DropZone> 3- El arreglo de archivos a ser recibidos por el blob
        // 4- armamos un JSON con el nombre del archivo para eliminarlo del BLOB
        //console.log(IDtoRemove, confirmDelete, deleteFrom)
        const { messagesTemp, updateContextAttribute } = this.context;
        const {
            newMultiMediaFields,
            fileObjects,
            fileDataToSend,
            existingFileObjects,
            fileDataToDelete,
            multiMediaErrorHelper,
            multiMediaErrors,
            existingMultiMediaErrors,
            existingMultiMediaErrorHelper,
        } = this.state;
        let fileDataToDeleteCopy = [...fileDataToDelete];
        let messagesTempCopy = JSON.parse(JSON.stringify(messagesTemp));
        let existingMultiMediaErrorsCopy = [...existingMultiMediaErrors];
        let existingMultiMediaErrorHelperCopy = [...existingMultiMediaErrorHelper];

        //Dialogo para confirmación de eliminado
        this.setState({
            openDialog: true,
            dialog: {
                title: "¿Deseas eliminar este archivo multimedia?",
            },
            //ponemos en espera la info necesaria para proceder a eliminar
            removeFile: true,
            indexOfFile: IDtoRemove,
            deleteFrom: deleteFrom,
        });
        if (confirmDelete) {
            if (deleteFrom.existingFile) {
                //eliminamos los 3 campos existentes
                messagesTempCopy.MULTIMEDIA_MESSAGES.splice(IDtoRemove, 1);
                //sacamos el nombre del archivo y armamos el JSON
                let removedFile = existingFileObjects.splice(IDtoRemove, 1);
                let removedFileName = removedFile?.[0]?.[0].file.name;
                if (removedFileName) {
                    fileDataToDeleteCopy.push({ name: removedFileName });
                }
                //Todos los archivos existentes tienen el atributo de index para identificar la posición en la que este será editado/eliminado
                let indexOfDataToDelete = fileDataToSend.findIndex(
                    (data) => data.index === IDtoRemove
                );
                //encontramos la posición correcta a ser eliminada y se elimina
                if (indexOfDataToDelete >= 0) {
                    fileDataToSend.slice(indexOfDataToDelete, 1);
                }
                existingMultiMediaErrorsCopy.splice(IDtoRemove, 1);
                existingMultiMediaErrorHelperCopy.splice(IDtoRemove, 1);

                //guardamos los cambios
                updateContextAttribute("messagesTemp", messagesTempCopy);
                updateContextAttribute("messagesHasChanges", true);
                this.setState({
                    messages: messagesTempCopy,
                    fileDataToSend: fileDataToSend,
                    fileDataToDelete: fileDataToDeleteCopy,
                    removeFile: false,
                    existingFileObjects: existingFileObjects,
                    existingMultiMediaErrors: existingMultiMediaErrorsCopy,
                    existingMultiMediaErrorHelper: existingMultiMediaErrorHelperCopy,
                });
            } else if (deleteFrom.newFile) {
                //si es un archivo nuevo, simplemente quitamos los inputs y los errores, y el JSON a ser mandada al WS de blob
                //console.log(`IDtoRemove`, IDtoRemove)
                newMultiMediaFields.splice(IDtoRemove, 1);
                fileObjects.splice(IDtoRemove, 1);
                fileDataToSend.splice(IDtoRemove, 1);
                multiMediaErrors.splice(IDtoRemove, 1);
                multiMediaErrorHelper.splice(IDtoRemove, 1);
                this.setState({
                    newMultiMediaFields: newMultiMediaFields,
                    fileObjects: fileObjects,
                    fileDataToSend: fileDataToSend,
                    multiMediaErrors: multiMediaErrors,
                    multiMediaErrorHelper: multiMediaErrorHelper,
                    removeFile: false,
                });
            }
        }
    };

    //Guardamos los mensajes del contexto temporal en el contexto normal
    onSave = async () => {
        //Obtenemos el valor y las funciones de Procesos del contexto temporal
        const { messagesTemp, updateContextAttribute, sendFilesToBlob, getStorageData } =
            this.context;
        const {
            newMultiMediaFields,
            fileDataToSend,
            fileDataToDelete,
            fileObjects,
            existingFileObjects,
        } = this.state;

        let messagesCopy = JSON.parse(JSON.stringify(messagesTemp));
        const { token } = getStorageData(["token"]);
        messagesCopy.token = token;

        updateContextAttribute("messages", messagesTemp);
        updateContextAttribute("fileObjects", fileObjects);
        updateContextAttribute("existingFileObjects", existingFileObjects);

        if (fileDataToSend.length > 0 || fileDataToDelete.length > 0) {
            //si hay archivos pendientes mandamos a llamar a la funcion send files, y guardamos los mensajes desde alla
            await sendFilesToBlob(
                fileDataToSend.length > 0,
                fileDataToDelete.length > 0,
                fileDataToSend,
                fileDataToDelete,
                messagesCopy,
                newMultiMediaFields
            );
            return;
        }

        //Si no hay blob, entonces mandamos a llamar al servicio de guardado de mensajes
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");
        let res = await intebotDataActions(
            messagesCopy,
            "updateConfigurationVariables&enviroment=Test",
            "BotConfigurationOperations"
        );
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                updateContextAttribute("messagesHasChanges", false);
                updateContextAttribute("messagesHasSavedChanges", true);
                this.setState({
                    alert: {
                        open: true,
                        severity: "success",
                        message: "Mensajes Guardados Correctamente",
                    },
                });
                break;
            case 2:
                //El usuario no tiene permisos para realizar esta acción
                this.setState({
                    alert: {
                        open: true,
                        severity: "info", //success, error, warning, info
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    },
                });
                break;
            case 3:
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;

            default:
                //Error en el servicio
                this.setState({
                    alert: {
                        open: true,
                        severity: "error", //success, error, warning, info
                        message:
                            "Ocurrió un error al guardar los mensajes, por favor intenta de nuevo.",
                    },
                });
                break;
        }
    };

    //Restauramos los cambios hechos en el contexto temporal con el contexto normal
    onCancel = () => {
        const {
            messages,
            updateContextAttribute,
            newMultiMediaMessages,
            existingFileObjects,
            fileObjects,
            fileDataToSend,
        } = this.context;
        updateContextAttribute("messagesTemp", messages);
        updateContextAttribute("messagesHasChanges", false);
        //Revisamos si existen errores en el contexto temporal
        let errors = {};
        let multiMediaErrors = [];
        let multiMediaErrorHelper = [];
        let existingMultiMediaErrors = [];
        let existingMultiMediaErrorHelper = [];
        this.messagesKeys.forEach((key) => {
            if (messages[key.name] === "" && key.required) {
                errors[key.name] = true;
            } else {
                errors[key.name] = false;
            }
        });
        messages.MULTIMEDIA_MESSAGES.forEach((multimedia, index) => {
            existingMultiMediaErrors[index] = {
                file: existingFileObjects[index] ? false : true,
                message: false,
                trigger: multimedia.trigger ? false : true,
            };
            existingMultiMediaErrorHelper[index] = {
                file: existingFileObjects[index]
                    ? ""
                    : "* No se puede guardar un mensaje multimedia sin archivo ",
                message: false,
                trigger: multimedia.trigger ? "" : "* El campo no puede estar vacío",
            };
        });
        newMultiMediaMessages.forEach((multimedia, index) => {
            multiMediaErrors[index] = {
                file: fileObjects[index] ? false : true,
                message: false,
                trigger: multimedia.trigger ? false : true,
            };
            multiMediaErrorHelper[index] = {
                file: fileObjects[index] ? "" : "* El archivo multimedia no puede estar vacío",
                trigger: multimedia.trigger ? "" : "* El campo no puede estar vacío",
            };
        });
        //Contamos la cantidad de errores actualmente
        const countErrors = Object.values(errors).reduce((a, item) => a + item, 0);

        //Notificamos que se cancelaron los cambios exitosamente
        this.setState({
            errors: errors,
            countErrors: countErrors,
            alert: {
                open: true,
                severity: "success", //success, error, warning, info
                message: "Se restauraron los cambios",
            },
            newMultiMediaFields: newMultiMediaMessages,
            fileObjects: fileObjects,
            existingFileObjects: existingFileObjects,
            existingMultiMediaErrorHelper: existingMultiMediaErrorHelper,
            existingMultiMediaErrors: existingMultiMediaErrors,
            multiMediaErrorHelper: multiMediaErrorHelper,
            multiMediaErrors: multiMediaErrors,
            fileDataToSend: fileDataToSend,
        });
    };

    handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: "",
            },
        });
    };

    handleCloseDialog = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            openDialog: false,
        });
    };

    decideEnableSave = () => {
        const { messagesHasChanges, readOnly } = this.context;
        const { multiMediaErrors, existingMultiMediaErrors, countErrors } = this.state;
        //preguntamos si algun campo multimedia tiene un error, se deshabilita el botón
        let multiMediaHasErrors = //Nuevos archivos multimedia
            multiMediaErrors.some((error) => {
                let hasErrors = error.file || error.trigger ? true : false;
                return hasErrors;
            });
        let existingMultiMediaHasErrors = //Existentes archivos multimedia
            existingMultiMediaErrors.some((error) => {
                let hasErrors = error.file || error.trigger ? true : false;
                return hasErrors;
            });
        if (
            !messagesHasChanges ||
            countErrors > 0 ||
            multiMediaHasErrors ||
            existingMultiMediaHasErrors ||
            readOnly
        ) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const {
            messagesTemp,
            messagesHasChanges,
            messages,
            globalValues,
            getStorageData,
            readOnly,
        } = this.context;
        const {
            newMultiMediaFields,
            fileObjects,
            openDialog,
            dialog,
            queuedFile,
            indexOfFile,
            removeFile,
            replaceFile,
            replaceExistingFile,
            existingFileObjects,
            Loading,
            multiMediaErrors,
            multiMediaErrorHelper,
            existingMultiMediaErrors,
            existingMultiMediaErrorHelper,
            errorHelper,
        } = this.state;
        const customMessages = messagesTemp.MULTIMEDIA_MESSAGES;

        let storageData = getStorageData(["currentSubscription"]);
        let { currentSubscription } = storageData;
        let subscriptionWithSpecialElements = SUBSCRIPTIONS.some(
            (e) => e === currentSubscription.subscriptionId
        );

        this.messagesKeys.map((key, index) => {
            // si la suscripción es de SEG, mostrar campos especiales
            if (key.name === "MENU_SECTION2" || key.name === "DESCRIPTION_MENU_PRIVATE") {
                key.required = subscriptionWithSpecialElements;
                return (key.render = subscriptionWithSpecialElements);
            }
        });

        return (
            <React.Fragment>
                {/* Alertas como succes, error, info, las de <dropzone> se manejan interno al componente */}
                <CustomAlert
                    open={this.state.alert.open}
                    severity={this.state.alert.severity}
                    message={this.state.alert.message}
                    handleCloseAlert={() => this.handleCloseAlert()}
                />

                {/* Dialogo de Confirmación de remplazar o eliminar un file*/}
                <Dialog open={openDialog} onClose={() => this.handleCloseDialog()}>
                    <DialogTitle>{dialog.title}</DialogTitle>
                    <DialogContent>{dialog.message}</DialogContent>
                    <DialogActions>
                        <CustomButton onClick={() => this.handleCloseDialog()}>No</CustomButton>
                        <CustomButton
                            //dependiendo de donde fue invocado el dialogo, en confirmación lo regresamos a la función de invocación
                            onClick={() => {
                                const { deleteFrom } = this.state;
                                let userConfirmation = true;
                                if (removeFile) {
                                    this.removeMultiMediaMessageWithFile(
                                        indexOfFile,
                                        userConfirmation,
                                        deleteFrom
                                    );
                                }
                                if (replaceFile) {
                                    this.handleAddNewFiles(
                                        queuedFile,
                                        indexOfFile,
                                        userConfirmation
                                    );
                                }
                                if (replaceExistingFile) {
                                    this.replaceExistingFile(
                                        queuedFile,
                                        indexOfFile,
                                        userConfirmation
                                    );
                                }
                                this.handleCloseDialog();
                            }}
                            color="primary"
                            autoFocus
                        >
                            Sí
                        </CustomButton>
                    </DialogActions>
                </Dialog>
                {/* ==================== Inicio de la sección de Mensajes ===================== */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {/* Títulos */}
                    <h3 className="messagesForm__title-left">Mensaje</h3>
                    <h3 className="messagesForm__title-right">Detonante</h3>
                </div>
                <Grid item sm={12} xs={12}>
                    {/* =================SERVICE MESAGES================== */}
                    <Grid container justifyContent="space-around">
                        <Grid item sm={12} xs={12}>
                            {this.messagesKeys.map((message, index) =>
                                message.render ? (
                                    <InputChooser
                                        disabled={message.disabled}
                                        key={index}
                                        name={message.name}
                                        value={
                                            message.inputKeys
                                                ? message.inputKeys.map((msg) => {
                                                      return messagesTemp[msg.name];
                                                  })
                                                : messagesTemp[message.name]
                                        }
                                        type={message.type}
                                        label={
                                            message.inputKeys
                                                ? message.inputKeys.map((msg) => {
                                                      return msg.alias;
                                                  })
                                                : message.alias
                                        }
                                        detailText={message.detail}
                                        variant="outlined"
                                        required={
                                            message.inputKeys
                                                ? message.inputKeys.map((msg) => {
                                                      return msg.required;
                                                  })
                                                : message.required
                                        }
                                        error={
                                            message.inputKeys
                                                ? message.inputKeys.map((msg) => {
                                                      return typeof this.state.errors[msg.name] ===
                                                          "undefined"
                                                          ? false
                                                          : this.state.errors[msg.name];
                                                  })
                                                : this.state.errors[message.name]
                                        }
                                        onChange={(
                                            targetName,
                                            targetValue,
                                            targetType,
                                            targetRequired
                                        ) =>
                                            this.changeHandler(
                                                targetName,
                                                targetValue,
                                                targetType,
                                                message.required ? message.required : targetRequired
                                            )
                                        }
                                        doubleInput={message.inputKeys}
                                        errorHelper={errorHelper}
                                        messages={messages}
                                        autoCompleteList={globalValues}
                                    />
                                ) : null
                            )}
                        </Grid>
                    </Grid>
                    {/* ======================== Arreglo de Mensajes Multimedia con Archivos =========================*/}
                    {/* <div>
                        <h2>Mensajes Multimedia</h2>
                        <Grid container justifyContent="space-around">
                            <Grid item xs={12} sm={12} md={10}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h3>Archivo</h3>
                                        {Loading ? <CircularProgress /> : null}
                                    </div>
                                    <h3>Mensaje</h3>
                                    <h3>Detonante</h3>
                                    <div></div>
                                </div>
                                {customMessages
                                    ? customMessages.map((message, index) => (
                                          <div
                                              key={index}
                                              className="messageMultimedia__tableContent"
                                          >
                                              <Tooltip title="Arrastra o elige un archivo de tipo: png, jpg, mp4, excel, word o powerpoint. Máximo 4 MB">
                                                  <div>
                                                      <DropzoneAreaBase
                                                          fileObjects={existingFileObjects[index]}
                                                          maxFileSize={4000000}
                                                          filesLimit={1}
                                                          acceptedFiles={this.acceptedFiles}
                                                          showFileNames
                                                          onAdd={(newFileObjs) => {
                                                              this.replaceExistingFile(
                                                                  newFileObjs,
                                                                  index,
                                                                  !existingFileObjects[index]
                                                              );
                                                          }}
                                                          dropzoneClass={
                                                              existingMultiMediaErrors.length > 0
                                                                  ? existingMultiMediaErrors?.[
                                                                        index
                                                                    ]?.file
                                                                      ? "dropZoneContainerWithError"
                                                                      : "dropZoneContainer"
                                                                  : "dropZoneContainer"
                                                          }
                                                          alertSnackbarProps={{
                                                              anchorOrigin: {
                                                                  vertical: "top",
                                                                  horizontal: "right",
                                                              },
                                                          }}
                                                          dropzoneParagraphClass="dropzoneParagraph"
                                                          previewGridClasses={{
                                                              container: "previewGridContainer",
                                                              item: "previewGridItem",
                                                              image: "previewGridImage",
                                                          }}
                                                          getFileAddedMessage={(fileName) =>
                                                              `El archivo ${fileName} fue agregado con éxito`
                                                          }
                                                          getFileLimitExceedMessage={(filesLimit) =>
                                                              ` Número máximo de archivos alcanzados. Solamente se permiten ${filesLimit} archivos `
                                                          }
                                                          getFileRemovedMessage={(fileName) =>
                                                              ` El archivo ${fileName} ha sido eliminado. `
                                                          }
                                                          getDropRejectMessage={(rejectedFile) =>
                                                              ` Este archivo ${rejectedFile.name} no es compatible o Excede el máximo permitido de 4MB `
                                                          }
                                                          dropzoneText={
                                                              existingFileObjects[index]
                                                                  ? ""
                                                                  : "Archivo"
                                                          }
                                                      />
                                                      {existingMultiMediaErrorHelper?.[index]
                                                          ?.file && (
                                                          <span
                                                              style={{
                                                                  fontSize: "0.75rem",
                                                                  color: "red",
                                                              }}
                                                          >
                                                              {
                                                                  existingMultiMediaErrorHelper?.[
                                                                      index
                                                                  ]?.file
                                                              }
                                                          </span>
                                                      )}
                                                  </div>
                                              </Tooltip>
                                              <MentionsInput
                                                  name="message"
                                                  value={message.message}
                                                  onChange={(event) =>
                                                      this.editMultiMediaMessages(
                                                          event,
                                                          index,
                                                          "message"
                                                      )
                                                  }
                                                  style={StyledMentionInput}
                                                  allowSpaceInQuery
                                                  allowSuggestionsAboveCursor={true}
                                                  className="mentions--field"
                                              >
                                                  <Mention
                                                      style={{
                                                          backgroundColor: "#E0E0E0",
                                                          padding: "3px",
                                                          border: "1px solid #E0E0E0",
                                                          height: "32px",
                                                          borderRadius: "16px",
                                                          margin: "-4px",
                                                      }}
                                                      trigger="@"
                                                      markup="{{__id__}}"
                                                      displayTransform={(id) => `${id}`}
                                                      data={globalValues}
                                                      appendSpaceOnAdd
                                                      renderSuggestion={(suggestion) => (
                                                          <div
                                                              style={{
                                                                  display: "flex",
                                                                  width: "200px",
                                                              }}
                                                          >
                                                              <span
                                                                  style={{
                                                                      margin: "0",
                                                                  }}
                                                              >
                                                                  {suggestion.display}
                                                              </span>
                                                              <span
                                                                  style={{
                                                                      color: "#B2B2B2",
                                                                      fontSize: "13px",
                                                                  }}
                                                              >
                                                                  &nbsp;• {suggestion.list}
                                                              </span>
                                                          </div>
                                                      )}
                                                  />
                                              </MentionsInput>
                                              <TextField
                                                  style={{
                                                      backgroundColor: "#fafafa",
                                                  }}
                                                  name="trigger"
                                                  value={message.trigger}
                                                  variant="outlined"
                                                  multiline
                                                  size="small"
                                                  required={true}
                                                  onChange={(event) =>
                                                      this.editMultiMediaMessages(event, index)
                                                  }
                                                  error={
                                                      existingMultiMediaErrors.length > 0
                                                          ? existingMultiMediaErrors?.[index]
                                                                ?.trigger
                                                          : false
                                                  }
                                                  helperText={
                                                      existingMultiMediaErrorHelper.length > 0
                                                          ? existingMultiMediaErrors?.[index]
                                                                ?.trigger
                                                              ? existingMultiMediaErrorHelper?.[
                                                                    index
                                                                ]?.trigger
                                                              : ""
                                                          : ""
                                                  }
                                                  className="messageMultimedia__triggerField"
                                              />
                                              <Tooltip title="Eliminar archivo">
                                                  <IconButton
                                                      style={{ padding: "12px 0" }}
                                                      color="secondary"
                                                      onClick={() =>
                                                          this.removeMultiMediaMessageWithFile(
                                                              index,
                                                              false,
                                                              { existingFile: true }
                                                          )
                                                      }
                                                  >
                                                      <TrashCan width="20px" height="20px" />
                                                  </IconButton>
                                              </Tooltip>
                                          </div>
                                      ))
                                    : null}
                                {newMultiMediaFields
                                    ? newMultiMediaFields.map((field, index) => (
                                          <div
                                              key={index}
                                              className="messageMultimedia__tableContent"
                                          >
                                              <Tooltip title="Arrastra o elige un archivo de tipo: png, jpg, mp4, excel, word o powerpoint. Máximo 4 MB">
                                                  <div>
                                                      <DropzoneAreaBase
                                                          fileObjects={fileObjects[index]}
                                                          maxFileSize={4000000}
                                                          filesLimit={1}
                                                          acceptedFiles={this.acceptedFiles}
                                                          showFileNames
                                                          onAdd={(newFileObjs) =>
                                                              this.handleAddNewFiles(
                                                                  newFileObjs,
                                                                  index
                                                              )
                                                          }
                                                          dropzoneClass={
                                                              multiMediaErrors[index].file
                                                                  ? "dropZoneContainerWithError"
                                                                  : "dropZoneContainer"
                                                          }
                                                          dropzoneParagraphClass="dropzoneParagraph"
                                                          previewGridClasses={{
                                                              container: "previewGridContainer",
                                                              item: "previewGridItem",
                                                              image: "previewGridImage",
                                                          }}
                                                          alertSnackbarProps={{
                                                              anchorOrigin: {
                                                                  vertical: "top",
                                                                  horizontal: "right",
                                                              },
                                                          }}
                                                          dropzoneText={
                                                              fileObjects[index] ? "" : "Archivo"
                                                          }
                                                          getFileAddedMessage={(fileName) =>
                                                              `El archivo ${fileName} fue agregado con éxito`
                                                          }
                                                          getFileLimitExceedMessage={(filesLimit) =>
                                                              ` Número máximo de archivos alcanzados. Solamente se permiten ${filesLimit} archivos `
                                                          }
                                                          getFileRemovedMessage={(fileName) =>
                                                              ` El archivo ${fileName} ha sido eliminado. `
                                                          }
                                                          getDropRejectMessage={(rejectedFile) =>
                                                              ` Este archivo ${rejectedFile.name} no es compatible o Excede el máximo permitido de 4MB `
                                                          }
                                                      />
                                                      {multiMediaErrors[index].file ? (
                                                          <span
                                                              style={{
                                                                  fontSize: "0.75rem",
                                                                  color: "red",
                                                              }}
                                                          >
                                                              {multiMediaErrorHelper[index].file}
                                                          </span>
                                                      ) : null}
                                                  </div>
                                              </Tooltip>
                                              <MentionsInput
                                                  name="message"
                                                  value={field.message}
                                                  onChange={(event) =>
                                                      this.updateNewMultiMediaMessages(
                                                          event,
                                                          index,
                                                          "message"
                                                      )
                                                  }
                                                  style={StyledMentionInput}
                                                  placeholder="Mensaje"
                                                  className="mentions--field"
                                              >
                                                  <Mention
                                                      trigger="@"
                                                      markup="{{__id__}}"
                                                      displayTransform={(id) => `*{{${id}}}*`}
                                                      data={(search) =>
                                                          globalValues.filter((value) =>
                                                              value.id.includes(search)
                                                          )
                                                      }
                                                      renderSuggestion={(suggestion) => (
                                                          <div
                                                              style={{
                                                                  display: "flex",
                                                                  width: "200px",
                                                              }}
                                                          >
                                                              <p
                                                                  style={{
                                                                      margin: "0",
                                                                  }}
                                                              >
                                                                  {suggestion.display}
                                                              </p>
                                                              <span
                                                                  style={{
                                                                      color: "#B2B2B2",
                                                                      fontSize: "13px",
                                                                  }}
                                                              >
                                                                  &nbsp;• {suggestion.list}
                                                              </span>
                                                          </div>
                                                      )}
                                                  />
                                              </MentionsInput>
                                              <TextField
                                                  style={{
                                                      background: "#fafafa",
                                                  }}
                                                  error={multiMediaErrors[index].trigger}
                                                  name="trigger"
                                                  value={field.trigger}
                                                  variant="outlined"
                                                  multiline
                                                  size="small"
                                                  required={true}
                                                  placeholder="Detonante"
                                                  onChange={(event) =>
                                                      this.updateNewMultiMediaMessages(event, index)
                                                  }
                                                  helperText={
                                                      multiMediaErrors[index].trigger
                                                          ? multiMediaErrorHelper[index].trigger
                                                          : ""
                                                  }
                                                  className="messageMultimedia__triggerField"
                                              />
                                              <Tooltip title="Eliminar archivo">
                                                  <IconButton
                                                      style={{ padding: "12px 0" }}
                                                      color="secondary"
                                                      size="small"
                                                      onClick={() =>
                                                          this.removeMultiMediaMessageWithFile(
                                                              index,
                                                              false,
                                                              { newFile: true }
                                                          )
                                                      }
                                                  >
                                                      <TrashCan width="20px" height="20px" />
                                                  </IconButton>
                                              </Tooltip>
                                          </div>
                                      ))
                                    : null}
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        style={{
                                            textTransform: "none",
                                        }}
                                        disabled={multiMediaErrors.some((error) => {
                                            let hasErrors =
                                                error.file || error.trigger ? true : false;
                                            return hasErrors;
                                        })}
                                        onClick={this.addNewMultiMediaMessages}
                                        startIcon={<AgregarIcono width="15px" height="15px" />}
                                        size="small"
                                    >
                                        {" "}
                                        Agregar nuevo mensaje multimedia{" "}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div> */}
                </Grid>
                <MandatoryField />
                <FooterControls
                    disableSave={this.decideEnableSave()}
                    disableCancel={!messagesHasChanges || readOnly}
                    onSave={() => this.onSave()}
                    onCancel={() => this.onCancel()}
                    showChatBtn={true}
                />
            </React.Fragment>
        );
    }
}
MessagesForm.contextType = AppContext;
