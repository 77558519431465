import React from 'react';
//Mui components
import { TextField, MenuItem } from '@material-ui/core';

//Icons
import AgregarIcono from '../../Icons/AgregarIcono';

//Custom Portal Components
import EditCatalogModal from './EditCatalogModal';
import CustomAlert from '../common/Alerts/CustomAlert';
import AppContext from '../../Context/AppContext';

export default class AddCatalogForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hasChanges: false,
            alert: {
                open: false,
                severity: '',
                message: ''
            },
        }
    }

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: ''
            }
        });
    }

    render() {
        const { handleCloseAlert, alert } = this.state
        const {
            catalogs,
            className,
            name,
            value,
            variant,
            error,
            onChange,
            helperText,
            addNewCatalog,
        } = this.props;
        return(
            <React.Fragment>
                <CustomAlert
                    open={alert.open}
                    severity={alert.severity}
                    message={alert.message}
                    handleCloseAlert={() => handleCloseAlert()}
                />
                <TextField
                    select
                    className={className}
                    name={name}
                    value={value}
                    variant={variant}
                    error={error}
                    fullWidth
                    size="small"
                    onChange={(event) => onChange(event)}
                    helperText={helperText}
                >
                    {catalogs ?
                        catalogs.map((catalog, index) => 
                            <MenuItem key={index} value={catalog.name}>{catalog.name}</MenuItem>
                        )
                    :
                        <MenuItem value={''}>No hay opciones disponibles</MenuItem>
                    }
                </TextField>
                <EditCatalogModal
                    buttonName="Agregar nuevo catálogo"
                    buttonTooltip="Abrir ventana"
                    buttonType="link"
                    startIcon={<AgregarIcono width="15px" height="15px"/>}
                    catalogList={catalogs.map(obj => obj.name)}
                    catalog={{name: '', erasable: true, catalog:[{key: '', values: []}]}}
                    addNewCatalog={(tempCatalog) => addNewCatalog(tempCatalog)}
                    tempSave
                    noConfirm
                    alignButtonRight={true}
                />
            </React.Fragment>
        )
    }
}

AddCatalogForm.contextType = AppContext;