import React, { useContext } from 'react';

import {
    MenuList,
    MenuItem,
    Drawer,
    CircularProgress,
} from '@material-ui/core/'

//Styles
import './styles/PLNleftMenuBar.css'

//Custom components
import AppContext from '../../Context/AppContext';



const PLNleftMenuBar = (props) => {
    const { setPLNComponent, loading, openPLNmenu, setOpenPLNmenu } = props;
    const { getStorageData, matchesUpMd } = useContext(AppContext);

    let storageData = getStorageData([
        "exclusiveServices",
    ])

    const PLNmenuItems =[
        {
            section: "Elementos del lenguaje",
            menuItems: ["Intenciones", "Entidades"]
        },
        {
            section: "Resultados de desempeño",
            menuItems: ["Probar el entrenamiento", "Apps de conversación", "Sugerencias para el entrenamiento"]
        }
    ]

    return (
        <React.Fragment>
            <Drawer
                className="PLN-permanent-drawer"
                variant={ matchesUpMd ? "permanent" : "temporary" }
                PaperProps={
                    matchesUpMd ? { className: "drawer-paper" } : { className: "drawer-paper-mobile" }
                }  
                anchor="left"
                open={ matchesUpMd ? true : openPLNmenu }
                onClose={ matchesUpMd ? null : () => setOpenPLNmenu(false)}
            >
                <MenuList className='menu-list-container'>
                    {
                        PLNmenuItems.map(menuObject =>                            
                            <div>
                                <h3>{menuObject.section}</h3>
                                {
                                    loading ?
                                        <div className="center-loading-icon">
                                            <CircularProgress />
                                        </div>
                                    : null
                                }
                                {
                                    !loading && menuObject.menuItems.map(item => {
                                        if(!storageData.exclusiveServices.processNaturalLenguage && item === "Apps de conversación") return null;
                                        return(
                                            <MenuItem 
                                                className="menu-item-text-overflow" 
                                                onClick={() => {setPLNComponent(item); setOpenPLNmenu(false)}} 
                                            >
                                                { "• " + item}
                                            </MenuItem>
                                        )
                                    }
                                    )
                                }
                            </div>
                        )
                    }
                </MenuList>
            </Drawer>
        </React.Fragment>
    );
}

export default PLNleftMenuBar;
