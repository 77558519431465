import React from "react";

//MUI components
import { Grid, TextField, MenuItem, Tooltip, IconButton } from "@material-ui/core";

//Icons
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";

const userStatusInfo = [
    {
        status: "VERIFIED",
        name: "Aceptada",
        detail: "Este usuario esta listo para utilizar el portal.",
    },
    {
        status: "PENDING_VERIFICATION",
        name: "Pendiente",
        detail: "En proceso de registro y verificación de correo",
    },
];

const PermissionUserInfoRow = (props) => {
    const {
        id,
        name,
        email,
        role,
        columns,
        status,
        handlePermissionsSelector,
        handleDeleteUser,
        permissionsOptions,
        currentUserEmail,
    } = props;

    let userStatusDetail;
    let statusName;
    let userCurrentStatus;
    switch (status) {
        case "VERIFIED":
            userCurrentStatus = userStatusInfo.find((info) => info.status === "VERIFIED");
            userStatusDetail = userCurrentStatus.detail;
            statusName = userCurrentStatus.name;
            break;
        case "PENDING_VERIFICATION":
            userCurrentStatus = userStatusInfo.find(
                (info) => info.status === "PENDING_VERIFICATION"
            );
            userStatusDetail = userCurrentStatus.detail;
            statusName = userCurrentStatus.name;
            break;
        default:
            break;
    }

    return (
        <div className="manage-users-row-container">
            <label style={{ width: `${columns[0].width}px`, padding: "15px" }}>{name}</label>
            <label style={{ width: `${columns[1].width}px`, padding: "15px" }}>{email}</label>
            {role === "owner" ? (
                <label style={{ width: `${columns[2].width}px`, padding: "15px" }}>
                    <div
                        style={{
                            border: "1px solid #B2B2B2",
                            paddingLeft: "14px",
                            padding: "8px 14px",
                            fontSize: "16px",
                            borderRadius: "4px",
                        }}
                    >
                        Dueño
                    </div>
                </label>
            ) : (
                <TextField
                    disabled={role === "owner"}
                    select
                    name="permissionSelector"
                    value={role}
                    style={{ width: `${columns[2].width}px`, padding: "15px" }}
                    className="permissions-selector"
                    variant="outlined"
                    size="small"
                    onChange={(event) => handlePermissionsSelector(event.target.value, id)}
                >
                    {permissionsOptions.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: `${columns[3].width}px`,
                    padding: "15px",
                }}
            >
                {role !== "owner" && (
                    <Tooltip title={userStatusDetail}>
                        <label>{statusName}</label>
                    </Tooltip>
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: `${columns[4].width}px`,
                    padding: "15px",
                }}
            >
                {role !== "owner" && email !== currentUserEmail && (
                    <Tooltip title="Eliminar usuario">
                        <IconButton
                            color="secondary"
                            size="small"
                            onClick={() => handleDeleteUser(id)}
                        >
                            <TrashCan width="20px" height="20px" />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default PermissionUserInfoRow;
