import React, { useState, useEffect, useContext, useRef } from "react";

//Custom portal components
import PLNleftMenuBar from "../components/PLNpageComponents/PLNleftMenuBar";
import EditIntentsTable from "../components/PLNpageComponents/EditIntentsTable";
import TestPLNTraining from "../components/PLNpageComponents/TestPLNTraining";
import EditEntitiesTable from "../components/PLNpageComponents/EditEntitiesTable";
import { actionsPLNManagement } from "../components/DataFunctions";
import AppContext from "../Context/AppContext";
import Button from "../components/common/CustomButton";
import ConversationApps from "../components/PLNpageComponents/ConversationApps";
import ReviewEndpointUtterances from "../components/PLNpageComponents/ReviewEndpointUtterances";

//Material UI components
import { Button as MUIButton } from "@material-ui/core/";

//Icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

//Styles
import "./StyledPages/PLNpage.css";

export default function PermanentDrawerLeft() {
    //Context
    const { sleep, updateContextAttribute, getStorageData, matchesUpMd, readOnly } =
        useContext(AppContext);
    let storageData = getStorageData(["appId", "appName", "exclusiveServices", "PLNModule"]);

    //State
    const [PLNComponent, setPLNComponent] = useState("Intenciones");
    const [loading, setLoading] = useState(false);
    const [errorInFetch, setErrorInFetch] = useState(false);
    const [intentPhraseList, setIntentPhraseList] = useState([]);
    const [entityPhraseList, setEntityPhraseList] = useState([]);
    const [intentsList, setIntentsList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [stateAppId, setAppId] = useState(storageData.appId);
    const [appName, setAppName] = useState(
        storageData.appName ?? storageData.PLNModule.defaultAppName
    );
    const [changeAppHasChanged, setChangeAppHasChanged] = useState(false);
    const [tempAppId, setTempAppId] = useState("");
    const [PLNtakenNamesList, setPLNtakenNamesList] = useState([]);
    const [needsTraining, setNeedsTraining] = useState(false);
    const [openPLNmenu, setOpenPLNmenu] = useState(false);

    //wait for the app to load then change name
    const tempAppName = useRef("");

    const isMounted = useRef(true);

    //DidMount
    useEffect(() => {
        isMounted.current = true;
        const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
        if (remeberAuthCredentials) {
            localStorage.setItem("appId", stateAppId);
            localStorage.setItem("appName", appName);
        } else {
            sessionStorage.setItem("appId", stateAppId);
            sessionStorage.setItem("appName", appName);
        }
        refreshPLNData();
        return () => {
            isMounted.current = false;
            document.body.classList.remove("hide-overflow");
        };
    }, [stateAppId, appName]);

    useEffect(() => {
        decideHideOverflow();
    }, [loading, errorInFetch, intentsList, entityList]);

    const clearState = () => {
        setEntityPhraseList([]);
        setIntentPhraseList([]);
        setPLNtakenNamesList([]);
    };

    const refreshPLNData = async () => {
        setLoading(true);
        document.body.classList.add("hide-overflow");
        clearState();
        //Si el componente no esta montado cancelamos las operaciones
        if (!isMounted.current) return;
        await sleep(1000);
        //console log timer
        // console.time("PLNpage");
        // console.time("getPhraseList");
        let { intentPhraseList = [], entityPhraseList = [] } = (await getPhraseList()) ?? {};
        // console.timeEnd("getPhraseList");
        await sleep(300);
        //console.time("getFeatureList");
        let featureList = await getFeatureList();
        //console.timeEnd("getFeatureList");
        await sleep(300);
        //console.time("getIntentsList");
        let intentsList = await getIntentsList();
        //console.timeEnd("getIntentsList");
        await sleep(300);
        //console.time("getEntintyList");
        let entityList = await getEntintyList();
        //console.timeEnd("getEntintyList");
        await sleep(300);
        //console.time("getTrainStatus");
        let needsTraining = await getNeedsTraining();
        //console.timeEnd("getTrainStatus");
        setLoading(false);
        document.body.classList.remove("hide-overflow");

        //console log timer
        console.timeEnd("PLNpage");

        if (!isMounted.current) return;

        //si alguna de los servicios falló, se muestra un mensaje de error
        if (!intentPhraseList || !entityPhraseList || !featureList || !intentsList || !entityList)
            return;

        //Una vez que obtuvimos los datos, se crean los objetos para mostrar la info del portal
        //Create selectableFeatures = featureList + entityList
        createSelectableFeatures(featureList, entityList);
        //create intentListObj = intentlist + intentPhraseList
        //createIntentListObj(intentsList, intentPhraseList);
        //create entityListObj = entityList + entityPhraseList
        createEntityListObj(entityList, entityPhraseList);

        let intentListNames = intentsList.map((intent) => {
            return { name: intent.intentName, id: intent.intentId };
        });
        let entityListNames = getEntityListNames(entityList);

        //merge intentListNames with entityListNames
        let PLNtakenNamesList = intentListNames.concat(entityListNames);

        setPLNtakenNamesList(PLNtakenNamesList);
        setEntityPhraseList(entityPhraseList);
        setIntentPhraseList(intentPhraseList);
        setNeedsTraining(needsTraining);
        setIntentsList(intentsList);

        setErrorInFetch(false);
    };

    const getEntityListNames = (entityList) => {
        let entityListNames = [];
        for (let entity of entityList) {
            const { entityType = "", readableType = "", children = [] } = entity;
            entityListNames.push({ name: entity.name, id: entity.id });
            if (entityType !== "Entity Extractor" && readableType !== "Child Entity Extractor")
                continue;
            if (children.length > 0) {
                entityListNames.push(...getEntityListNames(children));
            }
        }
        return entityListNames;
    };

    const extractNames = (obj) => {
        const names = [obj.name];
        if (obj.children) {
            names.push(...obj.children.flatMap(extractNames));
        }
        return names;
    };

    const createEntityListObj = (entityList, entityPhraseList) => {
        //agregamos la featureList a cada entidad
        entityList.forEach((entity) => {
            entityPhraseList.forEach((entityPhrase) => {
                if (entity.entityName === entityPhrase.entityName) {
                    entity.features = entityPhrase.features;
                }
            });
        });
        setEntityList(entityList);
    };

    // const createIntentListObj = (intentsList, intentPhraseList) => {
    //     intentsList.forEach((intent) => {
    //         //agregamos las phraseList a cada intent
    //         intentPhraseList.map((intentPhrase) => {
    //             if (intentPhrase.name === intent.intentName) {
    //                 intent.features = intentPhrase.features;
    //             }
    //         });

    //         //creamos las frases de cada ejemplo con los indices de las etiquetas
    //         intent.exampleList.forEach((exampleObj) => {
    //             const { examples = [], tokenizedText } = exampleObj;
    //             examples.forEach((example, index) => {
    //                 const { startTokenIndex, endTokenIndex } = example;
    //                 example["phrase"] = tokenizedText
    //                     .slice(startTokenIndex, endTokenIndex + 1)
    //                     .join(" "); //-> obtenemos la frase de los indices de los tokens;
    //                 example["entityId"] = index;
    //             });
    //         });
    //     });
    //     setIntentsList(intentsList);
    // };

    const getTrainStatus = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "trainStatus");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let status = res.data.data.details.status;
                return status;
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const getNeedsTraining = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "getStatus");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let status = res.data.data.trainingStatus;
                return status === "NeedsTraining";
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const createSelectableFeatures = (featureList, entityList) => {
        let selectableFeatures = [];
        featureList.forEach((feature) => {
            selectableFeatures.push({ name: feature.name, type: "feature" });
        });
        entityList.forEach((entity) => {
            selectableFeatures.push({ name: entity.name, type: "entity" });
        });
        setFeatureList(selectableFeatures);
    };

    const getEntintyList = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "getEntityList");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
        switch (responseCode) {
            case 0:
                let entityListCopy = JSON.parse(JSON.stringify(res.data.data));
                entityListCopy.map((entity) => {
                    const { children = [], name, id } = entity;
                    let level = 0;
                    entity["level"] = level;
                    entity["parents"] = [];
                    if (children.length === 0) return;
                    children.map((child) => {
                        const { children: children1, name: name1, id: id1 } = child;
                        let level = 1;
                        child["parents"] = [{ name, id, level: 0 }];
                        child["level"] = level;
                        if (children1.length === 0) return;
                        children1.map((child2) => {
                            const { children: children2, name: name2, id: id2 } = child2;
                            let level = 2;
                            child2["level"] = level;
                            child2["parents"] = [
                                { name, id, level: 0 },
                                { name: name1, id: id1, level: 1 },
                            ];
                            if (children2.length === 0) return;
                            children2.map((child3) => {
                                const { children: children3, name: name3, id: id3 } = child3;
                                let level = 3;
                                child3["level"] = level;
                                child3["parents"] = [
                                    { name, id, level: 0 },
                                    { name: name1, id: id1, level: 1 },
                                    { name: name2, id: id2, level: 2 },
                                ];
                                if (children3.length === 0) return;
                                children3.map((child4) => {
                                    let level = 4;
                                    child4["level"] = level;
                                    child4["parents"] = [
                                        { name, id, level: 0 },
                                        { name: name1, id: id1, level: 1 },
                                        { name: name2, id: id2, level: 2 },
                                        { name: name3, id: id3, level: 3 },
                                    ];
                                });
                            });
                        });
                    });
                });
                return entityListCopy;
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const getIntentsList = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        const res = await actionsPLNManagement(data, "getIntentList");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
        switch (responseCode) {
            case 0:
                let intentsList = res.data.data;
                // for (const intent of intentsList) {
                //     let { exampleList } = intent;
                //     for (const exampleObj of exampleList) {
                //         let { examples, tokenizedText } = exampleObj;
                //         examples.forEach((entity, index) => {
                //             const { entityName, startIndex, length, text, modelType } = entity;

                //             //create a uniqid using substring of the entity name and the index and a random number
                //             let uniqid =
                //                 entityName.substring(0, 3) +
                //                 index +
                //                 Math.floor(Math.random() * 1000);
                //             entity["uniqid"] = uniqid;

                //             if (
                //                 ["Regex Entity Extractor", "List Entity Extractor"].includes(
                //                     modelType
                //                 )
                //             ) {
                //                 let endTokenIndex;
                //                 let startTokenIndex;
                //                 let entityPhrase;
                //                 // Calculate the end index from the start index and the length of the entity
                //                 let endIndex = startIndex + length;

                //                 //Search the start token
                //                 let sumWordLength = 0;
                //                 // Loop through each word in the tokenized text array
                //                 for (let index = 0; index < tokenizedText.length; index++) {
                //                     const word = tokenizedText[index];
                //                     // Add the length of the word and a space to the sum
                //                     let wordLength = word.length + 1;
                //                     sumWordLength += wordLength;
                //                     // If the sum is greater than the start index, we have found the start token
                //                     if (sumWordLength > startIndex) {
                //                         startTokenIndex = index;
                //                         break;
                //                     }
                //                 }
                //                 //Buscamos el token final
                //                 let sumWordLength2 = 0;
                //                 for (let index = 0; index < tokenizedText.length; index++) {
                //                     const word = tokenizedText[index];
                //                     let wordLength = word.length + 1;
                //                     sumWordLength2 += wordLength;
                //                     // If the sum is greater than the start index, we have found the start token
                //                     if (sumWordLength2 > endIndex) {
                //                         endTokenIndex = index;
                //                         break;
                //                     }
                //                 }
                //                 entityPhrase = {
                //                     phrase: text,
                //                     startTokenIndex: startTokenIndex,
                //                     endTokenIndex: endTokenIndex,
                //                     entityName: entityName,
                //                     modelType: modelType,
                //                 };
                //                 examples[index] = entityPhrase;
                //             }
                //         });
                //     }
                // }
                return intentsList;
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const getPhraseList = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "getPhraseList");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
        switch (responseCode) {
            case 0:
                let phraseList = res.data.data;
                const { Intent: intentPhraseList, Entity: entityPhraseList } = phraseList;
                return { intentPhraseList, entityPhraseList };
            default:
                setErrorInFetch(true);
                return { intentPhraseList: null, entityPhraseList: null };
        }
    };

    const getFeatureList = async () => {
        if (!isMounted.current) return;
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "getFeatureList");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
        switch (responseCode) {
            case 0:
                let featuresList = res.data.data;
                return featuresList;
            default:
                setErrorInFetch(true);
                return null;
        }
    };

    const trainPLNpage = async () => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Entrenado PLN...");
        let data = null;
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "train");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                //we will ask for the status of the train status every 2 seconds until the status is Success or Error
                let trainStatus = res.data.data.status;
                while (trainStatus === "InProgress" || trainStatus === "Queued") {
                    await sleep(2000);
                    trainStatus = await getTrainStatus();
                }
                if (trainStatus === "Success" || trainStatus === "UpToDate") {
                    let alertSuccess = {
                        open: true,
                        severity: "success", //success, error, warning, info
                        message: "El portal de PLN fue entrenado de manera correcta",
                    };
                    updateContextAttribute("alert", alertSuccess);
                    refreshPLNData();
                    break;
                }
                if (trainStatus === "Fail") {
                    let alertError = {
                        open: true,
                        severity: "error", //success, error, warning, info
                        message: "Hubo un error al entrenar el portal de PLN",
                    };
                    updateContextAttribute("alert", alertError);
                    break;
                }
                break;
            case 2:
                let alertEmptyIntent = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "No se puede entrenar una aplicación con intenciones vacías.",
                };
                updateContextAttribute("alert", alertEmptyIntent);
                break;
            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Hubo un error al entrenar el portal de PLN",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
    };

    const publishPLNpage = async () => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Publicando el portal PLN...");
        let data = {
            enviroment: "production",
        };
        if (storageData.exclusiveServices.processNaturalLenguage && stateAppId) {
            data = {
                ...data,
                appID: stateAppId,
            };
        }
        let res = await actionsPLNManagement(data, "publishOnEnviroment");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                let alertSuccess = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "El portal de PLN fue publicado de manera correcta",
                };
                updateContextAttribute("alert", alertSuccess);
                break;
            case 2:
                let alertEmptyIntent = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "No se puede publicar una aplicación con intenciones vacías.",
                };
                updateContextAttribute("alert", alertEmptyIntent);
                break;
            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Hubo un error al publicar el portal de PLN",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const loadSelectedApp = async () => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Cargando aplicación...");
        setAppId(tempAppId);
        setAppName(tempAppName.current);
        //sleep 500ms to wait for the app name to be set
        await sleep(500);
        setPLNComponent("Intenciones");
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
    };

    const decideHideOverflow = () => {
        if (loading || errorInFetch || (intentsList.length < 5 && entityList.length < 5)) {
            return document.body.classList.add("hide-overflow");
        }
        return document.body.classList.remove("hide-overflow");
    };

    const intentsTableProps = {
        loading,
        setLoading,
        errorInFetch,
        setErrorInFetch,
        intentPhraseList,
        entityPhraseList,
        entityList,
        intentsList,
        refreshPLNData,
        featureList,
        setIntentsList,
        appName,
        stateAppId,
        PLNtakenNamesList,
    };

    const entitiesTableProps = {
        entityList,
        setEntityList,
        refreshPLNData,
        loading,
        errorInFetch,
        appName,
        PLNtakenNamesList,
    };

    const getCurrentPLNcomponent = () => {
        switch (PLNComponent) {
            case "Intenciones":
                return (
                    <EditIntentsTable
                        //props
                        {...intentsTableProps}
                    />
                );
            case "Entidades":
                return (
                    <EditEntitiesTable
                        //props
                        {...entitiesTableProps}
                    />
                );
            case "Probar el entrenamiento":
                return <TestPLNTraining entityList={entityList} />;
            case "Apps de conversación":
                return (
                    <ConversationApps
                        //props
                        tempAppId={tempAppId}
                        setTempAppId={setTempAppId}
                        setChangeAppHasChanged={setChangeAppHasChanged}
                        changeAppHasChanged={changeAppHasChanged}
                        tempAppName={tempAppName}
                    />
                );
            case "Sugerencias para el entrenamiento":
                return (
                    <ReviewEndpointUtterances
                        //props
                        intentsList={intentsList}
                        entityList={entityList}
                    />
                );
            default:
                return <div>{PLNComponent}</div>;
        }
    };

    const chooseFooterButtons = () => {
        switch (PLNComponent) {
            case "Apps de conversación":
                return (
                    <Button
                        disabled={!changeAppHasChanged || loading}
                        onClick={() => loadSelectedApp()}
                    >
                        Cargar aplicación
                    </Button>
                );

            default:
                return (
                    !readOnly && (
                        <>
                            <Button
                                disabled={loading || errorInFetch || !needsTraining}
                                onClick={() => trainPLNpage()}
                            >
                                Entrenar PLN
                            </Button>
                            <Button
                                variant="extended"
                                className={
                                    loading || errorInFetch
                                        ? "disabled-publish-pln-btn"
                                        : "publish-pln-btn"
                                }
                                onClick={() => publishPLNpage()}
                            >
                                Publicar
                            </Button>
                        </>
                    )
                );
        }
    };

    return (
        <div className="PLN-main-page">
            {!matchesUpMd && (
                <MUIButton
                    className="open-mobile-pln-menu-btn"
                    onClick={() => {
                        setOpenPLNmenu(!openPLNmenu);
                    }}
                    endIcon={<ArrowForwardIosIcon width="20px" height="20px" />}
                >
                    Entrenamiento
                </MUIButton>
            )}
            <PLNleftMenuBar
                setOpenPLNmenu={setOpenPLNmenu}
                openPLNmenu={openPLNmenu}
                loading={loading}
                setPLNComponent={setPLNComponent}
            />
            <main className="PLN-main-content-table">
                {getCurrentPLNcomponent()}
                {decideHideOverflow()}
            </main>
            <footer className="PLN-footer-page">{chooseFooterButtons()}</footer>
        </div>
    );
}
