import React from "react";
//Mui components
import { Grid } from "@material-ui/core";

//Icons
import { ReactComponent as TrashCan } from "../Icons/trashcanIcon.svg";
import AgregarIcono from "../Icons/AgregarIcono";
import EditarIcono from "../Icons/EditarIcono";

//Custom Portal Components
import EditCatalogModal from "../components/CatalogsTableComponents/EditCatalogModal";
import Table from "../components/common/Tables/StyledTable";
import ButtonConfirmDialog from "../components/common/ButtonConfirmDialog";
import CustomAlert from "../components/common/Alerts/CustomAlert";
import FooterControls from "../components/FooterComponents/FooterControls";
import AppContext from "../Context/AppContext";
import { intebotDataActions } from "../components/DataFunctions";

//swal
import Swal from "sweetalert2";

export default class CatalogsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogProcessArray: [], //Para checar relación del catálogo en los procesos
            processNamesCatalogUsed: {},
            //hasChanges: false,
            alert: {
                open: false,
                severity: "", //success, error, warning, info
                message: "",
            },
        };
    }

    //Al cargarse el componente tenemos que realizar algunas operaciones
    componentDidMount() {
        const { catalogsTemp, processes } = this.context;
        if (catalogsTemp.length > 0) {
            //Significa que ya tenemos datos en el contexto temporal y debemos cargar el catálogo de procesos
            let catalogProcessArray = [];
            let processNamesCatalogUsed = [];
            //Recorremos todos los pasos de todos los procesos para guardar en un arreglo los catálogos asociados
            processes.forEach((process) => {
                process.steps.forEach((step) => {
                    if (step.catalog) {
                        catalogProcessArray.push(step.catalog);
                        processNamesCatalogUsed.push({
                            catalog: step.catalog,
                            processName: process.processName,
                            step: step.step + 1,
                        });
                    }
                });
            });
            this.setState({
                catalogProcessArray: catalogProcessArray,
                processNamesCatalogUsed: processNamesCatalogUsed,
            });
        }
    }

    //Guardamos los cambios del contexto temporal en la base de test
    onSave = async () => {
        //Destructuramos todos los objetos a utilizar
        const { catalogsTemp, updateContextAttribute, getStorageData } = this.context;
        const { token } = getStorageData(["token"]);
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");

        //Llamamos al servicio para guardar el catálogo en la base de datos
        let data = {
            catalogs: catalogsTemp,
            token,
        };
        let res = await intebotDataActions(
            data,
            "updateConfigurationCatalogs&enviroment=Test",
            "ModelConfigurationOperations"
        );
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        //Manejamos la respuesta del servicio
        switch (responseCode) {
            case 0:
                //Guardamos en el estado y en el contexto
                this.setState({
                    hasChanges: false,
                    alert: {
                        open: true,
                        severity: "success",
                        message: "catalogos guardados correctamente",
                    },
                });
                updateContextAttribute("catalogs", catalogsTemp);
                updateContextAttribute("catalogsTemp", catalogsTemp);
                updateContextAttribute("catalogsHasSavedChanges", true);
                break;
            case 2:
                //El usuario no tiene permisos para realizar esta acción
                this.setState({
                    alert: {
                        open: true,
                        severity: "info", //success, error, warning, info
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    },
                });
                break;
            case 3:
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;

            default:
                //Ocurrió un error al guardar el catálogo
                this.setState({
                    alert: {
                        open: true,
                        severity: "error",
                        message:
                            "Ocurrió un error al guardar el catálogo, por favor intenta de nuevo.",
                    },
                });
                break;
        }
    };

    //Borramos un catálogo del contexto temporal
    onDelete = (id) => {
        const { catalogsTemp, updateContextAttribute } = this.context;
        let tempCatalogsCopy = JSON.parse(JSON.stringify(catalogsTemp));
        tempCatalogsCopy.splice(id, 1);
        this.setState({
            alert: {
                open: true,
                severity: "success", //success, error, warning, info
                message: "Se eliminó el catálogo temporalmente",
            },
        });
        updateContextAttribute("catalogsTemp", tempCatalogsCopy);
        updateContextAttribute("catalogsHasChanges", true);
    };

    //Revertimos los cambios del contexto temporal con el contexto normal
    onCancel = () => {
        const { catalogs, updateContextAttribute } = this.context;
        this.setState({
            alert: {
                open: true,
                severity: "success", //success, error, warning, info
                message: "Se restauraron los cambios",
            },
        });
        updateContextAttribute("catalogsTemp", catalogs);
        updateContextAttribute("catalogsHasChanges", false);
    };

    handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: "",
            },
        });
    };

    render() {
        const { catalogsTemp, catalogsHasChanges } = this.context;
        const { catalogProcessArray, alert, processNamesCatalogUsed } = this.state;

        return (
            <React.Fragment>
                <CustomAlert
                    open={alert.open}
                    severity={alert.severity}
                    message={alert.message}
                    handleCloseAlert={() => this.handleCloseAlert()}
                />
                <Table className="hideLastColumn">
                    <thead>
                        <tr>
                            <th width="14%">ID</th>
                            <th width="80%">Nombre del Catálogo</th>
                            <th width="3%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {catalogsTemp.map((catalog, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <EditCatalogModal
                                        buttonName={catalog.name}
                                        buttonTooltip="Editar catálogo"
                                        buttonType="link"
                                        catalogList={catalogsTemp.map((obj) =>
                                            obj.name === catalog.name ? "" : obj.name
                                        )}
                                        catalog={catalog}
                                        catalogId={index}
                                        startIcon={<EditarIcono width="15px" height="15px" />}
                                    />
                                </td>
                                <td>
                                    <div>
                                        {catalogProcessArray.includes(catalog.name) ? (
                                            <ButtonConfirmDialog
                                                title={
                                                    <span>
                                                        {" "}
                                                        El catálogo <b>{catalog.name}</b> no se
                                                        puede eliminar porque está relacionado con
                                                        los flujos:{" "}
                                                        {processNamesCatalogUsed
                                                            .filter(
                                                                (takenCatalog) =>
                                                                    takenCatalog.catalog ===
                                                                    catalog.name
                                                            )
                                                            .map((currentCatalog, index) => (
                                                                <div key={index}>
                                                                    •{" "}
                                                                    <span>
                                                                        {currentCatalog.processName}{" "}
                                                                        en el Paso{" "}
                                                                        {currentCatalog.step}
                                                                    </span>
                                                                </div>
                                                            ))}{" "}
                                                    </span>
                                                }
                                                confirmName="Ok"
                                                tooltip={"Eliminar catálogo"}
                                                color="secondary"
                                                size="small"
                                                icon={<TrashCan width="20px" height="20px" />}
                                            />
                                        ) : !catalog.erasable ? null : ( //Si el catalogo no permite ser borrado, quitamos el Icono de "Eliminar"
                                            <ButtonConfirmDialog
                                                title={
                                                    <span>
                                                        ¿Deseas eliminar el catálogo{" "}
                                                        <b>{catalog.name}</b>?
                                                    </span>
                                                }
                                                confirmName="Sí"
                                                cancelName="No"
                                                tooltip={"Eliminar catálogo"}
                                                color="secondary"
                                                size="small"
                                                onConfirm={() => this.onDelete(index)}
                                                icon={<TrashCan width="20px" height="20px" />}
                                            />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <EditCatalogModal
                                buttonName="Agregar nuevo catálogo"
                                buttonTooltip="Abrir ventana"
                                buttonType="link"
                                startIcon={<AgregarIcono width="15px" height="15px" />}
                                catalogList={catalogsTemp.map((obj) => obj.name)}
                                catalog={{
                                    name: "",
                                    erasable: true,
                                    catalog: [{ key: "", values: [] }],
                                }}
                                newCatalog={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <FooterControls
                    disableSave={!catalogsHasChanges}
                    disableCancel={!catalogsHasChanges}
                    onSave={() => this.onSave()}
                    onCancel={() => this.onCancel()}
                    showChatBtn={true}
                />
            </React.Fragment>
        );
    }
}

CatalogsTable.contextType = AppContext;
