import React from "react";
//MUI components
import { IconButton, Tooltip } from "@material-ui/core";

//Custom portal components
import ColumnCatalog from "../CatalogsTableComponents/ColumnCatalog";
import ButtonConfirmDialog from "../common/ButtonConfirmDialog";
import Table from "../common/Tables/StyledTable";
import IntebotCatalog from "../IntebotCatalog";

//Icons
import EditarIcono from "../../Icons/EditarIcono";
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";
import AgregarIcono from "../../Icons/AgregarIcono";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export default class StepsTable extends React.Component {
    stepsKeys = IntebotCatalog.StepsKeys;

    setOption = (string) => {
        switch (string) {
            case "all":
                return "Todos";
            case "any":
                return "Ninguno";
            case true:
                return "Sí";
            case false:
                return "No";
            default:
                return string;
        }
    };

    findJumpingStep = (currentDescription) => {
        const { tempSteps } = this.props;

        let isCurrentDescriptionJumpableStep = tempSteps.some((step) => {
            const { fowardStepsDescription, jumpStep, description } = step;
            return fowardStepsDescription?.[jumpStep - 1]?.option === currentDescription;
        });

        if (isCurrentDescriptionJumpableStep) {
            return (
                <span>
                    El paso seleccionado está configurado en otro paso para ser saltado, Si
                    continuas, la lista de saltos será actualizada
                </span>
            );
        }

        return "";
    };

    render() {
        const { tempSteps, deleteTempStep, openStep, errorHelper } = this.props;
        const { stepsKeys, findJumpingStep } = this;

        //console.log('step["fowardStepsDescription"]?.[step["jumpStep"] - 1]?.option :>> ', tempSteps[0]["fowardStepsDescription"]?.[tempSteps[0]["jumpStep"] - 1]?.option);

        return (
            <Table className="sticky">
                <thead>
                    <tr>
                        {stepsKeys.map((item, index) =>
                            item.type === "label" ||
                            item.name === "input" ||
                            item.name === "headerMenu" ||
                            item.name === "JumpCriteria" ||
                            item.name === "newMenuOption" ||
                            item.name === "addMenuOptions" ||
                            item.name === "subflowsLabel" ||
                            item.name === "menuOptions" ||
                            item.name === "hideInformation" ||
                            item.name === "enableMenuOptions" ||
                            item.name === "jumpOperartion" ? null : (
                                <th key={index} width="auto">
                                    <ColumnCatalog searchName={item.name} />
                                    &nbsp;
                                </th>
                            )
                        )}
                    </tr>
                </thead>
                <tbody>
                    {tempSteps.length > 0 ? (
                        tempSteps.map((step, index) => (
                            <tr key={index}>
                                {stepsKeys.map((id, j) =>
                                    id.type === "label" ||
                                    id.name === "input" ||
                                    id.name === "headerMenu" ||
                                    id.name === "JumpCriteria" ||
                                    id.name === "processBranches" ||
                                    id.name === "newMenuOption" ||
                                    id.name === "addMenuOptions" ||
                                    id.name === "subflowsLabel" ||
                                    id.name === "menuOptions" ||
                                    id.name === "hideInformation" ||
                                    id.name === "enableMenuOptions" ||
                                    id.name === "jumpOperartion" ? null : (
                                        <td align="center" key={j} width="auto">
                                            {j === 0
                                                ? index + 1
                                                : typeof step[id.name] == "boolean"
                                                ? this.setOption(step[id.name])
                                                : typeof step[id.name] == "number"
                                                ? step.jumpStep
                                                    ? step.fowardStepsDescription
                                                        ? step["fowardStepsDescription"]?.[
                                                              step[id.name] - 1
                                                          ]?.option
                                                        : step[id.name]
                                                    : step[id.name].toString()
                                                : step[id.name]}
                                        </td>
                                    )
                                )}
                                <td width="auto">
                                    <React.Fragment>
                                        <div style={{ display: "flex" }}>
                                            <Tooltip title="Editar este paso">
                                                <IconButton
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => openStep(index)}
                                                >
                                                    <EditarIcono width="20px" height="20px" />
                                                </IconButton>
                                            </Tooltip>
                                            <ButtonConfirmDialog
                                                title="¿Deseas eliminar este registro?"
                                                confirmName="Sí"
                                                cancelName="No"
                                                tooltip={"Eliminar paso"}
                                                onConfirm={() => deleteTempStep(index)}
                                                icon={<TrashCan width="20px" height="20px" />}
                                            >
                                                {findJumpingStep(step.description)}
                                            </ButtonConfirmDialog>
                                        </div>
                                    </React.Fragment>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            {Array.apply(null, { length: 10 }).map((e, i) => {
                                return <td style={{ padding: "10px" }} key={i}></td>; //agregar fila vacía
                            })}
                        </tr>
                    )}
                    <tr className="hideLastRow">
                        <td></td>
                        <td colSpan={Number.parseInt(stepsKeys.length - stepsKeys.length / 1.9)}>
                            {/* Error de tabla vacía */}
                            <span
                                style={{
                                    color: "#f44336",
                                    fontSize: "0.75rem",
                                    display: errorHelper["steps"] ? "block" : "none",
                                }}
                            >
                                {errorHelper["steps"]}
                            </span>
                        </td>
                        <td>
                            <Tooltip title="Agregar un nuevo paso">
                                <IconButton
                                    variant="outlined"
                                    size="small"
                                    onClick={() => openStep(tempSteps.length)}
                                >
                                    <AgregarIcono width="20px" height="20px" />
                                </IconButton>
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}
