import React, { useState, useContext, useEffect } from "react";

//MUI components
import { TextField, IconButton, Avatar, Badge } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

//Custom portal components
import { actionsQnAmaker } from "../DataFunctions";
import FAQTestUserMessage from "./FAQTestUserMessage";
import FAQTestIntebotReply from "./FAQTestIntebotReply";
import AppContext from "../../Context/AppContext";

// Icons
import Logo from "../../Icons/intebot_logo_bot.svg";

//Styles Sheets
import "./Styles/FAQTest.css";

const FAQTestQuestions = () => {
    const [userQuestion, setUserQuestion] = useState("");
    const [questionHistoy, setQuestionHistory] = useState([]);
    const [loading, setLoading] = useState(false);

    const { getStorageData } = useContext(AppContext);

    let storageData = getStorageData(["name", "lastName"]);

    const getCurrentHour = () => {
        const currentDate = new Date();
        let currentHour = currentDate.getHours();
        let currentMinutes = currentDate.getMinutes();
        if (currentMinutes < 10) {
            currentMinutes = "0" + currentMinutes;
        }
        let sendMessageHour = `${currentHour}:${currentMinutes}`;
        return sendMessageHour;
    };

    useEffect(() => {
        //if there are no questions, return
        if (questionHistoy.length === 0) {
            return;
        }

        //get the first element of questionHistory
        let lastElement = questionHistoy[0];

        //if the last element was asked by the user, return
        if (lastElement.userName === "Intebot") {
            return;
        }
        getQuestionScore(lastElement.question);
    }, [questionHistoy]);

    const handleSendQuestion = (userQuestion) => {
        let sendMessageHour = getCurrentHour();
        let questionHistoyCopy = JSON.parse(JSON.stringify(questionHistoy));
        let userQuestionObj = {
            question: userQuestion,
            hour: sendMessageHour,
            userName: `${storageData.name} ${storageData.lastName}`,
            userInitials: `${storageData.name.charAt(0)}${storageData.lastName.charAt(0)}`,
        };
        questionHistoyCopy.unshift(userQuestionObj);
        setQuestionHistory(questionHistoyCopy);
        setUserQuestion("");
    };

    const getQuestionScore = async (userQuestion) => {
        let data = {
            question: userQuestion,
        };
        setLoading(true);
        let res = await actionsQnAmaker(data, "getTestQuestion");
        setLoading(false);
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                {
                    let score = (res.data.data.confidenceScore * 100).toFixed(2);
                    const answer = res.data.data.answer;
                    let questionHistoyCopy = JSON.parse(JSON.stringify(questionHistoy));
                    let sendMessageHour = getCurrentHour();
                    let answerObj = {
                        answer: answer,
                        score: score,
                        hour: sendMessageHour,
                        userName: "Intebot",
                    };
                    questionHistoyCopy.unshift(answerObj);
                    setQuestionHistory(questionHistoyCopy);
                    //autoscroll the container of the chat to the top
                    let chatContainer = document.querySelector(".faq-test-chat-container");
                    chatContainer.scrollTop = 0;
                }
                break;
            case 1:
                {
                    // error message to user
                    let score = (0 * 100).toFixed(2);
                    const answer =
                        " Hubo un error al procesar tu pregunta, por favor intenta de nuevo. ";
                    let questionHistoyCopy = JSON.parse(JSON.stringify(questionHistoy));
                    let sendMessageHour = getCurrentHour();
                    let answerObj = {
                        answer: answer,
                        score: "Error",
                        hour: sendMessageHour,
                        userName: "Intebot",
                    };
                    questionHistoyCopy.push(answerObj);
                    setQuestionHistory(questionHistoyCopy);
                    //autoscroll the container of the chat
                    let chatContainer = document.querySelector(".faq-test-chat-container");
                    chatContainer.scrollTop = chatContainer.scrollHeight;
                }
                break;
            default:
                break;
        }
    };

    const handleUserQuestion = (targetValue) => {
        if (targetValue.length <= 5000) {
            setUserQuestion(targetValue);
        }
    };

    return (
        <>
            <div className="faq-test-container">
                <footer className="faq-test-chat-footer">
                    <TextField
                        label={loading ? "Cargando..." : "Escribe tu pregunta"}
                        disabled={loading}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                handleSendQuestion(userQuestion);
                            }
                        }}
                        name={"userQuestion"}
                        value={userQuestion}
                        variant="outlined"
                        fullWidth
                        size="small"
                        onChange={(event) => handleUserQuestion(event.target.value)}
                    />
                    <IconButton
                        className="faq-test-send-button"
                        disabled={loading}
                        color="primary"
                        size="small"
                        onClick={() => handleSendQuestion(userQuestion)}
                    >
                        <SendIcon width="20px" height="20px" />
                    </IconButton>
                </footer>
                <div className="faq-test-chat-container">
                    {questionHistoy.map((questionObj, index) => {
                        if (questionObj.userName === "Intebot") {
                            return (
                                <div key={index} className="faq-test-answer-container">
                                    <Badge
                                        className={" styled-badge"}
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        variant="dot"
                                        classes={{
                                            badge: "online-badge",
                                        }}
                                    >
                                        <Avatar
                                            src={Logo}
                                            classes={{
                                                img: "faq-test-avatar-img",
                                            }}
                                        />
                                    </Badge>
                                    <div className="faq-test-intebot-message">
                                        <FAQTestIntebotReply
                                            answer={questionObj.answer}
                                            score={questionObj.score}
                                        />
                                        <span className="faq-test-hour-question">
                                            {questionObj.hour}
                                        </span>
                                    </div>
                                </div>
                            );
                        }

                        //en caso de que el usuario sea el que envia el mensaje
                        return (
                            <div key={index} className="faq-test-question-container">
                                <div className="faq-test-user-message">
                                    <FAQTestUserMessage question={questionObj.question} />
                                    <span className="faq-test-hour-question">
                                        {questionObj.hour}
                                    </span>
                                </div>
                                <Avatar>{questionObj.userInitials}</Avatar>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default FAQTestQuestions;
