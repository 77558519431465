import React from 'react'

//MUI components
import {
    Accordion,
    AccordionSummary, 
    AccordionDetails,
    Button,
    Tooltip,
    TextField,
    IconButton
} from "@material-ui/core";

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AgregarIcono from '../../Icons/AgregarIcono';
import { ReactComponent as TrashCan } from '../../Icons/trashcanIcon.svg';


export default function OpenAccordion(props) {
    const {
      childId,
      name,
      buttonText,
      addNewEntityChild,
      children,
      disabled,
      index,
      childLevelIndex,
      previousChildIndex,
      previousChildIndex2,
      previousChildIndex3,
      removeEntityChild,
      handleChildEntityForm,
      entityErrors,
      entityErrorHelper,
      accordionExpanded,
      handleAccordionExpanded
    } = props;


    const renderChildren = (children) => {
        switch (true) {
            case !children:
                return 'Ya no se pueden agregar mas entidades hijas'
            case children.length === 0:
                return 'No hay entidades hijas'
            default:
                return children
        }
    }

    return (
        <div>
            <Accordion expanded={accordionExpanded[childId] ?? false} className="accordion-container">
                <AccordionSummary
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                    IconButtonProps={{
                        onClick: () => handleAccordionExpanded(childId)
                    }}
                >
                    <TextField
                        name="name"
                        value={name}
                        size='small'
                        type='small'
                        onChange={(event) => handleChildEntityForm(childId, childLevelIndex, index, previousChildIndex, previousChildIndex2, previousChildIndex3, event.target.name, event.target.value)}
                        variant='outlined'
                        error={entityErrors[childId]}
                        helperText={entityErrorHelper[childId]}
                    />
                    <div>
                        <Button
                            disabled={disabled}
                            startIcon={<AgregarIcono width="15px" height="15px" />}
                            color="primary"
                            size="small"
                            style={{ marginLeft: "3px" }}
                            onClick={() => addNewEntityChild(childLevelIndex, index, previousChildIndex, previousChildIndex2, previousChildIndex3)}
                        >
                            {buttonText}
                        </Button>
                        <Tooltip title="Eliminar entidad hija">
                            <IconButton color="secondary" size="small" onClick={() => removeEntityChild(childId, childLevelIndex, index, previousChildIndex, previousChildIndex2, previousChildIndex3)}>
                                <TrashCan width="20px" height="20px"/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="accordion-container">
                        {
                            renderChildren(children)
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
