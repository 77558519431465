import React from 'react';

//MUI components
import {
    Accordion,
    AccordionSummary, 
    AccordionDetails,
    Button,
    TextField
} from "@material-ui/core";

//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AgregarIcono from '../../../Icons/AgregarIcono';

//Custom portal components
import OpenAccordion from '../../common/OpenAccordion';

const EntityChildTable = (props) => {
    const { tempEntity, addNewEntityChild, removeEntityChild, handleChildEntityForm, entityErrors, entityErrorHelper, handleAccordionExpanded, accordionExpanded } = props
    const { name, children, id } = tempEntity

    
    return (
        <div>
            {/* Child 0 */}
            <Accordion expanded={accordionExpanded[id]} className="accordion-container">
                <AccordionSummary
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                    IconButtonProps={{
                        onClick: () => handleAccordionExpanded(id)
                    }}
                >
                    <TextField
                        name="name"
                        value={name}
                        size='small'
                        type='small'
                        onChange={(event) => handleChildEntityForm(id, 0, null, null, null, null, event.target.name, event.target.value)}
                        variant='outlined'
                        error={entityErrors[id]}
                        helperText={entityErrorHelper[id]}
                    />
                    <Button
                        startIcon={<AgregarIcono width="15px" height="15px" />}
                        color="primary"
                        size="small"
                        style={{ marginLeft: "3px" }}
                        onClick={() => addNewEntityChild(0)}
                    >
                        Agregar Entidad Hija
                    </Button>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        // Children 1
                        children.map((child, index) => 
                            <OpenAccordion
                                previousChildIndex={0}
                                index={index}
                                childLevelIndex={1}
                                key={index}
                                name={child.name}
                                childId={child.id}
                                buttonText="Agregar Entidad Hija"
                                addNewEntityChild={addNewEntityChild}
                                removeEntityChild={removeEntityChild}
                                handleChildEntityForm={handleChildEntityForm}
                                entityErrors={entityErrors}
                                entityErrorHelper={entityErrorHelper}
                                accordionExpanded={accordionExpanded}
                                handleAccordionExpanded={handleAccordionExpanded}
                            >
                                {
                                    // Children 2
                                    child.children.map((child2, index2) =>
                                        <OpenAccordion
                                            previousChildIndex={index}
                                            index={index2}
                                            childLevelIndex={2}
                                            key={index2}
                                            name={child2.name}
                                            childId={child2.id}
                                            buttonText="Agregar Entidad Hija"
                                            addNewEntityChild={addNewEntityChild}
                                            removeEntityChild={removeEntityChild}
                                            handleChildEntityForm={handleChildEntityForm}
                                            entityErrors={entityErrors}
                                            entityErrorHelper={entityErrorHelper}
                                            accordionExpanded={accordionExpanded}
                                            handleAccordionExpanded={handleAccordionExpanded}

                                        >
                                            {
                                                // Children 3
                                                child2.children.map((child3, index3) =>
                                                    <OpenAccordion
                                                        previousChildIndex2={index}
                                                        previousChildIndex={index2}
                                                        index={index3}
                                                        childLevelIndex={3}
                                                        key={index3}
                                                        name={child3.name}
                                                        childId={child3.id}
                                                        buttonText="Agregar Entidad Hija"
                                                        addNewEntityChild={addNewEntityChild}
                                                        removeEntityChild={removeEntityChild}
                                                        handleChildEntityForm={handleChildEntityForm}
                                                        entityErrors={entityErrors}
                                                        entityErrorHelper={entityErrorHelper}
                                                        accordionExpanded={accordionExpanded}
                                                        handleAccordionExpanded={handleAccordionExpanded}
                                                    >
                                                        {
                                                            // Children 4
                                                            child3.children.map((child4, index4) =>
                                                                <OpenAccordion
                                                                    index={index4}
                                                                    previousChildIndex3={index}
                                                                    previousChildIndex2={index2}
                                                                    previousChildIndex={index3}
                                                                    childLevelIndex={4}
                                                                    key={index4}
                                                                    disabled={true}
                                                                    name={child4.name}
                                                                    childId={child4.id}
                                                                    buttonText="Agregar Entidad Hija"
                                                                    addNewEntityChild={addNewEntityChild}
                                                                    removeEntityChild={removeEntityChild}
                                                                    handleChildEntityForm={handleChildEntityForm}
                                                                    entityErrors={entityErrors}
                                                                    entityErrorHelper={entityErrorHelper}
                                                                    accordionExpanded={accordionExpanded}
                                                                    handleAccordionExpanded={handleAccordionExpanded}
                                                                >
                                                                </OpenAccordion>
                                                            )
                                                        }
                                                    </OpenAccordion>
                                                )

                                            }
                                        </OpenAccordion>
                                    )
                                }
                            </OpenAccordion>
                        )
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default EntityChildTable;
