import React, { Fragment, useState, useEffect } from "react";

// Material UI inputs
import {
  Grid,
  Breadcrumbs,
  Typography,
  TextField,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//Custom Portal components
import FormModal from "../../../FormModalComponents/FormModal";
import AgregarIcono from "../../../../Icons/AgregarIcono";
import EditarIcono from "../../../../Icons/EditarIcono";
import IntebotCatalog from "../../../IntebotCatalog";

// styles
import "../Styles/Modals.css";

function ActivateSubflow(props) {
  let {
    isAddSubflow,
    allProcessesNames,
    addProcessBranch,
    updateProcessBranch,
    classes,
    optionsCatalog,
    optionsRegEx,
    currentSubflow,
    indexTable,
  } = props;

  const nestedConditions = IntebotCatalog.nestedConditions;
  const errorHelperKeys = IntebotCatalog.errorHelper;
  const operators = IntebotCatalog.operators;

  const formErrorsInit = {
    formHasErrors: true,
    formResponseErrors: {
      name: true,
      catalog: true,
      condition: true,
      type: true,
      operator: true,
      value: true,
    },
    errorHelperForm: {
      name: errorHelperKeys.emptyField,
      catalog: errorHelperKeys.emptyField,
      condition: errorHelperKeys.emptyField,
      type: errorHelperKeys.emptyField,
      operator: errorHelperKeys.emptyField,
      value: errorHelperKeys.emptyField,
    },
  };

  const [subflowData, setSubflowData] = useState({
    name: "",
    catalog: null,
    condition: "",
    operation: null,
    triggerSubProcess: false,
    type: null,
  });

  const [formValues, setFormValues] = useState({
    options: [],
    formHasChanges: false,
  });

  const [formErrors, setFormErrors] = useState(formErrorsInit);

  const [previousValue, setPreviousValue] = useState({});

  const [isEditableDataSet, setIsEditableDataSet] = useState(false);

  useEffect(() => {
    if (isEditableDataSet) {
      // cuando finalizó de actualizar, revisar si hay errores
      searchFormErrors();
    }
  }, [subflowData, isEditableDataSet]);

  /**
   * @name modalIsOpen
   * @description Funcion que revisa si el modal esta abierto para asignar datos correspondientes
   */
  const modalIsOpen = (isOpen) => {
    if (isOpen && !isAddSubflow) {
      setSubflowData(currentSubflow);
      setPreviousValue(currentSubflow);
      setIsEditableDataSet(true);
    }
  };

  function setOption(string) {
    switch (string) {
      case "all":
        return "Todos";
      case "any":
        return "Ninguno";
      case true:
        return "Sí";
      case false:
        return "No";
      default:
        return string;
    }
  }

  /**
   * @description Agregar nueva respuesta o editar una respuesta del cuerpo de la petición
   */
  const activeOrUpdateSubflow = () => {
    isAddSubflow
      ? addProcessBranch(subflowData)
      : updateProcessBranch(subflowData, indexTable);
    clearForm();
  };

  /**
   * @description Validar los campos y guardar el form
   */
  function handleChangesForm(targetName, targetValue) {
    let { formResponseErrors, errorHelperForm, formHasErrors } = formErrors;

    let subflowDataCopy = JSON.parse(JSON.stringify(subflowData));
    let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
    let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
    let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));
    let formValuesCopy = JSON.parse(JSON.stringify(formValues));

    // Buscamos errores según el campo
    switch (targetName) {
      case "name":
        subflowDataCopy[targetName] = targetValue; // guardamos el campo
        // si el campo está vacío, agregar errores
        if (targetValue === "") {
          formResponseErrorsCopy[targetName] = true;
          errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
          break;
        }
        //sin errores
        formResponseErrorsCopy[targetName] = false;
        errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
        break;

      case "condition":
        subflowDataCopy[targetName] = targetValue; // guardamos el valor

        if (targetValue === "") {
          formResponseErrorsCopy[targetName] = true;
          errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
          break;
        }

        //sin errores
        formResponseErrorsCopy[targetName] = false;
        errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
        // si cambia, poner valores iniciales en null
        subflowDataCopy["catalog"] = null;
        subflowDataCopy["type"] = null;
        subflowDataCopy["operation"] = null;

        if (targetValue === "catalog") {
          formResponseErrorsCopy["catalog"] = true;
          errorHelperFormCopy["catalog"] = errorHelperKeys.emptyField;

          formResponseErrorsCopy["type"] = false;
          errorHelperFormCopy["type"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["operator"] = false;
          errorHelperFormCopy["operator"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["value"] = false;
          errorHelperFormCopy["value"] = errorHelperKeys.removeError;
          break;
        }

        if (targetValue === "type") {
          formResponseErrorsCopy["type"] = true;
          errorHelperFormCopy["type"] = errorHelperKeys.emptyField;

          formResponseErrorsCopy["catalog"] = false;
          errorHelperFormCopy["catalog"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["operator"] = false;
          errorHelperFormCopy["operator"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["value"] = false;
          errorHelperFormCopy["value"] = errorHelperKeys.removeError;
          break;
        }

        if (targetValue === "operation") {
          formResponseErrorsCopy["operator"] = true;
          errorHelperFormCopy["operator"] = errorHelperKeys.emptyField;
          formResponseErrorsCopy["value"] = true;
          errorHelperFormCopy["value"] = errorHelperKeys.emptyField;

          formResponseErrorsCopy["catalog"] = false;
          errorHelperFormCopy["catalog"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["type"] = false;
          errorHelperFormCopy["type"] = errorHelperKeys.removeError;
          break;
        }

        break;

      case "dataType":
        // si el tipo de dato es "tipo de dato"
        if (subflowDataCopy["condition"] === "type") {
          subflowDataCopy["type"] = targetValue; // guardamos el campo

          // si el campo está vacío, agregar errores
          if (targetValue === "") {
            formResponseErrorsCopy["type"] = true;
            errorHelperFormCopy["type"] = errorHelperKeys.emptyField;
            break;
          }
          //sin errores
          formResponseErrorsCopy["type"] = false;
          errorHelperFormCopy["type"] = errorHelperKeys.removeError;
          break;
        }

        // si el tipo de dato es "catálogo"
        if (subflowDataCopy["condition"] === "catalog") {
          subflowDataCopy["catalog"] = targetValue;

          if (targetValue === "") {
            formResponseErrorsCopy["catalog"] = true;
            errorHelperFormCopy["catalog"] = errorHelperKeys.emptyField;
            break;
          }

          //sin errores
          formResponseErrorsCopy["catalog"] = false;
          errorHelperFormCopy["catalog"] = errorHelperKeys.removeError;
          break;
        }

        break;

      case "operationOperator":
        if (!subflowDataCopy["operation"]) subflowDataCopy.operation = {};
        subflowDataCopy["operation"]["operator"] = targetValue;

        if (targetValue === "") {
          formResponseErrorsCopy["operator"] = true;
          errorHelperFormCopy["operator"] = errorHelperKeys.emptyField;
          break;
        }
        //sin errores
        formResponseErrorsCopy["operator"] = false;
        errorHelperFormCopy["operator"] = errorHelperKeys.removeError;
        break;

      case "value":
        if (!subflowDataCopy["operation"]) subflowDataCopy.operation = {};
        subflowDataCopy["operation"][targetName] = targetValue;

        if (targetValue === "" || targetValue.length < 0) {
          formResponseErrorsCopy[targetName] = true;
          errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
          break;
        }
        //sin errores
        formResponseErrorsCopy[targetName] = false;
        errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
        break;

      case "triggerSubProcess":
        subflowDataCopy[targetName] = targetValue;
        break;

      default:
        break;
    }

    const countErrors = Object.values(formResponseErrorsCopy).reduce(
      (last, current) => last + current,
      0
    );

    formHasErrorsCopy = countErrors > 0 ? true : false;
    formValuesCopy.formHasChanges = true;

    setSubflowData(subflowDataCopy);
    setFormErrors({
      ...formErrors,
      formResponseErrors: formResponseErrorsCopy,
      errorHelperForm: errorHelperFormCopy,
      formHasErrors: formHasErrorsCopy,
    });
    setFormValues(formValuesCopy);
  }

  /**
   * @description buscar que el form no tengas errores antes de guardar
   */
  const searchFormErrors = () => {
    let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;

    let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
    let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
    let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));

    for (const key in subflowData) {
      const formValue = subflowData[key];
      switch (key) {
        case "name":
          if (formValue !== "") {
            formResponseErrorsCopy[key] = false;
            errorHelperFormCopy[key] = errorHelperKeys.removeError;
          }
          break;

        case "catalog":
          if (formValue !== "") {
            formResponseErrorsCopy[key] = false;
            errorHelperFormCopy[key] = errorHelperKeys.removeError;
          }

          formResponseErrorsCopy["operator"] = false;
          errorHelperFormCopy["operator"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["value"] = false;
          errorHelperFormCopy["value"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["type"] = false;
          errorHelperFormCopy["type"] = errorHelperKeys.removeError;
          break;

        case "condition":
          if (formValue !== "") {
            formResponseErrorsCopy[key] = false;
            errorHelperFormCopy[key] = errorHelperKeys.removeError;
          }
          break;

        case "operation":
          if (formValue !== "") {
            if (subflowData[key] && subflowData[key]["operator"] !== "") {
              formResponseErrorsCopy["operator"] = false;
              errorHelperFormCopy["operator"] = errorHelperKeys.removeError;
            }

            if (subflowData[key] && subflowData[key]["value"] !== "") {
              formResponseErrorsCopy["value"] = false;
              errorHelperFormCopy["value"] = errorHelperKeys.removeError;
            }
          }

          formResponseErrorsCopy["catalog"] = false;
          errorHelperFormCopy["catalog"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["type"] = false;
          errorHelperFormCopy["type"] = errorHelperKeys.removeError;
          break;

        case "type":
          if (formValue !== "") {
            formResponseErrorsCopy[key] = false;
            errorHelperFormCopy[key] = errorHelperKeys.removeError;
          }

          formResponseErrorsCopy["catalog"] = false;
          errorHelperFormCopy["catalog"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["operator"] = false;
          errorHelperFormCopy["operator"] = errorHelperKeys.removeError;
          formResponseErrorsCopy["value"] = false;
          errorHelperFormCopy["value"] = errorHelperKeys.removeError;
          break;

        default:
          break;
      }
    }
    //iteramos el objeto de errores
    const countErrors = Object.values(formResponseErrorsCopy).reduce(
      (a, item) => a + item,
      0
    );

    formHasErrorsCopy = countErrors > 0 ? true : false;
    setFormErrors({
      ...formErrors,
      formResponseErrors: formResponseErrorsCopy,
      errorHelperForm: errorHelperFormCopy,
      formHasErrors: formHasErrorsCopy,
    });
    setIsEditableDataSet(false);
  };

  /**
   * @name clearForm
   * @description al cerrar modal, limpiar form
   */
  const clearForm = () => {
    setFormValues({
      formHasChanges: false,
      options: [],
    });
    setFormErrors(formErrorsInit);
    setSubflowData({
      name: "",
      catalog: null,
      condition: "",
      operation: null,
      triggerSubProcess: false,
      type: null,
    });
  };

  /**
   * @name undoChanges
   * @description al cancelar, deshacer los cambios
   */
  const undoChanges = () => {
    if (isAddSubflow) {
      clearForm();
      return;
    }

    setSubflowData(previousValue);
    setFormValues({
      ...formValues,
      formHasChanges: false,
    });
    setIsEditableDataSet(true);
  };

  return (
    <Fragment>
      <FormModal
        modalTitle={
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography color="textPrimary">
              {isAddSubflow ? "Detonar subflujo" : "Actualizar subflujo"}
            </Typography>
          </Breadcrumbs>
        }
        buttonType={isAddSubflow ? "link" : null}
        buttonTooltip={
          isAddSubflow
            ? "Abrir modal para seleccionar subflujo"
            : "Editar subflujo"
        }
        justIcon={
          isAddSubflow ? null : <EditarIcono width="20px" height="20px" />
        }
        buttonName={isAddSubflow ? "Detonar otro subflujo" : null}
        startIcon={
          isAddSubflow ? <AgregarIcono width="15px" height="15px" /> : null
        }
        alignButtonRight={isAddSubflow ? true : null}
        isOpen={(e) => (e ? modalIsOpen(e) : null)}
        onCancel={() => undoChanges()}
        onSave={() => activeOrUpdateSubflow()}
        disableSaveOnError={true}
        hasChanges={formValues.formHasChanges}
        hasErrors={formErrors.formHasErrors}
      >
        <Grid container>
          <Grid item xs={12}>
            <label className="top-label-field">
              <span style={{ color: "red" }}>* </span>
              Nombre del subflujo
            </label>
            <TextField
              select
              name={"name"}
              value={subflowData.name}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleChangesForm(e.target.name, e.target.value);
              }}
              error={formErrors.formResponseErrors.name}
              helperText={formErrors.errorHelperForm.name}
              >
              {allProcessesNames.length !== 0 ? (
                allProcessesNames.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {setOption(option)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}>No hay opciones disponibles</MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ paddingBottom: "0" }}>
              <label className="top-label-field">
                <span style={{ color: "red" }}>* </span>
                Condición
              </label>
            </Grid>
            {/* Selector de tipo de dato/catalogo/valor */}
            <Grid
              item
              xs={12}
              sm={5}
              style={{
                paddingTop: "0",
              }}
            >
              <TextField
                select
                name={"condition"}
                value={subflowData.condition}
                fullWidth
                variant="outlined"
                size="small"
                onChange={(e) => {
                  handleChangesForm(e.target.name, e.target.value);
                }}
                error={formErrors.formResponseErrors.condition}
                helperText={formErrors.errorHelperForm.condition}
              >
                {nestedConditions.length !== 0 ? (
                  nestedConditions.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {setOption(option.name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={""}>No hay opciones disponibles</MenuItem>
                )}
              </TextField>
            </Grid>
            {/* selector de nombre del catalogo o tipo de dato */}
            <Grid
              item
              xs={12}
              sm
              style={{
                paddingTop: "0",
                display:
                  subflowData.condition === "type" ||
                  subflowData.condition === "catalog"
                    ? "flex"
                    : "none",
              }}
            >
              <TextField
                select
                name={"dataType"}
                value={
                  subflowData.condition === "type"
                    ? subflowData.type
                    : subflowData.condition === "catalog"
                    ? subflowData.catalog
                    : "No hay opciones disponibles"
                }
                fullWidth
                variant="outlined"
                size="small"
                onChange={(e) => {
                  handleChangesForm(e.target.name, e.target.value);
                }}
                error={
                  subflowData.condition === "type"
                    ? formErrors.formResponseErrors.type
                    : subflowData.condition === "catalog"
                    ? formErrors.formResponseErrors.catalog
                    : null
                }
                helperText={
                  subflowData.condition === "type"
                    ? formErrors.errorHelperForm.type
                    : subflowData.condition === "catalog"
                    ? formErrors.errorHelperForm.catalog
                    : null
                }
              >
                {subflowData.condition === "type" ? (
                  optionsRegEx.map((option, i) => (
                    <MenuItem key={i} value={option.name}>
                      {setOption(option.name)}
                    </MenuItem>
                  ))
                ) : subflowData.condition === "catalog" ? (
                  optionsCatalog.map((option, i) => (
                    <MenuItem key={i} value={option.name}>
                      {setOption(option.name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={""}>No hay opciones disponibles</MenuItem>
                )}
              </TextField>
            </Grid>
            {/* validar operador */}
            <Grid
              item
              xs={12}
              sm
              style={{
                paddingTop: "0",
                display:
                  subflowData.condition === "operation" ? "flex" : "none",
                gap: "10px",
              }}
            >
              <TextField
                select
                name={"operationOperator"}
                value={
                  subflowData["operation"]
                    ? subflowData["operation"]["operator"]
                    : ""
                }
                className={classes.consistentSelectorSize}
                style={{ width: "100%" }}
                fullWidth
                variant="outlined"
                size="small"
                onChange={(e) => {
                  handleChangesForm(e.target.name, e.target.value);
                }}
                error={formErrors.formResponseErrors.operator}
                helperText={formErrors.errorHelperForm.operator}
              >
                {operators.length !== 0 ? (
                  operators.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {setOption(option.name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={""}>No hay opciones disponibles</MenuItem>
                )}
              </TextField>
              <TextField
                name={"value"}
                value={
                  subflowData["operation"]
                    ? subflowData["operation"]["value"]
                    : ""
                }
                multiline
                style={{ width: "100%" }}
                className={classes.consistentInputSize}
                fullWidth
                variant="outlined"
                size="small"
                onChange={(e) => {
                  handleChangesForm(e.target.name, e.target.value);
                }}
                error={formErrors.formResponseErrors.value}
                helperText={formErrors.errorHelperForm.value}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: subflowData.condition ? "flex" : "none",
              marginTop: "10px",
            }}
          >
            <label className="top-label-field">
              Regresar al flujo original
            </label>
            <Checkbox
              name={"triggerSubProcess"}
              color="primary"
              checked={subflowData["triggerSubProcess"]}
              value={subflowData["triggerSubProcess"]}
              onChange={(e) => {
                handleChangesForm(e.target.name, e.target.checked);
              }}
            />
          </Grid>
        </Grid>
      </FormModal>
    </Fragment>
  );
}

export default ActivateSubflow;
