/**
 * @description obtener nombre del campo que tiene el error
 * @param {string} type
 * @returns nombre del campo; si no esta en la lista, el valor recibido
 */
export function getErrorFieldName(type) {
    let message = {
        processName: "Nombre del flujo",
        steps: "Construcción del flujo",
        bodyRequest: "Cuerpo de la petición",
        soapOperation: "Operación",
        soapWSDLRequest: "URL",
        endPoint: "URL",
        luisMessage: "Mensaje alternativo PLN",
        description: "Descripción del paso",
        ask: "Mensaje de InteBot",
        input: "Restricción de entrada",
        catalog: "Selección del catálogo",
        type: "Selección del tipo de dato",
        error: "Mensaje de error",
        step: "Paso",
        menuOptions: "Tabla del submenú",
        headerMenu: "Encabezado del submenú",
        questionFormulation: "Pregunta formulada",
        triggerNewFlow: "Segundo flujo",
    };
    return message[type] || type;
}
