import React from "react";

//MUi components
import { Grid } from "@material-ui/core";

//Custom Portal Components
import SimpleTable from "../../common/Tables/SimpleTable";
import AddRequestHeaderModal from "./Modals/AddRequestHeaderModal";

export default function RequestHeadersTable(props) {
    const {
        currentProcess,
        bodyRequestFields,
        addRequestHeader,
        updateRequestHeader,
        deleteRequestHeader,
        classes,
        StyledMentionInput,
    } = props;

    return (
        <section>
            <Grid container>
                <Grid item className={classes.tableSize}>
                    <SimpleTable
                        headers={["Clave", "Valor"]}
                        values={currentProcess["requestHeaders"]}
                        type="request-headers-table"
                        functionDelete={deleteRequestHeader}
                        functionEdit={updateRequestHeader}
                        currentProcess={currentProcess}
                        classes={classes}
                        updateBody={updateRequestHeader}
                        StyledMentionInput={StyledMentionInput}
                    />
                </Grid>

                <Grid container justifyContent="flex-end" className={classes.consistentInputSize}>
                    <AddRequestHeaderModal
                        isNewRequestHeader={true}
                        requestHeaders={currentProcess["requestHeaders"]}
                        classes={classes}
                        addRequestHeader={addRequestHeader}
                        updateRequestHeader={updateRequestHeader}
                        StyledMentionInput={StyledMentionInput}
                    />
                </Grid>
            </Grid>
        </section>
    );
}
