import React from 'react';

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions,
    CircularProgress
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

//Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

//Custom portal components
import CustomButton from "../CustomButton";

//Styles
  const Dialog = withStyles((theme) => ({
    root: {
        marginTop: '56px',
        [theme.breakpoints.up('md')]: {
            marginTop: '64px',
        },
    },
}))(MuiDialog)

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent:"center",
        alignItems:"center",
        // borderBottom: "1px solid #e3e3e3",
        padding: "10px 20px",
    },
}))(MuiDialogTitle)

const DialogContent = withStyles((theme) => ({
    root:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    }

}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root:{
        
    }

}))(MuiDialogActions)

const CustomModalActions = (props) => {

    const { handleDialogAction, dialog } = props;

    const { openDialogActions, dialogMessage, dialogAction, dialogFunction } = dialog;

    
    const setModalBody = () => {
        switch (dialogAction) {
            case "loadingModal":
                return loadingModal
            case "loadingSucceeded" :
                return loadingSucceeded
            case "LoadingFailed": 
                return LoadingFailed
            case "askConfirmation":
                return askConfirmation
            default:
                return (<div>...</div>)
        }
    }

    const loadingModal = (
        <div>
            <DialogTitle>Conectando con el servicio...</DialogTitle>
            <DialogContent>
                <CircularProgress/>
                <p>{dialogMessage}</p>
            </DialogContent>
        </div>
    )

    const loadingSucceeded = (
        <div>
            <DialogTitle>Acción realizada con éxito</DialogTitle>
            <DialogContent>
                <CheckCircleIcon className='check-circle-icon'/>
                <p>{dialogMessage}</p>
                <DialogActions>
                    <CustomButton 
                       onClick={() => {handleDialogAction(false)}}
                        variant="contained" 
                    > 
                        Ok 
                    </CustomButton>
                </DialogActions>
            </DialogContent>
        </div>
    )

    const LoadingFailed = (
        <div>
            <DialogTitle>Hubo un error</DialogTitle>
            <DialogContent>
                <ErrorIcon className='error-circle-icon'/>
                <p>{dialogMessage}</p>
                <DialogActions>
                    <CustomButton 
                        onClick={() => {handleDialogAction(false)}}
                        variant="contained" 
                    > 
                        Ok 
                    </CustomButton>
                </DialogActions>
            </DialogContent>
        </div>
    )

    const askConfirmation = ( 
        <div>
            {dialogMessage === "¿Deseas eliminar este template?" ?
              <DialogTitle>{dialogMessage}</DialogTitle>
            :
              <DialogTitle>¿Estas seguro?</DialogTitle>
            }
            <DialogContent>
                {dialogMessage === "¿Deseas eliminar este template?" ?
                  null
                :
                  <p>{dialogMessage}</p>
                }
                <DialogActions>
                    <CustomButton 
                        onClick={() => {handleDialogAction(false)}}
                        variant="contained" 
                    > 
                        No 
                    </CustomButton>
                    <CustomButton 
                        onClick={() => {handleDialogAction(false); dialogFunction()}}
                        variant="contained" 
                    > 
                        Si
                    </CustomButton>
                </DialogActions>
            </DialogContent>
        </div>
    )

    let modalBody = setModalBody()

    return (
        <Dialog open={openDialogActions}>
            {modalBody}
        </Dialog>
    );
}

export default CustomModalActions;
