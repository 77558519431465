import React, { Fragment, useState, useEffect } from "react";

// Material UI inputs
import { Grid, Breadcrumbs, Typography, TextField } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//Custom Portal components
import FormModal from "../../../FormModalComponents/FormModal";
import AgregarIcono from "../../../../Icons/AgregarIcono";
import EditarIcono from "../../../../Icons/EditarIcono";
import IntebotCatalog from "../../../IntebotCatalog";

// styles
import "../Styles/Modals.css";

function AddSubmenuOption(props) {
  let {
    addMenuOption,
    editMenuOption,
    isAddSubmenuOption,
    currentSubmenu,
    indexTable,
  } = props;

  const errorHelperKeys = IntebotCatalog.errorHelper;

  const formErrorsInit = {
    formHasErrors: true,
    formResponseErrors: {
      textOption: true,
    },
    errorHelperForm: {
      textOption: errorHelperKeys.emptyField,
    },
  };

  const [submenuOption, setSubmenuOption] = useState({
    textOption: "",
  });

  const [formHasChanges, setFormHasChanges] = useState(false);

  const [formErrors, setFormErrors] = useState(formErrorsInit);

  const [previousValue, setPreviousValue] = useState({});

  const [isEditableDataSet, setIsEditableDataSet] = useState(false);

  useEffect(() => {
    if (isEditableDataSet) {
      // cuando finalizó de actualizar, revisar si hay errores
      searchFormErrors();
    }
  }, [submenuOption, isEditableDataSet]);

  /**
   * @name modalIsOpen
   * @description Funcion que revisa si el modal esta abierto para asignar datos correspondientes
   */
  const modalIsOpen = (isOpen) => {
    if (isOpen && !isAddSubmenuOption) {
      setSubmenuOption(currentSubmenu);
      setPreviousValue(currentSubmenu);
      setIsEditableDataSet(true);
    }
  };

  /**
   * @description Agregar nueva respuesta o editar una respuesta del cuerpo de la petición
   */
  const addSubmenuOption = () => {
    isAddSubmenuOption
      ? addMenuOption(submenuOption)
      : editMenuOption(submenuOption, indexTable);
    clearForm();
  };

  /**
   * @description Validar los campos y guardar el form
   */
  function handleChangesForm(targetName, targetValue) {
    let { formResponseErrors, errorHelperForm, formHasErrors } = formErrors;

    let submenuOptionCopy = JSON.parse(JSON.stringify(submenuOption));
    let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
    let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
    let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));

    // Buscamos errores según el campo
    switch (targetName) {
      case "textOption":
        submenuOptionCopy[targetName] = targetValue;
        if (targetValue === "") {
          formResponseErrorsCopy[targetName] = true;
          errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
          formHasErrorsCopy = true;

          break;
        }

        if (targetValue.length > 20) {
          errorHelperFormCopy[targetName] = errorHelperKeys.maxLength20;
          formHasErrorsCopy = false;
          formResponseErrorsCopy[targetName] = false;
          break;
        }
        //sin errores
        formResponseErrorsCopy[targetName] = false;
        errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
        formHasErrorsCopy = false;

        break;
      default:
        break;
    }

    setSubmenuOption(submenuOptionCopy);
    setFormErrors({
      ...formErrors,
      formResponseErrors: formResponseErrorsCopy,
      errorHelperForm: errorHelperFormCopy,
      formHasErrors: formHasErrorsCopy,
    });
    setFormHasChanges(true);
  }

  /**
   * @description buscar que el form no tengas errores cuando se establecen
   */
  const searchFormErrors = () => {
    let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;

    let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
    let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
    let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));

    for (const key in submenuOption) {
      const formValue = submenuOption[key];

      switch (key) {
        case "textOption":
          if (formValue !== "") {
            errorHelperFormCopy[key] = errorHelperKeys.removeError;
            formResponseErrorsCopy[key] = false;
            formHasErrors = false;

            if (formValue.length > 20) {
              errorHelperFormCopy[key] = errorHelperKeys.maxLength20;
            }
          }
          break;
        default:
          break;
      }
    }

    setFormErrors({
      ...formErrors,
      formResponseErrors: formResponseErrorsCopy,
      errorHelperForm: errorHelperFormCopy,
      formHasErrors: formHasErrorsCopy,
    });
    setIsEditableDataSet(true);
  };

  /**
   * @name clearForm
   * @description al cerrar modal, limpiar form
   */
  const clearForm = () => {
    setFormHasChanges(false);
    setFormErrors(formErrorsInit);
    setSubmenuOption({ textOption: "" });
  };

  /**
   * @name undoChanges
   * @description al cancelar, deshacer los cambios
   */
  const undoChanges = () => {
    if (isAddSubmenuOption) {
      clearForm();
      return;
    }

    setSubmenuOption(previousValue);
    setFormHasChanges(false);
    setIsEditableDataSet(true);
  };

  return (
    <Fragment>
      <FormModal
        modalTitle={
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography color="textPrimary">
              {isAddSubmenuOption
                ? "Agregar opción al submenú"
                : "Editar opción del submenú"}
            </Typography>
          </Breadcrumbs>
        }
        buttonType={isAddSubmenuOption ? "link" : null}
        buttonTooltip={
          isAddSubmenuOption
            ? "Abrir modal para agregar opción"
            : "Editar opción"
        }
        justIcon={
          isAddSubmenuOption ? null : <EditarIcono width="20px" height="20px" />
        }
        buttonName={isAddSubmenuOption ? "Agregar opción de submenú" : null}
        startIcon={
          isAddSubmenuOption ? (
            <AgregarIcono width="15px" height="15px" />
          ) : null
        }
        alignButtonRight={isAddSubmenuOption ? true : null}
        isOpen={(e) => (e ? modalIsOpen(e) : null)}
        onCancel={() => undoChanges()}
        onSave={() => addSubmenuOption()}
        disableSaveOnError={true}
        hasChanges={formHasChanges}
        hasErrors={formErrors.formHasErrors}
      >
        <Grid container>
          <Grid item xs={12}>
            <label className="top-label-field">
              <span style={{ color: "red" }}>* </span>
              Opción del submenú
            </label>
            <TextField
              name={"textOption"}
              value={submenuOption.textOption}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleChangesForm(e.target.name, e.target.value);
              }}
              error={formErrors.formResponseErrors.textOption}
              helperText={formErrors.errorHelperForm.textOption}
            ></TextField>
          </Grid>
        </Grid>
      </FormModal>
    </Fragment>
  );
}
export default AddSubmenuOption;
