import React from 'react';
//MUI components
import { Button as MuiButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

//External libraries
import PropTypes from 'prop-types';
import clsx from 'clsx';


const Button = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        //border: 0,
        color: 'white',
        height: 36,
        padding: '0 30px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&$disabled': {
            color: 'white',
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: 'none',
    },
}))(MuiButton);

export default function ClassNames(props) {
    const { classes, children, className, ...other } = props;

    return (
        <Button className={clsx(className)} {...other}>
            {children || 'class names'}
        </Button>
    );
}

ClassNames.propTypes = {
    children: PropTypes.node,
    //classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

//export default withStyles(styles)(ClassNames);