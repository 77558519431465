import React, { useContext, useState, useEffect } from "react";

//MUI components
import { IconButton, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

//Botframework webchat
import Chat, { createDirectLine, createStore } from "botframework-webchat";

//Custom components
import { getDirectLineToken, reconnectToConversation } from "../DataFunctions";
import AppContext from "../../Context/AppContext";

//Style sheets
import "./Styles/BotframeworkChatWidget.css";

const BotframeworkChatWidget = (props) => {
    const [directLine, setDirectLine] = useState(false);
    const { getStorageData } = useContext(AppContext);
    const [store, setStore] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState(true);

    let storageData = getStorageData(["botSecret"]);

    const directLineActions = async () => {
        if (!storageData.botSecret) return;
        const res = await getDirectLineToken(storageData.botSecret);

        let token = res.data.token;
        const directLine = createDirectLine({
            token,
            conversationStartProperties: {
                locale: "es-ES",
            },
        });
        setDirectLine(directLine);
    };

    useEffect(() => {
        settingStore();
        directLineActions();
    }, []);

    const { setOpenTestChat } = props;

    useEffect(() => {
        // Check connection status every 5 minutes
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const lastConnectionTime = localStorage.getItem("lastConnectionTime");
            // check if the connection has been inactive for more than 30 minutes
            if (lastConnectionTime && currentTime - lastConnectionTime > 30 * 60 * 1000) {
                console.log("No connection for more than 30 minutes.");
                setConnectionStatus(false);
                reconnect();
            } else {
                setConnectionStatus(true);
            }
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(interval); // Clean up the interval on component unmount
        };
    }, []);

    const reconnect = async () => {
        const lastActivity = JSON.parse(localStorage.getItem("lastActivity"));

        let watermark = lastActivity.id;
        let conversationId = lastActivity.conversation.id;
        const res = await reconnectToConversation(conversationId, storageData.botSecret, watermark);

        let token = res.data.token;

        const directLine = createDirectLine({
            token,
            conversationStartProperties: {
                locale: "es-ES",
            },
        });

        setDirectLine(directLine);
    };

    const settingStore = async () => {
        const store = createStore({}, ({ dispatch, getState }) => (next) => (action) => {
            // activity middleware
            const state = getState();
            const currentTime = new Date().getTime();

            switch (action.type) {
                case "WEB_CHAT/CONNECT_FULFILLED":
                // caso: conexión completada
                case "DIRECT_LINE/POST_ACTIVITY_FULFILLED":
                // caso: último mensaje enviado
                case "DIRECT_LINE/SET_SUGGESTED_ACTIONS":
                    // caso: último mensaje recibido
                    localStorage.setItem("lastConnectionTime", currentTime);
                    localStorage.setItem(
                        "lastActivity",
                        JSON.stringify(state.activities[state.activities.length - 1])
                    );
                    break;

                default:
                    break;
            }

            return next(action);
        });
        setStore(store);
    };

    return (
        <div className="bot-chat-container slide-up-animation">
            <header className="bot-chat-header">
                <Grid container>
                    <Grid item xs={12}>
                        <h2>Chat de prueba</h2>
                    </Grid>
                </Grid>
                <IconButton
                    onClick={() => {
                        setOpenTestChat(false);
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </header>
            <main>
                {directLine ? (
                    <Chat className="bot-chat-widget" directLine={directLine} store={store} />
                ) : (
                    <div className="bot-chat-widget">Conectando&hellip;</div>
                )}
            </main>
        </div>
    );
};

export default BotframeworkChatWidget;
