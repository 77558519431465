import React from 'react';

//MUI components
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {
    Grid,
    Menu,
} from "@material-ui/core";

//Custom portal components
import IntentTreeChildren from './IntentTreeChildren';

const IntentClickTagMenu = (props) => {
    const {
      menuAnchor,
      handleCloseEntityMenu,
      entityList,
      createEntityPhrase,
      selectedEntityUniqid,
      removeEntityPhrase,
      changeEntityTag,
      selectedExampleType,
      createSuggestionLabel,
      selectedEntityName,
      findEntityInfo,
      createSuggestionLabelAndChildren
    } = props;

    let selectedEntityInfo = findEntityInfo(selectedEntityName);

    const decideMenuItems = (selectedExampleType) => {
        switch (selectedExampleType) {
            case 'label':
                return(
                    <div className='select-entity-menu'>
                        <h3>Selecciona una entidad</h3>
                        <div className='entity-list'>
                        <label onClick={()=> removeEntityPhrase(selectedEntityUniqid)} className='entity-label' >Eliminar Etiqueta</label>
                        {
                            entityList.map((entity, index) =>
                                <Grid key={index} container className='entity-menu-container'>
                                    <label onClick={()=> changeEntityTag(entity.name)} className='entity-label' >{entity.name}</label>
                                    {
                                        entity.entityType === "Entity Extractor" &&
                                        <TreeView
                                        className='entity-tree'
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                                <TreeItem className='entity-tree-item' nodeId="0" label="">
                                                    {
                                                        // Children 1 level
                                                        entity?.children?.map((child, index) =>
                                                            <IntentTreeChildren
                                                                index={index}
                                                                entityChild={child}
                                                                createEntityPhrase={createEntityPhrase}
                                                            >
                                                                <TreeView
                                                                    className='entity-tree'
                                                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                                                    defaultExpandIcon={<ChevronRightIcon />}
                                                                >
                                                                    <TreeItem className='entity-tree-item' nodeId="0" label="">
                                                                        {
                                                                                // Children 2 level
                                                                            child?.children?.map((child2, index) =>
                                                                                <IntentTreeChildren
                                                                                    index={index}
                                                                                    entityChild={child2}
                                                                                    createEntityPhrase={createEntityPhrase}
                                                                                >
                                                                                    <TreeView
                                                                                        className='entity-tree'
                                                                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                                                                        defaultExpandIcon={<ChevronRightIcon />}
                                                                                    >
                                                                                        <TreeItem className='entity-tree-item' nodeId="0" label="">
                                                                                            {
                                                                                                // Children 3 level
                                                                                                child2?.children?.map((child3, index) =>
                                                                                                    <IntentTreeChildren
                                                                                                        index={index}
                                                                                                        entityChild={child3}
                                                                                                        createEntityPhrase={createEntityPhrase}
                                                                                                    >
                                                                                                        <TreeView
                                                                                                            className='entity-tree'
                                                                                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                                                                                            defaultExpandIcon={<ChevronRightIcon />}
                                                                                                        >
                                                                                                            <TreeItem className='entity-tree-item' nodeId="0" label="">
                                                                                                                {
                                                                                                                    // Children 4 level
                                                                                                                    child3?.children?.map((child4, index) =>
                                                                                                                        <IntentTreeChildren
                                                                                                                            index={index}
                                                                                                                            entityChild={child4}
                                                                                                                            createEntityPhrase={createEntityPhrase}
                                                                                                                        >
                                                                                                                            {/* ya no es posible tener mas hijos */}
                                                                                                                        </IntentTreeChildren>
                                                                                                                    )
                                                                                                                }
                                                                                                            </TreeItem>
                                                                                                        </TreeView>
                                                                                                    </IntentTreeChildren>
                                                                                                )
                                                                                            }
                                                                                        </TreeItem>
                                                                                    </TreeView>
                                                                                </IntentTreeChildren>
                                                                            )
                                                                        }
                                                                    </TreeItem>
                                                                </TreeView>
                                                            </IntentTreeChildren>
                                                        )
                                                    }
                                                </TreeItem>
                                            </TreeView>
                                    }
                                </Grid>
                            )
                        }
                    </div>
                </div>
                );
            case 'suggest':
                return(
                    <div className='select-entity-menu'>
                        <h4>Predicción: {selectedEntityName} (Machine Learned)</h4>
                        <hr />
                        <label onClick={()=> createSuggestionLabel(selectedEntityName, selectedEntityUniqid)} className='entity-label' >[Aceptar sugerencia {selectedEntityName}]</label>
                        <br />
                        {selectedEntityInfo.level === 0 && <label onClick={()=> createSuggestionLabelAndChildren(selectedEntityName, selectedEntityInfo)} className='entity-label' >[Aceptar sugerencia {selectedEntityName} y subentidades]</label>}
                    </div>
                )
                
        
            default:
                return null;
        }
    }

    return (
        <>
            <Menu
                id="simple-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={handleCloseEntityMenu}
                style={{ transformOrigin: 'center bottom' }}
            >
                {decideMenuItems(selectedExampleType)}
            </Menu>
        </>
    );
}

export default IntentClickTagMenu;
