import React, { Fragment, useContext, useState, useEffect } from 'react';

//MUI Components
import { Menu, MenuItem, Grid, TextField, Tooltip, Select } from '@material-ui/core';
//Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

//Custom Portal Components 
import AppContext from '../../Context/AppContext';

//Expernal libraries
import Swal from 'sweetalert2';

//Styles
import './Styles/MyAccountMenu.css'

const Myaccountmenu = (props) => {
    const [currentSub, setCurrentSub] = useState('')
    const [subSelectorIsOpen, setSubSelectorIsOpen] = useState(false)
    const [openSelectorToolTip, setOpenSelectorToolTip] = useState(false)
    const context = useContext(AppContext);

    const { getStorageData, logOutUser, updateSubscriptionData } = context;
    const { anchorEl, handleMenuClose, changeOpenRighMenu } = props;

    let authData = getStorageData([
        "email",
        "name",
        "lastName",
        "organizationName",
        "subscriptionId",
        "token",
    ]);

    let storageData = getStorageData([
        "currentSubscription",
        "remeberAuthCredentials"
    ])
    const { subscriptionId } = authData;
    const { currentSubscription, remeberAuthCredentials } = storageData;
   
    useEffect(() => {
        setCurrentSub(currentSubscription.name)
    }, [anchorEl])
    

    let menuItems = [
        {name: "Mi Cuenta", className: 'menu-button', onclick: () =>{ handleMenuClose(); changeOpenRighMenu({account: true })}},
        {name: "Mi Suscripción", className: 'menu-button', onclick: () =>{ handleMenuClose(); changeOpenRighMenu({suscription: true })}},
        {name: "Ajustes", className: 'menu-button', onclick: () =>{ handleMenuClose(); changeOpenRighMenu({settings: true })}},
        {name: "Cerrar Sesión", className: 'logout-button menu-button', onclick: () =>{ handleMenuClose(); logOutUser(); }},
    ]

    const changeSubscription = async (targetValue) => {
        let alert = await Swal.fire({
            title: '¿Deseas cambiar de suscripción?',
            text: 'Se perdera cualquier cambio no guardado.',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
            confirmButtonColor: '#27315d',
            denyButtonColor: '#27315d',
        })
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return
        }
        let selectedSub = subscriptionId.find(subscription => subscription.name === targetValue)
        setCurrentSub(targetValue)
        updateSubscriptionData(authData, selectedSub)
        if(remeberAuthCredentials){
            localStorage.setItem("currentSubscription", JSON.stringify(selectedSub))
            return
        }
        sessionStorage.setItem("currentSubscription", JSON.stringify(selectedSub))
    }

    return (
        <Fragment>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <div className='menu-container'>
                    <div className='profile-name-container'>
                        <AccountCircleIcon className='my-account-icon'/>
                        <Grid item sm={6} xs={12}>
                        <Tooltip 
                            onOpen={(() => {
                                if(!subSelectorIsOpen){
                                    setOpenSelectorToolTip(true)
                                }
                            })}
                            onClose={() => setOpenSelectorToolTip(false)}
                            open={openSelectorToolTip} 
                            arrow 
                            title='Cambiar suscripción'
                        >
                            <Select
                                className='sub-selector'
                                name={'subSelector'}
                                value={currentSub}
                                fullWidth
                                variant='outlined'
                                size="small"
                                onOpen={() => {setSubSelectorIsOpen(true); setOpenSelectorToolTip(false);}}
                                onClose={() => setSubSelectorIsOpen(false)}
                                onChange={(event) => changeSubscription(event.target.value)}
                            >
                                {
                                    subscriptionId.map((subscription, i) =><MenuItem key={i} value={subscription.name}>{subscription.name}</MenuItem>)
                                }
                            </Select>    
                        </Tooltip>
                        </Grid>
                    </div>
                    
                    <div>
                        {
                            menuItems.map((item, index) => 
                                <MenuItem onClick={item.onclick} className={item.className} key={index}>{item.name}</MenuItem>
                            )
                        }
                    </div>
                </div>
            </Menu>
        </Fragment>
    );
}

export default Myaccountmenu;
