import React, { memo } from "react";
import { Grid, TextField, IconButton, Button, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AgregarIcono from "../../../Icons/AgregarIcono";

const IntentExampleTableHeader = (props) => {
    const {
        newExampleContent,
        handleIntentForm,
        addNewExampleContent,
        createIntentErrors,
        createIntentErrorHelper,
    } = props;

    return (
        <header className="edit-intents-actions">
            <div className="example-content-container">
                <div className="center-tooltip">
                    <label className="top-label-field">Contenido del ejemplo</label>
                    <Tooltip
                        title={`
                                Escribe el contenido que quieres que el usuario diga para que se active esta intención.
                            `}
                    >
                        <HelpOutlineIcon color="primary" fontSize="small" />
                    </Tooltip>
                </div>
                <TextField
                    name="exampleContent"
                    value={newExampleContent}
                    size="small"
                    type="small"
                    onChange={(event) => handleIntentForm(event.target.name, event.target.value)}
                    fullWidth
                    multiline
                    variant="outlined"
                    error={createIntentErrors["exampleContent"]}
                    helperText={createIntentErrorHelper["exampleContent"]}
                />
                <Grid container justifyContent="flex-end">
                    <Tooltip title="Agrega la frase escrita para su etiquetado">
                        <Button
                            disabled={false}
                            onClick={() => addNewExampleContent(newExampleContent)}
                            startIcon={<AgregarIcono width="15px" height="15px" />}
                            size="small"
                        >
                            Agregar ejemplo
                        </Button>
                    </Tooltip>
                </Grid>
            </div>
        </header>
    );
};

const propAreEqual = (prevProps, nextProps) => {
    return (
        prevProps.newExampleContent === nextProps.newExampleContent &&
        prevProps.createIntentErrors === nextProps.createIntentErrors
    );
};

export default memo(IntentExampleTableHeader, propAreEqual);
