import Axios from "axios";

export {
    actionsQnAmaker,
    blobStorage,
    whatsAppCampaignActions,
    authActions,
    intebotDataActions,
    actionsPLNManagement,
    getDirectLineToken,
    reconnectToConversation,
    openAIActions,
    actionsPowerBi,
};

//Centralized INTEBOT DATA
async function intebotDataActions(data, type, apiFunction) {
    if (!apiFunction) throw new Error("You must specify an apiFunction for this function");
    let code = "";

    const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
    const coreResourceServiceKey = remeberAuthCredentials
        ? localStorage.getItem("coreResourceServiceKey")
        : sessionStorage.getItem("coreResourceServiceKey");
    switch (apiFunction) {
        //Flows
        case "BotConfigurationOperations":
            code = process.env.REACT_APP_BOT_CONFIGURATION_CODE;
            break;
        case "ModelConfigurationOperations":
            code = process.env.REACT_APP_MODEL_CONFIGURATION_CODE;
            break;
        default:
            throw new Error("There is no code for this api call");
    }
    try {
        let res = await Axios({
            url: `${process.env.REACT_APP_INTEBOT_DATA_ENDPOINT}${apiFunction}?code=${code}&type=${type}`,
            data: JSON.stringify(data, null, 2),
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                "Content-Type": "application/json",
                Authorization: coreResourceServiceKey,
            },
        });
        process.env.REACT_APP_DEBUG === "true" && console.log(`Response in ${type}`, res);
        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" && console.log(`Catch error in ${type}`, error);
        return error;
    }
}

//QnAmaker Actions
async function actionsQnAmaker(data, type) {
    if (!type) throw new Error("You must specify an type for this function ");
    const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
    const coreResourceServiceKey = remeberAuthCredentials
        ? localStorage.getItem("coreResourceServiceKey")
        : sessionStorage.getItem("coreResourceServiceKey");
    try {
        let axiosObj = {
            url: `${process.env.REACT_APP_QNA_MAKER_URLWS}&type=${type}`,
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                "Content-Type": "application/json",
                Authorization: coreResourceServiceKey,
            },
        };
        if (data) axiosObj.data = JSON.stringify(data, null, 2);
        let res = await Axios(axiosObj);
        process.env.REACT_APP_DEBUG === "true" && console.log("Response in actionsQnAmaker", res);

        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Catch error in actionsQnAmaker", error);
        return error;
    }
}

//Authentication Actions
async function authActions(data, type, apiFunction) {
    // console.log('data', data)
    // console.log('type :>> ', type);
    // console.log('apiFunction', apiFunction)

    if (!apiFunction) throw new Error("You must specify an apiFunction for this function");

    let code = "";
    let endPoint = process.env.REACT_APP_AUTH_ACTIONS_ENDPOINT;
    let Authorization = process.env.REACT_APP_AUTHORIZATION_TOKEN;

    let remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
    let serviceKey = remeberAuthCredentials
        ? localStorage.getItem("serviceKey")
        : sessionStorage.getItem("serviceKey");
    let authToken = remeberAuthCredentials
        ? localStorage.getItem("token")
        : sessionStorage.getItem("token");
    switch (apiFunction) {
        case "LogIn":
            code = process.env.REACT_APP_LOGIN_CODE;
            if (type === "ValidateTokenRole") {
                type = `ValidateTokenRole&token=${authToken}`;
            }
            break;
        case "SignUp":
            code = process.env.REACT_APP_SIGNUP_CODE;
            break;
        case "PasswordReset":
            code = process.env.REACT_APP_PASSWORD_RESET_CODE;
            break;
        case "SubscriptionOperations":
            code = process.env.REACT_APP_SUBSCRIPTION_OP_CODE;
            break;
        case "RoleManagement":
            code = process.env.REACT_APP_ROLE_MANAGEMENT_CODE;
            break;
        default:
            throw new Error("There is no code for this api call " + apiFunction);
    }

    try {
        let res = await Axios({
            url: `${endPoint}${apiFunction}?code=${code}&type=${type}`,
            data: JSON.stringify(data, null, 2),
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                Authorization: Authorization,
                "Content-Type": "application/json",
            },
        });
        process.env.REACT_APP_DEBUG === "true" && console.log("Response in authActions", res);
        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" && console.log("Catch error in authActions", error);
        return error;
    }
}

//WhatsApp Campaigns Actions
async function whatsAppCampaignActions(data, type, isSendCampaign) {
    try {
        let remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
        let serviceKey = remeberAuthCredentials
            ? localStorage.getItem("serviceKey")
            : sessionStorage.getItem("serviceKey");

        let urlWS = process.env.REACT_APP_MANAGE_WHATSAPP_CAMPAIGNS_URLWS;
        if (isSendCampaign) {
            urlWS = process.env.REACT_APP_SEND_WHATSAPP_CAMPAIGNS_URLWS;
        }

        let res = await Axios({
            url: `${urlWS}&type=${type}`,
            data: JSON.stringify(data, null, 2),
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                Authorization: serviceKey,
                "Content-Type": "application/json",
            },
        });
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Response in whatsAppCampaignActions", res);
        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Catch error in whatsAppCampaignActions", error);
        return error;
    }
}

//send files or Delete files to blob storage
async function blobStorage(data, type) {
    const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
    const coreResourceServiceKey = remeberAuthCredentials
        ? localStorage.getItem("coreResourceServiceKey")
        : sessionStorage.getItem("coreResourceServiceKey");
    try {
        let res = await Axios({
            url: `${process.env.REACT_APP_BLOB_STORAGE_URLWS}&type=${type}`,
            data: JSON.stringify(data, null, 2),
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                "Content-Type": "application/json",
                Authorization: coreResourceServiceKey,
            },
        });
        process.env.REACT_APP_DEBUG === "true" && console.log("Response in blobStorage", res);
        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" && console.log("Catch error in blobStorage", error);
    }
}

async function actionsPLNManagement(data, type) {
    if (!type) throw new Error("You must specify an type for this function");
    const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
    const coreResourceServiceKey = remeberAuthCredentials
        ? localStorage.getItem("coreResourceServiceKey")
        : sessionStorage.getItem("coreResourceServiceKey");
    try {
        let axiosObj = {
            url: `${process.env.REACT_APP_PLN_MANAGEMENT_URLWS}&type=${type}`,
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                "Content-Type": "application/json",
                Authorization: coreResourceServiceKey,
            },
        };
        if (data) axiosObj.data = JSON.stringify(data, null, 2);
        let res = await Axios(axiosObj);
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Response in actionsPLNManagement, type", res, type);

        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Catch error in actionsPLNManagement", error);
        return error;
    }
}

async function getDirectLineToken(data) {
    try {
        let axiosObj = {
            url: `https://directline.botframework.com/v3/directline/tokens/generate`,
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${data}`,
            },
        };
        let res = await Axios(axiosObj);
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Response in getDirectLineToken", res);
        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Catch error in getDirectLineToken", error);
        return error;
    }
}

async function reconnectToConversation(conversationId, token, watermark) {
    try {
        let axiosObj = {
            url: `https://directline.botframework.com/v3/directline/conversations/${conversationId}/?watermark=${watermark}`,
            method: "GET",
            timeout: 3000000, //5 minutos
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        let res = await Axios(axiosObj);
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Response in reconnectToConversation", res);
        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Catch error in reconnectToConversation", error);
        return error;
    }
}

async function openAIActions(data, type) {
    if (!type) throw new Error("You must specify an type for this function");
    const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
    const coreResourceServiceKey = remeberAuthCredentials
        ? localStorage.getItem("coreResourceServiceKey")
        : sessionStorage.getItem("coreResourceServiceKey");
    try {
        let axiosObj = {
            url: `${process.env.REACT_APP_OPENAI_URLWS}&type=${type}`,
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                "Content-Type": "application/json",
                Authorization: coreResourceServiceKey,
            },
        };
        if (data) axiosObj.data = JSON.stringify(data, null, 2);
        let res = await Axios(axiosObj);
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Response in openAIActions, type", res, type);

        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Catch error in openAIActions", error);
        return error;
    }
}

async function actionsPowerBi(data) {
    const remeberAuthCredentials = localStorage.getItem("remeberAuthCredentials");
    const reportServiceKey = remeberAuthCredentials
        ? localStorage.getItem("reportServiceKey")
        : sessionStorage.getItem("reportServiceKey");
    try {
        let axiosObj = {
            url: `${process.env.REACT_APP_POWER_BI_URLWS}`,
            method: "POST",
            timeout: 3000000, //5 minutos
            headers: {
                "Content-Type": "application/json",
                Authorization: reportServiceKey,
            },
        };
        if (data) axiosObj.data = JSON.stringify(data, null, 2);
        let res = await Axios(axiosObj);
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Response in actionsPowerBi, type", res);

        return res;
    } catch (error) {
        process.env.REACT_APP_DEBUG === "true" &&
            console.log("Catch error in actionsPowerBi", error);
        return error;
    }
}
