import React from 'react';

//MUI components
import TreeItem from '@material-ui/lab/TreeItem';

const IntentTreeChildren = (props) => {

    const { entityChild, createEntityPhrase, index, children } = props;
    const { name, parents, level } = entityChild

    return (
        <div>
            <TreeItem key={index} onClick={()=> createEntityPhrase(name, parents, level)} nodeId={index + 1} label={entityChild.name} />
            {children}
        </div>
    );
}

export default IntentTreeChildren;
