import React from "react";

//MUI components
import {
    Breadcrumbs,
    Button as MuiButton,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Collapse,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

//Custom Portal Componetns
import FormModal from "../FormModalComponents/FormModal";
import AppContext from "../../Context/AppContext";
import ProcessContent from "./ProcessContent";
import StepsTableContent from "./StepsTableContent";
import EditStepContent from "./EditStepContent";
import IntebotCatalog from "../IntebotCatalog";
import CustomAlert from "../common/Alerts/CustomAlert";
import CustomButton from "../common/CustomButton";
import BackToFlows from "../common/Modals/BackToSectionModal";
import { intebotDataActions } from "../DataFunctions";

//External Libraries
import Swal from "sweetalert2"; //https://sweetalert2.github.io

const Button = withStyles({
    root: {
        fontWeight: "400",
        //textDecoration: 'underline',
    },
    disabled: {},
    label: {
        //fontWeight: 'none',
        textTransform: "none",
        fontSize: "1rem",
    },
})(MuiButton);

export default class ProcessModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Controls Modal level:
            hasChanges: false,
            section: "currentProcess", //['currentProcess', stepsTable, 'currentStep']
            //Controls Process level:
            tempProcess: {},
            processErrors: {},
            countProcessErrors: 0,
            //Controls Step level:
            idStep: 0,
            stepErrors: {},
            countStepErrors: 0,
            //alerts
            alert: {
                open: false,
                severity: "", //success, error, warning, info
                message: "",
            },
            idProcess: undefined,
            typeCopy: "",
            catalogCopy: "",
            errorHelper: {},
            newFlow: false,
            newStep: false,
            openDialog: false,
            singleActionDialog: false,
            dialog: {
                title: "",
                message: "",
            },
            stepHasChanges: false,
        };
        this.child = React.createRef();
    }

    //cuando el cliente quiere cambiar un flujo de posición, se debe actualizar el modal con el proceso correspondiente
    updateSortedFlow = (currentProcess) => {
        const { hasSorted } = this.props;
        const tempProcessCopy = JSON.parse(JSON.stringify(currentProcess));
        if (hasSorted) {
            this.setState({
                tempProcess: tempProcessCopy,
            });
        }
    };

    componentDidMount() {
        const { currentProcess } = this.props;
        this.updateErrors(JSON.parse(JSON.stringify(currentProcess)));
    }

    //Al momento de cambiar el orden, se sustituye el flujo en la posición antigua, con el nuevo
    componentDidUpdate(prevProps, prevState) {
        const { updateContextAttribute } = this.context;
        const { currentProcess } = this.props;
        const { hasChanges } = this.state;

        if (prevProps.currentProcess !== currentProcess) {
            this.updateSortedFlow(JSON.parse(JSON.stringify(currentProcess)));
        }

        if (prevState.hasChanges !== hasChanges) {
            updateContextAttribute("processesHasChanges", hasChanges);
        }
    }

    //Buscamos los errores que pueda haber en el flujo
    updateErrors = (contextProcess, isCancelButton) => {
        const { getStorageData } = this.context;
        const { idProcess, newFlow } = this.props;
        const { section, idStep } = this.state;
        const { updateChannelList } = this;

        const { ServicesKeys, SoapKeys, RestKeys, StepsKeys, ProcessKeys, FAQKeys } =
            IntebotCatalog;
        const errorHelperKeys = IntebotCatalog.errorHelper;

        let tempProcess = JSON.parse(JSON.stringify(contextProcess));
        let errorHelper = JSON.parse(JSON.stringify(this.state.errorHelper));

        let processErrors = {};
        let stepErrors = {};

        ProcessKeys.forEach((property) => {
            //Set errores especificos al momento de montar el modal de flujos
            switch (property.name) {
                case "processName":
                    //preguntamos si esta vacio
                    if (contextProcess[property.name] === "" && property.required) {
                        processErrors[property.name] = true;
                        errorHelper[property.name] = errorHelperKeys.emptyField;
                        break;
                    }
                    //preguntamos si excede el limite de caracteres
                    if (contextProcess[property.name].length > 20) {
                        errorHelper[property.name] = errorHelperKeys.maxLength20;
                        break;
                    }
                    //quitamos errores
                    processErrors[property.name] = false;
                    errorHelper[property.name] = errorHelperKeys.removeError;
                    break;
                case "steps":
                    // si la tabla de Construcción del flujo está vacía marcar error
                    if (contextProcess[property.name].length === 0 && property.required) {
                        processErrors[property.name] = true;
                        errorHelper[property.name] = errorHelperKeys.emptyTable;
                        break;
                    }
                    //quitamos errores
                    processErrors[property.name] = false;
                    errorHelper[property.name] = errorHelperKeys.removeError;
                    break;
                default:
                    if (
                        (!contextProcess[property.name] ||
                            contextProcess[property.name].length === 0) &&
                        property.required
                    ) {
                        processErrors[property.name] = true;
                        errorHelper[property.name] = errorHelperKeys.emptyField;
                        break;
                    }
                    processErrors[property.name] = false;
                    errorHelper[property.name] = errorHelperKeys.removeError;
                    break;
            }
        });

        if (contextProcess.serviceType === "REST" || contextProcess.serviceType === "SOAP") {
            ServicesKeys.forEach((property) => {
                switch (property.name) {
                    case "bodyRequestLabel":
                        if (
                            Object.keys(contextProcess["bodyRequest"]).length === 0 &&
                            property.required
                        ) {
                            processErrors["bodyRequest"] = true;
                            errorHelper["bodyRequest"] = errorHelperKeys.emptyField;
                            break;
                        }
                        processErrors["bodyRequest"] = false;
                        errorHelper["bodyRequest"] = errorHelperKeys.removeError;
                        break;
                    case "luisMessage":
                        if (
                            !contextProcess["luisMessage"] &&
                            property.required &&
                            contextProcess.enableLuis
                        ) {
                            processErrors["luisMessage"] = true;
                            errorHelper["luisMessage"] = errorHelperKeys.emptyField;
                            break;
                        }
                        processErrors["luisMessage"] = false;
                        errorHelper["luisMessage"] = errorHelperKeys.removeError;
                        break;
                    case "bodyRequest":
                        if (
                            ["POST", "PUT", "HEAD", "PATCH"].includes(contextProcess.requestMethod)
                        ) {
                            property.required = true;
                            break;
                        }
                        property.required = false;
                        break;
                    default:
                        break;
                }
            });
        }

        if (contextProcess.serviceType === "REST") {
            RestKeys.forEach((property) => {
                switch (property.name) {
                    default:
                        if (property.required && contextProcess[property.name] === "") {
                            processErrors[property.name] = true;
                            errorHelper[property.name] = errorHelperKeys.emptyField;
                            break;
                        }
                        processErrors[property.name] = false;
                        errorHelper[property.name] = errorHelperKeys.removeError;
                        break;
                }
            });
        }

        if (contextProcess.serviceType === "SOAP") {
            SoapKeys.forEach((property) => {
                switch (property.name) {
                    default:
                        if (property.required && contextProcess[property.name] === "") {
                            processErrors[property.name] = true;
                            errorHelper[property.name] = errorHelperKeys.emptyField;
                            break;
                        }
                        processErrors[property.name] = false;
                        errorHelper[property.name] = errorHelperKeys.removeError;
                        break;
                }
            });
        }

        if (contextProcess.serviceType === "Base de conocimientos") {
            FAQKeys.forEach((property) => {
                switch (property.name) {
                    default:
                        if (property.required && contextProcess[property.name] === "") {
                            processErrors[property.name] = true;
                            errorHelper[property.name] = errorHelperKeys.emptyField;
                            break;
                        }
                        processErrors[property.name] = false;
                        errorHelper[property.name] = errorHelperKeys.removeError;
                        break;
                }
            });
        }

        StepsKeys.forEach((property) => {
            contextProcess["steps"].forEach((stepObject) => {
                switch (property.name) {
                    case "ask":
                        if (contextProcess.enableMenuOptions) {
                            processErrors["ask"] = false;
                            errorHelper["ask"] = errorHelperKeys.removeError;
                        } else {
                            if (
                                contextProcess["ask"] !== undefined &&
                                property.required &&
                                stepObject[property.name] === ""
                            ) {
                                processErrors["ask"] = true;
                                errorHelper["ask"] = errorHelperKeys.emptyField;
                            } else {
                                processErrors["ask"] = false;
                                errorHelper["ask"] = errorHelperKeys.removeError;
                            }
                        }
                        break;
                    case "headerMenu":
                    case "menuOptions":
                        if (
                            !contextProcess[property.name] &&
                            property.required &&
                            (stepObject[property.name] === "" ||
                                (stepObject[property.name] &&
                                    stepObject[property.name].length === 0)) &&
                            contextProcess.enableMenuOptions
                        ) {
                            processErrors[property.name] = true;
                            errorHelper[property.name] =
                                property.name === "menuOptions"
                                    ? errorHelperKeys.emptyTable
                                    : errorHelperKeys.emptyField;
                            break;
                        }
                        processErrors[property.name] = false;
                        errorHelper[property.name] = errorHelperKeys.removeError;
                        break;
                    default:
                        if (
                            property.required &&
                            (stepObject[property.name] === "" ||
                                (stepObject[property.name] &&
                                    stepObject[property.name].length === 0))
                        ) {
                            stepErrors[property.name] = true;
                            errorHelper[property.name] = errorHelperKeys.emptyField;
                            return;
                        }
                        stepErrors[property.name] = false;
                        errorHelper[property.name] = errorHelperKeys.removeError;
                        break;
                }
            });
        });

        //Buscamos hacer el Update de la lista de canales en caso de que haya sido activado un nuevo canal
        const updatedChannelList = updateChannelList(contextProcess, getStorageData);
        const allChannelsVisible = Object.values(updatedChannelList).every(
            (isVisible) => isVisible
        );

        //Número de campos con errores:
        const countProcessErrors = Object.values(processErrors).reduce((a, item) => a + item, 0);
        const countStepErrors = Object.values(stepErrors).reduce((a, item) => a + item, 0);

        //Valores por default
        tempProcess = {
            ...tempProcess,
            visibleChannel: updatedChannelList,
            allChannelsCheckbox: allChannelsVisible,
        };

        this.setState({
            tempProcess: tempProcess,
            hasChanges: false,
            processErrors: processErrors,
            stepErrors: stepErrors,
            countProcessErrors: countProcessErrors,
            countStepErrors: countStepErrors,
            section:
                section === "currentStep" && isCancelButton && !newFlow
                    ? "currentStep"
                    : "currentProcess", //Si nos quedamos en "current Step" en un flujo nuevo, truena porque los flujos nuevos no tienen pasos
            idStep: section === "currentStep" && isCancelButton ? idStep : 0,
            idProcess: idProcess,
            errorHelper: errorHelper,
            newFlow: newFlow,
        });
    };

    updateChannelList(contextProcess, getStorageData) {
        const tempProcess = JSON.parse(JSON.stringify(contextProcess));
        let storageData = getStorageData(["availableChannels"]);
        const { visibleChannel } = tempProcess;
        const { availableChannels } = storageData;
        let newChannelList = {};
        let hasNewChannels = true;
        //Obtenemos los nombres de los canales
        let visibleChannelNames = Object.keys(visibleChannel)
            .map((channelName) => channelName.toLowerCase())
            .sort();
        let availableChannelsNames = Object.keys(availableChannels)
            .map((channelName) => channelName.toLowerCase())
            .sort();
        //Compraramos si ambas listas son iguales
        if (
            visibleChannelNames.length === availableChannelsNames.length &&
            visibleChannelNames.every((value, index) => value === availableChannelsNames[index])
        ) {
            hasNewChannels = false;
        }
        if (hasNewChannels) {
            //Si las lista de canales son distintas hacemos el Update
            availableChannelsNames.forEach((channelName) => {
                newChannelList[channelName] = visibleChannel[channelName] ?? false;
            });
            return newChannelList;
        }
        return visibleChannel;
    }

    changeHandler = (targetName, targetValue, targetType, targetRequired, targetIndex) => {
        const { getStorageData } = this.context;
        const { tempProcess, idProcess, processErrors, errorHelper } = this.state;
        const { allFlowsNames } = this.props;
        // console.log(
        //     targetName,
        //     targetValue,
        //     targetType,
        //     targetRequired,
        //     targetIndex
        // );
        let storageData = getStorageData(["subscriptionModule"]);
        //Hacemos una copia independiente del objeto para guardar cambios temporales:
        const tempObject = JSON.parse(JSON.stringify(tempProcess));
        const errors = { ...processErrors };
        let errorHelperCopy = JSON.parse(JSON.stringify(errorHelper));
        const errorHelperKeys = IntebotCatalog.errorHelper;
        const servicesKeys = IntebotCatalog.ServicesKeys;
        //Evaluamos el tipo de componente para actualizar correctamente el estado del objeto:
        //****************************guardamos el valor del campo en el estado***********************************************
        switch (targetType) {
            case "expandable-checkbox":
                //Preguntamos si es el checkbox de todos
                if (targetName === "Todos") {
                    tempObject["allChannelsCheckbox"] = targetValue; //Guardamos el valor
                    for (const channel in tempObject["visibleChannel"]) {
                        tempObject["visibleChannel"][channel] = targetValue; //Ponemos todos los Checkboxes con el value del todos
                    }
                    break;
                }
                //Otros canales
                tempObject["visibleChannel"][targetName] = targetValue; //Guardamos el valor
                const allChannelsVisible = Object.values(tempObject["visibleChannel"]).every(
                    (isVisible) => isVisible
                ); //Preguntamos si todos los canales activos estan visibles
                tempObject["allChannelsCheckbox"] = allChannelsVisible;
                break;
            default:
                tempObject[targetName] = targetValue;
                break;
        }
        // ============actualizar el estado del componente por su nombre===================
        if (targetType !== "expandable-checkbox") {
            switch (targetName) {
                case "processName":
                    //Campo Vacío
                    if (
                        (tempObject["processName"] === "" ||
                            tempObject["processName"].length === 0) &&
                        targetRequired
                    ) {
                        errors["processName"] = true;
                        errorHelperCopy["processName"] = errorHelperKeys.emptyField;
                        break;
                    }
                    //eliminamos el flujo actual
                    delete allFlowsNames[idProcess];
                    //Buscamos si el Nombre está repetido
                    if (allFlowsNames.includes(targetValue.toLowerCase())) {
                        errors["processName"] = true;
                        errorHelperCopy["processName"] = errorHelperKeys.flowNameTaken;
                        break;
                    }
                    //Nombre mayor a 20
                    if (targetValue.length > 20) {
                        errorHelperCopy["processName"] = errorHelperKeys.maxLength20;
                        errors["processName"] = false;
                        break;
                    }
                    //Sin errores
                    errorHelperCopy["processName"] = "";
                    errors["processName"] = false;
                    break;
                case "soapWSDLRequest":
                    const validateURLSOAP = /^(ftp|http|https):\/\/[^ "]+(wsdl|WSDL)$/;
                    let testSOAPurl = validateURLSOAP.test(targetValue);
                    //Campo Vacío
                    if (
                        (tempObject[targetName] === "" || tempObject[targetName].length === 0) &&
                        targetRequired
                    ) {
                        errors[targetName] = true;
                        errorHelperCopy[targetName] = "* El campo NO puede estar vacío";
                        break;
                    }
                    //No paso la prueba de URL
                    if (!testSOAPurl) {
                        errors[targetName] = true;
                        errorHelperCopy[targetName] =
                            "* El campo debe contener una URL válida con terminación en WSDL ";
                        break;
                    }
                    //Sin errores
                    errors[targetName] = false;
                    errorHelperCopy[targetName] = "";
                    break;
                case "endPoint":
                    const validateURLREST = /^(ftp|http|https):\/\/[^ "]+$/;
                    let testRESTurl = validateURLREST.test(targetValue);
                    //Campo vacío
                    if (
                        (tempObject[targetName] === "" || tempObject[targetName].length === 0) &&
                        targetRequired
                    ) {
                        errors[targetName] = true;
                        errorHelperCopy[targetName] = errorHelperKeys.emptyField;
                        break;
                    }
                    //No paso la prueba de URL
                    if (!testRESTurl) {
                        errors[targetName] = true;
                        errorHelperCopy[targetName] = errorHelperKeys.validURL;
                        break;
                    }
                    //Sin Errores
                    errors[targetName] = false;
                    errorHelperCopy[targetName] = errorHelperKeys.removeError;
                    break;
                case "requestMethod":
                    let bodyRequestIndex = servicesKeys.findIndex(
                        (keys) => keys.name === "bodyRequest"
                    );
                    //Si es uno de los metodos que requiere body, se activa el campo
                    if (["POST", "PUT", "HEAD", "PATCH"].includes(targetValue)) {
                        if (!tempObject?.bodyRequest) {
                            tempObject["bodyRequest"] = {};
                        }

                        //buscamos si el campo bodyRequest esta vacio
                        if (Object.keys(tempObject.bodyRequest).length === 0) {
                            //si esta vacio, activamos el campo y marcamos el error
                            errors["bodyRequest"] = true;

                            if (tempObject.enableCustomBodyRequest) {
                                //mostramos el campo vacío
                                errors["customBodyRequest"] = true;
                                errorHelperCopy["customBodyRequest"] = errorHelperKeys.emptyField;
                                errors["bodyRequest"] = false;
                                errorHelperCopy["bodyRequest"] = errorHelperKeys.removeError;
                                break;
                            }
                            //Desactivamos el switch para ocultar el campo
                            if (!tempObject.enableCustomBodyRequest) {
                                errors["customBodyRequest"] = false;
                                errorHelperCopy["customBodyRequest"] = errorHelperKeys.removeError;
                                errors["bodyRequest"] = true;
                                errorHelperCopy["bodyRequest"] = errorHelperKeys.emptyField;
                                break;
                            }
                        }
                        break;
                    }
                    //Si no es uno de los metodos que requiere body, se desactiva el campo
                    errors["bodyRequest"] = false;
                    errorHelperCopy["bodyRequest"] = errorHelperKeys.removeError;
                    errors["customBodyRequest"] = false;
                    errorHelperCopy["customBodyRequest"] = errorHelperKeys.removeError;
                    servicesKeys[bodyRequestIndex]["required"] = false;
                    break;
                case "enableLuis":
                    if (!targetValue) {
                        tempObject["luisMessage"] = "";
                        errors["luisMessage"] = false;
                        errorHelperCopy["luisMessage"] = errorHelperKeys.removeError;
                        break;
                    }
                    if (targetValue && tempObject["luisMessage"]) {
                        errors["luisMessage"] = false;
                        errorHelperCopy["luisMessage"] = errorHelperKeys.removeError;
                        break;
                    }
                    if (!storageData.subscriptionModule?.processNaturalLenguage) {
                        tempObject["enableLuis"] = false;
                        Swal.fire({
                            icon: "info",
                            title: "Este módulo no esta disponible en tu suscripción",
                            text: "Si deseas activar algún módulo, por favor, ponte en contacto con atención al cliente",
                        });
                        break;
                    }
                    errors["luisMessage"] = true;
                    errorHelperCopy["luisMessage"] = errorHelperKeys.emptyField;
                    break;
                case "enableCustomBodyRequest":
                    //Guardamos el campo
                    //Activamos el switch para mostrar el campo
                    tempObject["enableCustomBodyRequest"] = targetValue;
                    tempObject["bodyRequest"] = {};
                    tempObject["customBodyRequest"] = "";
                    //Si es uno de los metodos que requiere body, se activa el campo
                    if (["POST", "PUT", "HEAD", "PATCH"].includes(tempObject["requestMethod"])) {
                        //Si el switch esta activo, entonces mostramos el campo
                        if (targetValue) {
                            //mostramos el campo vacío
                            errors["customBodyRequest"] = true;
                            errorHelperCopy["customBodyRequest"] = errorHelperKeys.emptyField;
                            errors["bodyRequest"] = false;
                            errorHelperCopy["bodyRequest"] = errorHelperKeys.removeError;
                            break;
                        }
                        //Desactivamos el switch para ocultar el campo
                        if (!targetValue) {
                            errors["customBodyRequest"] = false;
                            errorHelperCopy["customBodyRequest"] = errorHelperKeys.removeError;
                            errors["bodyRequest"] = true;
                            errorHelperCopy["bodyRequest"] = errorHelperKeys.emptyField;
                            break;
                        }
                    }
                    break;
                case "customBodyRequest":
                    //Json parse the string
                    let match = targetValue.match(/(?<!\w)@\w+/g);
                    targetValue = targetValue.replace(/(?<!\w)@\w+/g, (match) => {
                        return `{{${match.slice(1)}}}`;
                    });

                    try {
                        let bodyRequest = JSON.parse(targetValue, null, 2);
                        tempObject["customBodyRequest"] = JSON.stringify(bodyRequest, null, 2);
                        tempObject["bodyRequest"] = bodyRequest;
                        errors["customBodyRequest"] = false;
                        errorHelperCopy["customBodyRequest"] = errorHelperKeys.removeError;
                    } catch (error) {
                        //console.log('error.message :>> ', error.message);
                        errors["customBodyRequest"] = true;
                        errorHelperCopy["customBodyRequest"] = errorHelperKeys.invalidJSON;
                    }
                    break;
                case "triggerFlowOnEnd":
                    if (targetValue) {
                        errors["triggerNewFlow"] = true;
                        errorHelperCopy["triggerNewFlow"] = errorHelperKeys.emptyField;
                        break;
                    }
                    tempObject["triggerNewFlow"] = "";
                    errors["triggerNewFlow"] = false;
                    errorHelperCopy["triggerNewFlow"] = errorHelperKeys.removeError;

                    break;
                default:
                    //Si el campo está vacío, entonces lo marcamos como error.
                    if (
                        (tempObject[targetName] === "" || tempObject[targetName].length === 0) &&
                        targetRequired
                    ) {
                        errors[targetName] = true;
                        errorHelperCopy[targetName] = "* El campo NO puede estar vacío";
                    } else {
                        errors[targetName] = false;
                        errorHelperCopy[targetName] = "";
                    }
                    break;
            }
        }

        const countErrors = Object.values(errors).reduce((a, item) => a + item, 0);
        this.setState({
            tempProcess: tempObject,
            hasChanges: true,
            processErrors: errors,
            countProcessErrors: countErrors,
            errorHelper: errorHelperCopy,
        });
    };

    handleServiceChange = (targetName, targetValue) => {
        // console.log(targetName, targetValue)
        let newTempProcess = Object.assign({}, this.state.tempProcess);
        const errors = Object.assign({}, this.state.processErrors);
        const errorHelper = JSON.parse(JSON.stringify(this.state.errorHelper));
        const soapKeys = IntebotCatalog.SoapKeys;
        const restKeys = IntebotCatalog.RestKeys;
        const FAQKeys = IntebotCatalog.FAQKeys;
        const servicesKeys = IntebotCatalog.ServicesKeys;
        const errorHelperKeys = IntebotCatalog.errorHelper;
        const webServiceKeys = [
            "responseType",
            "bodyRequestValue",
            "bodyRequestKey",
            "getDescriptionValue",
            "responseBranches",
            "mainResponseObject",
            "qnaRequest",
            "enableLuis",
            "requestHeaders",
        ];

        switch (targetValue) {
            case "FAQ":
                //Debemos quitar de tempProcess los atributos que son de SOAP
                soapKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //Debemos quitar de tempProcess los atributos que son de REST
                restKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //Debemos quitar de tempProcess los atributos que son de ambos servicios
                servicesKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //En caso de no tener respuesta de un servicio web, se eliminan los campos correspondientes
                webServiceKeys.map((key) => {
                    delete newTempProcess[key];
                    return null;
                });
                //Seteamos campos requeridos con errores:
                FAQKeys.map((key) => {
                    switch (key.name) {
                        default:
                            if (!newTempProcess[key.name] && key.required) {
                                errors[key.name] = true;
                                errorHelper[key.name] = errorHelperKeys.emptyField;
                                break;
                            }
                            break;
                    }
                    return key;
                });
                //agregamos valores por default de los campos de Base de conocimientos
                newTempProcess = {
                    ...newTempProcess,
                    qnaRequest: true,
                    questionFormulation: "",
                };
                break;
            case "Ninguno":
                //Debemos quitar de tempProcess los atributos que son de SOAP
                soapKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //Debemos quitar de tempProcess los atributos que son de REST
                restKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //Debemos quitar de tempProcess los atributos que son de SOAP
                FAQKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //Debemos quitar de tempProcess los atributos que son de ambos servicios
                servicesKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //En caso de no tener respuesta de un servicio web, se eliminan los campos correspondientes
                webServiceKeys.map((key) => {
                    delete newTempProcess[key];
                    return null;
                });
                break;
            case "SOAP":
                //Debemos quitar de tempProcess los atributos que son de REST
                restKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //Seteamos campos requeridos con errores:
                soapKeys.map((key) => {
                    switch (key.name) {
                        default:
                            if (!newTempProcess[key.name] && key.required) {
                                errors[key.name] = true;
                                errorHelper[key.name] = errorHelperKeys.emptyField;
                                break;
                            }
                            break;
                    }
                    return key;
                });
                //Debemos quitar de tempProcess los atributos que son de SOAP
                FAQKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                servicesKeys.map((key) => {
                    switch (key.name) {
                        //Quitamos errores que no lo necesitan al momento de montar los campos
                        case "bodyRequestLabel":
                            errors[key.name] = false;
                            errorHelper[key.name] = errorHelperKeys.removeError;
                            break;
                        case "luisMessage":
                            errors[key.name] = false;
                            errorHelper[key.name] = errorHelperKeys.removeError;
                            break;
                        default:
                            if (
                                (!newTempProcess[key.name] ||
                                    newTempProcess[key.name].length === 0) &&
                                key.required
                            ) {
                                errors[key.name] = true;
                                errorHelper[key.name] = errorHelperKeys.emptyField;
                                break;
                            }
                            break;
                    }
                    return key;
                });

                //agregamos valores por default de los campos de SOAP
                newTempProcess = {
                    ...newTempProcess,
                    bodyRequestValue: "Escribe tu valor aquí",
                    bodyRequestKey: "",
                    bodyRequest: {},
                    qnaRequest: false,
                    responseBranches: [],
                    enableLuis: false,
                    requestHeaders: {},
                    requestMethod: "POST",
                    enableCustomBodyRequest: false,
                };
                break;
            case "REST":
                //Debemos quitar de tempProcess los atributos que son de SOAP
                soapKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                //Seteamos campos requeridos con errores:
                restKeys.map((key) => {
                    switch (key.name) {
                        default:
                            if (!newTempProcess[key.name] && key.required) {
                                errors[key.name] = true;
                                errorHelper[key.name] = errorHelperKeys.emptyField;
                                break;
                            }
                            break;
                    }
                    return key;
                });
                //Debemos quitar de tempProcess los atributos que son de FAQ
                FAQKeys.map((key) => {
                    delete newTempProcess[key.name];
                    delete errors[key.name];
                    return null;
                });
                servicesKeys.map((key) => {
                    switch (key.name) {
                        //Quitamos errores que no lo necesitan al momento de montar los campos
                        case "bodyRequestLabel":
                            errors[key.name] = false;
                            errorHelper[key.name] = errorHelperKeys.removeError;
                            break;
                        case "luisMessage":
                            errors[key.name] = false;
                            errorHelper[key.name] = errorHelperKeys.removeError;
                            break;
                        default:
                            if (
                                (!newTempProcess[key.name] ||
                                    newTempProcess[key.name].length === 0) &&
                                key.required
                            ) {
                                errors[key.name] = true;
                                errorHelper[key.name] = errorHelperKeys.emptyField;
                                break;
                            }
                            break;
                    }
                    return key;
                });
                //agregamos valores por default de los campos de REST
                newTempProcess = {
                    ...newTempProcess,
                    bodyRequestValue: "Escribe tu valor aquí",
                    bodyRequestKey: "",
                    bodyRequest: {},
                    qnaRequest: false,
                    responseBranches: [],
                    enableLuis: false,
                    requestHeaders: {},
                    requestMethod: "POST",
                    enableCustomBodyRequest: false,
                };
                break;
            default:
                break;
        }
        const countProcessErrors = Object.values(errors).reduce((a, item) => a + item, 0);
        newTempProcess[targetName] = targetValue;

        if (targetName === "serviceType" && targetValue === "FAQ") {
            newTempProcess[targetName] = "Base de conocimientos";
        }

        this.setState({
            tempProcess: newTempProcess,
            hasChanges: true,
            processErrors: errors,
            countProcessErrors: countProcessErrors,
            errorHelper: errorHelper,
        });
    };

    onSaveStep = (tempStep) => {
        // console.log('tempStep :>> ', tempStep);
        const { tempProcess } = this.state;
        let tempProcessCopy = { ...tempProcess };
        tempProcessCopy["steps"].splice(tempStep.step, 1, tempStep);
        this.setState({
            tempProcess: tempProcessCopy,
            hasChanges: true,
            alert: {
                open: true,
                severity: "success",
                message: "Información del paso guardada correctamente.",
            },
        });
    };

    //Guardar el proceso temporal en los procesos del contexto
    onSave = async () => {
        const { processesTemp, updateContextAttribute, getStorageData } = this.context;
        const { tempProcess, idProcess, idStep } = this.state;
        const { token } = getStorageData(["token"]);
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");
        let tempProcessCopy = JSON.parse(JSON.stringify(tempProcess));
        //console.log("tempProcessCopy", tempProcessCopy);
        //Cambios al momento de guardar el flujo
        const getDescriptionValue = tempProcessCopy["steps"].map((step) => step.description);
        tempProcessCopy["getDescriptionValue"] = getDescriptionValue;
        tempProcessCopy["steps"].forEach((step, index) => {
            const stepDescriptionValue = tempProcessCopy["steps"]
                .filter((step) => step.step < index)
                .map((step) => step.description);
            // console.log('stepDescriptionValue :>> ', stepDescriptionValue);
            step.getDescriptionValue = stepDescriptionValue;
        });
        tempProcessCopy["processName"] = tempProcessCopy["processName"].trim();
        let newTempProcesses = [];
        if (typeof idProcess == "undefined") {
            //Si no contamos con idProceso tenemos que agregar un nuevo proceso
            newTempProcesses = JSON.parse(JSON.stringify(processesTemp));
            let newProcessIndex = newTempProcesses.push(tempProcessCopy) - 1;
            this.setState({
                idProcess: newProcessIndex,
            });
        } else {
            //Si contamos con idProceso tenemos que actualizar un proceso existente
            newTempProcesses = processesTemp.map((process, i) => {
                if (idProcess === i) {
                    return tempProcessCopy;
                }
                return process;
            });
        }
        //Actualizamos la lista de procesos en base de datos
        let data = {
            processes: newTempProcesses,
            token,
        };
        let res = await intebotDataActions(
            data,
            "updateConfigurationProcesses&enviroment=Test",
            "ModelConfigurationOperations"
        );
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                //Actualizamos el estado
                this.setState({
                    hasChanges: false,
                    alert: {
                        open: true,
                        severity: "success",
                        message: "Nuevo flujo guardado correctamente",
                    },
                });
                //Actualizamos el contexto de la parte de procesos
                updateContextAttribute("processes", newTempProcesses);
                updateContextAttribute("processesTemp", newTempProcesses);
                updateContextAttribute("processesHasSavedChanges", true);
                break;
            case 2:
                this.setState({
                    alert: {
                        open: true,
                        severity: "info", //success, error, warning, info
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    },
                });
            case 3:
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;
            default:
                this.setState({
                    alert: {
                        open: true,
                        severity: "error",
                        message: "Error al guardar el flujo",
                    },
                });
                break;
        }
    };

    onCancel = (isCancelButton) => {
        const { currentProcess } = this.props;
        this.updateErrors(JSON.parse(JSON.stringify(currentProcess)), isCancelButton);
    };

    openSection = (section) => {
        const { newStep, stepHasChanges } = this.state;
        if (stepHasChanges && this.state.section === "currentStep") {
            //console.log('No dejar cambiar')
            this.setState({
                openDialog: true,
                dialog: {
                    title: "El paso creado se eliminará",
                    message: "¿Estas seguro de que deseas salir del paso actual?",
                },
            });
        } else {
            this.setState({
                section: section,
                newStep: section === "currentStep" ? newStep : false,
            });
        }
    };

    //Creación de la lista de Jumps
    setJumpableSteps = (stepsList, currentStep) => {
        let stepsListLength = stepsList.length;
        let currentStepDescription = currentStep.description;
        let findIndexStep = stepsList.findIndex(
            (step) => step.description === currentStepDescription
        );
        let idStep = findIndexStep + 1;
        let fowardStepsDescription = [];
        let fowardStepsNumber = [];
        let finishProccesJump =
            stepsList.at(-1)?.description === currentStepDescription ? false : true;
        //Contamos cuantos Saltos puede dar el flujo
        for (idStep; idStep < stepsListLength; idStep++) {
            let fowardSteps = idStep;
            fowardStepsNumber.push(idStep);
            //Guardamos las descripciones de los flujos y les damos la posición del paso en termino de saltos
            for (let stepPostion = 0; stepPostion < fowardStepsNumber.length; stepPostion++) {
                fowardStepsDescription.push({
                    option: stepsList[fowardSteps].description,
                    value: stepPostion,
                });
            }
        }
        //Si estamos en otro paso que no sea el útimo agregamos la posibilidad de terminar el flujo y además quitamos el primer paso siguiente para saltar solo se pueden hacer saltos de 2
        if (finishProccesJump) {
            //Si el paso es el penútltimo significa que ya no se pueden dar mas saltos y solo agregamos la opción de finalizar flujp
            if (fowardStepsDescription.length === 0) {
                fowardStepsDescription.push({
                    option: "Finalizar Flujo",
                    value: 2,
                });
            }
            //todos los pasos anteriores agregamos finalizar flujo con su valor en saltos
            else {
                fowardStepsDescription.push({
                    option: "Finalizar Flujo",
                    value: fowardStepsDescription.at(-1).value + 1,
                });
                fowardStepsDescription.shift();
            }
        }
        return fowardStepsDescription;
    };

    updateJumpListForEachStep = (tempProcessCopy, deletedStepDescription) => {
        const { setJumpableSteps } = this;
        const { steps } = tempProcessCopy;
        for (let index = 0; index < steps.length; index++) {
            const currentStep = steps[index];
            const { jumpStep, fowardStepsDescription } = currentStep;
            //console.log('jumpStep, fowardStepsDescription :>> ', jumpStep, fowardStepsDescription);
            //Buscamos en la lista de description si tiene un paso configurado
            let jumpStepDescription = fowardStepsDescription.find(
                (fowardStep) => fowardStep.value === jumpStep
            );
            if (jumpStepDescription?.option === deletedStepDescription) {
                //eliminamos el jumpStep
                delete tempProcessCopy["steps"][index]["jumpStep"];
                delete tempProcessCopy["steps"][index]["allowJump"];
                delete tempProcessCopy["steps"][index]["JumpCriteria"];
                Swal.fire({
                    icon: "info",
                    title: "El paso eliminado era el destino de salto de uno o más pasos",
                    text: "La lista de salto de cada paso ha sido actualizada",
                });
            }
            let newFowardStepsDescription = setJumpableSteps(steps, currentStep);
            tempProcessCopy["steps"][index]["fowardStepsDescription"] = newFowardStepsDescription;
            //Si el fujo se quedo con 1 paso eliminamos los atributos de salto
            if (newFowardStepsDescription.length === 0) {
                delete tempProcessCopy["steps"][index]["jumpStep"];
                delete tempProcessCopy["steps"][index]["allowJump"];
                delete tempProcessCopy["steps"][index]["JumpCriteria"];
            }
        }
        return tempProcessCopy;
    };

    /* StepsTableContent Functions */
    //Borramos un paso de la tabla de pasos del proceso temporal
    deleteTempStep = (id) => {
        const { updateJumpListForEachStep } = this;
        const { processErrors, errorHelper, tempProcess } = this.state;
        const errorHelperKeys = IntebotCatalog.errorHelper;

        //Eliminamos el paso de la lista de pasos
        let tempProcessCopy = JSON.parse(JSON.stringify(tempProcess));
        let deletedStep = tempProcessCopy["steps"].splice(id, 1)[0];
        let deletedStepDescription = deletedStep.description;

        //Buscamos si alguno de los pasos tenía como salto el step eliminado
        tempProcessCopy = updateJumpListForEachStep(tempProcessCopy, deletedStepDescription);

        //Actualizamos el ID de los pasos para evitar que haya huecos
        tempProcessCopy["steps"].forEach((step, index) => {
            step["step"] = index;
        });

        //Si se elimina el último paso de la tabla colocamos el error correspondiente en procesos
        let errorHelperCopy = JSON.parse(JSON.stringify(errorHelper));
        let processErrorsCopy = JSON.parse(JSON.stringify(processErrors));
        if (tempProcessCopy["steps"].length === 0) {
            processErrorsCopy["steps"] = true;
            errorHelperCopy["steps"] = errorHelperKeys.emptyTable;
        }
        const countProcessErrors = Object.values(processErrorsCopy).reduce(
            (a, item) => a + item,
            0
        );

        this.setState({
            tempProcess: tempProcessCopy,
            processErrors: processErrorsCopy,
            countProcessErrors: countProcessErrors,
            hasChanges: true,
            stepErrors: {},
            countStepErrors: 0,
            errorHelper: errorHelperCopy,
        });
    };

    firstStepCreated = () => {
        const { errorHelper, processErrors } = this.state;
        let errorHelperKeys = IntebotCatalog.errorHelper;
        //Quitamos el error de la tabla de pasos
        let processErrorsCopy = { ...processErrors, steps: false };
        let errorHelperCopy = {
            ...errorHelper,
            steps: errorHelperKeys.removeError,
        };
        const countProcessErrors = Object.values(processErrorsCopy).reduce(
            (a, item) => a + item,
            0
        );
        this.setState({
            processErrors: processErrorsCopy,
            errorHelper: errorHelperCopy,
            countProcessErrors: countProcessErrors,
        });
    };

    //Abrimos la sección de edición del paso dependiendo si existe o es nuevo
    setStep = (idStep) => {
        const { tempProcess } = this.state;
        const { firstStepCreated } = this;

        //Si se agrega un paso por primera vez quitamos el error correspondiente en procesos
        if (idStep === 0) {
            firstStepCreated();
        }

        //Si es un paso nuevo indicamos que se agrego uno nuevo
        if (idStep === tempProcess["steps"].length) {
            let newStep = true;
            this.setState({
                newStep: newStep,
            });
        }

        //Guardamos el idStep
        this.setState({
            idStep: idStep,
        });
    };

    handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: "",
            },
        });
    };

    updateBodyRequest = (bodyRequest) => {
        //recibimos el nuevo body request y guardamos en el state
        const { processErrors } = this.state;
        const bodyRequestIsEmpty = Object.keys(bodyRequest).length === 0;
        processErrors["bodyRequest"] = bodyRequestIsEmpty ? true : false;
        const countProcessErrors = Object.values(processErrors).reduce((a, item) => a + item, 0);
        this.setState({
            tempProcess: {
                ...this.state.tempProcess,
                bodyRequest: bodyRequest,
                bodyRequestKey: "",
                bodyRequestValue: "",
            },
            hasChanges: true,
            processErrors: processErrors,
            countProcessErrors: countProcessErrors,
        });
    };

    addBodyRequest = (bodyRequest) => {
        const { tempProcess } = this.state;
        if (bodyRequest.bodyRequestValue !== "Escribe tu valor aquí") {
            delete bodyRequest.bodyOptionValue;
        }

        let inputKey = bodyRequest.bodyRequestKey.trim().replace(/\s/g, "");
        let inputValue =
            bodyRequest.bodyRequestValue === "null"
                ? null
                : bodyRequest.bodyOptionValue
                ? bodyRequest.bodyOptionValue
                : bodyRequest.bodyRequestValue;
        let bodyRequestObject = {
            ...tempProcess.bodyRequest,
            [inputKey]: inputValue,
        };

        this.updateBodyRequest(bodyRequestObject);
    };

    editBodyRequest = (bodyRequest, index) => {
        //creamos una copia del body request
        const { tempProcess } = this.state;

        let bodyRequestCopy = Object.assign({}, tempProcess.bodyRequest);

        if (bodyRequest.bodyRequestValue !== "Escribe tu valor aquí") {
            delete bodyRequest.bodyOptionValue;
        }

        let requestKey = bodyRequest.bodyRequestKey.trim().replace(/\s/g, "");

        //convertimos el bodyrequest a array
        let bodyRequestArray = Object.entries(bodyRequestCopy);
        //guardamos el nuevo valor en el valor correspondiente
        bodyRequestArray[index][0] = requestKey;
        //regresamos el body request a Object
        bodyRequestCopy = {
            ...Object.fromEntries(bodyRequestArray),
        };

        //agregamos el nuevo valor al key correspondiente
        let requestValue =
            bodyRequest.bodyRequestValue === "null"
                ? null
                : bodyRequest.bodyOptionValue
                ? bodyRequest.bodyOptionValue
                : bodyRequest.bodyRequestValue;
        bodyRequestCopy[requestKey] = requestValue;

        this.setState({
            tempProcess: {
                ...this.state.tempProcess,
                bodyRequest: bodyRequestCopy,
            },
            hasChanges: true,
        });
    };

    removeBodyRequest = (key) => {
        //simplemente hacemos un delete a la copia y guardamos en el state
        const { processErrors, tempProcess, errorHelper } = this.state;
        const errorHelperKeys = IntebotCatalog.errorHelper;

        let errorHelperCopy = JSON.parse(JSON.stringify(errorHelper));
        const bodyRequestCopy = JSON.parse(JSON.stringify(tempProcess.bodyRequest));
        delete bodyRequestCopy[key];
        // si body request está vacío, marcar error
        const bodyRequestIsEmpty = Object.keys(bodyRequestCopy).length === 0;
        processErrors["bodyRequest"] = bodyRequestIsEmpty ? true : false;
        errorHelperCopy["bodyRequest"] = bodyRequestIsEmpty
            ? errorHelperKeys.emptyTable
            : errorHelperKeys.removeError;
        const countProcessErrors = Object.values(processErrors).reduce((a, item) => a + item, 0);
        this.setState({
            tempProcess: {
                ...this.state.tempProcess,
                bodyRequest: bodyRequestCopy,
            },
            hasChanges: true,
            processErrors: processErrors,
            countProcessErrors: countProcessErrors,
            errorHelper: errorHelperCopy,
        });
    };

    addResponseBranch = (responseBranch) => {
        const { tempProcess } = this.state;
        const tempProcessCopy = JSON.parse(JSON.stringify(tempProcess));

        // agregamos el nombre del campo al caso de respuesta
        //responseBranch.responseCode.responseName = tempProcessCopy.responseBranches[0] ? tempProcessCopy.responseBranches[0].responseCode.responseName : tempProcessCopy.responseName;

        //hacemos push de únicamente el objeto
        tempProcessCopy.responseBranches.push(responseBranch);
        this.setState({
            tempProcess: tempProcessCopy,
            hasChanges: true,
        });
    };

    updateResponseBranch = (responseBranch, index) => {
        const { tempProcess } = this.state;
        const tempProcessCopy = JSON.parse(JSON.stringify(tempProcess));

        tempProcessCopy.responseBranches[index] = responseBranch;

        // agregamos el nombre del campo al caso de respuesta
        //tempProcessCopy.responseBranches[index].responseCode.responseName = tempProcessCopy.responseBranches[0].responseCode.responseName || tempProcessCopy.responseName

        this.setState({
            tempProcess: tempProcessCopy,
            hasChanges: true,
        });
    };

    removeResponseBranch = (index) => {
        const { tempProcess } = this.state;
        const tempProcessCopy = JSON.parse(JSON.stringify(tempProcess));

        tempProcessCopy.responseBranches.splice(index, 1);
        this.setState({
            tempProcess: tempProcessCopy,
            hasChanges: true,
        });
    };

    createBrandNewStep = () => {
        this.setStep(0);
        setTimeout(() => this.openSection("currentStep"), 3);
    };

    handleCloseDialog = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            openDialog: false,
            singleActionDialog: false,
        });
    };

    relatedValues = () => {
        const { tempProcess, idStep } = this.state;
        const relatedValues = tempProcess["steps"]
            .filter((step) => step.step < idStep)
            .map((step) => {
                return {
                    id: step.description,
                    display: `${step.description}`,
                    list: "Local",
                };
            });
        return relatedValues;
    };

    setStepHasChanges = (stepHasChanges) => {
        this.setState({
            stepHasChanges: stepHasChanges,
        });
    };

    addRequestHeader = (headerKey, headerValue) => {
        const { tempProcess } = this.state;
        let requestHeadersCopy = tempProcess["requestHeaders"] ?? {};
        requestHeadersCopy[headerKey] = headerValue;
        tempProcess["requestHeaders"] = requestHeadersCopy;
        this.setState({
            tempProcess: tempProcess,
            hasChanges: true,
        });
    };

    updateRequestHeader = (prevHeaderKey, headerKey, headerValue, indexTable) => {
        //console.log(prevHeaderKey, headerKey, headerValue);
        const { tempProcess } = this.state;
        let tempProcessCopy = JSON.parse(JSON.stringify(tempProcess));
        //convertimos el requestHeaders to array
        let requestHeadersArray = Object.entries(tempProcessCopy["requestHeaders"]);
        //guardamos el nuevo valor en el valor correspondiente
        requestHeadersArray[indexTable][0] = headerKey;
        requestHeadersArray[indexTable][1] = headerValue;
        //regresamos el requestHeaders a Object
        tempProcessCopy["requestHeaders"] = Object.fromEntries(requestHeadersArray);
        this.setState({
            tempProcess: tempProcessCopy,
            hasChanges: true,
        });
    };

    deleteRequestHeader = (headerKey) => {
        const { tempProcess } = this.state;
        let tempProcessCopy = JSON.parse(JSON.stringify(tempProcess));
        delete tempProcessCopy["requestHeaders"][headerKey];
        this.setState({
            tempProcess: tempProcessCopy,
            hasChanges: true,
        });
    };

    render() {
        const {
            alert,
            tempProcess,
            section,
            countProcessErrors,
            countStepErrors,
            processErrors,
            idStep,
            stepErrors,
            hasChanges,
            errorHelper,
            newFlow,
            newStep,
            openDialog,
            dialog,
            singleActionDialog,
        } = this.state;
        const { buttonName, buttonTooltip, buttonType, startIcon, currentProcess, processTemp } =
            this.props;
        const {
            relatedValues,
            openSection,
            deleteTempStep,
            updateBodyRequest,
            addBodyRequest,
            editBodyRequest,
            removeBodyRequest,
            addResponseBranch,
            updateResponseBranch,
            removeResponseBranch,
            setStep,
            onCancel,
            onSave,
            onSaveStep,
            setStepHasChanges,
            addRequestHeader,
            updateRequestHeader,
            deleteRequestHeader,
        } = this;

        const { readOnly } = this.context;

        //Props
        const currentProcessProps =
            section === "currentProcess"
                ? {
                      currentProcess: tempProcess,
                      errors: processErrors,
                      serviceType: tempProcess["serviceType"],
                      channels: tempProcess["visibleChannel"],
                      allChannelsCheckbox: tempProcess["allChannelsCheckbox"],
                      responseAttributes: tempProcess["responseAttributes"],
                      bodyRequest: tempProcess["bodyRequest"],
                      errorHelper,
                      processTemp,
                      openStepsTable: (isEditable) =>
                          isEditable ? this.openSection("stepsTable") : this.createBrandNewStep(),
                      changeHandler: (
                          targetName,
                          targetValue,
                          targetType,
                          targetRequired,
                          targetIndex
                      ) =>
                          this.changeHandler(
                              targetName,
                              targetValue,
                              targetType,
                              targetRequired,
                              targetIndex
                          ),
                      handleServiceChange: (targetName, targetValue) =>
                          this.handleServiceChange(targetName, targetValue),
                      updateBodyRequest,
                      addBodyRequest,
                      editBodyRequest,
                      removeBodyRequest,
                      addResponseBranch,
                      updateResponseBranch,
                      removeResponseBranch,
                      addRequestHeader,
                      updateRequestHeader,
                      deleteRequestHeader,
                  }
                : {};

        const stepsTableProps =
            section === "stepsTable"
                ? {
                      tempSteps: tempProcess["steps"],
                      errorHelper: errorHelper,
                      openStep: (idStep) => {
                          setStep(idStep);
                          setTimeout(() => openSection("currentStep"), 5);
                      },
                      deleteTempStep: (idStep) => deleteTempStep(idStep),
                  }
                : {};

        const currentStepProps =
            section === "currentStep"
                ? {
                      tempStep: tempProcess["steps"][idStep],
                      stepsList: tempProcess["steps"],
                      currentProcess: tempProcess,
                      relatedValues: relatedValues(),
                      processTemp,
                      idStep,
                      onSaveStep,
                      setStepHasChanges,
                      readOnly,
                  }
                : {};

        return (
            <div>
                {/* Alertas de error o de succes o de retroalimentacion  */}
                <CustomAlert
                    open={alert.open}
                    severity={alert.severity}
                    message={alert.message}
                    handleCloseAlert={() => this.handleCloseAlert()}
                />
                {/* Modales de acciones, pedir al usuario que confirme una acción */}
                <Dialog open={openDialog} onClose={() => this.handleCloseDialog()}>
                    <DialogTitle>{dialog.title}</DialogTitle>
                    <DialogContent>{dialog.message}</DialogContent>
                    {singleActionDialog ? (
                        <DialogActions>
                            <Button onClick={() => this.handleCloseDialog()} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <CustomButton onClick={() => this.handleCloseDialog()} color="primary">
                                No
                            </CustomButton>
                            <CustomButton
                                onClick={async () => {
                                    await this.setState({
                                        stepHasChanges: false,
                                    });
                                    this.openSection("stepsTable");
                                    this.handleCloseDialog();
                                }}
                                color="primary"
                                autoFocus
                            >
                                Sí
                            </CustomButton>
                        </DialogActions>
                    )}
                </Dialog>
                <FormModal
                    ref={this.child}
                    modalTitle={
                        //Sección en el título del modal para navegación
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                            {section === "currentProcess" ? (
                                //agregamos un espacio para poner la flecha hacia atrás flotante
                                <div style={{ padding: "0 25px" }}></div>
                            ) : section === "stepsTable" ? (
                                <IconButton onClick={() => this.openSection("currentProcess")}>
                                    <ArrowBackIcon />
                                </IconButton>
                            ) : section === "currentStep" ? (
                                <IconButton onClick={() => this.openSection("stepsTable")}>
                                    <ArrowBackIcon />
                                </IconButton>
                            ) : null}
                            <BackToFlows
                                titleButton="Flujos"
                                askConfirm={hasChanges}
                                onClose={() => {
                                    this.child.current.handleClose();
                                    this.onCancel();
                                }}
                            />
                            {currentProcess.processName === "" ? (
                                <Button
                                    color="primary"
                                    size="small"
                                    onClick={() => this.openSection("currentProcess")}
                                >
                                    Flujo:{" "}
                                    {this.state.tempProcess.processName !== ""
                                        ? this.state.tempProcess.processName
                                        : "Nuevo Flujo"}
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    size="small"
                                    onClick={() => this.openSection("currentProcess")}
                                >
                                    Flujo: {currentProcess.processName}
                                </Button>
                            )}
                            {section === "stepsTable" || section === "currentStep" ? (
                                <Button
                                    color="primary"
                                    size="small"
                                    onClick={() => this.openSection("stepsTable")}
                                >
                                    Secuencia de Pasos
                                </Button>
                            ) : null}
                            {section === "currentStep" ? (
                                <Button
                                    color="primary"
                                    size="small"
                                    onClick={() => this.openSection("currentStep")}
                                >
                                    Paso {idStep + 1}
                                </Button>
                            ) : null}
                        </Breadcrumbs>
                    }
                    fullScreen
                    buttonName={buttonName}
                    buttonTooltip={buttonTooltip}
                    buttonType={buttonType}
                    startIcon={startIcon}
                    onCancel={(e) => onCancel(e)}
                    onSave={() => onSave()}
                    hasErrors={
                        countProcessErrors > 0 || //Si existen errores en los campos nivel proceso
                        countStepErrors > 0 || //Si existen errores en los campos nivel paso
                        typeof tempProcess["steps"] == "undefined" || //O si no hemos definido pasos
                        tempProcess["steps"].length === 0 //O si la tabla de pasos está vacía
                    }
                    hasChanges={hasChanges}
                    section={section}
                    stepErrors={stepErrors}
                    flaggedErrors={{ ...processErrors, ...stepErrors }}
                    newFlow={newFlow}
                    disableCancel={newStep}
                    noActions={section === "currentStep" || readOnly}
                >
                    {section === "currentProcess" && <ProcessContent {...currentProcessProps} />}
                    {section === "stepsTable" && <StepsTableContent {...stepsTableProps} />}
                    {section === "currentStep" && <EditStepContent {...currentStepProps} />}
                </FormModal>
            </div>
        );
    }
}

ProcessModal.contextType = AppContext;
