import React, { useContext, useState, useEffect } from 'react';

//Mui components
import {
    TextField,
    InputAdornment,
    Button as MuiButton,
    IconButton,
    LinearProgress
  } from "@material-ui/core";
  import { withStyles } from '@material-ui/core/styles';

//Icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

//External libraries
import Swal from 'sweetalert2' //https://sweetalert2.github.io
import { isStrongPassword } from "validator";

//Custom Portal Components
import AppContext from '../../Context/AppContext';
import IntebotCatalog from '../IntebotCatalog'
import { hashPassword } from './hashPassword';
import { authActions } from '../DataFunctions'

const errorHelperKeys = IntebotCatalog.errorHelper

const Button = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        //border: 0,
        color: 'white',
        height: 36,
        padding: '0 30px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&$disabled': {
            color: 'white',
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: 'none',
    },
}))(MuiButton);

const ResetPassword = () => {

    //React Hooks
    const context = useContext(AppContext);
    const [state, setState] = useState({
        //Reset Password
        resetPasswordForm: {
            newPassword:'',
        },
        newConfirmPassword:'',
        resetPasswordErrors:{
            newPassword: false,
            newConfirmPassword: false
        },
        errorHelperResetPassword:{
            newPassword: errorHelperKeys.removeError,
            newConfirmPassword: errorHelperKeys.removeError,
        },
        passwordScore:0, 
        isPasswordStrong: false,
    });

    useEffect(() => {
        performURLaction()
    }, [])

    const performURLaction = async () => {
        let url = new URL(document.location);
        let searchParams = new URLSearchParams(url.search);
        let token = searchParams.get("token")
        let action = searchParams.get("action")
        if(!token){
            let resultAlert1 = await Swal.fire({
                icon: 'error',
                title: 'El enlace al que ingresaste esta roto',    
                text: 'No tienes permiso para ver esta página.',
            })
            if(resultAlert1){
                window.location.href=`/login`
            }
        }
    }
    
    //Cambiar el campo de password -> Text
    const handleShowPassword = () => {
        const { showPassword } = state;
        setState({
            ...state,
            showPassword: showPassword ? false : true,
        })
    }

    const handleResetPasswordForm = (targetName, targetValue) => {
        let { resetPasswordForm, newConfirmPassword, isPasswordStrong, passwordScore, resetPasswordErrors, errorHelperResetPassword } = state;
        let resetPasswordFormCopy = JSON.parse(JSON.stringify(resetPasswordForm));
        let newConfirmPasswordCopy = JSON.parse(JSON.stringify(newConfirmPassword));
        errorHelperResetPassword['newPassword'] = errorHelperKeys.removeError; //Quitamos el error del form cuando lo vuelven a llenar

        // manejo errores en form
        let resetPasswordErrorsCopy = JSON.parse(JSON.stringify(resetPasswordErrors));
        let errorHelperResetPasswordCopy = JSON.parse(JSON.stringify(errorHelperResetPassword)); //Mensaje de error

        //console.log(targetName, targetValue); 
        switch (targetName) {
            case 'newPassword':
                //Validar si la contraseña cumple los requisitos
                isPasswordStrong = isStrongPassword(targetValue, { minLength: 8, returnScore: false }) //Regresa true si la contraseña cumple los requerimientos
                passwordScore = isStrongPassword(targetValue, { minLength: 8, returnScore: true }) * 2 //Un score de 50 o más = %100 valido, entonces lo multiplicamos por 2 para tener la barra al 100%
                if(targetValue.length > 15) {
                    //NO guardamos el campo
                    break;
                }
                 //Guardamos el campo
                resetPasswordFormCopy[targetName] = targetValue;
                resetPasswordErrorsCopy[targetName] = false;
                errorHelperResetPasswordCopy[targetName] = errorHelperKeys.removeError;

                break;
            case 'newConfirmPassword':
                newConfirmPasswordCopy = targetValue;

                resetPasswordErrorsCopy[targetName] = false;
                errorHelperResetPassword[targetName] = errorHelperKeys.removeError;
                break;
            default:
                break;
        }

        setState({
            ...state,
            resetPasswordForm: resetPasswordFormCopy,
            newConfirmPassword: newConfirmPasswordCopy,
            isPasswordStrong,
            passwordScore,
        })
    }

    const sendResetPassword = async (resetPasswordForm) => {
        const { updateContextAttribute } = context;
        const { errorHelperResetPassword } = state;
        let errorHelperResetPasswordCopy = JSON.parse(JSON.stringify(errorHelperResetPassword))
        const { newPassword } = resetPasswordForm;
        let newPasswordCopy = newPassword
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Cambiando contraseña...')
        resetPasswordForm.newPassword = await hashPassword(newPassword)
        console.table(resetPasswordForm)
        //Buscamos si el URL contiene el Token
        let url = new URL(document.location);
        let searchParams = new URLSearchParams(url.search);
        let token = searchParams.get("token")
        let action = searchParams.get("action")
        let res = await authActions(resetPasswordForm, `${action}&token=${token}`, 'PasswordReset')
        let alertResult;
        resetPasswordForm.newPassword = newPasswordCopy
        //console.log('res :>> ', res);
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        //validar si no hay respuesta
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
        switch (responseCode) {
            case 0:
                alertResult = await Swal.fire({
                    icon: 'success',
                    title: 'Contraseña cambiada con éxito.',
                    text: 'Ya puedes iniciar sesión.',
                })
                if (alertResult) {
                    window.location.href="/login"
                }
                break;
            case 1:
                errorHelperResetPasswordCopy['resetForm'] = errorHelperKeys.accountNotExists;
                break;
            case 3:
                alertResult = await Swal.fire({
                    icon: 'error',
                    title: 'El tiempo para recuperar tu contraseña expiró.',
                    text: 'Solicita de nuevo el restablecimiento de tu contraseña.',
                })
                if (alertResult) {
                    window.location.href="/search-account"
                }
                break;
            default:
                Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error',
                    text: 'Intenta más tarde',
                })
                break;
            }
        setState({
            ...state,
            errorHelperResetPassword: errorHelperResetPasswordCopy
        })


    }

    const searchErrorsResetPassword = () => {
        const { resetPasswordForm, newConfirmPassword, resetPasswordErrors, errorHelperResetPassword, isPasswordStrong } = state;
        let newConfirmPasswordCopy = JSON.parse(JSON.stringify(newConfirmPassword));
        let resetPasswordErrorsCopy = JSON.parse(JSON.stringify(resetPasswordErrors));
        let errorHelperResetPasswordCopy = JSON.parse(JSON.stringify(errorHelperResetPassword));
        
        for (const keyResetPassword in resetPasswordForm) {
            // const formValue = resetPasswordForm[keyResetPassword]; //valor actual que esta siendo iterado
            switch (keyResetPassword) {
                case 'newPassword':
                    //Validar que la contraseña cumpla los requisitos
                    if(!isPasswordStrong){
                        resetPasswordErrorsCopy[keyResetPassword] = true;
                        errorHelperResetPasswordCopy[keyResetPassword] = errorHelperKeys.notStrongPassword;
                        break;
                    }
                    break;
            
                default:
                    break;
            }
        }
        //Las contraseñas no coinciden
        if(resetPasswordForm.newPassword !== newConfirmPasswordCopy){
            //Error de las contraseñas no son iguales
            resetPasswordErrorsCopy['newConfirmPassword'] = true;
            errorHelperResetPasswordCopy['newConfirmPassword'] = errorHelperKeys.passwordNotMatch;
        }

        //iteramos el objeto de errores
        let resetPasswordFormHasErrors = false;
        for (const key in resetPasswordErrorsCopy) {
            const errorValue = resetPasswordErrorsCopy[key];
            //Si encontramos 1 error, ponemos la bandera en true y evitamos que se mande el form
            if(errorValue){
                resetPasswordFormHasErrors = true;
                break; 
            }
        }

        //si no hay errores, mandamos a llamar a crear la cuenta
        if(!resetPasswordFormHasErrors) {
            sendResetPassword(resetPasswordForm)
        }

        setState({
            ...state, 
            resetPasswordErrors: errorHelperResetPasswordCopy,
            errorHelperResetPassword: errorHelperResetPasswordCopy,
        })

    }

    const { showPassword, resetPasswordForm, newConfirmPassword, passwordScore, isPasswordStrong, resetPasswordErrors, errorHelperResetPassword } = state;
    return (
        <div>
            <header>
               <h2>Cambio de contraseña</h2>
            </header>
            <div style={{display: 'flex',justifyContent:'center'}} >
                <span style={{fontSize: '0.75rem',color:'red'}} >{errorHelperResetPassword['resetForm']}</span> 
            </div>
            <div className='AuthInputsContainer'>
               <TextField
                   name="newPassword"
                   value={resetPasswordForm.newPassword}
                   variant="outlined"
                   size="small"
                   onChange={(e) => handleResetPasswordForm(e.target.name, e.target.value)}
                   label="Nueva contraseña"
                   type={showPassword ? "text" : "password"}
                   error={resetPasswordErrors.newPassword}
                   helperText={errorHelperResetPassword.newPassword}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                                   <IconButton onClick={() => handleShowPassword()}>
                                       { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                   </IconButton>
                           </InputAdornment>
                       ) 
                   }}
               />
               { !resetPasswordErrors["newPassword"] && <span style={{fontSize: '0.75rem'}} >{errorHelperKeys.notStrongPassword}</span> }
               <TextField
                   name="newConfirmPassword"
                   value={newConfirmPassword}
                   variant="outlined"
                   size="small"
                   onChange={(e) => handleResetPasswordForm(e.target.name, e.target.value)}
                   label="Confirmar nueva contraseña"
                   type={showPassword ? "text" : "password"}
                   error={resetPasswordErrors.newConfirmPassword}
                   helperText={errorHelperResetPassword.newConfirmPassword}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                                   <IconButton onClick={() => handleShowPassword()}>
                                       { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                   </IconButton>
                           </InputAdornment>
                       ) 
                   }}
               />
               <div>
                   <span>Seguridad de la contraseña</span>
                   <LinearProgress
                      classes={{
                        bar: isPasswordStrong
                        ? "linearProgressColorGreen"
                        : passwordScore > 50
                        ? "linearProgressColorOrange"
                        : "linearProgressColorRed",
                      }}
                      variant="determinate"
                      value={passwordScore < 100 ? passwordScore : 100}
                    />
               </div>
               <div className="authButtonContainer" >
                   <Button onClick={() => searchErrorsResetPassword()} >Cambiar contraseña</Button>
               </div>
            </div>
         </div>
    );
}

export default ResetPassword;
