import React, { useEffect, useState, useRef } from 'react';

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
    IconButton,
    TextField,
    Grid,
    Tooltip,
    MenuItem
} from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';

//Icons
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

//Custom portal components
import CustomButton from "../../common/CustomButton";
import { errorHelper, appCulture } from '../../IntebotCatalog';

//External libraries
import Swal from 'sweetalert2' //https://sweetalert2.github.io

//Footer styles
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        padding: theme.spacing(2),
        margin: '0',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
}))

//==== Dialog Styles =====
const Dialog = withStyles((theme) => ({
    root: {
        marginTop: '56px',
        [theme.breakpoints.up('md')]: {
            marginTop: '64px',
        },
    },
}))(MuiDialog)

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle)

const DialogContent = withStyles((theme) => ({
    root:{
        display:'flex',
        justifyContent:'center',
        padding: "23px",
        width: "400px"
    }

}))(MuiDialogContent)

const CreateNewAppModal = (props) => {

    const defaultAppBody = useRef({
        nameApp: '',
        cultureApp: "es-mx"
    })
    const defaultErrors = useRef({
        nameApp: true
    })
    const defaultErrorHelper = useRef({
        nameApp: errorHelper.emptyField
    })

    const [createAppError, setCreateAppError] = useState(defaultErrors.current);
    const [createAppErrorHelper, setCreateAppErrorHelper] = useState(defaultErrorHelper.current);
    const [newAppBody, setNewAppBody] = useState(defaultAppBody.current);
    const [hasChanges, setHasChanges] = useState(false);
    const { openCreateNewAppModal, setOpenCreateNewAppModal, createNewApp } = props;

    const handleNewAppForm = (targetName, targetValue) => {
        let newAppBodyCopy = { ...newAppBody };
        let createAppErrorCopy = { ...createAppError };
        let createAppErrorHelperCopy = { ...createAppErrorHelper };
        switch (targetName) {
            case 'nameApp':
                //remove spaces from targetValue
                newAppBodyCopy[targetName] = targetValue.replace(/ /g,"")
                if(!targetValue){
                    createAppErrorCopy.nameApp = true;
                    createAppErrorHelperCopy.nameApp = errorHelper.emptyField;
                    break;
                }
                createAppErrorCopy.nameApp = false;
                createAppErrorHelperCopy.nameApp = errorHelper.removeError;                
                break;
            case 'cultureApp':
                newAppBodyCopy[targetName] = targetValue;
                break;
            default:
                break;
        }
        setNewAppBody(newAppBodyCopy);
        setCreateAppError(createAppErrorCopy);
        setCreateAppErrorHelper(createAppErrorHelperCopy);
        setHasChanges(true);
    }

    //Limpia el formulario cuando se cierra el modal
    useEffect(() => {
        if (!openCreateNewAppModal) {
            clearForm();
        }
    }, [openCreateNewAppModal])


    const clearForm = () => {
        setNewAppBody(defaultAppBody.current);
        setCreateAppError(defaultErrors.current);
        setCreateAppErrorHelper(defaultErrorHelper.current);
        setHasChanges(false);
    }

    const handleClose = async (newIntentCreated) => {
        if(!newIntentCreated && hasChanges){
            let alert = await Swal.fire({
                text: '¿Estás seguro que deseas salir?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: `No`,
                confirmButtonColor: '#27315d',
                denyButtonColor: '#27315d',
            })
            //Cancelamos el eliminado del Par
            if (alert.isDenied || alert.isDismissed) {
                return
            }
        }
        setOpenCreateNewAppModal(false)
    }

    const classes = useStyles();
    return (
        <Dialog
            open={openCreateNewAppModal}
        >
            <DialogTitle disableTypography>
                <h2>Crear nueva aplicación</h2>
                <IconButton onClick={() => handleClose(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid className='intent-modal-content' item xs={12}>
                    <div>
                        <div className='center-tooltip'>
                            <label className='top-label-field' >
                                Nombre de la aplicación
                            </label>
                        </div>
                        <TextField
                            name="nameApp"
                            value={newAppBody.nameApp}
                            size='small'
                            type='small'
                            onChange={(event) => handleNewAppForm(event.target.name, event.target.value)}
                            fullWidth
                            variant='outlined'
                            error={createAppError['nameApp']}
                            helperText={createAppErrorHelper['nameApp']}
                        />
                    </div>
                    <div>
                        <div className='center-tooltip'>
                            <label className='top-label-field' >
                                Cultura de la aplicación
                            </label>
                            <Tooltip
                                title={`
                                    La cultura de la aplicación es el idioma que entenderá la aplicación. No el lenguaje de la interfaz.
                                `}
                                >
                                <HelpOutlineIcon color="primary" fontSize="small" />
                            </Tooltip>
                        </div>
                        <TextField
                            select
                            name={"cultureApp"}
                            value={newAppBody.cultureApp}
                            size='small'
                            type='small'
                            onChange={(event) => handleNewAppForm(event.target.name, event.target.value)}
                            fullWidth
                            variant='outlined'
                        >
                            {
                                appCulture.map((option, i) =>
                                    <MenuItem key={i} value={option.code}>{option.language}</MenuItem>
                                )

                            }
                        </TextField>
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <div className={classes.root}>
                    <CustomButton 
                        disabled={createAppError['nameApp'] || !hasChanges}
                        onClick={() => createNewApp(newAppBody)}
                        variant="contained" 
                    > 
                        Crear aplicación
                    </CustomButton>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default CreateNewAppModal;