import React from 'react';
// material ui components
import { Button as MuiButton, Tooltip, Typography, IconButton, Grid } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import LaunchIcon from '@material-ui/icons/Launch';
import { withStyles } from '@material-ui/core/styles';
// components
import CustomButton from "../common/CustomButton";
import ButtonConfirmDialog from '../common/ButtonConfirmDialog';
import CloseButton from '../common/CloseButton';
import ArrowClose from '../common/ArrowClose';

import AppContext from '../../Context/AppContext';

// functions
import { getErrorFieldName } from "./ErrorFieldName";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '8px',
    },
    paper: { minWidth: "500px" },
})

const Button = withStyles(theme => ({
    label: {
        textTransform: 'none',
    }
}))(MuiButton)

// const useStyles = makeStyles(theme => ({
//     paper: {
//         minWidth: "400px"
//     },
// }))


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, askConfirm, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <CloseButton onClose={onClose} askConfirm={askConfirm} modalTitle="¿Estás seguro que deseas salir?">
                    Los cambios realizados se perderán.
                </CloseButton>
            ) : null}
        </MuiDialogTitle>
    )
})

const DialogTitleArrow = withStyles(styles)((props) => {
    const { children, classes, onClose, askConfirm, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <ArrowClose onClose={onClose} askConfirm={askConfirm} modalTitle="¿Estás seguro que deseas salir?">
                    Los cambios realizados se perderán.
                </ArrowClose>
            ) : null}
        </MuiDialogTitle>
    )
})

const Dialog = withStyles((theme) => ({
    root: {
        marginTop: '56px',
        [theme.breakpoints.up('md')]: {
            marginTop: '64px',
        },
        "& .MuiDialog-paper": {
            minWidth: "26vw"
        },
        
    },
    
}))(MuiDialog)

const DialogContent = withStyles((theme) => ({
    root: props => ({
        [theme.breakpoints.up('sm')]: {
            paddingRight: '10%',
            paddingLeft: '10%',
        },
        '& div label': {
            display: 'block',
            [theme.breakpoints.up('md')]: {
                textAlign: 'right',
            },
        },
    }),
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        justifyContent: 'center',
    },
}))(MuiDialogActions)

export default class FormModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
        if (this.props.isOpen) {
            this.props.isOpen(true)
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
        })
        if (this.props.isOpen) {
            this.props.isOpen(false)
        }
    }
    maxFlows = (boolvalue) => {
        this.setState({
            openDialog:boolvalue,
        })
    }

    render() {
        const { getStorageData } = this.context;
        const {
          modalTitle,
          buttonName,
          buttonTooltip,
          children,
          endIcon,
          startIcon,
          justIcon,
          buttonSize,
          buttonType,
          fullScreen,
          hasChanges,
          hasErrors,
          mediumSize,
          noConfirm,
          section,
          newFlow,
          disableCancel,
          alignButtonRight,
          disableSaveOnError,
          flaggedErrors,
          noCancelButton,
          noActions,
        } = this.props;

        let storageData = getStorageData([
            "subscriptionTier"
        ])
        let suscription = storageData.subscriptionTier.subscription
        let numberFlows = storageData.subscriptionTier.alias

        return (
            <div>  
            <Dialog
                open={this.state.openDialog || false}
            >
                <DialogTitle style={{textAlign:'center'}} >&nbsp;Alcanzaste limite de flujos. </DialogTitle>
                <DialogContent>
                    <p>Si deseas agregar un nuevo flujo es necesario eliminar uno existente o contactar a soporte a cliente para actualizar tu nivel de suscripción.</p>
                    <p>Tu suscripción actual es: <strong> {suscription} </strong> y el limite de flujos es: <strong> {numberFlows} </strong> </p>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => this.maxFlows(false)} >
                    Ok
                    </Button>
                </DialogActions>
            </Dialog>
              <Grid container justifyContent={alignButtonRight ? "flex-end": "center"} >
                <Tooltip title={buttonTooltip} disableHoverListener={buttonTooltip && buttonTooltip.length > 0 ? false: true}>
                    {buttonType === 'link' ?
                        <Button startIcon={startIcon? startIcon : null} endIcon={endIcon? endIcon : null} color="primary" size="small" onClick={() => this.handleOpen()}>
                            {buttonName}
                        </Button>
                    : justIcon ? 
                        <IconButton variant="outlined" size={buttonSize} onClick={() => this.handleOpen()}>
                            {justIcon}
                        </IconButton>
                    : buttonType === 'maxFlows' ?
                        <Button startIcon={startIcon? startIcon : null} endIcon={endIcon? endIcon : null} color="primary" size="small" onClick={() => this.maxFlows(true)} >
                            {buttonName}
                        </Button>
                    :
                        <Button endIcon={endIcon ? endIcon : <LaunchIcon />} onClick={() => this.handleOpen()}>
                            {buttonName}
                        </Button>
                    }
                </Tooltip>
              </Grid>

                <Dialog
                    open={this.state.open}
                    fullScreen={fullScreen}
                >
                    <DialogTitle onClose={() => { this.handleClose(); this.props.onCancel(); }} askConfirm={hasChanges}>
                        {/* duplicamos el botón de salir pero con el icono de flecha hacia atrás, y preguntamos si esta en la primera sección del modal le haga render */}
                        {section ==='currentProcess' ?
                            <DialogTitleArrow onClose={() => { this.handleClose(); this.props.onCancel(); }} askConfirm={hasChanges}>
                            </DialogTitleArrow> 
                        : null}
                        {modalTitle}
                    </DialogTitle>

                    <DialogContent dividers mediumsize={mediumSize ? 'true' : 'false'}>
                        {children}
                    </DialogContent>

                    {
                        noActions ? 
                            null 
                        : <DialogActions>
                            {hasChanges ?
                                <React.Fragment>
                                    {
                                        newFlow ?
                                            <ButtonConfirmDialog
                                                disabled
                                                buttonName="Cancelar"
                                                title="¿Estás seguro que deseas cancelar tus cambios?"
                                                confirmName="Sí"
                                                cancelName="No"
                                                variant="contained"
                                                onConfirm={() => this.props.onCancel()}
                                            >
                                                Se revertirán todas las modificaciones realizadas.
                                            </ButtonConfirmDialog>
                                        : noCancelButton ? null
                                        : //false 
                                            <ButtonConfirmDialog
                                                disabled={disableCancel || noConfirm}
                                                buttonName="Cancelar"
                                                title="¿Estás seguro que deseas cancelar tus cambios?"
                                                confirmName="Sí"
                                                cancelName="No"
                                                variant="contained"
                                                onConfirm={() => section==="currentStep" ? this.props.onCancel(true) : this.props.onCancel()}
                                            >
                                                Se revertirán todas las modificaciones realizadas.
                                            </ButtonConfirmDialog>
                                    }
                                    { hasErrors && !disableSaveOnError  ?
                                        <ButtonConfirmDialog
                                            buttonName="Guardar"
                                            title="No es posible guardar la información."
                                            confirmName="Ok"
                                            variant="contained"
                                            color="primary"
                                        >
                                            <React.Fragment>
                                                Por favor revisa los errores en los campos marcados con color rojo.

                                            { flaggedErrors ? 
                                                <div>
                                                    <span><br/>Los siguientes campos no pueden estar vacíos: </span>
                                                    <ul style={{marginTop: "0"}}>
                                                    { Object.keys(flaggedErrors).map((key, index) => {
                                                        if (flaggedErrors[key]) {
                                                            return <li key={index}>{getErrorFieldName(key)}.</li> 
                                                        }
                                                        return "";
                                                      })
                                                    }
                                                    </ul>
                                                </div>
                                                : null
                                            }
                                            </React.Fragment>
                                        </ButtonConfirmDialog>
                                    : ((hasErrors && disableSaveOnError)) ?
                                        <CustomButton variant="contained" disabled color="primary">Guardar</CustomButton>
                                    : noConfirm || disableSaveOnError ?
                                        <CustomButton variant="contained" color="primary" onClick={() => { this.handleClose(); this.props.onSave() }} >
                                            Guardar
                                        </CustomButton>
                                    :
                                        <ButtonConfirmDialog
                                            buttonName="Guardar"
                                            title="¿Confirmas que deseas guardar tus cambios?"
                                            confirmName="Sí"
                                            cancelName="No"
                                            variant="contained"
                                            color="primary"
                                            onConfirm={disableSaveOnError ? 
                                                () => { this.props.onSave(); this.handleClose(); } : () => { this.props.onSave()}}
                                        >
                                        <p style={{marginTop: "3px"}}>
                                            Esta acción guardará de manera temporal las modificaciones realizadas.
                                            <br/>
                                            Será necesario públicar los cambios para que se apliquen en InteBot.
                                        </p>
                                        </ButtonConfirmDialog>
                                    }
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    { noCancelButton ? null
                                    : <CustomButton variant="contained" disabled>Cancelar</CustomButton>
                                    }
                                    <CustomButton variant="contained" disabled color="primary">Guardar</CustomButton>
                                </React.Fragment>
                            }
                        </DialogActions>
                    }

                </Dialog>
            </div>
        );
    }
}

FormModal.contextType = AppContext;