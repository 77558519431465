import React from 'react';

import { IconButton, TextField, Grid } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { ReactComponent as TrashCan } from '../../../Icons/trashcanIcon.svg';

const ListRowEntityType = (props) => {

    const { canonicalForm, list, handleListEntityForm, index, id, entityErrors, entityErrorHelper, addListItem, deleteListItem } = props

    return (
        <div className='list-entity-row'>
            <div className='list-entity-key'>
                <TextField
                    name="canonicalForm"
                    value={canonicalForm}
                    size='small'
                    type='small'
                    onChange={(event) => handleListEntityForm(event.target.name, event.target.value, null, index, id)}
                    fullWidth
                    variant='outlined'
                    error={entityErrors[id]}
                    helperText={entityErrorHelper[id]}
                />
            </div>
            <div className='list-entity-values'>
                <ChipInput
                    name="list"
                    fullWidth
                    value={list}
                    placeholder="Añade opción"
                    alwaysShowPlaceholder
                    variant="outlined"
                    size="small"
                    onAdd={(chip) => handleListEntityForm("list", chip, undefined, index)}
                    onDelete={(chip, position) => handleListEntityForm("list", chip, position, index, id)}
                    newChipKeyCodes={[9]}//Tab
                    fullWidthInput={true}
                    id={"chip-input-catalog"}
                />
            </div>
            <div className='list-entity-delete-row'>
                <IconButton color="secondary" size="small" onClick={() => deleteListItem(index)}>
                    <TrashCan width="20px" height="20px"/>
                </IconButton>
            </div>
        </div>
    );
}

export default ListRowEntityType;
