import { createTheme }  from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

const darkBlue = '#27315D';
const mediumBlue = '#373788';
const lightBlue = '#70C5D8';

const theme = createTheme({
    palette: {
        primary: {
            main: darkBlue,
            light: lightBlue,
            dark: mediumBlue,
        },
        secondary: {
            main: '#E42922',
            //light: '#dd3333',
            //dark: '#950000',
        },
        other: {
            black: '#000000',
            white: '#FFFFFF',
            gray: '#B2B2B2',
            green: '#44AB3F'
        }
    },
    typography: {
        fontFamily: 'Roboto',
        body1: {
            //fontFamily: 'Raleway',
            color: darkBlue,
        },
        allVariants: {
            color: darkBlue,
            //fontFamily: 'Roboto',
        },
    },
    overrides: {
        MuiInputBase: {
            input: {
                color: darkBlue,
                //fontFamily: 'Roboto',
            },
        },
        MuiTypography: {
            colorTextPrimary: {
                color: darkBlue,
                //fontFamily: 'Roboto',
            },
        },
        MuiChip: {
            label: {
                color: darkBlue,
                //fontFamily: 'Roboto',
            },
        },
        MuiPaper: {
            root: {
                color: darkBlue,
                //fontFamily: 'Roboto',
            },
        },
        MuiButton:{
            root:{
                textTransform: 'none',
            }
        },
        MuiFormHelperText:{
            root:{
                color:'#e48922'
            }
        }
    },
}, esES);

export default theme;