import React, { useContext, useState } from 'react';

//Mui components
import {
    TextField,
    InputAdornment,
    Button as MuiButton,
    IconButton,
    LinearProgress
  } from "@material-ui/core";
  import { withStyles } from '@material-ui/core/styles';

//Icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

//External libraries
import  { isStrongPassword }  from "validator"; //https://github.com/validatorjs/validator.js/
import Swal from 'sweetalert2' //https://sweetalert2.github.io

//Custom Portal Components
import AppContext from '../../Context/AppContext';
import IntebotCatalog from '../IntebotCatalog'
import { hashPassword } from './hashPassword';
import { authActions } from '../DataFunctions'


const errorHelperKeys = IntebotCatalog.errorHelper

const Button = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        //border: 0,
        color: 'white',
        height: 36,
        padding: '0 30px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&$disabled': {
            color: 'white',
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: 'none',
    },
}))(MuiButton);


const Changepassword = (props) => {

    //React Hooks
    const context = useContext(AppContext);
    const [state, setState] = useState({
        //Reset Password
        changePasswordForm: {
            password:'',
            newPassword: '',
            email:'',
        },
        newConfirmPassword:'',
        changePasswordErrors:{
            newPassword: false,
            newConfirmPassword: false
        },
        errorHelperChangePassword:{
            newPassword: errorHelperKeys.removeError,
            newConfirmPassword: errorHelperKeys.removeError,
        },
        isPasswordStrong: false, 
        passwordScore: 0
    });

    //Cambiar el campo de password -> Text
    const handleShowPassword = () => {
        const { showPassword } = state;
        setState({
            ...state,
            showPassword: showPassword ? false : true,
        })
    }

    const handleChangePasswordForm = (targetName, targetValue) => {
        const { changePasswordForm, newConfirmPassword, changePasswordErrors, errorHelperChangePassword, passwordScore, isPasswordStrong } = state;
        let changePasswordFormCopy = JSON.parse(JSON.stringify(changePasswordForm));
        let newConfirmPasswordCopy = JSON.parse(JSON.stringify(newConfirmPassword));
        let changePasswordErrorsCopy = JSON.parse(JSON.stringify(changePasswordErrors));
        let errorHelperChangePasswordCopy = JSON.parse(JSON.stringify(errorHelperChangePassword));
        let passwordScoreCopy = JSON.parse(JSON.stringify(passwordScore));
        let isPasswordStrongCopy = JSON.parse(JSON.stringify(isPasswordStrong))
        //Sin errores
        changePasswordErrorsCopy[targetName] = false;
        errorHelperChangePasswordCopy[targetName] = errorHelperKeys.removeError;
        //console.log(targetName, targetValue);        
        switch (targetName) {
            case 'password':
                if(targetValue.length > 15){
                    //NO guardamos la contraseña
                    break;
                }
                //Guardamos el campo
                changePasswordFormCopy[targetName] = targetValue;
                if(targetValue === '') {
                    changePasswordErrorsCopy[targetName] = true;
                    errorHelperChangePasswordCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }
                //Sin errores
                changePasswordErrorsCopy[targetName] = false;
                errorHelperChangePasswordCopy[targetName] = errorHelperKeys.removeError;
                break;
            case 'newPassword':
                //Validar si la contraseña cumple los requisitos
                if(targetValue.length > 15){
                    //NO guardamos la contraseña
                    break;
                }
                //La contraseña debe cumplir con los requerimientos minimos, Min 9 caracteres, Max 15, 1 mayuscula, 1 minuscula, 1 numero, 1 caracter especal
                isPasswordStrongCopy = isStrongPassword(targetValue, { minLength: 8, returnScore: false }) //Regresa true si la contraseña cumple los requerimientos
                passwordScoreCopy = isStrongPassword(targetValue, { minLength: 8, returnScore: true }) * 2 //Un score de 50 o más = %100 valido, entonces lo multiplicamos por 2 para tener la barra al 100%
                //Guardamos el campo
                changePasswordFormCopy[targetName] = targetValue;
                break;
            case 'newConfirmPassword':
                newConfirmPasswordCopy = targetValue;
                break;
            default:
                break;
        }
        setState({
            ...state,
            changePasswordForm: changePasswordFormCopy,
            newConfirmPassword: newConfirmPasswordCopy,
            changePasswordErrors: changePasswordErrorsCopy,
            errorHelperChangePassword: errorHelperChangePasswordCopy,
            passwordScore: passwordScoreCopy,
            isPasswordStrong: isPasswordStrongCopy

        })
    }

    const sendChangePassword = async (changePasswordForm) => {
        const { updateContextAttribute } = context;
        const { errorHelperChangePassword } = state;
        let errorHelperChangePasswordCopy = JSON.parse(JSON.stringify(errorHelperChangePassword))
        const { password, newPassword } = changePasswordForm;
        // let passwordCopy =  password
        // let newPasswordCopy = newPassword
        let remeberAuthCredentials = localStorage.getItem('remeberAuthCredentials')
        let email = remeberAuthCredentials ? localStorage.getItem('email') : sessionStorage.getItem('email');
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Cambiando contraseña...')
        changePasswordForm.email = email
        changePasswordForm.password = await hashPassword(password)
        changePasswordForm.newPassword = await hashPassword(newPassword)
        console.table(changePasswordForm)
        let token = remeberAuthCredentials ? localStorage.getItem('token') : sessionStorage.getItem('token');
        let res = await authActions(changePasswordForm, `ChangePassword&token=${token}`, "PasswordReset")
        //Regresamos las contraseñas sin el hash
        changePasswordForm.password = password
        changePasswordForm.newPassword = newPassword
        //console.log('res :>> ', res);
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
        switch (responseCode) {
            case 0:
                onClose();
                let tokenExpired = true
                logOutUser(tokenExpired);
                await Swal.fire({
                    icon: 'success',
                    title: 'Contraseña cambiada con éxito.',
                    text: 'Vuelve a iniciar sesión.',
                })
                break;
            case 1:
                errorHelperChangePasswordCopy['changePasswordForm'] = errorHelperKeys.accountNotExists;
                break;
            case 3: 
                //Cambiarlo al error helper
                errorHelperChangePasswordCopy['changePasswordForm'] = errorHelperKeys.currentPasswordNotValid;
                break;
            default:
                //Cambiarlo al error helper
                errorHelperChangePasswordCopy['changePasswordForm'] = errorHelperKeys.changePasswordError;
                break;
        }
        setState({
            ...state,
            errorHelperChangePassword: errorHelperChangePasswordCopy,
            changePasswordForm: {
                password:'',
                newPassword: '',
                email:'',
            },
            newConfirmPassword:'',
            isPasswordStrong: false, 
            passwordScore: 0
        })
    }



    const searchErrorsChangePassword = () => {
        const { changePasswordForm, newConfirmPassword, changePasswordErrors, errorHelperChangePassword, isPasswordStrong } = state;
        let changePasswordFormCopy = JSON.parse(JSON.stringify(changePasswordForm));
        let newConfirmPasswordCopy = JSON.parse(JSON.stringify(newConfirmPassword));
        let changePasswordErrorsCopy = JSON.parse(JSON.stringify(changePasswordErrors));
        let errorHelperChangePasswordCopy = JSON.parse(JSON.stringify(errorHelperChangePassword));

        for (const key in changePasswordFormCopy) {
            const formValue = changePasswordFormCopy[key];
            switch (key) {
                case 'password':
                    if(formValue.length < 8){
                        changePasswordErrorsCopy[key] = true;
                        errorHelperChangePasswordCopy[key] = errorHelperKeys.notPassword;
                        break;
                    }
                    break;
                case 'newPassword':
                    if(!isPasswordStrong){
                        changePasswordErrorsCopy[key] = true;
                        errorHelperChangePasswordCopy[key] = errorHelperKeys.notStrongPassword;
                    }
                    //Validar que la contraseña cumpla los requisitos
                    break;
            
                default:
                    break;
            }
        }
        //Las contraseñas no coinciden
        if(changePasswordForm.newPassword !== newConfirmPasswordCopy){
            changePasswordErrorsCopy['newConfirmPassword'] = true;
            errorHelperChangePasswordCopy['newConfirmPassword'] = errorHelperKeys.passwordNotMatch;
        }
         //La contraseña actual es identica a la nueva
         if(changePasswordForm.newPassword === changePasswordForm.password){
            changePasswordErrorsCopy['newPassword'] = true;
            errorHelperChangePasswordCopy['newPassword'] = errorHelperKeys.samePassword;
        }

        //iteramos el objeto de errores
        let changePasswordFormHasErrors = false;
        for (const key in changePasswordErrorsCopy) {
            const errorValue = changePasswordErrorsCopy[key];
            //Si encontramos 1 error, ponemos la bandera en true y evitamos que se mande el form
            if(errorValue){
                changePasswordFormHasErrors = true;
                break; 
            }
        }

        //si no hay errores, mandamos a llamar a crear la cuenta
        if(!changePasswordFormHasErrors) {
            sendChangePassword(changePasswordForm)
        }

        setState({
            ...state, 
            errorHelperChangePassword: errorHelperChangePasswordCopy,
            changePasswordErrors: errorHelperChangePasswordCopy
        })

    }
    const { logOutUser } = context;
    const { onClose } = props
    const { showPassword, changePasswordForm, newConfirmPassword, changePasswordErrors, errorHelperChangePassword, isPasswordStrong, passwordScore } = state;
    return (
        <div className='LoginForm'>
            <header>
               <h2>Cambio de contraseña</h2>
            </header>
             {/* Errores del Change Password */}
             <div style={{display: 'flex',justifyContent:'center'}} >
                <span style={{fontSize: '0.75rem',color:'red'}} >{errorHelperChangePassword['changePasswordForm']}</span> 
            </div>
            <div className='AuthInputsContainer'>
               <TextField
                   name="password"
                   value={changePasswordForm.password}
                   variant="outlined"
                   size="small"
                   onChange={(e) => handleChangePasswordForm(e.target.name, e.target.value)}
                   label="Contraseña actual"
                   type={showPassword ? "text" : "password"}
                   error={changePasswordErrors.password}
                   helperText={errorHelperChangePassword.password}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                                   <IconButton onClick={() => handleShowPassword()}>
                                       { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                   </IconButton>
                           </InputAdornment>
                       ) 
                   }}
               />
               <TextField
                   name="newPassword"
                   value={changePasswordForm.newPassword}
                   variant="outlined"
                   size="small"
                   onChange={(e) => handleChangePasswordForm(e.target.name, e.target.value)}
                   label="Nueva contraseña"
                   type={showPassword ? "text" : "password"}
                   error={changePasswordErrors.newPassword}
                   helperText={errorHelperChangePassword.newPassword}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                                   <IconButton onClick={() => handleShowPassword()}>
                                       { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                   </IconButton>
                           </InputAdornment>
                       ) 
                   }}
               />
               {changePasswordErrors["newPassword"] === false && <span style={{fontSize: '0.75rem'}} >{errorHelperKeys.notStrongPassword}</span> }
               <TextField
                   name="newConfirmPassword"
                   value={newConfirmPassword}
                   variant="outlined"
                   size="small"
                   onChange={(e) => handleChangePasswordForm(e.target.name, e.target.value)}
                   label="Confirmar nueva contraseña"
                   type={showPassword ? "text" : "password"}
                   error={changePasswordErrors.newConfirmPassword}
                   helperText={errorHelperChangePassword.newConfirmPassword}
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                                   <IconButton onClick={() => handleShowPassword()}>
                                       { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                   </IconButton>
                           </InputAdornment>
                       ) 
                   }}
               />
               <div>
                   <span>Seguridad de la contraseña</span>
                   <LinearProgress 
                        classes={{
                            bar: isPasswordStrong ? 
                                    'linearProgressColorGreen'
                                : passwordScore > 50 ? 
                                    'linearProgressColorOrange' 
                                : 'linearProgressColorRed'
                        }} 
                        variant="determinate" 
                        value={passwordScore < 100 ? passwordScore : 100} 
                    />
               </div>
               <div className="authButtonContainer" >
                   <Button onClick={() => searchErrorsChangePassword()} >Cambiar contraseña</Button>
               </div>
            </div>
         </div>
    );
}

export default Changepassword;
