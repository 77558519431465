import React, { useState, useEffect, useRef, createRef, useMemo, memo } from "react";
import Swal from "sweetalert2";

//MUI components
// import {
//     RadioGroup,
//     FormControlLabel,
//     Radio,
// } from '@material-ui/core/';

function IntentExample(props) {
    const {
        exampleObj,
        selectedWordsIds = [],
        handleSelection,
        exampleIndex,
        selectedExampleIndex,
        handleOpenClickEntityMenu,
        exampleList,
        noSelection,
        findEntityInfo,
        createIntentErrorHelper,
    } = props;

    const [tokenizedTextLabels, setTokenizedTextLabels] = useState([]); //exampleObj is an object with the example text and the intent label

    useEffect(() => {
        if (exampleObj) {
            createLabelsObj();
        }
    }, [exampleObj, exampleList]);

    const createLabelsObj = () => {
        try {
            let tokenizedTextLabels = [];
            exampleObj.tokenizedText.forEach((word, index) => {
                tokenizedTextLabels.push({
                    word: word,
                    entityName: [],
                    id: index,
                    exampleCount: exampleObj.examples.length,
                });
            });
            exampleObj.examples.forEach((example) => {
                const { startTokenIndex, endTokenIndex, entityName, entityId, uniqid, type } =
                    example;
                let entityObj = findEntityInfo(entityName);
                const { level = null } = entityObj;
                const entityLength = endTokenIndex - startTokenIndex;
                for (let index = startTokenIndex; index <= endTokenIndex; index++) {
                    const { word } = tokenizedTextLabels[index];
                    tokenizedTextLabels[index].entityName.push({
                        entityName: entityName,
                        firstEntity: startTokenIndex === index,
                        lastEntity: endTokenIndex === index,
                        entityId: entityId,
                        level: level,
                        uniqid: uniqid,
                        entityLength: entityLength,
                        type: type,
                        word: word,
                    });
                }
            });

            //ordenamos las etiquetas dependiendo el nivel de jerarquia
            tokenizedTextLabels.map((label) => {
                const { entityName } = label;
                //sort entityName by entityLength
                entityName.sort((a, b) => {
                    return b.entityLength - a.entityLength;
                });
            });
            setTokenizedTextLabels(tokenizedTextLabels);
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Ocurrió un error al agregar la frase.",
                text: "Intenta nuevamente con otra frase, si el problema persiste contactar al soporte.",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    };

    const decideLineColor = (level, type, noDashedBg) => {
        if (type === "label" || noDashedBg) {
            switch (level) {
                case 0:
                    //Azul cielo
                    return "blue-tag-color";
                case 1:
                    //Rojo
                    return "red-tag-color";
                case 2:
                    //Verde
                    return "green-tag-color";
                case 3:
                    //Rosado
                    return "pink-tag-color";
                case 4:
                    //Morado
                    return "purple-tag-color";
                default:
                    //Azul cielo
                    return "blue-tag-color";
            }
        }

        if (type === "suggest") {
            switch (level) {
                case 0:
                    //Azul cielo
                    return "dashed-blue-tag-color";
                case 1:
                    //Rojo
                    return "dashed-red-tag-color";
                case 2:
                    //Verde
                    return "dashed-green-tag-color";
                case 3:
                    //Rosado
                    return "dashed-pink-tag-color";
                case 4:
                    //Morado
                    return "dashed-purple-tag-color";
                default:
                    //Azul cielo
                    return "dashed-blue-tag-color";
            }
        }
    };

    const decideExampleHeight = (exampleCount) => {
        switch (exampleCount) {
            case 0:
                return "";
            case 1:
            case 2:
            case 3:
                return "example-label-container-md";
            case 4:
            case 5:
            case 6:
                return "example-label-container-lg";
            default:
                //Mayor a 6
                return "example-label-container-xl";
        }
    };

    return (
        <div>
            <div
                onPointerUp={(event) => {
                    if (noSelection) return;
                    handleSelection(exampleIndex, event);
                }}
                className="intent-table-example-component"
            >
                {tokenizedTextLabels.map((example, index) => {
                    return (
                        <div className={decideExampleHeight(example.exampleCount)} key={index}>
                            <span
                                id={index}
                                className={
                                    selectedWordsIds.includes(index) &&
                                    selectedExampleIndex === exampleIndex
                                        ? "selected-intent-word-example"
                                        : `intent-word-example`
                                }
                            >
                                {`${example.word}  `}
                            </span>
                            {example.entityName.map((entity, index) => {
                                const { firstEntity, lastEntity, entityName, uniqid, level, type } =
                                    entity;
                                return (
                                    <div
                                        style={{ userSelect: "none" }}
                                        className={
                                            index > 0
                                                ? "entity-label-container-second"
                                                : "entity-label-container"
                                        }
                                        key={index}
                                    >
                                        <div className="line-container">
                                            {firstEntity && (
                                                <div
                                                    className={`square ${decideLineColor(
                                                        level,
                                                        type,
                                                        true
                                                    )}`}
                                                ></div>
                                            )}
                                            <div
                                                className={`straight-line ${decideLineColor(
                                                    level,
                                                    type
                                                )}`}
                                            ></div>
                                            {lastEntity && (
                                                <div
                                                    className={`square ${decideLineColor(
                                                        level,
                                                        type,
                                                        true
                                                    )}`}
                                                ></div>
                                            )}
                                        </div>
                                        {firstEntity && (
                                            <div
                                                onClick={
                                                    noSelection
                                                        ? null
                                                        : (event) =>
                                                              handleOpenClickEntityMenu(
                                                                  event,
                                                                  exampleIndex,
                                                                  uniqid,
                                                                  type,
                                                                  entityName
                                                              )
                                                }
                                                id="entityName"
                                                className={`entity-example-label ${decideLineColor(
                                                    level,
                                                    type,
                                                    true
                                                )}`}
                                            >
                                                <span
                                                    title={
                                                        type === "label"
                                                            ? `Etiqueta: ${entityName}`
                                                            : `Sugerencia: ${entityName}`
                                                    }
                                                >
                                                    {type === "label"
                                                        ? `[${entityName}]`
                                                        : `:${entityName}:`}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            {createIntentErrorHelper["intentExample"] && exampleIndex === 0 && (
                <span
                    style={{
                        color: "#e48922",
                        margin: "0 14px",
                        fontSize: "0.75rem",
                        display: "block",
                    }}
                >
                    {createIntentErrorHelper["intentExample"]}
                </span>
            )}
        </div>
    );
}

const propAreEqual = (prevProps, nextProps) => {
    return (
        prevProps.selectedExampleIndex === nextProps.selectedExampleIndex &&
        prevProps.exampleObj === nextProps.exampleObj &&
        prevProps.selectedWordsIds === nextProps.selectedWordsIds
    );
};

export default memo(IntentExample, propAreEqual);

//export default IntentExample;
