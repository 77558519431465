import React from "react";

//MUi components
import { Grid, FormControlLabel, Switch, Tooltip } from "@material-ui/core";

//Icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

//Custom Portal Components
import SimpleTable from "../../common/Tables/SimpleTable";
import AddBodyRequestModal from "./Modals/AddBodyRequest";

export default function BodyRequestTable(props) {
    const {
        currentProcess,
        bodyRequestFields,
        addBodyRequest,
        editBodyRequest,
        removeBodyRequest,
        classes,
        StyledMentionInput,
        handleChange,
    } = props;

    return (
        <section>
            <Grid container alignItems="center">
                <FormControlLabel
                    control={
                        <Switch
                            checked={currentProcess["enableCustomBodyRequest"]}
                            onChange={(e) =>
                                handleChange(
                                    e.target.name,
                                    e.target.checked,
                                    "switchButton",
                                    undefined,
                                    undefined
                                )
                            }
                            name="enableCustomBodyRequest"
                            color="primary"
                        />
                    }
                    label="Personalizado"
                />
                <Tooltip
                    title={`Ingresa un bodyRequest en formato JSON válido por ejemplo: {"age": "{{userAge}}","city": "{{userCity}}","name": "{{USERNAME}}","bot": "Intebot"}`}
                >
                    <HelpOutlineIcon color="primary" fontSize="small" />
                </Tooltip>
            </Grid>
            {!currentProcess["enableCustomBodyRequest"] && (
                <Grid container>
                    <Grid item className={classes.tableSize}>
                        <SimpleTable
                            headers={["Nombre del campo", "Valor del campo"]}
                            values={bodyRequestFields}
                            type="body-response-table"
                            functionDelete={removeBodyRequest}
                            functionEdit={editBodyRequest}
                            currentProcess={currentProcess}
                            classes={classes}
                            updateBody={editBodyRequest}
                            StyledMentionInput={StyledMentionInput}
                        />
                    </Grid>

                    <Grid
                        container
                        justifyContent="flex-end"
                        className={classes.consistentInputSize}
                    >
                        <AddBodyRequestModal
                            isNewBodyRequest={true}
                            currentProcess={currentProcess}
                            classes={classes}
                            addBody={addBodyRequest}
                            StyledMentionInput={StyledMentionInput}
                            updateBody={editBodyRequest}
                            bodyRequestFields={bodyRequestFields}
                        />
                    </Grid>
                </Grid>
            )}
        </section>
    );
}
