import React, { Component } from "react";

//MUI components
import { CircularProgress, Button, IconButton, Grid } from "@material-ui/core";

//Custom Portal Components
import SearchBox from "../components/common/SearchBox";
import TemplateContent from "../components/CampaingPageComponents/TemplateContent";
import AppContext from "../Context/AppContext";
import { whatsAppCampaignActions, authActions } from "../components/DataFunctions";
import CreateNewTemplateForm from "../components/CampaingPageComponents/CreateNewTemplateForm";
import CustomButton from "../components/common/CustomButton";
import Uploadcampaingsphonenumbers from "../components/CampaingPageComponents/uploadCampaingsPhoneNumbers";
import CustomModalActions from "../components/common/Modals/CustomModalActions";
import RefreshButton from "../components/common/RefreshButton";

//CSS sheets
import "./StyledPages/sendCampaignsPage.css";

//Icons
import AgregarIcono from "../Icons/AgregarIcono";
import RefreshIcon from "@material-ui/icons/Refresh";

//External libraries
import Swal from "sweetalert2"; //https://sweetalert2.github.io
import axios from "axios";

class Sendcampaignspage extends Component {
    state = {
        templateList: [],
        Loading: false,
        query: "",
        errorInFetch: false,
        openNewTemplateForm: false,
        openUploadPhoneNumbersForm: false,
        selectedTemplate: "",
        intebotContacts: [],
        dialog: {
            openDialogActions: false,
            dialogMessage: "",
            dialogAction: "", //loadingModal, loadingSucceeded, LoadingFailed, askCloseConfirmation, askDeletionConfirmation ...
            dialogFunction: "",
        },
        emptyTemplates: false,
        multiMediaURLArray: [],
    };

    componentDidMount() {
        const { getSendCampaingsInfo } = this;
        getSendCampaingsInfo();
    }

    componentWillUnmount() {
        this.clearState();
        //delete blob URLS
        this.state.multiMediaURLArray.forEach((blobUrl) => URL.revokeObjectURL(blobUrl));
    }

    getSendCampaingsInfo = async () => {
        const { getWhatsAppTemplates, getMessageRateLimitFromDB, getIntebotContacts } = this;
        await getMessageRateLimitFromDB();
        await getIntebotContacts();
        await getWhatsAppTemplates();
    };

    clearState = () => {
        this.setState({
            templateList: [],
            query: "",
            errorInFetch: false,
            intebotContacts: [],
            emptyTemplates: false,
        });
    };

    getIntebotContacts = async () => {
        const { getStorageData } = this.context;
        let storageData = getStorageData(["serviceKey"]);
        this.setState({
            Loading: true,
        });
        let data = {
            serviceKey: storageData.serviceKey,
        };
        let res = await authActions(data, "GetContactList", "SubscriptionOperations");
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99; //Si no hay un código de respuesta lo mandamos al default
        switch (responseCode) {
            case 0:
                let intebotContacts = res.data.response.data.map((contact, index) => {
                    return { ...contact, id: index };
                });
                this.setState({
                    intebotContacts: intebotContacts,
                    Loading: false,
                });
                break;
            case 1:
                //No hay contactos disponibles
                this.setState({
                    intebotContacts: [],
                    Loading: false,
                });
                break;
            case 20:
                Swal.fire({
                    icon: "info",
                    title: "El servicio de envio de campañas está en mantenimeinto.",
                    text: "Espere unas horas e intente nuevamente.",
                });
                this.setState({
                    Loading: false,
                    errorInFetch: true,
                });
                break;
            default:
                this.setState({
                    Loading: false,
                    errorInFetch: true,
                });
                break;
        }
    };

    getMessageRateLimitFromDB = async () => {
        const { storeRateLimit, getStorageData } = this.context;
        let serviceKey = getStorageData(["serviceKey"]).serviceKey;
        let getMessageLimitData = {
            accessKeyMessageService: serviceKey,
        };
        let res = await authActions(getMessageLimitData, "GetMessageRateLimit", "LogIn");
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99; //Si no hay un código de respuesta lo mandamos al default
        switch (responseCode) {
            case 0:
                const { rateLimit } = res.data.response;
                storeRateLimit(rateLimit);
                break;
            case 20:
                Swal.fire({
                    icon: "info",
                    title: "El servicio de envio de campañas está en mantenimeinto.",
                    text: "Espere unas horas e intente nuevamente.",
                });
                this.setState({
                    Loading: false,
                    errorInFetch: true,
                });
                break;
            default:
                this.setState({
                    Loading: false,
                    errorInFetch: true,
                });
                break;
        }
    };

    lazyLoadMultimedia = async (multimediaUrl) => {
        if (!multimediaUrl) return { URL: "", type: "" };
        try {
            let res = await axios({
                method: "GET",
                url: multimediaUrl,
                responseType: "blob", // or 'blob'
            });
            let multiMediaBlob = res.data;
            let multiMediaURLObject = URL.createObjectURL(multiMediaBlob);
            return { URL: multiMediaURLObject, type: multiMediaBlob.type };
        } catch (error) {
            console.log("error", error);
            return { URL: "", type: "" };
        }
    };

    getWhatsAppTemplates = async () => {
        this.setState({
            Loading: true,
        });
        var res = await whatsAppCampaignActions(null, "getTemplateList");
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99; //Si no hay un código de respuesta lo mandamos al default
        switch (responseCode) {
            case 5:
                let multiMediaURLArray = [];
                let templateList = res.data.response.templateList
                    .filter((template) => {
                        //quitamos las que tienen el estaus de pendiente de eliminar
                        if (template.templateStatus === "PENDING_DELETION") return false;
                        else return true;
                        //creamos una lista filtrada
                    })
                    .map((template) => template);

                for (let i = 0; i < templateList.length; i++) {
                    let multimediaUrl = templateList[i].multimediaUrl;
                    let multiMediaURLObject = await this.lazyLoadMultimedia(multimediaUrl);
                    multiMediaURLArray.push(multiMediaURLObject);
                }

                this.setState({
                    templateList: templateList,
                    multiMediaURLArray: multiMediaURLArray,
                    Loading: false,
                });
                break;
            case 6:
                //No hay templates disponibles
                this.setState({
                    templateList: [],
                    Loading: false,
                    emptyTemplates: true,
                });
                break;
            case 20:
                Swal.fire({
                    icon: "info",
                    title: "El servicio de envio de campañas está en mantenimeinto.",
                    text: "Espere unas horas e intente nuevamente.",
                });
                this.setState({
                    Loading: false,
                    errorInFetch: true,
                });
                break;
            default:
                this.setState({
                    Loading: false,
                    errorInFetch: true,
                });
                break;
        }
    };

    searchTemplate = (query) => {
        this.setState({
            query: query,
        });
    };

    handleSelectTemplate = (targetValue) => {
        this.setState({
            selectedTemplate: targetValue,
        });
    };

    handleOpenNewTemplate = (openDialog) => {
        this.setState({
            openNewTemplateForm: openDialog,
        });
    };

    handleOpenSendCampaing = (openDialog) => {
        this.setState({
            openUploadPhoneNumbersForm: openDialog,
        });
    };

    handleDialogAction = (openDialog, message, action, dialogFunction) => {
        this.setState({
            dialog: {
                openDialogActions: openDialog,
                dialogMessage: message,
                dialogAction: action,
                dialogFunction: dialogFunction,
            },
        });
    };

    refreshTemplateList = async (noClearState) => {
        const { getSendCampaingsInfo, clearState } = this;
        if (!noClearState) {
            clearState();
        }
        await getSendCampaingsInfo();
    };

    render() {
        const {
            searchTemplate,
            handleOpenNewTemplate,
            handleDialogAction,
            refreshTemplateList,
            handleSelectTemplate,
            handleOpenSendCampaing,
        } = this;
        const {
            Loading,
            templateList,
            query,
            openNewTemplateForm,
            selectedTemplate,
            openUploadPhoneNumbersForm,
            dialog,
            errorInFetch,
            intebotContacts,
            emptyTemplates,
            multiMediaURLArray,
        } = this.state;
        const { readOnly } = this.context;
        let filteredList = templateList.filter((template) => template.templateName.includes(query));
        let allTemplatesNames = templateList.map((template) => template.templateName);
        return (
            <div>
                {/* Modales  */}
                <CreateNewTemplateForm
                    allTemplatesNames={allTemplatesNames}
                    handleOpenNewTemplate={handleOpenNewTemplate}
                    openNewTemplateForm={openNewTemplateForm}
                    refreshTemplateList={refreshTemplateList}
                    handleDialogAction={handleDialogAction}
                />
                <Uploadcampaingsphonenumbers
                    handleOpenSendCampaing={handleOpenSendCampaing}
                    selectedTemplate={selectedTemplate}
                    openUploadPhoneNumbersForm={openUploadPhoneNumbersForm}
                    handleDialogAction={handleDialogAction}
                    refreshTemplateList={refreshTemplateList}
                    intebotContacts={intebotContacts}
                    templateList={templateList}
                    multiMediaURLArray={multiMediaURLArray}
                />
                <CustomModalActions dialog={dialog} handleDialogAction={handleDialogAction} />
                <main>
                    <header>
                        <h1>Templates</h1>
                    </header>
                    <Grid container className="search-box-container">
                        <SearchBox searchFunction={searchTemplate} searchQuery={query} />
                        <Grid item>
                            <RefreshButton refreshFunction={refreshTemplateList} />
                            <CustomButton
                                disabled={!selectedTemplate || Loading || readOnly}
                                onClick={async () => {
                                    handleOpenSendCampaing(true);
                                    handleDialogAction(
                                        true,
                                        "Conectando con el servicio de mensajeria...",
                                        "loadingModal"
                                    );
                                    await refreshTemplateList(true);
                                    handleDialogAction(false);
                                }}
                                variant="contained"
                            >
                                Enviar campaña
                            </CustomButton>
                        </Grid>
                    </Grid>
                    <div>
                        {Loading ? (
                            <div className="center-loading-icon">
                                <CircularProgress />
                            </div>
                        ) : (
                            !errorInFetch &&
                            filteredList.map((template, index) => (
                                <div>
                                    <TemplateContent
                                        index={index}
                                        multiMediaURLArray={multiMediaURLArray}
                                        template={template}
                                        selectedTemplate={selectedTemplate}
                                        handleSelectTemplate={handleSelectTemplate}
                                        handleDialogAction={handleDialogAction}
                                        refreshTemplateList={refreshTemplateList}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                    {errorInFetch && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <span>Hubo un error, intenta más tarde.</span>
                            <IconButton onClick={() => refreshTemplateList()}>
                                <RefreshIcon color="primary" width="20px" height="20px" />
                            </IconButton>
                        </div>
                    )}
                    {emptyTemplates && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <span>No hay templates disponibles.</span>
                            <IconButton onClick={() => refreshTemplateList()}>
                                <RefreshIcon color="primary" width="20px" height="20px" />
                            </IconButton>
                        </div>
                    )}
                    <footer className="send-campaings-footer">
                        <Button
                            disabled={Loading || errorInFetch || readOnly}
                            style={{ textTransform: "none" }}
                            startIcon={<AgregarIcono width="15px" height="15px" />}
                            size="small"
                            onClick={() => handleOpenNewTemplate(true)}
                        >
                            Agregar nuevo template
                        </Button>
                    </footer>
                </main>
            </div>
        );
    }
}

Sendcampaignspage.contextType = AppContext;

export default Sendcampaignspage;
