import React from "react";

//MUi components
import { Grid } from "@material-ui/core";

//Custom Portal Components
import SimpleTable from "../../common/Tables/SimpleTable";
import AddSubmenuOption from "./Modals/AddSubmenuOption";

export default function SubmenuTable(props) {
  const {
    menuOptions,
    addMenuOption,
    editMenuOption,
    deleteMenuOption,
    classes,
  } = props;

  return (
    <section className="">
      <Grid container>
        <Grid item className={classes.tableSize}>
          <SimpleTable
            headers={["Submenú"]}
            values={menuOptions}
            type="submenu-table"
            functionDelete={deleteMenuOption}
            functionEdit={editMenuOption}
            classes={classes}
          />
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          className={classes.consistentInputSize}
        >
          <AddSubmenuOption
            menuOptions={menuOptions}
            addMenuOption={addMenuOption}
            editMenuOption={editMenuOption}
            classes={classes}
            isAddSubmenuOption={true}
          />
        </Grid>
      </Grid>
    </section>
  );
}
