import React from 'react'
//MUI components
import { Alert as MuiAlert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function CustomAlert(props) {
    const { open, severity, message } = props;

    return(
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                autoHideDuration={3000}
                onClose={() => props.handleCloseAlert()}
            >
                <Alert onClose={() => props.handleCloseAlert()} severity={severity} >
                    {message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}