import React, { useContext, useState } from "react";
import {
    Grid,
    Box,
    IconButton,
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AppContext from "../Context/AppContext";

import DownloadDashboard from "../components/DashboardComponents/DownloadDashboard";

import Dashboard from "../components/DashboardComponents/Dashboard";
import CustomButton from "../components/common/CustomButton";

import { actionsPowerBi } from "../components/DataFunctions";

import "../components/DashboardComponents/styles/dashboardPage.css";

//Icons
import CloseIcon from "@material-ui/icons/Close";

import { powerBiFilters } from "../components/IntebotCatalog";

import Swal from "sweetalert2";

function TabPanel(props) {
    const { children, value, index } = props;
    return <div role="tabpanel">{value === index && <Box>{children}</Box>}</div>;
}
const Dialog = withStyles((theme) => ({
    root: {
        marginTop: "56px",
        [theme.breakpoints.up("md")]: {
            marginTop: "64px",
        },
    },
}))(MuiDialog);

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
    },
}))(MuiDialogContent);

export default function DashboardPage() {
    const { getStorageData, messagesTemp, updateContextAttribute } = useContext(AppContext);
    let storageData = getStorageData(["powerBIReport", "subscriptionChannel"]);

    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [filtersBody, setFiltersBody] = useState({
        startDate: getTodayDate(),
        endDate: getTodayDate(),
        section: powerBiFilters.find((filter) => filter.name === "section").defaultValue,
        statusFAQ: ["correcta"],
        statusProcess: ["finalizado"],
        templateName: [],
        channel: [Object.keys(storageData.subscriptionChannel)[0]],
        minScore: 0,
        maxScore: messagesTemp.QNA_MAX_SCORE,
    });

    const requestDashboardDownload = async () => {
        //Ask Confirmation

        let askConfirm = await Swal.fire({
            title: "¿Estás seguro de que deseas descargar el reporte?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el guardado de cambios
        if (askConfirm.isDenied || askConfirm.isDismissed) {
            return;
        }

        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Generando reporte...");

        const res = await actionsPowerBi(filtersBody, "");

        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");

        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error

        switch (responseCode) {
            case 0:
                //convert JSON to CSV
                const jsonData = res.data.data;
                // Function to convert JSON array to CSV string
                const sanitizeValue = (value) => {
                    if (typeof value === "undefined" || value === null) {
                        return "";
                    }
                    if (typeof value === "string") {
                        return value
                            .replace(/,/g, "") // Remove commas
                            .replace(/\r?\n|\r/g, " ") // Replace line breaks with a space
                            .replace(/\s\s+/g, " ") // Replace multiple spaces with a single space
                            .replace(/\t/g, " "); // Replace tabs with a space
                    }
                    return value;
                };

                const jsonToCSV = (jsonArray) => {
                    if (jsonArray.length === 0) return "";

                    const keys = Object.keys(jsonArray[0]);
                    const csvRows = [];

                    // Add header row
                    csvRows.push(keys.join(","));

                    // Add data rows
                    for (const item of jsonArray) {
                        const values = keys.map((key) => `"${sanitizeValue(item[key])}"`);
                        csvRows.push(values.join(","));
                    }

                    return csvRows.join("\n");
                };

                const csvData = jsonToCSV(jsonData);
                const bom = "\uFEFF"; // UTF-8 BOM
                const blob = new Blob([bom + csvData], { type: "text/csv;charset=utf-8;" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                let selectedFilters = Object.values(filtersBody)
                    .filter((value) => value.length > 0)
                    .join(", ")
                    .substring(0, 80);
                a.href = url;
                a.download = `REPORTE INTEBOT [ ${selectedFilters} ] .csv`;
                a.click();
                window.URL.revokeObjectURL(url);
                break;

            default:
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al descargar el reporte, por favor intenta de nuevo con diferentes filtros.",
                });
                break;
        }
    };

    function getTodayDate() {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const dd = String(today.getDate()).padStart(2, "0");
        const formattedDate = `${yyyy}-${mm}-${dd}`;
        return formattedDate;
    }

    return (
        <React.Fragment>
            <Dialog open={openDownloadDialog} fullScreen>
                <DialogTitle disableTypography>
                    <h2>Descarga del reporte</h2>
                    <IconButton
                        onClick={() => {
                            setOpenDownloadDialog(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ marginBottom: "70px" }}>
                    <DownloadDashboard
                        filtersBody={filtersBody}
                        setFiltersBody={setFiltersBody}
                        getTodayDate={getTodayDate}
                    />
                </DialogContent>
                <DialogActions>
                    <div className="downloadContainerFooter">
                        <Grid container alignContent="flex-end">
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <CustomButton
                                            disabled={false}
                                            onClick={() => {
                                                requestDashboardDownload();
                                            }}
                                            variant="contained"
                                        >
                                            Descargar reporte
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </DialogActions>
            </Dialog>
            <Grid
                container
                justifyContent="flex-end"
                style={{
                    margin: "10px 0",
                }}
            >
                <CustomButton
                    onClick={() => {
                        setOpenDownloadDialog(true);
                    }}
                    variant="contained"
                >
                    Descargar reporte
                </CustomButton>
            </Grid>
            <Dashboard powerBIReport={storageData.powerBIReport} />
        </React.Fragment>
    );
}
