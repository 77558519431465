import React from "react";
//MUI components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Button as MuiButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

//Custom portal components
import CustomButton from "../CustomButton";

const Button = withStyles({
  root: {
    fontWeight: "400",
  },
  label: {
    textTransform: "none",
    fontSize: "1rem",
  },
})(MuiButton);

export default class BackToSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { askConfirm, onClose, titleButton } = this.props;
    return (
      <div>
        {askConfirm ? (
          <Tooltip title="Regresar">
            <Button
              color="primary"
              onClick={() => {
                this.handleOpen();
              }}
            >
              {titleButton}
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Regresar">
            <Button
              color="primary"
              onClick={() => {
                onClose();
              }}
            >
              {titleButton}
            </Button>
          </Tooltip>
        )}
        <Dialog
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Estás seguro que deseas salir?
          </DialogTitle>
          <DialogContent>Los cambios realizados se perderán.</DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => this.handleClose()}
              variant="contained"
            >
              No
            </CustomButton>
            <CustomButton
              onClick={() => {
                this.handleClose();
                onClose();
              }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Sí
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
