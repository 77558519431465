import React, { Fragment, useState, useContext, useEffect } from "react";

// Material UI inputs
import { Grid, Breadcrumbs, Typography, TextField, MenuItem, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//External Libraries
import { MentionsInput, Mention } from "react-mentions"; // https://github.com/signavio/react-mentions

//Custom Portal components
import FormModal from "../../../FormModalComponents/FormModal";
import AgregarIcono from "../../../../Icons/AgregarIcono";
import EditarIcono from "../../../../Icons/EditarIcono";
import IntebotCatalog from "../../../IntebotCatalog";
import AppContext from "../../../../Context/AppContext";

// styles
import "../Styles/Modals.css";

function AddBodyRequest(props) {
    let {
        isNewBodyRequest,
        currentProcess,
        addBody,
        updateBody,
        indexTable,
        bodyRequestFields,
        StyledMentionInput,
    } = props;

    const context = useContext(AppContext);
    const { globalValues } = context;

    const errorHelperKeys = IntebotCatalog.errorHelper;

    const formErrorsInit = {
        formHasErrors: true,
        formResponseErrors: {
            bodyRequestKey: true,
            bodyRequestValue: true,
            bodyOptionValue: false,
        },
        errorHelperForm: {
            bodyRequestKey: errorHelperKeys.emptyField,
            bodyRequestValue: errorHelperKeys.emptyField,
            bodyOptionValue: errorHelperKeys.removeError,
        },
    };

    const [bodyRequest, setBodyRequest] = useState({
        bodyRequestKey: "",
        bodyRequestValue: "",
        bodyOptionValue: "",
    });

    const [formValues, setFormValues] = useState({
        options: [],
        formHasChanges: false,
        addNewBodyValue: false,
    });

    const [formErrors, setFormErrors] = useState(formErrorsInit);

    const [previousValue, setPreviousValue] = useState({});

    const [isSelectItemsSet, setIsSelectItemsSet] = useState(false);

    const [isEditableDataSet, setIsEditableDataSet] = useState(false);

    /**
     * @description obtenemos los items para el select de valor del campo
     */
    function getSelectItems() {
        const stepsDescription = currentProcess["steps"].map((step) => {
            return {
                id: step.description,
                display: `${step.description}`,
                list: "Local",
            };
        });

        //creamos una lista para el input de "mentions" con las descripciones de los pasos + los valores globales
        let autoCompleteList = globalValues.concat(stepsDescription);

        //filtramos los datos para que no haya duplicados
        autoCompleteList = autoCompleteList.filter((item, index) => {
            //comparamos ambas listas y devolvemos la posición del id
            let position = autoCompleteList.findIndex((element) => element.id === item.id);
            //si hay un valor duplicado, este tendrá otro index diferente al que está siendo iterado y se quita
            return position === index;
        });

        setFormValues({ ...formValues, options: autoCompleteList });
        if (!isNewBodyRequest) {
            setIsSelectItemsSet(true);
        }
    }

    useEffect(() => {
        if (!isNewBodyRequest && isEditableDataSet) {
            // cuando finalizó de actualizar, revisar si hay errores
            searchFormErrors();
        }
    }, [bodyRequest.bodyRequestKey, isEditableDataSet, isNewBodyRequest]);

    useEffect(() => {
        if (isSelectItemsSet && globalValues && !isNewBodyRequest) {
            // llamamos funcion para validar los valores y los asignamos
            isCustomValue(bodyRequestFields[indexTable].key, bodyRequestFields[indexTable].value);
        }
    }, [
        formValues.options,
        globalValues,
        isSelectItemsSet,
        bodyRequestFields,
        indexTable,
        isNewBodyRequest,
    ]);

    /**
     * @name hasModalOpened
     * @description Funcion que revisa si el modal esta abierto para asignar datos correspondientes
     */
    const hasModalOpened = () => {
        getSelectItems();
    };

    /**
     * @description Agregar nueva respuesta o editar una respuesta del cuerpo de la petición
     */
    const addBodyRequestFunction = () => {
        let bodyRequestCopy = JSON.parse(JSON.stringify(bodyRequest));

        // si el valor del campo no es un campo vacío o personalizado, revisar si es valor global
        if (
            bodyRequestCopy.bodyRequestValue !== "Escribe tu valor aquí" &&
            bodyRequestCopy.bodyRequestValue !== "null"
        ) {
            bodyRequestCopy.bodyRequestValue = `{{${bodyRequestCopy.bodyRequestValue}}}`;
        } else {
            // bodyRequestCopy.bodyOptionValue = `{{${bodyRequestCopy.bodyOptionValue}}}`;
        }

        isNewBodyRequest ? addBody(bodyRequestCopy) : updateBody(bodyRequestCopy, indexTable);
        clearForm();
    };

    /**
     * @description Validar los campos y guardar el form
     */
    function handleChangesForm(targetName, targetValue) {
        let { formResponseErrors, errorHelperForm, formHasErrors } = formErrors;

        let bodyRequestCopy = JSON.parse(JSON.stringify(bodyRequest));
        let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
        let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
        let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));
        let formValuesCopy = JSON.parse(JSON.stringify(formValues));

        // Buscamos errores según el campo
        switch (targetName) {
            case "bodyRequestKey":
                bodyRequestCopy[targetName] = targetValue.trim().replace(/ /g, ""); // removemos los espacios y guardamos el campo

                // si el campo está vacío, agregar errores
                if (targetValue === "") {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }
                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;

            case "bodyRequestValue":
                bodyRequestCopy[targetName] = targetValue; // guardamos el campo

                // si el campo está vacío, agregar errores
                if (targetValue === "") {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }

                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;

                // si está disponible para "otro valor"
                if (targetValue === "Escribe tu valor aquí") {
                    formValuesCopy.addNewBodyValue = true;

                    formResponseErrorsCopy["bodyOptionValue"] = true;
                    errorHelperFormCopy["bodyOptionValue"] = errorHelperKeys.emptyField;
                    break;
                }

                // si no es "otro valor", ocultar campo y eliminar errores
                formValuesCopy.addNewBodyValue = false;
                bodyRequestCopy["bodyOptionValue"] = "";
                formResponseErrorsCopy["bodyOptionValue"] = false;
                errorHelperFormCopy["bodyOptionValue"] = errorHelperKeys.removeError;

                break;
            case "bodyOptionValue":
                bodyRequestCopy[targetName] = targetValue;

                if (targetValue === "") {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }

                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;
            default:
                break;
        }

        const countErrors = Object.values(formResponseErrorsCopy).reduce(
            (last, current) => last + current,
            0
        );

        formHasErrorsCopy = countErrors > 0 ? true : false;
        formValuesCopy.formHasChanges = true;

        setBodyRequest(bodyRequestCopy);
        setFormErrors({
            ...formErrors,
            formResponseErrors: formResponseErrorsCopy,
            errorHelperForm: errorHelperFormCopy,
            formHasErrors: formHasErrorsCopy,
        });
        setFormValues(formValuesCopy);
    }

    /**
     * @description buscar los errores en el form cuando se edita
     */
    const searchFormErrors = () => {
        let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;
        let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
        let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
        let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));

        for (const key in bodyRequest) {
            const formValue = bodyRequest[key];

            switch (key) {
                case "bodyRequestKey":
                    if (formValue !== "") {
                        formResponseErrorsCopy[key] = false;
                        errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        break;
                    }
                    break;
                case "bodyRequestValue":
                    if (formValue !== "") {
                        formResponseErrorsCopy[key] = false;
                        errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        break;
                    }
                    break;
                case "bodyOptionValue":
                    if (
                        bodyRequest["bodyRequestValue"] === "Escribe tu valor aquí" &&
                        formValue === ""
                    ) {
                        formResponseErrorsCopy[key] = true;
                        errorHelperFormCopy[key] = errorHelperKeys.emptyField;
                        break;
                    }

                    formResponseErrorsCopy[key] = false;
                    errorHelperFormCopy[key] = errorHelperKeys.removeError;
                    break;
                default:
                    break;
            }
        }

        //iteramos el objeto de errores
        const countErrors = Object.values(formResponseErrorsCopy).reduce((a, item) => a + item, 0);

        formHasErrorsCopy = countErrors > 0 ? true : false;

        setFormErrors({
            ...formErrors,
            formResponseErrors: formResponseErrorsCopy,
            errorHelperForm: errorHelperFormCopy,
            formHasErrors: formHasErrorsCopy,
        });
        setIsEditableDataSet(false);
    };

    /**
     * @name clearForm
     * @description al cerrar modal, limpiar form
     */
    const clearForm = () => {
        setFormValues({
            ...formValues,
            formHasChanges: false,
            addNewBodyValue: false,
        });
        setFormErrors(formErrorsInit);
        setBodyRequest({
            bodyRequestKey: "",
            bodyRequestValue: "",
            bodyOptionValue: "",
        });
        // getSelectItems();
    };

    /**
     * @name undoChanges
     * @description al cancelar, deshacer los cambios
     */
    const undoChanges = () => {
        if (isNewBodyRequest) {
            clearForm();
            return;
        }

        setBodyRequest(previousValue);
        setIsEditableDataSet(true);
        setFormValues({
            ...formValues,
            formHasChanges: false,
        });
    };

    /**
     * @description funcion para validar si el campo a asignar el personalizado, parte de los valores listados o nulo
     */
    function isCustomValue(key, value) {
        setIsSelectItemsSet(false);
        if (typeof value === "object" || typeof value === "array") {
            value = JSON.stringify(value);
        }

        // si es valor se marcó como vacío, asignar "null"
        if (value === "null" || value === null) {
            setBodyRequest({
                bodyRequestKey: key,
                bodyRequestValue: "null",
            });

            setPreviousValue({
                bodyRequestKey: key,
                bodyRequestValue: "null",
            });
            setIsEditableDataSet(true);
            return;
        }

        let beforeRemoveBrackets = value;

        if (value.includes("{{")) {
            value = value.trim().replace(/([{}])/g, "");
        }

        // si el valor es parte de un valor personalizado, asignar datos
        let isFromOptions = formValues.options.filter((option) => {
            return option.id === value;
        });

        if (isFromOptions && isFromOptions.length === 0) {
            setBodyRequest({
                bodyRequestKey: key,
                bodyRequestValue: "Escribe tu valor aquí",
                bodyOptionValue: beforeRemoveBrackets,
            });

            setPreviousValue({
                bodyRequestKey: key,
                bodyRequestValue: "Escribe tu valor aquí",
                bodyOptionValue: beforeRemoveBrackets,
            });

            setIsEditableDataSet(true);
            return;
        }

        setBodyRequest({
            bodyRequestKey: key,
            bodyRequestValue: value,
        });
        setPreviousValue({
            bodyRequestKey: key,
            bodyRequestValue: value,
        });
        setIsEditableDataSet(true);
    }

    return (
        <Fragment>
            <FormModal
                modalTitle={
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                        <Typography color="textPrimary">
                            {isNewBodyRequest
                                ? "Agregar campo al cuerpo de la petición"
                                : "Editar campo del cuerpo de la petición"}
                        </Typography>
                    </Breadcrumbs>
                }
                buttonType={isNewBodyRequest ? "link" : null}
                buttonTooltip={isNewBodyRequest ? "Abrir modal para agregar campo" : "Editar campo"}
                justIcon={isNewBodyRequest ? null : <EditarIcono width="20px" height="20px" />}
                buttonName={isNewBodyRequest ? "Agregar campo" : null}
                startIcon={isNewBodyRequest ? <AgregarIcono width="15px" height="15px" /> : null}
                alignButtonRight={isNewBodyRequest ? true : null}
                isOpen={(e) => (e ? hasModalOpened() : null)}
                onCancel={() => undoChanges()}
                onSave={() => addBodyRequestFunction()}
                disableSaveOnError={true}
                hasChanges={formValues.formHasChanges}
                hasErrors={formErrors.formHasErrors}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <label className="top-label-field">
                            <span style={{ color: "red" }}>* </span>
                            Nombre del campo
                        </label>
                        <TextField
                            name="bodyRequestKey"
                            value={bodyRequest.bodyRequestKey}
                            fullWidth
                            variant="outlined"
                            size="small"
                            onChange={(e) => {
                                handleChangesForm(e.target.name, e.target.value);
                            }}
                            error={formErrors.formResponseErrors.bodyRequestKey}
                            helperText={formErrors.errorHelperForm.bodyRequestKey}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label className="top-label-field">
                            {bodyRequest.bodyRequestValue !== null ? (
                                <span style={{ color: "red" }}>* </span>
                            ) : null}
                            Valor del campo
                            {bodyRequest.bodyRequestValue === null ? (
                                <Tooltip
                                    title="El valor del campo se marcó como vacío"
                                    style={{ marginLeft: "3px" }}
                                >
                                    <HelpOutlineIcon color="primary" fontSize="small" />
                                </Tooltip>
                            ) : null}
                        </label>
                        {/* cuando se agrega un nuevo campo */}
                        <TextField
                            select
                            name="bodyRequestValue"
                            value={bodyRequest.bodyRequestValue}
                            style={StyledMentionInput}
                            fullWidth
                            variant="outlined"
                            size="small"
                            onChange={(e) => {
                                handleChangesForm("bodyRequestValue", e.target.value);
                            }}
                            error={formErrors.formResponseErrors.bodyRequestValue}
                            helperText={formErrors.errorHelperForm.bodyRequestValue}
                        >
                            {
                                formValues.options && formValues.options.length !== 0 ? (
                                    formValues.options.map((option, i) => (
                                        <MenuItem key={i} value={option.id}>
                                            {option.id}
                                            <span style={{ color: "#B2B2B2", fontSize: "13px" }}>
                                                &nbsp;• {option.list}{" "}
                                            </span>{" "}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value={""}>No hay opciones disponibles</MenuItem>
                                ) //body request list is empty
                            }
                            <MenuItem value={"Escribe tu valor aquí"}>Personalizado </MenuItem>
                            <MenuItem value={"null"}>Vacío </MenuItem>
                        </TextField>
                        {/* agregar nuevo cuando el valor del campo es "otro valor" */}
                        {bodyRequest.bodyRequestValue === "Escribe tu valor aquí" ? (
                            <div>
                                <label className="top-label-field">
                                    <span style={{ color: "red" }}>* </span>
                                    Valor personalizado
                                </label>
                                <MentionsInput
                                    name="bodyOptionValue"
                                    value={bodyRequest.bodyOptionValue || ""}
                                    onChange={(e) => {
                                        handleChangesForm("bodyOptionValue", e.target.value);
                                    }}
                                    style={StyledMentionInput}
                                    allowSpaceInQuery
                                    allowSuggestionsAboveCursor={true}
                                    placeholder={"Escribe tu valor aquí..."}
                                    fullWidth
                                >
                                    <Mention
                                        //Estilo del etiqueta
                                        style={{
                                            backgroundColor: "#E0E0E0",
                                            padding: "3px",
                                            border: "1px solid #E0E0E0",
                                            height: "32px",
                                            borderRadius: "16px",
                                            margin: "-4px",
                                        }}
                                        trigger="@"
                                        //formato de la variable en el state
                                        markup="{{__id__}}"
                                        //formato de la variable en visualmente
                                        displayTransform={(id) => `${id}`}
                                        //filtrado de datos
                                        data={formValues.options}
                                        //Html personalizado de cada elemento idividual de la lista
                                        renderSuggestion={(suggestion) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        margin: "0",
                                                    }}
                                                >
                                                    {suggestion.display}
                                                </p>
                                                <span
                                                    style={{ color: "#B2B2B2", fontSize: "13px" }}
                                                >
                                                    &nbsp;• {suggestion.list}
                                                </span>
                                            </div>
                                        )}
                                    />
                                </MentionsInput>
                                <div>
                                    {formErrors.formResponseErrors.bodyOptionValue ? (
                                        <span className="errorText">
                                            {formErrors.errorHelperForm.bodyOptionValue}{" "}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </Grid>
                </Grid>
            </FormModal>
        </Fragment>
    );
}

export default AddBodyRequest;
