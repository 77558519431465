import React from "react";

//MUi components
import { Grid } from "@material-ui/core";

//Custom Portal Components
import SimpleTable from "../../common/Tables/SimpleTable";
import ActivateSubflow from "./Modals/ActivateSubflow";

export default function SubflowsTable(props) {
  const {
    processBranches,
    allProcessesNames,
    addProcessBranch,
    updateProcessBranches,
    removeProcessBranch,
    classes,
    optionsCatalog,
    optionsRegEx,
  } = props;

  return (
    <section>
      <Grid container>
        <Grid item className={classes.tableSize}>
          <SimpleTable
            headers={["Subflujo", "Condición"]}
            values={processBranches}
            type="subflows-table"
            functionDelete={removeProcessBranch}
            functionEdit={updateProcessBranches}
            allProcessesNames={allProcessesNames}
            classes={classes}
            optionsCatalog={optionsCatalog}
            optionsRegEx={optionsRegEx}
          />
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          className={classes.consistentInputSize}
        >
          <ActivateSubflow
            addProcessBranch={addProcessBranch}
            allProcessesNames={allProcessesNames}
            classes={classes}
            isAddSubflow={true}
            optionsCatalog={optionsCatalog}
            optionsRegEx={optionsRegEx}
          />
        </Grid>
      </Grid>
    </section>
  );
}
