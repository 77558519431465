import React from 'react';
//Icons
import LogoInteBotColor from '../Icons/intebot_color.svg'

//styleSheets
import './StyledPages/AuthPage.css'

//Custom Portal Components
import Login from '../components/AuthPageComponents/Login'
import CreateAccount from '../components/AuthPageComponents/CreateAccount';
import RestorePassword from '../components/AuthPageComponents/RestorePassword';
import ResetPassword from '../components/AuthPageComponents/ResetPassword';

//External Libraries
import Cookies from 'js-cookie' //https://www.npmjs.com/package/js-cookie

const Authpage = () => {
    const setAuthFormBody = () => {
        let pathname = window.location.pathname
        switch (pathname) {
            case '/login':
                return <Login/>
            case '/search-account':
                return <RestorePassword/>
            case '/create-account':
                return <CreateAccount/>
            case '/reset-password':
                return <ResetPassword/>
            default:
                return(<div>...</div>)
        }
    }

    let AuthFormBody = setAuthFormBody()
    let isDarkModeEnabled = (Cookies.get('darkMode') === 'true');
    return (
        <React.Fragment>
                <div className='LoginMain' >
                    <div className='intebotLogoContainer' >
                        <img src={isDarkModeEnabled ? '/intebot_logo.svg' : LogoInteBotColor} alt="Logo Intebot" />
                    </div>
                    <div className='LoginForm' >
                        {setAuthFormBody()}
                    </div>
                </div>
            </React.Fragment>
    );
}

export default Authpage;
