import React, { Component, Fragment } from "react";
import { Grid, TextField, InputAdornment, IconButton, Button, Tooltip } from "@material-ui/core/";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

//Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import LanguageIcon from "@material-ui/icons/Language";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { ReactComponent as MessengerIcon } from "../Icons/messengerIcon.svg";
import { ReactComponent as TeamsIcon } from "../Icons/TeamsIcon.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { makeStyles, withStyles } from "@material-ui/core/styles";
//Portal catalogs
import { suscripcionStatusTranslation } from "../components/IntebotCatalog";
import AppContext from "../Context/AppContext";

//CSS Style sheets
import "./StyledPages/MyAccountPage.css";

//Settings Keys
// const availableChannels = Settings.CHANNELS

// MUI Styles
const Accordion = withStyles({
    root: {
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        margin: "10px 0px",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 40,
        maxHeight: 40,
        "&$expanded": {
            minHeight: 40,
            maxHeight: 40,
        },
        //   padding: '10px 0',
        "&:hover": {
            background: "rgba(0, 0, 0, .03)",
        },
    },

    content: {
        "&$expanded": {
            margin: "8px 0",
        },
        flexGrow: "0",
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        //   backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}))(MuiAccordionDetails);

class MySubscriptionPage extends Component {
    state = {
        showToken: false,
        openTooltip: false,
    };

    logout = () => {
        const { logOutUser } = this.context;
        logOutUser();
    };

    //Mostarr token
    handleClick = (target) => {
        const { showToken, openTooltip } = this.state;
        if (target.toString().includes("SVG") || target.toString().includes("Button")) {
            this.setState({
                ...this.state,
                showToken: !showToken,
            });
        } else {
            navigator.clipboard.writeText("Token no disponible");
            this.setState({
                ...this.state,
                openTooltip: !openTooltip,
            });
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    openTooltip: false,
                });
            }, 1000);
        }
    };

    render() {
        const { processesTemp, getStorageData } = this.context;
        // const { onClose, openDrawerChangePassword } = this.props
        // const { logout } = this;
        let storageData = getStorageData([
            "email",
            "organizationName",
            "name",
            "lastName",
            "subscriptionModule",
            "subscriptionChannel",
            "currentSubscription",
            "subscriptionTier",
        ]);
        let { subscriptionModule, subscriptionTier, currentSubscription, subscriptionChannel } =
            storageData;
        let subscriptionModuleNames = Object.keys(subscriptionModule);
        //Preguntar si desde la base deben venir en español
        let subscriptionModuleNamesSpanish = subscriptionModuleNames.map((moduleName) => {
            switch (moduleName) {
                case "processNaturalLenguage":
                    return "Procesamiento de lenguaje natural";
                case "sendCampaigns":
                    return "Envio de campañas";
                case "processAudio":
                    return "Procesamiento de audio";
                case "processImages":
                    return "Procesamiento de Imagenes";
                case "conversationTransfer":
                    return "Transferencia de conversación";
                case "textGeneration":
                    return "Generación de texto";
                default:
                    return "...";
            }
        });
        let subscriptionChannelNames = Object.keys(subscriptionChannel);
        //Hacemos en mayuscula la primera letra del nombre
        let subscriptionChannelNamesCapital = subscriptionChannelNames.map((channel) => {
            return channel.charAt(0).toUpperCase() + channel.slice(1);
        });
        let channelIcons = {
            Web: <LanguageIcon className="icon-primary-color" fontSize="small" />,
            Whatsapp: <WhatsAppIcon className="icon-primary-color" fontSize="small" />,
            Messenger: <MessengerIcon className="icon-primary-color" width="20px" />,
            Teams: <TeamsIcon className="icon-primary-color" width="20px" />,
        };
        let ModulesChannles = {
            "Procesamiento de lenguaje natural": [
                channelIcons.Web,
                channelIcons.Whatsapp,
                channelIcons.Messenger,
                channelIcons.Teams,
            ],
            "Envio de campañas": [channelIcons.Whatsapp],
            "Procesamiento de audio": [channelIcons.Whatsapp],
            "Procesamiento de Imagenes": [
                channelIcons.Whatsapp,
                channelIcons.Messenger,
                channelIcons.Teams,
            ],
            "Transferencia de conversación": [channelIcons.Web],
            "Generación de texto": [
                channelIcons.Web,
                channelIcons.Whatsapp,
                channelIcons.Messenger,
                channelIcons.Teams,
            ],
        };

        let { showToken, openTooltip } = this.state;
        return (
            <div className="my-account-container">
                <Grid direction="column" container alignItems="center">
                    <Grid item xs={12} className="account-info-container">
                        <h3>
                            {" "}
                            Suscripción: {
                                suscripcionStatusTranslation[subscriptionTier.status]
                            }{" "}
                        </h3>
                        <p>ID de la suscripción:</p>
                        <p>{currentSubscription.subscriptionId}</p>
                        <p>
                            {" "}
                            Nivel de suscripción: <strong>
                                {" "}
                                {subscriptionTier.subscription}{" "}
                            </strong>{" "}
                        </p>
                        {/* Pendiente hacer el calculo de los flujos disponibles desde los valores del login */}
                        <p>
                            {" "}
                            Cantidad de flujos conversacionales:{" "}
                            <strong> {subscriptionTier.alias} </strong>{" "}
                        </p>
                        <p>
                            {" "}
                            Cantidad de flujos conversacionales disponibles:
                            <strong>
                                {subscriptionTier.subscription === "Platinum"
                                    ? subscriptionTier.alias
                                    : subscriptionTier.flows - processesTemp.length}
                            </strong>
                        </p>
                    </Grid>
                </Grid>
                {/* ====== Canales disponibles ========== */}
                <Grid
                    direction="column"
                    container
                    alignItems="center"
                    className="active-channels-container"
                >
                    <h2>Canales activos en tu suscripción</h2>
                    {subscriptionChannelNamesCapital.map((channel, index) => (
                        <Fragment key={index}>
                            {subscriptionChannel["web"] && channel === "Web" ? (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div
                                            id="accordion__summary"
                                            className="accordion__summary-container"
                                        >
                                            <div className="container--align-center">
                                                {channelIcons[channel]}
                                                <h3> &nbsp; {channel} </h3>
                                            </div>
                                            <div className="container--align-center">
                                                {subscriptionChannel[channel.toLowerCase()] ? (
                                                    <CheckCircleIcon style={{ color: "#44ab3f" }} />
                                                ) : (
                                                    <RemoveCircleIcon color="action" />
                                                )}
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="accordion__details-container">
                                            {/* <h4 style={{margin: '10px 0'}}>Token:  &nbsp;</h4>
                                                    <Tooltip title="Token copiado" open={openTooltip}>
                                                        <div></div>
                                                    </Tooltip> */}
                                            {/* <TextField
                                                        disabled={true}
                                                        name="token"
                                                        value="Token no disponible"
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="******"
                                                        type={showToken ? "text" : "password"}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <IconButton>
                                                                        { showToken ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ) 
                                                        }}
                                                        onClick={(e)=> {
                                                            this.handleClick(e.target)
                                                        }}
                                                    /> */}
                                            <Button
                                                id="downloadBtn"
                                                variant="contained"
                                                href="https://github.com/intevolution/InteBot_Web_Client"
                                                target="_blank"
                                                rel="noreferrer"
                                                disableElevation
                                                style={{ marginTop: "15px" }}
                                            >
                                                Descargar cliente web en GitHub
                                            </Button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ) : (
                                <div className="container--content">
                                    <div>{channelIcons[channel]}</div>
                                    <div>
                                        <h3> &nbsp; {channel} </h3>
                                    </div>
                                    <div>
                                        {subscriptionChannel[channel.toLowerCase()] ? (
                                            <CheckCircleIcon style={{ color: "#44ab3f" }} />
                                        ) : (
                                            <RemoveCircleIcon color="action" />
                                        )}
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    ))}
                    <span className="container__notes">
                        * Si deseas activar algún canal, por favor ponte en contacto con atención al
                        cliente.
                    </span>
                </Grid>
                <Grid
                    direction="column"
                    container
                    alignItems="center"
                    className="active-channels-container"
                >
                    <h2>Módulos disponibles en tu suscripción</h2>
                    <Grid item xs={12}>
                        <div style={{ display: "flex" }}>
                            <div>
                                {subscriptionModuleNamesSpanish.map((moduleName, i) => (
                                    <div key={i}>
                                        <h3 key={i} className="active-channels-container-name ">
                                            {" "}
                                            &nbsp; {moduleName}{" "}
                                        </h3>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {ModulesChannles[moduleName].map(
                                                (channelIcon, index) => (
                                                    <div key={index}>{channelIcon}</div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "25px",
                                    margin: "10px",
                                }}
                            >
                                {subscriptionModuleNames.map((moduleName, i) =>
                                    subscriptionModule[moduleName] ? (
                                        <CheckCircleIcon key={i} style={{ color: "#44ab3f" }} />
                                    ) : (
                                        <RemoveCircleIcon key={i} color="action" />
                                    )
                                )}
                            </div>
                        </div>
                    </Grid>
                    <span className="container__notes">
                        * Si deseas activar algún módulo, por favor ponte en contacto con atención
                        al cliente.
                    </span>
                </Grid>
            </div>
        );
    }
}

MySubscriptionPage.contextType = AppContext;

export default MySubscriptionPage;
