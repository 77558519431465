import React from 'react';

//Custom Portal Components
import RefreshButton from './RefreshButton';

const ErrorInFetch = (props) => {
    const { errorInFetch, refreshFunction } = props
    return (
        <React.Fragment>
            {
                errorInFetch && (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <span>Hubo un error, intenta más tarde.</span>
                        <RefreshButton
                            refreshFunction = {refreshFunction}
                        />
                    </div>
                )
            }
        </React.Fragment>
    );
}

export default ErrorInFetch;
