import React, { Fragment, useState, useEffect, useRef } from "react";

// Material UI inputs
import {
  Grid,
  Breadcrumbs,
  Typography,
  TextField,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//Custom Portal components
import FormModal from "../../../FormModalComponents/FormModal";
import AgregarIcono from "../../../../Icons/AgregarIcono";
import EditarIcono from "../../../../Icons/EditarIcono";
import IntebotCatalog from "../../../IntebotCatalog";
import AppContext from "../../../../Context/AppContext";

// styles
import "../Styles/Modals.css";

function RequestHeadersTable(props) {

  const errorHelperKeys = IntebotCatalog.errorHelper;

  const [requestHeader, setRequestHeader] = useState({
    headerKey: "",
    headerValue: ""
  });

  const [headerErrors, setHeaderErrors] = useState({
    headerKey: true,
    headerValue: true
  });

  const [headerErrorHelper, setHeaderErrorHelper] = useState({
    headerKey: errorHelperKeys.emptyField,
    headerValue: errorHelperKeys.emptyField
  });

  const [hasChanges, setHasChanges] = useState(false)
  const prevHeaderKey = useRef("")

  useEffect(() => {
    getCurrentHeader()
  }, []);

  useEffect(() => {
    searchHeaderErrors(requestHeader)
  },[requestHeader])

  const getCurrentHeader = () => { 
    let currentHeaderKey = "";
    let currentHeaderValue = "";
    if(!isNewRequestHeader){
      currentHeaderKey = Object.keys(requestHeaders)[indexTable]
      currentHeaderValue = requestHeaders[currentHeaderKey]
      prevHeaderKey.current = currentHeaderKey
    }
    setRequestHeader({
      headerKey: currentHeaderKey,
      headerValue: currentHeaderValue
    })
    setHasChanges(false)
  }

  const clearForm = () => {
    setRequestHeader({
      headerKey: "",
      headerValue: ""
    })
    setHeaderErrors({
      headerKey: true,
      headerValue: true
    })
    setHeaderErrorHelper({
      headerKey: errorHelperKeys.emptyField,
      headerValue: errorHelperKeys.emptyField
    })
    setHasChanges(false)
  }

  const searchHeaderErrors = (requestHeader) => {
    const { headerKey, headerValue } = requestHeader;
    let headerErrorsCopy = JSON.parse(JSON.stringify(headerErrors))
    let headerErrorHelperCopy = JSON.parse(JSON.stringify(headerErrorHelper))

    //Quitamos errores
    headerErrorsCopy["headerKey"] = false
    headerErrorHelperCopy["headerKey"] = errorHelperKeys.removeError
    headerErrorsCopy["headerValue"] = false
    headerErrorHelperCopy["headerValue"] = errorHelperKeys.removeError

    //Ponemos errores si esta vacío
    if(headerKey === ""){
      headerErrorsCopy["headerKey"] = true
      headerErrorHelperCopy["headerKey"] = errorHelperKeys.emptyField
    }
    if(headerValue === ""){
      headerErrorsCopy["headerValue"] = true
      headerErrorHelperCopy["headerValue"] = errorHelperKeys.emptyField
    }

    setHeaderErrors(headerErrorsCopy)
    setHeaderErrorHelper(headerErrorHelperCopy)
  }

  const handleHeaderChange = (targetName, targetValue) => {
    //console.log(targetName, targetValue);
    let requestHeaderCopy = JSON.parse(JSON.stringify(requestHeader))
    requestHeaderCopy[targetName] = targetValue
    setRequestHeader(requestHeaderCopy)
    setHasChanges(true)
  }

  const { headerKey, headerValue } = requestHeader;
  const { isNewRequestHeader, updateRequestHeader, addRequestHeader, requestHeaders, indexTable } = props;

  return (
    <Fragment>
      <FormModal
        modalTitle={
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography color="textPrimary">
              {isNewRequestHeader
                ? "Agregar encabezado de la petición"
                : "Editar encabezado de la petición"}
            </Typography>
          </Breadcrumbs>
        }
        buttonType={isNewRequestHeader ? "link" : null}
        buttonTooltip={
          isNewRequestHeader ? "Abrir modal para agregar encabezado" : "Editar encabezado"
        }
        justIcon={
          isNewRequestHeader ? null : <EditarIcono width="20px" height="20px" />
        }
        buttonName={isNewRequestHeader ? "Agregar encabezado" : null}
        startIcon={
          isNewRequestHeader ? <AgregarIcono width="15px" height="15px" /> : null
        }
        alignButtonRight={isNewRequestHeader ? true : null}
        onCancel={() => getCurrentHeader()}
        onSave={() => {
          if(isNewRequestHeader){
            addRequestHeader(headerKey, headerValue)
            clearForm()
            return
          }
          updateRequestHeader(prevHeaderKey.current, headerKey, headerValue, indexTable)
          setHasChanges(false)
        }}
        disableSaveOnError={true}
        disableCancel={isNewRequestHeader}
        hasChanges={hasChanges}
        hasErrors={Object.keys(headerErrors).some(errorKey => headerErrors[errorKey])}
      >
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={12}>
            <label className="top-label-field">
              <span style={{ color: "red" }}>* </span>
              Clave del encabezado
            </label>
            <TextField
              name="headerKey"
              value={headerKey ?? ""}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleHeaderChange(e.target.name, e.target.value);
              }}
              error={headerErrors["headerKey"]}
              helperText={headerErrorHelper["headerKey"]}
            />
          </Grid>
          <Grid item xs={12}>
            <label className="top-label-field">
              <span style={{ color: "red" }}>* </span>
              Valor del encabezado
            </label>
            <TextField
              name="headerValue"
              value={headerValue ?? ""}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleHeaderChange(e.target.name, e.target.value);
              }}
              error={headerErrors["headerValue"]}
              helperText={headerErrorHelper["headerValue"]}
            />
          </Grid>
        </Grid>
      </FormModal>
    </Fragment>
  );
}

export default RequestHeadersTable;
