exports.ProcessesKeys = [
    {
        name: "processName",
        type: "text-field",
        alias: "Flujos conversacionales",
        detail: "",
    },
];

exports.ProcessKeys = [
    {
        name: "processName",
        type: "text-field",
        alias: "Nombre del flujo",
        detail: "",
        required: true,
        placeholder: "Escribe el Nombre",
        render: true,
    },
    {
        name: "visibleChannel",
        type: "expandable-checkbox",
        alias: "Canal",
        detail: "Permite mostrar el flujo como una opción del menú principal en el canal seleccionado.",
        render: true,
        required: false,
    },
    {
        name: "steps",
        type: "steps-table",
        options: ["step", "description"],
        alias: "Construcción del flujo",
        detail: "Secuencia que pasos que define el flujo de la conversación.",
        required: true,
        render: true,
    },
    {
        name: "confirmationData",
        type: "checkbox",
        alias: "Confirmación de datos",
        detail: "Decide si el usuario debe confirmar los datos ingresados en los pasos",
        render: true,
        required: false,
    },
    {
        name: "showMenu",
        type: "checkbox",
        alias: "Regresar al menú",
        detail: "Mostrar el menú principal al concluir el flujo conversacional.",
        required: false,
        render: true,
    },
    {
        name: "subMenu",
        type: "checkbox",
        alias: "Agregar flujo al menú de trabajadores",
        required: false,
        render: false,
    },
    {
        name: "endProcess",
        type: "mention-text-field",
        alias: "Mensaje final",
        detail: "Mensaje de cierre al concluir el flujo conversacional.",
        required: false,
        render: true,
    },
    {
        name: "triggerFlowOnEnd",
        type: "switchButton",
        alias: "Detonar otro flujo",
        detail: "Permite detonar otro flujo al concluir el flujo conversacional.",
        required: false,
        render: true,
    },
    {
        name: "triggerNewFlow",
        type: "selector",
        alias: "Segundo flujo",
        options: [],
        detail: "Selecciona el flujo que se detonará al concluir el flujo conversacional.",
        defaultValue: "",
        required: false,
        render: false,
    },
];

exports.ServicesKeys = [
    //JSON de petición.
    {
        name: "requestHeaders",
        type: "request-headers-table",
        alias: "Encabezado de la petición",
        detail: "",
        defaultValue: "",
        required: false,
        render: true,
    },
    {
        name: "bodyRequest",
        type: "body-request-table",
        alias: "Cuerpo de la petición",
        detail: "",
        defaultValue: "",
        required: true,
        render: true,
    },
    {
        name: "customBodyRequest",
        type: "mention-text-field",
        alias: "",
        detail: ``,
        defaultValue: "",
        required: false,
        render: true,
    },
    {
        name: "responseBranches",
        type: "response-branches-table",
        alias: "Respuesta de la petición",
        detail: "",
        required: false,
        render: true,
    },
    {
        name: "replyDefaultMessage",
        type: "mention-text-field",
        alias: "Mensaje por defecto",
        detail: "Mensaje presentado al usuario cuando la respuesta de la petición no coincida con ningún código de la tabla.",
        render: true,
        fieldTitleUp: true,
    },
    {
        name: "enableLuis",
        type: "switchButton",
        alias: "PLN",
        detail: "Permite resolver el flujo conversacional en un solo paso.",
        render: true,
    },
    {
        name: "luisMessage",
        type: "text-field",
        alias: "Mensaje alternativo PLN",
        render: false,
        required: true,
    },
];

exports.SoapKeys = [
    {
        name: "soapWSDLRequest",
        type: "text-field",
        alias: "URL",
        detail: "Enlace de descripción al Servicio Web (WSDL).",
        defaultValue: "",
        required: true,
    },
    {
        name: "soapOperation",
        type: "text-field",
        alias: "Operación: ",
        detail: "Nombre de la operación a consumir del Servicio Web.",
        required: true,
    },
    {
        name: "requestMethod",
        type: "selector",
        alias: "Método de la petición HTTP",
        options: ["POST", "GET"],
        detail: "",
        defaultValue: "GET",
        required: false,
        render: true,
    },
];

exports.RestKeys = [
    {
        name: "endPoint",
        type: "mention-text-field",
        alias: "URL",
        detail: "Enlace de conexión al Servicio Web (endpoint).",
        defaultValue: "",
        required: true,
    },
    {
        name: "requestMethod",
        type: "selector",
        alias: "Método de la petición HTTP",
        options: ["POST", "GET", "PUT", "DELETE", "PATCH", "HEAD", "OPTIONS"],
        detail: "",
        defaultValue: "GET",
        required: false,
        render: true,
    },
];

exports.FAQKeys = [
    {
        name: "questionFormulation",
        type: "mention-text-field",
        alias: "Pregunta formulada",
        detail: "Formula la pregunta que será resuelta con FAQ",
        required: true,
    },
];

exports.StepsKeys = [
    {
        name: "step",
        type: "no-editable",
        alias: "Paso",
        detail: "",
        required: false,
        render: true,
    },
    {
        name: "description",
        type: "text-field",
        alias: "Descripción del paso",
        detail: "",
        required: true,
        render: true,
    },
    {
        name: "ask",
        type: "mention-text-field",
        alias: "Mensaje de InteBot",
        detail: "",
        required: true,
        render: true,
    },
    {
        name: "enableMenuOptions",
        type: "switchButton",
        alias: "Mostrar submenú",
        detail: "",
        render: true,
    },
    {
        name: "headerMenu",
        type: "mention-text-field",
        alias: "Encabezado",
        detail: "",
        required: true,
        render: false,
    },
    {
        name: "menuOptions",
        type: "menu-options-table",
        alias: "",
        detail: "",
        required: true,
        render: false,
    },
    {
        name: "input",
        type: "radio",
        options: ["Tipo de dato", "Catálogo"],
        alias: "Restricción de entrada:",
        detail: "Permite validar un mensaje de entrada.",
        required: true,
        render: true,
    },
    {
        name: "type",
        type: "regex-selector",
        alias: "",
        detail: "",
        required: true,
        render: false,
    },
    {
        name: "catalog",
        type: "catalog-selector",
        alias: "",
        detail: "",
        required: true,
        render: false,
    },
    {
        name: "error",
        type: "mention-text-field",
        alias: "Mensaje de error",
        detail: "Mensaje mostrado en caso de que mensaje de usuario no cumpla con la restricción de entrada.",
        required: true,
        render: true,
    },
    {
        name: "jumpStep",
        type: "jump-selector",
        options: "jumpable-steps",
        alias: "Saltar al paso...",
        detail: "Permite omitir pasos mediante un criterio de validación.",
        required: false,
        render: true,
        disabled: true,
    },
    {
        name: "jumpValidationLabel",
        type: "label",
        text: "Validación de salto",
        group: "JumpStep",
        render: false,
    },
    {
        name: "JumpCriteria",
        type: "radio",
        options: ["Tipo de dato", "Catálogo", "Valor"],
        group: "JumpStep",
        required: false,
        render: false,
    },
    {
        name: "allowJump",
        type: "selector",
        group: "JumpStep",
        required: false,
        render: false,
    },
    {
        name: "jumpOperartion",
        type: "jumpOperartion-field",
        required: false,
        render: false,
    },
    {
        name: "globalValue",
        type: "checkbox",
        alias: "Guardar información",
        detail: "Permite almacenar de manera global la información obtenida en este paso para recuperarla en otro momento.",
        required: false,
        render: true,
    },
    {
        name: "hideInformation",
        type: "checkbox",
        alias: "Esconder Información Sensible",
        detail: "Encripta el valor ingresado por el usuario y elimina el mensaje del chat",
        required: false,
        render: true,
    },
    {
        name: "processBranches",
        type: "nested-flows-table",
        alias: "Subflujos",
        required: false,
        render: true,
    },
];

/* Los siguientes dos arreglos para la sección de Mensajes */
exports.MessageCommandsKeys = [
    {
        type: "double-text-field",
        inputKeys: [
            {
                name: "WELCOME",
                type: "mentions",
                alias: "Bienvenida",
                detail: "",
                required: true,
            },
            {
                name: "WELCOME_DETECTED",
                type: "text-field",
                detail: "",
                required: true,
                configKey: true,
            },
        ],
        render: true,
    },
    {
        type: "double-text-field",
        inputKeys: [
            {
                name: "MENU_MESSAGE",
                type: "mentions",
                alias: "Mensaje del menú",
                detail: "",
                required: true,
            },
            {
                name: "MENU_DETECTED",
                type: "text-field",
                detail: "",
                required: true,
                configKey: true,
            },
        ],
        render: true,
    },
    {
        type: "double-text-field",
        inputKeys: [
            {
                name: "BREAK_MESSAGE",
                type: "mentions",
                alias: "Interrupción de flujo",
                detail: "",
                required: true,
            },
            {
                name: "BREAK_DETECTED",
                type: "text-field",
                detail: "",
                required: true,
                configKey: true,
            },
        ],
        render: true,
    },
    {
        name: "USERNAME",
        type: "text-field",
        alias: "Nombre del usuario",
        detail: "",
        required: false,
        disabled: true,
        configKey: true,
        render: true,
    },
    {
        name: "QUESTION",
        type: "text-field",
        alias: "Pregunta del usuario",
        detail: "",
        required: false,
        disabled: true,
        configKey: true,
        render: true,
    },
    {
        name: "CHANNEL",
        type: "text-field",
        alias: "Canal digital",
        detail: "",
        required: false,
        disabled: true,
        configKey: true,
        render: true,
    },
    {
        name: "PHONE_NUMBER",
        type: "text-field",
        alias: "Número telefónico del usuario",
        detail: "",
        required: false,
        disabled: true,
        configKey: true,
        render: true,
    },
    {
        name: "MENU_SECTION",
        type: "mention-text-field",
        alias: "Título del menú",
        detail: "",
        required: true,
        render: true,
    },
    {
        name: "DESCRIPTION_MENU",
        type: "mention-text-field",
        alias: "Instrucciones del menú",
        detail: "",
        required: true,
        render: true,
    },
    {
        name: "MENU_SECTION2",
        type: "mention-text-field",
        alias: "Título del menú privado",
        detail: "",
        required: true,
        render: false,
    },
    {
        name: "DESCRIPTION_MENU_PRIVATE",
        type: "mention-text-field",
        alias: "Instrucciones del menú privado",
        detail: "",
        required: true,
        render: false,
    },
    {
        name: "SHOW_MENU",
        type: "checkbox",
        alias: "Mostrar menú",
        detail: "Mostrar el menú principal junto al mensaje de bienvenida.",
        required: false,
        render: true,
    },
    // {
    //     type: "double-text-field",
    //     inputKeys: [
    //         {
    //             name: "BAD_QUESTION",
    //             type: "mentions",
    //             alias: "Pregunta confusa",
    //             detail: "",
    //             required: true,
    //         },
    //         {
    //             name: "QNA_MAX_SCORE",
    //             type: "text-field",
    //             detail: "La respuesta se considera exitosa si es mayor a este número",
    //             required: true,
    //             configKey: true,
    //             disabled: true,
    //         },
    //     ],
    //     render: true,
    // },
    {
        type: "double-text-field",
        inputKeys: [
            {
                name: "NOT_FOUND",
                type: "mentions",
                alias: "Pregunta no encontrada",
                detail: "",
                required: true,
            },
            {
                name: "QNA_MIN_SCORE",
                type: "text-field",
                detail: "Si la respuesta es mayor a este número y menor al porcentaje máximo se considera como pregunta mal formulada. Si es menor se considera como pregunta no encontrada",
                required: true,
                configKey: true,
                disabled: true,
            },
        ],
        render: true,
    },
];

/* Para la sección de Tipos de Datos */
exports.RegExpKeys = [
    {
        name: "name",
        type: "text-field",
        alias: "Nombre del tipo de dato",
        detail: "Con este nombre podrá identificar el tipo de dato y asociarlo dentro de los procesos.",
        required: true,
    },
    {
        name: "description",
        type: "text-field",
        alias: "Descripción",
        detail: "Descripción del dato.",
        required: false,
    },
    {
        name: "expresion",
        type: "text-field",
        alias: "Expresión regular",
        detail: "Esta expresión se usará para validar un dato que el usuario le escriba al InteBot.",
        required: true,
    },
];

/* Los siguientes dos arreglos para la sección de Catálogos */
exports.CatalogKeys = [
    {
        name: "name",
        type: "text-field",
        alias: "Nombre del catálogo",
        detail: "",
        required: true,
    },
    {
        name: "catalog",
        type: "catalogs-table",
        alias: "Registros del catálogo",
        detail: "Cada registro se compone de una clave única y al menos un valor",
        required: true,
    },
];

exports.CatalogItemsKeys = [
    {
        name: "key",
        type: "text-field",
        alias: "Clave",
        detail: "Clave con la que se identifica el campo del catálogo (ejemplos: 1, H, CDMX)",
        required: true,
    },
    {
        name: "jsonValues",
        type: "chip-editor",
        alias: "Valores",
        detail: "Palabras o valores que tienen una relación mutua para identificar el campo del catálogo (ejemplo: 1, primero, 1ro)",
        required: true,
    },
];

exports.nestedConditions = [
    { name: "Tipo de dato", value: "type" },
    { name: "Catálogo", value: "catalog" },
    { name: "Valor", value: "operation" },
];

exports.operators = [
    { name: "igual que", value: "=" },
    { name: "menor que", value: "<" },
    { name: "menor o igual que", value: "<=" },
    { name: "mayor que", value: ">" },
    { name: "mayor o igual que", value: ">=" },
    { name: "diferente de", value: "<>" },
];

exports.GlobalConsts = ["USERNAME", "CHANNEL", "QUESTION", "PHONE_NUMBER"];

exports.intebotConstants = {
    WELCOME_DETECTED: "GREETING",
    MENU_DETECTED: "MENU",
};

//Tipos de archivos aceptados en la sección Mensajes Multimedia
exports.acceptedFiles = [
    ".mp4",
    ".png",
    ".jpeg",
    ".jpg",
    ".pdf",
    ".docx",
    ".xlsx",
    ".pptx",
    ".txt",
    "application/msword", //any word related files
    "application/vnd.ms-excel", //any excel related files
    "application/vnd.ms-powerpoint", //any powerpoint related files
    "text/plain", //.txt
    "application/pdf",
];

exports.birdAcceptedFiles = [".jpg", ".jpeg", ".png", ".gif", ".mp4", ".pdf"];

exports.openAIAcceptedFiles = [".txt", ".pdf", ".docx", ".html", ".md", ".pptx"];

exports.fileTypesExtentions = {
    "video/mp4": "video",
    "image/gif": "image",
    "image/png": "image",
    "image/jpeg": "image",
    "image/jpg": "image",
    "application/pdf": "document",
};

exports.CampaignsHeaders = [
    { type: "none", alias: "Ninguno" },
    { type: "URL", alias: "URL del Archivo" },
    { type: "upload", alias: "Subir Archivo" },
];

exports.faqUploadTypes = [
    { type: "file", alias: "Archivo" },
    { type: "url", alias: "Sitio Web" },
];

exports.acceptedFilesQnA = [".xlsx"];

exports.errorHelper = {
    removeError: "",
    emptyField: "* El campo no puede estar vacío.",
    flowNameTaken: "* El campo ya ha sido tomado por otro flujo.",
    templateNameTaken: "* El campo ya ha sido tomado por otro template.",
    catalogTaken: "* El nombre ya ha sido tomado por otro catálogo.",
    catalogKeyTaken: "* Clave duplicada.",
    regexNameTake: "* El campo ya ha sido tomado por otro tipo de dato.",
    notEmail: "* El correo ingresado no es válido.",
    notPassword: "* La contraseña ingresada no es válida.",
    templateContentLength: "* El contenido del template no debe exceder los 1000 caracteres.",
    templateInvalidName:
        '* El nombre del template únicamente debe contar con: letras, números y "_".',
    templateNameLength: "* El nombre del template no debe exceder los 50 caracteres.",
    wrongCredentials: "* El correo y/o contraseña son incorrectos, por favor revisa nuevamente.",
    templateParamsDuplicated: "* Uno o más parámetros están duplicados.",
    maxLength60: "* Se recomienda como máximo 60 caracteres.",
    maxLength24: "* Se recomienda como máximo 24 caracteres.",
    maxLength20: "* Se recomienda como máximo 20 caracteres.",
    passwordNotMatch: "* Las contraseñas no coinciden.",
    notStrongPassword:
        "* Mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 carácter especial.",
    createAccountError: "* Hubo un error al crear la cuenta, intenta más tarde.",
    notSubscriptionID: "* La suscripción ingresada no es válida.",
    resetPasswordError: "* Hubo un error restaurar la contraseña, intenta más tarde.",
    changePasswordError: "* Hubo un error al cambiar la contraseña, intenta más tarde.",
    accountExists: "* El correo ingresado ya se encuentra registrado, intenta iniciar sesión.",
    accountNotExists: "* El correo ingresado no existe. Ingresa otro correo.",
    currentPasswordNotValid: "* La contraseña actual es incorrecta, revisa nuevamente.",
    samePassword: "* La contraseña nueva debe ser distinta de la contraseña actual.",
    notName: "* El nombre ingresado no es válido.",
    notLastName: "* El apellido ingresado no es válido.",
    validURL: "* El campo debe contener una URL válida que inicie con https:// o http://",
    emptyTable: "* La tabla no puede estar vacía.",
    emptyQuestions: "* La tabla de alternativas no puede estar vacía.",
    emptyTableField: "* Los campos en la tabla no pueden estar vacíos.",
    authApiError: "* Hubo un error al ingresar, intenta más tarde",
    tableHasNoAdmin: "* La suscripción debe contener al menos 1 administrador.",
    errorInStorage: "* No se pudo obtener la información de la suscripción, intenta más tarde.",
    duplicateName: "* El nombre ya ha sido tomado por otro campo.",
    excidedLength450: "* El campo no debe exceder los 450 caracteres.",
    predictionNotFound: "* No se pudo encontrar predicciones para el ejemplo.",
    invalidJSON: "* El JSON ingresado no es válido.",
    duplicateExample: "* El ejemplo ingresado ya existe, intenta con otro.",
    buttonMaxLength: "* El texto del botón no debe exceder los 20 caracteres.",
};

exports.responseBranchesKeys = {
    responseCode: {
        // alias:'Código de respuesta',
        responseObject: {
            name: "responseObject",
            alias: "Nombre del Objeto",
            required: false,
        },
        responseName: {
            name: "responseName",
            alias: "Nombre del código",
            required: false,
        },
        responseValue: {
            name: "responseValue",
            alias: "Valor del código",
            required: false,
        },
    },
    replyAction: {
        allowTriggerFlow: {
            name: "allowTriggerFlow",
            alias: "Detonar otro flujo",
            required: false,
            type: "radio",
            //Deben estar en el mismo orden
            options: ["sendMessage", "triggerFlow"],
            aliasOptions: ["Mensaje de negocio", "Detonar otro flujo"],
        },
        triggerFlow: {
            name: "triggerFlow",
            alias: "Detonar otro flujo",
            required: false,
        },
        sendMessage: {
            name: "sendMessage",
            alias: "Mensaje para el usuario final",
            required: false,
        },
    },
    responseAttributes: {
        alias: "Respuesta del servicio web",
        addNewResponseButton: {
            alias: "Agregar dato de respuesta al mensaje",
        },
        newAttributeField: {
            name: "newAttributeField",
            alias: "Nombre del campo",
        },
        newDescriptionField: {
            name: "newDescriptionField",
            alias: "Descripción",
        },
        responseObject: {
            name: "responseObject",
            alias: "Nombre de la respuesta",
            required: false,
        },
        attribute: {
            name: "attribute",
            alias: "Nombre del campo",
        },
        description: {
            name: "description",
            alias: "Descripción",
        },
        mediaType: {
            name: "mediaType",
            alias: "Tipo de multimedia",
        },
        saveAttribute: {
            name: "saveAttribute",
            alias: "Guardar respuesta del servicio",
        },
        tableType: {
            name: "tableType",
            alias: "Mostrar respuesta como:",
            detail: "Permite mostrar el resultado obtenido de la petición en formato de tabla, mensaje o contenido multimedia",
            //Deben estar en el mismo orden
            options: ["none", "links", "text", "multimedia", "flowCard", "customMessage"],
            aliasOptions: [
                "Tabla de Mensajes",
                "Tabla de enlaces",
                "Tabla de texto",
                "Multimedia",
                "Tarjeta de flujo",
                "Mensaje Personalizado",
            ],
        },
    },
};

exports.defaultResponseBranches = [
    {
        responseCode: {
            responseName: "",
            responseValue: "",
        },
        replyAction: {
            allowTriggerFlow: false,
            triggerFlow: null,
            sendMessage: "",
        },
        responseTable: {
            responseObject: "",
            responseAttributes: [],
            tableType: "none", //links or text
            iconTable: "ICO_PDF", //pending list for each icon
        },
    },
];

exports.entityTypes = [
    {
        name: "Entity Extractor",
        typeName: "Machine learned",
        typeDescription:
            "Las entidades de tipo Machine Learned son aprendidas del contexto. Utilice una entidad ML para identificar datos que no siempre están bien formateados pero tienen el mismo significado. Una entidad ML puede estar compuesta de subentidades más pequeñas, cada una de las cuales puede tener sus propias propiedades.",
    },
    {
        name: "Closed List Entity Extractor",
        typeName: "Lista",
        typeDescription:
            "Entidades de lista representan un conjunto cerrado y fijo de palabras relacionadas junto con sus sinónimos. Una entidad de lista no se aprende por máquina. Es una coincidencia de texto exacta. LUIS marca cualquier coincidencia con un elemento en cualquier lista como una entidad en la respuesta.",
    },
    {
        name: "Regex Entity Extractor",
        typeName: "Regex",
        typeDescription:
            "Las entidades de tipo regex extraen una entidad basada en un patrón de expresión regular que proporcione. Las entidades de expresiones regulares son adecuadas para los datos que están consistentemente formateados con cualquier variación que también sea consistente.",
    },
];

exports.appCulture = [
    { language: "Español (México)", code: "es-mx" },
    { language: "Español (España)", code: "es-es" },
    { language: "Inglés (Estados Unidos)", code: "en-us" },
    { language: "Inglés (Reino Unido)", code: "en-gb" },
    //   { language: "Francés (Canadá)", code: "fr-ca" },
    //   { language: "Francés (Francia)", code: "fr-fr" },
    //   { language: "Portugués (Brasil) ", code: "pt-br" },
    //   { language: "Árabe", code: "ar-ar" },
    //   { language: "Chino", code: "zh-cn" },
    //   { language: "Holandés", code: "nl-nl" },
    //   { language: "Alemán", code: "de-de" },
    //   { language: "Gujarati", code: "gu-in" },
    //   { language: "Hindi", code: "hi-in" },
    //   { language: "Italiano", code: "it-it" },
    //   { language: "Japonés", code: "ja-jp" },
    //   { language: "Coreano", code: "ko-kr" },
    //   { language: "Marathi", code: "mr-in" },
    //   { language: "Tamil", code: "ta-in" },
    //   { language: "Telugu", code: "te-in" },
    //   { language: "Turco", code: "tr-tr" },
];

exports.suscripcionStatusTranslation = {
    Active: "Activa",
    Inactive: "Inactiva",
};

exports.powerBiFilters = [
    {
        name: "section",
        options: ["preguntas frecuentes", "flujos conversacionales", "mensajes proactivos"],
        defaultValue: "preguntas frecuentes",
    },
    {
        name: "statusFAQ",
        options: ["correcta", "incorrecta"],
    },
    {
        name: "statusProcess",
        options: ["finalizado", "no finalizado"],
    },
];
