import * as React from "react";

function SvgEditarIcono(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38.28 38.79"
      {...props}
    >
      <defs>
        <style>
          {
            ".editar_icono_svg__aa5545f2-0caa-4e18-b3d8-b6e00de25654{fill:#27315d}"
          }
        </style>
      </defs>
      <g
        id="editar_icono_svg__b9fc1980-f3ad-427a-8877-9ed130d30a7d"
        data-name="Capa 2"
      >
        <g
          id="editar_icono_svg__b81cc38d-116b-4c94-bab9-1c2365396ec4"
          data-name="Capa 1"
        >
          <g
            id="editar_icono_svg__a7807f04-e084-45b6-b3c9-090a1e1daa3e"
            data-name="icono_editar"
          >
            <path
              className="editar_icono_svg__aa5545f2-0caa-4e18-b3d8-b6e00de25654"
              d="M30.89 0a6.19 6.19 0 012.61 1.85c1.33 1.37 2.72 2.69 4 4.1a3 3 0 01.07 4 36.06 36.06 0 01-2.71 2.55l-9.25-9.26C26.87 2.16 27.8.6 29.55 0zM23.81 5.08L33 14.26 14.35 32.92l-9.2-9.19zM0 38.79c1.11-4.44 2.35-8.66 3.43-13l9.11 9.15C8.25 36 4.46 37.68 0 38.79z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgEditarIcono;
