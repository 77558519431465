import React from 'react';
//MUI components
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, IconButton } from '@material-ui/core';
//Custom Portal Components
import Button from "./CustomButton";

class ButtonConfirmDialog extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    setOpen = val => {
        this.setState({
            open: val
        })
    }
    
    render() {
        const { title, children, onConfirm, buttonName, confirmName, cancelName, color, variant, size, endIcon, icon, tooltip, disabled } = this.props;
        return (
            <React.Fragment>
                {tooltip ?
                    <Tooltip title={tooltip}>
                        {icon ?
                            <IconButton disabled={disabled} color={color} component="span" size={size} onClick={() => this.setOpen(true)}>
                                {icon}
                            </IconButton>
                        :
                            <Button variant={variant} color={color} size={size} endIcon={endIcon} disabled={disabled} onClick={() => this.setOpen(true)} >
                                {buttonName ? buttonName : ""}
                            </Button>
                        }
                    </Tooltip> 
                :
                    <Button disabled={disabled} variant={variant} color={color} size={size} endIcon={endIcon} onClick={() => this.setOpen(true)} >
                        {buttonName ? buttonName : icon}
                    </Button>
                }
                
                <Dialog
                    open={this.state.open}
                    //onClose={() => this.setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>{children}</DialogContent>
                    <DialogActions>
                        {cancelName ? 
                            <Button variant="contained" onClick={() => this.setOpen(false)} >
                                { cancelName }
                            </Button>
                            : <React.Fragment/>
                        }
                        {confirmName ? 
                            <Button variant="contained" onClick={onConfirm ? () => { this.setOpen(false); onConfirm(); }: () => this.setOpen(false)} color="primary" autoFocus >
                                { confirmName }
                            </Button>
                            : <React.Fragment/>
                        }
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
};

export default ButtonConfirmDialog;