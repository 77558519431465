import React, { useContext, useState } from "react";
import { makeStyles /*, useTheme*/ } from "@material-ui/core/styles";
//import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Fab } from "@material-ui/core";
import Button from "../common/CustomButton";
//import SendIcon from '@material-ui/icons/Send';
//import PublishIcon from '@material-ui/icons/Publish';
import AppContext from "../../Context/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    fab: {
        //position: 'absolute',
        //bottom: theme.spacing(-3),
        //right: theme.spacing(0),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.other.green,
        "&:hover": {
            backgroundColor: theme.palette.other.green,
        },
        textTransform: "none",
        height: 36,
        padding: "0 30px",
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function FloatingSendButton(props) {
    // const { publishQnA } = props

    const {
        processesHasSavedChanges,
        messagesHasSavedChanges,
        regExHasSavedChanges,
        catalogsHasSavedChanges,
        settingsHasSavedChanges,
        fileDataToSendHasSavedChanges,
        fileDataToDeleteHasSavedChanges,
        applyAllChanges,
        publishQnaKB,
        checkUserPermission,
        faqHasSavedChanges,
        readOnly,
    } = useContext(AppContext);

    const enableSend =
        (processesHasSavedChanges ||
            messagesHasSavedChanges ||
            regExHasSavedChanges ||
            catalogsHasSavedChanges ||
            settingsHasSavedChanges ||
            fileDataToSendHasSavedChanges ||
            fileDataToDeleteHasSavedChanges ||
            faqHasSavedChanges) &&
        !readOnly;

    const classes = useStyles();
    //const theme = useTheme();
    //const matchesUpSm = useMediaQuery(theme.breakpoints.up('xs'));

    const [open, setOpen] = useState(false);

    const onSend = async () => {
        //Verificamos que se tenga los permisos.
        let responseCodeUserPermission = await checkUserPermission();
        if (responseCodeUserPermission === 1 || responseCodeUserPermission === 2) {
            //No tiene permiso entonces canelamos la publicación.
            return;
        }
        await publishQnaKB();
        await applyAllChanges();
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                {enableSend ? (
                    <Tooltip title="Públicar las modificaciones pendientes">
                        <Fab
                            variant="extended"
                            className={classes.fab}
                            onClick={() => setOpen(true)}
                        >
                            Publicar
                        </Fab>
                    </Tooltip>
                ) : (
                    <Button disabled variant="contained">
                        Publicar
                    </Button>
                )}
            </div>
            <Dialog open={open}>
                <DialogTitle id="alert-dialog-title">
                    ¿Estás seguro que deseas públicar y aplicar tus cambios?
                </DialogTitle>
                <DialogContent>
                    <p>Al confirmar la publicación se modificará la configuración de InteBot.</p>
                    <p>
                        Los servicios de InteBot tardarán unos momentos en estar disponibles
                        nuevamente.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setOpen(false)}>
                        No
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(false);
                            onSend();
                        }}
                        color="primary"
                        autoFocus
                    >
                        Sí
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
