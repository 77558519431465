import React, { Fragment, useState, useContext, useEffect } from "react";

// Material UI inputs
import {
    Grid,
    Tooltip,
    Breadcrumbs,
    Typography,
    TextField,
    MenuItem,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch,
    FormLabel,
    Checkbox,
    Button,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

//Custom Portal components
import AppContext from "../../../../Context/AppContext";
import FormModal from "../../../FormModalComponents/FormModal";
import AgregarIcono from "../../../../Icons/AgregarIcono";
import SimpleTable from "../../../common/Tables/SimpleTable";
import AddResponseObject from "./AddResponseObject";
import CustomButton from "../../../common/CustomButton";
import EditarIcono from "../../../../Icons/EditarIcono";
import IntebotCatalog from "../../../IntebotCatalog";

//External Libraries
import { MentionsInput, Mention } from "react-mentions"; // https://github.com/signavio/react-mentions

// styles
import "../Styles/Modals.css";

function AddResponseCase(props) {
    let {
        index,
        indexTable,
        classes,
        responseBranches,
        allProcessesNames,
        StyledMentionInput,
        autoCompleteList,
        responseCodeKeys,
        replyActionKeys,
        responseAttributesKeys,
        isNewCase,
        processName,
        addResponseBranch,
        updateResponseBranch,
    } = props;

    let tableTypeOptions = responseAttributesKeys.tableType.options;
    let tableTypeOptionsAlias = responseAttributesKeys.tableType.aliasOptions;

    const context = useContext(AppContext);
    const errorHelperKeys = IntebotCatalog.errorHelper;

    const formErrorsInit = {
        formHasErrors: true,
        formResponseErrors: {
            responseDescription: true,
        },
        errorHelperForm: {
            responseDescription: errorHelperKeys.emptyField,
        },
    };

    // states
    const [formErrors, setFormErrors] = useState(formErrorsInit);

    const [dynamicDialog, setDynamicDialog] = useState({
        openDialog: false,
        singleActionDialog: false,
        dialog: {
            title: "",
            message: "",
        },
    });

    const [responseCase, setResponseCase] = useState({
        replyAction: {
            allowTriggerFlow: false,
            sendMessage: "",
            triggerFlow: null,
        },
        responseCode: {
            responseDescription: "",
            mainResponseObject: "",
            responseName: "",
            responseValue: "",
        },
        responseTable: {
            responseAttributes: [],
            responseObject: "",
            tableType: "none",
            newAttributeField: "",
            newDescriptionField: "",
        },
    });

    const [formChanges, setFormChanges] = useState({
        openResponseObject: false,
        formHasChanges: false,
    });

    const [previousValue, setPreviousValue] = useState([]);

    const [isEditableDataSet, setIsEditableDataSet] = useState(false);

    useEffect(() => {
        if (isEditableDataSet) {
            // cuando finalizó de actualizar, revisar si hay errores
            searchFormErrors();
        }
    }, [isEditableDataSet]);

    useEffect(() => {
        let { formResponseErrors, errorHelperForm } = formErrors;

        let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
        let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));

        // si la tabla de respuesta del servicio está vacía, mostrar el error
        if (
            formChanges.openResponseObject &&
            responseCase.responseTable.responseAttributes.length === 0 &&
            responseCase.responseTable.tableType !== "customMessage"
        ) {
            formResponseErrorsCopy["responseAttributes"] = true;
            errorHelperFormCopy["responseAttributes"] = errorHelperKeys.emptyTable;

            setFormErrors({
                ...formErrors,
                formHasErrors: true,
                formResponseErrors: formResponseErrorsCopy,
                errorHelperForm: errorHelperFormCopy,
            });
        }

        searchFormErrors();
    }, [responseCase.responseTable.responseAttributes]);

    /**
     * @name modalIsOpen
     * @description asignar datos del elemento a modificar
     */
    const modalIsOpen = (isOpen) => {
        if (isOpen && !isNewCase) {
            let responseCode = responseBranches.map((item) => item.responseCode);
            let replyAction = responseBranches.map((item) => item.replyAction);
            let responseTable = responseBranches.map((item) => item.responseTable);

            let responseCaseTemp = JSON.parse(JSON.stringify(responseCase));
            responseCaseTemp.responseCode = responseCode[indexTable];
            responseCaseTemp.replyAction = replyAction[indexTable];
            responseCaseTemp.responseTable = responseTable[indexTable];

            if (
                responseCaseTemp.responseTable.responseObject ||
                responseCaseTemp.responseTable.responseAttributes.length > 0 ||
                responseCaseTemp.responseTable.customMessage !== ""
            ) {
                setFormChanges({
                    ...formChanges,
                    openResponseObject: true,
                });
            }
            setResponseCase(responseCaseTemp);
            setPreviousValue(responseCaseTemp);
            setIsEditableDataSet(true);
        }
    };

    const handleCloseDialog = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setDynamicDialog({
            ...dynamicDialog,
            openDialog: false,
            singleActionDialog: false,
        });
    };

    /**
     * @description funcion para editar o crear un nuevo caso de respuesta
     */
    function addNewResponseCase() {
        isNewCase
            ? addResponseBranch(responseCase)
            : updateResponseBranch(responseCase, indexTable);
        clearForm();
    }

    /**
     * @description Validar y guardar el form
     */
    async function handleChangesForm(targetName, targetValue, index, branch) {
        let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;
        //console.log(targetName, targetValue, index, branch);

        let responseCaseCopy = JSON.parse(JSON.stringify(responseCase));
        let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));
        let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
        let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
        let formChangesCopy = JSON.parse(JSON.stringify(formChanges));

        // Buscamos errores según el campo
        switch (targetName) {
            case "mainResponseObject":
                responseCaseCopy[branch][targetName] = targetValue; // guardamos el campo
                if (targetValue !== "") {
                    if (responseCaseCopy["responseCode"]["responseName"] === "") {
                        formResponseErrorsCopy["responseName"] = true;
                        errorHelperFormCopy["responseName"] = errorHelperKeys.emptyField;
                    }
                    if (responseCaseCopy["responseCode"]["responseValue"] === "") {
                        formResponseErrorsCopy["responseValue"] = true;
                        errorHelperFormCopy["responseValue"] = errorHelperKeys.emptyField;
                    }
                    break;
                }
                formResponseErrorsCopy["responseName"] = false;
                errorHelperFormCopy["responseName"] = errorHelperKeys.removeError;
                if (responseCaseCopy["responseCode"]["responseName"] === "") {
                    formResponseErrorsCopy["responseValue"] = false;
                    errorHelperFormCopy["responseValue"] = errorHelperKeys.removeError;
                }
                break;

            case "responseName":
                responseCaseCopy[branch][targetName] = targetValue; // guardamos el campo
                if (responseCaseCopy[branch]["mainResponseObject"]) {
                    if (targetValue === "") {
                        formResponseErrorsCopy[targetName] = true;
                        errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                        break;
                    }
                }
                if (targetValue !== "") {
                    if (responseCaseCopy["responseCode"]["responseValue"] === "") {
                        formResponseErrorsCopy["responseValue"] = true;
                        errorHelperFormCopy["responseValue"] = errorHelperKeys.emptyField;
                    }
                    formResponseErrorsCopy[targetName] = false;
                    errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                    break;
                }
                //sin errores
                formResponseErrorsCopy["responseValue"] = false;
                errorHelperFormCopy["responseValue"] = errorHelperKeys.removeError;
                break;

            case "responseValue":
                responseCaseCopy[branch][targetName] = targetValue; // guardamos el campo
                if (
                    responseCaseCopy[branch]["mainResponseObject"] ||
                    responseCaseCopy[branch]["responseName"]
                ) {
                    if (targetValue === "") {
                        formResponseErrorsCopy[targetName] = true;
                        errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                        break;
                    }
                }
                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;

            case "responseDescription":
                responseCaseCopy[branch][targetName] = targetValue; // guardamos el campo
                if (!targetValue) {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }
                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;

            case "allowTriggerFlow":
                responseCaseCopy[branch][targetName] = targetValue; // guardamos el campo

                if (targetValue === true) {
                    // si detonar otro flujo está disponible, validar triggerFlow
                    formResponseErrorsCopy["triggerFlow"] = true;
                    errorHelperFormCopy["triggerFlow"] = errorHelperKeys.emptyField;
                    break;
                }
                //sin errores
                formResponseErrorsCopy["triggerFlow"] = false;
                errorHelperFormCopy["triggerFlow"] = errorHelperKeys.removeError;
                break;

            case "triggerFlow":
                if (targetValue === "") {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }

                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;

            case "openResponseObject":
                formChangesCopy.openResponseObject = targetValue;

                // si acceder a la respuesta del servicio está disponible, validar tabla vacía
                if (targetValue) {
                    if (
                        responseCaseCopy["responseTable"]["responseAttributes"].length === 0 &&
                        responseCaseCopy["responseTable"]["tableType"] !== "customMessage"
                    ) {
                        formResponseErrorsCopy["responseAttributes"] = true;
                        errorHelperFormCopy["responseAttributes"] = errorHelperKeys.emptyTable;
                    }

                    if (
                        responseCaseCopy["responseTable"]["customMessage"] === "" &&
                        responseCaseCopy["responseTable"]["tableType"] === "customMessage"
                    ) {
                        formResponseErrorsCopy["customMessage"] = true;
                        errorHelperFormCopy["customMessage"] = errorHelperKeys.emptyField;
                    }

                    break;
                }

                //sin errores
                formResponseErrorsCopy["responseAttributes"] = false;
                errorHelperFormCopy["responseAttributes"] = errorHelperKeys.removeError;
                responseCaseCopy["responseTable"]["responseObject"] = "";
                responseCaseCopy["responseTable"]["responseAttributes"] = [];
                responseCaseCopy["responseTable"]["tableType"] = "none";
                break;

            // case "responseObject":
            //   responseCaseCopy[branch][targetName] = targetValue; // guardamos el campo

            //   // si los casos de respuesta estan activos, validar
            //   if (formChangesCopy && formChangesCopy.openResponseObject) {
            //     if (targetValue === "") {
            //       formResponseErrorsCopy[targetName] = true;
            //       errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
            //       break;
            //     }

            //     //sin errores
            //     formResponseErrorsCopy[targetName] = false;
            //     errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
            //     break;
            //   }
            //   break;

            case "customMessage":
                responseCaseCopy[branch][targetName] = targetValue; // guardamos el campo
                if (targetValue === "") {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;
            case "AddVariableButton":
                //Search for varibales in the template content
                const regexTextInBraces = /\{\{(.*?)\}\}/gm; //Regex para buscar texto dentro de llaves dobles {{HOLA}} -> HOLA
                let paramsInMsg = [];
                let match;
                //iteramos todo el string hasta que ya no encuentre concidencias
                while (
                    (match = regexTextInBraces.exec(responseCaseCopy[branch]["customMessage"])) !==
                    null
                ) {
                    if (match.index === regexTextInBraces.lastIndex) {
                        regexTextInBraces.lastIndex++;
                    }
                    //Guaramos el texto encontrado dentro de las llaves
                    paramsInMsg.push(match[1]);
                }

                let newParam = paramsInMsg.length + 1;

                //Verificamos que la cuenta sea correcta

                responseCaseCopy[branch][
                    "customMessage"
                ] = `${responseCaseCopy[branch]["customMessage"]} {{${newParam}}}`;
                break;

            case "tableType": {
                responseCaseCopy[branch][targetName] = targetValue;
                if (targetValue === "") {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }

                if (targetValue === "links") {
                    responseCaseCopy[branch]["iconTable"] = "ICO_PDF";
                } else {
                    delete responseCaseCopy[branch]["iconTable"];
                }

                // si la respuestas no se mostraran como contenido multimedia, el campo mediaType deberá ser null
                if (targetValue !== "multimedia") {
                    if (responseCaseCopy["responseTable"]["responseAttributes"].length > 1) {
                        responseCaseCopy["responseTable"]["responseAttributes"].forEach(
                            (element) => {
                                element.mediaType = null;
                            }
                        );
                        formResponseErrorsCopy["responseAttributes"] = false;
                        errorHelperFormCopy["responseAttributes"] = errorHelperKeys.removeError;
                    }
                } else {
                    // mostrar error cuando el campo tipo de multimedia este vacío
                    if (responseCaseCopy["responseTable"]["responseAttributes"].length > 1) {
                        let found = responseCaseCopy["responseTable"]["responseAttributes"].filter(
                            (element) => element.mediaType === null
                        );
                        if (found.length > 0) {
                            formResponseErrorsCopy["responseAttributes"] = true;
                            errorHelperFormCopy["responseAttributes"] =
                                errorHelperKeys.emptyTableField;
                        } else {
                            formResponseErrorsCopy["responseAttributes"] = false;
                            errorHelperFormCopy["responseAttributes"] = errorHelperKeys.removeError;
                        }
                    }
                }

                if (
                    targetValue === "links" ||
                    targetValue === "text" ||
                    targetValue === "flowCard"
                ) {
                    if (responseCaseCopy["responseTable"]["responseAttributes"].length > 1) {
                        // mostrar error cuando el campo encabezado este vacío
                        let found = responseCaseCopy["responseTable"]["responseAttributes"].filter(
                            (element) => element.description === ""
                        );
                        if (found.length > 0) {
                            formResponseErrorsCopy["responseAttributes"] = true;
                            errorHelperFormCopy["responseAttributes"] =
                                errorHelperKeys.emptyTableField;
                        } else {
                            formResponseErrorsCopy["responseAttributes"] = false;
                            errorHelperFormCopy["responseAttributes"] = errorHelperKeys.removeError;
                        }
                    }
                }

                if (targetValue === "customMessage") {
                    responseCaseCopy["responseTable"]["responseAttributes"] = [];
                    formResponseErrorsCopy["customMessage"] = true;
                    errorHelperFormCopy["customMessage"] = errorHelperKeys.emptyField;
                    formResponseErrorsCopy["responseAttributes"] = false;
                    errorHelperFormCopy["responseAttributes"] = errorHelperKeys.removeError;
                    break;
                }

                if (targetValue !== "customMessage") {
                    responseCaseCopy["responseTable"]["customMessage"] = "";

                    formResponseErrorsCopy["customMessage"] = false;
                    errorHelperFormCopy["customMessage"] = errorHelperKeys.removeError;

                    if (responseCaseCopy["responseTable"]["responseAttributes"].length === 0) {
                        formResponseErrorsCopy["responseAttributes"] = true;
                        errorHelperFormCopy["responseAttributes"] = errorHelperKeys.emptyTable;
                    }

                    break;
                }

                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;

                break;
            }

            default: // guardamos el campo
                responseCaseCopy[branch][targetName] = targetValue;
                break;
        }

        formChangesCopy.formHasChanges = true;

        //iteramos el objeto de errores
        const countErrors = Object.values(formResponseErrorsCopy).reduce((a, item) => a + item, 0);

        formHasErrorsCopy = countErrors > 0 ? true : false;

        setFormChanges(formChangesCopy);
        setFormErrors({
            ...formErrors,
            formHasErrors: formHasErrorsCopy,
            formResponseErrors: formResponseErrorsCopy,
            errorHelperForm: errorHelperFormCopy,
        });

        if (branch) {
            handleResponseBranches(
                targetName,
                targetValue,
                index,
                branch,
                undefined,
                undefined,
                responseCaseCopy
            );
        } else {
            setResponseCase(responseCaseCopy);
        }
    }

    /**
     * @name handleResponseBranches
     * @description manejar cambios realizados en el form y validar en caso de que el valor sea guardado en forma global
     */
    const handleResponseBranches = async (
        targetName,
        targetValue,
        index,
        branch,
        indexOfAttribute,
        formResponseObject,
        responseCaseModified
    ) => {
        const { globalValues, updateContextAttribute } = context;

        let globalValuesCopy = JSON.parse(JSON.stringify(globalValues));
        let tempObject = responseCaseModified
            ? JSON.parse(JSON.stringify(responseCaseModified))
            : JSON.parse(JSON.stringify(responseCase));

        //extraemos el response branch según la posición del campo y donde fue llamada la funcion
        let branchCopy = tempObject[branch];

        if (
            branch === "responseTable" &&
            typeof branchCopy["responseAttributes"][indexOfAttribute] !== "object"
        ) {
            branchCopy["responseAttributes"][indexOfAttribute] = formResponseObject;
        }

        //guardamos los cambios en su lugar correspondiente
        switch (targetName) {
            case "saveAttribute":
                // let attributeDescription = branchCopy["responseAttributes"][indexOfAttribute]["description"];
                let attributeDescription = formResponseObject.description;

                let indexAttributeDescription = globalValuesCopy.findIndex(
                    (globalValue) => globalValue.id === attributeDescription
                );

                if (targetValue) {
                    //antes del push validar si ese valor se repite
                    if (indexAttributeDescription > -1) {
                        setDynamicDialog({
                            ...dynamicDialog,
                            openDialog: true,
                            singleActionDialog: true,
                            dialog: {
                                title: "Valor global duplicado save",
                                message: "El valor de este campo se sobrescribirá",
                            },
                        });
                    } else {
                        globalValuesCopy.push({
                            flow: processName,
                            id: attributeDescription,
                            display: `${attributeDescription}`,
                            list: "Global",
                        });
                    }
                } else {
                    globalValuesCopy.splice(indexAttributeDescription, 1);
                }

                if (branch !== "responseTable" && !branchCopy["responseAttributes"]) {
                    branchCopy["responseAttributes"][indexOfAttribute][targetName] = targetValue;
                }

                await updateContextAttribute("globalValues", globalValuesCopy);
                break;
            case "attribute":
                break;
            case "description":
                // let saveAttribute = branchCopy["responseAttributes"][indexOfAttribute]["saveAttribute"];
                let saveAttribute = formResponseObject.saveAttribute;

                if (saveAttribute) {
                    let oldAttributeDescription =
                        tempObject[branch]["responseAttributes"][indexOfAttribute]["saveAttribute"];

                    let indexAttributeDescription = globalValuesCopy.findIndex(
                        (globalValue) => globalValue.id === oldAttributeDescription
                    );
                    let duplicateAttributeDescription = globalValuesCopy.findIndex(
                        (globalValue) => globalValue.id === targetValue
                    );

                    if (duplicateAttributeDescription > -1) {
                        setDynamicDialog({
                            ...dynamicDialog,
                            openDialog: true,
                            singleActionDialog: true,
                            dialog: {
                                title: "Valor global duplicado desc",
                                message: "El valor de este campo se sobrescribirá",
                            },
                        });
                        globalValuesCopy.splice(indexAttributeDescription, 1);
                    } else {
                        globalValuesCopy.splice(indexAttributeDescription, 1, {
                            flow: processName,
                            id: targetValue,
                            display: `${targetValue}`,
                            list: "Global",
                        });
                    }
                }

                if (branch !== "responseTable" && !branchCopy["responseAttributes"]) {
                    branchCopy["responseAttributes"][indexOfAttribute][targetName] = targetValue;
                }
                updateContextAttribute("globalValues", globalValuesCopy);

                break;
            case "allowTriggerFlow":
                branchCopy[targetName] = targetValue;
                if (targetValue === false) {
                    branchCopy["triggerFlow"] = null;
                    break;
                }
                break;
            default:
                branchCopy[targetName] = targetValue;
                break;
        }

        //guardamos los cambios
        setResponseCase(tempObject);
        setFormChanges({
            ...formChanges,
            formHasChanges: true,
        });
    };

    /**
     * @description buscar los errores en el form cuando se edita
     */
    const searchFormErrors = () => {
        let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;

        let responseCaseCopy = JSON.parse(JSON.stringify(responseCase));
        let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));
        let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
        let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));

        for (const branch in responseCaseCopy) {
            const branchObj = responseCaseCopy[branch];
            for (const key in branchObj) {
                let formValue = branchObj[key];
                switch (key) {
                    case "responseName":
                        if (formValue !== "") {
                            formResponseErrorsCopy[key] = false;
                            errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        }
                        break;

                    case "responseDescription":
                        if (formValue !== "") {
                            formResponseErrorsCopy[key] = false;
                            errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        }
                        break;

                    case "responseValue":
                        if (formValue !== "") {
                            formResponseErrorsCopy[key] = false;
                            errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        }
                        break;

                    case "responseObject":
                        if (formValue !== "") {
                            formResponseErrorsCopy[key] = false;
                            errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        }
                        break;

                    case "tableType":
                        if (formValue !== "") {
                            formResponseErrorsCopy[key] = false;
                            errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        }

                        if (formValue === "links" || formValue === "text") {
                            // mostrar error cuando el campo encabezado este vacío
                            let found = responseCaseCopy["responseTable"][
                                "responseAttributes"
                            ].filter((element) => element.description === "");
                            if (found.length > 0) {
                                formResponseErrorsCopy["responseAttributes"] = true;
                                errorHelperFormCopy["responseAttributes"] =
                                    errorHelperKeys.emptyTableField;
                            } else {
                                formResponseErrorsCopy["responseAttributes"] = false;
                                errorHelperFormCopy["responseAttributes"] =
                                    errorHelperKeys.removeError;
                            }
                        }

                        if (formValue === "multimedia") {
                            // mostrar error cuando el campo tipo de multimedia este vacío
                            let found = responseCaseCopy["responseTable"][
                                "responseAttributes"
                            ].filter((element) => element.mediaType === null);
                            if (found.length > 0) {
                                formResponseErrorsCopy["responseAttributes"] = true;
                                errorHelperFormCopy["responseAttributes"] =
                                    errorHelperKeys.emptyTableField;
                            } else {
                                formResponseErrorsCopy["responseAttributes"] = false;
                                errorHelperFormCopy["responseAttributes"] =
                                    errorHelperKeys.removeError;
                            }
                        }
                        break;
                    case "triggerFlow":
                        if (formValue !== "") {
                            formResponseErrorsCopy[key] = false;
                            errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        //iteramos el objeto de errores
        const countErrors = Object.values(formResponseErrorsCopy).reduce((a, item) => a + item, 0);

        formHasErrorsCopy = countErrors > 0 ? true : false;

        setFormErrors({
            ...formErrors,
            formHasErrors: formHasErrorsCopy,
            formResponseErrors: formResponseErrorsCopy,
            errorHelperForm: errorHelperFormCopy,
        });
        setIsEditableDataSet(false);
    };

    /**
     * @name addNewObjectResponse
     * @description Agregar nuevo objeto de respuesta
     */
    const addNewObjectResponse = (responseObject) => {
        let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;
        let responseCaseCopy = JSON.parse(JSON.stringify(responseCase));
        let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));
        let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
        let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
        responseCaseCopy["responseTable"]["responseAttributes"].push(responseObject);
        formResponseErrorsCopy["responseAttributes"] = false;
        errorHelperFormCopy["responseAttributes"] = errorHelperKeys.removeError;

        //iteramos el objeto de errores
        const countErrors = Object.values(formResponseErrorsCopy).reduce((a, item) => a + item, 0);

        formHasErrorsCopy = countErrors > 0 ? true : false;
        // console.log('formResponseErrorsCopy :>> ', formResponseErrorsCopy);
        setFormErrors({
            ...formErrors,
            formHasErrors: formHasErrorsCopy,
            formResponseErrors: formResponseErrorsCopy,
            errorHelperForm: errorHelperFormCopy,
        });
        setResponseCase(responseCaseCopy);
        setFormChanges({
            ...formChanges,
            formHasChanges: true,
        });
    };

    /**
     * @name updateObjectResponse
     * @description Editar objeto de respuesta
     */
    const updateObjectResponse = (responseObject, indexTable) => {
        let responseCaseCopy = JSON.parse(JSON.stringify(responseCase));

        responseCaseCopy["responseTable"]["responseAttributes"][indexTable] = responseObject;

        setResponseCase(responseCaseCopy);
        setFormChanges({
            ...formChanges,
            formHasChanges: true,
        });
    };

    /**
     * @name removeObjectResponse
     * @description Eliminar objeto de respuesta de la tabla
     */
    const removeObjectResponse = (indexTable) => {
        let responseCaseCopy = JSON.parse(JSON.stringify(responseCase));
        responseCaseCopy["responseTable"]["responseAttributes"].splice(indexTable, 1);

        setResponseCase(responseCaseCopy);
        setFormChanges({
            ...formChanges,
            formHasChanges: true,
        });
    };

    /**
     * @name clearForm
     * @description al cerrar modal, limpiar form
     */
    const clearForm = () => {
        setFormChanges({
            openResponseObject: false,
            formHasChanges: false,
        });
        setFormErrors(formErrorsInit);
        setResponseCase({
            replyAction: {
                allowTriggerFlow: false,
                sendMessage: "",
                triggerFlow: null,
            },
            responseCode: {
                responseDescription: "",
                mainResponseObject: "",
                responseName: "",
                responseValue: "",
            },
            responseTable: {
                responseAttributes: [],
                responseObject: "",
                tableType: "none",
                newAttributeField: "",
                newDescriptionField: "",
            },
        });
    };

    /**
     * @name undoChanges
     * @description al cancelar, deshacer los cambios
     */
    const undoChanges = () => {
        if (isNewCase) {
            clearForm();
            return;
        }

        let isOpenSwitch = false;

        if (
            previousValue.responseTable.responseObject ||
            previousValue.responseTable.responseAttributes.length > 0
        ) {
            isOpenSwitch = true;
        }
        setResponseCase(previousValue);
        setFormChanges({
            ...formChanges,
            formHasChanges: false,
            openResponseObject: isOpenSwitch,
        });
        setIsEditableDataSet(true);
    };

    return (
        <Fragment>
            {/* Modales de acciones, pedir al usuario que confirme una acción */}
            <Dialog open={dynamicDialog.openDialog} onClose={() => handleCloseDialog()}>
                <DialogTitle>{dynamicDialog.dialog.title}</DialogTitle>
                <DialogContent>{dynamicDialog.dialog.message}</DialogContent>
                {dynamicDialog.singleActionDialog ? (
                    <DialogActions>
                        <CustomButton onClick={() => handleCloseDialog()} color="primary">
                            Ok
                        </CustomButton>
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <CustomButton onClick={() => handleCloseDialog()} color="primary">
                            No
                        </CustomButton>
                        <CustomButton
                            onClick={() => {
                                handleCloseDialog();
                            }}
                            color="primary"
                            autoFocus
                        >
                            Sí
                        </CustomButton>
                    </DialogActions>
                )}
            </Dialog>
            <FormModal
                modalTitle={
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                        <Typography color="textPrimary">
                            {isNewCase
                                ? "Agregar escenario de respuesta"
                                : "Editar escenario de respuesta"}
                        </Typography>
                    </Breadcrumbs>
                }
                buttonType={isNewCase ? "link" : null}
                buttonTooltip={
                    isNewCase
                        ? "Abrir modal para agregar escenario de respuesta"
                        : "Editar escenario"
                }
                justIcon={isNewCase ? null : <EditarIcono width="20px" height="20px" />}
                buttonName={isNewCase ? "Agregar escenario de respuesta" : null}
                startIcon={isNewCase ? <AgregarIcono width="15px" height="15px" /> : null}
                alignButtonRight={isNewCase ? true : null}
                hasChanges={formChanges.formHasChanges}
                hasErrors={formErrors.formHasErrors}
                isOpen={(e) => (e ? modalIsOpen(e) : null)}
                onCancel={() => undoChanges()}
                onSave={() => addNewResponseCase()}
                disableSaveOnError={true}
            >
                <FormLabel className="modal-section-title">Estado de la respuesta</FormLabel>
                {Object.keys(responseCase).length > 0 ? (
                    <Grid container justifyContent="space-around">
                        <Grid item xs={12}>
                            <Grid item xs={12} md={12}>
                                <label className="top-label-field">
                                    <span style={{ color: "red" }}>* </span>
                                    Descripción del escenario
                                </label>
                                <TextField
                                    name={"responseDescription"}
                                    value={responseCase.responseCode.responseDescription}
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={formErrors.formResponseErrors.responseDescription}
                                    onChange={(event) =>
                                        handleChangesForm(
                                            event.target.name,
                                            event.target.value,
                                            index,
                                            "responseCode"
                                        )
                                    }
                                    helperText={formErrors.errorHelperForm.responseDescription}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <label className="top-label-field">Nombre de la respuesta</label>
                                <TextField
                                    name={"mainResponseObject"}
                                    value={responseCase.responseCode.mainResponseObject}
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={formErrors.formResponseErrors.mainResponseObject}
                                    onChange={(event) =>
                                        handleChangesForm(
                                            event.target.name,
                                            event.target.value,
                                            index,
                                            "responseCode"
                                        )
                                    }
                                    helperText={formErrors.errorHelperForm.mainResponseObject}
                                />
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <label className="top-label-field">
                                        <span style={{ color: "red" }}>
                                            {responseCase.responseCode.mainResponseObject
                                                ? "* "
                                                : ""}
                                        </span>
                                        {responseCodeKeys.responseName.alias}
                                    </label>
                                    <TextField
                                        name={"responseName"}
                                        value={responseCase.responseCode.responseName}
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        error={formErrors.formResponseErrors.responseName}
                                        onChange={(event) =>
                                            handleChangesForm(
                                                event.target.name,
                                                event.target.value,
                                                index,
                                                "responseCode"
                                            )
                                        }
                                        helperText={formErrors.errorHelperForm.responseName}
                                    />
                                </Grid>
                                <Grid item>
                                    <label className="top-label-field">
                                        <span style={{ color: "red" }}>
                                            {responseCase.responseCode.mainResponseObject ||
                                            responseCase.responseCode.responseName
                                                ? "* "
                                                : ""}
                                        </span>
                                        {responseCodeKeys.responseValue.alias}
                                    </label>
                                    <TextField
                                        name={responseCodeKeys.responseValue.name}
                                        value={responseCase.responseCode.responseValue}
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) =>
                                            handleChangesForm(
                                                event.target.name,
                                                event.target.value,
                                                index,
                                                "responseCode"
                                            )
                                        }
                                        error={formErrors.formResponseErrors.responseValue}
                                        helperText={formErrors.errorHelperForm.responseValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* ======= REPLY ACTION FIELDS ====== */}
                        <Grid item xs={12} className="reply-action-container">
                            {/* ===send message=== */}
                            <div>
                                <label className="top-label-field">
                                    {replyActionKeys.sendMessage.alias}
                                </label>
                                <MentionsInput
                                    name={replyActionKeys.sendMessage.name}
                                    value={responseCase.replyAction.sendMessage}
                                    onChange={(event) =>
                                        handleChangesForm(
                                            replyActionKeys.sendMessage.name,
                                            event.target.value,
                                            index,
                                            "replyAction"
                                        )
                                    }
                                    style={StyledMentionInput}
                                    allowSpaceInQuery
                                    allowSuggestionsAboveCursor={true}
                                >
                                    <Mention
                                        //Estilo del etiqueta
                                        style={{
                                            backgroundColor: "#E0E0E0",
                                            padding: "3px",
                                            border: "1px solid #E0E0E0",
                                            height: "32px",
                                            borderRadius: "16px",
                                            margin: "-4px",
                                        }}
                                        trigger="@"
                                        //formato de la variable en el state
                                        markup="{{__id__}}"
                                        //formato de la variable en visualmente
                                        displayTransform={(id) => `${id}`}
                                        //filtrado de datos
                                        data={autoCompleteList}
                                        //Html personalizado de cada elemento individual de la lista
                                        renderSuggestion={(suggestion) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        margin: "0",
                                                    }}
                                                >
                                                    {suggestion.display}
                                                </p>
                                                <span
                                                    style={{ color: "#B2B2B2", fontSize: "13px" }}
                                                >
                                                    &nbsp;• {suggestion.list}
                                                </span>
                                            </div>
                                        )}
                                    />
                                </MentionsInput>
                            </div>
                        </Grid>
                        {/* ======= RESPONSE ATTRIBUTES FIELDS ====== */}
                        <Grid container className="response-attributes-table-container">
                            <Grid item className="">
                                <FormControlLabel
                                    className="format-label modal-section-title"
                                    control={
                                        <Switch
                                            name="openResponseObject"
                                            color="primary"
                                            checked={formChanges.openResponseObject}
                                            onChange={(e) =>
                                                handleChangesForm(e.target.name, e.target.checked)
                                            }
                                        />
                                    }
                                    label={"Acceder a la respuesta del servicio"}
                                />
                            </Grid>
                        </Grid>
                        {formChanges.openResponseObject ? (
                            <Grid container>
                                {/* response object field */}
                                <Grid item xs={12} style={{ paddingRight: "5px" }}>
                                    <label className="top-label-field">
                                        {/* <span style={{ color: "red" }}>* </span> */}
                                        {responseAttributesKeys.responseObject.alias}
                                    </label>
                                    <TextField
                                        name={responseCodeKeys.responseObject.name}
                                        value={responseCase.responseTable.responseObject}
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) =>
                                            handleChangesForm(
                                                event.target.name,
                                                event.target.value,
                                                index,
                                                "responseTable"
                                            )
                                        }
                                        style={{ marginBottom: "10px" }}
                                        error={formErrors.formResponseErrors.responseObject}
                                        helperText={formErrors.errorHelperForm.responseObject}
                                    />
                                </Grid>
                                {/* select show results as table or message*/}
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                        className="top-label-field"
                                    >
                                        <Typography
                                            className={classes.Typography}
                                            name={responseAttributesKeys.tableType.name}
                                            style={{
                                                marginRight: "4px",
                                            }}
                                        >
                                            {" "}
                                            {responseAttributesKeys.tableType.alias}
                                        </Typography>
                                        <Tooltip title={responseAttributesKeys.tableType.detail}>
                                            <HelpOutlineIcon color="primary" fontSize="small" />
                                        </Tooltip>
                                    </div>
                                    <TextField
                                        select
                                        name={responseAttributesKeys.tableType.name}
                                        value={responseCase.responseTable.tableType || "none"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) =>
                                            handleChangesForm(
                                                event.target.name,
                                                event.target.value,
                                                index,
                                                "responseTable"
                                            )
                                        }
                                        error={formErrors.formResponseErrors.tableType}
                                        helperText={formErrors.errorHelperForm.tableType}
                                    >
                                        {tableTypeOptions.length !== 0 ? (
                                            tableTypeOptions.map((option, i) => (
                                                <MenuItem key={i} value={option}>
                                                    {tableTypeOptionsAlias[i]}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value={""}>
                                                No hay opciones disponibles
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                {
                                    <Grid
                                        style={{
                                            display:
                                                responseCase.responseTable.tableType === "links" ||
                                                responseCase.responseTable.tableType === "text"
                                                    ? "block"
                                                    : "none",
                                        }}
                                        container
                                        justifyContent="flex-end"
                                    ></Grid>
                                }
                                {/* table for service response */}
                                {responseCase.responseTable.tableType !== "customMessage" && (
                                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                                        <SimpleTable
                                            headers={
                                                responseCase.responseTable.tableType === "none" ||
                                                responseCase.responseTable.tableType ===
                                                    "multimedia"
                                                    ? ["Mensaje", "Campo"]
                                                    : ["Encabezado", "Campo"]
                                            }
                                            values={[
                                                ...responseCase.responseTable.responseAttributes,
                                            ]}
                                            type="service-response-table"
                                            functionDelete={(indexTable) => {
                                                removeObjectResponse(indexTable);
                                            }}
                                            functionEdit={updateObjectResponse}
                                            index={index}
                                            responseAttributesKeys={responseAttributesKeys}
                                            responseAttributes={
                                                responseCase.responseTable.responseAttributes
                                            }
                                            responseTable={responseCase.responseTable}
                                            handleResponseBranches={handleResponseBranches}
                                            tableType={responseCase.responseTable.tableType}
                                        />
                                    </Grid>
                                )}
                                {responseCase.responseTable.tableType === "customMessage" && (
                                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                                        <label className="top-label-field">
                                            <span style={{ color: "red" }}>* </span>
                                            Mensaje Personalizado
                                        </label>
                                        <TextField
                                            name={"customMessage"}
                                            value={responseCase.responseTable.customMessage}
                                            multiline
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            onChange={(event) =>
                                                handleChangesForm(
                                                    event.target.name,
                                                    event.target.value,
                                                    index,
                                                    "responseTable"
                                                )
                                            }
                                            error={formErrors.formResponseErrors.customMessage}
                                            helperText={formErrors.errorHelperForm.customMessage}
                                        />
                                    </Grid>
                                )}
                                {/* modal add response */}
                                <span style={{ color: "red" }}>
                                    {formErrors.errorHelperForm.responseAttributes}
                                </span>
                                {responseCase.responseTable.tableType !== "customMessage" && (
                                    <Grid
                                        container
                                        className="responseAttributesTable__section--justify"
                                    >
                                        <AddResponseObject
                                            index={index}
                                            addNewObjectResponse={addNewObjectResponse}
                                            responseAttributesKeys={responseAttributesKeys}
                                            responseAttributes={
                                                responseCase.responseTable.responseAttributes
                                            }
                                            responseTable={responseCase.responseTable}
                                            handleResponseBranches={handleResponseBranches}
                                            isNewResponse={true}
                                            tableType={responseCase.responseTable.tableType}
                                            label={
                                                responseCase.responseTable.tableType === "none"
                                                    ? {
                                                          newDescriptionField: "Mensaje",
                                                          newAttributeField: "Campo",
                                                      }
                                                    : responseCase.responseTable.tableType ===
                                                      "multimedia"
                                                    ? {
                                                          newDescriptionField: "Mensaje",
                                                          newAttributeField: "Campo",
                                                          mediaType: "Tipo de multimedia",
                                                      }
                                                    : {
                                                          newDescriptionField: "Encabezado",
                                                          newAttributeField: "Campo",
                                                      }
                                            }
                                        ></AddResponseObject>
                                    </Grid>
                                )}

                                {responseCase.responseTable.tableType === "customMessage" && (
                                    <Grid
                                        container
                                        className="responseAttributesTable__section--justify"
                                    >
                                        <Tooltip title="Agregar variable al contenido del template">
                                            <Button
                                                name="AddVariableButton"
                                                startIcon={
                                                    <AgregarIcono width="15px" height="15px" />
                                                }
                                                color="primary"
                                                size="small"
                                                style={{ marginLeft: "3px" }}
                                                onClick={() =>
                                                    handleChangesForm(
                                                        "AddVariableButton",
                                                        "{{1}}",
                                                        index,
                                                        "responseTable"
                                                    )
                                                }
                                            >
                                                Agregar variable
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        ) : null}
                        {/* ===checkbox trigger flow=== */}
                        <Grid container>
                            <FormControlLabel
                                className="format-label modal-section-title"
                                control={
                                    <Switch
                                        name={replyActionKeys.allowTriggerFlow.name}
                                        color="primary"
                                        checked={responseCase.replyAction.allowTriggerFlow}
                                        onChange={(event) =>
                                            handleChangesForm(
                                                event.target.name,
                                                event.target.checked,
                                                index,
                                                "replyAction"
                                            )
                                        }
                                    />
                                }
                                label={replyActionKeys.allowTriggerFlow.alias}
                            />
                            {/* ===trigger flow=== */}
                            {responseCase.replyAction.allowTriggerFlow !== null &&
                            responseCase.replyAction.allowTriggerFlow !== false ? (
                                <Grid container>
                                    <label className="top-label-field">
                                        <span style={{ color: "red" }}>* </span>
                                        {replyActionKeys.triggerFlow.alias}
                                    </label>
                                    <TextField
                                        select
                                        name={replyActionKeys.triggerFlow.name}
                                        value={responseCase.replyAction.triggerFlow || ""}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) =>
                                            handleChangesForm(
                                                event.target.name,
                                                event.target.value,
                                                index,
                                                "replyAction"
                                            )
                                        }
                                        error={formErrors.formResponseErrors.triggerFlow}
                                        helperText={formErrors.errorHelperForm.triggerFlow}
                                    >
                                        {allProcessesNames.length !== 0 ? (
                                            allProcessesNames.map((option, i) => (
                                                <MenuItem key={i} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value={""}>
                                                No hay opciones disponibles
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                ) : null}
            </FormModal>
        </Fragment>
    );
}

export default AddResponseCase;
