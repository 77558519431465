import React from 'react';

//MUI components
import { Avatar } from '@material-ui/core/';

//Custom portal components
import AppContext from '../../Context/AppContext';

const FAQTestUserMessage = (props) => {

    const { question } = props;
    return (
        <>
            <div className='faq-test-user-message-container'>
                <span>{question}</span>
            </div>
        </>
    );
}

export default FAQTestUserMessage;
