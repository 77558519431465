import React from 'react';

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
    Grid,
    TextField,
    MenuItem,
    Button,
    IconButton
} from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';

//Custom portal components
import CustomButton from "../common/CustomButton";

//Icons
import CloseIcon from '@material-ui/icons/Close';

//Footer styles
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        padding: theme.spacing(2),
        margin: '0',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
}))

const Dialog = withStyles((theme) => ({
    root: {
        marginTop: '56px',
        [theme.breakpoints.up('md')]: {
            marginTop: '64px',
        },
    },
}))(MuiDialog)

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle)

const DialogContent = withStyles((theme) => ({
    root:{
        display:'flex',
        justifyContent:'center'
    }

}))(MuiDialogContent)

const AddNewPermissionModal = (props) => {

    const { openNewPermissionModal, newUserRole, newUserRoleError, handleNewUser, newUserRoleErrorHelper, sendNewUserInvitation, askConfirm, closeInviteNewUser, permissionsOptions } = props

    const classes = useStyles();

    return (
        <div>
            <Dialog open={openNewPermissionModal}>
                <DialogTitle disableTypography>
                    <h2>Agregar usuario</h2>
                    <IconButton onClick={() => {
                        newUserRole.email ? askConfirm() : closeInviteNewUser()
                    }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className='manage-permissions-new-user-container'>
                            <div className='manage-permissions-new-email' >
                                <label className='top-label-field'>Correo Electrónico</label>
                                <TextField
                                    name={'email'}
                                    value={newUserRole.email}
                                    fullWidth
                                    variant='outlined'
                                    size="small"
                                    error={newUserRoleError.email}
                                    onChange={(event) => handleNewUser(event.target.name, event.target.value)}
                                    helperText={newUserRoleErrorHelper.email}
                                />
                            </div>
                            <div className='manage-permissions-new-role'>
                                <label className='top-label-field'>Permiso</label>
                                <TextField
                                    select
                                    name="role"
                                    value={newUserRole.role}
                                    className='permissions-selector'
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => handleNewUser(event.target.name, event.target.value)}
                                >
                                    {
                                        permissionsOptions.map((option, i) =>
                                            <MenuItem key={i} value={option.value}>{option.name}</MenuItem>
                                        )
                                    }
                                </TextField>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className={classes.root}>
                        <Grid container alignContent="flex-end">
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <CustomButton 
                                            disabled={!newUserRole.email}
                                            onClick={() => sendNewUserInvitation()}
                                            variant="contained" 
                                        > 
                                            Invitar Usuario 
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </DialogActions>
        </Dialog>
        </div>
    );
}

export default AddNewPermissionModal;
