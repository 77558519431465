import React, { useState, useContext } from "react";
//MUI components
import { IconButton, TextField, Grid, Tooltip, CircularProgress } from "@material-ui/core";

import { errorHelper } from "../IntebotCatalog";

import TestQueryList from "./TestQueryList";

import AgregarIcono from "../../Icons/AgregarIcono";
import { actionsPLNManagement } from "../../components/DataFunctions";
import AppContext from "../../Context/AppContext";
import SendIcon from "@material-ui/icons/Send";

//Styles
import "./styles/TestPLNTraining.css";

const TestPLNTraining = (props) => {
    const [testQuery, setTestQuery] = useState("");
    const [testErrors, setTestErrors] = useState({});
    const [testErrorHelper, setTestErrorHelper] = useState({});
    const [queryHistroy, setQueryHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorInFetch, setErrorInFetch] = useState(false);

    const { getStorageData } = useContext(AppContext);
    const storageData = getStorageData(["exclusiveServices", "appId"]);

    const { entityList } = props;

    const getQueryTestInfo = async (testQuery) => {
        if (!testQuery) {
            setTestErrors(true);
            setTestErrorHelper(errorHelper.emptyField);
            return;
        }
        let queryHistroyCopy = JSON.parse(JSON.stringify(queryHistroy));
        //conectar al servicio
        setLoading(true);
        setErrorInFetch(false);
        let data = {
            query: testQuery,
        };
        if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
            data["appID"] = storageData.appId;
        }
        let res = await actionsPLNManagement(data, "testTraining");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        setLoading(false);
        switch (responseCode) {
            case 0:
                let testData = res.data.data;
                const { query, score, intent, entities } = testData;
                let exampleId = queryHistroyCopy.length + 1;
                //dividimos el testQuery en signos de puntuacion y palabras
                const splitRegex = /([^\wáéíóúÁÉÍÓÚüÜñÑ]|\s+)/g;
                //creamos el arrgelo de palabras tokenizadas y quitamos los espacios en blanco
                let tokenizedText = testQuery
                    .split(splitRegex)
                    .filter((word) => word.trim() !== "")
                    .map((word) => word.trim());
                let exampleObj = {
                    text: query,
                    tokenizedText: tokenizedText,
                    id: exampleId,
                    score: score,
                    intent: intent,
                    examples: entities,
                };
                queryHistroyCopy.unshift(exampleObj);
                setTestQuery("");
                break;
            default:
                setErrorInFetch(true);
                break;
        }
        setQueryHistory(queryHistroyCopy);
    };

    return (
        <div className="test-pln-container">
            <div className="test-pln-chatbox">
                <TextField
                    label={loading ? "Cargando..." : "Escribe una expresión de prueba"}
                    name="testQuery"
                    value={testQuery}
                    size="small"
                    type="small"
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            getQueryTestInfo(testQuery);
                        }
                    }}
                    onChange={(event) => setTestQuery(event.target.value)}
                    fullWidth
                    variant="outlined"
                    error={testErrors["testQuery"]}
                    helperText={testErrorHelper["testQuery"]}
                />
                <Tooltip title="Probar expresión">
                    <IconButton
                        disabled={loading}
                        color="primary"
                        size="small"
                        onClick={() => getQueryTestInfo(testQuery)}
                    >
                        <SendIcon width="20px" height="20px" />
                    </IconButton>
                </Tooltip>
            </div>
            <main>
                {errorInFetch && <div>"Hubo un error al probar el PLN, Intenta nuevamente"</div>}
                {loading ? (
                    <div className="center-loading-icon">
                        <CircularProgress />
                    </div>
                ) : (
                    <TestQueryList queryHistroy={queryHistroy} entityList={entityList} />
                )}
            </main>
        </div>
    );
};

export default TestPLNTraining;
