import React from 'react'

//MUI components
import {
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core/';

const EntitiesTableComponent = (props) => {
    const { name, entityType, selectedEntity, setSelectedEntity } = props


    const getEntityTypeName = (entityType) => {
        switch (entityType) {
            case "Entity Extractor":
                return "Machine learned"
            case "Closed List Entity Extractor":
                return "Lista"
            case "Regex Entity Extractor":
                return "Expresión regular"
            default:
                return ''
        }
    }

    return (
        <div className='intent-table-component' >
            <RadioGroup value={selectedEntity} onChange={(e) => setSelectedEntity(e.target.value)}>
                <FormControlLabel value={name} control={<Radio color="primary"/>} />
            </RadioGroup>
            {/* OPEN MODAL BUTTON FOR EDIT INTENT */}
            <h3 className='entities-name-header'>{name}</h3>
            <h3 className='entities-type-header'>
                {
                    getEntityTypeName(entityType)
                }
            </h3>
        </div>
    )
}

export default EntitiesTableComponent;
