import React from 'react';

const FAQTestIntebotReply = (props) => {

    const { answer, score } = props;
    return (
        <>
            <div className='faq-test-intebot-message-container'>
                <span>{answer}</span>
                <hr />
                {score && <span>Coincidencia: {score}%</span>}
            </div>
        </>
    );
}

export default FAQTestIntebotReply;
