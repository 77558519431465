import React, { useState, useEffect, useContext, useRef } from "react";

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
    IconButton,
    TextField,
    Grid,
    CircularProgress as MuiCircularProgress,
    Tooltip,
    Button,
    MenuItem,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

//Custom portal components
import CustomButton from "../common/CustomButton";
import { whatsAppCampaignActions, blobStorage } from "../DataFunctions";
import AppContext from "../../Context/AppContext";
import IntebotCatalog from "../IntebotCatalog";

//External libraries
import isAlphanumeric from "validator/lib/isAlphanumeric"; //https://www.npmjs.com/package/validator
import isURL from "validator/lib/isURL";
import EmojiPicker from "emoji-picker-react";
import { DropzoneArea } from "material-ui-dropzone"; // https://yuvaleros.github.io/material-ui-dropzone/
import Swal from "sweetalert2";

//Icons
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import AgregarIcono from "../../Icons/AgregarIcono";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import CodeIcon from "@material-ui/icons/Code";
//Icons
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";

//CSS sheets
import "./Styles/CreateNewTemplateForm.css";

//Footer styles
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        backgroundColor: "white",
        padding: theme.spacing(2),
        margin: "0",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
}));

const Dialog = withStyles((theme) => ({
    root: {
        marginTop: "56px",
        [theme.breakpoints.up("md")]: {
            marginTop: "64px",
        },
    },
}))(MuiDialog);

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
    },
}))(MuiDialogContent);

const errorHelperKeys = IntebotCatalog.errorHelper;
const birdAcceptedFiles = IntebotCatalog.birdAcceptedFiles;
const CampaignsHeaders = IntebotCatalog.CampaignsHeaders;
const fileTypesExtentions = IntebotCatalog.fileTypesExtentions;

//=======================================================================
export default function CreateNewTemplateForm(props) {
    const CircularProgressTemplateContent = withStyles((theme) => ({
        circle: {
            color:
                templateContentLength >= 1000
                    ? "#E42922"
                    : templateContentLength >= 900
                    ? "#e48922"
                    : "#373788",
        },
    }))(MuiCircularProgress);

    const CircularProgressTemplateName = withStyles((theme) => ({
        circle: {
            color:
                templateNameLength >= 50
                    ? "#E42922"
                    : templateNameLength >= 40
                    ? "#e48922"
                    : "#373788",
        },
    }))(MuiCircularProgress);

    const context = useContext(AppContext);
    const { updateContextAttribute } = context;

    const [state, setState] = useState({
        newTemplate: {
            templateName: "",
            templateContent: "",
            header: {
                type: "none",
                url: "",
            },
            buttons: [],
        },
        templateErrors: {
            templateName: true,
            templateContent: true,
            buttons: [],
        },
        errorHelper: {
            templateName: errorHelperKeys.emptyField,
            templateContent: errorHelperKeys.emptyField,
            buttons: [],
        },
        templateContentLength: 0,
        templateNameLength: 0,
        countTemplateErrors: 2,
        openLoading: false,
        Loading: false,
        LoadingSucceeded: false,
        confirmDialog: false,
        dialogMessage: "",
        dialogTitle: "",
        hasChanges: false,
        uploadedFile: null,
        fileBase64: null,
    });

    const onCancel = () => {
        setState({
            ...state,
            //Cerramos el dialog
            openLoading: false,
            LoadingSucceeded: false,
            LoadingFailed: false,
            //Reiniciamos el state
            newTemplate: {
                templateName: "",
                templateContent: "",
                header: {
                    type: "none",
                    url: "",
                },
                buttons: [],
            },
            templateErrors: {
                templateName: true,
                templateContent: true,
                buttons: [],
            },
            errorHelper: {
                templateName: errorHelperKeys.emptyField,
                templateContent: errorHelperKeys.emptyField,
                buttons: [],
            },
            templateContentLength: 0,
            templateNameLength: 0,
            countTemplateErrors: 2,
            hasChanges: false,
            uploadedFile: null,
        });
    };

    const { hasChanges } = state;

    const [openEmojiMenu, setOpenEmojiMenu] = useState(false);
    const templateContentRef = useRef(null);

    useEffect(() => {
        updateContextAttribute("sendCampaingsHasChanges", hasChanges);
    }, [hasChanges]);

    const handleNewTemplateForm = async (targetName, targetValue, index) => {
        const { newTemplate, templateErrors, errorHelper, uploadedFile, fileBase64 } = state;
        let uploadedFileCopy = JSON.parse(JSON.stringify(uploadedFile));
        let fileBase64Copy = JSON.parse(JSON.stringify(fileBase64));

        //guardamos el valor
        switch (targetName) {
            case "templateName":
                //Guardamos únicamente si el valor es menor a 50
                if (targetValue.length <= 50) {
                    //Evitamos que el cliente ponga espacios y mayusculas
                    newTemplate["templateName"] = targetValue.replace(/ /g, "_").toLowerCase();
                    //preguntamos si es un nombre valido
                    let validName = isAlphanumeric(newTemplate["templateName"], "en-US", {
                        ignore: "_",
                    }); //solo números, letras y _
                    if (!validName) {
                        //indicamos que el nombre es incorrecto
                        templateErrors["templateName"] = true;
                        errorHelper["templateName"] = errorHelperKeys.templateInvalidName;
                        break;
                    }
                    //Indicamos que el nombre esta repetido
                    if (allTemplatesNames.includes(newTemplate["templateName"])) {
                        templateErrors["templateName"] = true;
                        errorHelper["templateName"] = errorHelperKeys.templateNameTaken;
                        break;
                    }
                    //preguntamos si es vacio
                    templateErrors["templateName"] = targetValue ? false : true;
                    errorHelper["templateName"] = targetValue
                        ? errorHelperKeys.removeError
                        : errorHelperKeys.emptyField;
                } else {
                    //Indicamos que el nombre mide mas de 50
                    //templateErrors['templateName'] = true
                    //errorHelper['templateName'] = errorHelperKeys.templateNameLength
                }
                break;
            case "templateContent":
                //Guardamos únicamente si el valor es menor a 1000
                if (targetValue.length <= 1000) {
                    newTemplate["templateContent"] = targetValue;
                    //preguntamos si es vacío
                    templateErrors["templateContent"] = targetValue ? false : true;
                    errorHelper["templateContent"] = targetValue
                        ? errorHelperKeys.removeError
                        : errorHelperKeys.emptyField;
                    //Buscamos si hay parametros duplicados
                    const regexTextInBraces = /\{\{(.*?)\}\}/gm; //Regex para buscar texto dentro de llaves dobles {{HOLA}} -> HOLA
                    let paramsInTemplate = [];
                    let match;
                    //iteramos todo el string hasta que ya no encuentre concidencias
                    while ((match = regexTextInBraces.exec(targetValue)) !== null) {
                        if (match.index === regexTextInBraces.lastIndex) {
                            regexTextInBraces.lastIndex++;
                        }
                        //Guaramos el texto encontrado dentro de las llaves
                        paramsInTemplate.push(match[1]);
                    }
                    //Buscamos si hay parametros duplicados
                    let duplicatedParams = paramsInTemplate.filter((param, index) => {
                        return paramsInTemplate.indexOf(param) !== index ? param : null;
                    });
                    //si hay duplicados entonces indicamos el error
                    if (duplicatedParams.length > 0) {
                        templateErrors["templateContent"] = true;
                        errorHelper["templateContent"] = errorHelperKeys.templateParamsDuplicated;
                    }
                }
                break;
            case "BoldButton":
            case "ItalicButton":
            case "StrikethroughButton":
            case "CodeButton":
                //Check if the last 2 characters are the same as the targetValue
                let lastTwoChars = newTemplate["templateContent"].slice(-2);
                if (lastTwoChars === targetValue) {
                    //Remove the last two characters
                    newTemplate["templateContent"] = newTemplate["templateContent"].slice(0, -2);
                } else {
                    //Add the targetValue
                    newTemplate[
                        "templateContent"
                    ] = `${newTemplate["templateContent"]}${targetValue}`;
                }
                templateContentRef.current.focus();
                break;
            case "AddVariableButton":
                //Search for varibales in the template content
                const regexTextInBraces = /\{\{(.*?)\}\}/gm; //Regex para buscar texto dentro de llaves dobles {{HOLA}} -> HOLA
                let paramsInTemplate = [];
                let match;
                //iteramos todo el string hasta que ya no encuentre concidencias
                while ((match = regexTextInBraces.exec(newTemplate["templateContent"])) !== null) {
                    if (match.index === regexTextInBraces.lastIndex) {
                        regexTextInBraces.lastIndex++;
                    }
                    //Guaramos el texto encontrado dentro de las llaves
                    paramsInTemplate.push(match[1]);
                }
                let lastParam = paramsInTemplate[paramsInTemplate.length - 1] || 0;
                let newParam = parseInt(lastParam) + 1;

                //Verificamos que la cuenta sea correcta

                newTemplate["templateContent"] = `${newTemplate["templateContent"]}{{${newParam}}}`;
                break;
            case "templateType":
                newTemplate["header"]["type"] = targetValue;
                if (targetValue === "none" || targetValue === "upload") {
                    newTemplate["header"]["url"] = "";
                }
                if (
                    targetValue !== "none" &&
                    targetValue !== "upload" &&
                    newTemplate["header"]["url"] === ""
                ) {
                    templateErrors["templateFileURL"] = true;
                    errorHelper["templateFileURL"] = errorHelperKeys.emptyField;
                    break;
                }
                if (targetValue !== "upload") {
                    uploadedFileCopy = null;
                }
                templateErrors["templateFileURL"] = false;
                errorHelper["templateFileURL"] = errorHelperKeys.removeError;
                break;
            case "templateFileURL":
                newTemplate["header"]["url"] = targetValue;
                if (!targetValue) {
                    templateErrors["templateFileURL"] = true;
                    errorHelper["templateFileURL"] = errorHelperKeys.emptyField;
                    break;
                }
                let filename = targetValue.replace(/\?.+/, "").split("/").pop();
                let fileExtension = "." + filename.split(".").pop();
                if (!isURL(targetValue) || !birdAcceptedFiles.includes(fileExtension)) {
                    templateErrors["templateFileURL"] = true;
                    errorHelper["templateFileURL"] = `${
                        errorHelperKeys.validURL
                    } y con terminación ${birdAcceptedFiles.join(" ")}`;
                    break;
                }

                templateErrors["templateFileURL"] = false;
                errorHelper["templateFileURL"] = errorHelperKeys.removeError;
                break;
            case "buttons":
                if (targetValue.length >= 20) {
                    templateErrors["buttons"][index] = true;
                    errorHelper["buttons"][index] = errorHelperKeys.buttonMaxLength;
                    break;
                }
                newTemplate["buttons"][index].text = targetValue;
                if (!targetValue) {
                    templateErrors["buttons"][index] = true;
                    errorHelper["buttons"][index] = errorHelperKeys.emptyField;
                    break;
                }
                templateErrors["buttons"][index] = false;
                errorHelper["buttons"][index] = errorHelperKeys.removeError;
                break;
            case "fileUpload":
                //save image in base64
                if (!targetValue) break;
                fileBase64Copy = await readFile(targetValue);
                let fileName = targetValue.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/ /g, "_");
                let fileType = targetValue.type;
                uploadedFileCopy = {
                    name: fileName,
                    body: fileBase64Copy.split(",")[1],
                    type: fileType,
                    length: targetValue.size,
                };
                break;

            default:
                break;
        }
        let templateContentLength = newTemplate.templateContent.length; //Max 1000
        let templateNameLength = newTemplate.templateName.length; //Max 50
        const countTemplateErrors = Object.values(templateErrors).reduce((a, item) => a + item, 0);
        setState({
            ...state,
            newTemplate: newTemplate,
            templateContentLength: templateContentLength,
            templateNameLength: templateNameLength,
            templateErrors: templateErrors,
            errorHelper: errorHelper,
            countTemplateErrors: countTemplateErrors,
            hasChanges: true,
            uploadedFile: uploadedFileCopy,
            fileBase64: fileBase64Copy,
        });
    };

    const closeLoadingDialog = (openLoading) => {
        setState({
            ...state,
            openLoading: openLoading,
            LoadingSucceeded: false,
            LoadingFailed: false,
        });
    };

    const addNewMenuButton = () => {
        const { newTemplate } = state;
        let newButtons = newTemplate.buttons;
        newButtons.push({ text: "" });
        templateErrors["buttons"].push(true);
        errorHelper["buttons"].push(errorHelperKeys.emptyField);
        setState({
            ...state,
            newTemplate: {
                ...newTemplate,
                buttons: newButtons,
            },
        });
    };

    const removeMenuButton = (index) => {
        const { newTemplate } = state;
        let newButtons = newTemplate.buttons;
        newButtons.splice(index, 1);
        templateErrors["buttons"].splice(index, 1);
        errorHelper["buttons"].splice(index, 1);
        setState({
            ...state,
            newTemplate: {
                ...newTemplate,
                buttons: newButtons,
            },
        });
    };

    const sendTemplateToApproval = async () => {
        // const { updateContextAttribute } = context;
        const { newTemplate, uploadedFile } = state;
        let askConfirm = await Swal.fire({
            title: "¿Estás seguro que deseas guardar?",
            text: "Una vez la plantilla sea mandada a revisión no podrá modificarse.",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el guardado de cambios
        if (askConfirm.isDenied || askConfirm.isDismissed) {
            return;
        }

        handleDialogAction(
            true,
            "Espere un momento, la plantilla esta siendo mandada a revisión....",
            "loadingModal"
        );
        if (uploadedFile) {
            let res = await blobStorage(uploadedFile, "uploadFile&uploadType=campaigns");
            let resCode = res.data.code;
            switch (resCode) {
                case "00":
                    let imageURL = res.data.imageURL;
                    newTemplate.header = {
                        type: fileTypesExtentions[uploadedFile.type],
                        url: imageURL,
                    };
                    break;

                default:
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "No se pudo subir el archivo, intentalo de nuevo.",
                    });
                    return;
            }
        }
        let res = await whatsAppCampaignActions(newTemplate, "createTemplate");
        handleDialogAction(false, "", "");
        let resCode = res.data.response.code;
        switch (resCode) {
            case "07":
                Swal.fire({
                    icon: "success",
                    title: "La plantilla fue mandada a revisón.",
                    text: "Espera unas horas a que el equipo de WhatsApp la apruebe.",
                });

                onCancel();
                handleOpenNewTemplate(false);
                refreshTemplateList();
                break;
            case "21":
                Swal.fire({
                    icon: "info",
                    title: "El nombre de la plantilla ya fue utilizado anteriormente",
                    text: "Debes esperar al menos 4 semanas después de su eliminación para reutilizar el nombre.",
                });
                break;

            default:
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No se pudo mandar la plantilla a revisión, intentalo de nuevo.",
                });
                break;
        }
    };

    function readFile(file) {
        if (!file) return;
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Define what happens once the file is loaded
            reader.onload = function (event) {
                resolve(event.target.result); // Resolve with the file contents
            };

            // Define what happens if there's an error reading the file
            reader.onerror = function (event) {
                reject(event.target.error); // Reject with the error
            };

            // Start reading the file
            reader.readAsDataURL(file);
        });
    }

    const askConfirm = () => {
        setState({
            ...state,
            openLoading: true,
            dialogTitle: "¿Estás seguro que deseas salir?",
            dialogMessage: "Los cambios realizados se perderán.",
            confirmDialog: true,
        });
    };

    const decideDisableSaveButton = () => {
        const { templateErrors } = state;
        let hasButtonError = templateErrors["buttons"].some((button) => button === true);
        return (
            templateErrors["templateName"] || templateErrors["templateContent"] || hasButtonError
        );
    };

    const {
        handleOpenNewTemplate,
        openNewTemplateForm,
        refreshTemplateList,
        allTemplatesNames,
        handleDialogAction,
    } = props;
    const {
        newTemplate,
        templateErrors,
        templateContentLength,
        Loading,
        dialogMessage,
        LoadingSucceeded,
        openLoading,
        errorHelper,
        templateNameLength,
        countTemplateErrors,
        LoadingFailed,
        dialogTitle,
        confirmDialog,
    } = state;

    const { header, templateContent, templateName, buttons } = newTemplate;
    const classes = useStyles();
    // const context = useContext(AppContext);
    return (
        <div>
            <Dialog open={openLoading}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {Loading && <MuiCircularProgress />}
                        {LoadingSucceeded && <CheckCircleIcon className="check-circle-icon" />}
                        {LoadingFailed && <ErrorIcon className="error-circle-icon" />}
                        <p>{dialogMessage}</p>
                        {confirmDialog ? (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                }}
                            >
                                <CustomButton
                                    onClick={() => closeLoadingDialog(false)}
                                    variant="contained"
                                >
                                    No
                                </CustomButton>
                                <CustomButton
                                    onClick={() => {
                                        handleOpenNewTemplate(false);
                                        onCancel();
                                    }}
                                    variant="contained"
                                >
                                    Si
                                </CustomButton>
                            </div>
                        ) : (
                            <CustomButton
                                onClick={() => closeLoadingDialog(false)}
                                variant="contained"
                            >
                                Ok
                            </CustomButton>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={openNewTemplateForm} fullScreen>
                <DialogTitle disableTypography>
                    <h2>Creación de nuevo template</h2>
                    <IconButton
                        onClick={() => {
                            if (
                                newTemplate.templateName === "" &&
                                newTemplate.templateContent === ""
                            ) {
                                handleOpenNewTemplate(false);
                            } else {
                                askConfirm();
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={11} sm={8} className="new-template-input-container">
                        <div className="new-template-input">
                            <div className="templateContentHeader">
                                <div>
                                    <label className="top-label-field">Nombre del template</label>
                                    <Tooltip
                                        title={`
                                   El nombre del template tiene un máximo de 50 caracteres. 
                                `}
                                    >
                                        <HelpOutlineIcon color="primary" fontSize="small" />
                                    </Tooltip>
                                </div>
                                <div className="template-content-lenght-container">
                                    {/* el value no debe pasar el 100% */}
                                    <CircularProgressTemplateName
                                        variant="determinate"
                                        value={
                                            templateNameLength * 2 >= 100
                                                ? 100
                                                : templateNameLength * 2
                                        }
                                    />
                                    <span
                                        style={{
                                            color: templateNameLength >= 50 ? "#E42922" : "inherit",
                                        }}
                                        className="template-name-lenght-number"
                                    >
                                        {templateNameLength}
                                    </span>
                                </div>
                            </div>
                            <TextField
                                name="templateName"
                                value={newTemplate.templateName}
                                size="small"
                                type="small"
                                onChange={(event) =>
                                    handleNewTemplateForm(event.target.name, event.target.value)
                                }
                                fullWidth
                                variant="outlined"
                                error={templateErrors["templateName"]}
                                helperText={errorHelper["templateName"]}
                            />
                        </div>
                        <div>
                            <label className="top-label-field">Archivo del template</label>
                            <Tooltip
                                title={`
                                El archivo del template debe ser un video, imagen o pdf.
                            `}
                            >
                                <HelpOutlineIcon color="primary" fontSize="small" />
                            </Tooltip>
                            <TextField
                                select
                                name={"templateType"}
                                value={header.type}
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={(event) =>
                                    handleNewTemplateForm(event.target.name, event.target.value)
                                }
                            >
                                {CampaignsHeaders.length !== 0 ? (
                                    CampaignsHeaders.map((option, i) => (
                                        <MenuItem key={i} value={option.type}>
                                            {option.alias}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value={""}>No hay opciones disponibles</MenuItem>
                                )}
                            </TextField>

                            {!["none", "upload"].includes(header.type) && (
                                <div>
                                    <label className="top-label-field">URL del archivo</label>
                                    <Tooltip title={`la URL directa al archivo del template.`}>
                                        <HelpOutlineIcon color="primary" fontSize="small" />
                                    </Tooltip>
                                    <TextField
                                        name="templateFileURL"
                                        value={header.url}
                                        size="small"
                                        type="small"
                                        onChange={(event) =>
                                            handleNewTemplateForm(
                                                event.target.name,
                                                event.target.value
                                            )
                                        }
                                        fullWidth
                                        variant="outlined"
                                        error={templateErrors["templateFileURL"]}
                                        helperText={errorHelper["templateFileURL"]}
                                    />
                                </div>
                            )}
                            {
                                //Si el tipo es upload entonces mostramos el dropzone
                                header.type === "upload" && (
                                    <div className="upload-templateFile-container">
                                        <div>
                                            <label className="top-label-field">Subir archivo</label>
                                            <Tooltip
                                                title={`Sube un archivo de tipo ${birdAcceptedFiles.join(
                                                    ", "
                                                )}`}
                                            >
                                                <HelpOutlineIcon color="primary" fontSize="small" />
                                            </Tooltip>
                                        </div>

                                        <DropzoneArea
                                            acceptedFiles={birdAcceptedFiles}
                                            dropzoneClass="dropZoneContainerTemplate"
                                            filesLimit={1}
                                            maxFileSize={20000000} //20 MB
                                            showFileNames
                                            dropzoneText={
                                                "Arrastra y suelta un archivo aquí o haz click"
                                            }
                                            onChange={(file) => {
                                                handleNewTemplateForm("fileUpload", file[0]);
                                            }}
                                            dropzoneParagraphClass="dropzoneParagraph"
                                            previewGridClasses={{
                                                container: "previewGridContainer",
                                                item: "previewGridItem",
                                                image: "previewGridImage",
                                            }}
                                            //Mensajes del Drop Zone
                                            getFileAddedMessage={(fileName) =>
                                                `El archivo ${fileName} fue agregado con éxito`
                                            }
                                            getFileLimitExceedMessage={(filesLimit) =>
                                                ` Número máximo de archivos alcanzados. Solamente se permiten ${filesLimit} archivos `
                                            }
                                            getFileRemovedMessage={(fileName) =>
                                                ` El archivo ${fileName} ha sido eliminado. `
                                            }
                                            getDropRejectMessage={(rejectedFile) =>
                                                ` Este archivo ${rejectedFile.name} no es compatible o Excede el máximo permitido de 4MB `
                                            }
                                            alertSnackbarProps={{
                                                anchorOrigin: {
                                                    vertical: "top",
                                                    horizontal: "right",
                                                },
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className="new-template-input">
                            <div className="templateContentHeader">
                                <div>
                                    <label className="top-label-field">
                                        Contenido del template
                                    </label>
                                    <Tooltip
                                        title={`
                                    El contenido del template tiene un máximo de 1000 caracteres. 
                                `}
                                    >
                                        <HelpOutlineIcon color="primary" fontSize="small" />
                                    </Tooltip>
                                </div>
                                <div className="template-content-lenght-container">
                                    {/* el value no debe pasar el 100% */}
                                    <CircularProgressTemplateContent
                                        variant="determinate"
                                        value={
                                            templateContentLength / 10 >= 100
                                                ? 100
                                                : templateContentLength / 10
                                        }
                                    />
                                    <span
                                        style={{
                                            color:
                                                templateContentLength >= 1000
                                                    ? "#E42922"
                                                    : "inherit",
                                        }}
                                        className="template-content-lenght-number"
                                    >
                                        {templateContentLength}
                                    </span>
                                </div>
                            </div>

                            <TextField
                                name="templateContent"
                                className="templateContent"
                                value={newTemplate.templateContent}
                                size="medium"
                                onChange={(event) =>
                                    handleNewTemplateForm(event.target.name, event.target.value)
                                }
                                fullWidth
                                multiline
                                variant="outlined"
                                error={templateErrors["templateContent"]}
                                helperText={errorHelper["templateContent"]}
                                inputRef={templateContentRef}
                            />
                            <div className="templateActionsContainer">
                                <Tooltip title="Abrir modal para agregar emoji al contenido del template">
                                    <Button
                                        startIcon={<EmojiEmotionsIcon width="15px" height="15px" />}
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: "3px" }}
                                        onClick={() => setOpenEmojiMenu(!openEmojiMenu)}
                                    >
                                        Emoji
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Agregar negritas al contenido del template">
                                    <Button
                                        name="BoldButton"
                                        startIcon={<FormatBoldIcon width="15px" height="15px" />}
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: "3px" }}
                                        onClick={(event) =>
                                            handleNewTemplateForm("BoldButton", "**")
                                        }
                                    >
                                        Bold
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Agregar cursiva al contenido del template">
                                    <Button
                                        name="ItalicButton"
                                        startIcon={<FormatItalicIcon width="15px" height="15px" />}
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: "3px" }}
                                        onClick={(event) =>
                                            handleNewTemplateForm("ItalicButton", "__")
                                        }
                                    >
                                        Italic
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Agregar tachado al contenido del template">
                                    <Button
                                        name="StrikethroughButton"
                                        startIcon={
                                            <StrikethroughSIcon width="15px" height="15px" />
                                        }
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: "3px" }}
                                        onClick={(event) =>
                                            handleNewTemplateForm("StrikethroughButton", "~~")
                                        }
                                    >
                                        Tachado
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Agregar código al contenido del template">
                                    <Button
                                        name="CodeButton"
                                        startIcon={<CodeIcon width="15px" height="15px" />}
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: "3px" }}
                                        onClick={(event) =>
                                            handleNewTemplateForm("CodeButton", "``````")
                                        }
                                    >
                                        Código
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Agregar variable al contenido del template">
                                    <Button
                                        name="AddVariableButton"
                                        startIcon={<AgregarIcono width="15px" height="15px" />}
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: "3px" }}
                                        onClick={(event) =>
                                            handleNewTemplateForm("AddVariableButton", "{{1}}")
                                        }
                                    >
                                        Agregar variable
                                    </Button>
                                </Tooltip>
                            </div>
                            {/* ============================== MENU DE EMOJIS ============================= */}
                            <div className="emojiPickerMenu">
                                <EmojiPicker
                                    height={"425px"}
                                    open={openEmojiMenu}
                                    onEmojiClick={(event) => {
                                        handleNewTemplateForm(
                                            "templateContent",
                                            `${newTemplate.templateContent}${event.emoji}`
                                        );
                                        setOpenEmojiMenu(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="buttonsFooter">
                            <label className="top-label-field">Botones</label>
                            <Tooltip
                                title={`
                                Los botones son opcionales, puedes agregar hasta 4 botones.
                            `}
                            >
                                <HelpOutlineIcon color="primary" fontSize="small" />
                            </Tooltip>
                            <div className="buttonsContainer">
                                {buttons.map((button, index) => {
                                    return (
                                        <div className="buttonsContent" key={index}>
                                            <TextField
                                                className="buttonTextField"
                                                name={`button${index}`}
                                                value={button.text}
                                                size="small"
                                                onChange={(event) => {
                                                    handleNewTemplateForm(
                                                        "buttons",
                                                        event.target.value,
                                                        index
                                                    );
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                placeholder={`Botón ${index + 1}`}
                                                error={templateErrors["buttons"][index]}
                                                helperText={errorHelper["buttons"][index]}
                                            />
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => removeMenuButton(index)}
                                            >
                                                <TrashCan width="20px" height="20px" />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <Button
                                    disabled={buttons.length >= 4}
                                    startIcon={<AgregarIcono width="15px" height="15px" />}
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: "3px" }}
                                    onClick={() => {
                                        addNewMenuButton();
                                    }}
                                >
                                    Agregar botón
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.root}>
                        <Grid container alignContent="flex-end">
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <CustomButton
                                            disabled={decideDisableSaveButton()}
                                            onClick={() => sendTemplateToApproval()}
                                            variant="contained"
                                        >
                                            Solicitar aprobación
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
