//React Libraires
import React from 'react';

//Custom Portal components
import ButtonConfirmDialog from '../components/common/ButtonConfirmDialog';
import CustomButton from "../components/common/CustomButton";
import AppContext from '../Context/AppContext';
import CustomAlert from '../components/common/Alerts/CustomAlert';
import MyaccountPage from './MyAccountPage';
import Changepassword from '../components/AuthPageComponents/ChangePassword';
import MySubscriptionPage from './MySubscriptionPage';
import Settings from './Settings';
import ManagePermissions from './ManagePermissions'

//Material UI components
import { Box, Grid, withStyles, Tabs, Tab, Tooltip, IconButton, } from'@material-ui/core/';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

//Icons
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LockIcon from '@material-ui/icons/Lock';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

//Portal catalogs
// import Settings from '../Config/settings';

//Styles
const dialogStyles = {
    container:{
        padding: '0px',
        width:'420px',
        overflowX:'hidden'
    },
    ChangePasswordContainer:{
        padding: '0px',
        overflowX:'hidden'
    },
    contentBody:{
        overflow:'hidden'
    },
    tabContainer:{
        backgroundColor:'#27315d',
    },
    settingTab:{
        minWidth: '0px',
        color:'white',
        opacity: '1',
    },
    tabPanel:{
        
    },
    tabBox:{
        padding:'5px 10px',
        marginTop:'10px',
    },
    arrowBack:{
        fill:'#ffffff'
    },
    arrowBackContainer:{
        background: "#27315d",
        position: "relative",
        top: "16px",
        height: "0px",
        zIndex: "99",
    },
    iconButton:{
        padding: '5px 10px'
    }
}

const DialogContent = withStyles({
    root:{
        overflow:'hidden',
        padding:'0 !important'
    },
})(MuiDialogContent);

const DialogActions = withStyles({
    root: {
        margin: 15,
        padding: '10px',
        justifyContent: 'center',
    },
})(MuiDialogActions);

//initialize UI components
function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div
        role="tabpanel"
        style={dialogStyles.tabPanel}
      >
        {value === index && (
          <Box style={dialogStyles.tabBox}>
            {children}
          </Box>
        )}
      </div>
    );
}

export default class PortalSettings extends React.Component{

    state = {
        hasChanges: false,
        currentTab: 0,
        alert: {
            open: false,
            severity: '', //success, error, warning, info
            message: ''
        },
    }

    componentDidMount = () =>{
    }

    changeSettingTab = (event, TabIndex) =>{
        this.setState({
            currentTab:TabIndex,
        })
    } 


    handleCloseAlert = (event, reason) => {
        this.setState({
            alert: {
                open: false,
                message: ''
            }
        });
    }
    

    render(){
        const { getStorageData } = this.context;
        const { onClose, openSettings, openAccount, openDrawerChangePassword, openDrawerManagePermissions, openChangePassword, openSuscription, openManagePermissions } =  this.props;
        const { hasChanges, hasErrors, currentTab, settings } = this.state;
        let storageData = getStorageData([
            "currentSubscription",
        ])
        const { currentSubscription } = storageData
        let userIsAdmin = true
        return(
            <React.Fragment>
                <CustomAlert
                    open={this.state.alert.open}
                    severity={this.state.alert.severity}
                    message={this.state.alert.message}
                    handleCloseAlert={() => this.handleCloseAlert()}
                />
                <section style={openChangePassword || openManagePermissions ? dialogStyles.ChangePasswordContainer : dialogStyles.container } >                
                    <DialogContent>
                        <div style={dialogStyles.contentBody} >
                            <div style={dialogStyles.arrowBackContainer} >
                                <IconButton style={dialogStyles.iconButton} onClick={() => onClose()}>
                                    <ArrowBackIcon style={dialogStyles.arrowBack} />
                                </IconButton>
                            </div>
                            <Tabs
                                value={currentTab}
                                onChange={this.changeSettingTab}
                                style={dialogStyles.tabContainer}
                                variant="fullWidth"
                                
                            >
                                {/* Pestañas de Settings */}
                                {openAccount && <Tab style={dialogStyles.settingTab} disabled label='Mi Cuenta' icon={<AccountBoxIcon/>}  />}
                                {openSettings && <Tab style={dialogStyles.settingTab} disabled label='Ajustes' icon={<QuestionAnswerIcon/>} />}
                                {openChangePassword && <Tab style={dialogStyles.settingTab} disabled label='Cambio de contraseña' icon={<LockIcon/>} />}
                                {openManagePermissions && <Tab style={dialogStyles.settingTab} disabled label='Administración de Permisos' icon={<SupervisorAccountIcon/>} />}
                                {openSuscription && <Tab style={dialogStyles.settingTab} disabled label={currentSubscription.name} icon={<AccountBoxIcon/>} />}
                            </Tabs>
                            {/* ==============Pestaña de Información de la cuenta================== */}
                            {openAccount && 
                            <TabPanel value={currentTab} index={0}>
                                <MyaccountPage openDrawerChangePassword={openDrawerChangePassword} openDrawerManagePermissions={openDrawerManagePermissions} onClose={onClose} />
                            </TabPanel>
                            }
                            {/* ==============Pestaña de Información de la Suscripcion================== */}
                            {openSuscription && 
                            <TabPanel value={currentTab} index={0}>
                                <MySubscriptionPage onClose={onClose} />
                            </TabPanel>
                            }
                            {/* ==============Pestaña de Información de Cambio de contraseña================== */}
                            {openChangePassword &&
                            <TabPanel value={currentTab} index={0}>
                                <Changepassword onClose={onClose} />
                            </TabPanel>
                            }
                            {/* ==============Pestaña de administración de permisos================== */}
                            {openManagePermissions && userIsAdmin &&
                            <TabPanel value={currentTab} index={0}>
                                <ManagePermissions onClose={onClose} />
                            </TabPanel>
                            }
                            {/* =================== Pestaña de ajustes del QnA Maker ========================== */}
                            { openSettings &&
                            <TabPanel value={currentTab} index={0}>
                                <Settings onClose={onClose} /> 
                            </TabPanel>
                            }
                        </div>
                    </DialogContent>
                    
                </section>
            </React.Fragment>
        )
    }
}

PortalSettings.contextType = AppContext;