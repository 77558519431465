import React from 'react';
//MUI components
import { makeStyles } from '@material-ui/core/styles';

//External libraries
import clsx from 'clsx';

const useStylesBase = makeStyles(theme => ({
    root: {
        '& table': {
            textAlign: 'center',
            width: '100%',
            borderCollapse: 'inherit',
            borderSpacing: '0px',
        },
        '& table td': {
            border: '1px solid #B2B2B2',
        },
        '& table th': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            padding: '10px',
        },
    }
}))
const useStyles = makeStyles((theme) => ({
    withButtons: {
        '& table td': {
            '&:first-child, &:last-child': {
                border: 'none',
                backgroundColor: 'rgb(0, 0, 0, 0.0)',
            },
        },
        '& table th': {
            '&:first-child, &:last-child': {
                border: 'none',
                backgroundColor: 'rgb(0, 0, 0, 0.0)',
            },
            '&:nth-child(2)': {
                borderTopLeftRadius: '15px',
                borderRight: '1px solid #B2B2B2',
            },
            '&:nth-last-child(2)': {
                borderTopRightRadius: '15px',
            },
        },
        '& table tr': {
            '&:last-of-type': {
                '& td': {
                    '&:nth-child(2)': {
                        borderBottomLeftRadius: '15px',
                    },
                    '&:nth-last-child(2)': {
                        borderBottomRightRadius: '15px',
                    }
                }
            },
        },
    },
    regular: {
        '& table td': {
            textAlign: 'center',
            wordBreak: "break-word"
        },
        '& table th': {
            '&:first-child': {
                borderTopLeftRadius: '15px',
                borderRight: '1px solid #B2B2B2',
            },
            '&:last-child': {
                borderTopRightRadius: '15px',
            },
        },
        '& table tr': {
            '&:last-of-type': {
                '& td': {
                    '&:first-child': {
                        borderBottomLeftRadius: '15px',
                    },
                    '&:last-child': {
                        borderBottomRightRadius: '15px',
                    }
                }
            },
        },
    },
    hideLastColumn: {
        '& table td': {
            '&:last-child': {
                border: 'none',
                backgroundColor: 'rgb(0, 0, 0, 0.0)',
            },
        },
        '& table th': {
            '&:first-child': {
                borderTopLeftRadius: '15px',
                borderRight: '1px solid #B2B2B2',
            },
            '&:nth-last-child(2)': {
                borderTopRightRadius: '15px',
            },
            '&:last-child': {
                border: 'none',
                backgroundColor: 'rgb(0, 0, 0, 0.0)',
            },
        },
        '& table tr': {
            '&:last-of-type': {
                '& td': {
                    '&:first-child': {
                        borderBottomLeftRadius: '15px',
                    },
                    '&:nth-last-child(2)': {
                        borderBottomRightRadius: '15px',
                    }
                }
            },
        },
    },
    sticky: {
        '& table td': {
            wordBreak: "break-word"
        },
        '& thead th': {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            border: '1px solid #B2B2B2',
            '&:nth-child(1)': {
                left: 0,
                zIndex: 2,
                borderTopLeftRadius: '15px',
                borderRight: '1px solid #B2B2B2',
            },
            '&:nth-child(2)': {
                left: '53px',
                zIndex: 3,
                borderRight: '1px solid #B2B2B2',
            },
            '&:nth-last-child(2)': {
                borderTopRightRadius: '15px',
            },
            '&:last-child': {
                right: 0,
                zIndex: 2,
                backgroundColor: 'white',
                border: 'none'
            },
        },
        '& tbody td:first-child': {
            position: 'sticky',
            left: 0,
            zIndex: 1,
            backgroundColor:'white'
        },
        '& tbody td:nth-child(2)': {
            position: 'sticky',
            left: '53px',
            zIndex: 1,
            backgroundColor:'white'
        },
        '& tbody td:last-child': {
            position: 'sticky',
            right: 0,
            zIndex: 1,
            backgroundColor: 'white',
            color: 'white',
            border: 'none',
        },
        '& tbody tr:last-child td': {
            border: 'none',
        },
        
        '& table tr': {
            '&:last-of-type': {
                '& td': {
                    '&:first-child': {
                        borderBottomLeftRadius: '15px',
                    },
                    '&:nth-last-child(2)': {
                        borderBottomRightRadius: '15px',
                    }
                }
            },
        },
    },
    scroller: {
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    simpleTableOverflow:{
        overflow: 'hidden',
        wordBreak: 'break-word'
    },
    hideLastRow:{
        'td':{
            backgroundColor:'red'
        }
    },
    simpleTable: {
        "& thead th": {
          position: "sticky",
          top: 0,
          border: "1px solid #B2B2B2",
          minWidth: "10%",
          "&:nth-child(1)": {
            left: 0,
            borderTopLeftRadius: "15px",
            borderRight: "1px solid #B2B2B2",
          },
          "&:nth-child(2)": {
            left: "53px",
            borderRight: "1px solid #B2B2B2",
          },
          "&:nth-last-child(2)": {
            borderTopRightRadius: "15px",
          },
          "&:last-child": {
            right: 0,
            backgroundColor: "white",
            border: "none",
          },
        },
        "& tbody td:first-child": {
          position: "sticky",
          left: 0,
          backgroundColor: "white",
        },
        "& tbody td:nth-child(2)": {
          position: "sticky",
          left: "53px",
          backgroundColor: "white",
        },
        "& tbody td:last-child": {
          position: "sticky",
          right: 0,
          backgroundColor: "white",
          color: "white",
          border: "none",
        },
        "& table tr": {
          "&:last-of-type": {
            "& td": {
              "&:first-child": {
                borderBottomLeftRadius: "15px",
              },
              "&:nth-last-child(2)": {
                borderBottomRightRadius: "15px",
              },
            },
          },
        },
    },
}))

export default function Table(props) {
    const { children, className, simpleTable } = props;
    const classes = useStyles();
    const classesBase = useStylesBase();
    const combinedClassName = clsx(classes[className], classesBase.root);

    return(
        <div className={simpleTable ? classes.simpleTableOverflow : classes.scroller}>
            <div className={combinedClassName}>
                <table>
                    {children}
                </table>
            </div>
        </div>
    )
}