import React, { useState, useEffect } from "react";

//Material UI inputs
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
    usePopupState,
    bindFocus,
    bindPopover,
} from "material-ui-popup-state/hooks";
import {
    Grid,
    TextField,
    MenuItem,
    Chip,
    Tooltip,
    Slider,
    OutlinedInput,
    Button,
    Typography,
    Popover as MuiPopover,
    Divider,
    Radio,
    RadioGroup,
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
    Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import ChipInput from "material-ui-chip-input";

//Material UI icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { ReactComponent as TrashCan } from "../Icons/trashcanIcon.svg";
import TablaIcono from "../Icons/TablaIcono";
import AgregarIcono from "../Icons/AgregarIcono";

//Custom Portal components
import ColumnCatalog from "./CatalogsTableComponents/ColumnCatalog";
import AddCatalogFrom from "./CatalogsTableComponents/AddCatalogForm";
import AddRegExForm from "./RegExComponents/AddRegExForm";
import Table from "./common/Tables/StyledTable";
import ResponseBranchesTable from "./FlowSectionComponents/ResponseBranches/ResponseBranchesTable";
import BodyRequestTable from "./FlowSectionComponents/BodyRequest/BodyRequestTable";
import SubflowsTable from "./FlowSectionComponents/Subflows/SubflowsTable";
import SubmenuTable from "./FlowSectionComponents/SubmenuOptions/SubmenuTable";
import RequestHeadersTable from "./FlowSectionComponents/RequestHeaders/RequestHeadersTable";

import { operators } from "./IntebotCatalog";

//External Libraries
import { MentionsInput, Mention } from "react-mentions"; // https://github.com/signavio/react-mentions

function setOption(string) {
    switch (string) {
        case "all":
            return "Todos";
        case "any":
            return "Ninguno";
        case true:
            return "Sí";
        case false:
            return "No";
        default:
            return string;
    }
}
//============= CSS BLOCK ============================
const Popover = withStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            width: "200px",
        },
    },
    paper: {
        overflowX: "unset",
        overflowY: "unset",
        "&::before": {
            content: '""',
            position: "absolute",
            marginRight: "-0.71em",
            bottom: "50%",
            left: 0,
            width: 20,
            height: 20,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[10],
            transform: "translate(-50%, 50%) rotate(225deg)",
            clipPath:
                "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
        },
    },
}))(MuiPopover);

const useStyles = makeStyles((theme) => ({
    popoverContent: {
        margin: theme.spacing(2),
        borderRadius: "25px",
    },
    popoverArrow: {
        overflowX: "unset",
        overflowY: "unset",
        "&::before": {
            content: '""',
            position: "absolute",
            marginRight: "-0.71em",
            bottom: "50%",
            left: 0,
            width: 20,
            height: 20,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[10],
            transform: "translate(-50%, 50%) rotate(225deg)",
            clipPath:
                "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
        },
    },
    addButton: {
        textTransform: "none",
    },
    Typography: {
        fontSize: "14px",
    },
    errorTypography: {
        fontSize: "14px",
        color: "red",
    },
    consistentInputSize: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        width: "59%",
    },
    doubleInputSize: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        width: "85%",
    },
    chipInputStyle: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        width: "59%",
    },
    chipComponetStyle: {
        overflow: "hidden",
    },
    chipTextField: {
        flex: "initial",
    },
    consistentMentionSize: {
        width: "190px",
    },
    consistentSelectorSize: {
        [theme.breakpoints.down("sm")]: {
            width: "200%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
        width: "119%",
    },
    treeView: {
        flexGrow: 1,
        maxWidth: 340,
    },
    jsonKeyLabel: {
        [theme.breakpoints.down("xl")]: {
            left: "6vw",
        },
        [theme.breakpoints.down("md")]: {
            left: "6vw",
        },
        [theme.breakpoints.down("sm")]: {
            left: "9vw",
        },
        [theme.breakpoints.down("xs")]: {
            left: "0px",
        },
        position: "relative",
        bottom: "25px",
        left: "105px",
        width: "150px",
    },
    jsonValueLabel: {
        [theme.breakpoints.down("xl")]: {
            width: "120px",
            left: "7vw",
        },
        [theme.breakpoints.down("lg")]: {
            width: "120px",
            left: "6vw",
        },
        [theme.breakpoints.down("md")]: {
            left: "6vw",
        },
        [theme.breakpoints.down("sm")]: {
            left: "9vw",
        },
        [theme.breakpoints.down("xs")]: {
            left: "0px",
        },

        position: "relative",
        bottom: "25px",
        left: "110px",
    },
    valueInput: {
        [theme.breakpoints.down("xl")]: {
            right: "9vw",
        },
        [theme.breakpoints.down("lg")]: {
            right: "9vw",
        },
        [theme.breakpoints.down("md")]: {
            right: "9vw",
        },
        [theme.breakpoints.down("sm")]: {
            right: "-10px",
        },
        [theme.breakpoints.down("xs")]: {
            right: "0px",
            maxWidth: "45vw",
        },
        position: "relative",
    },
    keyInput: {
        [theme.breakpoints.down("xl")]: {
            left: "7.4vw",
        },
        [theme.breakpoints.down("lg")]: {
            left: "7vw",
        },
        [theme.breakpoints.down("md")]: {
            left: "8vw",
        },
        [theme.breakpoints.down("sm")]: {
            left: "9vw",
        },
        [theme.breakpoints.down("xs")]: {
            left: "-3vw",
            maxWidth: "45vw",
        },
        position: "relative",
    },
    ButtonAlignRight: {
        textAlign: "right",
    },
    tableSize: {
        overflow: "hidden",
        [theme.breakpoints.down("xl")]: {
            width: "68%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "75%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
}));

const WarningTextField = withStyles((theme) => ({
    root: {
        background: theme.palette.other.gray,
        borderRadius: "10px",
        "& .MuiOutlinedInput-root": {
            //Default
            borderRadius: "10px",
            "& fieldset": {
                //borderColor: 'primary',
                //background: 'red',
                //color: 'black',
            },
            "&:hover fieldset": {
                //Hover
                //borderColor: 'yellow',
            },
            "&.Mui-focused fieldset": {
                //Focused
                //borderColor: 'yellow',
            },
        },
    },
}))(TextField);

//================================== END OF CSS BLOCK ==========================================

export default function InputChooser(props) {
    let {
        name,
        value,
        type,
        label,
        detailText,
        options,
        relatedOptions,
        relatedAttribute,
        error,
        required,
        variant,
        placeholder,
        text,
        onClick,
        channels,
        allChannelsCheckbox,
        jsonKey,
        jsonValue,
        requiredMark,
        responseAttributes,
        updateResponseAttributes,
        removeObjectResponse,
        fowardStepsDescription,
        disabled,
        autoCompleteList,
        errorHelper,
        messages,
        doubleInput,
        bodyRequest,
        editBodyRequest,
        removeBodyRequest,
        addBodyRequest,
        processBranches,
        allProcessesNames,
        updateProcessBranches,
        addProcessBranch,
        removeProcessBranch,
        responseBranches,
        menuOptions,
        deleteMenuOption,
        editMenuOption,
        addMenuOption,
        addResponseBranch,
        updateResponseBranch,
        removeResponseBranch,
        currentProcess,
        fieldTitleUp,
        modalAddRegExForm,
        addRequestHeader,
        updateRequestHeader,
        deleteRequestHeader,
        stepErrors,
        tempStep,
    } = props;
    const inputType = [
        "text-field",
        "warning-text-field",
        "chip-editor",
        "selector",
        "multi-selector",
        "object",
        "slider",
        "steps-table",
        "regex-selector",
        "catalog-selector",
        "related-description-selector",
        "text-field-alert",
        "radio",
        "checkbox",
        "label",
        "addButton",
        "expandable-checkbox",
        "response-table-attributes",
        "jump-selector",
        "table-checkbox",
        "mention-text-field",
        "error-label",
        "keyword-error",
        "double-text-field",
        "nested-flows-table",
        "body-request-table",
        "custom-messages-table",
        "response-branches-table",
        "menu-options-table",
        "simple-table",
        "switchButton",
        "request-headers-table",
        "jumpOperartion-field",
    ];

    //consts for object field
    const [objectField, setObjectField] = useState(
        JSON.stringify(value, null, 2)
    );
    const classes = useStyles();
    //const for body-request-table
    let bodyRequestFields = [];
    if (bodyRequest) {
        let bodyRequestKeys = Object.keys(bodyRequest);
        let bodyRequestValues = Object.values(bodyRequest);

        bodyRequestKeys.forEach((key, index) => {
            bodyRequestFields.push({
                key: key,
                value: bodyRequestValues[index],
            });
        });
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    //Para Popover de warning-text-field
    const popupState = usePopupState({
        variant: "popover",
        popupId: "warningPopover",
        disableAutoFocus: true,
    });
    //Para Popover de object
    const popupObject = usePopupState({
        variant: "popover",
        popupId: "objectPopover",
        disableAutoFocus: true,
    });
    //const for TreeView
    const [expanded, setExpanded] = React.useState(["0"]);
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    //Para actualizar campos de objeto al Cancelar cambios
    useEffect(() => {
        if (type === "object") {
            setObjectField(
                typeof value == "object"
                    ? JSON.stringify(value, null, 2)
                    : value,
                null,
                2
            );
        }
    }, [value, type]);

    //estilos del mention input
    const StyledMentionInput = {
        control: {
            backgroundColor: "#fff",
            fontSize: 16,
            fontWeight: "normal",
        },

        input: {
            margin: 0,
        },
        "&multiLine": {
            control: {
                fontFamily: "Roboto",
                border: "1px solid silver",
                borderRadius: "4px",
            },

            highlighter: {
                padding: 9,
                overflow: "hidden",
                lineHeight: "initial",
            },

            input: {
                padding: 10.5,
                minHeight: "40px",
                outline: 0,
                border: 0,
                overflow: "hidden",
                color: "#27315D",
            },
        },

        suggestions: {
            list: {
                backgroundColor: "white",
                border: "1px solid #b2b2b2",
                fontSize: 16,
                maxHeight: "300px",
                minWidth: "400px",
                overflowY: "auto",
                overflowX: "hidden",
                wordBreak: "break-word",
            },

            item: {
                padding: "8px 10px",
                "&focused": {
                    backgroundColor: "#ebebeb",
                },
            },
        },
    };
    if (Array.isArray(error)) {
        StyledMentionInput["&multiLine"].control.border = error[0]
            ? "1px solid red"
            : "1px solid silver";
    } else {
        StyledMentionInput["&multiLine"].control.border = error
            ? "1px solid red"
            : "1px solid silver";
    }
    //On Change funtion for the processModal state
    const handleChange = (event) =>
        props.onChange(name, event.target.value, type);

    return (
        <React.Fragment>
            {inputType.includes(type) ? (
                <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                    className={
                        jsonKey
                            ? classes.keyInput
                            : jsonValue
                            ? classes.valueInput
                            : null
                    }
                    justifyContent="center"
                >
                    <Grid
                        item
                        sm={
                            name === "headerMenu" || name === "menuOptions"
                                ? 11
                                : 12
                        }
                        xs={12}
                    >
                        <Grid container spacing={2} alignItems="flex-start">
                            {!modalAddRegExForm && (
                                <Grid item sm={3} xs={12}>
                                    {!fieldTitleUp ? (
                                        <label
                                            className={
                                                jsonKey
                                                    ? classes.jsonKeyLabel
                                                    : jsonValue
                                                    ? classes.jsonValueLabel
                                                    : null
                                            }
                                        >
                                            {required ? (
                                                <span style={{ color: "red" }}>
                                                    *{" "}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {label}&nbsp;
                                            {name ===
                                            "tableCard" ? null : detailText ? (
                                                <Tooltip title={detailText}>
                                                    <HelpOutlineIcon
                                                        color="primary"
                                                        fontSize="small"
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <React.Fragment />
                                            )}
                                        </label>
                                    ) : null}
                                </Grid>
                            )}
                            <Grid item sm={modalAddRegExForm ? 12 : 9} xs={12}>
                                {fieldTitleUp || modalAddRegExForm ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <label className="top-label-field">
                                            {required ? (
                                                <span style={{ color: "red" }}>
                                                    *{" "}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {label}&nbsp;
                                        </label>
                                        <Tooltip title={detailText}>
                                            <HelpOutlineIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </div>
                                ) : null}
                                {
                                    type === "text-field" ? (
                                        <TextField
                                            disabled={disabled}
                                            style={{
                                                backgroundColor: disabled
                                                    ? "#b2b2b2"
                                                    : "initial",
                                                zIndex: jsonKey ? "2" : "",
                                            }}
                                            name={name}
                                            value={value}
                                            multiline
                                            fullWidth={
                                                modalAddRegExForm
                                                    ? true
                                                    : typeof value ==
                                                      "undefined"
                                                    ? false
                                                    : value && value.length > 25
                                                    ? true
                                                    : false
                                            }
                                            variant={variant}
                                            className={
                                                name === "expresion" ||
                                                label === "Descripción" ||
                                                label ===
                                                    "Nombre del tipo de dato"
                                                    ? ""
                                                    : classes.consistentInputSize
                                            }
                                            size="small"
                                            error={error}
                                            onChange={handleChange}
                                            placeholder={
                                                placeholder ? placeholder : ""
                                            }
                                            helperText={errorHelper[name]}
                                        />
                                    ) : type === "warning-text-field" ? (
                                        <div>
                                            <WarningTextField
                                                {...bindFocus(popupState)}
                                                name={name}
                                                value={value}
                                                multiline
                                                fullWidth={
                                                    typeof value == "undefined"
                                                        ? false
                                                        : value.length > 25
                                                        ? true
                                                        : false
                                                }
                                                variant={variant}
                                                size="small"
                                                error={error}
                                                onChange={(event) =>
                                                    props.onChange(
                                                        name,
                                                        event.target.value,
                                                        type
                                                    )
                                                }
                                            />
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: "center",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "center",
                                                    horizontal: "left",
                                                }}
                                            >
                                                <div
                                                    className={
                                                        classes.popoverContent
                                                    }
                                                >
                                                    <Typography
                                                        style={{ margin: 5 }}
                                                    >
                                                        Advertencia.
                                                    </Typography>
                                                    <Divider />
                                                    Este campo necesita una
                                                    relación con la
                                                    configuración en Microsoft
                                                    QnA Maker.
                                                </div>
                                            </Popover>
                                        </div>
                                    ) : type === "chip-editor" ? (
                                        <ChipInput
                                            name={name}
                                            value={value}
                                            //label={label}
                                            placeholder="Añade Opción"
                                            fullWidth
                                            alwaysShowPlaceholder
                                            variant={variant}
                                            size="small"
                                            //className={classes.consistentInputSize}
                                            classes={{
                                                root: classes.chipInputStyle,
                                                input: classes.chipTextField,
                                                chip: classes.chipComponetStyle,
                                            }}
                                            error={error}
                                            helperText={
                                                error ? errorHelper[name] : ""
                                            }
                                            onAdd={(chip) =>
                                                props.onChange(name, chip, type)
                                            }
                                            onDelete={(chip, index) =>
                                                props.onChange(
                                                    name,
                                                    chip,
                                                    type,
                                                    index
                                                )
                                            }
                                            newChipKeyCodes={[
                                                9, 111, 174, 175, 188, 222,
                                            ]} //Tab,Divide,Semi-colon,Comma,Open-bracket,Back-slash,Close-braket,Single-quote
                                        />
                                    ) : type === "selector" ? (
                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                select
                                                name={name}
                                                value={value}
                                                className={
                                                    classes.consistentSelectorSize
                                                }
                                                fullWidth
                                                variant={variant}
                                                size="small"
                                                onChange={(event) =>
                                                    props.onChange(
                                                        name,
                                                        event.target.value,
                                                        type
                                                    )
                                                }
                                                helperText={
                                                    error
                                                        ? errorHelper[name]
                                                        : ""
                                                }
                                                error={error}
                                            >
                                                {
                                                    name ===
                                                    "bodyRequestValue" ? (
                                                        options.length !== 0 ? (
                                                            options.map(
                                                                (option, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={
                                                                            option.id
                                                                        }
                                                                    >
                                                                        {setOption(
                                                                            option.id
                                                                        )}{" "}
                                                                        <span
                                                                            style={{
                                                                                color: "#B2B2B2",
                                                                                fontSize:
                                                                                    "13px",
                                                                            }}
                                                                        >
                                                                            &nbsp;•{" "}
                                                                            {
                                                                                option.list
                                                                            }{" "}
                                                                        </span>{" "}
                                                                    </MenuItem>
                                                                )
                                                            )
                                                        ) : (
                                                            <MenuItem
                                                                value={""}
                                                            >
                                                                No hay opciones
                                                                disponibles
                                                            </MenuItem>
                                                        ) //body request list is empty
                                                    ) : options.length !== 0 ? ( //name != body request
                                                        options.map(
                                                            (option, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={
                                                                        option
                                                                    }
                                                                >
                                                                    {setOption(
                                                                        option
                                                                    )}
                                                                </MenuItem>
                                                            )
                                                        )
                                                    ) : (
                                                        <MenuItem value={""}>
                                                            No hay opciones
                                                            disponibles
                                                        </MenuItem>
                                                    ) //options is empty
                                                }
                                                {name === "bodyRequestValue" ? (
                                                    <MenuItem
                                                        value={
                                                            "Escribe tu valor aquí"
                                                        }
                                                    >
                                                        Otro Valor{" "}
                                                        <span
                                                            style={{
                                                                color: "#B2B2B2",
                                                                fontSize:
                                                                    "13px",
                                                            }}
                                                        >
                                                            &nbsp;• Local{" "}
                                                        </span>{" "}
                                                    </MenuItem>
                                                ) : null}
                                                {name === "bodyRequestValue" ? (
                                                    <MenuItem value={"null"}>
                                                        Vacío{" "}
                                                        <span
                                                            style={{
                                                                color: "#B2B2B2",
                                                                fontSize:
                                                                    "13px",
                                                            }}
                                                        >
                                                            &nbsp;• Local{" "}
                                                        </span>{" "}
                                                    </MenuItem>
                                                ) : null}
                                            </TextField>
                                        </Grid>
                                    ) : type === "regex-selector" ? (
                                        <Grid item md={7} sm={12} xs={12}>
                                            <AddRegExForm
                                                name={name}
                                                value={value}
                                                variant={variant}
                                                error={error}
                                                onChange={(name, value) =>
                                                    props.onChange(
                                                        name,
                                                        value,
                                                        type
                                                    )
                                                }
                                                regEx={props.regEx}
                                                addNewRegEx={(regEx) =>
                                                    props.addNewRegEx(regEx)
                                                }
                                                helperText={
                                                    error
                                                        ? errorHelper[name]
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                    ) : type === "multi-selector" ? (
                                        <Autocomplete
                                            multiple
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant={variant}
                                                    error={error}
                                                    placeholder="Buscar Opción"
                                                />
                                            )}
                                            value={
                                                typeof value == "undefined" ||
                                                value === ""
                                                    ? []
                                                    : value.map(
                                                          (value) => value
                                                      )
                                            }
                                            options={relatedOptions
                                                .map(
                                                    (object) =>
                                                        object[relatedAttribute]
                                                )
                                                .filter(
                                                    (attribute) =>
                                                        attribute != null
                                                )}
                                            fullWidth
                                            filterSelectedOptions
                                            noOptionsText="No hay opciones disponibles"
                                            size="small"
                                            className={
                                                classes.consistentInputSize
                                            }
                                            onChange={(event, value) =>
                                                props.onChange(
                                                    name,
                                                    value,
                                                    type
                                                )
                                            }
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        variant="default"
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                    />
                                                ))
                                            }
                                        />
                                    ) : type === "object" ? (
                                        <div>
                                            <TextField
                                                {...bindFocus(popupObject)}
                                                name={name}
                                                value={objectField}
                                                multiline
                                                variant={variant}
                                                size="small"
                                                className={
                                                    classes.consistentInputSize
                                                }
                                                error={error}
                                                onChange={(event) => {
                                                    setObjectField(
                                                        event.target.value
                                                    );
                                                    props.onChange(
                                                        name,
                                                        event.target.value,
                                                        type
                                                    );
                                                }}
                                                helperText={
                                                    error
                                                        ? errorHelper[name]
                                                        : ""
                                                }
                                            />
                                            <Popover
                                                {...bindPopover(popupObject)}
                                                anchorOrigin={{
                                                    vertical: "center",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "center",
                                                    horizontal: "left",
                                                }}
                                                //classes={{ paper: classes.popoverArrow }}
                                            >
                                                <div
                                                    className={
                                                        classes.popoverContent
                                                    }
                                                >
                                                    <Typography
                                                        style={{ margin: 5 }}
                                                    >
                                                        Formato JSON de ejemplo.
                                                    </Typography>
                                                    <Divider />
                                                    <p>
                                                        {"{"}
                                                        <br />
                                                        &nbsp;&nbsp;"Atributo1":
                                                        "texto de ejemplo",
                                                        <br />
                                                        &nbsp;&nbsp;"Atributo2":
                                                        2,
                                                        <br />
                                                        &nbsp;&nbsp;"Atributo3":
                                                        ["valor1", "valor2"]
                                                        <br />
                                                        {"}"}
                                                    </p>
                                                </div>
                                            </Popover>
                                        </div>
                                    ) : type === "slider" ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Grid item xs={9} md={6}>
                                                <Slider
                                                    value={parseInt(value)}
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) =>
                                                        props.onChange(
                                                            name,
                                                            newValue,
                                                            type
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={2}>
                                                <OutlinedInput
                                                    value={value}
                                                    margin="dense"
                                                    //variant="outl"
                                                    onChange={(event) =>
                                                        props.onChange(
                                                            name,
                                                            event.target.value,
                                                            type
                                                        )
                                                    }
                                                    //onBlur={(event) => props.onChange(name, event.target.value, type)}
                                                    inputProps={{
                                                        step: 1,
                                                        min: 0,
                                                        max: 100,
                                                        type: "number",
                                                        "aria-labelledby":
                                                            "input-slider",
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : type === "steps-table" ? (
                                        <div>
                                            <Grid
                                                container
                                                spacing={0}
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                            >
                                                <Grid
                                                    item
                                                    md={7}
                                                    sm={10}
                                                    xs={12}
                                                >
                                                    <Table className="regular">
                                                        <thead>
                                                            <tr>
                                                                {options.map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <th
                                                                            align="center"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <ColumnCatalog
                                                                                searchName={
                                                                                    option
                                                                                }
                                                                            />
                                                                        </th>
                                                                    )
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                value.length >
                                                                0 ? (
                                                                    value.map(
                                                                        (
                                                                            row,
                                                                            indexSteps
                                                                        ) => (
                                                                            <tr
                                                                                key={
                                                                                    indexSteps
                                                                                }
                                                                            >
                                                                                {
                                                                                    //Usualmente la lista de pasos empieza en 0. Se le suma 1 al momento de imprimir
                                                                                    options.map(
                                                                                        (
                                                                                            option,
                                                                                            indexOptions
                                                                                        ) => (
                                                                                            <td
                                                                                                align="left"
                                                                                                key={
                                                                                                    indexOptions
                                                                                                }
                                                                                            >
                                                                                                {Number.isInteger(
                                                                                                    row[
                                                                                                        option
                                                                                                    ]
                                                                                                )
                                                                                                    ? indexSteps +
                                                                                                      1
                                                                                                    : row[
                                                                                                          option
                                                                                                      ]}
                                                                                            </td>
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                padding:
                                                                                    "10px",
                                                                            }}
                                                                        ></td>
                                                                        <td></td>
                                                                    </tr>
                                                                ) //agregar fila vacía
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={7}
                                                    sm={10}
                                                    xs={12}
                                                    className={
                                                        classes.ButtonAlignRight
                                                    }
                                                >
                                                    {value.length > 0 ? (
                                                        <Tooltip title="Ver tabla completa para editar">
                                                            <Button
                                                                startIcon={
                                                                    <TablaIcono
                                                                        width="17px"
                                                                        height="17px"
                                                                    />
                                                                }
                                                                color="primary"
                                                                size="small"
                                                                onClick={() =>
                                                                    props.onClick(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Detalle
                                                            </Button>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Abrir modal para agregar paso">
                                                            <Button
                                                                startIcon={
                                                                    <AgregarIcono
                                                                        width="15px"
                                                                        height="15px"
                                                                    />
                                                                }
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                    marginLeft:
                                                                        "3px",
                                                                }}
                                                                onClick={() =>
                                                                    props.onClick(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                Agregar paso
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={7}
                                                    sm={10}
                                                    xs={12}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#f44336",
                                                            margin: "0 14px",
                                                            fontSize: "0.75rem",
                                                            display: error
                                                                ? "block"
                                                                : "none",
                                                        }}
                                                    >
                                                        {error
                                                            ? errorHelper[name]
                                                            : ""}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ) : type === "catalog-selector" ? (
                                        <Grid item md={7} sm={12}>
                                            <AddCatalogFrom
                                                name={name}
                                                value={value}
                                                variant={variant}
                                                error={error}
                                                onChange={(event) =>
                                                    props.onChange(
                                                        name,
                                                        event.target.value,
                                                        type
                                                    )
                                                }
                                                catalogs={props.catalogs}
                                                addNewCatalog={(catalog) =>
                                                    props.addNewCatalog(catalog)
                                                }
                                                helperText={
                                                    error
                                                        ? errorHelper[name]
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                    ) : type ===
                                      "related-description-selector" ? (
                                        <Autocomplete
                                            multiple
                                            options={relatedOptions}
                                            getOptionLabel={(option) =>
                                                typeof option == "undefined"
                                                    ? ""
                                                    : option
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant={variant}
                                                    error={error}
                                                    placeholder="Buscar Opción"
                                                />
                                            )}
                                            value={
                                                typeof value == "undefined" ||
                                                value === ""
                                                    ? []
                                                    : value.map(
                                                          (value) => value
                                                      )
                                            }
                                            filterSelectedOptions
                                            noOptionsText="No hay opciones disponibles"
                                            size="small"
                                            className={
                                                classes.consistentInputSize
                                            }
                                            onChange={(event, value) =>
                                                props.onChange(
                                                    name,
                                                    value,
                                                    type
                                                )
                                            }
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="default"
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                    />
                                                ))
                                            }
                                        />
                                    ) : type === "radio" ? (
                                        <RadioGroup
                                            row
                                            name={name}
                                            value={setOption(value) ?? ""}
                                            onChange={(event) =>
                                                props.onChange(
                                                    name,
                                                    event.target.value,
                                                    type
                                                )
                                            }
                                        >
                                            {options
                                                ? options.map(
                                                      (option, index) => (
                                                          <FormControlLabel
                                                              key={index}
                                                              value={setOption(
                                                                  option
                                                              )}
                                                              control={
                                                                  <Radio color="primary" />
                                                              }
                                                              label={setOption(
                                                                  option
                                                              )}
                                                          />
                                                      )
                                                  )
                                                : null}
                                        </RadioGroup>
                                    ) : type === "checkbox" ? (
                                        <Checkbox
                                            name={name}
                                            color="primary"
                                            checked={value ?? false}
                                            onChange={(event) =>
                                                props.onChange(
                                                    name,
                                                    event.target.checked,
                                                    type
                                                )
                                            }
                                        />
                                    ) : type === "switchButton" ? (
                                        <Switch
                                            name={name}
                                            color="primary"
                                            checked={value || false}
                                            onChange={(event) =>
                                                props.onChange(
                                                    name,
                                                    event.target.checked,
                                                    type
                                                )
                                            }
                                        />
                                    ) : type === "label" ? (
                                        <Typography
                                            className={classes.Typography}
                                            name={name}
                                        >
                                            {" "}
                                            {requiredMark ? (
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            ) : (
                                                ""
                                            )}{" "}
                                            {text}
                                        </Typography>
                                    ) : type === "addButton" ? (
                                        <Grid
                                            item
                                            md={7}
                                            xs={12}
                                            className={classes.ButtonAlignRight}
                                        >
                                            <Button
                                                className={classes.addButton}
                                                startIcon={
                                                    <AgregarIcono
                                                        width="15px"
                                                        height="15px"
                                                    />
                                                }
                                                color="primary"
                                                size="small"
                                                onClick={onClick}
                                            >
                                                {text}
                                            </Button>
                                        </Grid>
                                    ) : type === "expandable-checkbox" ? (
                                        <TreeView
                                            style={{ marginTop: "10px" }}
                                            className={classes.treeView}
                                            defaultCollapseIcon={
                                                <ExpandMoreIcon />
                                            }
                                            defaultExpandIcon={
                                                <ChevronRightIcon />
                                            }
                                            expanded={expanded}
                                            onNodeToggle={handleToggle}
                                        >
                                            <TreeItem
                                                nodeId="0"
                                                label={
                                                    "Selecciona una de las opciones disponibles"
                                                }
                                            >
                                                <TreeItem
                                                    nodeId="1"
                                                    label={
                                                        <FormGroup row>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={
                                                                            allChannelsCheckbox
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            props.onChange(
                                                                                "Todos",
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                                type,
                                                                                1
                                                                            )
                                                                        }
                                                                        name="Todos"
                                                                    />
                                                                }
                                                                label="Todos"
                                                            />
                                                        </FormGroup>
                                                    }
                                                />
                                                {channels
                                                    ? Object.keys(channels).map(
                                                          (channel, index) => (
                                                              <div
                                                                  key={index}
                                                                  style={{
                                                                      display:
                                                                          "flex",
                                                                      alignItems:
                                                                          "center",
                                                                  }}
                                                              >
                                                                  <TreeItem
                                                                      nodeId={`${
                                                                          index +
                                                                          2
                                                                      }`}
                                                                      key={
                                                                          index
                                                                      }
                                                                      label={
                                                                          <FormGroup
                                                                              row
                                                                          >
                                                                              <FormControlLabel
                                                                                  control={
                                                                                      <Checkbox
                                                                                          color="primary"
                                                                                          checked={
                                                                                              channels[
                                                                                                  channel
                                                                                              ]
                                                                                          }
                                                                                          onChange={(
                                                                                              event
                                                                                          ) =>
                                                                                              props.onChange(
                                                                                                  channel,
                                                                                                  event
                                                                                                      .target
                                                                                                      .checked,
                                                                                                  type,
                                                                                                  index
                                                                                              )
                                                                                          }
                                                                                          name={
                                                                                              channel
                                                                                          }
                                                                                      />
                                                                                  }
                                                                                  label={capitalizeFirstLetter(
                                                                                      channel
                                                                                  )}
                                                                              />
                                                                          </FormGroup>
                                                                      }
                                                                  />
                                                                  {/* Ayuda para Whats App */}
                                                                  {channel ===
                                                                      "whatsapp" && (
                                                                      <Tooltip
                                                                          title={
                                                                              "Para habilitar el menú interactivo el título del menú en la sección de mensajes y el Nombre del flujo no deben exceder 24 y 20 caracteres, respectivamente."
                                                                          }
                                                                      >
                                                                          <HelpOutlineIcon
                                                                              color="primary"
                                                                              fontSize="small"
                                                                          />
                                                                      </Tooltip>
                                                                  )}
                                                              </div>
                                                          )
                                                      )
                                                    : null}
                                            </TreeItem>
                                        </TreeView>
                                    ) : type === "response-table-attributes" ? (
                                        <Grid
                                            item
                                            md={8}
                                            lg={8}
                                            xs={12}
                                            sm={12}
                                        >
                                            {responseAttributes
                                                ? responseAttributes.map(
                                                      (obj, index) => (
                                                          <div
                                                              style={{
                                                                  display:
                                                                      "flex",
                                                              }}
                                                              key={index}
                                                          >
                                                              <div>
                                                                  <label
                                                                      style={{
                                                                          textAlign:
                                                                              "left",
                                                                      }}
                                                                  >
                                                                      Nombre del
                                                                      campo{" "}
                                                                  </label>
                                                                  <TextField
                                                                      style={{
                                                                          width: "95%",
                                                                      }}
                                                                      name={
                                                                          "Attribute_" +
                                                                          index
                                                                      }
                                                                      value={
                                                                          obj.attribute
                                                                      }
                                                                      multiline
                                                                      fullWidth={
                                                                          typeof value ==
                                                                          "undefined"
                                                                              ? false
                                                                              : value.length >
                                                                                25
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      variant={
                                                                          variant
                                                                      }
                                                                      size="small"
                                                                      error={
                                                                          error
                                                                      }
                                                                      onChange={(
                                                                          userInput
                                                                      ) =>
                                                                          updateResponseAttributes(
                                                                              userInput,
                                                                              index
                                                                          )
                                                                      }
                                                                      placeholder={
                                                                          placeholder
                                                                              ? placeholder
                                                                              : ""
                                                                      }
                                                                  />
                                                              </div>
                                                              <div>
                                                                  <label
                                                                      style={{
                                                                          textAlign:
                                                                              "left",
                                                                      }}
                                                                  >
                                                                      Descripción{" "}
                                                                  </label>
                                                                  <TextField
                                                                      name={
                                                                          "Description_" +
                                                                          index
                                                                      }
                                                                      value={
                                                                          obj.description
                                                                      }
                                                                      multiline
                                                                      fullWidth={
                                                                          typeof value ==
                                                                          "undefined"
                                                                              ? false
                                                                              : value.length >
                                                                                25
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      variant={
                                                                          variant
                                                                      }
                                                                      size="small"
                                                                      error={
                                                                          error
                                                                      }
                                                                      onChange={(
                                                                          userInput
                                                                      ) =>
                                                                          updateResponseAttributes(
                                                                              userInput,
                                                                              index
                                                                          )
                                                                      }
                                                                      placeholder={
                                                                          placeholder
                                                                              ? placeholder
                                                                              : ""
                                                                      }
                                                                  />
                                                              </div>
                                                              <IconButton
                                                                  color="secondary"
                                                                  size="small"
                                                                  onClick={() =>
                                                                      removeObjectResponse(
                                                                          index
                                                                      )
                                                                  }
                                                              >
                                                                  <TrashCan
                                                                      width="20px"
                                                                      height="20px"
                                                                  />
                                                              </IconButton>
                                                          </div>
                                                      )
                                                  )
                                                : null}
                                        </Grid>
                                    ) : type === "jump-selector" ? (
                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                select
                                                disabled={disabled}
                                                name={name}
                                                value={value ?? ""}
                                                className={
                                                    classes.consistentSelectorSize
                                                }
                                                fullWidth
                                                variant={variant}
                                                size="small"
                                                onChange={(event) =>
                                                    props.onChange(
                                                        name,
                                                        event.target.value,
                                                        type
                                                    )
                                                }
                                            >
                                                <MenuItem value={""}>
                                                    Ninguno
                                                </MenuItem>
                                                {fowardStepsDescription.length !==
                                                0 ? (
                                                    fowardStepsDescription.map(
                                                        (step, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={
                                                                    step.value
                                                                }
                                                            >
                                                                {step.option}
                                                            </MenuItem>
                                                        )
                                                    )
                                                ) : (
                                                    <MenuItem value={""}>
                                                        No hay opciones
                                                        disponibles
                                                    </MenuItem>
                                                )}
                                            </TextField>
                                        </Grid>
                                    ) : type === "table-checkbox" ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                className={classes.Typography}
                                                name={name}
                                            >
                                                {" "}
                                                {requiredMark ? (
                                                    <span
                                                        style={{ color: "red" }}
                                                    >
                                                        *
                                                    </span>
                                                ) : (
                                                    ""
                                                )}{" "}
                                                {text}
                                            </Typography>
                                            <Tooltip title={detailText}>
                                                <HelpOutlineIcon
                                                    color="primary"
                                                    fontSize="small"
                                                />
                                            </Tooltip>
                                            <Checkbox
                                                name={name}
                                                color="primary"
                                                checked={value}
                                                onChange={(event) =>
                                                    props.onChange(
                                                        name,
                                                        event.target.checked,
                                                        type
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : type === "mention-text-field" ? (
                                        <div>
                                            <MentionsInput
                                                value={!value ? "" : value}
                                                onChange={handleChange}
                                                style={StyledMentionInput}
                                                className={
                                                    classes.consistentInputSize
                                                }
                                                allowSpaceInQuery
                                                allowSuggestionsAboveCursor={
                                                    true
                                                }
                                            >
                                                <Mention
                                                    //Estilo del etiqueta
                                                    style={{
                                                        backgroundColor:
                                                            "#E0E0E0",
                                                        padding: "3px",
                                                        border: "1px solid #E0E0E0",
                                                        height: "32px",
                                                        borderRadius: "16px",
                                                        margin: "-4px",
                                                    }}
                                                    trigger="@"
                                                    //formato de la variable en el state
                                                    markup="{{__id__}}"
                                                    //formato de la variable en visualmente
                                                    displayTransform={(id) =>
                                                        `${id}`
                                                    }
                                                    //filtrado de datos
                                                    data={autoCompleteList}
                                                    //Html personalizado de cada elemento idividual de la lista
                                                    renderSuggestion={(
                                                        suggestion
                                                    ) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: "100%",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    margin: "0",
                                                                }}
                                                            >
                                                                {
                                                                    suggestion.display
                                                                }
                                                            </p>
                                                            <span
                                                                style={{
                                                                    color: "#B2B2B2",
                                                                    wordBreak:
                                                                        "initial",
                                                                    fontSize:
                                                                        "13px",
                                                                }}
                                                            >
                                                                &nbsp;•{" "}
                                                                {
                                                                    suggestion.list
                                                                }
                                                            </span>
                                                        </div>
                                                    )}
                                                />
                                            </MentionsInput>
                                            {/* mensaje de error */}
                                            <span
                                                style={{
                                                    color: error
                                                        ? "#f44336"
                                                        : "#e48922",
                                                    margin: "0 14px",
                                                    fontSize: "0.75rem",
                                                    display: errorHelper[name]
                                                        ? "block"
                                                        : "none",
                                                }}
                                            >
                                                {errorHelper[name]}
                                            </span>
                                        </div>
                                    ) : //============= Input doble en la sección de mensajes ============
                                    type === "double-text-field" ? (
                                        <Grid
                                            item
                                            md={9}
                                            lg={7}
                                            xs={12}
                                            sm={12}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                }}
                                            >
                                                {doubleInput
                                                    ? doubleInput.map(
                                                          (field, index) => (
                                                              <div key={index}>
                                                                  {field.type ===
                                                                  "text-field" ? (
                                                                      <TextField
                                                                          disabled={
                                                                              field.disabled
                                                                          }
                                                                          style={
                                                                              field.configKey
                                                                                  ? {
                                                                                        backgroundColor:
                                                                                            "#b2b2b2",
                                                                                        overflow:
                                                                                            "hidden",
                                                                                    }
                                                                                  : {
                                                                                        backgroundColor:
                                                                                            "#fafafa",
                                                                                    }
                                                                          }
                                                                          name={
                                                                              field.name
                                                                          }
                                                                          value={
                                                                              field.name ===
                                                                              "QNA_MIN_SCORE"
                                                                                  ? ` 0% - ${
                                                                                        messages[
                                                                                            "QNA_MAX_SCORE"
                                                                                        ] -
                                                                                        1
                                                                                    }% `
                                                                                  : Array.isArray(
                                                                                        value
                                                                                    )
                                                                                  ? value[
                                                                                        index
                                                                                    ]
                                                                                  : value
                                                                          }
                                                                          variant={
                                                                              variant
                                                                          }
                                                                          multiline
                                                                          size="small"
                                                                          error={
                                                                              Array.isArray(
                                                                                  error
                                                                              )
                                                                                  ? error[
                                                                                        index
                                                                                    ]
                                                                                  : error
                                                                          }
                                                                          helperText={
                                                                              errorHelper[
                                                                                  name
                                                                              ]
                                                                          }
                                                                          onChange={(
                                                                              event
                                                                          ) =>
                                                                              props.onChange(
                                                                                  field.name,
                                                                                  event
                                                                                      .target
                                                                                      .value,
                                                                                  type,
                                                                                  field.required
                                                                              )
                                                                          }
                                                                          placeholder={
                                                                              field.placeholder
                                                                          }
                                                                      />
                                                                  ) : field.type ===
                                                                    "mentions" ? (
                                                                      <div>
                                                                          <MentionsInput
                                                                              disabled={
                                                                                  field.disabled
                                                                              }
                                                                              value={
                                                                                  typeof value ===
                                                                                  "undefined"
                                                                                      ? ""
                                                                                      : Array.isArray(
                                                                                            value
                                                                                        )
                                                                                      ? value[
                                                                                            index
                                                                                        ]
                                                                                      : value
                                                                              }
                                                                              name={
                                                                                  field.name
                                                                              }
                                                                              onChange={(
                                                                                  event
                                                                              ) =>
                                                                                  props.onChange(
                                                                                      field.name,
                                                                                      event
                                                                                          .target
                                                                                          .value,
                                                                                      type,
                                                                                      field.required
                                                                                  )
                                                                              }
                                                                              style={
                                                                                  StyledMentionInput
                                                                              }
                                                                              error={
                                                                                  Array.isArray(
                                                                                      error
                                                                                  )
                                                                                      ? error[
                                                                                            index
                                                                                        ].toString()
                                                                                      : error
                                                                              }
                                                                              className={
                                                                                  classes.consistentMentionSize
                                                                              }
                                                                              allowSpaceInQuery
                                                                              allowSuggestionsAboveCursor={
                                                                                  true
                                                                              }
                                                                          >
                                                                              <Mention
                                                                                  style={{
                                                                                      backgroundColor:
                                                                                          "#E0E0E0",
                                                                                      padding:
                                                                                          "3px",
                                                                                      border: "1px solid #E0E0E0",
                                                                                      height: "32px",
                                                                                      borderRadius:
                                                                                          "16px",
                                                                                      margin: "-4px",
                                                                                  }}
                                                                                  //style={{backgroundColor:'red'}}
                                                                                  trigger="@"
                                                                                  //formato de la variable en el state
                                                                                  markup="{{__id__}}"
                                                                                  //formato de la variable en visualmente
                                                                                  displayTransform={(
                                                                                      id
                                                                                  ) =>
                                                                                      `${id}`
                                                                                  }
                                                                                  //filtrado de datos
                                                                                  data={
                                                                                      autoCompleteList
                                                                                  }
                                                                                  appendSpaceOnAdd
                                                                                  //Html personalizado de cada elemento idividual de la lista
                                                                                  renderSuggestion={(
                                                                                      suggestion
                                                                                  ) => (
                                                                                      <div
                                                                                          style={{
                                                                                              display:
                                                                                                  "flex",
                                                                                              width: "200px",
                                                                                          }}
                                                                                      >
                                                                                          <p
                                                                                              style={{
                                                                                                  margin: "0",
                                                                                              }}
                                                                                          >
                                                                                              {
                                                                                                  suggestion.display
                                                                                              }
                                                                                          </p>
                                                                                          <span
                                                                                              style={{
                                                                                                  color: "#B2B2B2",
                                                                                                  fontSize:
                                                                                                      "13px",
                                                                                              }}
                                                                                          >
                                                                                              &nbsp;•{" "}
                                                                                              {
                                                                                                  suggestion.list
                                                                                              }
                                                                                          </span>
                                                                                      </div>
                                                                                  )}
                                                                              ></Mention>
                                                                          </MentionsInput>
                                                                          {/* Mensaje de error de mention */}
                                                                          <span
                                                                              style={{
                                                                                  color: error?.[
                                                                                      index
                                                                                  ]
                                                                                      ? "#f44336"
                                                                                      : "#e48922",
                                                                                  fontSize:
                                                                                      "0.75rem",
                                                                                  display:
                                                                                      errorHelper[
                                                                                          field
                                                                                              .name
                                                                                      ]
                                                                                          ? "block"
                                                                                          : "none",
                                                                              }}
                                                                          >
                                                                              {
                                                                                  errorHelper[
                                                                                      field
                                                                                          .name
                                                                                  ]
                                                                              }
                                                                          </span>
                                                                      </div>
                                                                  ) : null}
                                                              </div>
                                                          )
                                                      )
                                                    : null}
                                            </div>
                                        </Grid>
                                    ) : // =================Input de ProcessBranches ================
                                    type === "nested-flows-table" ? (
                                        <SubflowsTable
                                            processBranches={processBranches}
                                            allProcessesNames={
                                                allProcessesNames
                                            }
                                            addProcessBranch={addProcessBranch}
                                            updateProcessBranches={
                                                updateProcessBranches
                                            }
                                            removeProcessBranch={
                                                removeProcessBranch
                                            }
                                            classes={classes}
                                            optionsCatalog={props.catalogs}
                                            optionsRegEx={props.regEx}
                                        />
                                    ) : type === "body-request-table" ? (
                                        <Grid item>
                                            <BodyRequestTable
                                                currentProcess={currentProcess}
                                                bodyRequestFields={
                                                    bodyRequestFields
                                                }
                                                addBodyRequest={addBodyRequest}
                                                editBodyRequest={
                                                    editBodyRequest
                                                }
                                                removeBodyRequest={
                                                    removeBodyRequest
                                                }
                                                classes={classes}
                                                StyledMentionInput={
                                                    StyledMentionInput
                                                }
                                                handleChange={props.onChange}
                                            />
                                            <span
                                                style={{
                                                    color: "#f44336",
                                                    margin: "0 14px",
                                                    fontSize: "0.75rem",
                                                    display: error
                                                        ? "block"
                                                        : "none",
                                                }}
                                            >
                                                {error ? errorHelper[name] : ""}
                                            </span>
                                        </Grid>
                                    ) : type === "response-branches-table" ? (
                                        <div>
                                            {
                                                responseBranches ? (
                                                    <ResponseBranchesTable
                                                        responseBranches={
                                                            responseBranches
                                                        }
                                                        classes={classes}
                                                        allProcessesNames={
                                                            allProcessesNames
                                                        }
                                                        StyledMentionInput={
                                                            StyledMentionInput
                                                        }
                                                        autoCompleteList={
                                                            autoCompleteList
                                                        }
                                                        removeObjectResponse={
                                                            removeObjectResponse
                                                        }
                                                        updateResponseAttributes={
                                                            updateResponseAttributes
                                                        }
                                                        addResponseBranch={
                                                            addResponseBranch
                                                        }
                                                        updateResponseBranch={
                                                            updateResponseBranch
                                                        }
                                                        removeResponseBranch={
                                                            removeResponseBranch
                                                        }
                                                        currentProcess={
                                                            currentProcess
                                                        }
                                                    />
                                                ) : null //Response branch undefined
                                            }
                                        </div>
                                    ) : type === "menu-options-table" ? (
                                        <div>
                                            <SubmenuTable
                                                menuOptions={menuOptions}
                                                addMenuOption={addMenuOption}
                                                editMenuOption={editMenuOption}
                                                deleteMenuOption={
                                                    deleteMenuOption
                                                }
                                                classes={classes}
                                            />
                                            <span
                                                style={{
                                                    color: "#f44336",
                                                    margin: "0 14px",
                                                    fontSize: "0.75rem",
                                                    display: error
                                                        ? "block"
                                                        : "none",
                                                }}
                                            >
                                                {error ? errorHelper[name] : ""}
                                            </span>
                                        </div>
                                    ) : type === "request-headers-table" ? (
                                        <div>
                                            <RequestHeadersTable
                                                currentProcess={currentProcess}
                                                addRequestHeader={
                                                    addRequestHeader
                                                }
                                                updateRequestHeader={
                                                    updateRequestHeader
                                                }
                                                deleteRequestHeader={
                                                    deleteRequestHeader
                                                }
                                                classes={classes}
                                                StyledMentionInput={
                                                    StyledMentionInput
                                                }
                                            />
                                        </div>
                                    ) : type === "jumpOperartion-field" ? (
                                        <Grid
                                            item
                                            style={{
                                                paddingTop: "0",
                                                gap: "10px",
                                                display: "flex",
                                                width: "59.7%",
                                            }}
                                        >
                                            <TextField
                                                select
                                                name={"operator"}
                                                value={
                                                    tempStep?.["jumpOperartion"]
                                                        ?.operator
                                                }
                                                style={{ width: "100%" }}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                onChange={(e) => {
                                                    props.onChange(
                                                        e.target.name,
                                                        e.target.value,
                                                        type,
                                                        required
                                                    );
                                                }}
                                                error={stepErrors["operator"]}
                                                helperText={
                                                    errorHelper["operator"]
                                                }
                                            >
                                                {operators.length !== 0 ? (
                                                    operators.map(
                                                        (option, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {setOption(
                                                                    option.name
                                                                )}
                                                            </MenuItem>
                                                        )
                                                    )
                                                ) : (
                                                    <MenuItem value={""}>
                                                        No hay opciones
                                                        disponibles
                                                    </MenuItem>
                                                )}
                                            </TextField>
                                            <TextField
                                                name={"value"}
                                                value={
                                                    tempStep?.["jumpOperartion"]
                                                        ?.value
                                                }
                                                multiline
                                                style={{ width: "100%" }}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                onChange={(e) => {
                                                    props.onChange(
                                                        e.target.name,
                                                        e.target.value,
                                                        type,
                                                        required
                                                    );
                                                }}
                                                error={stepErrors["value"]}
                                                helperText={
                                                    errorHelper["value"]
                                                }
                                            />
                                        </Grid>
                                    ) : null //New Input
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <React.Fragment />
            )}
        </React.Fragment>
    );
}
