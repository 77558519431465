import React from "react";

//Mui Components
import { Grid } from "@material-ui/core";

//Custom Portal components
import InputChooser from "../InputChooser";
import IntebotCatalog from "../IntebotCatalog";
import MandatoryField from "../common/Fields/MandatoryField";
import AppContext from "../../Context/AppContext";
import ButtonConfirmDialog from "../common/ButtonConfirmDialog";
import { intebotDataActions } from "../DataFunctions";

//External Libraries
import Swal from "sweetalert2"; //https://sweetalert2.github.io

//CSS sheets
import "../StyleComponents/editStepContent.css";

// functions
import { getErrorFieldName } from "../FormModalComponents/ErrorFieldName";

export default class EditStepContent extends React.Component {
    state = {
        tempStep: {},
        stepErrors: {},
        stepErrorHelper: {},
        countStepErrors: 0,
        newStep: false,
        stepHasChanges: false,
    };

    stepsKeys = IntebotCatalog.StepsKeys;
    errorHelperKeys = IntebotCatalog.errorHelper;

    componentDidMount() {
        let { tempStep } = this.props;
        const { searchStepErrors, createNewTempStep } = this;
        let newStep = false;
        //Si no existe el tempStep creamos uno
        if (!tempStep) {
            tempStep = createNewTempStep();
            newStep = true;
        }

        // si tempStep no incluye las opciones de habilitar submenu, agregarlas
        if (!("enableMenuOptions" in tempStep) || !("headerMenu" in tempStep)) {
            tempStep.enableMenuOptions = false;
            tempStep.headerMenu = null;
            if (tempStep.menuOptions.length > 0) {
                tempStep.enableMenuOptions = true;
            }
        }

        //buscamos errores
        let { stepErrorsCopy, countStepErrorsCopy, stepErrorHelperCopy } =
            searchStepErrors(tempStep);
        this.setState({
            tempStep: tempStep,
            stepErrors: stepErrorsCopy,
            countStepErrors: countStepErrorsCopy,
            stepErrorHelper: stepErrorHelperCopy,
            newStep: newStep,
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { stepHasChanges, tempStep } = this.state;
        const { setStepHasChanges } = this.props;
        if (prevState.stepHasChanges !== stepHasChanges) {
            setStepHasChanges(stepHasChanges);
        }

        if (tempStep.menuOptions !== prevState.tempStep.menuOptions) {
            this.checkSubmenuChanges();
        }
    };

    createNewTempStep = () => {
        let { tempStep, currentProcess, idStep } = this.props;
        const tempStepsLength = currentProcess["steps"].length;
        //Valores por default del step
        tempStep = {
            step: tempStepsLength,
            input: "Tipo de dato",
            type: "String",
            globalValue: false,
            menuOptions: [],
            processBranches: [],
            enableMenuOptions: false,
            headerMenu: null,
            ask: "",
        };
        return tempStep;
    };

    searchStepErrors = (tempStep) => {
        const { stepsKeys, errorHelperKeys } = this;
        let stepErrorsCopy = {};
        let stepErrorHelperCopy = {};
        //Iteramos los campos de steps para buscar errores
        stepsKeys.forEach((property) => {
            switch (property.name) {
                //Buscamos errores especificos
                case "type":
                    //Fall-Through
                    break;
                case "catalog":
                    //No ponemos error
                    break;
                case "menuOptions":
                    // si el submenu esta habilitado
                    if (tempStep.enableMenuOptions) {
                        //Ponemos el error si la tabla está vacía
                        if (!tempStep[property.name] || tempStep[property.name].length === 0) {
                            stepErrorsCopy[property.name] = true;
                            stepErrorHelperCopy[property.name] = errorHelperKeys.emptyTable;
                            break;
                        }
                        //quitamos errores
                        stepErrorsCopy[property.name] = false;
                        stepErrorHelperCopy[property.name] = errorHelperKeys.removeError;
                    }
                    break;
                case "headerMenu":
                    // si el submenu esta habilitado
                    if (tempStep.enableMenuOptions) {
                        if (!tempStep[property.name] || tempStep[property.name].length === 0) {
                            stepErrorsCopy[property.name] = true;
                            stepErrorHelperCopy[property.name] = errorHelperKeys.emptyField;
                            break;
                        }
                        stepErrorsCopy[property.name] = false;
                        stepErrorHelperCopy[property.name] = errorHelperKeys.removeError;
                        break;
                    }

                    break;
                case "ask":
                    // si el submenu esta habilitado
                    if (tempStep.enableMenuOptions) {
                        // quitar errores
                        stepErrorsCopy[property.name] = false;
                        stepErrorHelperCopy[property.name] = errorHelperKeys.removeError;
                    } else {
                        if (!tempStep[property.name] || tempStep[property.name] === "") {
                            stepErrorsCopy[property.name] = true;
                            stepErrorHelperCopy[property.name] = errorHelperKeys.emptyField;
                        } else {
                            stepErrorsCopy[property.name] = false;
                            stepErrorHelperCopy[property.name] = errorHelperKeys.removeError;
                        }
                    }
                    break;
                default:
                    //Solamente ponemos errores en los campos requeridos
                    if (property.required) {
                        //Ponemos el error en todos los campos vacíos
                        if (!tempStep[property.name] || tempStep[property.name].length === 0) {
                            stepErrorsCopy[property.name] = true;
                            stepErrorHelperCopy[property.name] = errorHelperKeys.emptyField;
                            break;
                        }
                        stepErrorsCopy[property.name] = false;
                        stepErrorHelperCopy[property.name] = errorHelperKeys.removeError;
                        break;
                    }
                    break;
            }
        });
        let countStepErrorsCopy = Object.values(stepErrorsCopy).reduce((a, item) => a + item, 0);
        return { stepErrorsCopy, countStepErrorsCopy, stepErrorHelperCopy };
    };

    handleStepChange = (targetName, targetValue, targetType, targetRequired, targetIndex) => {
        //console.log(targetName, targetValue, targetType, targetRequired, targetIndex);
        const { globalValues, updateContextAttribute } = this.context;
        const { tempStep, stepErrors, stepErrorHelper } = this.state;
        const { errorHelperKeys } = this;
        const { currentProcess } = this.props;
        let tempStepCopy = { ...tempStep };
        let stepErrorsCopy = { ...stepErrors };
        let stepErrorHelperCopy = { ...stepErrorHelper };
        let globalValuesCopy = [...globalValues];
        //Buscamos Casos especificos y Errores
        switch (targetName) {
            case "input":
                tempStepCopy[targetName] = targetValue; //Guardamos el valor
                //ponemos el error para evitar que se guarde una catalogo/tipo vacío
                if (targetValue === "Tipo de dato") {
                    tempStepCopy["type"] = "";
                    stepErrorsCopy = { ...stepErrorsCopy, type: true, catalog: false };
                    stepErrorHelperCopy = {
                        ...stepErrorHelperCopy,
                        catalog: errorHelperKeys.removeError,
                        type: errorHelperKeys.emptyField,
                    };
                    break;
                }
                if (targetValue === "Catálogo") {
                    tempStepCopy["catalog"] = "";
                    stepErrorsCopy = { ...stepErrorsCopy, type: false, catalog: true };
                    stepErrorHelperCopy = {
                        ...stepErrorHelperCopy,
                        catalog: errorHelperKeys.emptyField,
                        type: errorHelperKeys.removeError,
                    };
                    break;
                }
                break;
            case "globalValue":
                let currentStepDescription = tempStepCopy["description"];
                let indexStepDescription = globalValuesCopy.findIndex(
                    (globalValue) => globalValue.id === currentStepDescription
                );
                tempStepCopy[targetName] = targetValue; //Guardamos el valor
                if (targetValue) {
                    //Si ya existe ese valor global, entonces notificamos al usuario
                    if (indexStepDescription > -1) {
                        Swal.fire({
                            icon: "info",
                            title: "Valor global duplicado",
                            text: "El valor de este paso se sobrescribirá",
                        });
                        break;
                    }
                    //Si no es un valor duplicado, agregamos el nuevo valor global
                    globalValuesCopy.push({
                        flow: currentProcess["processName"],
                        id: currentStepDescription,
                        display: `${currentStepDescription}`,
                        list: "Global",
                    });
                    updateContextAttribute("globalValues", globalValuesCopy);
                    break;
                }
                if (globalValuesCopy[indexStepDescription]["list"] === "Constante") {
                    //NO eliminamos las constantes
                    break;
                }
                //Si pone en false el checkbox, eliminamos el valor global
                globalValuesCopy.splice(indexStepDescription, 1);
                updateContextAttribute("globalValues", globalValuesCopy);
                break;
            case "description":
                tempStepCopy[targetName] = targetValue; //Guardamos el valor
                //funcionalidad de actualizar la descripción en el global value
                let globalValue = tempStepCopy["globalValue"];
                if (globalValue) {
                    let oldDescription = tempStep.description;
                    let indexStepDescription = globalValuesCopy.findIndex(
                        (globalValue) => globalValue.id === oldDescription
                    );
                    let duplicatedGlobalValueIndex = globalValuesCopy.findIndex(
                        (globalValue) => globalValue.id === targetValue
                    );
                    if (duplicatedGlobalValueIndex > -1) {
                        Swal.fire({
                            icon: "info",
                            title: "Valor global duplicado",
                            text: "El valor de este paso se sobrescribirá",
                        });
                        globalValuesCopy.splice(indexStepDescription, 1);
                    } else {
                        globalValuesCopy.splice(indexStepDescription, 1, {
                            flow: currentProcess["processName"],
                            id: targetValue,
                            display: `${targetValue}`,
                            list: "Global",
                        });
                    }
                    updateContextAttribute("globalValues", globalValuesCopy);
                }
                //validar errores
                if (targetRequired) {
                    if (targetValue === "") {
                        stepErrorsCopy[targetName] = true;
                        stepErrorHelperCopy[targetName] = errorHelperKeys.emptyField;
                        break;
                    }
                    stepErrorsCopy[targetName] = false;
                    stepErrorHelperCopy[targetName] = errorHelperKeys.removeError;
                    break;
                }
                break;

            case "enableMenuOptions":
                tempStepCopy[targetName] = targetValue; //Guardamos el valor
                if (targetValue) {
                    // si esta habilitado, agregamos el error al encabezado y a la tabla
                    stepErrorsCopy["headerMenu"] = true;
                    stepErrorHelperCopy["headerMenu"] = errorHelperKeys.emptyField;
                    stepErrorsCopy["menuOptions"] = true;
                    stepErrorHelperCopy["menuOptions"] = errorHelperKeys.emptyTable;
                    // quitamos el error de mensaje de intebot
                    stepErrorsCopy["ask"] = false;
                    stepErrorHelperCopy["ask"] = errorHelperKeys.removeError;
                    break;
                }
                stepErrorsCopy["headerMenu"] = false;
                stepErrorHelperCopy["headerMenu"] = errorHelperKeys.removeError;
                stepErrorsCopy["menuOptions"] = false;
                stepErrorHelperCopy["menuOptions"] = errorHelperKeys.removeError;
                // quitamos informacion del campo y de la tabla
                tempStepCopy["headerMenu"] = null;
                tempStepCopy["menuOptions"] = [];

                // agregamos el error de mensaje de intebot
                if (tempStepCopy["ask"] === "") {
                    stepErrorsCopy["ask"] = true;
                    stepErrorHelperCopy["ask"] = errorHelperKeys.emptyField;
                    break;
                }
                stepErrorsCopy["ask"] = false;
                stepErrorHelperCopy["ask"] = errorHelperKeys.removeError;
                break;

            case "headerMenu":
                tempStepCopy[targetName] = targetValue; //Guardamos el valor
                if (targetValue === "") {
                    stepErrorsCopy[targetName] = true;
                    stepErrorHelperCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }

                //sin errores
                stepErrorsCopy[targetName] = false;
                stepErrorHelperCopy[targetName] = errorHelperKeys.removeError;
                break;

            case "JumpCriteria":
                tempStepCopy[targetName] = targetValue; //Guardamos el valor
                if (targetValue === "Tipo de dato") {
                    tempStepCopy["allowJump"] = "Any";
                    delete tempStepCopy["jumpOperartion"];
                    delete stepErrorsCopy["value"];
                    delete stepErrorHelperCopy["value"];
                    break;
                }
                if (targetValue === "Catálogo") {
                    tempStepCopy["allowJump"] = "YES";
                    delete tempStepCopy["jumpOperartion"];
                    delete stepErrorsCopy["value"];
                    delete stepErrorHelperCopy["value"];
                    break;
                }
                if (targetValue === "Valor") {
                    tempStepCopy["allowJump"] = "";
                    tempStepCopy["jumpOperartion"] = {
                        operator: "=",
                        value: "",
                    };
                    stepErrorsCopy["value"] = true;
                    stepErrorHelperCopy["value"] = errorHelperKeys.emptyField;
                    break;
                }
                break;
            case "jumpStep":
                tempStepCopy[targetName] = targetValue; //Guardamos el valor
                if (targetValue === "") {
                    delete tempStepCopy["jumpStep"];
                    delete tempStepCopy["allowJump"];
                    delete tempStepCopy["JumpCriteria"];
                    break;
                }
                //Valores por defecto al seleccionar un salto de paso
                tempStepCopy["JumpCriteria"] = "Tipo de dato";
                tempStepCopy["allowJump"] = "Any";
                break;
            case "operator":
                tempStepCopy["jumpOperartion"] = tempStepCopy["jumpOperartion"] || {};
                tempStepCopy["jumpOperartion"][targetName] = targetValue; //Guardamos el valor
                break;
            case "value":
                tempStepCopy["jumpOperartion"] = tempStepCopy["jumpOperartion"] || {};
                tempStepCopy["jumpOperartion"][targetName] = targetValue; //Guardamos el valor
                if (targetValue === "") {
                    stepErrorsCopy[targetName] = true;
                    stepErrorHelperCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }
                stepErrorsCopy[targetName] = false;
                stepErrorHelperCopy[targetName] = errorHelperKeys.removeError;
                break;

            default:
                tempStepCopy[targetName] = targetValue; //Guardamos el valor
                //Ponemos el error en todos los campos vacíos
                if (targetRequired) {
                    if (!tempStepCopy[targetName] || tempStepCopy[targetName].length === 0) {
                        stepErrorsCopy[targetName] = true;
                        stepErrorHelperCopy[targetName] = errorHelperKeys.emptyField;
                        break;
                    }
                    stepErrorsCopy[targetName] = false;
                    stepErrorHelperCopy[targetName] = errorHelperKeys.removeError;
                    break;
                }
                break;
        }
        let countStepErrorsCopy = Object.values(stepErrorsCopy).reduce((a, item) => a + item, 0);
        this.setState({
            tempStep: tempStepCopy,
            stepErrors: stepErrorsCopy,
            countStepErrors: countStepErrorsCopy,
            stepErrorHelper: stepErrorHelperCopy,
            stepHasChanges: true,
        });
    };

    setJumpableSteps = () => {
        const { stepsList } = this.props;
        const { tempStep } = this.state;
        let stepsListLength = stepsList.length;
        let currentStepDescription = tempStep.description;
        let findIndexStep = stepsList.findIndex(
            (step) => step.description === currentStepDescription
        );
        let idStep = findIndexStep + 1;
        let fowardStepsDescription = [];
        let fowardStepsNumber = [];
        let stepPostion = 0;
        let finishProccesJump =
            stepsList.at(-1)?.description === tempStep.description ? false : true;
        //Contamos cuantos Saltos puede dar el flujo
        for (idStep; idStep < stepsListLength; idStep++) {
            let fowardSteps = idStep;
            fowardStepsNumber.push(idStep);
            //Guardamos las descripciones de los flujos y les damos la posición del paso en termino de saltos
            for (stepPostion; stepPostion < fowardStepsNumber.length; stepPostion++) {
                fowardStepsDescription.push({
                    option: stepsList[fowardSteps].description,
                    value: stepPostion,
                });
            }
        }
        //Si estamos en otro paso que no sea el útimo agregamos la posibilidad de terminar el flujo y además quitamos el primer paso siguiente para saltar solo se pueden hacer saltos de 2
        if (finishProccesJump) {
            //Si el paso es el penútltimo significa que ya no se pueden dar mas saltos y solo agregamos la opción de finalizar flujp
            if (fowardStepsDescription.length === 0) {
                fowardStepsDescription.push({ option: "Finalizar Flujo", value: 2 });
            }
            //todos los pasos anteriores agregamos finalizar flujo con su valor en saltos
            else {
                fowardStepsDescription.push({
                    option: "Finalizar Flujo",
                    value: fowardStepsDescription.at(-1).value + 1,
                });
                fowardStepsDescription.shift();
            }
        }
        return fowardStepsDescription;
    };

    addMenuOption = (newOption) => {
        const { tempStep } = this.state;
        let tempStepCopy = JSON.parse(JSON.stringify(tempStep));
        // if (!tempStepCopy['menuOptions']) {
        //     tempStepCopy.menuOptions = []
        // }
        tempStepCopy["menuOptions"].push(newOption);
        this.setState({
            tempStep: tempStepCopy,
            stepHasChanges: true,
        });
    };

    // verificar si se modifico la tabla submenu para agregar o quitar los errores
    checkSubmenuChanges = () => {
        const { tempStep, stepErrors, stepErrorHelper } = this.state;
        const { errorHelperKeys } = this;
        let stepErrorsCopy = { ...stepErrors };
        let stepErrorHelperCopy = { ...stepErrorHelper };

        if (tempStep["menuOptions"].length > 0 || !tempStep["enableMenuOptions"]) {
            stepErrorsCopy["menuOptions"] = false;
            stepErrorHelperCopy["menuOptions"] = errorHelperKeys.removeError;
        } else {
            stepErrorsCopy["menuOptions"] = true;
            stepErrorHelperCopy["menuOptions"] = errorHelperKeys.emptyField;
        }

        let countStepErrorsCopy = Object.values(stepErrorsCopy).reduce((a, item) => a + item, 0);

        this.setState({
            stepErrors: stepErrorsCopy,
            countStepErrors: countStepErrorsCopy,
            stepErrorHelper: stepErrorHelperCopy,
        });
    };

    editMenuOption = (optionEdited, index) => {
        const { tempStep } = this.state;
        let tempStepCopy = { ...tempStep };
        tempStepCopy["menuOptions"][index] = optionEdited;
        this.setState({
            tempStepCopy: tempStepCopy,
            stepHasChanges: true,
        });
    };

    deleteMenuOption = (index) => {
        const { tempStep } = this.state;
        let tempStepCopy = { ...tempStep };
        let menuOptionsCopy = tempStepCopy["menuOptions"];
        menuOptionsCopy.splice(index, 1);
        tempStepCopy["menuOptions"] = menuOptionsCopy;
        this.setState({
            tempStep: tempStepCopy,
            stepHasChanges: true,
        });
        this.checkSubmenuChanges();
    };

    //Función para agregar una nueva Expresión Reg.
    addNewRegEx = async (newRegEx) => {
        //Desestructuramos el arreglo de Exp. Reg. del context
        const { regExTemp, updateContextAttribute, getStorageData } = this.context;
        const { tempStep, stepErrors, stepErrorHelper } = this.state;
        const { errorHelperKeys } = this;

        let { token } = getStorageData(["token"]);
        //Guardamos el Nuevo regex
        let newRegExCopy = JSON.parse(JSON.stringify(regExTemp));
        newRegExCopy.push({
            name: newRegEx.name,
            expresion: newRegEx.expresion,
            description: newRegEx.description,
        });

        //Actualizamos el estado de la aplicación para mostrar el loading
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");

        //Mandamos a guardar a base

        let data = {
            dataTypes: newRegExCopy,
            token,
        };

        let res = await intebotDataActions(
            data,
            "updateConfigurationDataTypes&enviroment=Test",
            "ModelConfigurationOperations"
        );
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");

        switch (responseCode) {
            case 0:
                updateContextAttribute("regEx", newRegExCopy);
                updateContextAttribute("regExTemp", newRegExCopy);
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success",
                    message: "Nuevo tipo de dato guardado temporalmente",
                });
                //Ponemos el valor del tipo, y quitamos el error de vacio
                const tempStepCopy = JSON.parse(JSON.stringify(tempStep));
                const stepErrorsCopy = JSON.parse(JSON.stringify(stepErrors));
                const stepErrorHelperCopy = JSON.parse(JSON.stringify(stepErrorHelper));
                tempStepCopy["type"] = newRegEx.name;
                stepErrorsCopy["type"] = false;
                stepErrorHelperCopy["type"] = errorHelperKeys.removeError;
                const countStepErrors = Object.values(stepErrorsCopy).reduce(
                    (a, item) => a + item,
                    0
                );
                this.setState({
                    tempStep: tempStepCopy,
                    stepErrors: stepErrorsCopy,
                    stepErrorHelper: stepErrorHelperCopy,
                    countStepErrors: countStepErrors,
                    stepHasChanges: true,
                });
                break;

            default:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "error",
                    message: "No se pudo guardar el nuevo tipo de dato",
                });
                break;
        }
    };

    //Función para agregar un nuevo catálogo
    addNewCatalog = (newCatalog) => {
        const { tempStep, stepErrors, stepErrorHelper } = this.state;
        const { errorHelperKeys } = this;
        //Ponemos el valor del catalogo, y quitamos el error de vacio
        const tempStepCopy = JSON.parse(JSON.stringify(tempStep));
        const stepErrorsCopy = JSON.parse(JSON.stringify(stepErrors));
        const stepErrorHelperCopy = JSON.parse(JSON.stringify(stepErrorHelper));
        tempStepCopy["catalog"] = newCatalog.name;
        stepErrorsCopy["catalog"] = false;
        stepErrorHelperCopy["catalog"] = errorHelperKeys.removeError;
        const countStepErrors = Object.values(stepErrorsCopy).reduce((a, item) => a + item, 0);
        this.setState({
            tempStep: tempStepCopy,
            stepErrors: stepErrorsCopy,
            stepErrorHelper: stepErrorHelperCopy,
            countStepErrors: countStepErrors,
            stepHasChanges: true,
        });
    };

    addProcessBranch = (subflowObject) => {
        const { tempStep } = this.state;
        const tempStepCopy = JSON.parse(JSON.stringify(tempStep));
        tempStepCopy.processBranches.push(subflowObject);
        this.setState({
            tempStep: tempStepCopy,
            stepHasChanges: true,
        });
    };

    onCancelStep = () => {
        let { tempStep } = this.props;
        const { searchStepErrors } = this;
        //buscamos errores
        let { stepErrorsCopy, countStepErrorsCopy, stepErrorHelperCopy } =
            searchStepErrors(tempStep);
        this.setState({
            tempStep: tempStep,
            stepErrors: stepErrorsCopy,
            countStepErrors: countStepErrorsCopy,
            stepErrorHelper: stepErrorHelperCopy,
            newStep: false,
            stepHasChanges: false,
        });
    };

    removeProcessBranch = (index) => {
        const { tempStep } = this.state;
        const tempStepCopy = JSON.parse(JSON.stringify(tempStep));
        tempStepCopy["processBranches"].splice(index, 1);
        this.setState({
            tempStep: tempStepCopy,
            stepHasChanges: true,
        });
    };

    updateProcessBranches = (subflowData, indexTable) => {
        const { tempStep } = this.state;
        const tempStepCopy = JSON.parse(JSON.stringify(tempStep));
        tempStepCopy["processBranches"][indexTable] = subflowData;
        this.setState({
            tempStep: tempStepCopy,
            stepHasChanges: true,
        });
    };

    render() {
        const { globalValues, regExTemp, catalogsTemp, getStorageData } = this.context;

        const {
            stepsKeys,
            handleStepChange,
            addMenuOption,
            editMenuOption,
            deleteMenuOption,
            addProcessBranch,
            updateProcessBranches,
            removeProcessBranch,
            addNewRegEx,
            addNewCatalog,
            onCancelStep,
        } = this;

        const { relatedValues, stepsList, processTemp, onSaveStep, readOnly } = this.props;

        const { tempStep, stepErrors, stepErrorHelper, newStep, stepHasChanges, countStepErrors } =
            this.state;

        //Variables para decidir los distintos renders o Opciones para manipular los inputs
        //===========Listas de Objetos==========================
        const fowardStepsDescription = this.setJumpableSteps();
        tempStep["fowardStepsDescription"] = fowardStepsDescription;
        const allProcessesNames = processTemp.map((process) => process.processName);
        let regExCopy = regExTemp.map((expresion) => expresion.name);
        let catalogsCopy = catalogsTemp.map((catalog) => catalog.name);
        let autoCompleteList = globalValues.concat(relatedValues);
        autoCompleteList = autoCompleteList.filter((item, index) => {
            //comparamos ambas listas y devolvemos la posición del id
            let position = autoCompleteList.findIndex((element) => element.id === item.id);
            //si hay un valor duplicado, este tendrá otro index diferente al que está siendo iterado y se quita
            return position === index;
        });
        let omitionOptions =
            tempStep.JumpCriteria === "Tipo de dato"
                ? regExCopy
                : tempStep.JumpCriteria === "Catálogo"
                ? catalogsCopy
                : regExCopy;
        //============Elección de render==================
        let chooseDisableJump =
            stepsList.at(-1)?.description === tempStep.description || newStep ? true : false;
        let chooseRenderJump = tempStep.jumpStep ? true : false;
        let chooseRenderType = tempStep.input === "Tipo de dato" ? true : false;
        let chooseRenderCatalog = tempStep.input === "Catálogo" ? true : false;
        let chooseRenderSubmenuFields = tempStep.enableMenuOptions
            ? true
            : tempStep.menuOptions && tempStep.menuOptions.length > 0
            ? true
            : false;

        //==========Set Render según los inputs=====
        stepsKeys.map((step) => {
            if (step.group === "JumpStep") {
                step.render = chooseRenderJump;
                if (step.name === "allowJump") {
                    step.required = chooseRenderJump;
                }
            }
            switch (step.name) {
                case "type":
                    step.render = chooseRenderType;
                    break;
                case "catalog":
                    step.render = chooseRenderCatalog;
                    break;
                case "jumpStep":
                    step.disabled = chooseDisableJump;
                    break;
                case "ask":
                    step.required = !chooseRenderSubmenuFields;
                    break;
                case "menuOptions":
                    step.render = chooseRenderSubmenuFields;
                    step.required = chooseRenderSubmenuFields;
                    break;
                case "headerMenu":
                    step.render = chooseRenderSubmenuFields;
                    step.required = chooseRenderSubmenuFields;
                    break;
                case "allowJump":
                    if (tempStep.JumpCriteria === "Valor") {
                        step.render = false;
                    }
                    break;
                case "jumpOperartion":
                    if (tempStep.JumpCriteria === "Valor") {
                        step.render = true;
                        break;
                    }
                    step.render = false;
                    break;
                default:
                    break;
            }
            return step;
        });
        //==========Delete de valores para no saturar el archivo process
        if (tempStep.jumpStep === "") {
            delete tempStep.jumpStep;
        }
        switch (tempStep.input) {
            case "Tipo de dato":
                delete tempStep.catalog;
                break;
            case "Catálogo":
                delete tempStep.type;
                break;
            default:
                break;
        }

        return (
            <React.Fragment>
                <Grid container justifyContent="space-around">
                    <Grid item sm={12} xs={12}>
                        {/* Iteramos sobre los inputs de StepsKeys ELIMINADO el input de Catalogo y tipo de dato */}
                        {stepsKeys.map((step, index) =>
                            step.render ? (
                                <InputChooser
                                    key={index}
                                    disabled={step.disabled}
                                    name={step.name}
                                    value={tempStep[step.name]}
                                    type={step.type}
                                    label={step.alias}
                                    detailText={step.detail}
                                    options={
                                        step.name === "allowJump" ? omitionOptions : step.options
                                    }
                                    relatedOptions={relatedValues}
                                    variant="outlined"
                                    error={stepErrors[step.name]}
                                    required={step.required}
                                    onChange={(targetName, targetValue, targetType, targetIndex) =>
                                        handleStepChange(
                                            targetName,
                                            targetValue,
                                            targetType,
                                            step.required,
                                            targetIndex
                                        )
                                    }
                                    regEx={regExTemp}
                                    catalogs={catalogsTemp}
                                    addNewRegEx={(newRegEx) => addNewRegEx(newRegEx)}
                                    addNewCatalog={(newCatalog) => addNewCatalog(newCatalog)}
                                    fowardStepsDescription={fowardStepsDescription}
                                    text={step.text}
                                    autoCompleteList={autoCompleteList}
                                    globalValues={globalValues}
                                    stepErrors={stepErrors}
                                    errorHelper={stepErrorHelper}
                                    doubleInput={step.inputKeys}
                                    allProcessesNames={allProcessesNames}
                                    processBranches={tempStep["processBranches"]}
                                    updateProcessBranches={updateProcessBranches}
                                    addProcessBranch={addProcessBranch}
                                    removeProcessBranch={removeProcessBranch}
                                    menuOptions={tempStep["menuOptions"]}
                                    deleteMenuOption={deleteMenuOption}
                                    editMenuOption={editMenuOption}
                                    addMenuOption={addMenuOption}
                                    tempStep={tempStep}
                                />
                            ) : null
                        )}
                    </Grid>
                    {!readOnly && (
                        <div style={{ margin: "20px" }}>
                            <div className="dialog-actions-steps">
                                <ButtonConfirmDialog
                                    disabled={stepHasChanges && !newStep ? false : true}
                                    buttonName="Cancelar"
                                    title="¿Estás seguro que deseas cancelar tus cambios?"
                                    confirmName="Sí"
                                    cancelName="No"
                                    variant="contained"
                                    onConfirm={() => onCancelStep()}
                                >
                                    Se revertirán todas las modificaciones realizadas.
                                </ButtonConfirmDialog>
                                {countStepErrors > 0 ? (
                                    <ButtonConfirmDialog
                                        disabled={stepHasChanges ? false : true}
                                        buttonName="Guardar"
                                        title="No es posible guardar la información."
                                        confirmName="Ok"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <React.Fragment>
                                            Por favor revisa los errores en los campos marcados con
                                            color rojo.
                                            <div>
                                                <span>
                                                    <br />
                                                    Los siguientes campos no pueden estar vacíos:{" "}
                                                </span>
                                                <ul style={{ marginTop: "0" }}>
                                                    {Object.keys(stepErrors).map((key, index) => {
                                                        if (stepErrors[key]) {
                                                            return (
                                                                <li key={index}>
                                                                    {getErrorFieldName(key)}.
                                                                </li>
                                                            );
                                                        }
                                                        return "";
                                                    })}
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    </ButtonConfirmDialog>
                                ) : (
                                    <ButtonConfirmDialog
                                        disabled={stepHasChanges ? false : true}
                                        buttonName="Guardar"
                                        title="¿Confirmas que deseas guardar tus cambios?"
                                        confirmName="Sí"
                                        cancelName="No"
                                        variant="contained"
                                        color="primary"
                                        onConfirm={() => {
                                            onSaveStep(tempStep);
                                            this.setState({
                                                stepHasChanges: false,
                                                newStep: false,
                                            });
                                        }}
                                    >
                                        <p style={{ marginTop: "3px" }}>
                                            Esta acción guardará de manera temporal las
                                            modificaciones realizadas.
                                            <br />
                                            Será necesario públicar los cambios para que se apliquen
                                            en InteBot.
                                        </p>
                                    </ButtonConfirmDialog>
                                )}
                            </div>
                        </div>
                    )}
                </Grid>
                <MandatoryField />
            </React.Fragment>
        );
    }
}

EditStepContent.contextType = AppContext;
