import React, { Fragment } from "react";

// Material UI inputs
import { Grid } from "@material-ui/core";

//Custom Portal components
import Table from "./StyledTable";
import ButtonConfirmDialog from "../ButtonConfirmDialog";
import AddResponseObject from "../../FlowSectionComponents/ResponseBranches/Modals/AddResponseObject";
import AddResponseCase from "../../FlowSectionComponents/ResponseBranches/Modals/AddResponseCase";
import AddBodyRequest from "../../FlowSectionComponents/BodyRequest/Modals/AddBodyRequest";
import AddRequestHeaderModal from "../../FlowSectionComponents/RequestHeaders/Modals/AddRequestHeaderModal";
import ActivateSubflow from "../../FlowSectionComponents/Subflows/Modals/ActivateSubflow";
import AddSubmenuOption from "../../FlowSectionComponents/SubmenuOptions/Modals/AddSubmenuOption";

//Icons
import { ReactComponent as TrashCan } from "../../../Icons/trashcanIcon.svg";

function SimpleTable(props) {
    const {
        headers,
        values,
        type,
        functionDelete,
        functionEdit,
        index,
        classes,
        // para casos de respuesta
        responseAttributesKeys,
        responseAttributes,
        responseTable,
        handleResponseBranches,
        responseBranches,
        allProcessesNames,
        StyledMentionInput,
        autoCompleteList,
        responseCodeKeys,
        replyActionKeys,
        processName,
        tableType,
        // para cuerpo de la petición
        currentProcess,
        // para subflujos
        optionsCatalog,
        optionsRegEx,
    } = props;

    function deleteRecord(value) {
        functionDelete(value);
    }

    function editRecord(objectUpdated, value) {
        functionEdit(objectUpdated, value);
    }

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Table simpleTable={true} className="simpleTable">
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index} align="center">
                                        {header}
                                    </th>
                                ))}
                                <th align="center" width="10%"></th>
                            </tr>
                        </thead>
                        {/* tabla para las respuestas del servicio */}
                        {type === "service-response-table" ? (
                            <tbody>
                                {values && values.length > 0 ? (
                                    values.map((row, indexTable) => (
                                        <tr key={indexTable}>
                                            <Fragment>
                                                <td width="37%">{row.description}</td>
                                                <td width="37%">{row.attribute}</td>
                                                {/* buttons edit and delete */}
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <AddResponseObject
                                                            index={index}
                                                            indexTable={indexTable}
                                                            responseAttributesKeys={
                                                                responseAttributesKeys
                                                            }
                                                            responseAttributes={responseAttributes}
                                                            responseTable={responseTable}
                                                            handleResponseBranches={
                                                                handleResponseBranches
                                                            }
                                                            tableType={tableType}
                                                            label={
                                                                tableType === "none"
                                                                    ? {
                                                                          newDescriptionField:
                                                                              "Mensaje",
                                                                          newAttributeField:
                                                                              "Campo",
                                                                      }
                                                                    : tableType === "multimedia"
                                                                    ? {
                                                                          newDescriptionField:
                                                                              "Mensaje",
                                                                          newAttributeField:
                                                                              "Campo",
                                                                          mediaType:
                                                                              "Tipo de multimedia",
                                                                      }
                                                                    : {
                                                                          newDescriptionField:
                                                                              "Encabezado",
                                                                          newAttributeField:
                                                                              "Campo",
                                                                      }
                                                            }
                                                            updateObjectResponse={(
                                                                object,
                                                                index
                                                            ) => {
                                                                editRecord(object, index);
                                                            }}
                                                        ></AddResponseObject>
                                                        <ButtonConfirmDialog
                                                            title={`¿Deseas eliminar esta respuesta: ${row.attribute}?`}
                                                            confirmName="Sí"
                                                            cancelName="No"
                                                            tooltip={"Eliminar respuesta"}
                                                            size="small"
                                                            onConfirm={() =>
                                                                deleteRecord(indexTable)
                                                            }
                                                            icon={
                                                                <TrashCan
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </Fragment>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {headers.map((header, index) => (
                                            <td
                                                key={index}
                                                width="50%"
                                                style={{ padding: "10px" }}
                                            ></td>
                                        ))}
                                        <td width=""></td>
                                    </tr>
                                )}
                            </tbody>
                        ) : // tabla para los casos de respuesta del servicio
                        type === "response-branches-table" ? (
                            <tbody>
                                {values && values.length > 0 ? (
                                    values.map((row, indexTable) => (
                                        <tr key={indexTable}>
                                            <Fragment>
                                                <td width="50%">
                                                    {row.responseCode.responseDescription || "..."}
                                                </td>
                                                <td width="40%">
                                                    {row.responseCode.responseValue || "..."}
                                                </td>
                                                {/* buttons edit and delete */}
                                                <td width="10%">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <AddResponseCase
                                                            index={index}
                                                            indexTable={indexTable}
                                                            classes={classes}
                                                            responseBranches={responseBranches}
                                                            allProcessesNames={allProcessesNames}
                                                            StyledMentionInput={StyledMentionInput}
                                                            autoCompleteList={autoCompleteList}
                                                            responseCodeKeys={responseCodeKeys}
                                                            replyActionKeys={replyActionKeys}
                                                            responseAttributesKeys={
                                                                responseAttributesKeys
                                                            }
                                                            handleResponseBranches={
                                                                handleResponseBranches
                                                            }
                                                            processName={processName}
                                                            updateResponseBranch={(object, index) =>
                                                                editRecord(object, index)
                                                            }
                                                        ></AddResponseCase>
                                                        <ButtonConfirmDialog
                                                            title={`¿Deseas eliminar este escenario: ${row.responseCode.responseValue}?`}
                                                            confirmName="Sí"
                                                            cancelName="No"
                                                            tooltip={"Eliminar escenario"}
                                                            size="small"
                                                            icon={
                                                                <TrashCan
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            }
                                                            onConfirm={() =>
                                                                deleteRecord(indexTable)
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </Fragment>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {headers.map((header, index) => (
                                            <td
                                                key={index}
                                                width="30%"
                                                style={{ padding: "10px" }}
                                            ></td>
                                        ))}
                                        <td width="5%"></td>
                                    </tr>
                                )}
                            </tbody>
                        ) : // tabla para el cuerpo de la petición
                        type === "body-response-table" ? (
                            <tbody>
                                {values && values.length > 0 ? (
                                    values.map((row, indexTable) => (
                                        <tr key={indexTable}>
                                            <Fragment>
                                                <td width="50%">{row.key}</td>
                                                <td width="50%">
                                                    {!row.value
                                                        ? ""
                                                        : typeof row.value === "object"
                                                        ? JSON.stringify(row.value)
                                                        : row.value.includes("{{")
                                                        ? row.value.trim().replace(/([{}])/g, "")
                                                        : row.value}
                                                </td>
                                                {/* buttons edit and delete */}
                                                <td width="">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            backgroundColor: "none",
                                                        }}
                                                    >
                                                        <AddBodyRequest
                                                            currentProcess={currentProcess}
                                                            classes={classes}
                                                            indexTable={indexTable}
                                                            bodyRequestFields={values}
                                                            StyledMentionInput={StyledMentionInput}
                                                            updateBody={(bodyRequest, index) =>
                                                                editRecord(bodyRequest, index)
                                                            }
                                                        />
                                                        <ButtonConfirmDialog
                                                            title={`¿Deseas eliminar este campo: ${row.key}?`}
                                                            confirmName="Sí"
                                                            cancelName="No"
                                                            tooltip={"Eliminar campo"}
                                                            size="small"
                                                            onConfirm={() => deleteRecord(row.key)}
                                                            icon={
                                                                <TrashCan
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </Fragment>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {headers.map((header, index) => (
                                            <td
                                                key={index}
                                                width="30%"
                                                style={{ padding: "10px" }}
                                            ></td>
                                        ))}
                                        <td width="1%"></td>
                                    </tr>
                                )}
                            </tbody>
                        ) : type === "subflows-table" ? (
                            <tbody>
                                {values && values.length > 0 ? (
                                    values.map((row, indexTable) => (
                                        <tr key={indexTable}>
                                            <Fragment>
                                                <td width="33%">{row.name}</td>
                                                <td width="33%">
                                                    {row.condition === "operation"
                                                        ? "Valor"
                                                        : row.condition === "type"
                                                        ? "Tipo de dato"
                                                        : "Catálogo"}
                                                </td>
                                                {/* buttons edit and delete */}
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <ActivateSubflow
                                                            currentSubflow={row}
                                                            allProcessesNames={allProcessesNames}
                                                            classes={classes}
                                                            optionsCatalog={optionsCatalog}
                                                            optionsRegEx={optionsRegEx}
                                                            updateProcessBranch={(object, index) =>
                                                                editRecord(object, index)
                                                            }
                                                            indexTable={indexTable}
                                                        />
                                                        <ButtonConfirmDialog
                                                            title={`¿Deseas eliminar este subflujo: ${row.name}?`}
                                                            confirmName="Sí"
                                                            cancelName="No"
                                                            tooltip={"Eliminar subflujo"}
                                                            size="small"
                                                            onConfirm={() =>
                                                                deleteRecord(indexTable)
                                                            }
                                                            icon={
                                                                <TrashCan
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </Fragment>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {headers.map((header, index) => (
                                            <td
                                                key={index}
                                                width="30%"
                                                style={{ padding: "10px" }}
                                            ></td>
                                        ))}
                                        <td width="1%" style={{ display: "none" }}></td>
                                    </tr>
                                )}
                            </tbody>
                        ) : type === "submenu-table" ? (
                            <tbody>
                                {values && values.length > 0 ? (
                                    values.map((row, indexTable) => (
                                        <tr key={indexTable}>
                                            <Fragment>
                                                <td width="58%">{row.textOption}</td>
                                                {/* buttons edit and delete */}
                                                <td width="10%">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <AddSubmenuOption
                                                            currentSubmenu={row}
                                                            classes={classes}
                                                            indexTable={indexTable}
                                                            editMenuOption={(submenuForm, index) =>
                                                                editRecord(submenuForm, index)
                                                            }
                                                        />
                                                        <ButtonConfirmDialog
                                                            title={`¿Deseas eliminar esta opción de submenú: ${row.textOption}?`}
                                                            confirmName="Sí"
                                                            cancelName="No"
                                                            tooltip={"Eliminar opción"}
                                                            size="small"
                                                            onConfirm={() =>
                                                                deleteRecord(indexTable)
                                                            }
                                                            icon={
                                                                <TrashCan
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </Fragment>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {headers.map((header, index) => (
                                            <td
                                                key={index}
                                                width="50%"
                                                style={{ padding: "10px" }}
                                            ></td>
                                        ))}
                                        <td width="1%" style={{ display: "none" }}></td>
                                    </tr>
                                )}
                            </tbody>
                        ) : type === "request-headers-table" ? (
                            <tbody>
                                {values && Object.keys(values).length > 0 ? (
                                    Object.keys(values).map((row, indexTable) => (
                                        <tr key={indexTable}>
                                            <Fragment>
                                                <td width="50%">{row}</td>
                                                <td width="50%">{values[row]}</td>
                                                {/* buttons edit and delete */}
                                                <td width="">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            backgroundColor: "none",
                                                        }}
                                                    >
                                                        <AddRequestHeaderModal
                                                            updateRequestHeader={functionEdit}
                                                            requestHeaders={
                                                                currentProcess["requestHeaders"]
                                                            }
                                                            classes={classes}
                                                            indexTable={indexTable}
                                                            StyledMentionInput={StyledMentionInput}
                                                        />
                                                        <ButtonConfirmDialog
                                                            title={`¿Deseas eliminar este campo: ${row}?`}
                                                            confirmName="Sí"
                                                            cancelName="No"
                                                            tooltip={"Eliminar campo"}
                                                            size="small"
                                                            onConfirm={() => functionDelete(row)}
                                                            icon={
                                                                <TrashCan
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </Fragment>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {headers.map((header, index) => (
                                            <td
                                                key={index}
                                                width="30%"
                                                style={{ padding: "10px" }}
                                            ></td>
                                        ))}
                                        <td width="1%"></td>
                                    </tr>
                                )}
                            </tbody>
                        ) : null}
                    </Table>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default SimpleTable;
