import React, { Component } from 'react';
import { Grid, Button} from'@material-ui/core/';

import AppContext from '../Context/AppContext';

//CSS Style sheets 
import './StyledPages/MyAccountPage.css'

class Myaccountpage extends Component {   
    render() {
        const { getStorageData } = this.context;
        const { onClose, openDrawerChangePassword, openDrawerManagePermissions } = this.props
        let storageData = getStorageData([
            "email",
            "organizationName",
            "name",
            "lastName",
            "permission"
        ])
        let { name, lastName, organizationName, email, permission } = storageData;
        return (
            <Grid container >
                <Grid item xs={12} className='user-info-container'>
                    <div className='my-account-container'  >
                        <h3>{name} {lastName}</h3>
                        <p>{email}</p>
                        <p>{organizationName}</p>
                        <div className='my-account-actions'>
                            <Button className="nav-link" onClick={() => { onClose(); openDrawerChangePassword(); }}>
                                Cambiar contraseña
                            </Button>
                            {
                                ["admin", "owner"].includes(permission.role) && 
                                <Button className="nav-link" onClick={() => { onClose(); openDrawerManagePermissions(); }}>
                                    Administrar permisos
                                </Button>
                            }
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

Myaccountpage.contextType = AppContext;

export default Myaccountpage;
