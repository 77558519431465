import React from "react";

//MUI Components
import { Tooltip, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//Icons
import MoverIcono from "@material-ui/icons/UnfoldMore";
import { ReactComponent as TrashCan } from "../Icons/trashcanIcon.svg";
import EditarIcono from "../Icons/EditarIcono";
import AgregarIcono from "../Icons/AgregarIcono";

//Custom portal components
import ProcessModal from "../components/FlowsMainComponents/ProcessModal";
import ButtonConfirmDialog from "../components/common/ButtonConfirmDialog";
import Table from "../components/common/Tables/StyledTable";
import FooterControls from "../components/FooterComponents/FooterControls";
import CustomAlert from "../components/common/Alerts/CustomAlert";
import LoadingDialog from "../components/common/Modals/LoadingDialog";
import AppContext from "../Context/AppContext";
import { intebotDataActions } from "../components/DataFunctions";

//external libraries
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc"; /*Mover Elementos*/
import uniqid from "uniqid"; //Hexadecimal ID's
import Swal from "sweetalert2";

//Catalog
// import IntebotCatalog from '../components/IntebotCatalog';

export default class ProcessesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnNames: ["processName"],
            alert: {
                open: false,
                severity: "", //success, error, warning, info
                message: "",
            },
            loadingDialog: false,
            hasSorted: true,
        };
    }

    //Función para ordenamiento en la tabla (los cambios se hacen sobre el contexto)
    handleSortEnd = ({ oldIndex, newIndex }) => {
        //Obtenemos los procesos actuales en el contexto
        const { processesTemp, updateContextAttribute } = this.context;

        //Hacemos una copia temporal para modificarla y actualizar el contexto
        const processesCopy = JSON.parse(JSON.stringify(processesTemp));
        const [removed] = processesCopy.splice(oldIndex, 1);
        processesCopy.splice(newIndex, 0, removed);
        //console.log(processesCopy);
        updateContextAttribute("processesTemp", processesCopy);
        updateContextAttribute("processesHasChanges", true);

        //Notificamos que el cambio se hizo correctamente
        this.setState({
            alert: {
                open: true,
                severity: "success", //success, error, warning, info
                message: "El orden se actualizó",
            },
            hasSorted: true,
        });
    };

    //Borramos un proceso del contexto temporal
    onDelete = (id) => {
        //Obtenemos los procesos actuales en el contexto
        const { processesTemp, updateContextAttribute } = this.context;

        //Hacemos una copia temporal para modificarla y actualizar el contexto
        const processesCopy = JSON.parse(JSON.stringify(processesTemp));
        processesCopy.splice(id, 1);
        updateContextAttribute("processesTemp", processesCopy);
        updateContextAttribute("processesHasChanges", true);

        //Notificamos que el cambio se hizo correctamente
        this.setState({
            alert: {
                open: true,
                severity: "success", //success, error, warning, info
                message: "El proceso se eliminó de la lista",
            },
        });
    };

    //Guardamos los procesos del contexto temporal en el contexto normal
    onSave = async () => {
        //Obtenemos el valor y las funciones de Procesos del contexto temporal
        const { processesTemp, updateContextAttribute, getStorageData } = this.context;
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");
        const { token } = getStorageData(["token"]);
        //Actualizamos la lista de procesos en base de datos
        let data = {
            processes: processesTemp,
            token,
        };
        let res = await intebotDataActions(
            data,
            "updateConfigurationProcesses&enviroment=Test",
            "ModelConfigurationOperations"
        );
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                //Actualizamos el estado
                this.setState({
                    hasChanges: false,
                    alert: {
                        open: true,
                        severity: "success",
                        message: "Tabla de flujos guardada correctamente",
                    },
                });

                //Actualizamos el contexto de la parte de procesos
                updateContextAttribute("processes", processesTemp);
                updateContextAttribute("processesHasChanges", false);
                updateContextAttribute("processesHasSavedChanges", true);
                break;
            case 2:
                this.setState({
                    alert: {
                        open: true,
                        severity: "info", //success, error, warning, info
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    },
                });
                break;
            case 3:
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;
            default:
                this.setState({
                    alert: {
                        open: true,
                        severity: "error",
                        message: "Error al guardar el flujo",
                    },
                });
                break;
        }
    };

    //Restauramos los cambios hechos en el contexto temporal con el contenido contexto normal
    onCancel = () => {
        const { processes, updateContextAttribute } = this.context;
        updateContextAttribute("processesTemp", processes);
        updateContextAttribute("processesHasChanges", false);

        //Notificamos que se cancelaron los cambios exitosamente
        this.setState({
            alert: {
                open: true,
                severity: "success", //success, error, warning, info
                message: "Se restauraron los cambios",
            },
        });
    };

    handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: "",
            },
        });
    };

    render() {
        const {
            processesTemp,
            processesHasChanges,
            regExTemp,
            catalogsTemp,
            processesHasSavedChanges,
            getStorageData,
            contextReady,
        } = this.context;
        const { hasSorted } = this.state;

        //libreria que automaticamente te genera un ID en hexadecimal
        let flowUniqueID = uniqid(); //"ktoz5w1e"
        //todos los nombres de los flujos existentes
        const allFlowsNames = processesTemp.map((process) => process.processName.toLowerCase());
        //validación de número máximo de flujos según la suscripción
        let { availableChannels, subscriptionTier, permission } = getStorageData([
            "subscriptionTier",
            "availableChannels",
            "permission",
        ]);

        //Ordenamos alfabéticamente los canales, y creamos un nuevo objeto con la visibilidad en false
        const visibleChannel = Object.keys(availableChannels)
            .sort()
            .reduce((newChannelObj, channel) => {
                newChannelObj[channel] = false;
                return newChannelObj;
            }, {});

        const maxFlows = subscriptionTier.flows;
        //==============
        return (
            <div>
                <CustomAlert
                    open={this.state.alert.open}
                    severity={this.state.alert.severity}
                    message={this.state.alert.message}
                    handleCloseAlert={() => this.handleCloseAlert()}
                />
                <LoadingDialog open={this.state.loadingDialog} />
                <Table className="withButtons">
                    <thead>
                        <tr>
                            <th width="3%" className="cells"></th>
                            <th width="14%">ID</th>
                            <th width="80%">Flujos conversacionales</th>
                            <th width="3%"></th>
                        </tr>
                    </thead>
                    <SortableTableBody useDragHandle onSortEnd={this.handleSortEnd}>
                        {/* //MUY IMPORTANTE SOLO RENDEREAR HASTA QUE EL CONTEXT ESTÉ LISTO  */}
                        {contextReady && processesTemp.length > 0 ? (
                            processesTemp.map(
                                (
                                    process,
                                    index //Iteramos sobre la estructura del archivo
                                ) => (
                                    <SortableRow key={index} index={index} id={index}>
                                        <td>
                                            <SortableTd />
                                        </td>
                                        <td width="10%">{index + 1}</td>
                                        <td width="75%">
                                            <ProcessModal
                                                buttonName={process.processName}
                                                buttonType="link"
                                                buttonTooltip="Editar Proceso"
                                                startIcon={
                                                    <EditarIcono width="15px" height="15px" />
                                                }
                                                currentProcess={process}
                                                idProcess={index}
                                                regExTemp={regExTemp}
                                                catalogsTemp={catalogsTemp}
                                                processTemp={processesTemp}
                                                allFlowsNames={allFlowsNames}
                                                //allkeyWords={keyWordsArray}
                                                processesHasSavedChanges={processesHasSavedChanges}
                                                hasSorted={hasSorted}
                                                newFlow={false}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                <ButtonConfirmDialog
                                                    title={
                                                        "¿Deseas eliminar el flujo: " +
                                                        process.processName +
                                                        "?"
                                                    }
                                                    confirmName="Sí"
                                                    cancelName="No"
                                                    tooltip={"Eliminar flujo"}
                                                    onConfirm={() => this.onDelete(index)}
                                                    icon={<TrashCan width="20px" height="20px" />}
                                                />
                                            </div>
                                        </td>
                                    </SortableRow>
                                )
                            )
                        ) : (
                            <tr>
                                {Array.from(Array(3).keys()).map((header, index) => (
                                    <td key={index} style={{ padding: "10px" }}></td>
                                ))}
                                <td width="1%"></td>
                            </tr>
                        )}
                    </SortableTableBody>
                </Table>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            {contextReady && (
                                <ProcessModal
                                    buttonName="Agregar flujo"
                                    buttonTooltip="Abrir modal para crear flujo"
                                    buttonType={
                                        processesTemp.length >= maxFlows ? "maxFlows" : "link"
                                    }
                                    startIcon={<AgregarIcono width="15px" height="15px" />}
                                    currentProcess={{
                                        //Incluye valores por defecto de un proceso
                                        processName: "",
                                        steps: [],
                                        visibleChannel: visibleChannel,
                                        flowUniqueID: flowUniqueID,
                                        allChannelsCheckbox: false,
                                        serviceType: "Ninguno",
                                        confirmationData: false,
                                        showMenu: false,
                                        subMenu: false,
                                        endProcess: "",
                                    }}
                                    regExTemp={regExTemp}
                                    catalogsTemp={catalogsTemp}
                                    processTemp={processesTemp}
                                    allFlowsNames={allFlowsNames}
                                    //allkeyWords={keyWordsArray}
                                    newFlow={true}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <FooterControls
                    disableSave={!processesHasChanges || permission.readOnly}
                    disableCancel={!processesHasChanges || permission.readOnly}
                    onSave={() => this.onSave()}
                    onCancel={() => this.onCancel()}
                    showChatBtn={true}
                />
            </div>
        );
    }
}

ProcessesTable.contextType = AppContext;

/* Componentes para mover renglones de tabla */
const useStyles = makeStyles((theme) => ({
    moveItem: {
        "&:hover": {
            cursor: "grab",
        },
    },
}));
const DraggableElement = SortableElement(({ children }) => <tr>{children}</tr>);
const SortableRow = ({ id, index, children }) => (
    <DraggableElement key={id} index={index}>
        {children}
    </DraggableElement>
);
const SortableTd = SortableHandle(() => (
    <Tooltip title="Cambiar ordenamiento">
        <MoverIcono color="primary" fontSize="small" className={useStyles().moveItem} />
    </Tooltip>
));
const SortableTableBody = SortableContainer(({ children }) => <tbody>{children}</tbody>);
