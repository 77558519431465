import React, { Fragment, useState, useEffect } from "react";

// Material UI inputs
import {
    Grid,
    Breadcrumbs,
    Typography,
    TextField,
    Checkbox,
    FormControlLabel,
    MenuItem,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//Custom Portal components
import FormModal from "../../../FormModalComponents/FormModal";
import AgregarIcono from "../../../../Icons/AgregarIcono";
import EditarIcono from "../../../../Icons/EditarIcono";
import IntebotCatalog from "../../../IntebotCatalog";

// styles
import "../Styles/Modals.css";

function AddResponseObject(props) {
    let {
        index,
        addNewObjectResponse,
        updateObjectResponse,
        responseAttributesKeys,
        responseAttributes,
        responseTable,
        handleResponseBranches,
        isNewResponse,
        label,
        indexTable,
        tableType,
    } = props;

    const errorHelperKeys = IntebotCatalog.errorHelper;

    const formErrorsInit = {
        formHasErrors: true,
        formResponseErrors: {
            description: true,
            attribute: true,
            mediaType: false,
        },
        errorHelperForm: {
            description: errorHelperKeys.emptyField,
            attribute: errorHelperKeys.emptyField,
            mediaType: errorHelperKeys.removeError,
        },
    };
    // opciones para el select
    const mediaOptions = [
        { name: "Imagen", value: "image/png" },
        { name: "Video", value: "video/mpeg" },
        { name: "Archivo", value: "file" },
        { name: "Texto y archivo", value: "text" },
    ];

    const [responseObject, setResponseObject] = useState({
        description: "",
        attribute: "",
        mediaType: null,
        saveAttribute: false,
    });

    const [formHasChanges, setFormHasChanges] = useState(false);

    const [formErrors, setFormErrors] = useState(formErrorsInit);

    const [previousValue, setPreviousValue] = useState({});

    const [isEditableDataSet, setIsEditableDataSet] = useState(false);

    /**
     * @name modalIsOpen
     * @description Funcion que revisa si el modal esta abierto para asignar datos correspondientes
     */
    const modalIsOpen = (isOpen) => {
        if (isOpen) {
            if (label.newDescriptionField === "Mensaje") {
                // si la respuesta se mostrará como mensaje será opcional el mensaje, quitar los errores
                let { formResponseErrors, errorHelperForm } = formErrors;
                let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
                let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
                formResponseErrorsCopy.description = false;
                errorHelperFormCopy.description = errorHelperKeys.removeError;

                if (tableType === "multimedia") {
                    formResponseErrorsCopy.mediaType = true;
                    errorHelperFormCopy.mediaType = errorHelperKeys.emptyField;
                }

                setFormErrors({
                    ...formErrors,
                    formResponseErrors: formResponseErrorsCopy,
                    errorHelperForm: errorHelperFormCopy,
                });
            } else {
                searchFormErrors();
            }
        }
        if (isOpen && !isNewResponse) {
            setResponseObject({
                description: responseTable.responseAttributes[indexTable].description,
                attribute: responseTable.responseAttributes[indexTable].attribute,
                mediaType:
                    tableType === "multimedia"
                        ? responseTable.responseAttributes[indexTable].mediaType
                        : null,
                saveAttribute: responseTable.responseAttributes[indexTable].saveAttribute
                    ? responseTable.responseAttributes[indexTable].saveAttribute
                    : false,
            });
            setPreviousValue({
                description: responseTable.responseAttributes[indexTable].description,
                attribute: responseTable.responseAttributes[indexTable].attribute,
                mediaType:
                    tableType === "multimedia"
                        ? responseTable.responseAttributes[indexTable].mediaType
                        : null,
                saveAttribute: responseTable.responseAttributes[indexTable].saveAttribute
                    ? responseTable.responseAttributes[indexTable].saveAttribute
                    : false,
            });
            setIsEditableDataSet(true);
        }
    };

    useEffect(() => {
        if (isEditableDataSet) {
            // cuando finalizó de actualizar, revisar si hay errores
            searchFormErrors();
        }
    }, [responseObject, isEditableDataSet]);

    /**
     * @name validateResponseFields
     * @description enviar informacion de los campos a validar en handleResponseBranches
     */
    const validateResponseFields = async (targetName, targetValue) => {
        const indexAttribute = responseAttributes.length;
        let indexOfAttribute = isNewResponse ? indexAttribute : indexTable;

        await handleResponseBranches(
            targetName,
            targetValue,
            index,
            "responseTable",
            indexOfAttribute,
            responseObject
        );
    };

    /**
     * @description Agregar nueva respuesta o editar una respuesta del servicio
     */
    const addNewResponse = async () => {
        let finishedLoop = false;
        let properties = Object.keys(responseObject);
        for (let i = 0; i < properties.length; i++) {
            await validateResponseFields(properties[i], responseObject[properties[i]]);

            if (i === properties.length - 1) {
                // cuando termine de revisar cada uno de los campos, marcar como finalizado
                finishedLoop = true;
            }
        }

        // console.log('finishedLoop', finishedLoop)
        if (finishedLoop) {
            isNewResponse
                ? addNewObjectResponse(responseObject)
                : updateObjectResponse(responseObject, indexTable);
        }

        clearForm();
    };

    /**
     * @description Validar y guardar el form
     */
    function handleChangesForm(targetName, targetValue) {
        //console.log("targetName, targetValue", targetName, targetValue);
        let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;

        let responseObjectCopy = JSON.parse(JSON.stringify(responseObject));
        let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));
        let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
        let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));

        // Buscamos errores según el campo
        switch (targetName) {
            case "description":
                responseObjectCopy[targetName] = targetValue; // guardamos el campo

                // si la respuesta se mostrará como mensaje quitar los errores
                if (
                    label.newDescriptionField !== "Mensaje" &&
                    (targetValue === "" || !/\S/.test(targetValue))
                ) {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }
                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;

            case "attribute":
                responseObjectCopy[targetName] = targetValue; // guardamos el campo

                if (targetValue === "" || !/\S/.test(targetValue)) {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }

                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;

            case "mediaType":
                responseObjectCopy[targetName] = targetValue; // guardamos el campo

                if (targetValue === "" || null) {
                    formResponseErrorsCopy[targetName] = true;
                    errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
                    break;
                }

                //sin errores
                formResponseErrorsCopy[targetName] = false;
                errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
                break;

            case "saveAttribute":
                responseObjectCopy[targetName] = targetValue; // guardamos el campo
                break;

            default:
                break;
        }

        setResponseObject(responseObjectCopy);
        setFormHasChanges(true);

        //iteramos el objeto de errores
        const countErrors = Object.values(formResponseErrorsCopy).reduce((a, item) => a + item, 0);

        formHasErrorsCopy = countErrors > 0 ? true : false;

        setFormErrors({
            ...formErrors,
            formHasErrors: formHasErrorsCopy,
            formResponseErrors: formResponseErrorsCopy,
            errorHelperForm: errorHelperFormCopy,
        });
    }

    /**
     * @description buscar los errores en el form cuando se abre para editar
     */
    const searchFormErrors = () => {
        let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;

        let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
        let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));
        let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));

        for (const key in responseObject) {
            const formValue = responseObject[key];
            switch (key) {
                case "description":
                    // si la respuesta se mostrará como mensaje quitar los errores
                    if (label.newDescriptionField === "Mensaje" || formValue !== "") {
                        formResponseErrorsCopy[key] = false;
                        errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        break;
                    }

                    formResponseErrorsCopy[key] = true;
                    errorHelperFormCopy[key] = errorHelperKeys.emptyField;
                    break;
                case "attribute":
                    if (formValue !== "") {
                        formResponseErrorsCopy[key] = false;
                        errorHelperFormCopy[key] = errorHelperKeys.removeError;
                        break;
                    }
                    formResponseErrorsCopy[key] = true;
                    errorHelperFormCopy[key] = errorHelperKeys.emptyField;
                    break;
                case "mediaType":
                    if (tableType === "multimedia") {
                        if (formValue !== null) {
                            formResponseErrorsCopy[key] = false;
                            errorHelperFormCopy[key] = errorHelperKeys.removeError;
                            break;
                        }
                        formResponseErrorsCopy[key] = true;
                        errorHelperFormCopy[key] = errorHelperKeys.emptyField;
                        break;
                    }
                default:
                    break;
            }
        }

        //iteramos el objeto de errores
        const countErrors = Object.values(formResponseErrorsCopy).reduce((a, item) => a + item, 0);

        formHasErrorsCopy = countErrors > 0 ? true : false;
        setFormErrors({
            ...formErrors,
            formResponseErrors: formResponseErrorsCopy,
            errorHelperForm: errorHelperFormCopy,
            formHasErrors: formHasErrorsCopy,
        });
        setIsEditableDataSet(false);
    };

    /**
     * @name clearForm
     * @description al cerrar modal, limpiar form
     */
    const clearForm = () => {
        setFormHasChanges(false);
        setFormErrors(formErrorsInit);
        setResponseObject({
            attribute: "",
            description: "",
            mediaType: null,
            saveAttribute: false,
        });
    };

    /**
     * @name undoChanges
     * @description al cancelar, deshacer los cambios
     */
    const undoChanges = () => {
        if (isNewResponse) {
            clearForm();
            setIsEditableDataSet(true);
            return;
        }

        setResponseObject(previousValue);
        setFormHasChanges(false);
        setIsEditableDataSet(true);
    };

    return (
        <Fragment>
            <FormModal
                modalTitle={
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                        <Typography color="textPrimary">
                            {isNewResponse
                                ? "Respuesta del servicio web"
                                : "Editar respuesta del servicio web"}
                        </Typography>
                    </Breadcrumbs>
                }
                buttonType={isNewResponse ? "link" : null}
                buttonTooltip={isNewResponse ? "Agregar dato" : "Editar dato"}
                justIcon={isNewResponse ? null : <EditarIcono width="20px" height="20px" />}
                buttonName={isNewResponse ? "Agregar dato de respuesta al mensaje" : null}
                startIcon={isNewResponse ? <AgregarIcono width="15px" height="15px" /> : null}
                alignButtonRight={isNewResponse ? true : null}
                hasChanges={formHasChanges}
                hasErrors={formErrors.formHasErrors}
                isOpen={(e) => (e ? modalIsOpen(e) : null)}
                onCancel={() => undoChanges()}
                onSave={() => addNewResponse()}
                disableSaveOnError={true}
            >
                <Grid item xs={12}>
                    <div>
                        <div className="double-textField">
                            <div>
                                <label className="top-label-field">
                                    {label.newDescriptionField !== "Mensaje" && (
                                        <span style={{ color: "red" }}>* </span>
                                    )}
                                    {label.newDescriptionField}
                                </label>
                                <TextField
                                    name="description"
                                    value={responseObject.description}
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {
                                        handleChangesForm(e.target.name, e.target.value);
                                    }}
                                    error={formErrors.formResponseErrors.description}
                                    helperText={formErrors.errorHelperForm.description}
                                />
                            </div>
                            <div>
                                <label className="top-label-field">
                                    <span style={{ color: "red" }}>* </span>
                                    {label.newAttributeField}
                                </label>
                                <TextField
                                    name="attribute"
                                    value={responseObject.attribute}
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {
                                        handleChangesForm(e.target.name, e.target.value);
                                    }}
                                    error={formErrors.formResponseErrors.attribute}
                                    helperText={formErrors.errorHelperForm.attribute}
                                />
                            </div>
                        </div>
                        {label.mediaType && (
                            <div>
                                <label className="top-label-field">
                                    <span style={{ color: "red" }}>* </span>
                                    {label.mediaType}
                                </label>
                                <TextField
                                    select
                                    name="mediaType"
                                    value={responseObject.mediaType || null}
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {
                                        handleChangesForm(e.target.name, e.target.value);
                                    }}
                                    error={formErrors.formResponseErrors.mediaType}
                                    helperText={formErrors.errorHelperForm.mediaType}
                                >
                                    {mediaOptions.map((option, i) => (
                                        <MenuItem key={i} value={option.value}>
                                            {mediaOptions[i].name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        )}
                        {/* save response checkbox */}
                        <div
                            style={{
                                display: "block",
                            }}
                        >
                            <FormControlLabel
                                className="format-label"
                                control={
                                    <Checkbox
                                        name={responseAttributesKeys.saveAttribute.name}
                                        color="primary"
                                        checked={responseObject.saveAttribute}
                                        onChange={(e) =>
                                            handleChangesForm(e.target.name, e.target.checked)
                                        }
                                    />
                                }
                                label={responseAttributesKeys.saveAttribute.alias}
                            />
                        </div>
                    </div>
                </Grid>
            </FormModal>
        </Fragment>
    );
}

export default AddResponseObject;
