import React from 'react'

class ColumnCatalog extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            catalog: {
                //Columnas process.js
                "processName": "Nombre del Proceso",
                "keyWords": "Palabras Clave",
                "steps": "Pasos",
                'soapWSDLRequest': "Servicio SOAP",
                'soapOperation': "Operación SOAP",
                'bobyRequest': "Petición Boby",
                'replySuccessMessage': "Mensaje Éxito",
                'replyUnsuccessMessage': "Mensaje No Éxito",
                'responseObject': "Objeto con Respuesta",
                'succesResponse': "Código de éxito",
                'publicOption': "Opción Pública",
                'endPoint': "End Point",
                'linksTableCard': "Imagen en PDF",
                'responseAttributes': "Atributo de Respuesta",
                'getDescriptionValue': "Información previa",
                'unsuccesResponse': "Código de error",
                'sendMail': "Enviar correo",
                'flowCard': "Tipo de Tarjeta",
                'replyUnsuccessMessage2': "Segunda Mensaje No Éxito",
                'unsuccesResponse2': "Segundo Código de error",
                'tableCard': "Respuesta de Tabla",
                //Columnas para pasos de process.js
                "step": "Paso",
                "ask": "Pregunta",
                "error": "Mensaje de Error",
                "type": "Tipo de dato",
                "value": "Valor",
                "description": "Descripción",
                "globalValue": "Información Global",
                "allowJump": "Validación de salto",
                "attribute": "Campo de WS",
                "catalog": "Catálogo",
                "allowNestedFlows": "Detonar otro flujo",
                "triggerNewProcess": "Llamar a otro flujo",
                "delMessage": "Borrar Mensaje",
                "input":"Restricción de entrada",
                "jumpStep":"Saltar al paso...",
                "JumpCriteria":"Validación de salto",
                "subflows": "Subflujos",
                "enableMenuOptions": "Mostrar submenú",
                "hideInformation":"Esconder información sensible",
            }
        }
    }
    
    render() {
        return(
            <div>{this.state.catalog[this.props.searchName]}</div>
        )
    }
}

export default ColumnCatalog