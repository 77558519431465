import React, { useState } from "react";

//MUI components
import { TextField, Grid, InputAdornment } from "@material-ui/core";

//Icons
import SearchIcon from "@material-ui/icons/Search";

export default function SearchBox(props) {
    const { searchFunction, searchQuery } = props;

    const handleSearchBarTextField = (targetValue) => {
        searchFunction(targetValue);
    };

    return (
        <Grid item xs={12}>
            <TextField
                {...props}
                name="searchBox"
                value={searchQuery}
                size="small"
                type="small"
                onChange={(input) => handleSearchBarTextField(input.target.value)}
                placeholder="Buscar"
                fullWidth
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <div
                                style={{
                                    margin: "5px",
                                }}
                            >
                                <SearchIcon />
                            </div>
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    );
}
