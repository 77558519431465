import React, { useState, useContext } from "react";
//MUI components
import { Tooltip, Button, Button as MuiButton, Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

//Custom portal components
import CloseButton from "../../common/CloseButton";
import IntebotCatalog from "../../IntebotCatalog";
import CustomAlert from "../../common/Alerts/CustomAlert";
import LoadingDialog from "../../common/Modals/LoadingDialog";
import AppContext from "../../../Context/AppContext";

//Icons
import AgregarIcono from "../../../Icons/AgregarIcono";

// External Libraries
import { DropzoneAreaBase } from "material-ui-dropzone"; // https://yuvaleros.github.io/material-ui-dropzone/
import Swal from "sweetalert2"; //https://sweetalert2.github.io

//CSS sheets
import "../Styles/dropzone.css";

const ButtonFilled = withStyles((theme) => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        color: "white",
        height: 36,
        padding: "0 30px",
        "&:hover": {
            background: theme.palette.primary.light,
        },
        "&$disabled": {
            color: "white",
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: "none",
    },
}))(MuiButton);

const Dialog = withStyles((theme) => ({
    root: {
        marginTop: "56px",
        [theme.breakpoints.up("md")]: {
            marginTop: "64px",
        },
        "& .MuiDialog-paper": {
            minWidth: "35vw",
        },
    },
}))(MuiDialog);

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: "8px",
    },
    paper: { minWidth: "500px" },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, onClose } = props;
    return (
        <MuiDialogTitle disableTypography>
            <Typography variant="body1">{children}</Typography>
            {onClose ? (
                <CloseButton onClose={onClose} modalTitle="¿Estás seguro que deseas salir?">
                    Los cambios realizados se perderán.
                </CloseButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: (props) => ({
        [theme.breakpoints.up("sm")]: {
            paddingRight: "10%",
            paddingLeft: "10%",
        },
        "& div label": {
            display: "block",
            [theme.breakpoints.up("md")]: {
                textAlign: "right",
            },
        },
    }),
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        justifyContent: "center",
    },
}))(MuiDialogActions);

export default function AddFile(props) {
    const acceptedFilesQnA = IntebotCatalog.acceptedFilesQnA;
    const context = useContext(AppContext);

    const dropzoneInitials = {
        fileErrorsCopy: true,
        fileErrorHelperCopy: "* El archivo no puede estar vacío",
        newFileFields: { fileType: "", fileLink: "", fileName: "" }, //Agregar un nuevo archivo
        fileObjects: [], //Arreglo de archivos controlados que estan almacenados en el componente de Drop Zone
        fileDataToSend: [], //Arreglo de archivos con las cuatro propiedades necesarias para subirlos al blob #name #body #type #length,
        queuedFile: {}, //En caso de que ya exista un archivo en el drop zone, ponemos en espera el nuevo archivo, si el cliente acepta el remplazo, lo sustituimos con el anterior archivo
        indexOfFile: 0, //posición del archvio a ser remplazado/eliminado
        replaceFile: false, //Confirmación del usuario de remplazar un archivo sin súbir al blob en el <dialog>
        replaceExistingFile: false, //Confirmación del usuario de eliminar y remplazar un archivo traido desde una URL
    };

    //state
    const [open, setOpen] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [disabledSave, setDisabledSave] = useState(true);
    const [modalState, setModalState] = useState({
        openDialog: false,
        dialog: {
            title: "",
            message: "",
        },
    });

    const [loadingModal, setLoadingModal] = useState({
        open: false,
        message: "",
    });
    const [alertState, setAlertState] = useState({});
    const [sources, setSources] = useState([]);
    const [dropzoneFile, setDropzoneFile] = useState(dropzoneInitials);

    const handleOpen = () => {
        setOpen(true);
        if (props.kbSources.length > 0) {
            setSources(props.kbSources);
        }
    };

    const onCancel = () => {
        setDropzoneFile(dropzoneInitials);
        setHasChanges(false);
        setDisabledSave(true);
    };

    // cuando se agregar un nuevo archivo al dropzone,
    const handleAddNewFiles = async (ArrayOfFiles, indexVal, replace) => {
        setHasChanges(true);
        // console.log('ArrayOfFiles, indexVal, replace :>> ', ArrayOfFiles, indexVal, replace);

        const { fileObjects, fileDataToSend, fileErrors, fileErrorHelper } = dropzoneFile;

        let fileErrorsCopy = fileErrors;
        let fileErrorHelperCopy = fileErrorHelper;
        let index = indexVal || props.kbSources.length;

        //Copia del arreglo de archivos a ser mandados al blob
        let fileDataList = fileDataToSend;
        //Copia de la lista de todos los archivos cargados en el Dropzone
        let fileList = fileObjects;
        //Info extraida por el navegador del archivo a subir
        let fileToUpload = ArrayOfFiles[0];
        //Buscamos si ya existe el archivo que se ha agregado
        let existingFileNames = [...sources.map((storedFile) => storedFile.displayName)];
        let fileNameNoSpaces = fileToUpload.file.name.trim().replace(/ /g, "-");
        let duplicateFile =
            existingFileNames.includes(fileToUpload.file.name) ||
            existingFileNames.includes(fileNameNoSpaces);
        let resultOnDuplicate = false;
        if (duplicateFile) {
            //Preguntamos al usuario si quiere remplazar el archivo
            resultOnDuplicate = await Swal.fire({
                text: "El archivo seleccionado ya existe en el almacenamiento, ¿Deseas sobrescribirlo?",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            //Cancelamos el archivo subido dentro del Drop Zone
            if (resultOnDuplicate.isDenied || resultOnDuplicate.isDismissed) {
                return (fileToUpload = null);
            }
        }

        //hacemos el remplazo en caso de que la funcion sea llamada desde el <dialog>
        if (replace) {
            fileList.splice(index, 1, ArrayOfFiles);
            setDropzoneFile({
                ...dropzoneFile,
                replaceFile: true,
            });
        } else if (fileList[index]) {
            //Si no, preguntamos si existe un archivo en la posición del drop zone
            setModalState({
                openDialog: true,
                dialog: {
                    title: "¿Quieres remplazar el archivo existente?",
                    message: "Ya existe este documento, ¿deseas remplazarlo?",
                },
                //Ponemos al archivo en espera
                queuedFile: ArrayOfFiles,
                indexOfFile: index,
                replaceFile: true,
            });
            //Cancelamos el archivo subido dentro del Drop Zone
            fileToUpload = null;
        } else {
            //Si no hay remplazos ni archivos existentes, guardamos el arreglo de archivos en el state
            fileList = [ArrayOfFiles];
            //quitamos el error
            fileErrorsCopy = false;
            fileErrorHelperCopy = "";
        }

        //Si el archivo no fue cancelado, entonces extraemos la info el blob storage para almacenar el archivo
        let existingFile = false; //Control para saber si extraemos la data de un archivo existente o uno nuevo
        fileDataList = fileToUpload
            ? extractFileData(fileToUpload, index, replace, existingFile)
            : fileDataList;

        // console.log('fileDataList', fileDataList)
        setDropzoneFile({
            ...dropzoneFile,
            fileObjects: fileList,
            fileDataToSend: fileDataList,
            fileErrors: fileErrorsCopy,
            fileErrorHelper: fileErrorHelperCopy,
            replaceExistingFile: resultOnDuplicate.isConfirmed
                ? resultOnDuplicate.isConfirmed
                : resultOnDuplicate,
        });
        setDisabledSave(false);
    };

    //El servicio de Blob necesita cuatro propiedades para almacenar el archivo: #name #body #type #length,
    const extractFileData = (fileToUpload, index, replace, existingFile) => {
        //inicialización de variables
        const { fileDataToSend, newFileFields } = dropzoneFile;
        let newFileFieldsCopy = newFileFields;
        let fileDataList = fileDataToSend;

        //Extraemos las 4 propiedades del objeto File
        let fileName = fileToUpload.file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let onlyBase64Code = fileToUpload.data.split(",")[1];
        let fileType = fileToUpload.file.type;
        let fileSize = fileToUpload.file.size;

        let newFileName = fileName.trim().replace(/ /g, "-"); //reemplazar los espacios del nombre con guiones
        // aplicamos restricciones para el nombre del archivo no contenga " ó '
        const regex = /((\'|"))?/g; //regex para identificar: " ó '
        if (Boolean(newFileName.match(regex))) {
            newFileName = newFileName.replace(regex, "");
        }
        if (newFileName.length > 194) {
            let arraySplit = newFileName.split(/\.(?=[^\.]+$)/); //separamos el nombre del archivo de la extension
            let lastVal = arraySplit.pop(); // obtenemos la extension
            let firstVal = arraySplit.join("."); // obtenemos la primer parte del string, antes del último punto
            let trimmedString = firstVal.substring(0, 45); //cortamos el nombre del string para que conserve solo 45 caracteres
            newFileName = `${trimmedString}.${lastVal}`;
        }

        //Armamos el JSON que recibirá el WS
        const FILE_DATA = {
            name: newFileName,
            body: onlyBase64Code,
            type: fileType,
            length: fileSize,
            index: existingFile ? index : null, //Si es un archivo con un link existente, guardamos la posición del link a sustituir
        };

        //Preguntamos si es un archivo existente y remplazamos en el respectivo lugar
        if (existingFile) {
            newFileFieldsCopy.fileType = "file";
            newFileFieldsCopy.fileName = newFileName;
        } else {
            //Si es un archivo nuevo, lo agregamos en los campos nuevos
            newFileFieldsCopy = { fileType: "file", fileName: newFileName };
        }
        //la guardamos en su respectivo lugar
        if (replace) {
            //si es un archivo existente, en la posición a ser remplazada
            fileDataList.splice(index, 1, FILE_DATA);
        } else {
            fileDataList = FILE_DATA;
        }
        return fileDataList;
    };

    //Guardamos los archivos temporalmente
    const onSave = async () => {
        //Obtenemos el valor y las funciones de procesos
        const { fileDataToSend, replaceExistingFile } = dropzoneFile;
        const { kbSources } = props;
        const { fileDataFAQToSend } = context;

        // comprobamos que se hayan obtenido los datos del objeto a mandar
        if (Object.keys(fileDataToSend).length > 0) {
            //eliminamos el index para evitar mandar info innecesaria al WS
            delete fileDataToSend.index;
            let fileDataFAQToSendCopy = [...fileDataFAQToSend];

            // copiar arreglo con nombre de los archivos en la base de conocimientos y agregar el nuevo
            let kbSourcesCopy = [...kbSources];

            // si el archivo no se debe reemplazar, agregar al arreglo
            if (!replaceExistingFile) {
                let object = {
                    displayName: fileDataToSend.name,
                    source: "",
                };
                kbSourcesCopy.push(object);
                fileDataFAQToSendCopy.push(fileDataToSend);
            } else {
                // comprobamos si el archivo no se encuentra en los archivos que se subiran
                let filteredArray = fileDataFAQToSendCopy.filter(
                    (e) => e.name !== fileDataToSend.name
                );

                // agregamos el archivo
                filteredArray.push(fileDataToSend);
                fileDataFAQToSendCopy = filteredArray;
            }

            // guardamos temporalmente
            props.tempSaveFiles(fileDataFAQToSendCopy, kbSourcesCopy);

            setAlertState({
                open: true,
                severity: "success", //success, error, warning, info
                message: "Se ha guardado el archivo temporalmente.",
            });

            props.hasChanges(true);

            setLoadingModal({
                open: false,
            });
        }
        setOpen(false);
        setDropzoneFile(dropzoneInitials);
        setHasChanges(false);
        setDisabledSave(true);
    };

    const handleCloseDialog = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setModalState({
            openDialog: false,
            dialog: {
                title: "",
                message: "",
            },
        });
    };

    return (
        <div>
            <LoadingDialog open={loadingModal.open} LoadingMessage={loadingModal.message} />

            {/* Alertas como succes, error, info, las de <dropzone> se manejan interno al componente */}
            <CustomAlert
                open={alertState.open}
                severity={alertState.severity}
                message={alertState.message}
                handleCloseAlert={() => setAlertState({ ...alertState, open: false })}
            />

            {/* Dialogo de Confirmación de remplazar o eliminar un archivo*/}
            <Dialog open={modalState.openDialog} onClose={() => handleCloseDialog()}>
                <DialogTitle>{modalState.dialog.title}</DialogTitle>
                <DialogContent>{modalState.dialog.message}</DialogContent>
                <DialogActions>
                    <ButtonFilled onClick={() => handleCloseDialog()}>No</ButtonFilled>
                    <ButtonFilled
                        //dependiendo de donde fue invocado el dialogo, en confirmación lo regresamos a la función de invocación
                        onClick={() => {
                            const { indexOfFile } = modalState;
                            const { replaceFile, replaceExistingFile, queuedFile } = dropzoneFile;
                            let userConfirmation = true;

                            if (replaceFile) {
                                handleAddNewFiles(queuedFile, indexOfFile, userConfirmation);
                            }
                            if (replaceExistingFile) {
                                replaceExistingFile(queuedFile, indexOfFile, userConfirmation);
                            }

                            handleCloseDialog();
                        }}
                        color="primary"
                        autoFocus
                    >
                        Sí
                    </ButtonFilled>
                </DialogActions>
            </Dialog>

            <Tooltip title="Abrir modal para agregar archivo">
                <Button
                    onClick={() => {
                        handleOpen();
                    }}
                    startIcon={<AgregarIcono width="15px" height="15px" />}
                    size="small"
                >
                    Agregar archivo
                </Button>
            </Tooltip>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={() => {
                        setOpen(false);
                    }}
                >
                    Agregar archivo de preguntas frecuentes
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container justifyContent="center">
                        {/* {dropzoneFile.newFileFields.map((item, index) => ( */}
                        <Tooltip title="Arrastra o elige un archivo de tipo: .xlsx (excel)">
                            <div id="dropzone-faq">
                                <DropzoneAreaBase
                                    //archivos almacenados en el state
                                    fileObjects={
                                        dropzoneFile.fileObjects[0] || dropzoneFile.fileObjects
                                    }
                                    maxFileSize={3000000} //3 MB
                                    acceptedFiles={acceptedFilesQnA}
                                    filesLimit={1}
                                    showFileNames
                                    //cuando se agrega un archivo este se almacena en el state
                                    onAdd={(newFileObjs) => handleAddNewFiles(newFileObjs)}
                                    //CSS classes
                                    dropzoneClass={
                                        dropzoneFile.fileErrors
                                            ? "dropzoneContainerFaqWithError"
                                            : "dropzoneContainerFaq"
                                    }
                                    dropzoneParagraphClass="dropzoneParagraph"
                                    previewGridClasses={{
                                        container: "previewGridContainer",
                                        item: "previewGridItem",
                                        image: "previewGridImage",
                                    }}
                                    alertSnackbarProps={{
                                        anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "right",
                                        },
                                    }}
                                    //Mensajes del Drop Zone
                                    dropzoneText={"Archivo"}
                                    getFileAddedMessage={(fileName) =>
                                        `El archivo ${fileName} fue agregado con éxito.`
                                    }
                                    getFileLimitExceedMessage={(filesLimit) =>
                                        `Número máximo de archivos alcanzados. Solamente se permiten ${filesLimit} archivos.`
                                    }
                                    getFileRemovedMessage={(fileName) =>
                                        `El archivo ${fileName} ha sido eliminado.`
                                    }
                                    getDropRejectMessage={(rejectedFile) =>
                                        `Este archivo ${rejectedFile.name} no es compatible o excede el máximo permitido de 3MB.`
                                    }
                                />
                                {dropzoneFile.fileErrors ? (
                                    <span
                                        style={{
                                            fontSize: "0.75rem",
                                            color: "red",
                                        }}
                                    >
                                        {dropzoneFile.fileErrorHelper}
                                    </span>
                                ) : null}
                            </div>
                        </Tooltip>
                        {/* ))} */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonFilled disabled={!hasChanges} onClick={() => onCancel()}>
                        Cancelar
                    </ButtonFilled>
                    <ButtonFilled
                        onClick={() => {
                            onSave();
                        }}
                        disabled={disabledSave}
                    >
                        Guardar
                    </ButtonFilled>
                </DialogActions>
            </Dialog>
        </div>
    );
}
