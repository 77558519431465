import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'

//Mui components
import {
    TextField,
    InputAdornment,
    Button as MuiButton,
    IconButton,
    LinearProgress
  } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

//Icons 
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

//External Libraries 
import { isEmail, isStrongPassword, isAlpha } from "validator"; //https://github.com/validatorjs/validator.js/
import Swal from 'sweetalert2' //https://sweetalert2.github.io

//Custom Portal Components
import AppContext from '../../Context/AppContext';
import IntebotCatalog from '../IntebotCatalog'
import { hashPassword } from './hashPassword';
import { authActions } from '../DataFunctions'

const errorHelperKeys = IntebotCatalog.errorHelper

const Button = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        //border: 0,
        color: 'white',
        height: 36,
        padding: '0 30px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&$disabled': {
            color: 'white',
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: 'none',
    },
}))(MuiButton);



const Createaccount = () => {

    //React Hooks
    const context = useContext(AppContext);
    const { updateContextAttribute } = context
    const [state, setState] = useState({
        //New Account
        createAccountForm: {
            name:'',
            lastName: '',
            email:'',
            subscriptionId:'',
            password:'',
        },
        confirmPassword: '',
        newAccountErrors: {
            name: false,
            lastName: false,
            email: false,
            subscriptionId: false,
            password: false,
            confirmPassword: false,
        },
        errorHelperNewAccount:{
            name: errorHelperKeys.removeError,
            lastName: errorHelperKeys.removeError,
            email: errorHelperKeys.removeError,
            subscriptionId: errorHelperKeys.removeError,
            password: errorHelperKeys.removeError,
            confirmPassword: errorHelperKeys.removeError,
        },
        passwordScore:0, 
        isPasswordStrong: false,
        //Auxiliar
        showPassword: false,
    });

    useEffect(() => {
        performURLaction();
    }, [])

    const performURLaction = () => {
        let url = new URL(document.location);
        let searchParams = new URLSearchParams(url.search);
        let token = searchParams.get("token")
        let action = searchParams.get("action")
        switch (action) {
            case 'ConfirmRole':
                getTokenData(token)
                break;
        
            default:
                break;
        }
    }

    const removeTokenFromURL = () => {
        let currentURL = window.location.href;
        let afterDomain = currentURL.substring(currentURL.lastIndexOf('/') + 1);
        let beforeQueryString= afterDomain.split("?")[0];  
        window.history.pushState({}, document.title, "/" + beforeQueryString );
    }

    const getTokenData = async (token) => {
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Cargando información...')
        let res = await authActions({},`ConfirmRole&token=${token}`,'RoleManagement')
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 
        switch (responseCode) {
            case 1:
                const { createAccountForm } = state;
                let createAccountFormCopy = JSON.parse(JSON.stringify(createAccountForm))
                const { email, subscriptionId, role } = res.data.response.data;
                createAccountFormCopy = {
                    ...createAccountFormCopy,
                    email: email,
                    subscriptionId: subscriptionId,
                    role: role
                }
                //Borramos el token del URL
                removeTokenFromURL()
                setState({
                    ...state,
                    createAccountForm: createAccountFormCopy
                })
                break;
            case 9:
                let resultAlert = await Swal.fire({
                    icon: 'success',
                    title: 'El usuario ha sido agregado correctamente a la suscripción',    
                    text: 'Ya puedes utilizar el portal',
                })
                if(resultAlert){
                    window.location.href="/login"
                }
                break;
            default:
                let result = await Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error al registrarse.',    
                    text: 'Intenta más tarde.',
                })
                if(result){
                    window.location.href="/login"
                }
                break;
        }
    }
    

    //Cambiar el campo de password -> Text
    const handleShowPassword = () => {
        const { showPassword } = state;
        setState({
            ...state,
            showPassword: showPassword ? false : true,
        })
    }

    //===== Create Account ===========
    const clearCreateAccountForm = () => {
        setState({
            ...state,
            createAccountForm: {
                name:'',
                lastName: '',
                email:'',
                subscriptionId:'',
                password:'',
            },
            confirmPassword: '',
        })
    }

    const searchErrorsCreateAccount = () => {
        const { createAccountForm, newAccountErrors, errorHelperNewAccount, isPasswordStrong, confirmPassword } = state;
        let { password } = createAccountForm;
        let newAccountErrorsCopy = JSON.parse(JSON.stringify(newAccountErrors));
        let errorHelperNewAccountCopy = JSON.parse(JSON.stringify(errorHelperNewAccount));
        //Iteramos el objeto en busca de errores
        for (const newAccountKey in createAccountForm) {
            const formValue = createAccountForm[newAccountKey]; //valor actual que esta siendo iterado
            if(formValue === ''){
                //Preguntamos si el campo actual esta vacío
                newAccountErrorsCopy[newAccountKey] = true;
                errorHelperNewAccountCopy[newAccountKey] = errorHelperKeys.emptyField;
            }
            //Buscamos errores especificos para cada campo
            switch (newAccountKey) {
                case 'name':
                    if(formValue.length < 3){
                        newAccountErrorsCopy[newAccountKey] = true;
                        errorHelperNewAccountCopy[newAccountKey] = errorHelperKeys.notName;
                        break;
                    }
                    //Validamos que el nombre no contenga números o simbolos
                    let validName = isAlpha( formValue, 'es-ES', {ignore : ' '})
                    if(!validName){
                        newAccountErrorsCopy[newAccountKey] = true;
                        errorHelperNewAccountCopy[newAccountKey] = errorHelperKeys.notName;
                        break;
                    }
                    break;
                case 'lastName':
                    if(formValue.length < 3){
                        newAccountErrorsCopy[newAccountKey] = true;
                        errorHelperNewAccountCopy[newAccountKey] = errorHelperKeys.notLastName;
                        break;
                    }
                     //Validamos que el nombre no contenga números o simbolos
                    let validLastName = isAlpha( formValue, 'es-ES', {ignore : ' '})
                    if(!validLastName){
                        newAccountErrorsCopy[newAccountKey] = true;
                        errorHelperNewAccountCopy[newAccountKey] = errorHelperKeys.notName;
                        break;
                    }
                    break;
                // case 'email':
                //     //preguntamos si el correo es valido
                //     let validEmail = isEmail(formValue)
                //     if(!validEmail){
                //         newAccountErrorsCopy[newAccountKey] = true;
                //         errorHelperNewAccountCopy[newAccountKey] = errorHelperKeys.notEmail;
                //         break;
                //     }
                //     createAccountForm.email = createAccountForm.email.trim() //quitamos los espacios al final de la cadena
                //     break;
                case 'password':
                    //Preguntamos si la contraseña NO cumple los requisitos
                    if(!isPasswordStrong){
                        newAccountErrorsCopy[newAccountKey] = true;
                        errorHelperNewAccountCopy[newAccountKey] = errorHelperKeys.notStrongPassword;
                        break;
                    }
                    break;
                // case 'subscriptionId':
                //     //El subscription ID debe medir más de 24
                //     if(formValue.length !== 24){
                //         newAccountErrorsCopy[newAccountKey] = true;
                //         errorHelperNewAccountCopy[newAccountKey] = errorHelperKeys.notSubscriptionID;
                //         break;
                //     }
                //     break;
                default:
                    break;
            }
        }
        //Las contraseñas no coinciden
        if(password !== confirmPassword){
            newAccountErrorsCopy['confirmPassword'] = true;
            errorHelperNewAccountCopy['confirmPassword'] = errorHelperKeys.passwordNotMatch;
        }
        //iteramos el objeto de errores
        let createAccountFormHasErrors = false;
        for (const key in newAccountErrorsCopy) {
            const errorValue = newAccountErrorsCopy[key];
            //Si encontramos 1 error, ponemos la bandera en true y evitamos que se mande el form
            if(errorValue){
                createAccountFormHasErrors = true;
                break; 
            }
        }
        //si no hay errores, mandamos a llamar a crear la cuenta
        if(!createAccountFormHasErrors) {
            sendCreateAccountForm(createAccountForm)
        }
        setState({
            ...state,
            newAccountErrors: newAccountErrorsCopy,
            errorHelperNewAccount: errorHelperNewAccountCopy
        })
    }

    //Guardar el form de Registro con sus respectibas validaciones para cada campo
    const handleNewAccountForm = (targetName, targetValue) => {
        let { createAccountForm, passwordScore, isPasswordStrong, newAccountErrors, errorHelperNewAccount, confirmPassword } = state;
        //console.log(targetName, targetValue);
        let newAccountErrorsCopy = JSON.parse(JSON.stringify(newAccountErrors));
        let errorHelperNewAccountCopy = JSON.parse(JSON.stringify(errorHelperNewAccount));
        errorHelperNewAccount['createAccount'] = errorHelperKeys.removeError; //Quitamos el error del form cuando lo vuelven a llenar
        //Buscamos errores según el campo
        switch (targetName) {
            case "name":
                if(targetValue.length > 50){
                    //NO guardamos el campo
                    break;
                }
                //sin errores
                createAccountForm[targetName] = targetValue //guardamos el campo
                newAccountErrorsCopy[targetName] = false;
                errorHelperNewAccountCopy[targetName] = errorHelperKeys.removeError;
                break;
            case "lastName":
                if(targetValue.length > 50){
                    //NO guardamos el campo
                    break;
                }
                //sin errores
                createAccountForm[targetName] = targetValue //guardamos el campo
                newAccountErrorsCopy[targetName] = false;
                errorHelperNewAccountCopy[targetName] = errorHelperKeys.removeError;
                break;
            case "email":
                if(targetValue.length > 50){
                    //NO guardamos el campo
                    break;
                }
                //sin errores
                createAccountForm[targetName] = targetValue //guardamos el campo
                newAccountErrorsCopy[targetName] = false;
                errorHelperNewAccountCopy[targetName] = errorHelperKeys.removeError;
                break;
            case "subscriptionId":
                if(targetValue.length > 24){
                    //NO guardamos el campo
                    break;
                }
                //sin errores
                createAccountForm[targetName] = targetValue //guardamos el campo
                newAccountErrorsCopy[targetName] = false;
                errorHelperNewAccountCopy[targetName] = errorHelperKeys.removeError;
                break;
            case 'password':
                //La contraseña debe cumplir con los requerimientos minimos, Min 9 caracteres, Max 15, 1 mayuscula, 1 minuscula, 1 numero, 1 caracter especal
                isPasswordStrong = isStrongPassword(targetValue, { minLength: 8, returnScore: false }) //Regresa true si la contraseña cumple los requerimientos
                passwordScore = isStrongPassword(targetValue, { minLength: 8, returnScore: true }) * 2 //Un score de 50 o más = %100 valido, entonces lo multiplicamos por 2 para tener la barra al 100%
                if(targetValue.length > 15) {
                    //NO guardamos el campo
                    break;
                }
                //sin errores
                createAccountForm[targetName] = targetValue //guardamos el campo
                newAccountErrorsCopy[targetName] = false;
                errorHelperNewAccountCopy[targetName] = errorHelperKeys.removeError;
                break;
            case 'confirmPassword':
                if(targetValue.length > 15) {
                    //NO guardamos el campo
                    break;
                }
                confirmPassword = targetValue //guardamos el campo
                //sin errores
                newAccountErrorsCopy[targetName] = false;
                errorHelperNewAccountCopy[targetName] = errorHelperKeys.removeError;
                break;
            default:
                createAccountForm[targetName] = targetValue //guardamos el campo
                 //sin errores
                 newAccountErrorsCopy[targetName] = false;
                 errorHelperNewAccountCopy[targetName] = errorHelperKeys.removeError;
                break;
        }
        setState({
            ...state,
            createAccountForm:createAccountForm,
            passwordScore: passwordScore,
            isPasswordStrong: isPasswordStrong,
            newAccountErrors: newAccountErrorsCopy,
            errorHelperNewAccount: errorHelperNewAccountCopy,
            confirmPassword: confirmPassword
        })
    }

    const sendCreateAccountForm = async (createAccountForm) => {
        const { errorHelperNewAccount, newAccountErrors } = state;
        let { password } = createAccountForm;
        let passwordCopy =  password
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Creando usuario...')
        //hashear la password crea un pequeño delay, entonces esperamos a que aparezca el dialogo de loading, y despues hasheamos y llamamos al servicio
        createAccountForm.password = await hashPassword(password)
        //Hacemos la petición y recibimos un código de respuesta
        let res = await authActions(createAccountForm, 'SignUp', 'SignUp')
        //Regresamos la contraseña no hasheada una vez mandado el form
        createAccountForm.password = passwordCopy
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 1 //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        //si no hay respuesta significa que algo salio mal y ponemos el error
        if(!res){
            errorHelperNewAccount['createAccount'] = errorHelperKeys.createAccountError;
            updateContextAttribute('loadingDialog', false)
            updateContextAttribute('LoadingMessage', '')
            clearCreateAccountForm();
            return
        }
        //si hay una respuesta de parte del servicio, quitamos los dialogos de loading
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
        clearCreateAccountForm();
        //Ponemos los errores según el codigo de respuesta
        switch (responseCode) {
            case 0:
                let result = await Swal.fire({
                    icon: 'success',
                    title: 'Cuenta Creada con exito',    
                    text: 'Ya puedes iniciar sesión',
                })
                if(result){
                    window.location.href="/login"
                }
                break;
            case 1: //Error al crear cuenta
                errorHelperNewAccount['createAccount'] = errorHelperKeys.createAccountError;
                break;
            case 2: //Cuenta existente
                errorHelperNewAccount['createAccount'] = errorHelperKeys.accountExists;
                break;
            case 3: //Subscription ID no válido
                newAccountErrors['subscriptionId'] = true;
                errorHelperNewAccount['subscriptionId'] = errorHelperKeys.notSubscriptionID;
                break;
            default:
                break;
        }
        setState({
            ...state,
            errorHelperNewAccount: errorHelperNewAccount
        })
    }

    const { showPassword, createAccountForm, passwordScore, isPasswordStrong, newAccountErrors, errorHelperNewAccount, confirmPassword } = state;
    return (
        <div>
            <header>
                <h2>Registro de cuenta</h2>
            </header>
            {/* Errores del Create Account */}
            <div style={{display: 'flex',justifyContent:'center'}} >
                <span style={{fontSize: '0.75rem',color:'red'}} >{errorHelperNewAccount['createAccount']}</span> 
            </div>
            {/* Campos de Registro */}
            <div className='AuthInputsContainer' >
                <TextField
                    disabled
                    name="email"
                    value={createAccountForm.email}
                    variant="outlined"
                    size="small"
                    type="email"
                    fullWidth
                    onChange={(e) => handleNewAccountForm(e.target.name, e.target.value)} //handle Create Form
                    label='Correo'
                    error={newAccountErrors.email}
                    helperText={errorHelperNewAccount.email}
                />
                <TextField
                    name="name"
                    value={createAccountForm.name}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) => handleNewAccountForm(e.target.name, e.target.value)} //handle Create Form
                    label='Nombre'
                    error={newAccountErrors.name}
                    helperText={errorHelperNewAccount.name}
                />
                <TextField
                    name="lastName"
                    value={createAccountForm.lastName}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) => handleNewAccountForm(e.target.name, e.target.value)} //handle Create Form
                    label='Apellido'
                    error={newAccountErrors.lastName}
                    helperText={errorHelperNewAccount.lastName}
                />
                {/* Campo Oculto de la suscripción
                <TextField
                    style={{display: "none"}}
                    name="subscriptionId"
                    value={createAccountForm.subscriptionId} //Create Form Value
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) => handleNewAccountForm(e.target.name, e.target.value)} //handle Create Form
                    label='ID de suscripción'
                    error={newAccountErrors.subscriptionId}
                    helperText={errorHelperNewAccount.subscriptionId}
                /> */}
                <TextField
                    name="password"
                    value={createAccountForm.password}
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleNewAccountForm(e.target.name, e.target.value)}
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    error={newAccountErrors.password}
                    helperText={errorHelperNewAccount.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton onClick={() => handleShowPassword()}>
                                        { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                    </IconButton>
                            </InputAdornment>
                        ) 
                    }}
                />
                {!newAccountErrors['password'] && <span style={{fontSize: '0.75rem'}} >{errorHelperKeys.notStrongPassword}</span>} 
                <TextField
                    name="confirmPassword"
                    value={confirmPassword}
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleNewAccountForm(e.target.name, e.target.value)}
                    label="Confirmar contraseña"
                    type={showPassword ? "text" : "password"}
                    error={newAccountErrors.confirmPassword}
                    helperText={errorHelperNewAccount.confirmPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton onClick={() => handleShowPassword()}>
                                        { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                    </IconButton>
                            </InputAdornment>
                        ) 
                    }}
                />
                <div>
                    <span>Seguridad de la contraseña</span>
                    <LinearProgress 
                        classes={{
                            bar: isPasswordStrong ? 
                                    'linearProgressColorGreen'
                                : passwordScore > 50 ? 
                                    'linearProgressColorOrange' 
                                : 'linearProgressColorRed'
                        }} 
                        variant="determinate" 
                        value={passwordScore < 100 ? passwordScore : 100} 
                    />
                </div>
                <div className="restoreHelpContainer">
                    <Link to="/login">Inicio de sesión</Link> 
                </div>
                <div className="authButtonContainer" >
                    <Button onClick={() => searchErrorsCreateAccount()} >Regístrate</Button>
                </div>
            </div>
        </div>
    );
}

export default Createaccount;

