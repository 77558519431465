import React, { useState, useEffect, useContext, useRef } from "react";

// MUI components
import { Grid, Button, CircularProgress, MenuItem, TextField } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

// Custom portal components
import SearchBox from "../common/SearchBox";
import FaqPair from "./FAQPair";
import { actionsQnAmaker, blobStorage, intebotDataActions } from "../DataFunctions";
import RefreshButton from "../common/RefreshButton";
import IntebotCatalog from "../IntebotCatalog";
import FooterControls from "../../components/FooterComponents/FooterControls";
import AppContext from "../../Context/AppContext";
import SearchNoResults from "../common/SearchNoResults";

// External Libraries
import Swal from "sweetalert2"; // https://sweetalert2.github.io

// Icons
import AgregarIcono from "../../Icons/AgregarIcono";

// Styles Sheets
import "./Styles/editQnAQuestions.css";

const EditQnAQuestions = () => {
    const context = useContext(AppContext);

    const [pagination, setPagination] = useState({
        pages: 0,
        currentPage: 1,
        itemsPerPage: 10,
    });

    // Get Pairs
    const qnaPairs = useRef([]); // Todos los pares provenientes de la KB
    const filteredPairs = useRef([]); // Los pares filtrados mediante el buscador
    const filterPairsByFile = useRef([]); // Copia de los pares de un archivo en específico
    const [renderPairs, setRenderPairs] = useState([]); // Los pares mostrados en la interfaz

    // Modify Pairs
    const pairsToAdd = useRef([]); // Objeto que obtiene el WS para crear pares nuevos
    const pairsToDelete = useRef([]); // Objeto que utiliza el WS para eliminar pares
    const pairsToUpdate = useRef([]); // Objeto que modifica las preguntas o respuestas existentes en el WS

    // Errors Catalogs
    const qnaErrors = useRef([]);
    const qnaErrorHelper = useRef([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [fileQuery, setFileQuery] = useState("Mostrar todas las preguntas");
    const [loading, setLoading] = useState(false);
    const [errorInFetch, setErrorInFetch] = useState(false);
    const [searchHasNoResults, setSearchHasNoResults] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const kbFileSources = useRef([]);
    const firstIdsFromSources = useRef([]);

    // Multimedia
    const [dropZoneOpenObj, setDropZoneOpenObj] = useState({}); // Array de booleanos para abrir y cerrar el dropzone de cada par
    const [fileDataToSend, setFileDataToSend] = useState([]); // Array de objetos de archivos a ser mandados al blob
    const [fileDataToDelete, setFileDataToDelete] = useState([]); // Array de objetos de archivos a ser eliminados del blob

    const { pages, currentPage, itemsPerPage } = pagination;

    const {
        updateContextAttribute,
        sleep,
        fetchKnowledgeBaseSources,
        getStorageData,
        readOnly,
        messagesTemp,
    } = context;
    const { token } = getStorageData(["token"]);

    const errorHelperKeys = IntebotCatalog.errorHelper;

    // Did mount
    useEffect(() => {
        refreshQnaMaker(true);
    }, []);

    const clearQnAMakerState = () => {
        qnaPairs.current = [];
        pairsToAdd.current = [];
        pairsToDelete.current = [];
        pairsToUpdate.current = [];
        filteredPairs.current = [];
        setRenderPairs([]);
        setSearchQuery("");
        setFileQuery("Mostrar todas las preguntas");
        setHasChanges(false);
        setPagination({
            pages: 0,
            currentPage: 1,
            itemsPerPage: 10,
        });
        qnaErrors.current = [];
        qnaErrorHelper.current = [];
        setErrorInFetch(false);
        setSearchHasNoResults(false);
        setFileDataToSend([]);
        setFileDataToDelete([]);
        setDropZoneOpenObj({});
    };

    const refreshQnaMaker = async (refreshNoConfirm) => {
        if (!refreshNoConfirm && !errorInFetch && hasChanges) {
            let alert = await Swal.fire({
                title: "¿Deseas actualizar la lista de preguntas?",
                text: "Se perderán las modificaciones no guardadas",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            // Cancelamos el eliminado del Par
            if (alert.isDenied || alert.isDismissed) {
                return;
            }
        }
        clearQnAMakerState();
        setLoading(true);
        await sleep(1000); // Esperamos a que el servicio actualize la KB
        await getQnAPairs();
        await getQnaKBSources();
    };

    const handleImageUploadField = (idPair) => {
        setDropZoneOpenObj((prevState) => ({
            ...prevState,
            [idPair]: true,
        }));
    };

    const closeImageUploadField = async (idPair) => {
        let alert = await Swal.fire({
            text: "¿Deseas eliminar este archivo multimedia?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        // Cancelamos el eliminado del Par
        if (alert.isDenied || alert.isDismissed) {
            return;
        }

        //borramos el archivo de la lista de archivos a mandar
        let fileDataToSendCopy = [...fileDataToSend];
        let fileIndex = fileDataToSendCopy.findIndex((file) => file.id === idPair);
        if (fileIndex > -1) {
            fileDataToSendCopy.splice(fileIndex, 1);
        }

        setFileDataToSend(fileDataToSendCopy);

        setDropZoneOpenObj((prevState) => ({
            ...prevState,
            [idPair]: false,
        }));
    };

    const handleAddNewFiles = async (fileToUpload, id) => {
        if (!fileToUpload) return;

        // Copia del arreglo de Archivos a ser mandados al blob
        let fileDataToSendCopy = [...fileDataToSend];
        let fileData = await extractFileData(fileToUpload, id);
        if (!fileData) return;

        fileDataToSendCopy.push(fileData);
        setFileDataToSend(fileDataToSendCopy);
    };

    const handleDeleteFile = async (idPair, fileToDelete) => {
        let alert = await Swal.fire({
            text: "¿Deseas eliminar este archivo multimedia?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        // Cancelamos el eliminado del Par
        if (alert.isDenied || alert.isDismissed) {
            return;
        }

        let data = {
            name: fileToDelete.fileName,
        };
        // Copia del arreglo de Archivos a ser eliminados del blob
        let fileDataToDeleteCopy = [...fileDataToDelete];
        fileDataToDeleteCopy.push(data);
        setFileDataToDelete(fileDataToDeleteCopy);

        //Eliminamos el archivo de la lista de messagesTemp
        let messagesCopy = JSON.parse(JSON.stringify(messagesTemp));
        let fileIndex = messagesCopy.MULTIMEDIA_MESSAGES.findIndex(
            (file) => file.idPair === idPair
        );

        if (fileIndex > -1) {
            messagesCopy.MULTIMEDIA_MESSAGES.splice(fileIndex, 1);
        }
        updateContextAttribute("messagesTemp", messagesCopy);
    };

    const extractFileData = async (fileToUpload, id) => {
        if (!fileToUpload) return;
        // Extraemos las 4 propiedades del objeto File
        let fileName = fileToUpload.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let fileExtension = fileName.split(".");
        let FileBase64Code = await readFile(fileToUpload);
        let onlyBase64Code = FileBase64Code.split(",")[1];
        let fileType = fileToUpload.type;
        let fileSize = fileToUpload.size;

        // Creamos el objeto a ser mandado al blob
        return {
            name: `${fileExtension[0]}_${Math.floor(Math.random() * 1000000).toString()}.${
                fileExtension[1]
            }`, // Nombre del archivo + número aleatorio + extensión
            body: onlyBase64Code,
            type: fileType,
            length: fileSize,
            id,
            message: qnaPairs.current.find((pair) => pair.id === id).answer,
        };
    };

    function readFile(file) {
        if (!file) return;
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Define what happens once the file is loaded
            reader.onload = function (event) {
                resolve(event.target.result); // Resolve with the file contents
            };

            // Define what happens if there's an error reading the file
            reader.onerror = function (event) {
                reject(event.target.error); // Reject with the error
            };

            // Start reading the file
            reader.readAsDataURL(file);
        });
    }

    // Obtenemos los pares de Preguntas - Respuesta del ws
    const getQnAPairs = async () => {
        setLoading(true);
        let res = await actionsQnAmaker(null, "getAnswersKb");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; // si no hay respuesta correcta del serivico, mandamos el codigo a 99 para indicar el error
        setLoading(false);
        switch (responseCode) {
            case 0:
                let qnaPairsFromKB = res.data.qnaPairs.reverse();
                // Ponemos Primero los Pares de la Editorial
                let qnaPairsFromEditoral = [];
                let qnaPairsFromFiles = [];
                // Iteramos y mandamos cada pregunta a su respectivo array
                for (let index = 0; index < qnaPairsFromKB.length; index++) {
                    const pair = qnaPairsFromKB[index];
                    if (pair.source === "Editorial") {
                        qnaPairsFromEditoral.push(pair);
                        continue;
                    }
                    qnaPairsFromFiles.push(pair);
                }
                // Juntamos ambos arrays primero la editorial y luego los pair de archivos y guardamos
                qnaPairsFromKB = [...qnaPairsFromEditoral, ...qnaPairsFromFiles];
                qnaPairs.current = qnaPairsFromKB;
                let pages = calculatePagination(qnaPairsFromKB.length);
                setPagination({
                    ...pagination,
                    currentPage: 1,
                    pages: pages,
                });

                loadFirstPairsPage(qnaPairsFromKB);
                setErrorInFetch(false);
                return qnaPairsFromKB;
            case 20:
                setErrorInFetch(true);
                Swal.fire({
                    icon: "info",
                    title: "El servicio de FAQ está en mantenimeinto.",
                    text: "Espere unas horas e intente nuevamente.",
                });
                break;
            default:
                setErrorInFetch(true);
                break;
        }
    };

    const getQnaKBSources = async () => {
        setLoading(true);
        try {
            // Obtenemos la lista de archivos del Qna
            let allSourcesArray = await fetchKnowledgeBaseSources();
            allSourcesArray.unshift({
                source: "Mostrar todas las preguntas",
                displayName: "Mostrar todas las preguntas",
            });
            let editorialIndex = allSourcesArray.findIndex(
                (fileObj) => fileObj.source === "Editorial"
            );
            allSourcesArray[editorialIndex].displayName = "Agregadas manualmente";
            let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
            // Obtenemos los primeros pares de cada archivo
            let firstQuestionIdFromEachSource = getFirstPairsFromFiles(
                allSourcesArray,
                qnaPairsCopy
            );
            kbFileSources.current = allSourcesArray;
            firstIdsFromSources.current = firstQuestionIdFromEachSource;
        } catch (error) {
            setErrorInFetch(true);
        }
        setLoading(false);
    };

    // Ejecutada cada vez que el search query cambia
    useEffect(() => {
        setLoading(true);
        setSearchHasNoResults(false);
        // Esperamos a que el usuario termine de escribir
        const awaitUserTyping = setTimeout(() => {
            if (searchQuery === "") {
                filterFAQpairsByFile(fileQuery);
            } else {
                searchFAQpair();
            }
        }, 250);
        return () => clearTimeout(awaitUserTyping);
    }, [searchQuery]);

    useEffect(() => {
        setSearchHasNoResults(false);
        filterFAQpairsByFile(fileQuery);
        if (fileQuery !== "Editorial" && fileQuery !== "Mostrar todas las preguntas") {
            deleteEmptyPairs();
        }
    }, [fileQuery]);

    useEffect(() => {
        updateContextAttribute("FAQpageHasChanges", hasChanges);
    }, [hasChanges]);

    const searchQnA = (searchQuery) => {
        setSearchQuery(searchQuery);
    };

    const getFirstPairsFromFiles = (sourcesArray, qnaPairsCopy) => {
        let firstQuestionIdFromEachSource = [];
        let sourcesArrayCopy = JSON.parse(JSON.stringify(sourcesArray));
        let showAllSourcesIndex = sourcesArrayCopy.findIndex(
            (source) => source === "Mostrar todas las preguntas"
        );
        if (showAllSourcesIndex > -1) {
            sourcesArrayCopy.splice(showAllSourcesIndex, 1);
        }
        sourcesArrayCopy.forEach((source) => {
            let AllRenderPairsFromSource = qnaPairsCopy.filter((pair) => pair.source === source);
            if (AllRenderPairsFromSource.length > 0) {
                let firstQuestionFromSource = AllRenderPairsFromSource.shift();
                firstQuestionIdFromEachSource.push(firstQuestionFromSource.id);
            }
        });
        return firstQuestionIdFromEachSource;
    };

    const deleteEmptyPairs = () => {
        let qnaErrorsCopy = JSON.parse(JSON.stringify(qnaErrors.current));
        for (const pairErrors of qnaErrorsCopy) {
            let answerIsEmpty = pairErrors.answer;
            let questionsIsEmpty = pairErrors.questions.every((error) => error);
            if (answerIsEmpty && questionsIsEmpty) {
                let deleteEmptyPair = true;
                removePair(pairErrors.id, deleteEmptyPair);
            }
        }
    };

    const getPairsById = (pairsIds) => {
        let foundPairs = [];
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        pairsIds.forEach((id) => {
            let pair = qnaPairsCopy.find((pair) => pair.id === id);
            foundPairs.push(pair);
        });
        return foundPairs;
    };

    const searchFAQpair = () => {
        let filteredIDs = [];
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        // Si esta seleccionado un filequery, entonces hacemos la busqueda en las preguntas de ese archivo
        if (fileQuery !== "Mostrar todas las preguntas") {
            qnaPairsCopy = getPairsById(filteredPairs.current);
        }
        // Iteramos todos los pares
        qnaPairsCopy.forEach((pair) => {
            const { answer, questions } = pair;
            // Buscamos primero si hay preguntas con la busqueda
            let filteredQuestions = questions.filter((questionObj) =>
                questionObj.question.toLowerCase().includes(searchQuery.toLowerCase().trimEnd())
            );
            // Si encuentra preguntas con el query, sustituimos las preguntas encontradas en el par y regresamos el par completo
            if (filteredQuestions.length > 0) {
                // pair.questions = filteredQuestions
                return filteredIDs.push(pair.id);
            }
            // Si no encuentra preguntas, buscamos entonces en la respuesta, si encuentra una respuesta, regresamos todo el par con todas las preguntas
            if (answer.toLowerCase().includes(searchQuery.toLowerCase())) {
                return filteredIDs.push(pair.id);
            }
        });
        // Guardamos la busqueda de IDs
        filteredPairs.current = filteredIDs;
        // Calculamos la paginación con el nuevo arreglo y rendereamos la primera pagina.
        let filteredPairsFromQuery = getPairsById(filteredIDs);
        let pages = calculatePagination(filteredPairsFromQuery.length);
        loadFirstPairsPage(filteredPairsFromQuery);
        setPagination({
            ...pagination,
            currentPage: 1,
            pages: pages,
        });
        // Indicamos si no encuentra Pares
        if (filteredIDs.length === 0 && qnaPairs.current.length > 0) {
            setSearchHasNoResults(true);
        }
        // Únicamente apagamos el loading ya esta cargada la info del ws
        if (qnaPairs.current.length > 0) {
            setLoading(false);
        }
    };

    const filterFAQpairsByFile = (fileQuery) => {
        setSearchQuery("");
        // Cambiamos el nombre del documento de personalidad para filtrar
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let filteredIDs = [];

        if (fileQuery === "Mostrar todas las preguntas") {
            // Guardamos todos los pairs id en el Filtered
            qnaPairsCopy.forEach((pair) => filteredIDs.push(pair.id));
        } else {
            // Guardamos los pairs id del source seleccionado
            qnaPairsCopy.filter((pair) => {
                if (pair.source === fileQuery) {
                    filteredIDs.push(pair.id);
                }
            });
        }

        if (filteredIDs.length === 0 && fileQuery !== "Mostrar todas las preguntas") {
            setSearchHasNoResults(true);
        }

        // Guardamos la busqueda de IDs
        filteredPairs.current = fileQuery === "Mostrar todas las preguntas" ? [] : filteredIDs;
        // Calculamos la paginación con el nuevo arreglo y rendereamos la primera pagina.
        let filteredPairsFromQuery = getPairsById(filteredIDs);
        let pages = calculatePagination(filteredPairsFromQuery.length);
        loadFirstPairsPage(filteredPairsFromQuery);
        setPagination({
            ...pagination,
            currentPage: 1,
            pages: pages,
        });
        // Únicamente apagamos el loading ya esta cargada la info del ws
        if (qnaPairs.current.length > 0) {
            setLoading(false);
        }
    };

    const calculatePagination = (pairsArrayLength) => {
        // Dividimos las preguntas entre el número de items por página y redondeamos al número mayor 3.1 -> 4
        return Math.ceil(pairsArrayLength / itemsPerPage);
    };

    const loadFirstPairsPage = (qnaPairs) => {
        // Sacamos la primera pagina del QnA
        let firstPageQnA = qnaPairs.slice(0, itemsPerPage);
        setRenderPairs(firstPageQnA);
    };

    const reRenderPairs = (qnaPairs) => {
        let endSlice = currentPage * itemsPerPage;
        let startSlice = endSlice - itemsPerPage;
        let renderPairs = qnaPairs.slice(startSlice, endSlice);
        // Rendereamos las preguntas en la busqueda
        if (
            filteredPairs.current.length > 0 ||
            searchQuery !== "" ||
            fileQuery !== "Mostrar todas las preguntas"
        ) {
            let filteredPairsFromQuery = getPairsById(filteredPairs.current);
            renderPairs = filteredPairsFromQuery.slice(startSlice, endSlice);
        }
        setRenderPairs(renderPairs);
    };

    const handleChangePage = (targetValue) => {
        let endSlice = targetValue * itemsPerPage;
        let startSlice = endSlice - itemsPerPage;
        let renderPairs = qnaPairs.current.slice(startSlice, endSlice);
        // Cambiamos de página si hay una busqueda
        if (filteredPairs.current.length > 0) {
            let filteredPairsFromQuery = getPairsById(filteredPairs.current);
            renderPairs = filteredPairsFromQuery.slice(startSlice, endSlice);
        }
        setRenderPairs(renderPairs);
        setPagination({
            ...pagination,
            currentPage: targetValue,
        });
    };

    const createPairErrors = (id, qnaErrorsCopy, qnaErrorHelperCopy) => {
        // Errores por default en campos existentes
        let newPairErrors = {
            id: id,
            questions: [false],
            answer: false,
            questionsTable: false,
        };
        let newPairErrorHelper = {
            id: id,
            questions: [errorHelperKeys.removeError],
            answer: errorHelperKeys.removeError,
            questionsTable: errorHelperKeys.removeError,
        };
        // Agregamos el objeto de errores
        qnaErrorsCopy.unshift(newPairErrors);
        qnaErrorHelperCopy.unshift(newPairErrorHelper);

        return { qnaErrorsCopy, qnaErrorHelperCopy };
    };

    const handlePairChange = (targetName, targetValue, idPair, idQuestion, newPair) => {
        // console.log(targetName, targetValue, idPair, idQuestion, newPair);

        // Copias de objetos a ser cambiados
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let pairsToAddCopy = JSON.parse(JSON.stringify(pairsToAdd.current));
        let pairsToUpdateCopy = JSON.parse(JSON.stringify(pairsToUpdate.current));
        let qnaErrorsCopy = JSON.parse(JSON.stringify(qnaErrors.current));
        let qnaErrorHelperCopy = JSON.parse(JSON.stringify(qnaErrorHelper.current));

        // Index de cada pair a ser modificado en cada Objeto
        let { pairIndex, pairErrorIndex, pairErrorHelperIndex } = findPairIndex(idPair);
        // No existe el objeto de errores el elemento que se quiere modificar
        if (pairErrorIndex === -1 && pairErrorHelperIndex === -1) {
            let newPairErrors = createPairErrors(idPair, qnaErrorsCopy, qnaErrorHelperCopy);
            qnaErrorsCopy = newPairErrors.qnaErrorsCopy;
            qnaErrorHelperCopy = newPairErrors.qnaErrorHelperCopy;
            pairErrorIndex = 0;
            pairErrorHelperIndex = 0;
        }

        // Obtenemos el index del par según su objeto
        if (newPair) {
            var newPairIndex = pairsToAddCopy.findIndex((pair) => pair.id === idPair);
        }
        if (!newPair) {
            var pairToUpdateIndex = pairsToUpdateCopy.findIndex((pair) => pair.id === idPair);
            if (pairToUpdateIndex === -1) {
                pairsToUpdateCopy = createPairToUpdate(idPair, pairsToUpdateCopy);
                pairToUpdateIndex = 0;
            }
        }

        switch (targetName) {
            case "questions":
                let questionIndex = qnaPairsCopy[pairIndex].questions.findIndex(
                    (questionObj) => questionObj.id === idQuestion
                );
                // Guardamos el cambio
                qnaPairsCopy[pairIndex][targetName][questionIndex]["question"] = targetValue;
                // Copia del valor al objeto add a ser mandado al WS
                if (newPair) {
                    pairsToAddCopy[newPairIndex][targetName][questionIndex] = targetValue;
                }
                // Copia del valor a Update
                if (!newPair) {
                    let questionToUpdateIndex = pairsToUpdateCopy?.[
                        pairToUpdateIndex
                    ]?.questions.findIndex((questionObj) => questionObj.id === idQuestion);
                    pairsToUpdateCopy[pairToUpdateIndex].questions[questionToUpdateIndex].question =
                        targetValue;
                }
                // Campo vacío
                if (!targetValue) {
                    qnaErrorsCopy[pairErrorIndex][targetName][questionIndex] = true;
                    qnaErrorHelperCopy[pairErrorHelperIndex][targetName][questionIndex] =
                        errorHelperKeys.emptyField;
                    break;
                }
                // Quitamos Errores
                qnaErrorsCopy[pairErrorIndex][targetName][questionIndex] = false;
                qnaErrorHelperCopy[pairErrorHelperIndex][targetName][questionIndex] =
                    errorHelperKeys.removeError;
                break;
            case "answer":
                qnaPairsCopy[pairIndex][targetName] = targetValue; // Guardamos el valor
                // Copia del valor en Add
                if (newPair) {
                    pairsToAddCopy[newPairIndex][targetName] = targetValue;
                }
                // Copia valor en Update
                if (!newPair) {
                    pairsToUpdateCopy[pairToUpdateIndex][targetName] = targetValue;
                }
                // Campo vacío
                if (!targetValue) {
                    qnaErrorsCopy[pairErrorIndex][targetName] = true;
                    qnaErrorHelperCopy[pairErrorHelperIndex][targetName] =
                        errorHelperKeys.emptyField;
                    break;
                }

                //buscamos si hay un fileLink en el fileDataToSend para agregarlo al answer
                let fileDataToSendCopy = [...fileDataToSend];
                let fileIndex = fileDataToSendCopy.findIndex((file) => file.id === idPair);
                if (fileIndex > -1) {
                    //agregamos el targetValue al message del fileDataToSend
                    fileDataToSendCopy[fileIndex].message = targetValue;
                    setFileDataToSend(fileDataToSendCopy);
                }

                // Quitamos errores
                qnaErrorsCopy[pairErrorIndex][targetName] = false;
                qnaErrorHelperCopy[pairErrorHelperIndex][targetName] = errorHelperKeys.removeError;
                break;
            default:
                break;
        }
        // Guardamos los cambios a objetos
        if (newPair) {
            pairsToAdd.current = pairsToAddCopy;
        }
        if (!newPair) {
            pairsToUpdate.current = pairsToUpdateCopy;
        }
        qnaPairs.current = qnaPairsCopy;
        qnaErrors.current = qnaErrorsCopy;
        qnaErrorHelper.current = qnaErrorHelperCopy;

        // Hacemos render de las preguntas
        reRenderPairs(qnaPairsCopy);
        setHasChanges(true);
    };

    const onSave = () => {
        let pairsToAddCopy = JSON.parse(JSON.stringify(pairsToAdd.current.reverse()));
        let pairsToDeleteCopy = JSON.parse(JSON.stringify(pairsToDelete.current));
        let pairsToUpdateCopy = JSON.parse(JSON.stringify(pairsToUpdate.current));

        let knowledgeBaseChanges = [];

        pairsToAddCopy.forEach((pair) =>
            knowledgeBaseChanges.push({
                op: "add",
                value: pair,
            })
        );

        pairsToDeleteCopy.forEach((pairId) =>
            knowledgeBaseChanges.push({
                op: "delete",
                value: {
                    id: pairId,
                },
            })
        );

        pairsToUpdateCopy.forEach((pair) =>
            knowledgeBaseChanges.push({
                op: "replace",
                value: {
                    ...pair,
                    questions: pair.questions.map((questionObj) => questionObj.question),
                },
            })
        );

        modifyAllPairs(knowledgeBaseChanges);
    };

    const sendQnAFilesToBlob = async (updatedqnaPairsFromKB) => {
        let messagesCopy = JSON.parse(JSON.stringify(messagesTemp));

        if (fileDataToDelete.length !== 0) {
            for (let index = 0; index < fileDataToDelete.length; index++) {
                updateContextAttribute(
                    "LoadingMessage",
                    `Eliminando archivo ${index + 1} de ${fileDataToDelete.length}...`
                );
                const data = fileDataToDelete[index];
                let res = await blobStorage(data, "deleteFile");
                let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
                switch (responseCode) {
                    case 0:
                        break;
                    default:
                        updateContextAttribute("alert", {
                            open: true,
                            severity: "error",
                            message: "Hubo un error al eliminar los archivos multimedia",
                        });
                        return;
                }
            }
        }

        if (fileDataToSend.length !== 0) {
            for (let index = 0; index < fileDataToSend.length; index++) {
                updateContextAttribute(
                    "LoadingMessage",
                    `Subiendo archivo ${index + 1} de ${fileDataToSend.length}...`
                );

                let currentPair = updatedqnaPairsFromKB.find((pair) => {
                    // Si el id es mayor a 99999999 es un par nuevo
                    if (fileDataToSend[index].id > 99999999) {
                        //asoaciamos el mensaje con la respuesta del nuevo par para obtener el id actualizado
                        return pair.answer === fileDataToSend[index].message;
                    }
                    return pair.id === fileDataToSend[index].id;
                });
                const data = fileDataToSend[index];
                let res = await blobStorage(data, "uploadFile");
                let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
                switch (responseCode) {
                    case 0:
                        let fileLink = res.data.imageURL;
                        let fileType =
                            data.type.split("/")[0] === "image"
                                ? "image"
                                : data.type.split("/")[0] === "video"
                                ? "video"
                                : "file";
                        //le agregamos el URL faltante al respectivo mensaje
                        let multiMediaMessage = {
                            fileLink,
                            fileName: data.name,
                            fileType: fileType,
                            idPair: currentPair.id,
                            message: currentPair.answer,
                            trigger: currentPair.answer,
                        };

                        messagesCopy.MULTIMEDIA_MESSAGES.push(multiMediaMessage);
                        break;
                    default:
                        updateContextAttribute("alert", {
                            open: true,
                            severity: "error",
                            message: "Hubo un error al subir los archivos multimedia",
                        });
                        return;
                }
            }
        }

        setDropZoneOpenObj({});
        messagesCopy.token = token;
        //Guardamos los mensajes
        let resConfig = await intebotDataActions(
            messagesCopy,
            "updateConfigurationVariables&enviroment=Test",
            "BotConfigurationOperations"
        );
        let responseCode = resConfig?.data?.code ? parseInt(resConfig.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                updateContextAttribute("messagesHasChanges", false);
                updateContextAttribute("messagesHasSavedChanges", true);

                break;
            case 2:
                //El usuario no tiene permisos para realizar esta acción
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;

            default:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "error",
                    message: "Hubo un error al guardar los mensajes",
                });
                break;
        }
        updateContextAttribute("messages", messagesCopy);
        updateContextAttribute("messagesTemp", messagesCopy);
    };

    const trainKB = async () => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Entrenando la base de conocimiento...");
        let res = await actionsQnAmaker(null, "trainKb");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; // si no hay respuesta correcta del serivico, mandamos el codigo a 99 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", // success, error, warning, info
                    message: "La base de conocimientos fue entrenada exitosamente",
                });
                break;
            default:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "error", // success, error, warning, info
                    message: "Hubo un error al entrenar la base, intente más tarde.",
                });
                break;
        }
    };

    const modifyAllPairs = async (knowledgeBaseChanges) => {
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Modificando los pares de la base...");
        let data = {
            knowledgeBaseChanges,
            token,
        };
        let res = await actionsQnAmaker(data, "modifyKb");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; // si no hay respuesta correcta del serivico, mandamos el codigo a 99 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", // success, error, warning, info
                    message: "Se realizaron las modificaciones a la base correctamente",
                });
                updateContextAttribute("loadingDialog", true);
                updateContextAttribute("faqHasSavedChanges", true);

                // Esperamos a que se guarden los cambios en la base
                await sleep(1000);

                let updatedqnaPairsFromKB = await getQnAPairs();

                await sendQnAFilesToBlob(updatedqnaPairsFromKB);
                refreshQnaMaker(true);
                break;
            case 3:
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;
            default:
                updateContextAttribute("alert", {
                    open: true,
                    severity: "error", // success, error, warning, info
                    message: "Hubo un error al modificar la base, intente más tarde.",
                });
                break;
        }
    };

    const addNewPair = () => {
        // Copias sin referencia
        let pairsToAddCopy = JSON.parse(JSON.stringify(pairsToAdd.current));
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let qnaErrorsCopy = JSON.parse(JSON.stringify(qnaErrors.current));
        let qnaErrorHelperCopy = JSON.parse(JSON.stringify(qnaErrorHelper.current));

        // Valores por default de los pares nuevos
        let newPair = {
            id: 99999999 + pairsToAddCopy.length + 1, // Acepta hasta 9 dígitos
            answer: "",
            questions: [
                {
                    id: `${99999999 + pairsToAddCopy.length + 1}${0}`,
                    question: "",
                },
            ],
            source: "Editorial",
            newPair: true,
        };

        // Errores por default
        let newPairErrors = {
            id: 99999999 + pairsToAddCopy.length + 1,
            questions: [true],
            answer: true,
            questionsTable: false,
        };

        let newPairErrorHelper = {
            id: 99999999 + pairsToAddCopy.length + 1,
            questions: [errorHelperKeys.emptyField],
            answer: errorHelperKeys.emptyField,
            questionsTable: errorHelperKeys.removeError,
        };

        // Guardamos el nuevo par
        pairsToAddCopy.unshift(newPair);
        qnaPairsCopy.unshift(newPair);
        pairsToAdd.current = pairsToAddCopy;
        qnaPairs.current = qnaPairsCopy;

        // Guardamos Errores
        qnaErrorsCopy.unshift(newPairErrors);
        qnaErrorHelperCopy.unshift(newPairErrorHelper);
        qnaErrors.current = qnaErrorsCopy;
        qnaErrorHelper.current = qnaErrorHelperCopy;

        if (searchQuery !== "" || fileQuery !== "Mostrar todas las preguntas") {
            setSearchQuery("");
            setFileQuery("Mostrar todas las preguntas");
        }

        let pages = calculatePagination(qnaPairsCopy.length);

        // Hacemos render con la nueva pregunta
        loadFirstPairsPage(qnaPairsCopy);
        setPagination({
            ...pagination,
            currentPage: 1,
            pages: pages,
        });
        setHasChanges(true);
    };

    const findPairIndex = (id) => {
        let pairIndex = qnaPairs.current.findIndex((pair) => pair.id === id);
        let pairErrorIndex = qnaErrors.current.findIndex((pair) => pair.id === id);
        let pairErrorHelperIndex = qnaErrorHelper.current.findIndex((pair) => pair.id === id);
        let pairIndexFiltered = filteredPairs.current.findIndex((pairId) => pairId === id);
        return {
            pairIndex,
            pairErrorIndex,
            pairErrorHelperIndex,
            pairIndexFiltered,
        };
    };

    const createPairToUpdate = (id, pairsToUpdateCopy) => {
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let pairToUpdate = qnaPairsCopy.find((pair) => pair.id === id);
        pairsToUpdateCopy.unshift(pairToUpdate);
        return pairsToUpdateCopy;
    };

    const addNewQuestion = async (id) => {
        // Copia de objetos a modificar
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let qnaErrorsCopy = JSON.parse(JSON.stringify(qnaErrors.current));
        let qnaErrorHelperCopy = JSON.parse(JSON.stringify(qnaErrorHelper.current));
        let { pairIndex, pairErrorIndex, pairErrorHelperIndex } = findPairIndex(id);

        // Buscamos si hay una pregunta vacía al momento de agregar y la eliminamos
        let pairEmptyQuestionId;
        // Iteramos el Arreglo de Errores
        for (const pairErrorObj of qnaErrorsCopy) {
            const { questions } = pairErrorObj;
            // Nos saltamos la pregunta en la que esta siendo llamada la funcion
            if (pairErrorObj.id === id) continue;
            for (const hasQuestionError of questions) {
                // Si hay un error en la pregunta vacia, guardamos el id del par con la question vacia
                if (hasQuestionError) {
                    pairEmptyQuestionId = pairErrorObj.id;
                }
            }
        }
        if (pairEmptyQuestionId) {
            // Obtenemos el index de la pregunta con la question vacia
            let { pairIndex } = findPairIndex(pairEmptyQuestionId);
            const { newPair, questions } = qnaPairsCopy[pairIndex];
            // Obtenemos el ID de la question vacia del arreglo de questions del par
            let emptyQuestionId = questions.find((questionObj) => questionObj.question === "").id;
            let removeEmptyQuestion = true;
            // Eliminamos la question vacia
            await removeQuestion(
                pairEmptyQuestionId,
                emptyQuestionId,
                newPair,
                removeEmptyQuestion
            );
            return; // Evitamos que se ejecute el codigo de agregar otra question hasta que no haya questions vacias en otros pairs
        }

        // Agregamos la nueva pregunta con su ID
        let newQuestionId = `${qnaPairsCopy[pairIndex].id}${
            qnaPairsCopy[pairIndex]["questions"].length + 1
        }`;
        let newQuestionIndex =
            qnaPairsCopy[pairIndex]["questions"].push({
                id: newQuestionId,
                question: "",
            }) - 1;

        // Preguntamos si es nuevo Pair
        let newPair = qnaPairsCopy[pairIndex].newPair;
        // Guardamos la nueva question en el Obj de Add
        if (newPair) {
            let pairsToAddCopy = JSON.parse(JSON.stringify(pairsToAdd.current));
            var newPairIndex = pairsToAddCopy.findIndex((pair) => pair.id === id);
            pairsToAddCopy[newPairIndex].questions.push({
                id: newQuestionId,
                question: "",
            });
            pairsToAdd.current = pairsToAddCopy;
        }
        if (!newPair) {
            // Guardamos la nueva question en el Obj de Update
            let pairsToUpdateCopy = JSON.parse(JSON.stringify(pairsToUpdate.current));
            let pairToUpdateIndex = pairsToUpdateCopy.findIndex((pair) => pair.id === id);
            if (pairToUpdateIndex === -1) {
                pairsToUpdateCopy = createPairToUpdate(id, pairsToUpdateCopy);
                pairToUpdateIndex = 0;
            }
            pairsToUpdateCopy[pairToUpdateIndex].questions.push({
                id: newQuestionId,
                question: "",
            });
            pairsToUpdate.current = pairsToUpdateCopy;
        }

        // Si no existen los errores, los creamos
        if (pairErrorIndex === -1 && pairErrorHelperIndex === -1) {
            let newPairErrors = createPairErrors(id, qnaErrorsCopy, qnaErrorHelperCopy);
            qnaErrorsCopy = newPairErrors.qnaErrorsCopy;
            qnaErrorHelperCopy = newPairErrors.qnaErrorHelperCopy;
            pairErrorIndex = 0;
            pairErrorHelperIndex = 0;
        }
        // Agregamos el error de campo vacío
        qnaErrorsCopy[pairErrorIndex]["questions"][newQuestionIndex] = true;
        qnaErrorHelperCopy[pairErrorHelperIndex]["questions"][newQuestionIndex] =
            errorHelperKeys.emptyField;
        qnaErrorsCopy[pairErrorIndex]["questionsTable"] = false;
        qnaErrorHelperCopy[pairErrorHelperIndex]["questionsTable"] = errorHelperKeys.removeError;
        qnaErrors.current = qnaErrorsCopy;
        qnaErrorHelper.current = qnaErrorHelperCopy;

        // Guardamos cambios en los Objetos
        qnaPairs.current = qnaPairsCopy;
        reRenderPairs(qnaPairsCopy);
        setHasChanges(true);
    };

    const removeQuestion = async (idPair, idQuestion, newPair, removeEmptyQuestion) => {
        // Preguntamos si se desea eliminar
        if (!removeEmptyQuestion) {
            let alert = await Swal.fire({
                text: "¿Deseas eliminar esta pregunta?",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            // Cancelamos el eliminado del Par
            if (alert.isDenied || alert.isDismissed) {
                return;
            }
        }
        // Copias sin referencia
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let qnaErrorsCopy = JSON.parse(JSON.stringify(qnaErrors.current));
        let qnaErrorHelperCopy = JSON.parse(JSON.stringify(qnaErrorHelper.current));

        // Obtenemos el index de la question eliminada
        let { pairIndex, pairErrorIndex, pairErrorHelperIndex } = findPairIndex(idPair);
        let questionIndex = qnaPairsCopy[pairIndex].questions.findIndex(
            (questionObj) => questionObj.id === idQuestion
        );

        // Preguntamos si existe el arreglo de errores de la question eliminada y lo creamos
        if (pairErrorIndex === -1 && pairErrorHelperIndex === -1) {
            let newPairErrors = createPairErrors(idPair, qnaErrorsCopy, qnaErrorHelperCopy);
            qnaErrorsCopy = newPairErrors.qnaErrorsCopy;
            qnaErrorHelperCopy = newPairErrors.qnaErrorHelperCopy;
            pairErrorIndex = 0;
            pairErrorHelperIndex = 0;
        }

        // Quitamos la question del arreglo global del Qna y sus errores
        let removedQuestion = qnaPairsCopy[pairIndex]["questions"].splice(questionIndex, 1)[0];
        qnaErrorsCopy[pairErrorIndex]["questions"].splice(questionIndex, 1);
        qnaErrorHelperCopy[pairErrorHelperIndex]["questions"].splice(questionIndex, 1);

        // Si el arreglo de questions del par esta vacío, indicamos el error
        if (qnaPairsCopy[pairIndex]["questions"].length === 0) {
            qnaErrorsCopy[pairErrorIndex]["questionsTable"] = true;
            qnaErrorHelperCopy[pairErrorHelperIndex]["questionsTable"] =
                errorHelperKeys.emptyQuestions;
        }

        // Eliminamos del arreglo de pares a agregar
        if (newPair) {
            let pairsToAddCopy = JSON.parse(JSON.stringify(pairsToAdd.current));
            let newPairIndex = pairsToAddCopy.findIndex((pair) => pair.id === idPair);
            pairsToAddCopy[newPairIndex]["questions"].splice(questionIndex, 1);
            pairsToAdd.current = pairsToAddCopy;
        }
        // O Eliminamos del arreglo de pares a actualizar
        if (!newPair) {
            let pairsToUpdateCopy = JSON.parse(JSON.stringify(pairsToUpdate.current));
            let pairToUpdateIndex = pairsToUpdateCopy.findIndex((pair) => pair.id === idPair);
            if (pairToUpdateIndex === -1) {
                pairsToUpdateCopy = createPairToUpdate(idPair, pairsToUpdateCopy);
                pairToUpdateIndex = 0;
            }
            let questionToUpdateIndex = pairsToUpdateCopy[pairToUpdateIndex]["questions"].findIndex(
                (question) => question.id === removedQuestion.id
            );
            pairsToUpdateCopy[pairToUpdateIndex]["questions"].splice(questionToUpdateIndex, 1);
            pairsToUpdate.current = pairsToUpdateCopy;
        }

        qnaPairs.current = qnaPairsCopy;
        qnaErrors.current = qnaErrorsCopy;
        qnaErrorHelper.current = qnaErrorHelperCopy;
        reRenderPairs(qnaPairsCopy);
        setHasChanges(true);
    };

    const removePair = async (id, deleteEmptyPair) => {
        // Preguntamos si se desea eliminar
        // console.log('removePair', id);
        if (!deleteEmptyPair) {
            let alert = await Swal.fire({
                text: "¿Deseas eliminar esta FAQ?",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            // Cancelamos el eliminado del Par
            if (alert.isDenied || alert.isDismissed) {
                return;
            }
        }

        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let qnaErrorHelperCopy = JSON.parse(JSON.stringify(qnaErrorHelper.current));
        let qnaErrorsCopy = JSON.parse(JSON.stringify(qnaErrors.current));
        let pairsToAddCopy = JSON.parse(JSON.stringify(pairsToAdd.current));
        let filteredPairsCopy = JSON.parse(JSON.stringify(filteredPairs.current));
        let pairsToUpdateCopy = JSON.parse(JSON.stringify(pairsToUpdate.current));

        let { pairIndex, pairErrorIndex, pairErrorHelperIndex, pairIndexFiltered } =
            findPairIndex(id);

        // Si es nuevo par eliminamos del objeto de agregar del ws
        let newPair = qnaPairsCopy[pairIndex]["newPair"];

        if (newPair) {
            let newPairIndex = pairsToAddCopy.findIndex((pair) => pair.id === id);
            pairsToAddCopy.splice(newPairIndex, 1);
            pairsToAdd.current = pairsToAddCopy;
        }
        // Si no es nuevo entonces lo mandamos al objeto de eliminar del ws
        if (!newPair) {
            pairsToDelete.current = [...pairsToDelete.current, id];
            // Si fue editado y luego eliminado, lo quitamos del objeto de update
            let updatedPairIndex = pairsToUpdateCopy.findIndex((pair) => pair.id === id);
            if (updatedPairIndex > -1) {
                pairsToUpdateCopy.splice(updatedPairIndex, 1);
                pairsToUpdate.current = pairsToUpdateCopy;
            }
        }

        // Si existe el arreglo de errores del question a eliminar los quitamos
        if (pairErrorIndex > -1 && pairErrorHelperIndex > -1) {
            qnaErrorsCopy.splice(pairErrorIndex, 1);
            qnaErrorHelperCopy.splice(pairErrorHelperIndex, 1);
            qnaErrors.current = qnaErrorsCopy;
            qnaErrorHelper.current = qnaErrorHelperCopy;
        }
        // Lo eliminamos de la lista de pares
        qnaPairsCopy.splice(pairIndex, 1);
        qnaPairs.current = qnaPairsCopy;
        let pages = calculatePagination(qnaPairsCopy.length);

        // Si estamos eliminando en un arreglo filtrado, entonces también lo eliminamos de ahí
        if (filteredPairsCopy.length > 0) {
            filteredPairsCopy.splice(pairIndexFiltered, 1);
            filteredPairs.current = filteredPairsCopy;
            if (filteredPairsCopy.length === 0) {
                setSearchHasNoResults(true);
            }
            pages = calculatePagination(filteredPairsCopy.length);
        }

        // Buscamos si la pregunta eliminada era la primera de un archivo y volvemos a calcular las primera pregunta de los archivos
        let firstIdsFromSourcesCopy = JSON.parse(JSON.stringify(firstIdsFromSources.current));
        if (firstIdsFromSourcesCopy.includes(id)) {
            let kbFileSourcesCopy = JSON.parse(JSON.stringify(kbFileSources.current));
            let firstQuestionIdFromEachSource = getFirstPairsFromFiles(
                kbFileSourcesCopy,
                qnaPairsCopy
            );
            firstIdsFromSources.current = firstQuestionIdFromEachSource;
        }

        //si hay un archivo multimedia asociado a la pregunta eliminada, lo eliminamos
        let currentMultiMediaIndex = messagesTemp.MULTIMEDIA_MESSAGES.findIndex(
            (message) => message.idPair === id
        );
        if (currentMultiMediaIndex > -1) {
            let fileDataToDeleteCopy = [...fileDataToDelete];
            let data = {
                name: messagesTemp.MULTIMEDIA_MESSAGES[currentMultiMediaIndex].fileName,
            };
            fileDataToDeleteCopy.push(data);
            setFileDataToDelete(fileDataToDeleteCopy);

            let messagesCopy = JSON.parse(JSON.stringify(messagesTemp));
            messagesCopy.MULTIMEDIA_MESSAGES.splice(currentMultiMediaIndex, 1);
            updateContextAttribute("messagesTemp", messagesCopy);
        }

        setPagination({
            ...pagination,
            pages: pages,
        });

        reRenderPairs(qnaPairsCopy);
        setHasChanges(true);
    };

    // Props
    const FaqPairProps = {
        handlePairChange,
        addNewQuestion,
        removeQuestion,
        removePair,
        currentPage,
        handleImageUploadField,
        dropZoneOpenObj,
        messagesTemp,
        handleAddNewFiles,
        closeImageUploadField,
        handleDeleteFile,
    };

    const findQnaErrorsPair = (id) => {
        let pairErrorIndex = qnaErrors.current.findIndex((pairToFind) => pairToFind.id === id);
        return qnaErrors.current[pairErrorIndex];
    };

    const findQnaErrorHelperPair = (id) => {
        let pairErrorHelperIndex = qnaErrorHelper.current.findIndex(
            (pairToFind) => pairToFind.id === id
        );
        return qnaErrorHelper.current[pairErrorHelperIndex];
    };

    const decideDisableSaveQnA = () => {
        let hasChanges =
            pairsToAdd.current.length > 0 ||
            pairsToDelete.current.length > 0 ||
            pairsToUpdate.current.length > 0 ||
            fileDataToSend.length > 0 ||
            fileDataToDelete.length > 0;

        let questionsHasErrors = qnaErrors.current.some((pairError) =>
            pairError.questions.some((questionError) => questionError)
        );
        let answerHasErrors = qnaErrors.current.some((pairError) => pairError.answer);
        let questionsTableHasErrors = qnaErrors.current.some(
            (pairError) => pairError.questionsTable
        );
        return questionsHasErrors || answerHasErrors || questionsTableHasErrors || !hasChanges;
    };

    const decideDisableAddFAQ = () => {
        let questionsHasErrors = qnaErrors.current.some((pairError) =>
            pairError.questions.some((questionError) => questionError)
        );
        let answerHasErrors = qnaErrors.current.some((pairError) => pairError.answer);
        let questionsTableHasErrors = qnaErrors.current.some(
            (pairError) => pairError.questionsTable
        );
        return !questionsHasErrors &&
            !answerHasErrors &&
            !questionsTableHasErrors &&
            !loading &&
            !errorInFetch
            ? false
            : true;
    };

    return (
        <div>
            <Grid container className="edit-qna-container">
                <Grid item xs={12}>
                    <Grid container>
                        <label className="top-label-field">Filtrar preguntas por archivo</label>
                        <TextField
                            disabled={loading || errorInFetch}
                            select
                            name={"fileQuery"}
                            value={fileQuery}
                            fullWidth
                            variant="outlined"
                            size="small"
                            onChange={(event) => {
                                setFileQuery(event.target.value);
                            }}
                        >
                            {kbFileSources.current.map(
                                (fileObj, index) => (
                                    <MenuItem key={index} value={fileObj.source ?? ""}>
                                        {fileObj.displayName}
                                    </MenuItem>
                                ) // body request list is empty
                            )}
                        </TextField>
                    </Grid>
                    <header className="edit-qna-header">
                        <div className="edit-qna-header-search-container">
                            <SearchBox
                                disabled={(loading && searchQuery === "") || errorInFetch}
                                searchFunction={searchQnA}
                                searchQuery={searchQuery}
                            />
                            <RefreshButton refreshFunction={refreshQnaMaker} />
                        </div>
                        <Button
                            disabled={decideDisableAddFAQ()}
                            onClick={() => addNewPair()}
                            startIcon={<AgregarIcono width="15px" height="15px" />}
                            size="small"
                        >
                            {" "}
                            Agregar FAQ{" "}
                        </Button>
                    </header>
                    <Grid container className="pagination-container">
                        <Pagination
                            siblingCount={4}
                            disabled={pages > 1 ? false : true}
                            count={pages}
                            page={currentPage}
                            onChange={(e, value) => handleChangePage(value)}
                        />
                    </Grid>
                    <main className="edit-qna-main">
                        <header className="pairs-header">
                            <h2>Pregunta</h2>
                            <h2>Respuesta</h2>
                        </header>
                        {/* =========== ERROR COMPONENT ============ */}
                        {errorInFetch && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <span>Hubo un error, intenta más tarde.</span>
                                <RefreshButton refreshFunction={refreshQnaMaker} />
                            </div>
                        )}
                        {/* =========== SEARCH HAS NO RESULTS COMPONENT =========== */}
                        {searchHasNoResults && <SearchNoResults searchQuery={searchQuery} />}
                        {/* =========== MAIN COMPONENT =========== */}
                        {loading ? (
                            <div className="center-loading-icon">
                                <CircularProgress />
                            </div>
                        ) : (
                            renderPairs.map((pair, index) => (
                                <FaqPair
                                    {...pair}
                                    {...FaqPairProps}
                                    key={index}
                                    pairIndex={index}
                                    firstIdsFromSources={firstIdsFromSources.current}
                                    pairErrors={findQnaErrorsPair(pair.id)}
                                    pairErrorHelper={findQnaErrorHelperPair(pair.id)}
                                />
                            ))
                        )}
                    </main>
                </Grid>
                <FooterControls
                    disableSave={decideDisableSaveQnA()}
                    noCancel={true}
                    onSave={() => onSave()}
                    publishQnA={loading ? false : true} // Reutilizar el botón de publicar en el QnA
                    saveButtonName={"Guardar"}
                    showChatBtn={true}
                />
            </Grid>
        </div>
    );
};

export default EditQnAQuestions;
