import React from 'react';
//MUI components
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    text: {
        textAlign: 'end',
        color: theme.palette.secondary.main,
        marginTop: '10px',
    },
}));

export default function MandatoryField() {
    const classes = useStyles();
    return(
        <div className={classes.text}>* Campos obligatorios</div>
    );
}