import React, { useState, useContext } from "react";
//Custom portal components
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//MUI Components
import Button from "../common/CustomButton";
import ButtonConfirmDialog from "../common/ButtonConfirmDialog";
import FloatingSendButton from "./FloatingSendButton";
import IntebotChatModal from "../ChatbotComponents/IntebotChatModal";
import CustomButton from "../common/CustomButton";
import AppContext from "../../Context/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "fixed",
        bottom: "0",
        flex: "0 0 auto",
        width: "100%",
        left: "0",
        zIndex: "99",
        backgroundColor: "white",
        padding: theme.spacing(2),
        margin: "0",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    justifyButtonsFAQ: {
        [theme.breakpoints.down("xs")]: {
            marginLeft: "-10vw",
        },
        justifyContent: "center",
    },
    justifyButtons: {
        justifyContent: "center",
    },
}));

export default function FooterControls(props) {
    const classes = useStyles();
    const { getStorageData } = useContext(AppContext);
    let { botSecret } = getStorageData(["botSecret"]);

    const { disableCancel, noCancel, disableSave, publishQnA, saveButtonName, showChatBtn } = props;
    const [openTestChat, setOpenTestChat] = useState(false);

    return (
        <div className={classes.root}>
            <IntebotChatModal openTestChat={openTestChat} setOpenTestChat={setOpenTestChat} />
            <Grid container alignContent="flex-end">
                <Grid item xs={12}>
                    <Grid
                        container
                        className={
                            saveButtonName ? classes.justifyButtonsFAQ : classes.justifyButtons
                        }
                        spacing={2}
                    >
                        <Grid item>
                            {disableCancel ? (
                                <Button variant="contained" disabled>
                                    Cancelar
                                </Button>
                            ) : noCancel ? null : (
                                <ButtonConfirmDialog
                                    buttonName="Cancelar"
                                    confirmName="Sí"
                                    cancelName="No"
                                    variant="contained"
                                    title="¿Estás seguro que deseas cancelar tus cambios?"
                                    onConfirm={() => props.onCancel()}
                                >
                                    Si confirmas se perderán todos los cambios realizados.
                                </ButtonConfirmDialog>
                            )}
                        </Grid>
                        <Grid item>
                            {disableSave ? (
                                <Button variant="contained" disabled>
                                    {saveButtonName ?? "Guardar"}
                                </Button>
                            ) : (
                                <ButtonConfirmDialog
                                    buttonName={saveButtonName ?? "Guardar"}
                                    confirmName="Sí"
                                    cancelName="No"
                                    variant="contained"
                                    color="primary"
                                    title="¿Estás seguro que deseas guardar tus cambios?"
                                    onConfirm={() => props.onSave()}
                                >
                                    <p style={{ marginTop: "3px" }}>
                                        Esta acción guardará de manera temporal las modificaciones
                                        realizadas.
                                        <br />
                                        Será necesario públicar los cambios para que se apliquen en
                                        InteBot.
                                    </p>
                                </ButtonConfirmDialog>
                            )}
                        </Grid>
                        <Grid item>
                            <CustomButton
                                disabled={botSecret === "Unavailable"}
                                onClick={() => setOpenTestChat(true)}
                                variant="contained"
                            >
                                Probar
                            </CustomButton>
                        </Grid>
                        <Grid item>
                            <FloatingSendButton publishQnA={publishQnA} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
