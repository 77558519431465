import React from "react";
//MUI components
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton as MuiIconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
//Custom portal components
import Button from "./CustomButton";
//Icons
import CloseIcon from "@material-ui/icons/Close";

const IconButton = withStyles((theme) => ({
    root: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(0.3),
        color: theme.palette.grey[500],
        zIndex: 999,
    },
}))(MuiIconButton);

export default class CloseButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { askConfirm, modalTitle, children, cancelLabel, confirmLabel } = this.props;
        return (
            <div>
                {askConfirm ? (
                    <Tooltip title="Salir">
                        <IconButton onClick={() => this.handleOpen()}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Salir">
                        <IconButton onClick={() => this.props.onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Dialog
                    open={this.state.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
                    <DialogContent>{children}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} variant="contained">
                            {cancelLabel ? cancelLabel : "No"}
                        </Button>
                        <Button
                            onClick={() => {
                                this.handleClose();
                                this.props.onClose();
                            }}
                            variant="contained"
                            color="primary"
                            autoFocus
                        >
                            {confirmLabel ? confirmLabel : "Sí"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
