import React, { useEffect, useState, useRef, useContext } from "react";
import { Grid, CircularProgress, TextField, MenuItem } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import RefreshButton from "../common/RefreshButton";
import SearchBox from "../common/SearchBox";
import SearchNoResults from "../common/SearchNoResults";
import FaqPair from "./FAQPair";
import { actionsQnAmaker } from "../DataFunctions";
import AppContext from "../../Context/AppContext";
import FooterControls from "../../components/FooterComponents/FooterControls";

import Swal from "sweetalert2";

const FAQSuggestions = () => {
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [errorInFetch, setErrorInFetch] = useState(false);
    const [pagination, setPagintation] = useState({
        pages: 0,
        currentPage: 1,
        itemsPerPage: 10,
    });
    const [searchHasNoResults, setSearchHasNoResults] = useState(false);
    //Get Pairs
    const qnaPairs = useRef([]); //Todos los pares provenientes de la KB
    const filteredPairs = useRef([]); //Los pares filtrados mediante el buscador
    const [renderPairs, setRenderPairs] = useState([]); //Los pares mostrados en la interfaz
    const [hasChanges, setHasChanges] = useState(false);
    const [suggestionsHasChanges, setSuggestionsHasChanges] = useState(false);
    const context = useContext(AppContext);
    const knowledgeBaseChanges = useRef([]); //Array con los cambios en la KB de produccion

    const suggestionsToReplace = useRef([]); //Array con los ids de las sugerencias a reemplazar

    const [subjectQuery, setSubjectQuery] = useState("Mostrar todas las preguntas");
    const kbSubject = useRef(["Mostrar todas las preguntas"]);
    const { pages, currentPage, itemsPerPage } = pagination;
    const { updateContextAttribute, sleep, readOnly, messagesTemp } = context;

    //Did mount
    useEffect(async () => {
        refreshQnaSuggestions(true);
    }, []);

    //Executada cadavez que el search query cambia
    useEffect(() => {
        setLoading(true);
        setSearchHasNoResults(false);
        //Esperamos a que el usuario termine de escribir
        const awaitUserTyping = setTimeout(() => {
            searchFAQpair();
        }, 250);
        return () => clearTimeout(awaitUserTyping);
    }, [searchQuery]);

    useEffect(() => {
        let filteredPairsFromQuery = filterSuggestionsBySubject(subjectQuery);
        let pages = calculatePagination(filteredPairsFromQuery.length);
        setSearchHasNoResults(false);
        setPagintation({
            ...pagination,
            pages: pages,
        });
        filteredPairs.current = filteredPairsFromQuery.map((pair) => pair.id);
        loadFirstPairsPage(filteredPairsFromQuery);
    }, [subjectQuery]);

    const searchQnA = (searchQuery) => {
        setSearchQuery(searchQuery);
    };

    const clearQnAMakerState = () => {
        //Limpiamos los estados
        setErrorInFetch(false);
        setLoading(false);
        setSearchQuery("");
        setPagintation({
            pages: 0,
            currentPage: 1,
            itemsPerPage: 10,
        });
        qnaPairs.current = [];
        filteredPairs.current = [];
        setRenderPairs([]);
        setHasChanges(false);
        setSuggestionsHasChanges(false);
        knowledgeBaseChanges.current = [];
        suggestionsToReplace.current = [];
        kbSubject.current = ["Mostrar todas las preguntas"];
        setSubjectQuery("Mostrar todas las preguntas");
        //Errors Catalogs
        qnaErrors.current = [];
        qnaErrorHelper.current = [];
    };

    //Errors Catalogs
    const qnaErrors = useRef([]);
    const qnaErrorHelper = useRef([]);

    const searchFAQpair = () => {
        let filteredIDs = [];
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        //Iteramos todos los pares
        qnaPairsCopy.forEach((pair) => {
            const { answer, questions } = pair;
            //Buscamos primero si hay preguntas con la busqueda
            let filteredQuestions = questions.filter((questionObj) =>
                questionObj.question.toLowerCase().includes(searchQuery.toLowerCase().trimEnd())
            );
            //Si encuentra preguntas con el query, sustituimos las preguntas encontradas en el par y regresamos el par completo
            if (filteredQuestions.length > 0) {
                //pair.questions = filteredQuestions
                return filteredIDs.push(pair.id);
            }
            //si no encuentra preguntas, buscamos entonces en la respuesta, si encuentra una respuesta, regresamos todo el par con todas las preguntas
            if (answer.toLowerCase().includes(searchQuery.toLowerCase())) {
                return filteredIDs.push(pair.id);
            }
        });
        //guardamos la busqueda de IDs
        filteredPairs.current = filteredIDs;
        //Calculamos la paginación con el nuevo arreglo y rendereamos la primera pagina.
        let filteredPairsFromQuery = getPairsById(filteredIDs);
        let pages = calculatePagination(filteredPairsFromQuery.length);
        loadFirstPairsPage(filteredPairsFromQuery);
        setPagintation({
            ...pagination,
            currentPage: 1,
            pages: pages,
        });
        //Indicamos si no encuentra Pares
        if (filteredIDs.length === 0 && qnaPairs.current.length > 0) {
            setSearchHasNoResults(true);
        }
        //únicamante apagamos el loading ya esta cargada la info del ws
        if (qnaPairs.current.length > 0) {
            setLoading(false);
        }
    };

    const loadFirstPairsPage = (qnaPairs) => {
        //Sacamos la primera pagina del QnA
        let firstPageQnA = qnaPairs.slice(0, itemsPerPage);
        setRenderPairs(firstPageQnA);
    };

    const getFAQSuggestion = async () => {
        setLoading(true);
        let res = await actionsQnAmaker(null, "getSuggestionKB");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        setLoading(false);
        switch (responseCode) {
            case 0:
                //Success
                let pairs = res.data.data;
                qnaPairs.current = pairs;
                pairs.forEach((pair) => {
                    if (!pair.subject) {
                        pair.subject = "Base de conocimientos";
                    }
                    if (!kbSubject.current.includes(pair.subject)) {
                        kbSubject.current.push(pair.subject);
                    }
                });
                let pages = calculatePagination(pairs.length);
                setPagintation({
                    ...pagination,
                    pages,
                });
                setRenderPairs(pairs.slice(0, itemsPerPage));
                setErrorInFetch(false);
                break;

            default:
                //Error
                setErrorInFetch(true);

                break;
        }
    };

    const refreshQnaSuggestions = async (refreshNoConfirm) => {
        if (!refreshNoConfirm && !errorInFetch && hasChanges && suggestionsHasChanges) {
            let alert = await Swal.fire({
                title: "¿Deseas actualizar la lista de preguntas?",
                text: "Se perderán las modificaciones no guardadas",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            //Cancelamos el eliminado del Par
            if (alert.isDenied || alert.isDismissed) {
                return;
            }
        }
        clearQnAMakerState();
        setLoading(true);
        await sleep(1000); //Esperamos a que el servicio actualize la KB
        await getFAQSuggestion();
    };

    const calculatePagination = (pairsArrayLength) => {
        //Dividimos las preguntas entre el numero de items por pagina y redondeamos al número mayor 3.1 -> 4
        return Math.ceil(pairsArrayLength / itemsPerPage);
    };

    const getPairsById = (pairsIds) => {
        let foundPairs = [];
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        pairsIds.forEach((id) => {
            let pair = qnaPairsCopy.find((pair) => pair.id === id);
            foundPairs.push(pair);
        });
        return foundPairs;
    };

    const handleChangePage = (targetValue) => {
        let endSlice = targetValue * itemsPerPage;
        let startSlice = endSlice - itemsPerPage;
        let renderPairs = qnaPairs.current.slice(startSlice, endSlice);
        //Cambiamos de página si hay una busqueda
        if (filteredPairs.current.length > 0) {
            let filteredPairsFromQuery = getPairsById(filteredPairs.current);
            renderPairs = filteredPairsFromQuery.slice(startSlice, endSlice);
        }
        setRenderPairs(renderPairs);
        setPagintation({
            ...pagination,
            currentPage: targetValue,
        });
    };

    const findQnaErrorsPair = (id) => {
        let pairErrorIndex = qnaErrors.current.findIndex((pairToFind) => pairToFind.id === id);
        return qnaErrors.current[pairErrorIndex];
    };

    const findQnaErrorHelperPair = (id) => {
        let pairErrorHelperIndex = qnaErrorHelper.current.findIndex(
            (pairToFind) => pairToFind.id === id
        );
        return qnaErrorHelper.current[pairErrorHelperIndex];
    };

    const handleSuggetionChange = (name, value, id, suggestionId) => {
        //console.log("name, value, id, suggestionId :>> ", name, value, id, suggestionId);
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let currentPair = qnaPairsCopy.find((pair) => pair.id === id);
        let currentPairIndex = qnaPairsCopy.findIndex((pair) => pair.id === id);
        switch (name) {
            case "suggestion":
                let suggestionIndex = currentPair["suggestion"].findIndex(
                    (suggestion) => suggestion.id === suggestionId
                );
                currentPair["suggestion"][suggestionIndex].question = value;
                break;
            case "answer":
                currentPair.answer = value;
                break;
            default:
                break;
        }
        qnaPairsCopy[currentPairIndex] = currentPair;
        qnaPairs.current = qnaPairsCopy;
        reRenderPairs(qnaPairsCopy);
        setSuggestionsHasChanges(true);
        setHasChanges(true);
    };

    const reRenderPairs = (qnaPairs) => {
        let endSlice = currentPage * itemsPerPage;
        let startSlice = endSlice - itemsPerPage;
        let renderPairs = qnaPairs.slice(startSlice, endSlice);
        //rendereamos las preguntas en la busqueda
        if (
            filteredPairs.current.length > 0 ||
            searchQuery !== "" ||
            subjectQuery !== "Mostrar todas las preguntas"
        ) {
            let filteredPairsFromQuery = getPairsById(filteredPairs.current);
            renderPairs = filteredPairsFromQuery.slice(startSlice, endSlice);
        }
        setRenderPairs(renderPairs);
    };

    const acceptSuggestion = async (pairId) => {
        //Preguntamos si desea aceptar la sugerencia
        let alert = await Swal.fire({
            title: "¿Deseas aceptar la sugerencia?",
            text: "Debes guardar para que los cambios se vean reflejados",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del Par
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        let knowledgeBaseChangesCopy = JSON.parse(JSON.stringify(knowledgeBaseChanges.current));
        //Obtenemos le objeto del suggestion
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let currentPair = qnaPairsCopy.find((pair) => pair.id === pairId);
        //concat suggestions to questions
        currentPair.questions = [...currentPair.questions, ...currentPair.suggestion];
        //clear suggestions
        currentPair.suggestion = [];
        //si hay un filtro de busqueda, eliminamos el par de la lista de pares filtrados
        if (filteredPairs.current.length > 0) {
            let filteredPairsFromQuery = getPairsById(filteredPairs.current);
            let filteredPairIndex = filteredPairsFromQuery.findIndex((pair) => pair.id === pairId);
            filteredPairsFromQuery.splice(filteredPairIndex, 1);
            filteredPairs.current = filteredPairsFromQuery.map((pair) => pair.id);
            //si la lista de pares filtrados se queda vacia, indicamos que no hay resultados
            if (filteredPairsFromQuery.length === 0) {
                setSearchHasNoResults(true);
            }
        }
        //Eliminamos el par de la lista de pares
        let pairIndex = qnaPairsCopy.findIndex((pair) => pair.id === pairId);
        qnaPairsCopy.splice(pairIndex, 1);
        //agregamos los cambios a la KB
        knowledgeBaseChangesCopy.push(currentPair);
        knowledgeBaseChanges.current = knowledgeBaseChangesCopy;
        //actualizamos el array de pares
        qnaPairs.current = qnaPairsCopy;

        reRenderPairs(qnaPairsCopy);
        setHasChanges(true);
    };

    const onSave = async () => {
        let hasChanges = knowledgeBaseChanges.current.length > 0;
        let resCodeArray = [];
        //Guardamos los cambios en la KB de produccion
        if (hasChanges) {
            let resProduction = await modifyKb();
            resCodeArray.push(resProduction);
        }
        //Guardamos los cambios en la KB de sugerencias
        if (suggestionsHasChanges) {
            let resSuggestions = await modifySuggestionKB();
            resCodeArray.push(resSuggestions);
        }

        //Si hay errores en alguno de los dos servicios, no limpiamos el estado
        let responseHasError = resCodeArray.some((code) => code !== 0);
        if (responseHasError) {
            updateContextAttribute("alert", {
                open: true,
                severity: "error", //success, error, warning, info
                message: "Hubo un error, intenta más tarde",
            });
            return;
        }
        //Limpiamos los estados
        refreshQnaSuggestions(true);
        updateContextAttribute("faqHasSavedChanges", true);
        //Actualizamos el estado
        updateContextAttribute("alert", {
            open: true,
            severity: "success", //success, error, warning, info
            message: "Se realizaron las modificaciones a la base correctamente",
        });
    };

    const modifySuggestionKB = async () => {
        let data = {
            knowledgeBaseChanges: qnaPairs.current,
        };
        //console.log("data IN modifySuggestionKB:>> ", JSON.stringify(data, null, 2));
        updateContextAttribute("loadingDialog", true);
        //Guardamos los cambios en la KB
        let res = await actionsQnAmaker(data, "deleteSuggestKB");
        updateContextAttribute("loadingDialog", false);
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                //Actualizamos el estado
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "Se realizaron las modificaciones a la base correctamente",
                });
                break;

            default:
                //Error
                Swal.fire({
                    title: "Hubo un error, intenta más tarde",
                    icon: "error",
                    confirmButtonColor: "#27315d",
                });
                break;
        }

        return responseCode;
    };

    const modifyKb = async () => {
        let data = {
            knowledgeBaseChanges: knowledgeBaseChanges.current,
        };
        //stringlyfy data
        //console.log("data in modifyKb :>> ", JSON.stringify(data, null, 2));
        updateContextAttribute("loadingDialog", true);
        //Guardamos los cambios en la KB
        let res = await actionsQnAmaker(data, "modifySuggestion");
        updateContextAttribute("loadingDialog", false);
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                //Actualizamos el estado
                updateContextAttribute("alert", {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: "Se realizaron las modificaciones a la base correctamente",
                });
                break;

            default:
                //Error
                Swal.fire({
                    title: "Hubo un error, intenta más tarde",
                    icon: "error",
                    confirmButtonColor: "#27315d",
                });
                break;
        }
        return responseCode;
    };

    const removeSuggestion = async (pairId, suggestionId) => {
        //Preguntamos si desea eliminar la sugerencia
        let alert = await Swal.fire({
            title: "¿Deseas eliminar la sugerencia?",
            text: "Se perderá la sugerencia",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del Par
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        //eliminamos la sugerencia que fue clickeada por el usuario
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let currentPair = qnaPairsCopy.find((pair) => pair.id === pairId);
        let suggestionIndex = currentPair["suggestion"].findIndex(
            (suggestion) => suggestion.id === suggestionId
        );
        currentPair["suggestion"].splice(suggestionIndex, 1);
        let pairIndex = qnaPairsCopy.findIndex((pair) => pair.id === pairId);
        if (currentPair["suggestion"].length === 0) {
            //si se queda sin sugerencias, lo eliminamos de la lista de pares
            qnaPairsCopy.splice(pairIndex, 1);
        } else {
            //actualizamos el array de pares
            qnaPairsCopy[pairIndex] = currentPair;
        }

        //si hay un filtro de busqueda, actualizamos el array de pares filtrados
        if (filteredPairs.current.length > 0) {
            let filteredPairsFromQuery = getPairsById(filteredPairs.current);
            let filteredPairIndex = filteredPairsFromQuery.findIndex((pair) => pair.id === pairId);
            filteredPairsFromQuery[filteredPairIndex] = currentPair;
            filteredPairs.current = filteredPairsFromQuery.map((pair) => pair.id);
        }
        setSuggestionsHasChanges(true);
        qnaPairs.current = qnaPairsCopy;
        reRenderPairs(qnaPairsCopy);
        setHasChanges(true);
    };

    const removeSuggestionPair = async (pairId) => {
        //Preguntamos si desea eliminar la sugerencia
        let alert = await Swal.fire({
            title: "¿Deseas eliminar la sugerencia?",
            text: "Se perderá la sugerencia",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del Par
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        //eliminamos la sugerencia que fue clickeada por el usuario
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let pairIndex = qnaPairsCopy.findIndex((pair) => pair.id === pairId);
        //si hay un filtro de busqueda, eliminamos el par de la lista de pares filtrados
        if (filteredPairs.current.length > 0) {
            let filteredPairsFromQuery = getPairsById(filteredPairs.current);
            let filteredPairIndex = filteredPairsFromQuery.findIndex((pair) => pair.id === pairId);
            filteredPairsFromQuery.splice(filteredPairIndex, 1);
            filteredPairs.current = filteredPairsFromQuery.map((pair) => pair.id);
            //si la lista de pares filtrados se queda vacia, indicamos que no hay resultados
            if (filteredPairsFromQuery.length === 0) {
                setSearchHasNoResults(true);
            }
        }
        //actualizamos el array de pares
        qnaPairsCopy.splice(pairIndex, 1);
        qnaPairs.current = qnaPairsCopy;
        reRenderPairs(qnaPairsCopy);
        setSuggestionsHasChanges(true);
    };

    const filterSuggestionsBySubject = (subject) => {
        if (subject === "Mostrar todas las preguntas") {
            return qnaPairs.current;
        }
        let qnaPairsCopy = JSON.parse(JSON.stringify(qnaPairs.current));
        let filteredSuggestions = qnaPairsCopy.filter((pair) => pair.subject === subject);
        return filteredSuggestions;
    };

    const FaqPairProps = {
        handleSuggetionChange,
        removeSuggestion,
        acceptSuggestion,
        messagesTemp,
        dropZoneOpenObj: {},
    };

    return (
        <div>
            <Grid Grid container className="edit-qna-container">
                <Grid item xs={12}>
                    <h1>Sugerencias FAQ</h1>
                    <TextField
                        disabled={loading || errorInFetch}
                        select
                        name={"subjectQuery"}
                        value={subjectQuery}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(event) => {
                            setSubjectQuery(event.target.value);
                        }}
                        //helperText={error ? errorHelper[name] : ''}
                    >
                        {kbSubject.current.map(
                            (fileObj, index) => (
                                <MenuItem key={index} value={fileObj ?? ""}>
                                    {fileObj}
                                </MenuItem>
                            ) //body request list is empty
                        )}
                    </TextField>
                    <header className="edit-suggestions-header">
                        <SearchBox
                            fullWidth
                            disabled={(loading && searchQuery === "") || errorInFetch}
                            searchFunction={searchQnA}
                            searchQuery={searchQuery}
                        />
                        <RefreshButton refreshFunction={refreshQnaSuggestions} />
                    </header>
                    <Grid container className="pagination-container">
                        <Pagination
                            siblingCount={4}
                            disabled={pages > 1 ? false : true}
                            count={pages}
                            page={currentPage}
                            onChange={(e, value) => handleChangePage(value)}
                        />
                    </Grid>
                    <main className="edit-qna-main">
                        <header className="pairs-header">
                            <h2>Pregunta</h2>
                            <h2>Respuesta</h2>
                        </header>
                        {/* =========== ERROR COMPONENT ============ */}
                        {errorInFetch && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <span>Hubo un error, intenta más tarde.</span>
                                <RefreshButton refreshFunction={refreshQnaSuggestions} />
                            </div>
                        )}

                        {/* =========== SEARCH HAS NO RESULTS COMPONENT =========== */}
                        {searchHasNoResults && (
                            <SearchNoResults searchQuery={searchQuery || subjectQuery} />
                        )}
                        {/* =========== MAIN COMPONENT =========== */}
                        {loading ? (
                            <div className="center-loading-icon">
                                <CircularProgress />
                            </div>
                        ) : (
                            renderPairs.length !== 0 &&
                            renderPairs.map((pair, index) => (
                                <FaqPair
                                    {...pair}
                                    {...FaqPairProps}
                                    key={index}
                                    pairIndex={index}
                                    pairErrors={findQnaErrorsPair(pair.id)}
                                    pairErrorHelper={findQnaErrorHelperPair(pair.id)}
                                    pairIsSuggestions={true}
                                    handlePairChange={handleSuggetionChange}
                                    removePair={removeSuggestionPair}
                                />
                            ))
                        )}
                        {renderPairs.length === 0 &&
                            !loading &&
                            !errorInFetch &&
                            !searchHasNoResults && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <span>No hay sugerencias disponibles.</span>
                                </div>
                            )}
                    </main>
                </Grid>
                <FooterControls
                    disableSave={(!hasChanges && !suggestionsHasChanges) || readOnly}
                    noCancel={true}
                    onSave={() => onSave()}
                    publishQnA={loading ? false : true} //Reutilizar el boton de publicar en el QnA
                    saveButtonName={"Guardar"}
                    showChatBtn={true}
                />
            </Grid>
        </div>
    );
};

export default FAQSuggestions;
