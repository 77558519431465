import React from 'react'

//MUI components
import { IconButton, Tooltip } from '@material-ui/core';

//Icons 
import RefreshIcon from '@material-ui/icons/Refresh';

export default function RefreshButton(props) {

    const { refreshFunction } = props;
    return (
        <React.Fragment>
            <Tooltip title={`Actualizar la lista.`}>
                <IconButton onClick={() => refreshFunction()}>
                    <RefreshIcon color="primary" width="20px" height="20px"/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    )
}
