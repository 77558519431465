import React from "react";

//Custom portal components
import Editqnaquestions from "../components/QnAMakerComponents/editQnAQuestions";
import Faqsettings from "../components/QnAMakerComponents/FAQsettings";
import Faqtestquestions from "../components/QnAMakerComponents/FAQTestQuestions";
import FAQSuggestions from "../components/QnAMakerComponents/FAQSuggestions";

import AppContext from "../Context/AppContext";
// import { actionsQnAmaker } from '../components/DataFunctions';

//MUI Components
import { Box, Grid, Tabs, Tab } from "@material-ui/core/";

//=========Styles=================
import "./StyledPages/FAQpage.css";

function TabPanel(props) {
    const { children, value, index } = props;
    return <div role="tabpanel">{value === index && <Box>{children}</Box>}</div>;
}

// =====================FAQ page React Component =============================

export default class FAQpage extends React.Component {
    state = {
        currentTab: 0,
        loading: false,
        mounted: false,
    };

    changeQnAtab = (event, tabIndex) => {
        // console.log('tabIndex :>> ', tabIndex);
        // console.log('event.target.value :>> ', event);
        this.setState({
            currentTab: tabIndex,
        });
    };

    //FAQ sections Catalog

    FAQsections = [
        { label: "Editar Preguntas" },
        { label: "Ajustes" },
        { label: "Probar FAQ" },
        { label: "Sugerencias FAQ" },
    ];

    FAQcomponents = [
        {
            component: (
                <Editqnaquestions
                //props
                />
            ),
        },
        {
            component: (
                <Faqsettings
                //props
                />
            ),
        },
        {
            component: (
                <Faqtestquestions
                //props
                />
            ),
        },
        {
            component: (
                <FAQSuggestions
                //props
                />
            ),
        },
    ];

    componentDidMount() {
        this.setState({ ...this.state, mounted: true });
    }

    componentWillUnmount() {
        this.setState({ ...this.state, mounted: false });
    }

    render() {
        const { FAQcomponents, FAQsections, changeQnAtab } = this;
        const { currentTab, mounted } = this.state;
        return (
            <React.Fragment>
                {
                    <Grid container>
                        {/* =================Pestañas del FAQ=============================== */}
                        <Grid item xs={12}>
                            <Tabs
                                value={currentTab}
                                onChange={changeQnAtab}
                                className="Tabs"
                                variant="fullWidth"
                            >
                                {FAQsections.map((item, index) => (
                                    <Tab key={index} label={item.label} className="FAQsection" />
                                ))}
                            </Tabs>
                            {/* =============Contenido de las Pestañas del FAQ======================= */}
                            <Grid item xs={12}>
                                {FAQcomponents.map((item, index) => (
                                    <TabPanel value={currentTab} index={index} key={index}>
                                        <Grid direction="column" container alignItems="center">
                                            {item.component}
                                        </Grid>
                                    </TabPanel>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

FAQpage.contextType = AppContext;
