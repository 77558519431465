import React from 'react'
import IntentExample from './Modals/IntentExample'

//MUI components
import {
    Tooltip,
} from "@material-ui/core";

export default function TestQuery(props) {

    const { queryHistroy, entityList } = props;

    const findEntityInfo = (entityName) => {
        for (let index = 0; index < entityList.length; index++) {
            const entity = entityList[index];
            if(entity.name === entityName){
                return entity
            }
            if(!entity.hasOwnProperty('children')) continue;
            for (let index = 0; index < entity.children.length; index++) {
                const child = entity.children[index];
                if(child.name === entityName){
                    return child
                }
                for (let index = 0; index < child.children.length; index++) {
                    const child2 = child.children[index];
                    if(child2.name === entityName){
                        return child2
                    }
                    for (let index = 0; index < child2.children.length; index++) {
                        const child3 = child2.children[index];
                        if(child3.name === entityName){
                            return child3
                        }
                        for (let index = 0; index < child3.children.length; index++) {
                            const child4 = child3.children[index];
                            if(child4.name === entityName){
                                return child4
                            }
                        }
                    }
                }
            }
            
        }
        return {}
    }

    return (
    <div>
        <div>
            <main className='test-query-table'>
                {
                    queryHistroy.map((exampleObj, index) => 
                    <div>
                        <div className='intent-example-main'>
                            <div className='test-intent-result'>
                                <label className='top-label-field' >
                                    Intención de mayor puntuación: <strong>{exampleObj.intent ?? "No se encontró un resultado"}</strong>
                                </label>
                            </div>
                            <Tooltip title="Puntuación del ejemplo">
                                <u>
                                    {
                                        //round the score to 3 decimals
                                        Math.round((exampleObj.score + Number.EPSILON) * 1000) / 1000 <= 0 ? "N/A" : Math.round((exampleObj.score + Number.EPSILON) * 1000) / 1000
                                    }    
                                </u>
                            </Tooltip>
                        </div>
                            <IntentExample
                                findEntityInfo={findEntityInfo}
                                createIntentErrorHelper={[]}
                                exampleList={queryHistroy}
                                exampleObj={exampleObj}
                                exampleIndex={index}
                                noSelection={true}
                                entityList={entityList}
                            />
                    </div>
                    )
                }
            </main>
            
        </div>
    </div>
    )
}
