import React, { useContext } from "react";

//MUI components
import {
    TextField,
    IconButton,
    Tooltip,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";

//Icons
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

//CSS sheets
import "./Styles/TemplateContent.css";

//Custom Portal components
import { blobStorage, whatsAppCampaignActions } from "../DataFunctions";
import Swal from "sweetalert2";
import AppContext from "../../Context/AppContext";
import MultiMediaComponent from "./MultiMediaComponent";

const infoAboutStaus = [
    { status: "APPROVED", detail: "Esta plantilla esta lista para ser utilizada." },
    {
        status: "REJECTED",
        detail: "La plantilla fue rechazada por WhatsApp, revisa el contenido de la plantilla y vuelva a mandar a revisión.",
    },
    {
        status: "PENDING",
        detail: "La plantilla esta siendo revisada por el provedor de mensajes, espere unas horas y revisa de nuevo.",
    },
];

export default function TemplateContent(props) {
    const { updateContextAttribute } = useContext(AppContext);

    const removeTemplate = async () => {
        handleDialogAction(true, "Espera un momento..", "loadingModal");

        if (templateType === "multimedia" && isBlobStorage) {
            let fileName = multimediaUrl.replace(/\?.+/, "").split("/").pop();
            let data = {
                name: fileName,
            };
            let res = await blobStorage(data, "deleteFile&uploadType=campaigns");
            let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;
            switch (responseCode) {
                case 0:
                    //Continuar con la eliminación del template
                    break;
                default:
                    //Waring al usuario que no se pudo eliminar el archivo multimedia
                    let alert = {
                        open: true,
                        severity: "error", //success, error, warning, info
                        message: `El archivo "${fileName}" no se pudo eliminar del contenedor de multimedia.`,
                    };
                    updateContextAttribute("alert", alert);
                    break;
            }
        }
        let data = {
            templateName: templateName,
        };
        let res = await whatsAppCampaignActions(data, "deleteTemplate");
        handleDialogAction(false);

        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99;

        switch (responseCode) {
            case 11:
                Swal.fire({
                    icon: "success",
                    title: "Template eliminado",
                    text: "El template fue eliminado con exito.",
                    confirmButtonColor: "#44AB3F",
                });
                refreshTemplateList();
                break;

            default:
                Swal.fire({
                    icon: "error",
                    title: "Error al eliminar template",
                    text: "El template no se pudo mandar al proceso de eliminación. Intentalo mas tarde.",
                    confirmButtonColor: "#E42922",
                });
                break;
        }
    };

    const {
        handleSelectTemplate,
        selectedTemplate,
        handleDialogAction,
        refreshTemplateList,
        template,
        multiMediaURLArray,
        index,
    } = props;

    const {
        templateName,
        templateContent,
        templateStatus,
        multimediaUrl,
        templateType,
        isBlobStorage,
        templateButtons,
    } = template;
    let statusColor, templateStatusDetail, templateCurrentStatus;
    switch (templateStatus) {
        case "APPROVED":
            templateCurrentStatus = infoAboutStaus.find((info) => info.status === "APPROVED");
            templateStatusDetail = templateCurrentStatus.detail;
            statusColor = "#44AB3F"; //Verde
            break;
        case "REJECTED":
            templateCurrentStatus = infoAboutStaus.find((info) => info.status === "REJECTED");
            templateStatusDetail = templateCurrentStatus.detail;
            statusColor = "#E42922"; //Rojo
            break;
        case "PENDING":
        case "PENDING_DELETION":
        case "NEW":
            templateCurrentStatus = infoAboutStaus.find((info) => info.status === "PENDING");
            templateStatusDetail = templateCurrentStatus.detail;
            statusColor = "#e48922"; //Naranja
            break;
        default:
            break;
    }

    return (
        <div>
            <div className="template-header">
                <h3>{templateName}</h3>
                <Tooltip title={templateStatusDetail}>
                    <FiberManualRecordIcon
                        style={{
                            color: statusColor,
                        }}
                    />
                </Tooltip>
            </div>
            <div className="template-item">
                <RadioGroup
                    row
                    value={selectedTemplate}
                    onChange={(e) => handleSelectTemplate(e.target.value)}
                >
                    <FormControlLabel
                        value={templateName}
                        disabled={templateStatus === "APPROVED" ? false : true}
                        control={<Radio color="primary" />}
                    />
                </RadioGroup>
                <div className="multimedia-template-container gray-input">
                    {templateType === "multimedia" && (
                        <MultiMediaComponent
                            multiMediaURLArray={multiMediaURLArray}
                            template={template}
                            index={index}
                        />
                    )}
                    <TextField
                        className="gray-input"
                        disabled
                        value={templateContent}
                        size="small"
                        fullWidth
                        multiline
                        variant="outlined"
                    />
                    {templateButtons.length > 0 && (
                        <div className="template-buttons-container">
                            <label className="top-label-field">Botones</label>
                            <Tooltip
                                title={`
                                Los botones son opciones que se le presentan al usuario para que pueda interactuar con el mensaje.
                            `}
                            >
                                <HelpOutlineIcon color="primary" fontSize="small" />
                            </Tooltip>
                            {templateButtons.map((button, index) => {
                                return (
                                    <div key={index} className="template-button">
                                        <TextField
                                            className="gray-input"
                                            disabled
                                            value={button.text}
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div className="template-action-buttons-container">
                    <IconButton
                        onClick={() => {
                            handleDialogAction(
                                true,
                                "¿Deseas eliminar este template?",
                                "askConfirmation",
                                removeTemplate
                            );
                        }}
                    >
                        <TrashCan width="20px" height="20px" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}
