import React, { useState } from 'react';

//MUI components
import {
    RadioGroup,
    FormControlLabel,
    Radio,
    Chip
} from '@material-ui/core/';
import ChipInput from 'material-ui-chip-input';


const IntentsTableComponent = (props) => {
    const { intentName, examples, features, selectedIntent, setSelectedIntent } = props;

    return (
        <div className='intent-table-component' >
            <RadioGroup value={selectedIntent} onChange={(e) => setSelectedIntent(e.target.value)}>
                <FormControlLabel value={intentName} control={<Radio color="primary"/>} />
            </RadioGroup>
            {/* OPEN MODAL BUTTON FOR EDIT INTENT */}
            <h3 className='name-header'>{intentName}</h3>
            <h3 className='examples-header'>{examples}</h3>
            <ChipInput
                readOnly
                className='features-header'
                name="intentFeatures"
                value={
                    features?.map((feature) => 
                        feature.featureName || feature.modelName
                    )}
                disableUnderline
                size="small"
                chipRenderer={({value})=>(<Chip style={{margin: "3px"}} label={value}/>)}
                InputProps={{style: {display: "none"}}}
            />
        </div>
    );
}

export default IntentsTableComponent;
