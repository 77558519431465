import React from 'react';

import ListRowEntityType from './ListRowEntityType';
import { Button, Grid } from '@material-ui/core';
import AgregarIcono from '../../../Icons/AgregarIcono';

import '../styles/ListEntityTable.css'

const ListEntityType = (props) => {
    const { tempEntity, handleListEntityForm, entityErrors, entityErrorHelper, deleteListItem, addListItem } = props;

    return (
        <React.Fragment>
            <header className='list-entity-header'>
                <h3 className='list-entity-key-header'>Clave</h3>
                <h3 className='list-entity-values-header'>Valores</h3>
            </header>
            <main className='list-entity-table'>
                {
                    tempEntity.subLists.map((list, index) => 
                        <ListRowEntityType
                            {...list}
                            index={index}
                            handleListEntityForm={handleListEntityForm}
                            entityErrors={entityErrors}
                            entityErrorHelper={entityErrorHelper}
                            deleteListItem={deleteListItem}
                            addListItem={addListItem}
                        />
                    )
                }
             <Grid container justifyContent="flex-end" >
                <Button onClick={() => addListItem()} startIcon={<AgregarIcono width="15px" height="15px" />} size="small" > Agregar valor a la lista </Button>
            </Grid>
            </main>
        </React.Fragment>
    );
}

export default ListEntityType;
