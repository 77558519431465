import React from "react";

export default function Dashboard(props) {
    const { powerBIReport } = props;
    return (
        <iframe
            className="frame"
            title="Dashboard InteBot"
            src={powerBIReport}
            frameBorder="0"
            allowFullScreen={true}
            height="100%"
            width="100%"
        />
    );
}
