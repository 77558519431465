import React from 'react';
//MUI components
import {
    TextField,
    Chip,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const FeaturesAutoComplete = (props) => {


    const { features, featureList, handleIntentForm } = props

    return (
        <div>
            <Autocomplete
                multiple
                name="featuresList"
                id="tags-filled"
                filterSelectedOptions
                disableClearable
                value={
                    features?.map((feature) => 
                        feature.featureName || feature.modelName
                    )}
                onChange={(event, value, reason) => handleIntentForm("featuresList", value, reason)}
                options={featureList.map((option) => option.name)}
                renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
                }
                renderInput={(params) => (
                    <TextField {...params} variant='outlined' placeholder="Añade opción" />
                )}
                
            />
        </div>
    );
}

export default FeaturesAutoComplete;
