import React from "react";
import { Grid } from "@material-ui/core";

import IntebotCatalog from "../IntebotCatalog";
import InputChooser from "../InputChooser";
import AppContext from "../../Context/AppContext";
import MandatoryField from "../common/Fields/MandatoryField";

import "../StyleComponents/ProcessContent.css";

// config
import { SUBSCRIPTIONS } from "../../Config/settings";
export default class ProcessContent extends React.Component {
    //Catalogs to help iterate through process' attributes
    processKeys = IntebotCatalog.ProcessKeys;
    servicesKeys = IntebotCatalog.ServicesKeys;
    soapKeys = IntebotCatalog.SoapKeys;
    restKeys = IntebotCatalog.RestKeys;
    FAQKeys = IntebotCatalog.FAQKeys;

    addNewJSON = () => {
        const { currentProcess, updateBodyRequest } = this.props;

        let inputKey = currentProcess.bodyRequestKey.trim().replace(/\s/g, "");
        let inputValue =
            currentProcess.bodyRequestValue === "null"
                ? null
                : currentProcess.bodyRequestValue;
        let bodyRequest = {
            ...currentProcess.bodyRequest,
            [inputKey]: inputValue,
        };
        updateBodyRequest(bodyRequest);
    };

    render() {
        const { globalValues, getStorageData } = this.context;
        const {
            currentProcess,
            channels,
            allChannelsCheckbox,
            responseAttributes,
            errorHelper,
            errors,
            bodyRequest,
            addBodyRequest,
            editBodyRequest,
            removeBodyRequest,
            processTemp,
            handleNewResponseAttributes,
            addNewObjectResponse,
            addResponseBranch,
            removeResponseBranch,
            updateResponseBranch,
            addRequestHeader,
            updateRequestHeader,
            deleteRequestHeader,
            serviceType,
        } = this.props;
        const { processKeys, servicesKeys, soapKeys, restKeys, FAQKeys } = this;

        let storageData = getStorageData([
            "subscriptionModule",
            "currentSubscription",
        ]);
        servicesKeys.map((key) => {
            switch (key.name) {
                case "customBodyRequest":
                    key.render = currentProcess["enableCustomBodyRequest"];
                    break;
                case "luisMessage":
                    key.render =
                        storageData.subscriptionModule.processNaturalLenguage &&
                        currentProcess.enableLuis;
                    break;
                default:
                    break;
            }
        });
        const keyValueInput = {
            display: "flex",
            margin: "0 10px 10px 10px",
            marginTop: "2.3em",
        };
        const stepsDescription = currentProcess["steps"].map((step, i) => {
            return {
                id: step.description,
                display: `${step.description}`,
                list: "Local",
            };
        });
        //creamos una lista para el input de "mentions" con las descripciones de los pasos + los valores globales
        let autoCompleteList = globalValues.concat(stepsDescription);
        const allProcessesNames = processTemp.map(
            (process) => process.processName
        );
        // let currentProcessIndex = allProcessesNames.indexOf(currentProcess.processName)
        // allProcessesNames.splice(currentProcessIndex,1)
        //console.log(stepsDescription)
        //filtramos los datos para que no haya duplicados
        autoCompleteList = autoCompleteList.filter((item, index) => {
            //comparamos ambas listas y devolvemos la posición del id
            let position = autoCompleteList.findIndex(
                (element) => element.id === item.id
            );
            //si hay un valor duplicado, este tendrá otro index diferente al que está siendo iterado y se quita
            return position === index;
        });

        let { currentSubscription } = storageData;
        let subscriptionWithSpecialElements = SUBSCRIPTIONS.some(
            (e) => e === currentSubscription.subscriptionId
        );

        processKeys.map((key, index) => {
            // si la suscripción es de SEG, mostrar campos especiales
            switch (key.name) {
                case "subMenu":
                    key.render = subscriptionWithSpecialElements;
                    break;
                case "triggerNewFlow":
                    key.render = currentProcess["triggerFlowOnEnd"];
                    key.options = allProcessesNames;
                    key.required = false;
                    break;

                default:
                    break;
            }
        });

        //el radio button elige cual input se debe  hacer render
        return (
            <React.Fragment>
                <Grid container justifyContent="space-around">
                    <Grid item lg={10} md={11} sm={12} xs={12}>
                        {processKeys.map((process, index) =>
                            process.render ? (
                                //===============Flow Fields=============
                                <InputChooser
                                    key={index}
                                    name={process.name}
                                    value={
                                        typeof currentProcess[process.name] ==
                                        "undefined"
                                            ? process.defaultValue
                                            : currentProcess[process.name]
                                    }
                                    type={process.type}
                                    label={process.alias}
                                    detailText={process.detail}
                                    options={process.options}
                                    relatedOptions={
                                        currentProcess[process.relatedOptions]
                                    }
                                    relatedAttribute={process.relatedAttribute}
                                    variant="outlined"
                                    error={errors[process.name]}
                                    required={process.required}
                                    onChange={(
                                        targetName,
                                        targetValue,
                                        targetType,
                                        targetIndex
                                    ) =>
                                        this.props.changeHandler(
                                            targetName,
                                            targetValue,
                                            targetType,
                                            process.required,
                                            targetIndex
                                        )
                                    }
                                    //saveTempSteps={(tempSteps) => this.saveTempSteps(tempSteps)}
                                    onClick={(isEditable) =>
                                        this.props.openStepsTable(isEditable)
                                    }
                                    placeholder={process.placeholder}
                                    channels={channels}
                                    allChannelsCheckbox={allChannelsCheckbox}
                                    errorHelper={errorHelper}
                                    autoCompleteList={autoCompleteList}
                                />
                            ) : null
                        )}

                        <InputChooser
                            //Hardcoded SeriveType Field
                            name="serviceType"
                            value={
                                serviceType === "Base de conocimientos"
                                    ? "FAQ"
                                    : serviceType
                            }
                            type="selector"
                            label="Integración con API"
                            detailText="Petición HTTP que permite la integración del flujo conversacional con una API externa."
                            options={["Ninguno", "SOAP", "REST", "FAQ"]}
                            variant="outlined"
                            error={errors["serviceType"]}
                            required={process.required}
                            onChange={(targetName, targetValue) =>
                                this.props.handleServiceChange(
                                    targetName,
                                    targetValue
                                )
                            }
                            errorHelper={errorHelper}
                            autoCompleteList={autoCompleteList}
                        />
                        <Grid
                            container
                            className="apiOptions--styles"
                            justifyContent="flex-end"
                        >
                            <Grid item lg={11} md={11} sm={11} xs={11}>
                                {serviceType === "SOAP" ? (
                                    //=========SOAP fields==============
                                    soapKeys.map((soapKey, index) => (
                                        <InputChooser
                                            key={index}
                                            name={soapKey.name}
                                            value={
                                                typeof currentProcess[
                                                    soapKey.name
                                                ] == "undefined"
                                                    ? soapKey.defaultValue
                                                    : currentProcess[
                                                          soapKey.name
                                                      ]
                                            }
                                            type={soapKey.type}
                                            label={soapKey.alias}
                                            detailText={soapKey.detail}
                                            options={soapKey.options}
                                            relatedOptions={
                                                currentProcess[
                                                    soapKey.relatedOptions
                                                ]
                                            }
                                            relatedAttribute={
                                                soapKey.relatedAttribute
                                            }
                                            variant="outlined"
                                            error={errors[soapKey.name]}
                                            required={soapKey.required}
                                            onChange={(
                                                targetName,
                                                targetValue,
                                                targetType,
                                                targetIndex
                                            ) =>
                                                this.props.changeHandler(
                                                    targetName,
                                                    targetValue,
                                                    targetType,
                                                    soapKey.required,
                                                    targetIndex
                                                )
                                            }
                                            errorHelper={errorHelper}
                                            autoCompleteList={autoCompleteList}
                                        />
                                    ))
                                ) : serviceType === "REST" ? (
                                    //==========REST FIELDS==================
                                    restKeys.map((restKey, index) => (
                                        <InputChooser
                                            key={index}
                                            name={restKey.name}
                                            value={
                                                typeof currentProcess[
                                                    restKey.name
                                                ] == "undefined"
                                                    ? restKey.defaultValue
                                                    : currentProcess[
                                                          restKey.name
                                                      ]
                                            }
                                            type={restKey.type}
                                            label={restKey.alias}
                                            detailText={restKey.detail}
                                            options={restKey.options}
                                            variant="outlined"
                                            error={errors[restKey.name]}
                                            required={restKey.required}
                                            onChange={(
                                                targetName,
                                                targetValue,
                                                targetType,
                                                targetIndex
                                            ) =>
                                                this.props.changeHandler(
                                                    targetName,
                                                    targetValue,
                                                    targetType,
                                                    restKey.required,
                                                    targetIndex
                                                )
                                            }
                                            errorHelper={errorHelper}
                                            autoCompleteList={autoCompleteList}
                                        />
                                    ))
                                ) : serviceType === "Base de conocimientos" ? (
                                    FAQKeys.map((FAQkey, index) => (
                                        <InputChooser
                                            key={index}
                                            name={FAQkey.name}
                                            value={
                                                currentProcess[FAQkey.name] ??
                                                ""
                                            }
                                            type={FAQkey.type}
                                            label={FAQkey.alias}
                                            detailText={FAQkey.detail}
                                            options={FAQkey.options}
                                            variant="outlined"
                                            error={errors[FAQkey.name]}
                                            required={FAQkey.required}
                                            onChange={(
                                                targetName,
                                                targetValue,
                                                targetType,
                                                targetIndex
                                            ) =>
                                                this.props.changeHandler(
                                                    targetName,
                                                    targetValue,
                                                    targetType,
                                                    FAQkey.required,
                                                    targetIndex
                                                )
                                            }
                                            errorHelper={errorHelper}
                                            autoCompleteList={autoCompleteList}
                                        />
                                    ))
                                ) : serviceType === "Base de conocimientos" ||
                                  "FAQ" ||
                                  "Ninguno" ? (
                                    //En caso de ser un proceso sin servicio no agregamos nada
                                    <React.Fragment />
                                ) : null}

                                {
                                    //Agregamos los inputs que deben tener tanto SOAP como REST
                                    serviceType !== "Base de conocimientos" &&
                                    serviceType !== "FAQ" &&
                                    serviceType !== "Ninguno"
                                        ? servicesKeys.map((serviceKey, i) =>
                                              Array.isArray(serviceKey) ? (
                                                  //================Array Fields==============
                                                  <div
                                                      style={keyValueInput}
                                                      key={i}
                                                  >
                                                      {serviceKey.map(
                                                          (Key, index) =>
                                                              Key.render ? (
                                                                  <InputChooser
                                                                      key={
                                                                          index
                                                                      }
                                                                      name={
                                                                          Key.name
                                                                      }
                                                                      value={
                                                                          currentProcess[
                                                                              Key
                                                                                  .name
                                                                          ]
                                                                      }
                                                                      type={
                                                                          Key.type
                                                                      }
                                                                      label={
                                                                          Key.alias
                                                                      }
                                                                      detailText={
                                                                          Key.detail
                                                                      }
                                                                      //le pasamos de opcion descripción de los pasos para que la pueda elegir el cliente y la pueda usar en el bodyrequest
                                                                      //options={Key.name === 'bodyRequestValue' ? currentProcess['steps'].map((step,i)=>step.description) : Key.options }
                                                                      options={
                                                                          Key.name ===
                                                                          "bodyRequestValue"
                                                                              ? autoCompleteList
                                                                              : Key.options
                                                                      }
                                                                      relatedOptions={
                                                                          currentProcess[
                                                                              Key
                                                                                  .relatedOptions
                                                                          ]
                                                                      }
                                                                      relatedAttribute={
                                                                          Key.relatedAttribute
                                                                      }
                                                                      variant="outlined"
                                                                      error={
                                                                          errors[
                                                                              Key
                                                                                  .name
                                                                          ]
                                                                      }
                                                                      required={
                                                                          Key.required
                                                                      }
                                                                      onChange={
                                                                          Key.group ===
                                                                          "success"
                                                                              ? this
                                                                                    .props
                                                                                    .updateSuccessResponseCode
                                                                              : Key.group ===
                                                                                "unSuccess"
                                                                              ? this
                                                                                    .props
                                                                                    .updateUnsuccessResponseCode
                                                                              : (
                                                                                    targetName,
                                                                                    targetValue,
                                                                                    targetType,
                                                                                    targetIndex
                                                                                ) =>
                                                                                    this.props.changeHandler(
                                                                                        targetName,
                                                                                        targetValue,
                                                                                        targetType,
                                                                                        serviceKey.required,
                                                                                        targetIndex
                                                                                    )
                                                                      }
                                                                      text={
                                                                          Key.text
                                                                      }
                                                                      onClick={
                                                                          Key.name ===
                                                                          "addRequestKey"
                                                                              ? () =>
                                                                                    this.addNewJSON()
                                                                              : Key.name ===
                                                                                "addObjectResponse"
                                                                              ? () =>
                                                                                    this.props.addNewObjectResponse()
                                                                              : Key.name ===
                                                                                "addsuccessResponse"
                                                                              ? () =>
                                                                                    this.addSuccessResponseCode()
                                                                              : Key.name ===
                                                                                "addUnsuccessResponse"
                                                                              ? () =>
                                                                                    this.addUnsuccessResponseCode()
                                                                              : null
                                                                      }
                                                                      requiredMark={
                                                                          Key.requiredMark
                                                                      }
                                                                      jsonKey={
                                                                          Key.jsonKey
                                                                      }
                                                                      jsonValue={
                                                                          Key.jsonValue
                                                                      }
                                                                      errorHelper={
                                                                          errorHelper
                                                                      }
                                                                      bodyRequest={
                                                                          bodyRequest
                                                                      }
                                                                      addBodyRequest={
                                                                          addBodyRequest
                                                                      }
                                                                      editBodyRequest={
                                                                          editBodyRequest
                                                                      }
                                                                      removeBodyRequest={
                                                                          removeBodyRequest
                                                                      }
                                                                      autoCompleteList={
                                                                          autoCompleteList
                                                                      }
                                                                  />
                                                              ) : null
                                                      )}
                                                  </div>
                                              ) : serviceKey.render ? (
                                                  //Single Fields
                                                  <div key={i}>
                                                      <Grid
                                                          item
                                                          xs={12}
                                                          className={
                                                              serviceKey.name ===
                                                              "enableLuis"
                                                                  ? "element--alignFirstLevel"
                                                                  : ""
                                                          }
                                                      >
                                                          <InputChooser
                                                              key={i}
                                                              name={
                                                                  serviceKey.name
                                                              }
                                                              value={
                                                                  currentProcess[
                                                                      serviceKey
                                                                          .name
                                                                  ]
                                                              }
                                                              type={
                                                                  serviceKey.type
                                                              }
                                                              label={
                                                                  serviceKey.alias
                                                              }
                                                              detailText={
                                                                  serviceKey.detail
                                                              }
                                                              //options={serviceKey.name === 'bodyRequestValue' ? currentProcess['steps'].map((step,i)=>step.description) : serviceKey.options }
                                                              options={
                                                                  serviceKey.name ===
                                                                  "bodyRequestValue"
                                                                      ? autoCompleteList.map(
                                                                            (
                                                                                step,
                                                                                i
                                                                            ) =>
                                                                                step.id
                                                                        )
                                                                      : serviceKey.options
                                                              }
                                                              relatedOptions={
                                                                  currentProcess[
                                                                      serviceKey
                                                                          .relatedOptions
                                                                  ]
                                                              }
                                                              relatedAttribute={
                                                                  serviceKey.relatedAttribute
                                                              }
                                                              variant="outlined"
                                                              error={
                                                                  errors[
                                                                      serviceKey
                                                                          .name
                                                                  ]
                                                              }
                                                              required={
                                                                  serviceKey.required
                                                              }
                                                              onChange={
                                                                  serviceKey.group ===
                                                                  "success"
                                                                      ? this
                                                                            .props
                                                                            .updateSuccessResponseCode
                                                                      : serviceKey.group ===
                                                                        "unSuccess"
                                                                      ? this
                                                                            .props
                                                                            .updateUnsuccessResponseCode
                                                                      : (
                                                                            targetName,
                                                                            targetValue,
                                                                            targetType,
                                                                            targetIndex
                                                                        ) =>
                                                                            this.props.changeHandler(
                                                                                targetName,
                                                                                targetValue,
                                                                                targetType,
                                                                                serviceKey.required,
                                                                                targetIndex
                                                                            )
                                                              }
                                                              requiredMark={
                                                                  serviceKey.requiredMark
                                                              }
                                                              text={
                                                                  serviceKey.text
                                                              }
                                                              //AddButton funtions
                                                              onClick={
                                                                  serviceKey.name ===
                                                                  "addRequestKey"
                                                                      ? () =>
                                                                            this.addNewJSON()
                                                                      : serviceKey.name ===
                                                                        "addObjectResponse"
                                                                      ? () =>
                                                                            this.props.addNewObjectResponse()
                                                                      : serviceKey.name ===
                                                                        "addResponseBranch"
                                                                      ? () =>
                                                                            addResponseBranch()
                                                                      : null
                                                              }
                                                              responseAttributes={
                                                                  responseAttributes
                                                              }
                                                              updateResponseAttributes={
                                                                  this.props
                                                                      .updateResponseAttributes
                                                              }
                                                              removeObjectResponse={
                                                                  this.props
                                                                      .removeObjectResponse
                                                              }
                                                              autoCompleteList={
                                                                  autoCompleteList
                                                              }
                                                              globalValues={
                                                                  globalValues
                                                              }
                                                              //decideAutoCompleteList={decideAutoCompleteList}
                                                              fieldTitleUp={
                                                                  serviceKey.fieldTitleUp
                                                              }
                                                              errorHelper={
                                                                  errorHelper
                                                              }
                                                              bodyRequest={
                                                                  bodyRequest
                                                              }
                                                              addBodyRequest={
                                                                  addBodyRequest
                                                              }
                                                              editBodyRequest={
                                                                  editBodyRequest
                                                              }
                                                              removeBodyRequest={
                                                                  removeBodyRequest
                                                              }
                                                              responseBranches={
                                                                  serviceKey.name ===
                                                                  "responseBranches"
                                                                      ? currentProcess[
                                                                            serviceKey
                                                                                .name
                                                                        ]
                                                                      : null
                                                              }
                                                              handleNewResponseAttributes={
                                                                  handleNewResponseAttributes
                                                              }
                                                              allProcessesNames={
                                                                  allProcessesNames
                                                              }
                                                              addNewObjectResponse={
                                                                  addNewObjectResponse
                                                              }
                                                              mainResponseObject={
                                                                  currentProcess[
                                                                      "mainResponseObject"
                                                                  ]
                                                              }
                                                              //responseName={currentProcess["responseName"]}
                                                              addResponseBranch={
                                                                  addResponseBranch
                                                              }
                                                              updateResponseBranch={
                                                                  updateResponseBranch
                                                              }
                                                              removeResponseBranch={
                                                                  removeResponseBranch
                                                              }
                                                              currentProcess={
                                                                  currentProcess
                                                              }
                                                              addRequestHeader={
                                                                  addRequestHeader
                                                              }
                                                              updateRequestHeader={
                                                                  updateRequestHeader
                                                              }
                                                              deleteRequestHeader={
                                                                  deleteRequestHeader
                                                              }
                                                          />
                                                      </Grid>
                                                  </div>
                                              ) : null
                                          )
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <MandatoryField />
            </React.Fragment>
        );
    }
}

ProcessContent.contextType = AppContext;
