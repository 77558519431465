import React, { useContext, useState, useEffect, useRef } from "react";
import {
    Container,
    CssBaseline,
    TextField,
    MenuItem,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

import { whatsAppCampaignActions } from "../../components/DataFunctions";

import AppContext from "../../Context/AppContext";
import CustomButton from "../../components/common/CustomButton";

import { powerBiFilters } from "../../components/IntebotCatalog";

import Swal from "sweetalert2";
import Slider from "rc-slider";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

//custom styles
const marks = {
    0: {
        style: {
            color: "#27315d",
            fontSize: "16px",
            position: "absolute",
        },
        label: <strong>0%</strong>,
    },
    100: {
        style: {
            color: "#27315d",
            fontSize: "16px",
            position: "absolute",
        },
        label: <strong>100%</strong>,
    },
};

export default function DownloadDashboard(props) {
    const { filtersBody, setFiltersBody, getTodayDate } = props;
    const { getStorageData, messagesTemp } = useContext(AppContext);
    let storageData = getStorageData([
        "powerBIReport",
        "subscriptionChannel",
        "subscriptionModule",
        "exclusiveServices",
    ]);
    const [loading, setLoading] = useState(false);

    const [errorInFetch, setErrorInFetch] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [filterSelectedGroup, setFilterSelectedGroup] = useState("");

    const powerBiSectionDashboard = useRef([]);

    useEffect(() => {
        getTemplates();

        let powerBiSectionDashboardCopy = JSON.parse(
            JSON.stringify(powerBiSectionDashboard.current)
        );

        if (!storageData.exclusiveServices.sendCampaigns) {
            powerBiSectionDashboardCopy.push("preguntas frecuentes");
            powerBiSectionDashboardCopy.push("flujos conversacionales");
        }
        if (
            storageData.exclusiveServices.sendCampaigns ||
            storageData.subscriptionModule.sendCampaigns
        ) {
            powerBiSectionDashboardCopy.push("mensajes proactivos");
        }

        powerBiSectionDashboard.current = powerBiSectionDashboardCopy;
        setFilterSelectedGroup(powerBiSectionDashboardCopy[0]);
        setFiltersBody({
            ...filtersBody,
            section: powerBiSectionDashboardCopy[0],
        });
    }, []);

    const getTemplates = async () => {
        setLoading(true);
        var res = await whatsAppCampaignActions(null, "getTemplateList");
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99; //Si no hay un código de respuesta lo mandamos al default
        setLoading(false);
        switch (responseCode) {
            case 5:
                let templateList = res.data.response.templateList
                    .filter((template) => {
                        //quitamos las que tienen el estaus de pendiente de eliminar
                        if (template.templateStatus === "PENDING_DELETION") return false;
                        else return true;
                        //creamos una lista filtrada
                    })
                    .map((template) => template.templateName);

                setTemplateList(templateList);
                setLoading(false);
                break;
            case 6:
                //No hay templates disponibles
                setTemplateList([]);
                break;
            case 20:
                Swal.fire({
                    icon: "info",
                    title: "El servicio de envio de campañas está en mantenimeinto.",
                    text: "Espere unas horas e intente nuevamente.",
                });
                setLoading(false);

                break;
            default:
                setErrorInFetch(true);
                setLoading(false);
                break;
        }
    };

    const handleFilterChange = (targetName, targetValue, targetChecked) => {
        let filtersBodyCopy = JSON.parse(JSON.stringify(filtersBody));

        switch (targetName) {
            case "section":
                if (targetValue === "mensajes proactivos") {
                    filtersBodyCopy["channel"] = ["whatsapp"];
                }
                filtersBodyCopy[targetName] = targetValue;

                filtersBodyCopy = {
                    ...filtersBodyCopy,
                    statusFAQ: [],
                    statusProcess: [],
                    templateName: [],
                    minScore: 0,
                    maxScore: messagesTemp.QNA_MAX_SCORE,
                };
                setFilterSelectedGroup(targetValue);
                break;

            case "minScore":
                //minScore no puede ser mayor que maxScore
                if (targetValue > filtersBodyCopy["maxScore"]) {
                    filtersBodyCopy["minScore"] = filtersBodyCopy["maxScore"];
                    break;
                }
                filtersBodyCopy["minScore"] = targetValue;

                break;
            case "maxScore":
                //maxScore no puede ser menor que minScore
                if (targetValue < filtersBodyCopy["minScore"]) {
                    filtersBodyCopy["maxScore"] = filtersBodyCopy["minScore"];
                    break;
                }
                filtersBodyCopy["maxScore"] = targetValue;
                break;
            case "startDate":
                //startDate no puede ser mayor que endDate
                if (targetValue > filtersBodyCopy["endDate"]) {
                    filtersBodyCopy["startDate"] = filtersBodyCopy["endDate"];
                    break;
                }
                filtersBodyCopy[targetName] = targetValue;
                break;

            case "endDate":
                //endDate no puede ser menor que startDate
                if (targetValue < filtersBodyCopy["startDate"]) {
                    filtersBodyCopy["endDate"] = filtersBodyCopy["startDate"];
                    break;
                }
                filtersBodyCopy[targetName] = targetValue;
                break;

            default:
                if (targetChecked) {
                    filtersBodyCopy[targetName].push(targetValue);
                    break;
                }

                if (!targetChecked) {
                    filtersBodyCopy[targetName] = filtersBodyCopy[targetName].filter(
                        (item) => item !== targetValue
                    );
                    break;
                }

                filtersBodyCopy[targetName] = targetValue;
                break;
        }

        setFiltersBody(filtersBodyCopy);
    };

    return (
        <div className="downloadContainer">
            <div className="downloadContainerInput">
                <label className="top-label-field">Sección del Reporte</label>
                <TextField
                    select
                    name={"section"}
                    value={filtersBody.section}
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleFilterChange(e.target.name, e.target.value)}
                >
                    {powerBiSectionDashboard.current.map((option, i) => {
                        return (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </div>
            {filterSelectedGroup !== "mensajes proactivos" && (
                <div className="downloadContainerInput">
                    <label className="top-label-field">Canal digital</label>
                    {Object.keys(storageData.subscriptionChannel).map(
                        (option, i) =>
                            storageData.subscriptionChannel[option] && (
                                <FormControlLabel
                                    key={i}
                                    control={
                                        <Checkbox
                                            checked={filtersBody.channel.includes(option)}
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "channel",
                                                    option,
                                                    e.target.checked
                                                )
                                            }
                                            name={option}
                                            color="primary"
                                        />
                                    }
                                    label={option}
                                />
                            )
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filtersBody.channel.includes("pruebas")}
                                onChange={(e) =>
                                    handleFilterChange("channel", "pruebas", e.target.checked)
                                }
                                name={"pruebas"}
                                color="primary"
                            />
                        }
                        label={"pruebas"}
                    />
                </div>
            )}
            <div className="downloadContainerInput">
                <label className="top-label-field">Fecha de inicio</label>
                <input
                    name="startDate"
                    type="date"
                    value={filtersBody.startDate}
                    onChange={(e) => handleFilterChange(e.target.name, e.target.value)}
                />
                <label className="top-label-field">Fecha de fin</label>
                <input
                    name="endDate"
                    type="date"
                    value={filtersBody.endDate}
                    onChange={(e) => handleFilterChange(e.target.name, e.target.value)}
                />
            </div>

            {filterSelectedGroup === "preguntas frecuentes" && (
                <div className="downloadContainerInput">
                    <label className="top-label-field">Estatus de preguntas frecuentes</label>

                    {powerBiFilters
                        .find((filter) => filter.name === "statusFAQ")
                        .options.map((option, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        checked={filtersBody.statusFAQ.includes(option)}
                                        onChange={(e) =>
                                            handleFilterChange(
                                                "statusFAQ",
                                                option,
                                                e.target.checked
                                            )
                                        }
                                        name={option}
                                        color="primary"
                                    />
                                }
                                label={option}
                            />
                        ))}
                </div>
            )}
            {filterSelectedGroup === "preguntas frecuentes" && (
                <div className="downloadContainerInput">
                    <div style={{ marginBottom: "20px" }}>
                        <label className="top-label-field">
                            Puntaje mínimo de pregunta frecuente
                        </label>
                        <Range
                            id="slider-range"
                            min={0}
                            max={100}
                            value={[filtersBody.minScore]}
                            onChange={(e) => handleFilterChange("minScore", e[0])}
                            allowCross={false}
                            pushable={1}
                            trackStyle={[{ backgroundColor: "#B2B2B2" }]}
                            railStyle={{ background: "#B2B2B2" }}
                            tipProps={{
                                visible: true,
                                placement: "top",
                                id: `tooltip-id`,
                            }}
                            marks={marks}
                        />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <label className="top-label-field">
                            Puntaje máximo de pregunta frecuente
                        </label>
                        <Range
                            id="slider-range"
                            min={1}
                            max={100}
                            value={[filtersBody.maxScore]}
                            onChange={(e) => handleFilterChange("maxScore", e[0])}
                            allowCross={false}
                            pushable={1}
                            trackStyle={[{ backgroundColor: "#B2B2B2" }]}
                            railStyle={{ background: "#B2B2B2" }}
                            tipProps={{
                                visible: true,
                                placement: "top",
                                id: `tooltip-id`,
                            }}
                            marks={marks}
                        />
                    </div>
                </div>
            )}
            {filterSelectedGroup === "flujos conversacionales" && (
                <div className="downloadContainerInput">
                    <label className="top-label-field">Estatus de flujo conversacional</label>

                    {powerBiFilters
                        .find((obj) => obj.name === "statusProcess")
                        .options.map((option, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        checked={filtersBody.statusProcess.includes(option)}
                                        onChange={(e) =>
                                            handleFilterChange(
                                                "statusProcess",
                                                option,
                                                e.target.checked
                                            )
                                        }
                                        name={option}
                                        color="primary"
                                    />
                                }
                                label={option}
                            />
                        ))}
                </div>
            )}
            {filterSelectedGroup === "mensajes proactivos" && (
                <div className="downloadContainerInput">
                    <label className="top-label-field">plantilla de campaña de WhatsApp</label>
                    {loading ? (
                        <div className="center-loading-icon">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            {templateList.length > 0 &&
                                templateList.map((option, i) => (
                                    <FormControlLabel
                                        key={i}
                                        control={
                                            <Checkbox
                                                checked={filtersBody.templateName.includes(option)}
                                                onChange={(e) =>
                                                    handleFilterChange(
                                                        "templateName",
                                                        option,
                                                        e.target.checked
                                                    )
                                                }
                                                name={option}
                                                color="primary"
                                            />
                                        }
                                        label={option}
                                    />
                                ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
