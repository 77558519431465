import React from 'react';

//MUI Components
import { MenuItem, Tooltip, Select } from '@material-ui/core';

const ChangeSubSelector = (props) => {

    const { subSelectorIsOpen, setOpenSelectorToolTip, openSelectorToolTip, currentSub, setSubSelectorIsOpen, changeSubscription, subscriptionId } = props

    return (
        <>
            <Tooltip 
                onOpen={(() => {
                    if(!subSelectorIsOpen){
                        setOpenSelectorToolTip(true)
                    }
                })}
                onClose={() => setOpenSelectorToolTip(false)}
                open={openSelectorToolTip} 
                arrow 
                title='Cambiar suscripción'
            >
                <Select
                    className='sub-selector'
                    name={'subSelector'}
                    value={currentSub}
                    fullWidth
                    variant='outlined'
                    size="small"
                    onOpen={() => {setSubSelectorIsOpen(true); setOpenSelectorToolTip(false);}}
                    onClose={() => setSubSelectorIsOpen(false)}
                    onChange={(event) => changeSubscription(event.target.value)}
                >
                    {
                        subscriptionId.map((subscription, i) =><MenuItem key={i} value={subscription.name}>{subscription.name}</MenuItem>)
                    }
                </Select>    
            </Tooltip>
        </>
    );
}

export default ChangeSubSelector;
