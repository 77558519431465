import React from 'react';
import { Breadcrumbs, Typography, Grid, TextField, MenuItem } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AgregarIcono from '../../Icons/AgregarIcono';
import FormModal from '../FormModalComponents/FormModal';
import IntebotCatalog from '../IntebotCatalog';
import InputChooser from '../InputChooser';
import CustomAlert from '../common/Alerts/CustomAlert';

export default class AddRegExForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hasChanges: false,
            regExp: {
                name: '',
                expresion: '',
                description:'',
            },
            errors: {
                name: true,
                expresion: true,
                description:false,
            },
            countErrors: 2,
            regExList: [],
            alert: {
                open: false,
                severity: '',
                message: ''
            },
            loadingDialog: false,
            errorHelper:{
                name: this.errorHelperKeys.emptyField,
                expresion: this.errorHelperKeys.emptyField,
                description: this.errorHelperKeys.removeError,
            },
        }
    }

    errorHelperKeys = IntebotCatalog.errorHelper

    componentDidMount() {
        const regExList = this.props.regEx.map(regEx => regEx.name);
        this.setState({
            regExList: regExList,
        });
    }

    //Función importante para recibir props actualizados y volver a renderizar
    componentDidUpdate(prevProps) {
        const { regEx } = this.props;
        if (prevProps.regEx !== regEx) {
            const regExList = this.props.regEx.map(regEx => regEx.name);
            this.setState({
                regExList: regExList,
            });
        }
    }

    changeHandler = (targetName, targetValue, targetType, targetRequired) => {
        //Hacemos una copia independiente del objeto para guardar cambios:
        //console.log(targetName, targetValue, targetType, targetRequired)
        const { errorHelperKeys } = this
        const { regExp, errorHelper, errors, regExList } = this.state
        const regExpCopy = {...regExp}
        let errorHelperCopy = JSON.parse(JSON.stringify(errorHelper));
        const errorsCopy = {...errors}
        switch (targetName) {
            case 'name':
                regExpCopy[targetName] = targetValue.toLowerCase();
                //También colocamos error si ya existe el nombre para evitar duplicidad
                if(regExList.includes(targetValue)){
                    errorsCopy[targetName] = true
                    errorHelperCopy[targetName] = errorHelperKeys.regexNameTake
                    break;
                }
                //No este vacío
                if(targetValue === '' && targetRequired) {
                    errorsCopy[targetName] = true
                    errorHelperCopy[targetName] = errorHelperKeys.emptyField
                    break;
                } 
                errorsCopy[targetName] = false
                errorHelperCopy[targetName] = errorHelperKeys.removeError
                break;
            default:
                regExpCopy[targetName] = targetValue
                //Revisamos si hay errores en los campos
                if(targetValue === '' && targetRequired) {
                    errorsCopy[targetName] = true
                    errorHelperCopy[targetName] = errorHelperKeys.emptyField
                    break;
                } 
                errorsCopy[targetName] = false
                errorHelperCopy[targetName] = errorHelperKeys.removeError
                break;
        }
        const countErrors = Object.values(errorsCopy).reduce((a, item) => a + item, 0)
        //Guardamos los cambios en el estado
        this.setState({
            regExp: regExpCopy,
            errors: errorsCopy,
            countErrors: countErrors,
            hasChanges: true,
            errorHelper:errorHelperCopy
        })
    }

    onSave = () => {
        //Regresamos el estado del componente al inicial
        this.setState({
            hasChanges: false,
            regExp: {
                name: '',
                expresion: '',
                description:'',
            },
            errors: {
                name: true,
                expresion: true,
                description:false,
            },
            errorHelper:{
                name: this.errorHelperKeys.emptyField,
                expresion: this.errorHelperKeys.emptyField,
                description: this.errorHelperKeys.removeError,
            },
            countErrors: 2,
        });
    }

    //Reiniciamos el estado del componente
    onCancel = () => {
        this.setState({
            hasChanges: false,
            regExp: {
                name: '',
                expresion: '',
                description:'',
            },
            errors: {
                name: true,
                expresion: true,
                description:false,
            },
            countErrors: 2,
        })
    }

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: ''
            }
        });
    }

    //Propiedades del catálogo
    regExpKeys = IntebotCatalog.RegExpKeys;
    
    render() {
        const { regEx } = this.props;
        return(
            <React.Fragment>
                <TextField
                    select
                    className={this.props.className}
                    name={this.props.name}
                    value={this.props.value}
                    fullWidth
                    variant={this.props.variant}
                    error={this.props.error}
                    size="small"
                    onChange={(event) => this.props.onChange(this.props.name, event.target.value, this.props.type)}
                    helperText={this.props.helperText}
                >
                    {regEx.length > 0 ?
                        regEx.map((object, index) => 
                            <MenuItem key={index} value={object.name}>{object.name}</MenuItem>
                        )
                    :
                        <MenuItem value={''}>No hay opciones disponibles</MenuItem>
                    }
                </TextField>
                <FormModal
                    modalTitle={
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} >
                            <Typography color="textPrimary">
                                Agregar nuevo tipo de dato
                            </Typography>
                        </Breadcrumbs>
                    }
                    buttonName="Agregar nuevo tipo de dato"
                    buttonType="link"
                    buttonTooltip="Abrir modal para agregar el nuevo dato"
                    startIcon={<AgregarIcono width="15px" height="15px"/>}
                    onCancel={() => this.onCancel()}
                    onSave={() => { this.onSave(); this.props.addNewRegEx(this.state.regExp) }}
                    hasErrors={this.state.countErrors > 0 ? true : false}
                    hasChanges={this.state.hasChanges}
                    noConfirm
                    alignButtonRight={true}
                    noCancelButton
                >
                    <CustomAlert
                        open={this.state.alert.open}
                        severity={this.state.alert.severity}
                        message={this.state.alert.message}
                        handleCloseAlert={() => this.handleCloseAlert()}
                    />
                    <Grid container justifyContent="space-around" >
                        <Grid item sm={12} xs={10}>
                            {this.regExpKeys.map((item, index) =>
                                <InputChooser
                                    key={index}
                                    name={item.name}
                                    value={this.state.regExp[item.name]}
                                    type={item.type}
                                    label={item.alias}
                                    detailText={item.detail}
                                    variant="outlined"
                                    error={this.state.errors[item.name]}
                                    required={item.required}
                                    onChange={
                                        (targetName, targetValue, targetType) =>
                                            this.changeHandler(targetName, targetValue, targetType, item.required)
                                    }
                                    errorHelper={this.state.errorHelper}
                                    modalAddRegExForm={true}
                                />
                            )}
                        </Grid>
                    </Grid>
                </FormModal>
            </React.Fragment>
        )
    }
}