import React, { useState, useEffect, useContext } from "react";

//MUI components
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions,
    IconButton,
    TextField,
    Grid,
    CircularProgress as MuiCircularProgress,
    Tooltip,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

//Custom portal components
import CustomButton from "../../common/CustomButton";
import { entityTypes, errorHelper } from "../../IntebotCatalog";
import EntityChildTable from "./EntityChildTable";
import { actionsPLNManagement } from "../../DataFunctions";
import AppContext from "../../../Context/AppContext";
import RegexEntityType from "./RegexEntityType";
import ListEntityType from "./ListEntityType";

//Icons
import CloseIcon from "@material-ui/icons/Close";

//Styles
import "../styles/entitiesModal.css";

import Swal from "sweetalert2";

//Footer styles
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        backgroundColor: "white",
        padding: theme.spacing(2),
        margin: "0",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
}));

//==== Dialog Styles =====
const Dialog = withStyles((theme) => ({
    root: {
        marginTop: "56px",
        [theme.breakpoints.up("md")]: {
            marginTop: "64px",
        },
    },
}))(MuiDialog);

const DialogTitle = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #e3e3e3",
        padding: "0px 20px",
    },
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        padding: "23px",
    },
}))(MuiDialogContent);

const EntitiesModal = (props) => {
    const classes = useStyles();

    const [tempEntity, setTempEntity] = useState({});
    const [addChildEntity, setAddChildEntity] = useState([]);
    const [removeChildEntityObj, setRemoveChildEntityObj] = useState([]);
    const [updateChildEntityObj, setUpdateChildEntityObj] = useState([]);
    const [updateEntityFatherName, setUpdateEntityFatherName] = useState({});
    const [hasChanges, setHasChanges] = useState(false);
    const [entityErrors, setEntityErrors] = useState({});
    const [entityErrorHelper, setEntityErrorHelper] = useState({});
    const [accordionExpanded, setAccordionExpanded] = useState({});

    const { updateContextAttribute, sleep, getStorageData } = useContext(AppContext);
    const storageData = getStorageData(["exclusiveServices", "appId"]);

    const {
        openEntitiesModal,
        setOpenEntitiesModal,
        createNewEntity,
        currentEntity,
        refreshFunction,
        PLNtakenNamesList,
    } = props;
    const { name, entityType, entity, id } = tempEntity;

    useEffect(() => {
        setTempEntity(currentEntity);
        setAccordionExpanded({ [currentEntity.id]: true });
    }, [currentEntity]);

    const addNewEntityChild = (
        childLevelIndex,
        childIndex,
        previousChildIndex,
        previousChildIndex2
    ) => {
        //console.log('childLevelIndex, childIndex, previousChildIndex, previousChildIndex2', childLevelIndex, childIndex, previousChildIndex, previousChildIndex2)
        const tempEntityCopy = JSON.parse(JSON.stringify(tempEntity));
        const tempAddChildEntityCopy = JSON.parse(JSON.stringify(addChildEntity));
        let entityFatherId;
        //generate unique id for the new child with 6 random numbers and characters
        let childId = Math.random().toString(36).substr(2, 6);
        //First que look for the level, then add the new child under this direction: <- the previous child index
        switch (childLevelIndex) {
            case 0:
                tempEntityCopy.children.push({
                    name: "",
                    children: [],
                    id: childId,
                    childLevelIndex: childLevelIndex,
                });
                entityFatherId = tempEntityCopy.id;
                break;
            case 1:
                tempEntityCopy.children[childIndex].children.push({
                    name: "",
                    children: [],
                    id: childId,
                    childLevelIndex: childLevelIndex,
                });
                entityFatherId = tempEntityCopy.children[childIndex].id;
                break;
            case 2:
                tempEntityCopy.children[previousChildIndex].children[childIndex].children.push({
                    name: "",
                    children: [],
                    id: childId,
                    childLevelIndex: childLevelIndex,
                });
                entityFatherId =
                    tempEntityCopy.children[previousChildIndex].children[childIndex].id;
                break;
            case 3:
                tempEntityCopy.children[previousChildIndex2].children[previousChildIndex].children[
                    childIndex
                ].children.push({
                    name: "",
                    children: [],
                    id: childId,
                    childLevelIndex: childLevelIndex,
                });
                entityFatherId =
                    tempEntityCopy.children[previousChildIndex2].children[previousChildIndex]
                        .children[childIndex].id;
                break;
            default:
                break;
        }
        let data = {
            childId: childId,
            entityName: "",
            entityFatherId: entityFatherId,
            childLevelIndex: childLevelIndex,
        };
        //Guardamos errores
        setEntityErrors({
            ...entityErrors,
            [childId]: true,
        });
        setEntityErrorHelper({
            ...entityErrorHelper,
            [childId]: errorHelper.emptyField,
        });

        if (!accordionExpanded[entityFatherId]) {
            setAccordionExpanded({
                ...accordionExpanded,
                [entityFatherId]: true,
            });
        }

        tempAddChildEntityCopy.push(data);
        setAddChildEntity(tempAddChildEntityCopy);
        setTempEntity(tempEntityCopy);
        setHasChanges(true);
    };

    const removeEntityChild = async (
        id,
        childLevelIndex,
        childIndex,
        previousChildIndex,
        previousChildIndex2,
        previousChildIndex3
    ) => {
        let alert = await Swal.fire({
            text: "¿Deseas eliminar esta entidad hija?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        const tempEntityCopy = JSON.parse(JSON.stringify(tempEntity));
        let deletedChild;
        //First que look for the level, then add the new child under this direction: <- the previous child index
        switch (childLevelIndex) {
            case 1:
                deletedChild = tempEntityCopy.children.splice(childIndex, 1)[0];
                break;
            case 2:
                deletedChild = tempEntityCopy.children[previousChildIndex].children.splice(
                    childIndex,
                    1
                )[0];
                break;
            case 3:
                deletedChild = tempEntityCopy.children[previousChildIndex2].children[
                    previousChildIndex
                ].children.splice(childIndex, 1)[0];
                break;
            case 4:
                deletedChild = tempEntityCopy.children[previousChildIndex3].children[
                    previousChildIndex2
                ].children[previousChildIndex].children.splice(childIndex, 1)[0];
            default:
                break;
        }
        //Si el id es mayor a 9, se manda a eliminar a base
        if (id.length > 6) {
            let data = {
                entityId: deletedChild.id,
            };
            let tempRemoveChildEntityObjCopy = JSON.parse(JSON.stringify(removeChildEntityObj));
            tempRemoveChildEntityObjCopy.push(data);
            setRemoveChildEntityObj(tempRemoveChildEntityObjCopy);
        } else {
            //Si el id es menor a 6, se manda a eliminar del arreglo
            let tempAddChildEntityCopy = JSON.parse(JSON.stringify(addChildEntity));
            let index = tempAddChildEntityCopy.findIndex((child) => child.childId === id);
            tempAddChildEntityCopy.splice(index, 1);
            setAddChildEntity(tempAddChildEntityCopy);
        }
        //Guardamos errores
        let entityErrorsCopy = JSON.parse(JSON.stringify(entityErrors));
        let entityErrorHelperCopy = JSON.parse(JSON.stringify(entityErrorHelper));
        delete entityErrorsCopy[id];
        delete entityErrorHelperCopy[id];
        setEntityErrors(entityErrorsCopy);
        setEntityErrorHelper(entityErrorHelperCopy);
        setTempEntity(tempEntityCopy);
        setHasChanges(true);
    };

    const getCurrentEntityChildrenNames = (entityList) => {
        let entityListNames = [];
        for (let entity of entityList) {
            const { children = [] } = entity;
            entityListNames.push({ name: entity.name, id: entity.id });
            if (children.length > 0) {
                entityListNames.push(...getCurrentEntityChildrenNames(children));
            }
        }
        return entityListNames;
    };

    const handleChildEntityForm = (
        childId,
        childLevelIndex,
        childIndex,
        previousChildIndex,
        previousChildIndex2,
        previousChildIndex3,
        targetName,
        targetValue
    ) => {
        //console.log('handleChildEntityForm', childId, childLevelIndex, childIndex, previousChildIndex, previousChildIndex2, previousChildIndex3, targetName, targetValue);
        let tempEntityCopy = JSON.parse(JSON.stringify(tempEntity));
        let tempAddChildEntityCopy = JSON.parse(JSON.stringify(addChildEntity));
        let updateChildEntityObjCopy = JSON.parse(JSON.stringify(updateChildEntityObj));
        let entityErrorsCopy = JSON.parse(JSON.stringify(entityErrors));
        let entityErrorHelperCopy = JSON.parse(JSON.stringify(entityErrorHelper));
        let currentChildrenNames = [];
        //Manejo del la lista de entity childs del estado
        switch (childLevelIndex) {
            case 0:
                tempEntityCopy[targetName] = targetValue;
                break;
            case 1:
                tempEntityCopy.children[childIndex][targetName] = targetValue;
                break;
            case 2:
                tempEntityCopy.children[previousChildIndex].children[childIndex][targetName] =
                    targetValue;
                break;
            case 3:
                tempEntityCopy.children[previousChildIndex2].children[previousChildIndex].children[
                    childIndex
                ][targetName] = targetValue;
                break;
            case 4:
                tempEntityCopy.children[previousChildIndex3].children[previousChildIndex2].children[
                    previousChildIndex
                ].children[childIndex][targetName] = targetValue;
                break;
            default:
                break;
        }
        currentChildrenNames = getCurrentEntityChildrenNames(tempEntityCopy.children);
        setTempEntity(tempEntityCopy);
        //Manejo de errores
        switch (targetName) {
            case "name":
                if (!targetValue) {
                    entityErrorsCopy[childId] = true;
                    entityErrorHelperCopy[childId] = errorHelper.emptyField;
                    break;
                }
                let PLNtakenNamesListCopy = [...PLNtakenNamesList];
                let currentChildrenNamesCopy = [...currentChildrenNames];

                //Remove duplicated names from currentChildrenNames and PLNtakenNamesList
                let currentChildrenNamesCopyFiltered = currentChildrenNamesCopy.filter(
                    (childObj) => {
                        //compare PLNtakenNamesListCopy with currentChildrenNamesCopy
                        let duplicateName = PLNtakenNamesListCopy.filter(
                            (takenNameObj) =>
                                takenNameObj.name.toLowerCase() === childObj.name.toLowerCase()
                        );
                        if (duplicateName.length > 0) {
                            return false;
                        }
                        return true;
                    }
                );

                //add the current children names to the list
                PLNtakenNamesListCopy.push(...currentChildrenNamesCopyFiltered);

                //remove current intent name from intentListNames
                let index = PLNtakenNamesListCopy.findIndex(
                    (takenNameObj) => takenNameObj.id === childId
                );
                PLNtakenNamesListCopy.splice(index, 1);
                //check if the input name is duplicated
                let duplicateName = PLNtakenNamesListCopy.filter(
                    (takenNameObj) => takenNameObj.name.toLowerCase() === targetValue.toLowerCase()
                );
                if (duplicateName.length > 0) {
                    entityErrorsCopy[childId] = true;
                    entityErrorHelperCopy[childId] = errorHelper.duplicateName;
                    break;
                }
                entityErrorsCopy[childId] = false;
                entityErrorHelperCopy[childId] = "";
                break;

            default:
                break;
        }
        setEntityErrors(entityErrorsCopy);
        setEntityErrorHelper(entityErrorHelperCopy);

        //Manejo de los objetos a enviar a la base de datos
        switch (true) {
            case childLevelIndex === 0:
                let updateEntityFatherNameCopy = {
                    entityId: childId,
                    entityName: targetValue,
                };
                setUpdateEntityFatherName(updateEntityFatherNameCopy);
                break;
            case childId.length > 6:
                //significa que es un objeto que ya existe en la base de datos
                let updateChildEntityObjIndex = updateChildEntityObjCopy.findIndex(
                    (child) => child.childId === childId
                );
                if (updateChildEntityObjIndex === -1) {
                    let data = {
                        childId: childId,
                        entityName: targetValue,
                        entityId: childId,
                    };
                    updateChildEntityObjCopy.push(data);
                } else {
                    updateChildEntityObjCopy[updateChildEntityObjIndex][targetName] = targetValue;
                }
                setUpdateChildEntityObj(updateChildEntityObjCopy);
                break;
            case childId.length === 6:
                //significa que es un objeto que no existe en la base de datos
                let childIndexAddChildEntity = tempAddChildEntityCopy.findIndex(
                    (child) => child.childId === childId
                );
                tempAddChildEntityCopy[childIndexAddChildEntity]["entityName"] = targetValue;
                setAddChildEntity(tempAddChildEntityCopy);
                break;
            default:
                break;
        }
        setHasChanges(true);
    };

    const saveEntity = async () => {
        let alert = await Swal.fire({
            text: "¿Deseas guardar los cambios?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Actualizando entidad...");

        let responseCodesArray = [];
        let addChildEntityCopy = JSON.parse(JSON.stringify(addChildEntity));

        //Ordenamos el array de hijos a agregar por nivel empezando por el nivel 0 (padre) y terminando en el nivel 4
        addChildEntityCopy.sort((a, b) => (a.childLevelIndex > b.childLevelIndex ? 1 : -1));
        if (addChildEntityCopy.length > 0) {
            for (let index = 0; index < addChildEntityCopy.length; index++) {
                const childToAdd = addChildEntityCopy[index];
                //Mandamos el hijo al servicio del PLN
                updateContextAttribute(
                    "LoadingMessage",
                    `Actualizando entidad "${childToAdd.entityName}"... ${index + 1}/${
                        addChildEntity.length
                    } `
                );
                if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                    childToAdd["appID"] = storageData.appId;
                }
                let res = await actionsPLNManagement(childToAdd, "createEntityChild");
                let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
                switch (responseCode) {
                    case 0:
                        //Si el hijo se creo correctamente, actualizamos el id del hijo en el array de hijos a agregar
                        let entityChildId = res.data.data.entityChildId;
                        let nextChildLevelIndex = childToAdd.childLevelIndex + 1;
                        //Buscamos los hijos del padre que acaba de ser creado
                        for (let index = 0; index < addChildEntityCopy.length; index++) {
                            let nextChildToAdd = addChildEntityCopy[index];
                            //Buscamos el siguiente nivel al del padre que acaba de ser creado
                            if (nextChildToAdd.childLevelIndex === nextChildLevelIndex) {
                                //Si el siguiente elemento es hijo del padre actual, lo actualizamos con el nuevo id del padre
                                if (nextChildToAdd.entityFatherId === childToAdd.childId) {
                                    nextChildToAdd.entityFatherId = entityChildId;
                                }
                            }
                        }
                        break;
                    case 5:
                        //No se pueden agregar mas de 10 hijos a un padre
                        let alertError2 = {
                            open: true,
                            severity: "error", //success, error, warning, info
                            message: `No se pueden agregar más de 20 hijos a la entidad, la entidad "${childToAdd.entityName}" no se agregó.`,
                        };
                        updateContextAttribute("alert", alertError2);
                        break;

                    default:
                        let alertError = {
                            open: true,
                            severity: "error", //success, error, warning, info
                            message: `Ocurrió un error al guardar la entidad "${childToAdd.entityName}".`,
                        };
                        updateContextAttribute("alert", alertError);
                        updateContextAttribute("loadingDialog", false);
                        updateContextAttribute("LoadingMessage", "");
                        continue;
                }
                responseCodesArray.push(responseCode);
                //sleep for 3 second
                await new Promise((r) => setTimeout(r, 3000));
            }
        }

        if (removeChildEntityObj.length > 0) {
            for (let index = 0; index < removeChildEntityObj.length; index++) {
                const childToRemove = removeChildEntityObj[index];
                if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                    childToRemove["appID"] = storageData.appId;
                }
                let res = await actionsPLNManagement(childToRemove, "deleteEntityChild");
                await new Promise((r) => setTimeout(r, 1000));
                let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
                responseCodesArray.push(responseCode);
            }
        }

        if (updateChildEntityObj.length > 0) {
            for (let index = 0; index < updateChildEntityObj.length; index++) {
                const childToUpdate = updateChildEntityObj[index];
                if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                    childToUpdate["appID"] = storageData.appId;
                }
                let res = await actionsPLNManagement(childToUpdate, "updateEntityChild");
                await new Promise((r) => setTimeout(r, 1000));
                let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
                responseCodesArray.push(responseCode);
            }
        }

        if (updateEntityFatherName.entityName) {
            if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
                updateEntityFatherName["appID"] = storageData.appId;
            }
            let res = await actionsPLNManagement(updateEntityFatherName, "updateEntityChild");
            await new Promise((r) => setTimeout(r, 1000));
            let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
            responseCodesArray.push(responseCode);
        }

        let everyResponseCode = responseCodesArray.every((code) => code === 0);
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        if (everyResponseCode) {
            let alertSuccess = {
                open: true,
                severity: "success", //success, error, warning, info
                message: `La entidad fue actualizada correctamente.`,
            };
            updateContextAttribute("alert", alertSuccess);
            setOpenEntitiesModal(false);
            await sleep(300);
            setAddChildEntity([]);
            setRemoveChildEntityObj([]);
            setUpdateChildEntityObj([]);
            setHasChanges(false);
            refreshFunction();
            return;
        }
        let alertError = {
            open: true,
            severity: "error", //success, error, warning, info
            message:
                "Ocurrió un error al guardar los datos, puede que la información se haya guardado parcialmente.",
        };
        updateContextAttribute("alert", alertError);
        setOpenEntitiesModal(false);
        await sleep(300);
        setAddChildEntity([]);
        setRemoveChildEntityObj([]);
        setUpdateChildEntityObj([]);
        setHasChanges(false);
        refreshFunction();
        return;
    };

    const handleAccordionExpanded = (id) => {
        setAccordionExpanded({
            ...accordionExpanded,
            [id]: !accordionExpanded[id],
        });
    };

    //Regex functions
    const handleRegexEntityForm = (targetName, targetValue) => {
        //console.log('targetName, targetValue', targetName, targetValue)
        let tempEntityCopy = JSON.parse(JSON.stringify(tempEntity));
        let entityErrorsCopy = JSON.parse(JSON.stringify(entityErrors));
        let entityErrorHelperCopy = JSON.parse(JSON.stringify(entityErrorHelper));
        switch (targetName) {
            case "regexPattern":
                tempEntityCopy[targetName] = targetValue;
                if (!targetValue) {
                    entityErrorsCopy["regexPattern"] = true;
                    entityErrorHelperCopy["regexPattern"] = errorHelper.emptyField;
                    break;
                }
                //quitamos errores
                entityErrorsCopy[targetName] = false;
                entityErrorHelperCopy[targetName] = errorHelper.removeError;
                break;

            default:
                break;
        }
        setTempEntity(tempEntityCopy);
        setEntityErrors(entityErrorsCopy);
        setEntityErrorHelper(entityErrorHelperCopy);
        setHasChanges(true);
    };

    const saveRegexEntityChanges = async () => {
        let alert = await Swal.fire({
            text: "¿Deseas guardar los cambios?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Creando entidad...");
        const { id, name, regexPattern } = tempEntity;
        let data = {
            entityName: name,
            regexEntityId: id,
            regex: regexPattern,
        };
        if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
            data["appID"] = storageData.appId;
        }
        let res = await actionsPLNManagement(data, "updateEntityRegex");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let alertSuccess = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: `La entidad fue actualizada correctamente.`,
                };
                updateContextAttribute("alert", alertSuccess);
                setOpenEntitiesModal(false);
                await sleep(300);
                setHasChanges(false);
                refreshFunction();
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
    };

    //List functions
    const handleListEntityForm = (targetName, targetValue, chipPosition, index, listId) => {
        let tempEntityCopy = JSON.parse(JSON.stringify(tempEntity));
        let entityErrorsCopy = JSON.parse(JSON.stringify(entityErrors));
        let entityErrorHelperCopy = JSON.parse(JSON.stringify(entityErrorHelper));
        switch (targetName) {
            case "canonicalForm":
                tempEntityCopy["subLists"][index][targetName] = targetValue;
                if (!targetValue) {
                    entityErrorsCopy[listId] = true;
                    entityErrorHelperCopy[listId] = errorHelper.emptyField;
                    break;
                }
                entityErrorsCopy[listId] = false;
                entityErrorHelperCopy[listId] = errorHelper.removeError;
                break;
            case "list":
                if (chipPosition >= 0) {
                    //si hay un position entonces eliminamos el chip
                    tempEntityCopy["subLists"][index]["list"].splice(chipPosition, 1);
                    break;
                }
                //agregamos un nuevo chip
                tempEntityCopy["subLists"][index]["list"].push(targetValue);
                break;
            default:
                break;
        }
        setEntityErrors(entityErrorsCopy);
        setEntityErrorHelper(entityErrorHelperCopy);
        setTempEntity(tempEntityCopy);
        setHasChanges(true);
    };

    const addListItem = () => {
        let tempEntityCopy = JSON.parse(JSON.stringify(tempEntity));
        let entityErrorsCopy = JSON.parse(JSON.stringify(entityErrors));
        let entityErrorHelperCopy = JSON.parse(JSON.stringify(entityErrorHelper));
        //generate unique id for the new child with 6 random numbers and characters
        let listId = Math.random().toString(36).substr(2, 6);
        tempEntityCopy["subLists"].push({ id: listId, canonicalForm: "", list: [] });
        entityErrorsCopy[listId] = true;
        entityErrorHelperCopy[listId] = errorHelper.emptyField;
        setEntityErrors(entityErrorsCopy);
        setEntityErrorHelper(entityErrorHelperCopy);
        setTempEntity(tempEntityCopy);
        setHasChanges(true);
    };

    const deleteListItem = (index) => {
        let tempEntityCopy = JSON.parse(JSON.stringify(tempEntity));
        let entityErrorsCopy = JSON.parse(JSON.stringify(entityErrors));
        let entityErrorHelperCopy = JSON.parse(JSON.stringify(entityErrorHelper));
        let deletedItem = tempEntityCopy["subLists"].splice(index, 1)[0];
        delete entityErrorsCopy[deletedItem.id];
        delete entityErrorHelperCopy[deletedItem.id];
        setTempEntity(tempEntityCopy);
        setEntityErrors(entityErrorsCopy);
        setEntityErrorHelper(entityErrorHelperCopy);
        setHasChanges(true);
    };

    const saveListEntity = async () => {
        let alert = await Swal.fire({
            text: "¿Deseas guardar los cambios?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        //Cancelamos el eliminado del usuario
        if (alert.isDenied || alert.isDismissed) {
            return;
        }
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Actualizando entidad...");
        const { id, name, subLists } = tempEntity;
        let data = {
            clEntityId: id,
            entityName: name,
            subList: subLists,
        };
        if (storageData.exclusiveServices.processNaturalLenguage && storageData.appId) {
            data["appID"] = storageData.appId;
        }
        let res = await actionsPLNManagement(data, "updateEntityClosedList");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        switch (responseCode) {
            case 0:
                let alertSuccess = {
                    open: true,
                    severity: "success", //success, error, warning, info
                    message: `La entidad fue actualizada correctamente.`,
                };
                updateContextAttribute("alert", alertSuccess);
                setOpenEntitiesModal(false);
                await sleep(300);
                setHasChanges(false);
                refreshFunction();
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error", //success, error, warning, info
                    message: "Ocurrió un error al guardar los datos",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
    };

    const getModalBody = (entityType) => {
        switch (entityType) {
            case "Entity Extractor":
                return (
                    <EntityChildTable
                        tempEntity={tempEntity}
                        addNewEntityChild={addNewEntityChild}
                        removeEntityChild={removeEntityChild}
                        handleChildEntityForm={handleChildEntityForm}
                        entityErrors={entityErrors}
                        entityErrorHelper={entityErrorHelper}
                        accordionExpanded={accordionExpanded}
                        handleAccordionExpanded={handleAccordionExpanded}
                    />
                );
            case "Closed List Entity Extractor":
                return (
                    <ListEntityType
                        tempEntity={tempEntity}
                        handleListEntityForm={handleListEntityForm}
                        entityErrors={entityErrors}
                        entityErrorHelper={entityErrorHelper}
                        addListItem={addListItem}
                        deleteListItem={deleteListItem}
                    />
                );
            case "Regex Entity Extractor":
                return (
                    <RegexEntityType
                        handleChange={handleRegexEntityForm}
                        entityErrors={entityErrors}
                        entityErrorHelper={entityErrorHelper}
                        regexPattern={tempEntity.regexPattern}
                    />
                );
            default:
                return <div>Este tipo de entidad no es soportada por el Portal ╰（‵□′）╯</div>;
        }
    };

    const decideSaveFunction = (entityType) => {
        switch (entityType) {
            case "Regex Entity Extractor":
                return saveRegexEntityChanges;
            case "Entity Extractor":
                return saveEntity;
            case "Closed List Entity Extractor":
                return saveListEntity;
            default:
                break;
        }
    };

    const onClose = async () => {
        if (hasChanges) {
            let askConfirm = await Swal.fire({
                title: "¿Estás seguro que deseas salir?",
                text: "Los cambios realizados se perderán.",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
                confirmButtonColor: "#27315d",
                denyButtonColor: "#27315d",
            });
            //Cancelamos el guardado de cambios
            if (askConfirm.isDenied || askConfirm.isDismissed) {
                return;
            }
        }
        setOpenEntitiesModal(false);
        setHasChanges(false);
        setTempEntity(currentEntity);
    };

    return (
        <React.Fragment>
            <Dialog open={openEntitiesModal} fullScreen>
                <DialogTitle disableTypography>
                    <h2>{createNewEntity ? "Crear entidad" : "Editar entidad"}</h2>
                    <IconButton onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid className="intent-modal-content" item xs={11} sm={8}>
                        <div>
                            <label className="top-label-field">Nombre de la entidad</label>
                            <TextField
                                name="name"
                                value={name}
                                size="small"
                                type="small"
                                onChange={(event) =>
                                    handleChildEntityForm(
                                        id,
                                        0,
                                        null,
                                        null,
                                        null,
                                        null,
                                        event.target.name,
                                        event.target.value
                                    )
                                }
                                fullWidth
                                variant="outlined"
                                error={entityErrors[id]}
                                helperText={entityErrorHelper[id]}
                            />
                        </div>
                        <div>
                            <label className="top-label-field">Tipo de entidad</label>
                            <div>
                                <h3>
                                    {entityTypes?.find((type) => type.name === entityType)
                                        ?.typeName ?? ""}
                                </h3>
                                <span>
                                    {entityTypes?.find((type) => type.name === entityType)
                                        ?.typeDescription ?? ""}
                                </span>
                            </div>
                        </div>
                        <hr />
                        <div className="main-entity-modal">{getModalBody(entityType)}</div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.root}>
                        <CustomButton
                            disabled={
                                !hasChanges || Object.values(entityErrors).some((error) => error)
                            }
                            onClick={decideSaveFunction(tempEntity.entityType)}
                            variant="contained"
                        >
                            Guardar entidad
                        </CustomButton>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default EntitiesModal;
