import React from 'react'
//MUI components
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, IconButton as MuiIconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

//Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//Custom Portal Components
import Button from "./CustomButton";


const IconButton = withStyles((theme) => ({
root:{

    position: 'absolute',
    top: '18px',
    left: '10px',
}
}))(MuiIconButton)

export default class ArrowClose extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    render() {
        const { askConfirm, modalTitle, children, cancelLabel, confirmLabel } = this.props
        return(
            <div>
                {askConfirm ?
                    <Tooltip title="Salir">
                        <IconButton onClick={() => this.handleOpen()}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                :
                    <Tooltip title="Salir">
                        <IconButton onClick={() => this.props.onClose()}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                }
                <Dialog
                    open={this.state.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {modalTitle}
                    </DialogTitle>
                    <DialogContent>
                        {children}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} variant="contained" >
                            { cancelLabel ? cancelLabel : 'No' }
                        </Button>
                        <Button onClick={() => { this.handleClose(); this.props.onClose(); }} variant="contained"color="primary" autoFocus >
                            { confirmLabel ? confirmLabel : 'Sí' }
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}