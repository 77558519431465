import React from "react";

//Material UI
import {
    Grid as MuiGrid,
    TextField,
    IconButton,
    Tooltip,
    Button as MuiButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

//Icons
import { ReactComponent as TrashCan } from "../Icons/trashcanIcon.svg";
import AgregarIcono from "../Icons/AgregarIcono";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

//Custom Components
import ButtonConfirmDialog from "../components/common/ButtonConfirmDialog";
import FooterControls from "../components/FooterComponents/FooterControls";
import CustomAlert from "../components/common/Alerts/CustomAlert";
import MandatoryField from "../components/common/Fields/MandatoryField";
import AppContext from "../Context/AppContext";
import IntebotCatalog from "../components/IntebotCatalog";
import { intebotDataActions } from "../components/DataFunctions";

//Swal
import Swal from "sweetalert2";

const Grid = withStyles((theme) => ({
    root: {
        "& div label": {
            display: "block",
            [theme.breakpoints.up("md")]: {
                textAlign: "right",
            },
        },
    },
}))(MuiGrid);

const Button = withStyles((theme) => ({
    label: {
        textTransform: "none",
    },
}))(MuiButton);

export default class RegExForm extends React.Component {
    errorHelper = IntebotCatalog.errorHelper;

    constructor(props) {
        super(props);
        this.state = {
            regExProcessArray: [], //Para checar relación de las expresiones en los procesos
            processNameRegExUsed: [],
            regExErrors: {},
            countRegExErrors: 0,
            //Propiedades para agregar nuevas expresiones regulares
            newRegExFields: [],
            newRegExErrors: [],
            errorHelper: [],
            countNewRegExErrors: 0,
            hasChanges: false,
            //Controla las notificaciones
            alert: {
                open: false,
                severity: "", //success, error, warning, info
                message: "",
            },
        };
    }

    //Al cargarse el componente necesitamos saber si hubo cambios en el contexto para colocar los errores correspondientes
    componentDidMount() {
        const { regExTemp, processes } = this.context;
        //console.log(regEx.length);
        if (regExTemp.length > 0) {
            //Ya tenemos cargados los datos en el contexto y hay que revisar errores
            //Errores de campos existentes
            let regExErrors = {};
            //Recorremos el objeto con las expresiones regulares
            for (let expressions in regExTemp) {
                //Si el nuevo valor es vacío entonces marcamos error
                regExErrors[regExTemp[expressions].name] =
                    regExTemp[expressions].expresion === "" ? true : false;
            }
            //Contamos la cantidad de errores que se encontraron
            const countRegExErrors = Object.values(regExErrors).reduce((a, item) => a + item, 0);

            //Obtenemos la relación de las expresiones en los procesos
            let regExProcessArray = [];
            let processNameRegExUsed = [];
            //Recorremos todos los pasos de todos los procesos para guardar en un arreglo los catálogos asociados
            processes.forEach((process) => {
                process.steps.forEach((step) => {
                    if (step.type) {
                        regExProcessArray.push(step.type);
                        processNameRegExUsed.push({
                            regex: step.type,
                            processName: process.processName,
                            step: step.step + 1,
                        });
                    }
                });
            });
            this.setState({
                regExProcessArray: regExProcessArray,
                processNameRegExUsed: processNameRegExUsed,
                regExErrors: regExErrors,
                countRegExErrors: countRegExErrors,
            });
        }
    }

    //Si se modifica un registro se debe cambiar en el contexto temporal
    regExChangeHandler = (event, index) => {
        const { regExTemp, updateContextAttribute } = this.context;
        const { value, name } = event.target;
        //Hacemos una copia independiente del objeto para guardar cambios temporales:
        const tempRegExArray = JSON.parse(JSON.stringify(regExTemp));
        const regExErrors = Object.assign({}, this.state.regExErrors);
        //Guardamos el nuevo valor en nuestro arreglo temporal
        if (name.includes("description")) {
            tempRegExArray[index]["description"] = value;
        }
        if (name.includes("expresion")) {
            tempRegExArray[index]["expresion"] = value;
            regExErrors[name] = value ? false : true;
        }
        //Si el nuevo valor es vacío entonces marcamos error
        //Contamos la cantidad de errores con la siguiente función
        const countRegExErrors = Object.values(regExErrors).reduce((a, item) => a + item, 0);
        this.setState({
            regExErrors: regExErrors,
            countRegExErrors: countRegExErrors,
        });
        updateContextAttribute("regExTemp", tempRegExArray);
        updateContextAttribute("regExHasChanges", true);
    };

    //Si se agrega o modifica un nuevo registro se debe guardar en el estado hasta que se guarden los cambios
    newRegExChangeHandler = (event, index) => {
        const { name, value } = event.target;
        const { newRegExFields, newRegExErrors, errorHelper } = this.state;
        const { regExTemp } = this.context;
        //Hacemos una copia de newRegExFields y de sus errores para que el estado siga siendo inmutable:
        let newRegExFieldsCopy = [];
        let newRegExErrorsCopy = [];
        let errorHelperCopy = JSON.parse(JSON.stringify(errorHelper));
        //Primero identificamos si se está editando el nombre o la expresión
        if (name.includes("newName")) {
            //Recorremos la estructura para guardar en la posición exacta:
            newRegExFieldsCopy = newRegExFields.map((object, i) =>
                i === index
                    ? {
                          name: value.toLowerCase(),
                          expresion: object.expresion,
                          description: object.description,
                      }
                    : object
            );
            newRegExErrorsCopy = newRegExErrors.map((object, i) =>
                i === index
                    ? {
                          //Tenemos error si la cadena está vacía o si ya existe en el contexto temporal o si ya existe en newRegExFields
                          name:
                              value === "" ||
                              regExTemp.some((o) => o.name === value) ||
                              newRegExFields.some((o) => o.name === value)
                                  ? true
                                  : false,
                          expresion: object.expresion,
                          description: object.description,
                      }
                    : object
            );
            errorHelperCopy[index].name = value ? "" : this.errorHelper.emptyField;
        } else if (name.includes("newExpression")) {
            //Recorremos la estructura para settear en la posición exacta:
            newRegExFieldsCopy = newRegExFields.map((object, i) =>
                i === index
                    ? { name: object.name, expresion: value, description: object.description }
                    : object
            );
            newRegExErrorsCopy = newRegExErrors.map((object, i) =>
                i === index
                    ? {
                          name: object.name,
                          expresion: value === "" ? true : false,
                          description: object.description,
                      }
                    : object
            );
            errorHelperCopy[index].expresion = value ? "" : this.errorHelper.emptyField;
        } else if (name.includes("newDescription")) {
            //Recorremos la estructura para settear en la posición exacta:
            newRegExFieldsCopy = newRegExFields.map((object, i) =>
                i === index
                    ? { name: object.name, expresion: object.expresion, description: value }
                    : object
            );
            newRegExErrorsCopy = newRegExErrors.map((object, i) =>
                i === index
                    ? {
                          name: object.name,
                          expresion: object.expresion,
                          description: value === "" ? true : false,
                      }
                    : object
            );
            errorHelperCopy[index].description = value ? "" : "";
        }
        const countNewRegExErrors = Object.values(newRegExErrorsCopy).reduce(
            (a, { name, expresion }) => a + name + expresion,
            0
        );
        //Guardamos en el estado
        this.setState({
            newRegExFields: newRegExFieldsCopy,
            newRegExErrors: newRegExErrorsCopy,
            countNewRegExErrors: countNewRegExErrors,
            errorHelper: errorHelperCopy,
            hasChanges: true,
        });
    };

    onDelete = (id) => {
        const { regExTemp, updateContextAttribute } = this.context;
        const tempRegExArray = regExTemp.map((object) => object);
        tempRegExArray.splice(id, 1);
        this.setState({
            alert: {
                open: true,
                severity: "success",
                message: "Se eliminó correctamente",
            },
        });
        updateContextAttribute("regExTemp", tempRegExArray);
        updateContextAttribute("regExHasChanges", true);
    };

    //Revertimos todos los cambios del contexto temporal con los del contexto normal
    onCancel = () => {
        const { regEx, updateContextAttribute } = this.context;
        updateContextAttribute("regExTemp", regEx);
        updateContextAttribute("regExHasChanges", false);

        this.setState({
            regExErrors: {},
            newRegExFields: [],
            newRegExErrors: [],
            countRegExErrors: 0,
            countNewRegExErrors: 0,
            hasChanges: false,
            alert: {
                open: false,
                severity: "success",
                message: "Se restauraron los cambios",
            },
        });
    };

    //Guardamos todos los cambios del contexto temporal en el contexto normal
    onSave = async () => {
        //Destructuramos los objetos necesarios del contexto y del estado
        const { regExTemp, updateContextAttribute, getStorageData } = this.context;
        const { newRegExFields } = this.state;
        const { token } = getStorageData(["token"]);

        //Actualizamos el estado de la aplicación para mostrar el loading
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");

        //Hacemos una copia de las expresiones del contexto temporal
        const regExCopy = regExTemp.map((object) => object);

        //Si existen nuevas expresiones las agregamos al contexto
        newRegExFields.forEach((item) => {
            regExCopy.push(item);
        });

        //Actualizamos la lista de expresiones regulares en base de datos
        let data = {
            dataTypes: regExCopy,
            token,
        };
        let res = await intebotDataActions(
            data,
            "updateConfigurationDataTypes&enviroment=Test",
            "ModelConfigurationOperations"
        );
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                //Actualizamos el estado y el contexto
                this.setState({
                    //Propiedades para agregar nuevas expresiones regulares
                    newRegExFields: [],
                    newRegExErrors: [],
                    countNewRegExErrors: 0,
                    hasChanges: false,
                    //Controla las notificaciones
                    alert: {
                        open: true,
                        severity: "success", //success, error, warning, info
                        message: "Los cambios se guardaron temporalmente",
                    },
                });
                updateContextAttribute("regEx", regExCopy);
                updateContextAttribute("regExTemp", regExCopy);
                updateContextAttribute("regExHasChanges", false);
                updateContextAttribute("regExHasSavedChanges", true);
                break;
            case 2:
                //El usuario no tiene permisos para realizar esta acción
                this.setState({
                    alert: {
                        open: true,
                        severity: "info", //success, error, warning, info
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    },
                });
                break;
            case 3:
                Swal.fire({
                    icon: "info",
                    title: "Tu cuenta no tiene permisos para editar esta suscripción",
                    text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                });
                break;
            default:
                //Error desconocido
                this.setState({
                    alert: {
                        open: true,
                        severity: "error", //success, error, warning, info
                        message:
                            "Ocurrió un error al guardar los cambios, por favor intenta más tarde.",
                    },
                });
                break;
        }
    };

    addNewRegEx = () => {
        //Creamos una copia sin referencia y le agregamos un nuevo objeto con campos vacíos
        const { errorHelper, newRegExFields, newRegExErrors } = this.state;
        const newRegExFieldsCopy = newRegExFields.map((object) => object);
        newRegExFieldsCopy.push({ name: "", expresion: "" });
        //Hacemos lo mismo para guardar los errores
        const newRegExErrorsCopy = newRegExErrors.map((object) => object);
        newRegExErrorsCopy.push({ name: true, expresion: true });
        const errorHelperCopy = errorHelper.map((object) => object);
        errorHelperCopy.push({
            name: this.errorHelper.emptyField,
            description: "",
            expresion: this.errorHelper.emptyField,
        });
        //Contamos la cantidad de errores con la siguiente función
        const countNewRegExErrors = Object.values(newRegExErrorsCopy).reduce(
            (a, { name, expresion }) => a + name + expresion,
            0
        );
        this.setState((prevState) => ({
            newRegExFields: newRegExFieldsCopy,
            newRegExErrors: newRegExErrorsCopy,
            errorHelper: errorHelperCopy,
            countNewRegExErrors: countNewRegExErrors,
            hasChanges: true,
        }));
    };

    removeNewRegEx = (id) => {
        //Copia de campos (sin referencia)
        const newRegExFieldsCopy = this.state.newRegExFields.map((object) => object);
        newRegExFieldsCopy.splice(id, 1);
        //Copia de errores (sin referencia)
        const newRegExErrorsCopy = this.state.newRegExErrors.map((object) => object);
        newRegExErrorsCopy.splice(id, 1);
        //Contamos la cantidad de errores en los nuevos campos
        const countNewRegExErrors = Object.values(newRegExErrorsCopy).reduce(
            (a, { name, expresion }) => a + name + expresion,
            0
        );
        this.setState({
            newRegExFields: newRegExFieldsCopy,
            newRegExErrors: newRegExErrorsCopy,
            countNewRegExErrors: countNewRegExErrors,
            alert: {
                open: true,
                severity: "success",
                message: "Se quitó correctamente",
            },
        });
    };

    handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            alert: {
                open: false,
                message: "",
            },
        });
    };

    render() {
        const { regExTemp, regExHasChanges, readOnly } = this.context;
        const {
            errorHelper,
            regExProcessArray,
            processNameRegExUsed,
            newRegExErrors,
            alert,
            newRegExFields,
            countNewRegExErrors,
            hasChanges,
            countRegExErrors,
            regExErrors,
        } = this.state;

        return (
            <React.Fragment>
                <CustomAlert
                    open={alert.open}
                    severity={alert.severity}
                    message={alert.message}
                    handleCloseAlert={() => this.handleCloseAlert()}
                />
                <Grid container justifyContent="space-around">
                    <Grid item sm={12} xs={12}>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                margin: "5px 0",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <div style={{ display: "flex" }}>
                                <h3 style={{ textAlign: "center", margin: 0 }}> Descripción </h3>
                                <Tooltip title="Campo informativo que permite indicar cuales son las validaciones consideradas para los datos ingresados por el usuario.">
                                    <HelpOutlineIcon color="primary" fontSize="small" />
                                </Tooltip>
                            </div>
                            <div style={{ display: "flex" }}>
                                <h3 style={{ textAlign: "center", margin: 0 }}>Expresión </h3>
                                <Tooltip
                                    title="Estructura que permite validar si el dato ingresado por el usuario corresponde a la entrada esperada.
                                Para utilizar caracteres especiales como ‘()’, ‘[]’ o ‘{}’ se requiere anteponer ‘\’, por ejemplo: “(a | a\))”, lo anterior permite las entradas ‘a’ y ‘a)’."
                                >
                                    <HelpOutlineIcon color="primary" fontSize="small" />
                                </Tooltip>
                            </div>
                        </div>
                        {/*==========================REGEX CREADOS POR EL CLIENTE========================================*/}
                        {regExTemp.map((regExp, index) => (
                            <Grid container spacing={3} alignItems="flex-start" key={index}>
                                <Grid item sm={12} xs={12}>
                                    <Grid container spacing={1} alignItems="flex-start">
                                        <Grid item sm={3} xs={12}>
                                            <label>
                                                {/* <span style={{color: 'red'}}>* </span> &nbsp;  */}
                                                {regExp.name}
                                            </label>
                                        </Grid>
                                        <Grid item md={7} sm={7} xs={11}>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <TextField
                                                    disabled={regExp.noEditable} //Todos los regex no editables los deshabilitamos
                                                    name={regExp.name + "_description"}
                                                    value={regExp.description}
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    size="small"
                                                    //error={regExErrors[regExp.name]}
                                                    required={true}
                                                    onChange={(event) =>
                                                        this.regExChangeHandler(event, index)
                                                    }
                                                />
                                                <TextField
                                                    disabled={regExp.noEditable}
                                                    name={regExp.name + "_expresion"}
                                                    value={regExp.expresion}
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    size="small"
                                                    error={regExErrors[`${regExp.name}_expresion`]}
                                                    required={true}
                                                    onChange={(event) =>
                                                        this.regExChangeHandler(event, index)
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {regExp.noEditable ? (
                                                <div>
                                                    {/* Botón de eliminar invisible para los regex NO editables */}
                                                </div>
                                            ) : (
                                                //preguntamos si ya esta siendo utilizado
                                                <React.Fragment>
                                                    {regExProcessArray.includes(regExp.name) ? (
                                                        <ButtonConfirmDialog
                                                            title={
                                                                <span>
                                                                    {" "}
                                                                    La expresión{" "}
                                                                    <b>{regExp.name}</b> no se puede
                                                                    eliminar porque está relacionado
                                                                    con los flujos:{" "}
                                                                    {processNameRegExUsed
                                                                        .filter(
                                                                            (takenRegex) =>
                                                                                takenRegex.regex ===
                                                                                regExp.name
                                                                        )
                                                                        .map(
                                                                            (
                                                                                currentRegex,
                                                                                index
                                                                            ) => (
                                                                                <div key={index}>
                                                                                    •{" "}
                                                                                    <span>
                                                                                        {
                                                                                            currentRegex.processName
                                                                                        }{" "}
                                                                                        en el Paso{" "}
                                                                                        {
                                                                                            currentRegex.step
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        )}{" "}
                                                                </span>
                                                            }
                                                            confirmName="Ok"
                                                            tooltip={"Eliminar campo"}
                                                            color="secondary"
                                                            size="small"
                                                            icon={
                                                                <TrashCan
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            }
                                                        />
                                                    ) : (
                                                        //permitimos que sea eliminado
                                                        <ButtonConfirmDialog
                                                            title={
                                                                <div>
                                                                    ¿Deseas eliminar el tipo de
                                                                    dato: <b>{regExp.name}</b>?
                                                                </div>
                                                            }
                                                            confirmName="Sí"
                                                            cancelName="No"
                                                            tooltip={"Eliminar campo"}
                                                            color="secondary"
                                                            size="small"
                                                            onConfirm={() => this.onDelete(index)}
                                                            icon={
                                                                <TrashCan
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        {/*==========================REGEX CREADOS TEMPORALMENTE========================================*/}
                        {newRegExFields.map((object, index) => (
                            <Grid container spacing={3} alignItems="flex-start" key={index}>
                                <Grid item sm={12} xs={12}>
                                    <Grid container spacing={1} alignItems="flex-start">
                                        <Grid item sm={3} xs={12}>
                                            <TextField
                                                name={"newName_" + index}
                                                value={object.name}
                                                placeholder="Nombre"
                                                variant="outlined"
                                                multiline
                                                fullWidth
                                                size="small"
                                                error={newRegExErrors[index].name}
                                                required={true}
                                                helperText={errorHelper[index].name}
                                                onChange={(event) =>
                                                    this.newRegExChangeHandler(event, index)
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={7} sm={7} xs={11}>
                                            <div style={{ display: "flex" }}>
                                                <TextField
                                                    name={"newDescription_" + index}
                                                    value={object.description}
                                                    placeholder="Descripción"
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    size="small"
                                                    error={newRegExErrors[index].description}
                                                    required={true}
                                                    helperText={errorHelper[index].description}
                                                    onChange={(event) =>
                                                        this.newRegExChangeHandler(event, index)
                                                    }
                                                />
                                                <TextField
                                                    name={"newExpression_" + index}
                                                    value={object.expresion}
                                                    placeholder="Expresión"
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    size="small"
                                                    error={newRegExErrors[index].expresion}
                                                    required={true}
                                                    helperText={errorHelper[index].expresion}
                                                    onChange={(event) =>
                                                        this.newRegExChangeHandler(event, index)
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title="Quitar campo temporal">
                                                <IconButton
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => this.removeNewRegEx(index)}
                                                >
                                                    <TrashCan width="20px" height="20px" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container spacing={3} alignItems="flex-start">
                            <Grid item sm={12} xs={12}>
                                <Grid container spacing={1} alignItems="flex-start">
                                    <Grid item sm={3} xs={12}>
                                        <React.Fragment />
                                    </Grid>
                                    <Grid item md={7} sm={7} xs={12}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            {countNewRegExErrors > 0 ? (
                                                <Button
                                                    startIcon={
                                                        <AgregarIcono width="15px" height="15px" />
                                                    }
                                                    disabled
                                                    size="small"
                                                >
                                                    Crear nuevo tipo de dato
                                                </Button>
                                            ) : (
                                                <Button
                                                    startIcon={
                                                        <AgregarIcono width="15px" height="15px" />
                                                    }
                                                    color="primary"
                                                    size="small"
                                                    onClick={this.addNewRegEx}
                                                >
                                                    Crear nuevo tipo de dato
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <MandatoryField />
                <FooterControls
                    disableSave={
                        (!hasChanges && !regExHasChanges) ||
                        countRegExErrors > 0 ||
                        countNewRegExErrors > 0 ||
                        readOnly
                    }
                    disableCancel={(!hasChanges && !regExHasChanges) || readOnly}
                    onSave={() => this.onSave()}
                    onCancel={() => this.onCancel()}
                    showChatBtn={true}
                />
            </React.Fragment>
        );
    }
}

RegExForm.contextType = AppContext;
