import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'

//Mui components
import {
    TextField,
    InputAdornment,
    Button as MuiButton,
    FormControlLabel as MuiFormControlLabel,
    Checkbox,
    IconButton,
  } from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';

//Icons
import { ReactComponent as IconPassword } from '../../Icons/contraseña_icono.svg'
import { ReactComponent as IconUser } from '../../Icons/usuario_icono.svg'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

//External Libraries
import { isEmail } from "validator"; //https://github.com/validatorjs/validator.js/
import Swal from 'sweetalert2' //https://sweetalert2.github.io

//Custom Portal Components
import AppContext from '../../Context/AppContext';
import IntebotCatalog from '../IntebotCatalog'
import { hashPassword } from './hashPassword';
import { authActions } from '../DataFunctions'

const errorHelperKeys = IntebotCatalog.errorHelper

//Estilos de componentes
const Button = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        //border: 0,
        color: 'white',
        height: 36,
        padding: '0 30px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&$disabled': {
            color: 'white',
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: 'none',
    },
}))(MuiButton);

const FormControlLabel = withStyles(theme => ({
    label: {
        fontSize: '1em',
        color: '#b2b2b2',
    }
}))(MuiFormControlLabel);


const Login = () => {
    const { authenticateUser, updateContextAttribute } = useContext(AppContext);
    const [state, setState] = useState({
        //Login
        loginForm: {
            email: '',
            password: '',
        },
        remember: false,
        loginErrors: {
            email: false,
            password: false,
        },
        errorHelperLogin:{
            email: errorHelperKeys.removeError,
            password: errorHelperKeys.removeError,
            login: errorHelperKeys.removeError
        },
    });

    const { loginForm, remember, loginErrors, errorHelperLogin, showPassword } = state;

    //Cambiar el campo de password -> Text
    const handleShowPassword = () => {
        const { showPassword } = state;
        setState({
            ...state,
            showPassword: showPassword ? false : true,
        })
    }

    //================ ANTIGUO codigo de Verificar el correo con un token ============
    // useEffect(() => {
    //     Buscamos si el URL contiene el Token
    //     let url = new URL(document.location);
    //     let searchParams = new URLSearchParams(url.search);
    //     let token = searchParams.get("token")
    //     let action = searchParams.get("action")
    //     console.log('action', action)
    //     Si existe el token, este lo mandamos a validar
    //     if(token) {
    //         getValidationToken(token, action)
    //     }
    //   }, []);

        //Obtener el token de creación de cuenta, mandarlo y eliminarlo de la URL
    // const getValidationToken = (token, action) => {
    //     //Recibimos el token y 
    //     sendValidationToken(token, action)
    //     //Después de hacer la petición, obtenemos la url, quitamos los parametros y dejamos únicamente la URL del login
    //     let currentURL = window.location.href;
    //     let afterDomain = currentURL.substring(currentURL.lastIndexOf('/') + 1);
    //     let beforeQueryString= afterDomain.split("?")[0];  
    //     window.history.pushState({}, document.title, "/" + beforeQueryString );
    // }

    //Mandar a validar el token de creación de cuenta
    // const sendValidationToken = async (token, action) => {
    //     updateContextAttribute('loadingDialog', true)
    //     updateContextAttribute('LoadingMessage', 'Validando el correo...')
    //     //Reutilizamos la función para reenviar el correo de cambio de password
    //     let tokenIsEmail = isEmail(token);
    //     let bodyRequest = tokenIsEmail ? { "email" : token } : {};
    //     let typeToken = tokenIsEmail ? "email" : "token";
    //     //console.table(bodyRequest)
    //     //Mandamos el token al servicio
    //     let res = await authActions(bodyRequest, `${action}&${typeToken}=${token}`, 'LogIn')
    //     let responseCode =res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //Si no hay un código de respuesta lo mandamos al default
    //     //console.log('res validate token :>> ', res);
    //     updateContextAttribute('loadingDialog', false)
    //     updateContextAttribute('LoadingMessage', '')
    //     //Mandamos la notificación, según La respuesta de la validación del token
    //     switch (responseCode) {
    //         //Codigos de confirmación de creacion de cuenta
    //         case 0:
    //             Swal.fire({
    //                 icon: 'success',
    //                 title: tokenIsEmail ? "Correo enviado con éxito." : 'Cuenta registrada con éxito.',
    //                 text: tokenIsEmail ? "Checa tu bandeja de entrada o de spam." : 'Cuenta registrada con éxito.',
    //             })
    //             break;
    //         case 1: 
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Hubo un error al procesar tu solicitud',
    //                 text: 'Intenta más tarde',
    //             })
    //             break;
    //         case 2: 
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Este correo ya esta registrado.',
    //                 text: 'Inicia sesión.',
    //             })
    //             break;
    //         case 3: 
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'El enlace ha expirado.',
    //                 text: 'Vuelve a hacer el proceso de registro.',
    //             })
    //             break;
    //         default:
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Hubo un error al procesar tu solicitud',
    //                 text: 'Intenta de nuevo más tarde',
    //             })
    //             break;
    //     }
    // }

    useEffect(() => {
        performURLaction()
    }, [])
    

    const performURLaction = () => {
        let url = new URL(document.location);
        let searchParams = new URLSearchParams(url.search);
        let token = searchParams.get("token")
        let action = searchParams.get("action")
        switch (action) {
            case 'ConfirmRole':
                getTokenData(token)
                break;
            case 'ResendLinkPassword':
                reSendRestorePassword(token) //Token is email
                break;
        
            default:
                break;
        }
    }

    const reSendRestorePassword = async (email) => {
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Reenviando Correo...')
        let res = await authActions(null, `ResendLinkPassword&email=${email}`, 'PasswordReset')
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 //si no hay respuesta correcta del serivico, mandamos el codigo 99 para indicar el error
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
        switch (responseCode) {
            case 0:
                let resultAlert0 = await Swal.fire({
                        icon: 'success',
                        title: "Correo enviado con éxito.",
                        text: "Checa tu bandeja de entrada o de spam.",
                    })
                removeTokenFromURL()
                break;
            default:
                Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error al procesar tu solicitud',
                    text: 'Intenta de nuevo más tarde',
                })
                break;
        }
    }

    const removeTokenFromURL = () => {
        let currentURL = window.location.href;
        let afterDomain = currentURL.substring(currentURL.lastIndexOf('/') + 1);
        let beforeQueryString= afterDomain.split("?")[0];  
        window.history.pushState({}, document.title, "/" + beforeQueryString );
    }

    const getTokenData = async (token) => {
        updateContextAttribute('loadingDialog', true)
        updateContextAttribute('LoadingMessage', 'Cargando información...')
        let res = await authActions({},`ConfirmRole&token=${token}`,'RoleManagement')
        updateContextAttribute('loadingDialog', false)
        updateContextAttribute('LoadingMessage', '')
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99 
        switch (responseCode) {
            case 1:
                let resultAlert1 = await Swal.fire({
                    icon: 'info',
                    title: 'No cuentas con una cuenta activa del portal',    
                    text: 'Serás Redirigido la sección de crear cuenta.',
                })
                if(resultAlert1){
                    window.location.href=`/create-account${window.location.search}`
                }
                break;
            case 9:
                let resultAlert9 = await Swal.fire({
                    icon: 'success',
                    title: 'El usuario ha sido agregado correctamente a la suscripción',    
                    text: 'Ya puedes utilizar el portal',
                })
                removeTokenFromURL()
                break;
            default:
                let resultAlertDefault = await Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error agregar la cuenta a la suscripción.',    
                    text: 'Intenta más tarde.',
                })
                break;
        }
    }

    //Guardar el form de Inicio de sesión con sus respectibas validaciones para cada campo
    const handleLoginChange = (targetName, targetValue) => {
        let { loginForm, remember, errorHelperLogin, loginErrors } = state;
        switch (targetName) {
            case "remember":
                remember = targetValue
                break;
            case "email":
                loginForm[targetName] = targetValue 
                loginErrors[targetName] = targetValue ? false : true
                errorHelperLogin[targetName] = targetValue ? errorHelperKeys.removeError : errorHelperKeys.emptyField
                break;
            case "password":
                //la contraseña no debe medir mas de 15
                if(targetValue.length > 15){
                    //NO guardamos
                   break;
                }
                //Sin errores
                loginForm[targetName] = targetValue;
                loginErrors[targetName] = false;
                errorHelperLogin[targetName] = errorHelperKeys.removeError;
                break;
            default:
                loginForm[targetName] = targetValue
                break;
        }
        setState({
            ...state,
            loginForm: loginForm,
            remember: remember,
            loginErrors:loginErrors,
            errorHelperLogin:errorHelperLogin
        })
    }


    //Mandar el Form de inicio de sesión al servicio al momento de dar click al boton de acceder
    const sendAuthForm = async () => {
        const { loginForm, loginErrors, errorHelperLogin, remember } = state;
        let loginFormCopy = JSON.parse(JSON.stringify(loginForm))
        let loginErrorsCopy = JSON.parse(JSON.stringify(loginErrors))
        let errorHelperCopy = JSON.parse(JSON.stringify(errorHelperLogin))
        //Validaciones de cada campo
        let email = loginFormCopy.email.trim();
        let password = loginFormCopy.password;
        let validEmail = (isEmail(email) && email !== '') ? true : false //correo con @ y dominio, diferente de vacío
        let validPassword = password.length >= 8 && password.length <= 15 ? true : false //Diferente de Vacío
        loginErrorsCopy['email'] = validEmail ? false : true
        errorHelperCopy['email'] = validEmail ? errorHelperKeys.removeError : errorHelperKeys.notEmail
        loginErrorsCopy['password'] = validPassword ? false : true
        errorHelperCopy['password'] = validPassword ? errorHelperKeys.removeError : errorHelperKeys.notPassword
        //Hasta que tengamos un email y password valido mandamos el form
        if(validEmail && validPassword){
            updateContextAttribute('loadingDialog', true)
            updateContextAttribute('LoadingMessage', 'Validando credenciales...')
            updateContextAttribute('remeberAuthCredentials', remember)
            //hashear la password crea un pequeño delay, entonces esperamos a que aparezca el dialogo de loading, y despues hasheamos y llamamos al servicio
            loginFormCopy.password = await hashPassword(password)
            loginFormCopy.email = loginFormCopy.email.trim() //quitamos los espacios al final de la cadena
            let responseCode = await authenticateUser(loginFormCopy)
            switch (responseCode) {
                case 11: 
                    //Si el usuario no esta activo
                    Swal.fire({
                        icon: 'info',
                        title: 'Tu cuenta no está activa',
                        text: 'El correo ingresado no cuenta con alguna suscripción activa. Por favor, contacta a tu administrador para que te agregue a la suscripción.',
                    })
                    
                    break;
                case 3:
                    errorHelperCopy['login'] = errorHelperKeys.wrongCredentials
                    break;
                case 98:
                    errorHelperCopy['login'] = errorHelperKeys.errorInStorage
                    break;
                default:
                    errorHelperCopy['login'] = errorHelperKeys.authApiError
                    break;
            }
        }
        setState({
            ...state,
            loginErrors: loginErrorsCopy,
            errorHelperLogin: errorHelperCopy
        })
    }

    return (
        <React.Fragment>
            <header>
                    <h2>Iniciar Sesión</h2>
                </header>
                {/* Errores del Login */}
                <div style={{display: 'flex',justifyContent:'center'}} >
                    <span style={{fontSize: '0.75rem',color:'red'}} >{errorHelperLogin['login']}</span> 
                </div>
                {/*Campos del Login */}
                <form onSubmit={(e) => {e.preventDefault(); sendAuthForm()}}>
                    <div className='AuthInputsContainer'>
                        <TextField
                            name="email"
                            value={loginForm.email}
                            variant="outlined"
                            size="small"
                            type="email"
                            onChange={(e) => handleLoginChange(e.target.name, e.target.value)}
                            placeholder="Correo electrónico"
                            error={loginErrors.email}
                            helperText={loginErrors.email ? errorHelperLogin['email'] : ''}
                            InputProps={{
                                //Icono del Correo
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <div>
                                            <IconUser width="12px" />
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            name="password"
                            value={loginForm.password}
                            variant="outlined"
                            size="small"
                            onChange={(e) => handleLoginChange(e.target.name, e.target.value)}
                            placeholder="Contraseña"
                            type={showPassword ? "text" : "password"}
                            error={loginErrors.password}
                            helperText={loginErrors.password ? errorHelperLogin['password'] : ''}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <div>
                                            <IconPassword width="12px" />
                                        </div>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                            <IconButton onClick={() => handleShowPassword()}>
                                                { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                            </IconButton>
                                    </InputAdornment>
                                ) 
                            }}
                        />
                    </div>
                    {/* Botones de ayuda del login  */}
                    <div className="loginHelpContainer">
                        <FormControlLabel
                            control={
                                <Checkbox
                                checked={remember}
                                onChange={(e) => handleLoginChange(e.target.name, e.target.checked)}
                                name="remember"
                                color="primary"
                                />
                            }
                            label="Recuerdame"
                        />
                        <div className='loginLinksContainer' >
                            <Link to="/search-account">¿Has olvidado tu contraseña?</Link>
                            {/* <Link to="/create-account">Crea una cuenta</Link> */}
                        </div>
                    </div>
                    <div className="authButtonContainer" >
                        <Button type='submit'>Acceder</Button>
                    </div>
                </form>
        </React.Fragment>
    );
}

export default Login;
